import React, {useEffect} from "react";
import type {ControlProps} from "./BaseControl";
import BaseControl from "./BaseControl";
import type {InputType} from "components/constants";
import {Upload} from "antd";
import type {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import _ from "lodash";
import Api from "@byk/api/Api";
import link_icon from "@byk/assets/images/link_icon.png";
import LinkPageSet from "../../pages/Editor/MenuFrame/LinkPageSet";
import {useDispatch, useSelector} from "react-redux";
import {X_LINKPAGESET, X_LINKPANNELOFFSET, X_OPENLINKPANNEL} from "@byk/store/XReducers";
import { getCurrentApplicationId } from "../../selectors/editorSelectors";
import { getTenant } from "utils/localStorage";

const UploadImage = (props: any) => {
  let {valueChange, propertyValue: fileList, size, isCarouselWidget, isDescription} = props;
  const dispatch = useDispatch();
  const maxImageLength = size ?? 5;
  if (fileList && fileList.startsWith && fileList.startsWith('{{appsmith.store.key')) {
    return fileList
  }

  fileList = (!fileList || fileList == '[]') ? [] : fileList;

  const applicationId = useSelector(getCurrentApplicationId)

  const openLinkPannel = useSelector((state: any) => {
    return state.XReducers.openLinkPannel;
  })

  const xLinkPageSet = useSelector((state: any) => {
    return state.XReducers.linkPannelSet;
  })

  useEffect(() => {
    const _fileList = _.cloneDeep(fileList);
    const _idx = _.findIndex(_fileList, {uid: xLinkPageSet.uid});
    if (_idx >= 0 && xLinkPageSet.save) {
      _fileList.splice(_idx, 1, {...xLinkPageSet});
      valueChange(_fileList);
    }

  }, [xLinkPageSet]);

  const onChange: UploadProps['onChange'] = async ({fileList: newFileList}) => {
    if (newFileList.length < fileList.length) {
      //valueChange(newFileList)
    } else {
      let diff: any = _.difference(newFileList, fileList)?.[0];
      if (!diff) {
        return;
      }
      diff.thumbUrl = await reSrc(diff);
      const {thumbUrl, name, type, lastModified} = diff;
      const ext = name.substring(name.lastIndexOf('.'));
      const params = {
        data: thumbUrl?.split('base64,')?.[1],
        ext,
        filename: name,
        type
      };
      Api.post(`/byk/${getTenant()}/rest/SysFile`, params).then((res: any) => {
        if(!res.success){
          return ;
        }
        let code = res.result[0]['code'];
        valueChange([
          ...fileList,
          {
            uid: code,
            url: `/byk/${getTenant()}/file/${code}`,
            status: 'done',
            lastModified
          }
        ]);
      })
    }
  };
  const reSrc = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    return src;
  }
  const onPreview = async (file: UploadFile) => {
    const src = await reSrc(file);
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onBeforeUpload = () => {
    return false;
  }
  const onRemove = (file:any)=>{
    let newFileList = fileList.filter((i:any)=>{
      return i.uid !== file.uid;
    });
    valueChange(newFileList);
  }

  return (
    <>
      {isCarouselWidget && openLinkPannel && <LinkPageSet />}

      <Upload
        className={isCarouselWidget ? 'carouselWidgetCarousel' : ''}
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onRemove={onRemove}
        onPreview={onPreview}
        beforeUpload={onBeforeUpload}
        itemRender={(originNode, file, currFileList) => {
          return (
            (
              <>
                {isCarouselWidget &&
                <div style={{position: 'relative'}}>
                  <img
                    style={{
                      backgroundColor: '#090707 ',
                      width: '12px',
                      position: 'absolute',
                      right: '-3px',
                      top: '-2px',
                      zIndex: 1,
                      cursor: 'pointer',
                    }}
                    src={link_icon}
                    onClick={() => {
                      dispatch({
                        type: X_LINKPANNELOFFSET,
                        data: {
                          left: 'auto',
                          right: '293px',
                          top: '324px',
                          bottom: 'auto',
                          isIcon: false
                        }
                      })
                      dispatch({
                        type: X_OPENLINKPANNEL,
                        data: true
                      })
                      dispatch({
                        type: X_LINKPAGESET,
                        data: {
                          ...file,
                          appid: applicationId,
                          isDescription,
                          save: false
                        }
                      });
                    }}
                  />
                </div>
                }
                {originNode}
              </>
            )
          )
        }}
      >
        {fileList.length < maxImageLength && '+'}
      </Upload>
    </>
  );
};

class CarouselControl extends BaseControl<CarouselControlProps> {
  render() {
    const {propertyValue, size, iconSize, widgetProperties, isCarousel} = this.props;
    const {type, isDescription} = widgetProperties;
    let isCarouselWidget = (type === "CAROUSEL_WIDGET"||isCarousel)
    return (
      <UploadImage
        size={size} isCarouselWidget={isCarouselWidget} isDescription={isDescription} iconSize={iconSize} propertyValue={propertyValue}
        valueChange={this.valueChange}/>
    );
  }

  valueChange = (result: any) => {
    if(this.props.widgetProperties.type === 'X_IMAGE_WIDGET'){
      this.batchUpdatePropertiesWithAssociatedUpdates([
        { propertyName: 'value', propertyValue: result[0]?result[0].url:'' },
        { propertyName: 'uploadValue', propertyValue: result },
      ])
    }else{
      this.updateProperty(this.props.propertyName, result, true);
    }

  }


  static getControlType() {
    return "CAROUSEL_ARRAY";
  }
}

export interface CarouselControlProps extends ControlProps {
  placeholderText: string;
  inputType: InputType;
  validationMessage?: string;
  widgetType?: string;
  isDisabled?: boolean;
  isDescription?: boolean;
  isCarousel?: boolean;
  defaultValue?: any;
  size?: number,
  onFocus?: () => void;
  onBlur?: () => void;
  iconSize?: number,
}

export default CarouselControl;
