import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {isDbColumnType} from "@byk/pages/modeling/ModelDataUtil";
import _ from "lodash";
import {PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";
import MySqlCrudTemplate from "@byk/pages/modeling/SqlQueryEditor/CrudTemplate/MySqlGen";

// 字母前添加下划线
function addUnderscoreBeforeCapital(str: any) {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
}

// 获取模型名对应的数据库名称
export function getModelTableName(modeName: any) {
  let tableName = addUnderscoreBeforeCapital(modeName);
  if (tableName.indexOf('_') == 0) {
    tableName = tableName.substring(1);
  }

  return tableName;
}

function sortProperties(properties: any = []) {
  let sortProperties = _.sortBy(properties, ['name']);
  let _properties: any = [];
  let fkProperties: any = [];
  let primaryProperties: any = [];
  sortProperties.forEach((item: any) => {
    if (item.type == PROPERTY_REL_TYPE.M2O) {
      fkProperties.push(item);
    } else if (item.primaryKey) {
      primaryProperties.push(item);
    } else {
      _properties.push(item);
    }
  });
  return [...primaryProperties, ...fkProperties, ..._properties];
}

interface SqlQueryEditorModelProps {
  modelList: any[];
  codeMirrorHintOptions: any;
}

export class SqlQueryEditorModel implements SqlQueryEditorModelProps {
  modelList: any = [];
  codeMirrorHintOptions: any = {};
  sqlList: any = [];

  async init() {
    this.modelList = [];
    this.sqlList = [];
    this.codeMirrorHintOptions = {};
    await this.getSqlList();

    await ModelingApi.doGetModelList().then((res: any) => {
      this.codeMirrorHintOptions = {};
      let _modelList = res.result || [];
      let modelMapByName: any = {};
      _modelList.forEach((item: any) => {
        let _codeMirrorHintOptionsItem: any = [];
        modelMapByName[item.name] = item;
        item._table_name = getModelTableName(item.name);

        let _properties: any = [];
        let properties: any = sortProperties(item.properties);
        properties.forEach((proItem: any) => {
          if (isDbColumnType(proItem.type)) {
            proItem['_column_name'] = addUnderscoreBeforeCapital(proItem.name);
            _properties.push(proItem);
            _codeMirrorHintOptionsItem.push(proItem._column_name)
          }
        });
        item.properties = [..._properties];
        MySqlCrudTemplate.addCrudTemplate(item);
        this.codeMirrorHintOptions[item._table_name] = _codeMirrorHintOptionsItem;
      });
      this.modelList = _modelList;
    });
  }

  async getSqlList() {
    await ModelingApi.doSqlInfoRestUrl().then((res: any) => {
      if (res.success && res.result) {
        this.sqlList = res.result;
      }
    });
  }

  getNewTitle() {
    let maxCnt = 0;
    this.sqlList.forEach((item: any) => {
      if (item.title.indexOf('Query') == 0) {
        let _cnt:any = new Number(item.title.substring(5));
        if (!isNaN(_cnt) && _cnt > maxCnt) {
          maxCnt = _cnt;
        }
      }
    });
    return 'Query' + (maxCnt + 1);
  }

  async newSqlInfo() {
    let newTitle = this.getNewTitle();
    let newSqlInfo = {
      title: newTitle,
    }
    let res:any = await ModelingApi.doSqlInfoRestUrl("insert", newSqlInfo);
    if (res.success && res.result.length > 0) {
      await this.getSqlList();
      return res.result[0];
    }

    return null;
  }

  async deleteSqlInfo(params: any) {
    let res:any = await ModelingApi.doSqlInfoRestUrl("delete", params);
    if (res.success) {
      await this.getSqlList();
    }
  }

  async saveSqlInfo(sqlInfo: any) {
    let res:any = await ModelingApi.doSqlInfoRestUrl("update", sqlInfo);
    if (res.success) {
      await this.getSqlList();
      return res.result[0];
    }
    return null;
  }

  async insertSqlInfo(sqlInfo: any) {
    let res:any = await ModelingApi.doSqlInfoRestUrl("insert", sqlInfo);
    if (res.success) {
      await this.getSqlList();
      return res.result[0];
    }
    return null;
  }

  filterSqlList(searchText: any = '') {
    if (!searchText) {
      return this.sqlList;
    } else {
      return this.sqlList.filter((item: any) => {
        return item.title.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
      });
    }
  }
}
