import {Alignment} from "@blueprintjs/core";
import {LabelPosition} from "components/constants";
import {EventType} from "constants/AppsmithActionConstants/ActionConstants";
import {Layers} from "constants/Layers";
import type {WidgetType} from "constants/WidgetConstants";
import type {ValidationResponse} from "constants/WidgetValidation";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import equal from "fast-deep-equal/es6";
import type {LoDashStatic} from "lodash";
import _, {isArray, isFinite, isString, xorWith} from "lodash";
import type {DraftValueType, LabelInValueType} from "rc-select/lib/Select";
import React from "react";
import {isAutoLayout} from "utils/autoLayout/flexWidgetUtils";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type {AutocompletionDefinitions} from "widgets/constants";
import {GRID_DENSITY_MIGRATION_V1, MinimumPopupRows} from "widgets/constants";
import {DefaultAutocompleteDefinitions, isAutoHeightEnabledForWidget,} from "widgets/WidgetUtils";
import MultiSelectComponent from "../component";
import derivedProperties from "./parseDerivedProperties";
import {SelectWidgetProps} from "../../SelectWidget/widget";

export function defaultOptionValueValidation(
  value: unknown,
  props: MultiSelectWidgetProps,
  _: LoDashStatic,
): ValidationResponse {
  let isValid = false;
  let parsed: any[] = [];
  let message = {name: "", message: ""};
  const isServerSideFiltered = props.serverSideFiltering;
  // TODO: options shouldn't get un-eval values;
  let options = props.options;

  const DEFAULT_ERROR_MESSAGE = {
    name: "TypeError",
    message:
      "value should match: Array<string | number> | Array<{label: string, value: string | number}>",
  };
  const MISSING_FROM_OPTIONS = {
    name: "ValidationError",
    message:
      "Some or all default values are missing from options. Please update the values.",
  };
  const MISSING_FROM_OPTIONS_AND_WRONG_FORMAT = {
    name: "ValidationError",
    message:
      "Default value is missing in options. Please use [{label : <string | num>, value : < string | num>}] format to show default for server side data",
  };
  /*
   * Function to check if the object has `label` and `value`
   */
  const hasLabelValue = (obj: any) => {
    return (
      _.isPlainObject(obj) &&
      obj.hasOwnProperty("label") &&
      obj.hasOwnProperty("value") &&
      _.isString(obj.label) &&
      (_.isString(obj.value) || _.isFinite(obj.value))
    );
  };

  /*
   * Function to check for duplicate values in array
   */
  const hasUniqueValues = (arr: Array<string>) => {
    const uniqueValues = new Set(arr);

    return uniqueValues.size === arr.length;
  };

  /*
   * When value is "['green', 'red']", "[{label: 'green', value: 'green'}]" and "green, red"
   */
  if (_.isString(value) && value.trim() !== "") {
    try {
      /*
       * when value is "['green', 'red']", "[{label: 'green', value: 'green'}]"
       */
      const parsedValue = JSON.parse(value);
      // Only parse value if resulting value is an array or string
      if (Array.isArray(parsedValue) || _.isString(parsedValue)) {
        value = parsedValue;
      }
    } catch (e) {
      /*
       * when value is "green, red", JSON.parse throws error
       */
      const splitByComma = (value as string).split(",") || [];

      value = splitByComma.map((s) => s.trim());
    }
  }

  /*
   * When value is "['green', 'red']", "[{label: 'green', value: 'green'}]" and "green, red"
   */
  if (Array.isArray(value)) {
    if (value.every((val) => _.isString(val) || _.isFinite(val))) {
      /*
       * When value is ["green", "red"]
       */
      if (hasUniqueValues(value)) {
        isValid = true;
        parsed = value;
      } else {
        parsed = [];
        message = {
          name: "ValidationError",
          message: "values must be unique. Duplicate values found",
        };
      }
    } else if (value.every(hasLabelValue)) {
      /*
       * When value is [{label: "green", value: "red"}]
       */
      if (hasUniqueValues(value.map((val) => val.value))) {
        isValid = true;
        parsed = value;
      } else {
        parsed = [];
        message = {
          name: "ValidationError",
          message: "path:value must be unique. Duplicate values found",
        };
      }
    } else {
      /*
       * When value is [true, false], [undefined, undefined] etc.
       */
      parsed = [];
      message = DEFAULT_ERROR_MESSAGE;
    }
  } else if (_.isString(value) && value.trim() === "") {
    /*
     * When value is an empty string
     */
    isValid = true;
    parsed = [];
  } else if (_.isNumber(value) || _.isString(value)) {
    /*
     * When value is a number or just a single string e.g "Blue"
     */
    isValid = true;
    parsed = [value];
  } else {
    /*
     * When value is undefined, null, {} etc.
     */
    parsed = [];
    message = DEFAULT_ERROR_MESSAGE;
  }

  if (isValid && !_.isNil(parsed) && !_.isEmpty(parsed)) {
    if (!Array.isArray(options) && typeof options === "string") {
      try {
        const parsedOptions = JSON.parse(options);
        if (Array.isArray(parsedOptions)) {
          options = parsedOptions;
        } else {
          options = [];
        }
      } catch (e) {
        options = [];
      }
    }

    const parsedValue = parsed;
    const areValuesPresent = parsedValue.every((value) => {
      const index = _.findIndex(
        options,
        (option) => option.value == value || option.value == value.value,
      );
      return index !== -1;
    });

    if (!areValuesPresent) {
      isValid = false;
      if (!isServerSideFiltered) {
        message = MISSING_FROM_OPTIONS;
      } else {
        if (!parsed.every(hasLabelValue)) {
          message = MISSING_FROM_OPTIONS_AND_WRONG_FORMAT;
        } else {
          message = MISSING_FROM_OPTIONS;
        }
      }
    }
  }
  return {
    isValid,
    parsed,
    messages: [message],
  };
}

class MultiSelectWidget extends BaseWidget<MultiSelectWidgetProps,
  WidgetState> {
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc":
        "MultiSelect is used to capture user input/s from a specified list of permitted inputs. A MultiSelect captures multiple choices from a list of options",
      "!url": "https://docs.appsmith.com/widget-reference/dropdown",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      filterText: {
        "!type": "string",
        "!doc": "The filter text for Server side filtering",
      },
      selectedOptionValues: {
        "!type": "[string]",
        "!doc": "The array of values selected in a multi select dropdown",
        "!url": "https://docs.appsmith.com/widget-reference/dropdown",
      },
      selectedOptionLabels: {
        "!type": "[string]",
        "!doc":
          "The array of selected option labels in a multi select dropdown",
        "!url": "https://docs.appsmith.com/widget-reference/dropdown",
      },
      isDisabled: "bool",
      isValid: "bool",
      isDirty: "bool",
      options: "[$__dropdownOption__$]",
    };
  }

  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('MultiSelectWidgetV2');
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "标签",
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "labelText",
            label: "文本",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Sets the label position of the widget",
            propertyName: "labelPosition",
            label: "位置",
            controlType: "ICON_TABS",
            fullWidth: true,
            hidden: isAutoLayout,
            options: [
              {label: "自动", value: LabelPosition.Auto},
              {label: "居左", value: LabelPosition.Left},
              {label: "居上", value: LabelPosition.Top},
            ],
            defaultValue: LabelPosition.Left,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Sets the label alignment of the widget",
            propertyName: "labelAlignment",
            label: "对齐方式",
            controlType: "LABEL_ALIGNMENT_OPTIONS",
            fullWidth: false,
            options: [
              {
                startIcon: "align-left",
                value: Alignment.LEFT,
              },
              {
                startIcon: "align-right",
                value: Alignment.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
            hidden: (props: MultiSelectWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          },
          {
            helpText:
              "Sets the label width of the widget as the number of columns",
            propertyName: "labelWidth",
            label: "宽度（以列为单位）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
            hidden: (props: MultiSelectWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          },
        ],
      },
      {
        sectionName: "数据",
        children: [
          {
            helpText: "Select Dict.",
            propertyName: "options",
            label: "选项",
            controlType: "SELECT_DICT_CONTROL",
            placeholderText: '从数据字典中选择',
            isBindProperty: true,
            isTriggerProperty: false,
            selectId: (props: SelectWidgetProps) => props.selectId,
            dependencies: ["selectId", "defaultOptionLabel", "defaultOptionValue"]
          },
        ],
      },
      {
        sectionName: "设置",
        children: [
          {
            propertyName: "isFilterable",
            label: "允许搜索",
            helpText: "Makes the dropdown list filterable",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            helpText:
              "Controls the visibility of select all option in dropdown.",
            propertyName: "allowSelectAll",
            label: "是否开启全选",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      },
      {
        sectionName: "验证",
        children: [
          {
            propertyName: "isRequired",
            label: "是否必填",
            helpText: "Makes input to the widget mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      },
      {
        sectionName: "一般",
        children: [
          {
            helpText: "Sets a Placeholder Text",
            propertyName: "placeholderText",
            label: "引导文字",
            controlType: "INPUT_TEXT",
            placeholderText: "Search",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Show help text or details about current selection",
            propertyName: "labelTooltip",
            label: "提示",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isVisible",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isDisabled",
            label: "是否禁用",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isReadonly",
            label: "是否只读",
            helpText: "Readonly input to this widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "animateLoading",
            label: "加载动画",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      },


    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "labelTextColor",
            label: "字体颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelTextSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            hidden: isAutoLayout,
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "2xl",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3xl",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "输入文字",
        children: [
          {
            propertyName: "it_color",
            label: "字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "it_size",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            hidden: isAutoLayout,
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "it_style",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "输入框",
        children: [
          {
            propertyName: "ib_bgColor",
            label: "背景色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "ib_borderColor",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "ib_iconColor",
            label: "图标颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "accentColor",
            label: "强调色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
            invisible: true,
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
      it_color: "{{appsmith.theme.form.inputText.fontColor}}",
      it_size: "{{appsmith.theme.form.inputText.fontSize}}",
      it_style: "{{appsmith.theme.form.inputText.fontStyle}}",
      labelTextColor: "{{appsmith.theme.form.label.fontColor}}",
      labelTextSize: "{{appsmith.theme.form.label.fontSize}}",
      labelStyle: "{{appsmith.theme.form.label.fontStyle}}",
      ib_bgColor: "{{appsmith.theme.form.inputBorder.bgColor}}",
      ib_borderColor: "{{appsmith.theme.form.inputBorder.bgBorderColor}}",
    };
  }

  static getDerivedPropertiesMap() {
    return {
      value: `{{this.selectedOptionValues}}`,
      getValue: `{{this.selectedOptionValues}}`,
      getLabel: `{{this.selectedOptionLabels}}`,
      isValid: `{{(()=>{${derivedProperties.getIsValid}})()}}`,
      selectedOptionValues: `{{(()=>{${derivedProperties.getSelectedOptionValues}})()}}`,
      selectedOptionLabels: `{{(()=>{${derivedProperties.getSelectedOptionLabels}})()}}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedOptions: "defaultOptionValue",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedOptions: undefined,
      filterText: "",
      isDirty: false,
    };
  }

  componentDidUpdate(prevProps: MultiSelectWidgetProps): void {
    // Check if defaultOptionValue is string
    let isStringArray = false;
    if (
      this.props.defaultOptionValue && this.props.defaultOptionValue.some &&
      this.props.defaultOptionValue.some(
        (value: any) => isString(value) || isFinite(value),
      )
    ) {
      isStringArray = true;
    }

    const hasChanges = isStringArray
      ? xorWith(
      this.props.defaultOptionValue as string[],
      prevProps.defaultOptionValue as string[],
      equal,
    ).length > 0
      : xorWith(
      this.props.defaultOptionValue as OptionValue[],
      prevProps.defaultOptionValue as OptionValue[],
      equal,
    ).length > 0;
    if (hasChanges && this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", false);
    }
  }

  componentDidMount() {
  }

  handleOptions = ()=>{
    const opts = isArray(this.props.options) ? this.props.options : [];
    let optsMap:any = [];
    _.each(opts, (i:any)=>{
      let opt = {
        label: i.label,
        value: i.value
      };
      _.each(i, (value:any, key:any)=>{
        if(_.isArray(value)){
          return true;
        }
        if(key.endsWith('.label')){
          opt.label = value;
        }
        if(key.endsWith('.value')){
          opt.value = value;
        }
      })
      if(opt.label&&opt.value){
        optsMap.push(opt);
      }
    })
    return optsMap;
  }
  getPageView() {
    const options = this.handleOptions();
    const minDropDownWidth = MinimumPopupRows * this.props.parentColumnSpace;
    const {componentWidth} = this.getComponentDimensions();
    const values = this.props.selectedOptions||[];//this.mergeLabelAndValue();
    const isInvalid =
      "isValid" in this.props && !this.props.isValid && !!this.props.isDirty;
    return (
      <MultiSelectComponent
        accentColor={this.props.accentColor}
        allowSelectAll={this.props.allowSelectAll}
        borderRadius={this.props.borderRadius}
        boxShadow={this.props.boxShadow}
        compactMode={
          !(
            (this.props.bottomRow - this.props.topRow) /
            GRID_DENSITY_MIGRATION_V1 >
            1
          )
        }
        disabled={this.props.isDisabled ?? false}
        readonly={this.props.isReadonly ?? false}
        dropDownWidth={minDropDownWidth}
        dropdownStyle={{
          zIndex: Layers.dropdownModalWidget,
        }}
        filterText={this.props.filterText}
        isDynamicHeightEnabled={isAutoHeightEnabledForWidget(this.props)}
        isFilterable={this.props.isFilterable}
        isValid={!isInvalid}
        labelAlignment={this.props.labelAlignment}
        labelPosition={this.props.labelPosition}
        labelStyle={this.props.labelStyle}
        labelText={this.props.labelText}
        labelTextColor={this.props.labelTextColor}
        labelTextSize={this.props.labelTextSize}
        labelTooltip={this.props.labelTooltip}
        labelWidth={this.getLabelWidth()}
        loading={this.props.isLoading}
        onChange={this.onOptionChange}
        onDropdownClose={this.onDropdownClose}
        onDropdownOpen={this.onDropdownOpen}
        onFilterChange={this.onFilterChange}
        options={options}
        placeholder={this.props.placeholderText as string}
        renderMode={this.props.renderMode}
        serverSideFiltering={this.props.serverSideFiltering}
        value={values}
        widgetId={this.props.widgetId}
        width={componentWidth}
        isRequired={this.props.isRequired}
        it_color={this.props.it_color}
        it_size={this.props.it_size}
        it_style={this.props.it_style}
        ib_bgColor={this.props.ib_bgColor}
        ib_borderColor={this.props.ib_borderColor}
        ib_iconColor={this.props.ib_iconColor}
      />
    );
  }

  onOptionChange = (value: DraftValueType) => {
    const _str = super.reDynamicStringFn(this.props.onOptionChange as string);
    this.props.updateWidgetMetaProperty("selectedOptions", value, {
      triggerPropertyName: "onOptionChange",
      dynamicString: _str,
      event: {
        type: EventType.ON_OPTION_CHANGE,
      },
    });
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
  };

  // { label , value } is needed in the widget
  mergeLabelAndValue = (): LabelInValueType[] => {
    if (!this.props.selectedOptionLabels || !this.props.selectedOptionValues) {
      return this.props.selectedOptions||[];
    }
    let labels = [...this.props.selectedOptionLabels];
    let values = [...this.props.selectedOptionValues];
    let _ids: any = [];
    if (this.props.selectedOptions?.length > 0 && (labels.length == 0 || values.length == 0)) {
      let _labels: any = [];
      let _values: any = [];

      _.each(this.props.defaultOptionValue, (i: any) => {
        _.each(this.props.options, (j: any) => {
          if (i == j.value) {
            _labels.push(j.label);
            _values.push(j.value);
            _ids.push(j.id);
          }
        })
      })
      labels = _labels;
      values = _values;
    }
    return values.map((value, index) => ({
      value,
      id: _ids[index] ?? value,
      label: labels[index],
    }));
  };

  onFilterChange = (value: string) => {
    this.props.updateWidgetMetaProperty("filterText", value);

    if (this.props.onFilterUpdate && this.props.serverSideFiltering) {
      super.executeAction({
        triggerPropertyName: "onFilterUpdate",
        dynamicString: this.props.onFilterUpdate,
        event: {
          type: EventType.ON_FILTER_UPDATE,
        },
      });
    }
  };

  onDropdownOpen = () => {
    if (this.props.onDropdownOpen) {
      super.executeAction({
        triggerPropertyName: "onDropdownOpen",
        dynamicString: this.props.onDropdownOpen,
        event: {
          type: EventType.ON_DROPDOWN_OPEN,
        },
      });
    }
  };

  onDropdownClose = () => {
    if (this.props.onDropdownClose) {
      super.executeAction({
        triggerPropertyName: "onDropdownClose",
        dynamicString: this.props.onDropdownClose,
        event: {
          type: EventType.ON_DROPDOWN_CLOSE,
        },
      });
    }
  };

  static getWidgetType(): WidgetType {
    return "MULTI_SELECT_WIDGET_V2";
  }
}

export interface OptionValue {
  label: string;
  value: string;
}

export interface DropdownOption extends OptionValue {
  disabled?: boolean;
}

export interface MultiSelectWidgetProps extends WidgetProps {
  placeholderText?: string;
  selectedIndex?: number;
  selectedIndexArr?: number[];
  selectedOption: DropdownOption;
  options?: DropdownOption[];
  onOptionChange: string;
  onFilterChange: string;
  onDropdownOpen?: string;
  onDropdownClose?: string;
  defaultOptionValue: string[] | OptionValue[];
  isRequired: boolean;
  isLoading: boolean;
  selectedOptions: LabelInValueType[];
  filterText: string;
  selectedOptionValues?: string[];
  selectedOptionLabels?: string[];
  serverSideFiltering: boolean;
  onFilterUpdate: string;
  allowSelectAll?: boolean;
  isFilterable: boolean;
  labelText: string;
  labelPosition?: LabelPosition;
  labelAlignment?: Alignment;
  labelWidth?: number;
  isDirty?: boolean;
}

export default MultiSelectWidget;
