import React, {useEffect, useState} from 'react';
import {AtList, AtListItem} from "taro-ui";
import {Picker} from "@tarojs/components";
import {PickerWrapper} from "./MonthPicker";

const TimeWidget = (props:any) => {
  const {label, value, onDateChange, isVisible} = props;
  const reValue = (v:any)=>{
    if(v.indexOf(':')!==2){
      return '00:00';
    }
    return v;
  }
  return (
    <PickerWrapper isVisible={isVisible}>
      <Picker mode='time' onChange={(e:any)=>{
        onDateChange(e.detail.value);
      }}>
        <AtList>
          <AtListItem title={label} extraText={reValue(value)} />
        </AtList>
      </Picker>
    </PickerWrapper>
  );
};

export default TimeWidget;
