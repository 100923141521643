import React from 'react';
import ImportExcelWidget from "@byk/pages/components/ImportExcelWidget";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "antd";
import {X_IMPORT_EXCEL} from "@byk/store/XReducers";
import {xExecuteTrigger} from "../../../actions/widgetActions";
import {uuid2} from "@byk/utils/Utils";
function XImportExcelWidget(){
  const dispatch = useDispatch();
  const { visible, config } = useSelector((state:any)=>{
    return state.XReducers.xImportExcel;
  })
  const onCancel = ()=>{
    dispatch({
      type: X_IMPORT_EXCEL,
      data: {
        visible: false
      }
    })
  }
  const successBack = ()=>{
    dispatch(xExecuteTrigger({
      dynamicString: config.success
    }))
  }
  const failBack = ()=>{
    dispatch(xExecuteTrigger({
      dynamicString: config.fail
    }))
  }
  if(config.actionId){
    return (
        <Modal title={<span style={{ fontSize: "20px", color: "#101010" }}>导入数据</span>}
               centered
               visible={visible}
               onCancel={onCancel}
               onOk={successBack}
               width={1000}
               wrapClassName={`x-ImportDataComponent`}
               footer={null}
        >
          <ImportExcelWidget key={uuid2()} successBack={successBack} failBack={failBack} onModalClose={onCancel} actionId={config.actionId} />
        </Modal>
    )
  }
  return null;
}
export default XImportExcelWidget;
