import {Button, Form, Input, message, Row} from 'antd';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

import {ModelHeaderWrapper, ModelProps} from "@byk/pages/modeling/MxGraph/CreateModel";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import _ from 'lodash';
import {
  MODEL_DESCRIPTION_PATTERN,
  MODEL_DESCRIPTION_PATTERN_MSG,
  MODEL_NAME_PATTERN,
  MODEL_NAME_PATTERN_MSG,
  MODEL_PROPERTY_NAME_PATTERN,
  MODEL_PROPERTY_NAME_PATTERN_MSG
} from "@byk/pages/modeling/ModelDataUtil";
import DataBackup from "@byk/pages/modeling/MxGraph/DataBackup";
import {useDispatch, useSelector} from "react-redux";
import {getDynamicModel} from "@byk/pages/modeling/modelSelector";
import {setCurDynamicModel} from "@byk/store/modelingReducers";
import styled from "styled-components";
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import {DelConfirm} from "@byk/pages/modeling/MxGraph/DelConfirm";
import {graphModel} from "@byk/pages/modeling/MxGraph/ModelingMx";

export const ButtonWrapper = styled(Button)`
  height: 40px;
  border-radius: 5px;
  margin:10px 8px 0 0;

  &.delBtn {
    color: #5A5A5A !important;
  }

  &.delBtn .i-icon-delete{
    height: 14px;
  }

  &.delBtn:hover,
  &.delBtn:hover .i-icon-delete path{
    stroke: rgb(0, 108, 242) !important;
    color: rgb(0, 108, 242) !important;
  }
`

const ModelBasicInfo: React.FC<ModelProps> = forwardRef((
      props, ref
    ) => {
      useImperativeHandle(ref, () => ({
        saveModel,
      }));
      const {excelModel} = props;
      const model = excelModel != null ? excelModel : useSelector(getDynamicModel);
      const dispatch = useDispatch();
      const [form] = Form.useForm();
      const formRef = useRef<any>();
      const dataBackupRef = useRef<any>();
      const delConfirmRef = useRef<any>();
      const [deleteDisable, setDeleteDisable] = useState<boolean>(false);
      const [name, setName] = useState<string>(model.name);
      const [description, setDescription] = useState<string>(model.description);

      useEffect(() => {
        setDeleteDisable(!model.id);
        setTimeout(() => {
          formRef.current.setFieldsValue({
            name: model.name,
            description: model.description,
          });
          setName(model.name);
          setDescription(model.description);
        }, formRef.current ? 0 : 100);
      }, [model])

      useEffect(() => {
        model.name = name;
        model.description = description;
        doDispatchSetCurDynamicModel(model);
      }, [name, description])

      const doDispatchSetCurDynamicModel = (model: any) => {
        if (!excelModel) {
          dispatch(setCurDynamicModel(model));
        }
      }

      const saveModel = async (closeFlag?: boolean) => {
        if (!await props.modelPropertyRef.current.formValid()) {
          message.error("请检查属性称信息是否录入正确！");
          return;
        }

        let newModelData = props.modelPropertyRef && props.modelPropertyRef.current?.getModelData();
        let modelInfo = newModelData || model;
        let properties = modelInfo.properties || [];
        let requiredCheck = true;
        _.forEach(properties, item => {
          if (requiredCheck && (!item.name || !item.description)) {
            message.error("属性名称，属性编码必填！");
            requiredCheck = false;
          }
          if (requiredCheck && !MODEL_PROPERTY_NAME_PATTERN.test(item.name)) {
            message.error("属性编码 " + MODEL_PROPERTY_NAME_PATTERN_MSG);
            requiredCheck = false;
          }
        });

        if (requiredCheck) {
          form.validateFields().then(
            async (values: any) => {
              let modelData = {
                ...model,
                ...values,
                tag: 'default',
                properties: [...properties]
              }
              let isNew = !modelData.id;
              const canSave = await openDataBackup(modelData, isNew);
              if (canSave) {
                await doSaveModel(modelData, isNew, closeFlag);
              }
            }
          )
        }
      }

      const doSaveModel = async (modelData: any, isNew: boolean, closeFlag?: boolean) => {
        props.setSpinning && props.setSpinning(true);
        let res: any = await ModelingApi.doSaveModel(modelData);
        setDeleteDisable(false);
        if (res.success) {
          let resultModelData = res.result[0];
          if (isNew) {
            props.mxAndModel && props.mxAndModel(resultModelData);
          } else {
            props.mxUpdateModel && props.mxUpdateModel(resultModelData);
          }
          props.updateModelState && props.updateModelState(resultModelData);
          props.modelPropertyRef.current?.updateModel(resultModelData);
          doDispatchSetCurDynamicModel(resultModelData);
          message.success("保存成功");
          if (closeFlag) {
            props.closeMode && props.closeMode();
          }
        }
        props.setSpinning && props.setSpinning(false);
      }

      const openDataBackup = async (model: any, isNew: boolean) => {
        if (isNew) {
          return true;
        }
        let res: any = await ModelingApi.doApiGetNeedDelColumn(model);
        if (res.success) {
          let needDropColumns = res.result;
          if (needDropColumns.length > 0) {
            dataBackupRef.current?.showModal(needDropColumns, model, isNew);
            return false;
          } else {
            return true;
          }
        } else {
          console.error(res);
          return false;
        }
      }

      const onClickDel = async () => {
        delConfirmRef.current?.showModal();
      }

      const deleteModel = async () => {
        props.mxDeleteModel && await props.mxDeleteModel();
        doDispatchSetCurDynamicModel({});
        props.closeMode && props.closeMode();
      }

      return (
        <>
          <ModelHeaderWrapper><span>基本信息</span></ModelHeaderWrapper>

          <Form
            style={{marginLeft: '21px'}}
            ref={formRef}
            form={form}
            wrapperCol={{span: 12}}
            autoComplete="off"
            initialValues={{name, description}}
          >
            <Row>
              <FormItemWrapper
                label="模型编码"
                name="name"
                rules={[
                  {required: true, message: ''},
                  {
                    pattern: MODEL_NAME_PATTERN,
                    message: MODEL_NAME_PATTERN_MSG,
                  },
                  {
                    min: 0,
                    max: 30,
                    message: '模型编码不能超过30个字符!',
                  },
                  {
                    validator: (rule: any, value: any, callback: any) => {
                      let modelExist = graphModel.getModel(value);
                      if (modelExist && modelExist.id && modelExist.id !== model.id) {
                        callback('模型编码已存在!');
                      } else {
                        callback();
                      }
                    }
                  }
                ]}
              >
                <Input disabled={model.id} style={{...FormItemInputStyle}}
                       onInput={(e: any) => setName(e.target.value)}/>
              </FormItemWrapper>

              <FormItemWrapper
                label="模型名称"
                name="description"
                rules={[
                  {required: true, message: ''},
                  {
                    pattern: MODEL_DESCRIPTION_PATTERN,
                    message: MODEL_DESCRIPTION_PATTERN_MSG
                  },
                  {
                    min: 0,
                    max: 60,
                    message: '模型名称不能超过60个字符!',
                  },
                ]}
                style={{marginLeft: '52px'}}
              >
                <Input style={{...FormItemInputStyle}}
                       onInput={(e: any) => setDescription(e.target.value)}/>
              </FormItemWrapper>
            </Row>
          </Form>

          <div style={{
            position: "absolute",
            bottom: "10px",
            borderTop: '1px solid rgba(228, 232, 235, 1)',
            background: 'white',
            zIndex: 1000,
            width: `calc(100% - ${excelModel ? '260px' : '171px'})`,
          }}>
            <div style={{display: "flex", justifyContent: 'space-between'}}>
              <div style={{marginLeft: "28px"}}>
                {model?.id &&
                <ButtonWrapper
                  className='delBtn' icon={getIconPark("Delete", "#5A5A5A")} type={"link"}
                  onClick={onClickDel}
                  disabled={!model.id && deleteDisable}
                >
                  删除模型
                </ButtonWrapper>
                }

                <DelConfirm delCb={deleteModel} ref={delConfirmRef}/>
              </div>
              <div style={{marginRight: '16px'}}>
                <ButtonWrapper
                  onClick={() => {
                    props.closeMode && props.closeMode();
                  }}
                >
                  取消
                </ButtonWrapper>
                <ButtonWrapper
                  type={"primary"}
                  onClick={async () => {
                    await saveModel(false)
                  }}
                >
                  {model.id ? "更新" : "保存"}
                </ButtonWrapper>
                <ButtonWrapper
                  type={"primary"}
                  onClick={async () => {
                    await saveModel(true)
                  }}
                >
                  {model.id ? "更新并关闭" : "保存并关闭"}
                </ButtonWrapper>
              </div>
            </div>
          </div>

          <DataBackup
            ref={dataBackupRef}
            saveModel={async (modelData: any, isNew: boolean) => {
              await doSaveModel(modelData, isNew);
              props.closeMode && props.closeMode();
            }}
          />
        </>
      );
    }
  )
;

export default ModelBasicInfo;


export const FormItemWrapper = styled(Form.Item)`
  .ant-form-item-label>label {
     height: 40px;
  }
`

export const FormItemInputStyle = {
  width: "390px",
  height: "40px",
};
