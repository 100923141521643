import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TDividerWidget from "../component";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";

class TDivider extends TaroWidget<TDividerWidgetProps, WidgetState> {
  constructor(props: TDividerWidgetProps) {
    super(props);
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "样式",
        children: [
          {
            propertyName: "lineColor",
            label: "颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          }
        ]
      }
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.label }}`
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }
  getPageView() {
    return (
      <TDividerWidget {...this.props} />
    );
  }
  static getWidgetType(): WidgetType {
    return "AT_DIVIDER_WIDGET";
  }
}

export interface TDividerWidgetProps extends WidgetProps {
  value: any[];
  label: string;
  onClick?: any;
  borderRadius: string;
  boxShadow?: string;
}

export default TDivider;
