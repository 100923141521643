import React from 'react';
import {AtList, AtListItem} from "taro-ui";
import {Picker} from "@tarojs/components";
import {PickerWrapper} from "./MonthPicker";

const DateWidget = (props:any) => {
  const {label, value, onDateChange, isVisible} = props;
  const reValue = (v:any)=>{
    return v;
  }
  return (
    <PickerWrapper isVisible={isVisible}>
      <Picker mode='date' onChange={(e:any)=>{
        onDateChange(e.detail.value);
      }} value={value}>
        <AtList>
          <AtListItem title={label} extraText={reValue(value)} />
        </AtList>
      </Picker>
    </PickerWrapper>
  );
};

export default DateWidget;
