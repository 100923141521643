import React from "react";
import CarouselWidget from '../component';
import BaseWidget, { WidgetState } from "../../../widgets/BaseWidget";
import { WidgetType } from "../../../constants/WidgetConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { EventType, type ExecutionResult } from "constants/AppsmithActionConstants/ActionConstants";
import { GRID_DENSITY_MIGRATION_V1 } from "widgets/constants";
import {
  isAutoHeightEnabledForWidget
} from "widgets/WidgetUtils";
import type { DerivedPropertiesMap } from "utils/WidgetFactory";
import {Stylesheet} from "../../../entities/AppTheming";
class Carousel extends BaseWidget<any, WidgetState> {
  constructor(props: any) {
    super(props);
  }
  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('SearchWidget');
  }
  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "labelText",
            label: "标签",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Sets a placeholder text for the input",
            propertyName: "placeholderText",
            label: "引导文字",
            controlType: "INPUT_TEXT",
            placeholderText: "Placeholder",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isVisible",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }
  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "基本",
        children: [
          {

            propertyName: "size",
            label: "组件大小",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              { label: "大", value: "large" },
              { label: "中", value: "middle" },
              { label: "小", value: "small" },
            ],
            defaultValue: "large",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "输入框",
        children: [
          {
            propertyName: "inputBackgroundColor",
            helpText: "Sets the icon color of the widget",
            label: "背景色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "inputBorderColor",
            helpText: "Changes the color of the recorder button",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ]
      },
      {
        sectionName: "图标",
        children: [
          {
            propertyName: "iconColor",
            helpText: "Sets the icon color of the widget",
            label: "颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "iconBackGroundColor",
            helpText: "Changes the color of the recorder button",
            label: "背景色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ]
      },
      {
        sectionName: "边框&阴影",
        children: [
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.value }}`,
      getValue: `{{ this.value }}`,
    };
  }
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      value: ''
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue"
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }


  getPageView() {
    return (
      <>
        <CarouselWidget value={this.props.value} widgetId={this.props.widgetId} key={this.props.widgetId} {...this.props} isDynamicHeightEnabled={isAutoHeightEnabledForWidget(this.props)}
          labelWidth={this.getLabelWidth()} compactMode={!((this.props.bottomRow - this.props.topRow) / GRID_DENSITY_MIGRATION_V1 > 1)}
          onChange={this.onChange} onClick={this.onClick} onEnter={this.onEnter} onValueChange={this.onValueChange} />
      </>
    )
  }

  onValueChange = (updatedValue: any) => {
    console.log(this.props);
    this.props.updateWidgetMetaProperty("value", updatedValue);
  };

  handleActionResult = (result: ExecutionResult) => { };
  onChange = (val: any) => {
    const _str = super.reDynamicStringFn(this.props.onTextChanged as string);
    this.props.updateWidgetMetaProperty(
      "value",
      val, {
        triggerPropertyName: "onTextChanged",
        dynamicString: _str,
        event: {
          type: EventType.ON_TEXT_CHANGE,
        },
      }
    );
  };

  onEnter = (val: any) => {
    this.props.updateWidgetMetaProperty(
      "value",
      val
    );
    if (this.props.onEnter) {
      super.executeAction({
        triggerPropertyName: "onEnter",
        dynamicString: this.props.onEnter,
        event: {
          type: EventType.ON_ENTER,
          callback: this.handleActionResult,
        },
      });
    }
  };

  onClick = (val: any) => {
    this.props.updateWidgetMetaProperty(
      "value",
      val
    );
    if (this.props.onClick) {
      const _str = super.reDynamicStringFn(this.props.onClick as string);
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: _str,
        event: {
          type: EventType.ON_CLICK,
          callback: this.handleActionResult,
        },
      });
    }
  };

  static getWidgetType(): WidgetType {
    return 'SEARCH_WIDGET';
  }
}

export default Carousel;
