import GraphModel from "@byk/pages/modeling/lib/GraphModel";
import _ from "lodash";
import {getModelPk, isPk} from "@byk/pages/modeling/ModelDataUtil";
import {PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";

/**
 * 模型关系模型
 */
export class PropertyRelationModel {
  private _graphModel: GraphModel;
  private readonly _model: any;
  private _relationList: any = [];
  private readonly _relationDbCache: any = {};

  constructor(graphModel: GraphModel, model: any) {
    this._graphModel = graphModel;
    this._model = model;
    this._relationList = [];
    this._relationDbCache = {};

    let relationList: any = [];
    let relationCache: any = {};
    _.forEach(model.properties, (item) => {
      if (item.type == PROPERTY_REL_TYPE.O2M || item.type == PROPERTY_REL_TYPE.M2O) {
        relationList.push({
          ...item,
          key: item.id,
        });
        relationCache[item.id + ''] = item;
      }
    });

    this._relationList = [...relationList];
    this._relationDbCache = {...relationCache};
  }

  getProperties() {
    return this._model?.properties || [];
  }

  getModels() {
    return this._graphModel.getModels();
  }

  getModel() {
    let relationIdMap = _.keyBy(this._relationList || [], "id");
    let propertyIdMap = _.keyBy(this._model.properties || [], "id");
    let proList: any = [];
    // 更新
    _.forEach(this._model.properties, (item) => {
      let mappingItem = relationIdMap[item.id] || item;
      proList.push({
        ...item,
        ...mappingItem,
        key: item.id,
      });
    })

    // 新增
    _.forEach(this._relationList, (item) => {
      if (item.id && !propertyIdMap[item.id]) {
        proList.push(item);
      }
    })

    this._model.properties = [...proList];
    return this._model;
  }

  /**
   * 获取属性的下拉列表
   */
  getPropertyOptions() {
    let options: any = [];
    _.forEach(this.getProperties(), (item) => {
      options.push({
        "label": item.description,
        "value": item.name,
      });
    });
    return options;
  }

  getTargetModelOptions() {
    let options: any = [];
    _.forEach(this.getModels(), (item) => {
      options.push({
        "label": item.description,
        "value": item.name,
      });
    });
    return options;
  }

  getTargetModelPropertyOptions(key: any, values: any = {}) {
    let targetModel = this._graphModel.getModel(values.targetModel);
    let properties = targetModel && targetModel.properties;
    let mappedByMap = _.keyBy(this._relationList, "mappedBy");
    let modelPk = getModelPk(this.getProperties());
    let options: any = [];
    _.forEach((properties || []), (item) => {
      /*
      1. 属性没有关联过
      2. 不是主键
      3. 与模型主键类型一致
      4. 没有relationList列表中
      或者是 当前选项
       */
      let itemType = this._graphModel.getPropertyType(item);
      if ((!item.targertModel && !isPk(item) && modelPk.type == itemType && !mappedByMap[item.name] && !item.targetModel)
        || (mappedByMap[item.name] && key == mappedByMap[item.name].key)) {
        options.push({
          "label": item.description,
          "value": item.name,
        });
      }
    });

    return options;
  }

  addRelationProperty(newData: any) {
    this._relationList.push(newData);
    return this._relationList;
  }

  getRelationProperties(selectedKeys?: any) {
    let properties: any = [];
    _.forEach(this._relationList, (item: any) => {
      if (!selectedKeys || selectedKeys.indexOf(item.key) >= 0) {
        let dbItem = this._relationDbCache[item.key + ''] || {};
        properties.push({
          ...dbItem,
          ...item,
          model: this._model.name,
          modelId: this._model.id,
        });
      }
    })
    return properties;
  }

  removeProperties(keys: any) {
    let pro = _.filter(this._model.properties, (item) => {
      return keys.indexOf(item.key) == -1
    });

    this._model.properties = [...pro];
  }

  removeRelationProperty(keys: any) {
    let list = _.filter(this._relationList, function (o) {
      return keys.indexOf(o.key) == -1;
    });

    this._relationList = [...list];
    return this._relationList;
  }

  updateRelationProperty(relationProperty: any) {
    let list: any = [];
    let existed = false;
    _.forEach(this._relationList, (item: any) => {
      if (item.key == relationProperty.key) {
        list.push({...relationProperty});
        existed = true;
      } else {
        list.push(item);
      }
    })
    if (!existed) {
      list.push({...relationProperty});
    }
    this._relationList = [...list];
  }

  /**
   * 检查属性关系是否可以保存
   * @param selectedKeys
   */
  relationCanSave(selectedKeys: any) {
    let ret = true;
    this._relationList.forEach((value: any) => {
      if (ret && selectedKeys.indexOf(value.key) > -1) {
        ret = ret && !_.isEmpty(value.name) && !_.isEmpty(value.description)
          && !_.isEmpty(value.targetModel) && !_.isEmpty(value.mappedBy) && !_.isEmpty(value.type);
      }
    })

    return ret && selectedKeys.length;
  }

  updateRelationPropertyAfterSave(result: any) {
    let relationList: any = [];
    let keyMap = _.keyBy(result, "key");
    _.forEach(this._relationList, (item) => {
      if (keyMap[item.key]) {
        relationList.push({
          ...item,
          ...keyMap[item.key],
        });
      } else {
        relationList.push(item);
      }
    });

    this._relationList = [...relationList];
  }
}
