import {Button, Col, Form, Input, Row, Select, Space} from "antd";
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import _ from "lodash";
import DmTable from "@byk/pages/modeling/ModelData/DmTable";
import {buildSearchOptions, buildTableProps} from "@byk/pages/modeling/ModelData/config";
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import styled from "styled-components";
import ModelTree from "@byk/pages/modeling/MxGraph/ModelTree";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import moment from "moment";
import {downloadModelData} from "@byk/pages/modeling/ModelDataUtil";
import DataRecovery from "@byk/pages/modeling/MxGraph/DataRecovery";
import UiFormModal from "@byk/pages/modeling/ModelData/DynamicForm/UiFormModal";

const ContainerDiv = styled.div`
  padding: 24;
  margin: 0;
  height: 100%;
  color: #151515;

 .breadcrumb {
    display: flex;
    color: #101010;
    padding-left: 25px;
    padding-bottom: 10px;
    padding-top: 10px;

    button {
      height: 33px;
      width: 118px;
      padding: 0px;
      span.iconPark {
        height: 13px;
      }
    }

    .title {
      margin-left: 12px;
      font-size: 20px;
    }

    .noSelectedModel {
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      color: #a00606;
    }

    .selectedModel {
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 0 12px;
    }
  }

  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    background: #f5f5f5 !important;
    border-color: var(--primary-color10) !important;
    background: var(--primary-color70) !important;
  }
`

const formItemLayout = {
  labelCol: {span: 10},
  wrapperCol: {span: 14},
}

const ModelBizDataQuery: React.FC<any> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    init,
    close,
    destroy,
    onSelectedData,
    search4UiForm,
  }))
  const {addToForm, selectedId} = props;
  const [tableProps, setTableProps] = useState<any>()                   // 当前模型的Table选项
  const [searchOptions, setSearchOptions] = useState<any[]>()           // 当前模型的查询条件选项/
  const [searchOptionVal, setSearchOptionVal] = useState<any[]>()       // 当前模型的选择的查询条件
  const in2ScriptIdsRef = useRef<any>([])                     // 需要加入脚本的数据ID
  const selectRef = useRef<any>();
  const tableRef = useRef<any>();
  const dataRecoveryRef = useRef<any>();
  const uiFormModalRef = useRef<any>();
  const formContainerRef = useRef<any>();
  const containerDivRef = useRef<any>();
  const [form] = Form.useForm();
  const [model, setModel] = useState<any>()
  const [modelTreeData, setModelTreeData] = useState<any>([])
  const [modelByName, setModelByName] = useState<any>({})
  const [searchCondition, setSearchCondition] = useState<any>()
  const [ids, setIds] = useState<any>([])
  const [selectedData, setSelectedData] = useState<any>([]);

  const initCallback = (m: any, ids: any = []) => {
    setModel(m);
    setIds(ids);
    setSelectedData([]);
    setTableProps({...buildTableProps(m), selectedIds: ids})
    setSearchOptions(buildSearchOptions(m))
    in2ScriptIdsRef.current = ids
    setSearchOptionVal([])
  }

  const search4UiForm = () => {
    if (addToForm && selectedId) {
      setSearchOptionVal(['id']);
      form.setFieldValue('id', selectedId);
      doRefreshTable({id: selectedId});
    }
  }

  const init = (m: any, ids: any = []) => {
    if (!m) {
      ModelingApi.doGetModelList().then((res: any) => {
        let modelList = res.result || [];
        let modelMapByName: any = {};
        modelList.forEach((item: any) => modelMapByName[item.name] = item);
        setModelByName(modelMapByName);
        setModelTreeData(res.result || []);
        initCallback(m, ids);
      });
    } else {
      initCallback(m, ids);
    }
  }

  const destroy = () => {
    setSelectedData([]);
    setModel(null)
    setTableProps(null)
    setSearchOptions([])
    setSearchOptionVal([])
    tableRef.current?.cleanData()
  }

  const onSelectedData = () => {
    props.addToScript && props.addToScript(in2ScriptIdsRef.current)
    addToForm && addToForm(in2ScriptIdsRef.current);
    destroy();
  }

  const doRefreshTable = (searchData: any = {}) => {
    if (model && model.id) {
      tableRef.current?.getTableList({...searchData});
    }
  }

  const onFinish = (v: any) => {
    for (let val of Object.keys(v)) {
      if (v[val] == undefined) {
        v[val] = '';
      }
    }

    setSearchCondition(JSON.parse(JSON.stringify(v)));
    doRefreshTable(JSON.parse(JSON.stringify(v)));
  }

  const Crud = useCallback(() => {
    const selectedDataCallback = async (data: any, callback: any) => {
      // 当前页的ID集合
      let curPageDataIds: any = []
      _.forEach(tableRef.current.getTableData() || [], item => curPageDataIds.push(item.id))
      // 非当前页的已选择的ID集合
      let notInPageDataIds: any = _.pullAll(in2ScriptIdsRef.current || [], curPageDataIds)
      // 当前页选择的ID
      let dataIds: any = []
      _.forEach(data || [], item => dataIds.push(item.id))
      in2ScriptIdsRef.current = _.uniq(notInPageDataIds.concat(dataIds))
      setIds(in2ScriptIdsRef.current);
      setSelectedData(data);
      callback(in2ScriptIdsRef.current)
    }

    let scrollYDiff = (containerDivRef.current?.offsetHeight - formContainerRef.current?.offsetHeight - 120) || 360;
    return (
      <DmTable
        ref={tableRef}
        selectType={addToForm ? 'radio' : 'checkbox'}
        {...tableProps}
        scroll={{y: scrollYDiff}}
        selectedDataCallback={selectedDataCallback}
      />
    )
  }, [tableProps, searchOptionVal])

  const onSelectedModel = (item: any) => {
    init(item);
  }

  const goBack = () => {
    props.goToModeling && props.goToModeling();
  }

  const exportData = async () => {
    let modelName = model.name;
    let excelName = "模型(" + modelName + ")数据_" + moment(new Date()).format("yyyyMMDD") + ".xlsx";
    downloadModelData(modelName, excelName, searchCondition);
  }

  const importData = () => {
    dataRecoveryRef.current?.showModal(model);
  }

  const deleteData = () => {
    if (in2ScriptIdsRef.current && in2ScriptIdsRef.current.length > 0) {
      ModelingApi.doDeleteModelDataById(model, in2ScriptIdsRef.current || []).then(() => {
        doRefreshTable(searchCondition);
      })
    }
  }

  const addModelData = () => {
    uiFormModalRef.current?.showModal(model);
  }

  const updateModelData = () => {
    if ( selectedData?.length == 1) {
      let _selectedData = selectedData?.length == 1 ? selectedData[0] : null;
      let tableData = tableRef.current.getTableData() || [];
      let newModelData =  tableData.find((item:any) => item.id == _selectedData.id);
      uiFormModalRef.current?.showModal(model, newModelData);
    }
  }

  let isDataMgr = props.dataMgrFlag;
  const style:any = {
    display: 'block', margin: 0, height: "100%"
  }
  if (isDataMgr) {
    style.width = 'calc(100% - 260px)';
    style.float = 'right';
  }

  return (
    <ContainerDiv ref={containerDivRef}>
      {isDataMgr &&
      <div style={{width: '260px', float:'left'}}>
        <ModelTree
          style={{
            height: "calc(100vh - 118px)",
            contentH: 'calc(100vh - 190px)',
          }}
          models={modelTreeData}
          onSelectedItem={onSelectedModel}
        />
      </div>
      }

      <div style={{...style}}>
        <div ref={formContainerRef}>
          {props.dataMgrFlag &&
          <div className='breadcrumb'>
            <Button icon={getIconPark('Left')} onClick={goBack}>返回业务建模</Button>
            {model?.id && <div className='selectedModel'>当前选择模型：{model?.description}</div>}
            {!model?.id && <div className='noSelectedModel'>请选择模型</div>}
          </div>
          }
          <Form
            style={{paddingRight: '100px', backgroundColor: '#F7F7F7', marginBottom: "19px"}}
            layout={"inline"}
            {...formItemLayout}
            form={form}
            autoComplete="off"
            onFinish={onFinish}
          >
            <div className={'x-mb10'} style={{marginTop: '8px',}}>
              <Space>
                <AddOneBtn>{getIconPark("AddOne")}添加筛选条件</AddOneBtn>
                <Select
                  ref={selectRef}
                  {...{
                    mode: 'multiple',
                    style: {width: '620px'},
                    value: searchOptionVal,
                    options: searchOptions,
                    onChange: (newValue: string[]) => {
                      setSearchOptionVal(newValue);
                    },
                    maxTagCount: 'responsive',
                  }} />
              </Space>
            </div>

            <Row style={{width: '100%'}}>
              {searchOptionVal && searchOptionVal.map((sv: any) => {
                  let item: any = _.head(_.filter(searchOptions, item => item.value == sv))
                  return (
                    <Col className={'x-mb10'} span={6}>
                      <Form.Item label={item.label} name={item.value}>
                        <Input/>
                      </Form.Item>
                    </Col>
                  )
                }
              )}

              <Col span={6} className={'x-mb10'}>
                <>
                  <Button
                    disabled={!model?.id}
                    size={"small"} className='x-ml25' type="primary"
                    htmlType="submit">
                    查询
                  </Button>
                  <Button
                    disabled={!model?.id}
                    size={"small"} className='x-ml10' onClick={() => {
                    form.resetFields();
                  }}>
                    重置
                  </Button>
                </>
              </Col>
            </Row>
          </Form>
        </div>
        {isDataMgr &&
        <>
          <Button disabled={!model?.id} type='link' onClick={addModelData}>新增</Button>
          <Button disabled={!(selectedData?.length == 1)} type='link' onClick={updateModelData}>编辑</Button>
          <Button disabled={ids.length == 0} onClick={deleteData} type='link'>删除</Button>
          <Button disabled={!model?.id} type='link' onClick={importData}>导入</Button>
          <Button disabled={!model?.id} type='link' onClick={exportData}>导出数据</Button>
        </>
        }
        <Crud/>
      </div>

      <DataRecovery ref={dataRecoveryRef}/>
      <UiFormModal
        ref={uiFormModalRef}
        addToForm={addToForm}
        modelByName={modelByName}
        submitCallback={() => doRefreshTable(searchCondition)}
      />
    </ContainerDiv>
  )
})

export default ModelBizDataQuery;

const AddOneBtn = styled.span`
  display: flex;
  color: #006CF2;
  align-items: center;
  border:none;
  margin-left: 19px;

  .iconPark {
    margin-right: 5px;
  }
`
