import React, {useState} from 'react';
import Api from "@byk/api/Api";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../utils/localStorage";
import styled from "styled-components";
import SpinnerLoader from "../../../pages/common/SpinnerLoader";
const ImagePickerWrapper = styled.div<{
  value: string;
  loading: boolean;
}>`
  border: 1px solid #d6e4ef;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after, &:before{
    content: '';
    position: absolute;
    background: #ccc;
    display: ${({value})=> value?'none':'block'}
  }
  &:before{
    left: 50%;
    top: 30%;
    bottom: 30%;
    width: 2px;
  }
  &:after{
    left: 30%;
    right: 30%;
    top: 50%;
    height: 2px;
  }
  background: ${({value})=>{
    if(value){
      return `url('${value}') no-repeat center/cover;`
    }
  }}
  .ip-loading{
    display: ${({loading})=> loading?'block':'none'}
  }
  .ip-delete-btn{
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 2;
    right: 5px;
    top: 5px;
    border-radius: 10px;
    background: rgba(0,0,0,.2);
    &:hover{
      cursor: pointer;
      background: rgba(0,0,0,.5);
    }
    &:after, &:before{
      content: '';
      position: absolute;
      background: #fff;
      width: 10px;
      height: 1px;
      left: 50%;
      top: 50%;
    }
    &:before{
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
    &:after{
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }
  }

`;
const TImagePickerWidget = (props:any) => {
  const {value, updateMetaValue} = props;
  const [loading, setLoading] = useState<boolean>(false);
  const onChange = (ev:any)=>{
    let files = ev.target.files[0];
    if(!files){ return ;}
    let {name, type, size} = files;
    let reader = new FileReader();
    reader.onload = (e:any)=> {
      let base64Image = e.target.result;
      let params = {
        data: base64Image.split('base64,')[1],
        ext: name.substring(name.lastIndexOf('.')),
        filename: name,
        type: type,
      };
      setLoading(true);
      Api.post(`${apiPath}/${getTenant()}/rest/SysFile`, params).then((res:any)=>{
        if(res.success){
          let {code} = res.result[0];
          updateMetaValue&&updateMetaValue(`${apiPath}/${getTenant()}/file/${code}`);
        }
      }).finally(()=>{
        setLoading(false);
      })
    };

    // 以 Data URL 的形式读取文件内容
    reader.readAsDataURL(files);
  }
  const deleteImage = ()=>{
    updateMetaValue&&updateMetaValue('');
  }
  return (
    <>
      <ImagePickerWrapper loading={loading} value={value}>
        {
          loading && <SpinnerLoader className='ip-loading' size="lg" />
        }
        {
          value && <div onClick={deleteImage} className="ip-delete-btn" />
        }
        {
          !value &&
          <input style={{
            width: '100%',
            height:'100%',
            position: 'absolute',
            left: 0,
            top: 0,
            opacity: 0
          }} type="file" accept=".jpg, .png" onChange={onChange} />
        }
      </ImagePickerWrapper>
    </>
  );
};

export default TImagePickerWidget;
