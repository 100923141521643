import React from "react";
import EchartsWidget from '../component';
import BaseWidget, { WidgetState } from "../../../widgets/BaseWidget";
import { WidgetType } from "../../../constants/WidgetConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { EvaluationSubstitutionType } from "../../../entities/DataTree/types";
import { PropertyPaneConfig } from "../../../constants/PropertyControlConstants";
import {Stylesheet} from "../../../entities/AppTheming";
class XEcharts extends BaseWidget<any, WidgetState> {
  constructor(props:any) {
    super(props);
    this.updateWidgetProperty = this.updateWidgetProperty.bind(this);
  }
  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return super.getWidgetEvents('EchartsWidget');
  }
  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: '基础',
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Bind data from an API using {{}}",
            propertyName: "currentData",
            label: "Tree data",
            controlType: "INPUT_TEXT",
            placeholderText: '[{ "name": "John" }]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.OBJECT_ARRAY,
              params: {
                default: [],
              },
            },
            evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
          }
        ]
      }

    ];
  }
  updateWidgetProperty(propertyName: string, propertyValue: any): void {
  }

  getPageView(){
    console.log('this.props>>', this.props);

    return (
      <>
        <EchartsWidget {...this.props} updateWidgetProperty={this.updateWidgetProperty} />
      </>
    )
  }
  static getWidgetType(): WidgetType{
    return 'X_ECHARTS_WIDGET';
  }
}

export default XEcharts;
