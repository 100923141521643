import React, {useEffect, useState} from "react";
import type {ControlProps} from "./BaseControl";
import BaseControl from "./BaseControl";
import {Button, Drawer, Select} from "antd";
import {EditOutlined} from "@ant-design/icons/lib";
import DictManager from '@byk/pages/AppSystemInit/DictManager';
import Api from "@byk/api/Api";
import localStorage, { getTenant } from "utils/localStorage";
import _ from "lodash";
import {ReduxActionTypes} from "../../ce/constants/ReduxActionConstants";
import {useDispatch, useSelector} from "react-redux";
import {getAppStoreData} from "../../selectors/entitiesSelector";
import { apiPath } from "@byk/routes";

export const getSysDataDict = (params: any = {}) => {
  return Api.get(`${apiPath}/${getTenant()}/subapp/SysDataDict/list`, params);
}
export const getSysDataDictItem = (params: any = {}) => {
  params.enable=true;
  return Api.get(`${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`, params);
}
const SelectDict = (props: any) => {
  const {propertyValue, valueChange, placeholderText, selectId, widgetProperties, evaluatedValue,mark,propertyName} = props;
  //alert(mark);
  const {widgetId, defaultOptionValue, defaultSelectedValues, type} = widgetProperties;
  const [open, setOpen] = useState(false);
  const [selectData, setSelectData] = useState<any>([]);
  const [selectValue, setSelectValue] = useState(selectId||propertyValue);
  const [isSubTable, setIsSubTable] = useState(mark&&mark=="subTableProperty");
  const [dictItems, setDicItems] = useState(!evaluatedValue || evaluatedValue == "[]" ? [] : evaluatedValue);
  let defaultVal = defaultOptionValue || defaultSelectedValues;
  const currentStore: any = useSelector(getAppStoreData);
  if (defaultOptionValue && defaultOptionValue.indexOf('{{') > -1) {
    defaultVal = undefined;
  }
  if (defaultSelectedValues && defaultSelectedValues.indexOf && defaultSelectedValues.indexOf('{{') > -1) {
    defaultVal = undefined;
  }

  const [dictItemDefaultValue, setDictItemDefaultValue] = useState(defaultVal);
  const isMulti = type === 'MULTI_SELECT_WIDGET_V2' || type === 'CHECKBOX_GROUP_WIDGET';
  const dispatch = useDispatch();
  const openDictPopup = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  }

  const handleChange = (value: any) => {
    if (value != 'edit') {
      setDicItems([])
      setSelectValue(undefined)
      setDictItemDefaultValue(undefined)
      doValueChange(undefined)
    }
    if(value === undefined){
      let _m = getVal(null);
      valueChange(_m);
    }
    if(value != 'edit'&&value !== undefined){
      setSelectValue(value);
      valueChange({
        dictOnLoad: undefined,
        selectId: value
      })
    }
  }
  const getVal=(value:any)=>{
    let _m:any={};
    const lastIndex = propertyName.lastIndexOf('.');
    let _selectIdProperty:any=(lastIndex !== -1 ? propertyName.substring(0, lastIndex) : propertyName)+".selectId";
    _m[propertyName]=value;
    _m[_selectIdProperty]=value;
    return _m;
  }

  const handleSelect = (value: any) => {
    if (value == 'edit') {
      setSelectValue(selectValue);
      openDictPopup();
    } else {
      setSelectValue(value);
      getSysDataDictItem({dict: value, asc: "sort,createDate"}).then((res: any) => {
        if (res.success) {
          let dictItems = res.result.map((i: any) => {
            return {
              id: i.id,
              label: i.name,
              value: i.code
            }
          })
          let _onLoad = {
            name: '',
            children: [
              {
                type: 'block',
                children: [
                  {
                    type: 'action',
                    actionConfig: {
                      "action": "dict",
                      "message": "",
                      "actionId": "dict",
                      "dictId": value,
                      "apiTarget": widgetId,
                    }
                  }
                ]
              }
            ]
          };
          setDicItems(dictItems)
          dispatch({
            type: ReduxActionTypes.UPDATE_APP_STORE,
            payload: {
              ...currentStore,
              [`key${widgetId}`]: dictItems
            }
          })
          let _m:any={
            options: `{{appsmith.store.key${widgetId}}}`,//dictItems,
            dictOnLoad: _onLoad,
            selectId: value,
          };

          if(isSubTable){
            _m = getVal(value);
          }
          valueChange(_m);
        }
      });
    }
  };
  useEffect(() => {
    if (_.isArray(propertyValue)) {
      setSelectData(propertyValue);
    }
    getSysDataDict({
      tenant: getTenant(),
      asc: "sort,createDate"
    }).then((res: any) => {
      if (res.success) {
        let selectMap = res.result.map((i: any) => {
          return {
            id: i.id,
            label: i.name,
            value: i.id
          }
        })
        selectMap.push({
          value: 'edit',
          label: (
            <Button style={{paddingLeft: '0', paddingRight: '0'}} type="link" icon={<EditOutlined/>}>编辑数据字典</Button>),
        })

        setSelectData(selectMap)

        if (selectId) {
          handleSelect(selectId);
        }
      }
    })
  }, [])

  const handleSelectDefault = (value: any) => {
    if (!isMulti) {
      doValueChange(value)
    }
  }
  const handleChangeDefault = (value: any) => {
    doValueChange(value)
  }

  const doValueChange = (value: any) => {
    setDictItemDefaultValue(value)
    valueChange({
      defaultOptionValue: value,
      defaultSelectedValues: value,
    });
  }

  return (
    <>
      <Select
        placeholder={placeholderText}
        value={selectValue}
        style={{width: '100%'}}
        onSelect={handleSelect}
        onChange={handleChange}
        options={selectData}
        allowClear
      />
      <Drawer width='80%' title="编辑数据字典" placement="right" open={open} onClose={onClose}>
        <DictManager hideHead={true} hideMenu={true}/>
      </Drawer>
      {
        (!isSubTable)&&
        <>
          <p style={{marginTop:"8px"}}>默认选定值</p>
          <div>
            <Select
              mode={isMulti ? 'multiple' : undefined}
              style={{width: '100%'}}
              options={dictItems}
              onSelect={handleSelectDefault}
              onChange={handleChangeDefault}
              value={dictItemDefaultValue}
              allowClear
            />
          </div>
        </>
      }

    </>

  )
}


class SelectDictControl1 extends BaseControl<SelectDictControlProps> {
  render() {
    return <SelectDict {...this.props} valueChange={this.valueChange}/>;
  }

  valueChange = (result: any) => {
    this.batchUpdateProperties(result);
  }

  static getControlType() {
    return "SELECT_DICT_CONTROL_SUB";
  }
}

export interface SelectDictControlProps extends ControlProps {
  placeholderText?: string,
  selectId?: any
}

export default SelectDictControl1;
