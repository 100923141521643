import React from "react";
import TableList from "@byk/pages/components/TableList";
import localStorage, { getAppId, getProjectId, getTenant } from "utils/localStorage";
import {Button, Form, Input, message, Modal, Select, Space, Table, Timeline} from "antd";
import Api from "@byk/api/Api";

import {withRouter} from "react-router-dom";
import _ from "lodash";
import {uuid2} from "@byk/utils/Utils";
import { apiPath } from "@byk/routes";

interface DataType {
  id: React.Key;
}

class MemberManagerList extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  tableAddRef: React.RefObject<any>;
  formInputRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.tableAddRef = React.createRef();
    this.formInputRef = React.createRef();
  }

  state = {
    tenantId: getTenant(),
    projectId: getProjectId(),
    visibleLoadTableModal: false,
    title: '全部成员',
    isModalForm: false,
    initParams: {
      appId: getAppId(),
      projectId: getProjectId(),
      i18n:false
    },
    formCfg: {
      title: "",
      okText: "确定",
      type: null
    },
    roleList: [],
    titeType: { "u": "编辑", "c": "新增", "cc": "新增" },
    btnTextType: { "u": "保存", "c": "保存", "cc": "保存" },
    dict: null,
    appId: getAppId(),
    selectOptions: [],
    langOptions: [],
    model: "",
    tableType:null,
    lang: "default",
    columnList: [],
    columns: [],
    id: null,
    updateData: [],
    updateColumnList: [],
    key:uuid2(16,16),
    isSavePage:true,
    savePageKey:"InternationalizeContent",
    isSearchByOldPage:false,
  };

  openLoadTableModal = () => {
    this.setState({
      visibleLoadTableModal: true
    });
  };
  componentDidMount() {
    this.bindModelList();
    this.bindLangList();
    // this.props.onRef && this.props.onRef(this);
    // this.init();
  };
  callback(_data: any) {
    // this.setState({ dict: _data }, () => {
    //   this.init();
    // });

  };
  bindModelList = async () => {
    let result1: any = await Api.get(`${apiPath}/${getTenant()}/i18n/i18NModelList`, { appId: this.state.appId, projectId: this.state.projectId });
    if (result1.success) {
      let model = result1.ext.modelMap;
      this.setState({ selectOptions: model })

    }
  };
  bindLangList = async () => {
    let result1: any = await Api.get(`${apiPath}/${getTenant()}/subapp/SubappLocale/list`, { projectId: this.state.projectId });
    if (result1.success) {
      let lang = [{ languageName: "默认语言", localeString: "default" }];
      this.setState({ langOptions: [...lang, ...result1.result] })
    }
  };
  langChange = (v: any) => {
    this.setState({ lang: v }, () => {
      this.init();
    })
  };
  modelChange = (v: any) => {
    let _columnList:any = this.setColumnList(v);
    let _columns = this.bindColumns(_columnList.properties);
    this.setState({ model: v,tableType:_columnList.tableType, columnList: _columnList.properties, columns: _columns }, () => {
      this.init();
    })
  };
  setColumnList = (v: any) => {
    let j = this.state.selectOptions.filter((val: any) => {
      return val.modelName == v;
    });
    return j.length > 0 ? {properties:j[0]["properties"],tableType:j[0]["tableType"]}
    :{properties:[],tableType:null} ;
  };
  getColumnVal = (record: any, columName: any) => {
    let _content = record["languages"];
    let lang = this.state.lang;
    let val = "";
    if (!_.isEmpty(_content)) {
      let _j: any = JSON.parse(_content);
      if (_j.hasOwnProperty(lang)) {
        val = _j[lang][columName];
      }
    }
    //console.log("getColumnVal", val);
    if(val==undefined){
      val="";
    }
    return val;
  };

  bindColumns(columnList: any) {
    let that = this;
    let list: any = [{
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: any) => `${index + 1}`,
      width: 100,
    }];
    _.each(columnList, (itm: any, idx: any) => {
      list.push({
        title: itm["columDescription"],
        dataIndex: itm["columName"],
        key: itm["columName"],
        //width: 110,
        align: "left",
        render: (_: any, record: any) => (
          <>
            {this.state.lang == "default" ? record[itm["columName"]] : (this.getColumnVal(record, itm["columName"])==""?record[itm["columName"]]:this.getColumnVal(record, itm["columName"]))}
          </>
        ),
      });
    })
    list.push({
      title: "操作",
      key: "action",
      render: (_: any, record: any) => (
        <>
          <Space size="middle" style={{ display: this.state.lang == "default" ? '' : 'none' }} >
            <Button type="link" onClick={() => { this.edit(record) }} >
              国际化配置
            </Button>
          </Space>
          <Space size="middle" style={{ display: this.state.lang != "default" ? '' : 'none' }}>
            <Button type="link" onClick={() => { this.edit(record) }}>
              编辑
            </Button>
          </Space>
        </>
      ),
      width: 100,
    });
    return list;
    //this.state.columnList = list;
  };
  getLangName=(val:any)=>{
    let obj=this.state.langOptions.filter((itm:any)=>{
      return itm.localeString==val;
    })
    return obj[0]["languageName"];
  }
  bindUpdateColumns() {
    let lang: any = [];
    if (this.state.lang == "default") {
      _.each(this.state.langOptions, (itm: any, idx: any) => {
        if (itm.localeString != "default") {
          lang.push(itm.localeString);
        }
      })
    }
    else {
      lang.push(this.state.lang);
    }
    let that = this;
    let list: any = [{
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: any) => `${index + 1}`,
      width: 100,
    }, {
      title: "字段",
      dataIndex: "columName",
      key: "columName",
      width: 100,
    }, {
      title: "默认语种",
      dataIndex: "default",
      key: "default",
      width: 100,
    }];
    _.each(lang, (itm: any, idx: any) => {
      list.push({
        title: this.getLangName(itm),
        dataIndex: itm,
        key: itm,
        align: "left",
        render: (_: any, record: any) => (
          <>
            <Form.Item
              label=""
              colon={false}
              name={itm + "." + record["columName"]}
            >
              <Input  allowClear key={itm + "." + record["columName"]} />
            </Form.Item>

          </>
        ),

      });
    })
    return list;
    //this.state.columnList = list;
  };
  edit = (record: any) => {
    let _data: any = [];
    let _content = record["languages"];
    let lang = this.state.lang;
    let langList = this.state.langOptions;
    let _columnList = [...this.state.columnList];
    let values: any = {};
    _.each(_columnList, (itm: any, idx: any) => {
      let obj: any = {};
      obj.key = idx;
      obj.columName = itm["columName"];
      obj.default = record[itm["columName"]];
      //如果有数据
      let _j: any;
      if (!_.isEmpty(_content)) {
        _j = JSON.parse(_content);
        //如果默认语言，列为所有语言
        if (lang == "default") {
          _.each(langList, (itm1: any, idx1: any) => {
            if (_j.hasOwnProperty(itm1["localeString"]) && itm1["localeString"] != "default") {
              let m: any = _j[itm1["localeString"]][obj.columName];
              obj[itm1["localeString"]] = m;
              values[itm1["localeString"] + "." + itm["columName"]] = m;
            }
          })
        }
        else{
          let m: any = _j[lang]?.[obj.columName];
          values[lang + "." + obj.columName] = m;
        }
      }
      else {
        _.each(langList, (itm1: any, idx1: any) => {
          if (itm1["localeString"] != "default") {
            obj[itm1["localeString"]] = "";
            values[itm1["localeString"] + "." + itm["columName"]] = "";
          }
        })
      }
      obj.languages = _j||{};
      _data.push(obj);
    })
    console.log("data", _data);
    let _updataColums = this.bindUpdateColumns();
    this.setState({ isModalForm: true, updateData: _data, updateColumnList: _updataColums, id: record.id }, () => {
      console.log("values",values);
      console.log("_updataColums",_updataColums);
      this.formInputRef.current?.setFieldsValue(values);
    });
    //this.state.updateColumnList = true;
  };
  save = async () => {

    let _data = this.formInputRef.current.getFieldsValue();
    console.log("save_data",_data);
    let _obj:any={};
    for (let key of Object.keys(_data)) {
      let keys:any=key.split(".");
      let lang=keys[0];
      let columName=keys[1];
      //_obj[lang][columName]=_data[key];
      if (!_obj.hasOwnProperty(lang)){
        let j:any={};
        j[columName]=_data[key];
        _obj[lang]=j;
      }
      else{
        _obj[lang][columName]=_data[key];
      }
    }
    const editData:any = this.state.updateData[0]||{};
    let oldLanguage = editData.languages||{};
    _obj = {
      ...oldLanguage,
      ..._obj
    }
    console.log('--save--', _obj);
    let data: any = {};
    data.id = this.state.id;
    data.appId = this.state.appId;
    data.projectId = this.state.projectId;
    data.modelName = this.state.model;
    data.tableType = this.state.tableType;
    data.languages = JSON.stringify(_obj);
    let result: any = await Api.put(`${apiPath}/${getTenant()}/i18n/modelI18NData`, data);
    if (result.success) {
      message.success("保存成功!");
      this.setState({isModalForm:false});
      this.init(true);
    }
  };
  init(isSearchByOldPage:any=false) {
    let params: any = this.state.initParams;
    params.modelName = this.state.model;
    params.tableType = this.state.tableType;
    let _key=uuid2(16,16);
    if(isSearchByOldPage){
      _key=this.state.key;
    }
    this.setState({ initParams: { ...params },key:_key,isSearchByOldPage:isSearchByOldPage }, () => {
      if (this.tableRef.current) {
        //因为key的变化Table会重新渲染。所以不需要单独调。
        //this.tableRef.current.getTableList(params);
      }
    })
  };
  showModal1(type: string) {
    this.setState({ isModalForm: true }, () => {
      let _data: any = this.state.formCfg.data;
      if (type == "c" || type == "cc") {
        this.formRef.current?.setFieldsValue({ languageName: "", localeString: "", region: "" });
      }
      if (type == "u") {
        this.formRef.current?.setFieldsValue({ languageName: _data.languageName, localeString: _data.localeString, region: _data.region });
      }
    });
  };

  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data,
      }
    }, () => { this.showModal1(type); });

    //this.formRef.current.resetFields();
  };
  handleOk1 = () => {
    this.setState({ isModalForm: false });
  };

  handleCancel1 = () => {
    this.setState({ isModalForm: false });
  };
  //更新
  updateForm = async (value: any) => {
    let postUrl = `${apiPath}/${getTenant()}/subapp/SubappLocale`;
    let _data: any = {};
    let cfg: any = this.state.formCfg;
    let result: any = null;
    if (cfg.type == "c" || cfg.type == "cc") {
      _data.languageName = value.languageName;
      _data.localeString = value.localeString;
      _data.region = value.region;
      _data.projectId = this.state.projectId;
      _data.appId = this.state.appId;
      if (cfg.type == "cc") {
        _data.sorts = value.sort - 1;
      }
      result = await Api.post(postUrl, _data);
    }
    if (cfg.type == "b") {
      _data.id = cfg.data.id;
      _data.languageName = value.languageName;
      _data.localeString = value.localeString;
      _data.region = value.region;
      _data.projectId = this.state.projectId;
      _data.appId = this.state.appId;
      result = await Api.put(postUrl, _data);
    }
    console.log("_data", _data);
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel1();
      this.init();
    }
    else {
      message.error(result.message);
    }

  }

  render() {
    // api
    const apiGet: string = `${apiPath}/${getTenant()}/i18n/modelI18NData`;
    const apiDel: string = `${apiPath}/${getTenant()}/subapp/SubappLocale`;
    const apiGetModel: string = `${apiPath}/${getTenant()}/i18n/i18NModelList`;
    const showDrawer = () => {
      this.setState({ open: true });
    };

    const onClose = () => {
      this.setState({ open: false });
    };

    const onFinish = async (values: any) => {
      console.log("values", values)
      values.id = this.state.actionId;
      let result1: any = await Api.get(apiGetModel, values);;
      if (result1.success) {
        message.success("保存成功!");
        onClose();
        //refreshTable();
      }
    };

    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };






    const tableProps = {
      columns: this.state.columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
      // isSavePage:true,
      // savePageKey:"InternationalizeContent"
    };


    const handleCancel = () => {
      this.setState({ visibleLoadTableModal: false });
    }
    return (
      <>
        <div className="x-main-box-in">

          <Timeline>
            <Timeline.Item>
              <>
                <p><span className="x-step-text" style={{
                  color: "#1890ff",
                  marginRight: "7px",
                  fontWeight: "bold"
                }}>Step1</span>请先选择需要配置多语言文案的模型</p>
                <p>
                  <span>模型</span>

                  <Select
                    key="model"
                    style={{ width: 180, marginTop: "10px", marginLeft: "10px" }}
                    options={this.state.selectOptions}
                    fieldNames={{ label: "modelDescription", value: "modelName" }}
                    onChange={(v: any) => { this.modelChange(v) }}
                    getPopupContainer={() => document.getElementById('x-sys-manage-wrapper')  as HTMLElement}
                  />
                </p>
              </>

            </Timeline.Item>
            <Timeline.Item>
              <>
                <p><span className="x-step-text" style={{
                  color: "#1890ff",
                  marginRight: "7px",
                  fontWeight: "bold"
                }}>Step2</span>为模型内容配置多语言文案</p>
                <p>
                  <span>语种</span>

                  <Select
                    key="lang"
                    defaultValue={"default"}
                    style={{ width: 180, marginTop: "10px", marginLeft: "10px" }}
                    options={this.state.langOptions}
                    fieldNames={{ label: "languageName", value: "localeString" }}
                    onChange={(v: any) => { this.langChange(v) }}
                    getPopupContainer={() => document.getElementById('x-sys-manage-wrapper')  as HTMLElement}
                  />
                </p>
                <br></br>
                {
                  this.state.model != "" && <TableList key={this.state.key} ref={this.tableRef} {...tableProps}
                  isSavePage={this.state.isSavePage}
                  savePageKey={this.state.savePageKey}
                  isSearchByOldPage={this.state.isSearchByOldPage}
                  />
                }

              </>
            </Timeline.Item>
          </Timeline>

          <Modal title={"内容国际化"} width={"80%"} open={this.state.isModalForm} onOk={() => this.save()} okText="保存"
            cancelText="取消" onCancel={this.handleCancel1}>
            <Form
              key="outForm"
              ref={this.formInputRef}
            >
              <Table columns={this.state.updateColumnList} bordered={true} size="small" pagination={false}  dataSource={this.state.updateData} />
            </Form>
            {/* <Button type="link" onClick={() => { this.save() }}>
              保存
            </Button> */}
          </Modal>
        </div>
      </>
    );
  }
}
export default withRouter(MemberManagerList);
