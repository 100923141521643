import React from 'react';
import {AtList, AtListItem} from "taro-ui";
import {Picker} from "@tarojs/components";
import _ from "lodash";
import styled from "styled-components";
export const PickerWrapper = styled.div<{
  isVisible: boolean;
}>`
  .at-list__item-content{
    display: ${({isVisible})=> isVisible?'block':'none'};
  }
`;
const multiSelectorOptions = [['2023','2024'], ['09','10']];
const MultiWidget = (props:any) => {
  const {label, value, onDateChange, isVisible} = props;
  const reValue = (v:any)=>{
    let newValue:any = [];
    if(_.isString(v)||_.isEmpty(v)){
      return multiSelectorOptions[0][1] + '-' + multiSelectorOptions[1][1];
    }
    _.each(multiSelectorOptions, (i:any, idx:number)=>{
      newValue.push(i[v[idx]]);
    })
    return newValue.join('-');
  }

  return (
    <PickerWrapper isVisible={isVisible}>
      <Picker mode='multiSelector' range={multiSelectorOptions} onChange={(e:any)=>{
        onDateChange(e.detail.value);
      }} value={value}>
        <AtList>
          <AtListItem title={label} extraText={reValue(value)} />
        </AtList>
      </Picker>
    </PickerWrapper>
  );
};

export default MultiWidget;
