import {ReduxActionTypes} from "@appsmith/constants/ReduxActionConstants";
import {LayoutDirection, Positioning} from "utils/autoLayout/constants";
import {EventType} from "constants/AppsmithActionConstants/ActionConstants";
import {WIDGET_PADDING} from "constants/WidgetConstants";
import type {ValidationResponse} from "constants/WidgetValidation";
import {ValidationTypes} from "constants/WidgetValidation";
import {find} from "lodash";
import React from "react";
import {AppPositioningTypes} from "reducers/entityReducers/pageListReducer";
import type {WidgetProperties} from "selectors/propertyPaneSelectors";
import {AutocompleteDataType} from "utils/autocomplete/AutocompleteDataType";
import WidgetFactory from "utils/WidgetFactory";
import type {WidgetState} from "../../BaseWidget";
import BaseWidget from "../../BaseWidget";
import TabsComponent from "../component";
import type {TabContainerWidgetProps, TabsWidgetProps} from "../constants";
import derivedProperties from "./parseDerivedProperties";
import type {Stylesheet} from "entities/AppTheming";
import {
  DefaultAutocompleteDefinitions,
  isAutoHeightEnabledForWidget,
  isAutoHeightEnabledForWidgetWithLimits,
} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import {TabPosition} from "../../../components/constants";
import {BaseInputWidgetProps} from "../../BaseInputWidget/widget";

export function selectedTabValidation(
  value: unknown,
  props: TabContainerWidgetProps,
): ValidationResponse {
  const tabs: Array<{
    label: string;
    id: string;
  }> = props.tabsObj ? Object.values(props.tabsObj) : props.tabs || [];
  const tabNames = tabs.map((i: { label: string; id: string }) => i.label);
  return {
    isValid: value === "" ? true : tabNames.includes(value as string),
    parsed: value,
    messages: [
      {
        name: "ValidationError",
        message: `Tab name ${value} does not exist`,
      },
    ],
  };
}

class TabsWidget extends BaseWidget<TabsWidgetProps<TabContainerWidgetProps>,
  WidgetState> {
  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('TabsWidget');
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "Data",
        children: [
          {
            propertyName: "tabsObj",
            isJSConvertible: false,
            label: "Tabs",
            helpText: "Tabs",
            controlType: "TABS_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
            prefix: 'Tab',
            updateRelatedWidgetProperties: (
              propertyPath: string,
              propertyValue: string,
              props: WidgetProperties,
            ) => {
              const propertyPathSplit = propertyPath.split(".");
              const property = propertyPathSplit.pop();
              if (property === "label") {
                const itemId = propertyPathSplit.pop() || "";
                const item = props.tabsObj[itemId];
                if (item) {
                  return [
                    {
                      widgetId: item.widgetId,
                      updates: {
                        modify: {
                          tabName: propertyValue,
                        },
                      },
                    },
                  ];
                }
              }
              return [];
            },
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              children: [
                {
                  sectionName: "一般",
                  children: [
                    {
                      propertyName: "iconName",
                      label: "图标",
                      helpText: "Sets the icon to be used for the icon button",
                      controlType: "ICON_SELECT",
                      defaultIconName: "plus",
                      isJSConvertible: false,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {
                        type: ValidationTypes.TEXT,
                        params: {},
                      },
                    },
                  ],
                },
                {
                  sectionName: "一般",
                  children: [
                    {
                      propertyName: "isVisible",
                      label: "是否可见",
                      helpText: "Controls the visibility of the tab",
                      controlType: "SWITCH",
                      useValidationMessage: true,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {type: ValidationTypes.BOOLEAN},
                    },
                  ],
                },
              ],
            },
          },
          {
            propertyName: "defaultTab",
            helpText: "Selects a tab name specified by default",
            placeholderText: "Tab 1",
            label: "Default tab",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.FUNCTION,
              params: {
                fn: selectedTabValidation,
                expected: {
                  type: "Tab Name (string)",
                  example: "Tab 1",
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
              dependentPaths: ["tabsObj", "tabs"],
            },
            dependencies: ["tabsObj", "tabs"],
          },
        ],
      },
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "autoLoadData",
            label: "自动加载数据",
            helpText: "打开时，页面加载时自动加载所有标签页下的数据，关闭后，只加载选中的标签页下的数据",
            controlType: "SWITCH",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isVisible",
            label: "是否可见",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "animateLoading",
            label: "加载动画",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            helpText: "",
            propertyName: "tabPosition",
            label: "标签位置",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {label: "上", value: TabPosition.Top},
              {label: "下", value: TabPosition.Bottom},
              {label: "左", value: TabPosition.Left},
              {label: "右", value: TabPosition.Right},
            ],
            defaultValue: TabPosition.Top,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Sets the label alignment of the widget",
            propertyName: "labelAlignment",
            label: "标签对齐方式",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {
                label: "居左对齐",
                value: "flex-start",
              },
              {
                label: "居中对齐",
                value: "center",
              },
              {
                label: "居右对齐",
                value: "flex-end",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            defaultValue: "flex-start",
            validation: {type: ValidationTypes.TEXT},
            hidden: (props: BaseInputWidgetProps) =>
              props.tabPosition == TabPosition.Left || props.tabPosition == TabPosition.Right,
            dependencies: ["labelPosition"],
          },
        ],
      },
      {
        sectionName: "标签宽度",
        children: [
          {
            propertyName: "tabWFlex1",
            label: "与容器等宽",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: false,
            validation: {
              type: ValidationTypes.BOOLEAN,
            },
          },
          {
            helpText: "Enter value for border width",
            propertyName: "tabW",
            label: "宽度设置",
            placeholderText: "Enter value in px",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: "160",
            validation: {type: ValidationTypes.NUMBER},
            postUpdateAction: ReduxActionTypes.CHECK_CONTAINERS_FOR_AUTO_HEIGHT,
          },
        ]
      },
      {
        sectionName: "颜色",
        children: [
          {
            propertyName: "accentColor",
            helpText: "Sets the background color of the widget",
            label: "强调色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "backgroundColor",
            helpText: "Sets the checked state color of the checkbox",
            label: "背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "borderColor",
            helpText: "Sets the checked state color of the checkbox",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "文字",
        children: [
          {
            propertyName: "textSize",
            label: "文字大小",
            helpText: "Controls the size of text in the column",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
            ],
            defaultValue: "1rem",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "textColor",
            label: "文字颜色",
            helpText: "Controls the color of text in the column",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "lineHeight",
            helpText: "Changes the width of the button",
            label: "文字行高",
            controlType: "INPUT_TEXT",
            isJSConvertible: false,
            isBindProperty: true,
            defaultValue: 3,
            validation: {type: ValidationTypes.NUMBER},
          },
          {
            propertyName: "selectedTextSize",
            label: "选中文字大小",
            helpText: "Controls the size of text in the column",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
            ],
            defaultValue: "1rem",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "selectedLabelStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "textSelectedColor",
            label: "文字颜色",
            helpText: "Controls the color of text in the column",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "边框&阴影",
        children: [
          {
            helpText:
              "Enter value for border width which can also use as margin",
            propertyName: "borderWidth",
            label: "边框宽度",
            placeholderText: "Enter value in px",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER},
          },
          {
            propertyName: "borderRadius",
            label: "圆角大小",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
    ];
  }

  callDynamicHeightUpdates = () => {
    const {checkContainersForAutoHeight} = this.context;
    checkContainersForAutoHeight && checkContainersForAutoHeight();
  };

  callPositionUpdates = (tabWidgetId: string) => {
    const {updatePositionsOnTabChange} = this.context;
    updatePositionsOnTabChange &&
    updatePositionsOnTabChange(this.props.widgetId, tabWidgetId);
  };

  onTabChange = (tabWidgetId: string) => {
    const _str = super.reDynamicStringFn(this.props.onTabSelected as string);
    this.props.updateWidgetMetaProperty("selectedTabWidgetId", tabWidgetId, {
      triggerPropertyName: "onTabSelected",
      dynamicString: _str,
      event: {
        type: EventType.ON_TAB_CHANGE,
      },
    });
    setTimeout(this.callDynamicHeightUpdates, 0);
    setTimeout(() => this.callPositionUpdates(tabWidgetId), 0);
  };

  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      textColor: "{{appsmith.theme.colors.primaryColor}}",
      textSelectedColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  static getDerivedPropertiesMap() {
    return {
      selectedTab: `{{(()=>{${derivedProperties.getSelectedTab}})()}}`,
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      selectedTab: "string",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedTabWidgetId: undefined,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  getPageView() {
    const {leftColumn, parentColumnSpace, rightColumn} = this.props;

    const tabsComponentProps = {
      ...this.props,
      tabs: this.getVisibleTabs(),
      width:
        (rightColumn - leftColumn) * parentColumnSpace - WIDGET_PADDING * 2,
    };
    const isAutoHeightEnabled: boolean =
      isAutoHeightEnabledForWidget(this.props) &&
      !isAutoHeightEnabledForWidgetWithLimits(this.props);
    return (
      <TabsComponent
        {...tabsComponentProps}
        $noScroll={isAutoHeightEnabled}
        backgroundColor={this.props.backgroundColor}
        borderColor={this.props.borderColor}
        borderRadius={this.props.borderRadius}
        borderWidth={this.props.borderWidth}
        boxShadow={this.props.boxShadow}
        onTabChange={this.onTabChange}
        primaryColor={this.props.primaryColor}
        selectedTabWidgetId={this.getSelectedTabWidgetId()}
        shouldScrollContents={
          this.props.shouldScrollContents &&
          this.props.appPositioningType !== AppPositioningTypes.AUTO
        }
        tabPosition={this.props.tabPosition}
        labelAlignment={this.props.labelAlignment}
        tabW={this.props.tabW || 45}
        tabWFlex1={this.props.tabWFlex1}
        textSize={this.props.textSize}
        labelStyle={this.props.labelStyle}
        textColor={this.props.textColor}
        lineHeight={this.props.lineHeight || 3}
        selectedTextSize={this.props.selectedTextSize}
        selectedLabelStyle={this.props.selectedLabelStyle}
        textSelectedColor={this.props.textSelectedColor}
      >
        {this.renderComponent()}
      </TabsComponent>
    );
  }

  renderComponent = () => {
    const selectedTabWidgetId = this.getSelectedTabWidgetId();
    const childWidgetData = {
      ...this.props.children?.filter(Boolean).filter((item) => {
        return selectedTabWidgetId === item.widgetId;
      })[0],
    };
    if (!childWidgetData) {
      return null;
    }

    childWidgetData.canExtend = this.props.shouldScrollContents;
    const {componentHeight, componentWidth} = this.getComponentDimensions();
    childWidgetData.rightColumn = componentWidth;
    childWidgetData.isVisible = this.props.isVisible;
    childWidgetData.bottomRow = this.props.shouldScrollContents
      ? childWidgetData.bottomRow
      : componentHeight - 1;
    childWidgetData.parentId = this.props.widgetId;
    childWidgetData.minHeight = componentHeight;
    const selectedTabProps = Object.values(this.props.tabsObj)?.filter(
      (item) => item.widgetId === selectedTabWidgetId,
    )[0];
    const positioning: Positioning =
      this.props.appPositioningType == AppPositioningTypes.AUTO
        ? Positioning.Vertical
        : Positioning.Fixed;
    childWidgetData.positioning = positioning;
    childWidgetData.useAutoLayout = positioning !== Positioning.Fixed;
    childWidgetData.direction =
      positioning === Positioning.Vertical
        ? LayoutDirection.Vertical
        : LayoutDirection.Horizontal;
    childWidgetData.alignment = selectedTabProps?.alignment;
    childWidgetData.spacing = selectedTabProps?.spacing;

    return WidgetFactory.createWidget(childWidgetData, this.props.renderMode);
  };

  private getSelectedTabWidgetId() {
    let selectedTabWidgetId = this.props.selectedTabWidgetId;
    if (this.props.children) {
      selectedTabWidgetId =
        this.props.children.find((tab) =>
          this.props.selectedWidgetAncestry?.includes(tab.widgetId),
        )?.widgetId ?? this.props.selectedTabWidgetId;
    }
    return selectedTabWidgetId;
  }

  static getWidgetType(): string {
    return "TABS_WIDGET";
  }

  componentDidUpdate(prevProps: TabsWidgetProps<TabContainerWidgetProps>) {
    const visibleTabs = this.getVisibleTabs();
    const selectedTab = find(visibleTabs, {
      widgetId: this.props.selectedTabWidgetId,
    });

    if (this.props.defaultTab !== prevProps.defaultTab || !selectedTab) {
      this.setDefaultSelectedTabWidgetId();
    }
  }

  getVisibleTabs = () => {
    const tabs = Object.values(this.props.tabsObj || {});
    if (tabs.length) {
      return tabs
        .filter(
          (tab) => tab.isVisible === undefined || !!tab.isVisible === true,
        )
        .sort((tab1, tab2) => tab1.index - tab2.index);
    }
    return [];
  };

  setDefaultSelectedTabWidgetId = () => {
    const visibleTabs = this.getVisibleTabs();
    // Find the default Tab object
    const defaultTab = find(visibleTabs, {
      label: this.props.defaultTab,
    });
    // Find the default Tab id
    const defaultTabWidgetId =
      defaultTab?.widgetId ?? visibleTabs?.[0]?.widgetId; // in case the default tab is deleted

    // If we have a legitimate default tab Id and it is not already the selected Tab
    if (
      defaultTabWidgetId &&
      defaultTabWidgetId !== this.props.selectedTabWidgetId
    ) {
      // Select the default tab
      this.props.updateWidgetMetaProperty(
        "selectedTabWidgetId",
        defaultTabWidgetId,
      );
      setTimeout(this.callDynamicHeightUpdates, 0);
    }
  };

  componentDidMount() {
    Object.keys(this.props.tabsObj || {}).length &&
    this.setDefaultSelectedTabWidgetId();
  }
}

export default TabsWidget;
