import * as React from "react";
import { useEffect, useRef, useState } from "react";
import type { ComponentProps } from "widgets/BaseComponent";
import { Button, Cascader, Col, Divider, Row, Tag } from "antd";
import { LabelPosition } from "../../../components/constants";
import _ from "lodash";
import "./index.css";
import styled from "styled-components";
import { StyledLabel } from "@design-system/widgets-old";
import { Alignment } from "@blueprintjs/core";
import MapWidget from "@byk/pages/components/MapWidget";
import { CloseCircleOutlined, EnvironmentFilled } from "@ant-design/icons";
import { strToJson, uuid2 } from "@byk/utils/Utils";

const ContainerWrapper = styled.div<{
  labelTextColor?: string,
  labelPosition?: string,
  labelAlignment?: string,
  btn_color?: string,
  btn_fontSize?: string,
  btn_bgColor?: string,
  btn_borderColor?: string,
  boxShadow?: string,
  borderRadius?: string,
  borderWidth?: string,
  addr_color?: string,
  addr_fontSize?: string,
  addr_bgColor?: string,
  addr_borderColor?: string,
  addr_borderWidth?: string,
  accentColor?:string,
}>`
  .ant-row .ant-col:first-child {
    align-items: center;
    display: flex !important;
    ${({ labelPosition, labelAlignment }) => labelPosition === LabelPosition.Left && labelAlignment === Alignment.RIGHT && "justify-content: flex-end;"};
  }

  .ant-btn {
    height: auto !important;
    background-color: ${({ btn_bgColor}) => {
      return( btn_bgColor ? `${btn_bgColor}` : "rgb(255, 255, 255)") + " !important"
    }};
    color: ${({btn_color}) => {
      return (btn_color ? `${btn_color}` : "var(--wds-color-text)") + ' !important;'
    }};
    font-size: ${({btn_fontSize}) => {
      return (btn_fontSize ? `${btn_fontSize}` : "14px") + ' !important;'
    }};
    border-radius: ${({ borderRadius }) => `${borderRadius}`} !important;
    box-shadow: ${({ boxShadow }) => `${boxShadow}`} !important;
    border-width: ${({ borderWidth }) => `${borderWidth}px`};
    border-color: ${({ btn_borderColor }) => btn_borderColor} !important;
  }

   .ant-btn:hover {
      border-width: ${({ borderWidth }) => `${borderWidth || 1}px !important`};
      border-color: ${({accentColor}) => accentColor} !important;
   }

  .ant-tag {
    line-height: normal !important;
    display:inline !important;
    border-color: ${({ addr_borderColor }) => addr_borderColor} !important;
    border-width: ${({ addr_borderWidth }) => addr_borderWidth}px !important;
    background-color: ${({ addr_bgColor}) => {
      return (addr_bgColor ? `${addr_bgColor}` : "rgb(255, 255, 255)") + " !important;"
    }};
    color: ${({addr_color}) => {
      return (addr_color ? `${addr_color}` : "var(--wds-color-text)") + ' !important;'
    }};
    font-size: ${({addr_fontSize}) => {
      return (addr_fontSize ? `${addr_fontSize}` : "14px") + ' !important;'
    }};
  }
`;

const AmapWidget = (props: any) => {
  const {
    label,
    labelPosition,
    value,
    defaultValue,
    isDirty,
    updateWidgetProperty,
    onChange,
    onOpen,
    isReadonly,
    isDisabled,
    isRequired,
    labelWidth,
    selectedCount,
    labelTextColor,
    labelTextSize,
    labelStyle,
    btnText,
    securityJsKey,
    securityJsCode,
    sKey,
    accentColor,
  } = props;

  const chooseMapRef = useRef(null);
  const [isAction, setIsAction] = useState<any>(false);
  const [key, setKey] = useState<any>(uuid2());
  const showModel = () => {
    let _data = getValue(value, defaultValue);
    console.log("_data", _data);
    chooseMapRef.current?.showModal(_data);
  }
  const setValue = (v: any) => {
    let str_v = "";
    if (!_.isEmpty(v)) {
      str_v = JSON.stringify(v);
    }
    setKey(uuid2());
    setIsAction(true);
    updateWidgetProperty(str_v);
    onChange && onChange();
  }

  const getValue = (value: any) => {
    if (!isDirty && defaultValue) {
      return strToJson(defaultValue);
    } else {
      if(value){
        return strToJson(value);
      }
      else{
        return "";
      }
    }
  }

  return (
    <>
      <ContainerWrapper {...props} key={key}>
        <div className="AmapWidget-class"
          style={labelPosition == LabelPosition.Top ? {} : { display: 'flex', alignItems: 'center' }}>
          <Row style={{ width: "100%" }}>
            <Col style={{ alignItems: "baseline" }} span={labelPosition == LabelPosition.Top ? 24 : (labelWidth)}>
              {label && (
                <StyledLabel
                  color={labelTextColor}
                  disabled={isDisabled}
                  fontSize={labelTextSize}
                  fontStyle={labelStyle}
                  position={labelPosition}
                  compact hasHelpText>
                  <span className={isRequired == true ? "z-smith-label" : ""}>{label}</span>
                </StyledLabel>
              )}
            </Col>
            <Col span={labelPosition == LabelPosition.Top ? 24 : (24 - labelWidth)}>
              {!isReadonly && <Button icon={<EnvironmentFilled />} onClick={showModel}>{btnText}</Button>}
              {
                getValue(value) &&
                <>
                  <Tag closeIcon={<CloseCircleOutlined />} color="#EEEEEE" style={{marginTop: isReadonly ? "0px" : "5px" }} closable={!isReadonly} onClose={() => setValue("")}>
                    {getValue(value)?.address}
                  </Tag>
                </>
              }
              <MapWidget ref={chooseMapRef}
                securityJsKey={securityJsKey}
                securityJsCode={securityJsCode}
                zoomLevel={17} isShowCard={true} dragEnable={true} zoomEnable={true} isSearch={true}
                callback={setValue} mode="modal" defaultLngLat={getValue(value)}></MapWidget>
            </Col>
          </Row>
        </div>
      </ContainerWrapper>
    </>
  )
};

class AmapComponent extends React.Component<AmapComponentProps> {
  constructor(props: AmapComponentProps) {
    super(props);
  }

  render() {
    return <AmapWidget {...this.props} />;
  }
}

export interface AmapComponentProps extends ComponentProps {
  updateWidgetProperty: any,
  onChange?: () => void,
  //key:any
}

export default AmapComponent;
