import _ from "lodash";
import {events} from "../sagas/WidgetOperationUtils";

const collect:any = {
  "X_STEP_WIDGET": (regs:any)=>{
    let { widgetProps, languageDict, currentPageId, canvasWidget } = regs;
    let tabsObj = {...widgetProps.tabsObj};
    let _doneText = languageDict[`${currentPageId}.${canvasWidget.widgetId}.doneText`];
    let _prevText = languageDict[`${currentPageId}.${canvasWidget.widgetId}.prevText`];
    let _nextText = languageDict[`${currentPageId}.${canvasWidget.widgetId}.nextText`];
    if(_doneText){
      widgetProps.doneText = _doneText;
    }
    if(_prevText){
      widgetProps.prevText = _prevText;
    }
    if(_nextText){
      widgetProps.nextText = _nextText;
    }
    _.each(tabsObj, (value:any, key:any)=>{
      let _stepLabel = languageDict[`${currentPageId}.${canvasWidget.widgetId}.tabsObj.${key}.label`];
      let _stepDes = languageDict[`${currentPageId}.${canvasWidget.widgetId}.tabsObj.${key}.description`];
      tabsObj[key] = {
        ...value,
        label: _stepLabel??value.label,
        description: _stepDes??value.description
      }
    })
    widgetProps.tabsObj = tabsObj;
    return widgetProps;
  },
  "TABLE_WIDGET_ANTD": (regs:any)=>{
    let { widgetProps, languageDict, currentPageId, canvasWidget } = regs;
    let _tableAction = {...widgetProps.tableAction};
    let _tableColumns = _.cloneDeep(widgetProps.tableColumns);
    let _fixTableColumns = _.cloneDeep(widgetProps.fixTableColumns);
    _.each(_tableAction, (value, key)=>{
      let newLabel = languageDict[`${currentPageId}.${canvasWidget.widgetId}.tableAction.${key}.label`];
      _tableAction[key] = {
        ...value,
        label: newLabel||value.label
      }
    })
    _.each(_tableColumns, (i:any)=>{
      let newLabel = languageDict[`${currentPageId}.${canvasWidget.widgetId}.tableColumns.${i.id}.label`];
      i.label = newLabel||i.label;
    })
    if(_fixTableColumns){
      _.each(_fixTableColumns, (i:any)=>{
        let newLabel = languageDict[`${currentPageId}.${canvasWidget.widgetId}.fixTableColumns.${i.id}.label`];
        i.label = newLabel||i.label;
      })
    }
    widgetProps.tableAction = _tableAction;
    widgetProps.tableColumns = _tableColumns;
    widgetProps.fixTableColumns = _fixTableColumns;
    return widgetProps;
  },
  "TABLE_WIDGET_V2": (regs:any)=>{
    let { widgetProps, languageDict, currentPageId, canvasWidget } = regs;
    let _primaryColumns = {...widgetProps.primaryColumns};
    let _orderedTableColumns = _.cloneDeep(widgetProps.orderedTableColumns);
    _.each(_primaryColumns, (value, key)=>{
      let newLabel = languageDict[`${currentPageId}.${canvasWidget.widgetId}.primaryColumns.${key}.label`];
      _primaryColumns[key] = {
        ...value,
        label: newLabel||value.label
      }
    })
    _.each(_orderedTableColumns, (i:any)=>{
      let newLabel = languageDict[`${currentPageId}.${canvasWidget.widgetId}.primaryColumns.${i.id}.label`];
      i.label = newLabel||i.label;
    })
    widgetProps.primaryColumns = _primaryColumns;
    widgetProps.orderedTableColumns = _orderedTableColumns;
    return widgetProps;
  },
  "JSON_FORM_WIDGET": (regs:any)=>{
    let {widgetProps, languageDict, currentPageId, canvasWidget} = regs;
    let submitButtonLabel = languageDict[`${currentPageId}.${canvasWidget.widgetId}.submitButtonLabel`],
      title = languageDict[`${currentPageId}.${canvasWidget.widgetId}.title`],
      resetButtonLabel = languageDict[`${currentPageId}.${canvasWidget.widgetId}.resetButtonLabel`];
    return {
      ...widgetProps,
      submitButtonLabel: submitButtonLabel??widgetProps.submitButtonLabel,
      title: title??widgetProps.title,
      resetButtonLabel: resetButtonLabel??widgetProps.resetButtonLabel
    };
  },
  "TABS_WIDGET": (regs:any)=>{
    let {widgetProps, languageDict, currentPageId, canvasWidget} = regs;
    let tabsObj = _.cloneDeep(widgetProps.tabsObj);
    _.each(tabsObj, (value, key)=>{
      value.label = languageDict[`${currentPageId}.${canvasWidget.widgetId}.tabsObj.${key}.label`]||value.label;
    })
    return {
      ...widgetProps,
      tabsObj
    }
  }
};

function Translate(regs:any){
  let {widgetProps, languageDict, currentPageId, canvasWidget} = regs;

  if(collect[widgetProps.type]&&canvasWidget){
    return collect[widgetProps.type]({
      widgetProps,
      languageDict,
      currentPageId,
      canvasWidget
    });
  }

  const keys = ['text', 'label', 'labelText', 'placeholderText', 'placeholderText1', 'placeholderText2','tooltip'];
  _.each(keys, (i:any)=>{
    let value = '';
    if(!canvasWidget){
      value = languageDict[`${currentPageId}.${widgetProps.referencedWidgetId}.${i}`];
    }else{
      value = languageDict[`${currentPageId}.${canvasWidget.widgetId}.${i}`];
    }
    if(value&&!value.startsWith('{{')){//组件绑定变量后，不做国际化，原样输出
      widgetProps[i] = value;
    }
  })
  //动作里弹框信息翻译

  _.each(events, (event:string)=>{
    if(!widgetProps[event]){
      return true;
    }
    const children = _.cloneDeep(widgetProps[event].children);
    const fn = (obj:any)=>{
      _.each(obj, (i)=>{
        if(i.type === 'action'){
          let {action, type, message} = i.actionConfig;
          if(action ==='showAlert' && type&&message){
            const keyStr = `${currentPageId}.${canvasWidget.widgetId}.${event}.${i.uid}.actionConfig.${action}.${type}`;
            i.actionConfig.message = languageDict[keyStr] || i.actionConfig.message;
          }
        }
        i.children&&fn(i.children);
      })
    }
    fn(children);
    widgetProps[event] = {
      ...widgetProps[event],
      children
    };

  })

  return widgetProps;
}
export default Translate;
