import {useDispatch, useSelector} from "react-redux";
import {getCanvasMobile, getCurrentPageId} from "../../../selectors/editorSelectors";
import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {ICON_NAMES} from "../../../components/propertyControls/IconSelectControl";
import {Input, Radio, Select, type RadioChangeEvent} from "antd";
import {Button, TextArea} from "@blueprintjs/core";
import styled from "styled-components";
import {X_LINKPAGESET, X_OPENLINKPANNEL} from "@byk/store/XReducers";
import {CloseOutlined} from "@ant-design/icons";
import _ from "lodash";
import Icon from '@icon-park/react/es/all';
import { AtIcon } from "taro-ui";
import taroUIIcon from "./TaroUIIcon";
import XTreeSelectPage from "components/utils/XTreeSelectPage";
const {Option} = Select;
const actionTitleStyle = {
  fontSize: '12px',
  color: '#101010',
  marginBottom: '9px',
  marginTop: '5px',
}

export const OpenLinkpageWrapper = styled.div<{
  linkPannelOffset: any
}>`
  width: ${({linkPannelOffset}) => linkPannelOffset.width || '230'}px;
  max-width: 1200px;
  padding: 11px 15px;
  position: absolute;
  z-index: 9;
  right: ${({linkPannelOffset}) => linkPannelOffset.right};
  left: ${({linkPannelOffset}) => linkPannelOffset.left};
  top: ${({linkPannelOffset}) => linkPannelOffset.top};
  bottom: ${({linkPannelOffset}) => linkPannelOffset.bottom};
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
  .isIcon{
    display: ${({linkPannelOffset}) => linkPannelOffset.isIcon?'block':'none'}
  }
  .x-radio-group-icon .ant-radio-button-wrapper{width:50%;text-align:center}
`;

function LinkPageSet() {
  const dispatch = useDispatch();
  const linkPageData = useSelector((state: any) => {
    return state.XReducers.linkPannelSet;
  });
  const linkPannelOffset = useSelector((state: any) => {
    return state.XReducers.linkPannelOffset;
  });
  const setLinkPanel = (data: any) => {
    dispatch({
      type: X_LINKPAGESET,
      data
    })
  }
  const setLinkOpen = (data: boolean) => {
    dispatch({
      type: X_OPENLINKPANNEL,
      data
    })
  }

  const pageId = useSelector(getCurrentPageId);
  const paramsObj = useParams<{ pageId: string }>();
  const isMobile:boolean = useSelector(getCanvasMobile);
  const [iconList, setIconList] = useState<any>([]);
  const navigatePageList = useSelector(state => {
    const pagesList = state.entities.pageList.pages.filter((i: any) => {
      return i.pageId != pageId && i.pageId != paramsObj.pageId && i.slug != 'frameDesignPage';
    });
    let options = pagesList.map((j: any) => {
      return {
        label: j.pageName,
        value: j.slug,
        pageId: j.pageId,
        slug: j.pageName
      }
    });
    return [
      {
        value: '',
        label: '=请选择打开页面=',
        disabled: true
      },
      ...options
    ]
  });

  const navigatePageType = linkPageData.actionType != 'toUrl';

  //动作下拉选项
  const actionSelect = [
    {
      value: 'toPage',
      label: '跳转页面',
    },
    {
      value: 'toUrl',
      label: '跳转超链接',
    },
  ];
  useEffect(()=>{
    if(isMobile){
      setIconList(taroUIIcon);
    }else{
      let pcIcons = Object.keys(ICON_NAMES).map((i: any) => {
        if (i == 0) {
          return {
            label: 'none',
            value: ''
          }
        } else {
          return {
            label: ICON_NAMES[i],
            value: ICON_NAMES[i]
          }
        }
      });
      setIconList(pcIcons);
    }
  }, [isMobile]);

  return (
    <OpenLinkpageWrapper linkPannelOffset={linkPannelOffset}>
      <div
        style={{
          width: '50px', height: '20px', margin: '0 0 18px 0', color: '#4C5664', fontSize: '16px', fontWeight: 'bold'
        }}
      >设置
        <div style={{
          cursor: 'pointer',
          position: 'absolute',
          right: '10px',
          top: '10px',
        }} onClick={() => setLinkOpen(false)}><CloseOutlined/></div>
      </div>
      
      
      {
        navigatePageType && linkPannelOffset.isIcon &&
        <>
          <div
          style={{
            width: '100%', margin: '8px 0 8px 0', color: '#4C5664', fontSize: '14px', fontWeight: 'bold'
          }}
        >
          图标设置
        </div>
        <div className={'isIcon'} style={{...actionTitleStyle}}>选择图标</div>
          <Select className={'isIcon'} allowClear onChange={(value) => {
            setLinkPanel({
              ...linkPageData,
              icon: value,
              save: true
            })
          }} value={linkPageData.icon} placeholder="=请选择图标=" style={{width: "100%"}}
          >
            {
              iconList.map((i: any) => {
                return (
                  <Option value={i.value} label={i.label} key={i.label}>
                    <div>{i.value ? ((isMobile?<AtIcon value={i.value} size='30' />:<Button small={true} icon={ <Icon type={i.value} /> }/>)) : null} {i.label}</div>
                  </Option>
                )
              })
            }
          </Select>
          <div className={'isIcon'} style={{...actionTitleStyle}}>图标位置</div>
            <Radio.Group  onChange={({ target: { value } }: RadioChangeEvent) => {
            setLinkPanel({
              ...linkPageData,
              iconAlign: value,
              save: true
            })
          }} value={linkPageData.iconAlign}  optionType="button" buttonStyle="outline"  className="x-radio-group2 x-radio-group-icon" style={{width:"100%"}}>
              <Radio value={'left'}>居左</Radio>
              <Radio value={'top'}>居上</Radio>
            </Radio.Group>
         
        </>
      }

      <div
        style={{
          width: '100%', margin: '18px 0 8px 0', color: '#4C5664', fontSize: '14px', fontWeight: 'bold'
        }}
      >
        事件设置-onClick
      </div>
      <div style={{...actionTitleStyle}}><span style={{color: "#FF0000"}}>*</span>动作</div>
      <Select
        style={{width: "100%"}}
        placeholder="请选择"
        allowClear={true}
        options={actionSelect}
        value={linkPageData.actionType}
        onChange={(value) => {
          setLinkPanel({
            ...linkPageData,
            actionType: value,
            topage: '',
            topageid: '',
            toUrl: '',
            topagetype: value=="toPage"?'SAME_WINDOW':'NEW_WINDOW',
            linkParams: '',
            icon: '',
            save: true
          })
        }}
      />

      <div style={{...actionTitleStyle}}>{linkPageData.actionType == 'toUrl' ? '目标超链接' : '目标页面'}</div>
      {
        navigatePageType && (
          /*<Select
            style={{width: '100%'}} placeholder="请选择" value={linkPageData.topage} options={navigatePageList}
            allowClear={true}
            onChange={(value) => {
              let selectItems: any = _.find(navigatePageList, {value: value});
              setLinkPanel({
                ...linkPageData,
                topage: selectItems.slug||value,
                topageid: selectItems && selectItems.pageId,
                save: true
              })
            }}
          />*/
          <XTreeSelectPage value={linkPageData.topageid} onChange={(value:any, selectItems:any) => {
            setLinkPanel({
              ...linkPageData,
              topage: selectItems.pageName,
              topageid: selectItems.pageId,
              save: true
            })
          }} />
        )
      }
      {
        !navigatePageType && (
          <Input
            style={{width: '100%'}}
            value={linkPageData.toUrl}
            onChange={(e) => {
              setLinkPanel({
                ...linkPageData,
                toUrl: e.target.value,
                save: true
              })
            }}
          />
        )
      }

      <div style={{...actionTitleStyle}}>打开方式</div>
      <Select
        style={{width: '100%'}}
        placeholder="请选择"
        allowClear={true}
        value={linkPageData.topagetype}
        options={[
          {
            value: '',
            label: '请选择',
            disabled: true
          },
          {
            value: 'NEW_WINDOW',
            label: '新窗口'
          },
          {
            value: 'SAME_WINDOW',
            label: '当前窗口'
          }
        ]}
        onChange={(value) => {
          setLinkPanel({
            ...linkPageData,
            topagetype: value,
            save: true
          })
        }}
      />

      <div style={{...actionTitleStyle}}>参数配置</div>
      <TextArea
        style={{width: '100%'}}
        rows={4}
        placeholder="请输入参数配置"
        value={linkPageData.linkParams}
        onChange={(e) => {
          setLinkPanel({
            ...linkPageData,
            linkParams: e.target.value,
            save: true
          })
        }}
      />
      {
        linkPageData.isDescription&&(
          <>
            <div style={{...actionTitleStyle}}>文本描述</div>
            <Input
              placeholder="请输入文本描述"
              value={linkPageData.description}
              onChange={(e) => {
                setLinkPanel({
                  ...linkPageData,
                  description: e.target.value,
                  save: true
                })
              }}
            />
          </>
        )
      }
    </OpenLinkpageWrapper>
  )
}

export default LinkPageSet;
