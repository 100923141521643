import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputRef,
  Radio,
  Row,
  Select,
  Switch,
  Upload
} from 'antd';
import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';

import {CheckOutlined, CloseOutlined, EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";
import {RoleContext} from "@byk/pages/AppSystemInit/RoleManager";

export interface IProps {
  ref?: any,
  onSearch?: (v?: any) => void;
  onClear?: () => void;
  option: any;
  placeholder?: string;
  form?: any;
  data?: any[],
  initialValues?: any,
  btnBoolean?: Boolean,
  btnWrapBoolean?: Boolean,
  formRef?: any,
  refreshTable?: (v?: any) => void
}

const SearchForm: React.FC<IProps> = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    getSearchData
  }))
  const {option, btnBoolean, onSearch, btnWrapBoolean} = props;
  const {getPopupContainer} = useContext(RoleContext);
  const [form] = Form.useForm();

  const getSearchData = () => {
    let v: any = form.getFieldsValue();
    for (let val of Object.keys(v)) {
      if (v[val] == undefined) {
        v[val] = '';
      }
    }
    return v;
  }

  function onFinish(v: any) {
    let value = getSearchData();
    onSearch && onSearch(JSON.parse(JSON.stringify(value)));
  }

  function clear() {
    onSearch && onSearch();
  }

  //文本框
  const InputRender = (item: any) => {
    //const maxLength=item.maxLength?item.maxLength:9999;
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}
                   initialValue={item.initialValue}>
          <Input hidden={item.hidden} disabled={item.disabled} maxLength={item.maxLength} style={{width: "100%"}}
                 placeholder={item.placeholder}/>
        </Form.Item>
      </Col>
    );
  };
  const {TextArea} = Input;
  //textarea文本框
  const InputTextareaRender = (item: any) => {
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}>
          <TextArea showCount  disabled={item.disabled} maxLength={item.maxLength} style={{height: item.height,wordBreak:"break-all"}} placeholder={item.placeholder}/>
        </Form.Item>
      </Col>
    );
  };
  //密码框
  const InputPasswordRender = (item: any) => {
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}>
          <Input.Password style={{width: 180}} placeholder={item.placeholder}
                          iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}/>
        </Form.Item>
      </Col>
    );
  };
  //下拉框
  const SelectRender = (item: any) => {
    let selectOptions: any[] = item.data;
    let fieldNames = {label: 'name', value: 'id'};
    let filterKey = "name";
    if (item.fieldNames != undefined) {
      fieldNames = item.fieldNames;
      filterKey = item.fieldNames.label;
    }
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}>
          <Select
            mode={item.mode}
            style={{width: "100%"}}
            options={selectOptions}
            showSearch
            fieldNames={fieldNames}
            allowClear
            filterOption={(input: any, option: any) =>
              (option[filterKey] ?? '').toLowerCase().includes(input.toLowerCase())
            }
            getPopupContainer={getPopupContainer}
          />
        </Form.Item>

      </Col>

    );

  };

  let index = 0;
  //下拉框
  const SelectRender2 = (item: any) => {
    const [items, setItems] = useState<any>([]);
    const [name, setName] = useState('');
    const [addFlag, setAddFlag] = useState<boolean>(false);
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
      if (typeof item.data == 'function') {
        item.data().then((opts:any) => {
          setItems(opts);
        });
      }
    }, [item.data])

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      let exist = items.findIndex((item:any) => item.value == name) >= 0
      if (!exist && name){
        setItems([...items, {label:name, value:name}]);
      }
      setName('');
      setAddFlag(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };

    const cancel = () => {
      setName('');
      setAddFlag(false);
    }

    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}>
          <Select
            style={{width: "100%"}}
            placeholder={item.placeholder}
            dropdownRender={(menu:any) => (
              <>
                {menu}
                <Divider style={{margin: '8px 0'}}/>
                {!addFlag &&
                <Button
                  style={{display: 'flex', alignItems: 'center'}}
                  type={"link"}
                  icon={getIconPark('AddOne')} onClick={() => setAddFlag(true)}
                >
                  <span style={{marginLeft:'5px'}}>{item.addOneLabel}</span>
                </Button>
                }
                {addFlag &&
                  <Row style={{margin:'0 5px'}}>
                    <Col span={18}>
                      <Input
                        ref={inputRef}
                        value={name}
                        onChange={onNameChange}
                      />
                    </Col>
                    <Col span={6} style={{display:'flex'}}>
                      <Button style={{padding:0, marginLeft: '5px'}} type={"link"} onClick={addItem}>确认</Button>
                      <Button style={{padding:0, marginLeft: '5px'}} type={"link"} onClick={cancel}><span style={{color:'gray'}}>取消</span></Button>
                    </Col>
                  </Row>
                }
              </>
            )}
            options={items}
          />
        </Form.Item>

      </Col>
    );

  };

  //DatePicker
  const DatePickerRender = (item: any) => {
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}>
          <DatePicker style={{width:"100%"}} placeholder={item.placeholder} picker={item.picker}/>
        </Form.Item>
      </Col>
    );
  };
  //Cascader
  const CascaderRender = (item: any) => {
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}>
          <Cascader placeholder={item.placeholder} options={item.data}/>
        </Form.Item>
      </Col>
    );
  };
  //Checkbox
  const CheckboxRender = (item: any) => {
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}>
          <Checkbox.Group style={{width: '100%'}}>
            {item.data.map((i: any) => {
              return (
                <Checkbox value={i.value}>{i.label}</Checkbox>
              )
            })}
          </Checkbox.Group>
        </Form.Item>
      </Col>
    );
  };
  //Radio
  const RadioRender = (item: any) => {
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules}>
          <Radio.Group style={{width: '100%'}}>
            {item.data.map((i: any) => {
              return (
                <Radio value={i.value}>{i.label}</Radio>
              )
            })}
          </Radio.Group>
        </Form.Item>
      </Col>
    );
  };
  //Switch
  const SwitchRender = (item: any) => {
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules} valuePropName="checked">
          <Switch checkedChildren={<CheckOutlined/>}
                  unCheckedChildren={<CloseOutlined/>}
                  defaultChecked/>
        </Form.Item>
      </Col>
    );
  };
  //Upload

  const UploadRender = (item: any) => {
    return (
      <Col xs={12} sm={8} md={item.col || 6}>
        <Form.Item label={item.label} name={item.name} labelCol={{span: item.labelCol||6}} rules={item.rules} valuePropName="checked">
          <Upload action={item.action} onChange={item.onChange} defaultFileList={item.defaultFileList}>
            <Button>Upload</Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };

  const searchBtnGroup = () => {
    return (
      <>
        <Button type="primary" htmlType="submit">查询</Button>
        <Button className='x-ml10 reset' onClick={() => {
          form.resetFields();
          clear();
        }}>重置</Button>
      </>
    )
  }

  return (
    <Form ref={props.formRef} form={form} labelWrap={true} onFinish={(v) => onFinish(v)} autoComplete="off"
          className="flex-auto">
      <Row gutter={24}>
        {option.map((config: any, index: any) => (
          <React.Fragment key={index}>
            {config.itemType === 'input' && InputRender(config)}
            {config.itemType === 'textarea' && InputTextareaRender(config)}
            {config.itemType === 'password' && InputPasswordRender(config)}
            {config.itemType === 'select' && SelectRender(config)}
            {config.itemType === 'select2' && SelectRender2(config)}
            {config.itemType === 'DatePicker' && DatePickerRender(config)}
            {config.itemType === 'Cascader' && CascaderRender(config)}
            {config.itemType === 'Checkbox' && CheckboxRender(config)}
            {config.itemType === 'Radio' && RadioRender(config)}
            {config.itemType === 'Switch' && SwitchRender(config)}
            {config.itemType === 'Upload' && UploadRender(config)}
          </React.Fragment>
        ))}
        {
          (btnBoolean && (btnWrapBoolean != true)) ? searchBtnGroup() : null
        }
      </Row>
      {
        (btnBoolean && (btnWrapBoolean == true)) ?
          <Row gutter={24} style={{display: "flex", justifyContent: "center"}}>
            {searchBtnGroup()}
          </Row> : null
      }
    </Form>
  );
});

export default SearchForm;
