import {PROPERTY_REL_TYPE, RelAttrType} from "@byk/pages/modeling/constants/Constants";
import {isRelProperty} from "@byk/pages/modeling/ModelDataUtil";
import Api from "@byk/api/Api";
import localStorage, {getTenant} from "utils/localStorage";

/**
 * 动态模型属性类型Options
 */
export const ModelingOptions = [
  {label: '单行文本', value: 'string',},
  {label: '多行文本', value: 'textarea',},
  {label: '富文本', value: 'richtext',},
  {label: '整数', value: 'integer',},
  {label: '长整数', value: 'bigint',},
  {label: '小数', value: 'decimal',},
  {label: '邮箱', value: 'mail',},
  {label: '布尔', value: 'bool',},
  {label: '日期时间', value: 'datetime',},
  {label: '选项', value: 'dict',},
  {label: '文件', value: 'file',},
  {label: '图片', value: 'image',},
  {label: '二进制', value: 'blob',},
  {label: '一对多', value: 'o2m'},
  {label: '多对一', value: 'm2o'},
  {label: '关联属性', value: RelAttrType.REF_ATTR_TYPE,},
];

export const getModelingOptionByValue = (value: any) => {
  return ModelingOptions.find(item => item.value == value) || {};
}

export const getPropertyType = (type: any) => {
  let _type: any = getModelingOptionByValue(type);
  if (_type.value) {
    return _type;
  }
  _type = ModelingOptions.find(item => item.label == type) || {};
  if (_type.value) {
    return _type;
  } else {
    let defaultType = {label: '单行文本', value: 'string',};
    return defaultType;
  }
}

/**
 * 根据下拉value获取label
 * @param val
 */
export function getModelPropertyTypeLabel(record: any) {
  let ret = '';
  ModelingOptions.forEach(({label, value}) => {
    if (value == record.type) {
      ret = label;
    }
  });

  if (isRelProperty(record.type)) {
    if (PROPERTY_REL_TYPE.O2M == record.type) {
      ret = '一对多';
    }
    if (PROPERTY_REL_TYPE.M2O == record.type) {
      ret = '多对一';
    }
  }

  return ret;
}

export const TrueOrFalseOptions = [
  {label: '是', value: true},
  {label: '否', value: false},
];

/**
 * 模型关系
 */
export const ModelRelTypeOptions = [
  {label: '一对多', value: PROPERTY_REL_TYPE.O2M},
  // {label: '一对一', value: PROPERTY_REL_TYPE.O2O},
]

export const getDictOptions = async () => {

  let api = `/byk/${getTenant()}/rest/SysDataDict/list`;
  let ret: any = [];
  let result: any = await Api.get(api, {tenant: getTenant()});
  if (result.success) {
    if (result.result.length > 0) {
      result.result.forEach((item: any) => {
        ret.push({label: item.name, value: item.name, id: item.id});
      })
    }
  }

  return ret;
}
