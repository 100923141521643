import type { WidgetType } from "constants/WidgetConstants";
import * as React from "react";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import TimingComponent from "../component";

import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import { EvaluationSubstitutionType } from "entities/DataTree/types";
import { isAutoLayout } from "utils/autoLayout/flexWidgetUtils";
import {Stylesheet} from "../../../entities/AppTheming";


class TimingWidget extends BaseWidget<TimingWidgetProps, WidgetState> {
  private timer: any;
  constructor(props: TimingWidgetProps) {
    super(props);
    this.updateWidgetProperty = this.updateWidgetProperty.bind(this);
    this.onClick = this.onClick.bind(this);
    this.timer = null;
  }
  static getPropertyPaneEventConfig(){
    return super.getWidgetEvents('TimingWidget');
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText:
              "",
            propertyName: "timesOpt",
            label: "选项",
            controlType: "INPUT_TEXT",
            placeholderText: '[0, 5, 10,30,60]',
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,

            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
        ],
      },
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "isVisible",
            helpText: "Controls the visibility of the widget",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ]
      }

    ];
  }
  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "外观",
        children: [
          {
            propertyName: "backGroundColor",
            label: "背景颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Use a html color name, HEX, RGB or RGBA value",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "borderColor",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "borderWidth",
            label: "边框宽度(单位:px)",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            max:1,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角半径(单位:px)",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "字体",
        children: [
          {
            propertyName: "fontSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            hidden: isAutoLayout,
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "color",
            label: "字体颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "fontStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          }
        ],
      },
      {
        sectionName: "图标",
        children: [
          {
            propertyName: "iconName",
            label: "图标",
            helpText: "Sets the icon to be used for a button",
            controlType: "ICON_SELECT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }
  static getStylesheetConfig(): Stylesheet {
    return {
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  startTiming(){
    if(this.props.times>0){
      this.timer = setTimeout(()=>{
        this.onClick();
        this.startTiming();
      }, this.props.times*1000);
    }
  }
  pauseTiming(){
    if(this.timer){
      clearTimeout(this.timer as NodeJS.Timeout);
      this.timer = null;
    }
  }
  componentDidUpdate(prevProps: TimingWidgetProps, prevState?: WidgetState) {
    if(prevProps.times > 0&&!this.timer){
      this.startTiming();
    }
    if(prevProps.times == 0&&this.timer){
      this.pauseTiming();
    }
  }
  componentWillUnmount(){
    this.pauseTiming();
  }

  getPageView() {
    let primaryColorTheme = this.getThemeSettingsByKey('colors', 'primaryColor');
    return (
      <TimingComponent
        {...this.props}
        widgetId={this.props.widgetId}
        updateWidgetProperty={this.updateWidgetProperty}
        onClick={this.onClick}
        primaryColorTheme={primaryColorTheme}
      />
    );
  }
  updateWidgetProperty(propertyName: string, propertyValue: any){
    super.updateWidgetProperty(propertyName, propertyValue);
  }

  onClick() {
    if (this.props.onClick) {
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: this.props.onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      });
    }
  }

  static getWidgetType(): WidgetType {
    return "X_TIMING_WIDGET";
  }
}

export interface TimingWidgetProps extends WidgetProps {
  timesOpt: any[],
  times: number
}

export default TimingWidget;
