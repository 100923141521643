import Api from "@byk/api/Api";
import _ from "lodash";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {getDictOptions} from "@byk/pages/modeling/constants/ModelingOptions";

const URL_ALL_PAGE_ELE = "/byk/platform/app/appPageDsl?appId=";

const getPropertyType = (uiTypeOrWidgetName: any = '', isDecimal: boolean = false) => {
  switch (uiTypeOrWidgetName) {
    case 'PASSWORD':                // 输入框(密码)
    case 'TEXT':                    // 输入框(单行文本)
    case 'IDCARD':                  // 输入框(身份证)
    case 'PHONE_INPUT_WIDGET':      // 电话输入框
    case 'SELECT_WIDGET':           // 下拉框
    case 'MULTI_SELECT_WIDGET_V2':  // 多选下拉框
    case 'RANGEDATEPICKER_WIDGET':  // 日期区间
    case 'CASCADER_WIDGET':         // 级联选择框
    case 'CHECKBOX_GROUP_WIDGET':   // 复选框组
    case 'RADIO_GROUP_WIDGET':      // 单选组框
    case 'SWITCH_GROUP_WIDGET':     // 开关组
    case 'SLIDER_WIDGET':           // 滑块
    case 'AMAP_WIDGET':             // 定位
    case 'text':                    // AntdTable -- 纯文本
      return 'string';
    case 'MULTI_LINE_TEXT':         // 输入框(多行文本)
      return 'textarea';
    case 'html':                    // 富文本
    case 'rich':                    // AntdTable -- 富文本
      return 'richtext';
    case 'DATE_PICKER_WIDGET2':     // 日期选择框
    case 'date':
      return 'datetime';
    case 'NUMBER':                  // 输入框(数字)
    case 'INPUTNUMBER_WIDGET':      // 数字输入框
      return isDecimal ? 'decimal' : 'integer';
    case 'CHECKBOX_WIDGET':         // 复选框
    case 'SWITCH_WIDGET':           // 开关
    case 'switch':                  // AntdTable -- 开关
      return 'bool';
    case 'UPLOAD_IMAGE_WIDGET':     // 图片上传
    case 'image':                   // AntdTable -- 图片
      return 'image';
    case 'video':                   // AntdTable -- 视频
    case 'UPLOAD_WIDGET':           // 上传文件
      return 'file';
    case 'EMAIL':                   // 输入框(邮箱)
      return 'mail';
    case 'dict':                   // 输入框(邮箱)
      return 'dict';
    default:
      return 'string';
  }
}

export class PageEleModel {
  private treeData: any[] = [];
  private keyMap: any = {};
  private keyCnt: number = 0;
  private dicMap: any = {};

  getPageEleTree(): any[] {
    return this.treeData;
  }

  async init() {
    await getDictOptions().then(res => {
      res.forEach((i: any) => {
        this.dicMap[i.id] = i
      });
    });

    this.keyMap = {};
    this.treeData = [];
    this.keyCnt = 0;
    let appId = ModelingApi.getAppIdRaw();
    Api.get(URL_ALL_PAGE_ELE + appId).then((data: any) => {
      let pageData: any = data || [];
      this._buildTreeData(pageData);
    });
  }

  private _buildTreeData(pageData: any) {
    _.forEach(pageData, page => {
      let pageNode = this._buildNode(page);
      if (pageNode) {
        this._addChildren(page, pageNode);
        this.treeData.push(pageNode);
      }
    })
  }

  private _addChildren(data: any, node: any) {
    if (node.isLeaf) {
      return;
    }

    _.forEach(data.children, item => {
      let cNode = this._buildNode(item);
      node.children.push(cNode);
      this._addChildren(item, cNode);
    })
  }

  private _buildNode(keyData: any) {
    let title = this._getNodeTitle(keyData);
    if (title) {
      if (!this.keyMap[title]) {
        let _defaultValue = keyData.defaultValue || null;
        let _len = keyData.length || 0;
        let _type = getPropertyType(keyData.inputType || keyData.type, keyData?.isDecimal);
        if (_defaultValue && _defaultValue.splice) {
          if (_defaultValue.length > 0) {
            _defaultValue = _defaultValue.join(',');
            if (_defaultValue.length > _len) {
              _len = _defaultValue.length * 2
            }
          } else {
            _defaultValue = null;
          }
        }
        let cNode: any = {
          title: title,
          key: this._getNodeKey(title),
          valid: true,
          disabled: false,
          children: [],
          isLeaf: !keyData.children || keyData.children.length == 0,
          type: _type,
          defaultValue: _defaultValue,
          nullable: !keyData.isRequired,
          length: _len,
        };
        if (_type == 'dict' && keyData.dictId && this.dicMap[keyData.dictId]) {
          cNode.dictName = this.dicMap[keyData.dictId].value;
        }
        return cNode;
      }
    }

    return null;
  }

  private _getNodeKey(title: any) {
    this.keyCnt = this.keyCnt + 1;
    let key = title + "_" + this.keyCnt;
    this.keyMap[key] = title;
    return key;
  }

  private _getNodeTitle(data: any) {
    if (data.pageName) {
      return data.pageName;
    }
    let name = data.label ? (data.label == 'null' ? data.widgetName : data.label) : data.widgetName;
    return name || data.displayName;
  }

  getAttrByKey(key: any) {
    return this.keyMap[key];
  }
}
