import _ from "lodash";
import {PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";
import {getModelPk, getPropertyDefaultLength, isPk, isRelProperty} from "@byk/pages/modeling/ModelDataUtil";

interface GraphModel {
  metadata: {
    models: any,
    properties: any,
    visibleGraph: any,
    modelsByName: any,
    relProperties: any,
    modelPk: any,
    propertyByName: any,
  },
  nodeCache: any,
  loadFlag: boolean,
  model: any, // 当前编辑的模型
}

export type RelPropertyType = {
  source?: any,  // 图上连线的 source, 连线没有箭头的端
  target?: any,  // 图上连线的 target, 连线箭头则
};

class GraphModel {
  constructor() {
    this.metadata = {
      models: {},
      properties: {},
      visibleGraph: {},
      modelsByName: {},
      relProperties: [],
      modelPk: {},
      propertyByName: {},
    };
    this.nodeCache = {};
    this.loadFlag = false;
  }

  initMetaData(models: any[] = []) {
    let visibleGraph = this.metadata.visibleGraph;
    this.metadata = {
      models: {},
      properties: {},
      visibleGraph: {},
      modelsByName: {},
      relProperties: [],
      modelPk: {},
      propertyByName: {},
    };

    let modelsObj: any = {};
    let propertiesObj: any = {};
    let visibleGraphObj: any = {};
    let modelsByNameObj: any = {};
    let fkPropertiesObj: any = []
    let modelPk: any = {};
    let propertyByName: any = {};

    models.forEach(item => {
      let key = item.id + '';
      let nameKey = item.name.toUpperCase();
      modelsObj[key] = item;
      modelsByNameObj[nameKey] = item;
      visibleGraphObj[key] = visibleGraph[key];
      if (item.properties) {
        item.properties = this.reSortProperties(item.properties);
        item.properties.forEach((proItem: any) => {
          if (proItem.type != 'decimal') {
            proItem.scale = null;
          }
          propertiesObj[proItem.id + ''] = proItem;
          propertyByName[proItem.model + '_' + proItem.name] = proItem;
          if (isRelProperty(proItem.type)) {
            fkPropertiesObj.push(proItem);
          }
          if (proItem.primaryKey) {
            modelPk[item.name] = proItem;
          }
        });
      }
    })

    this.metadata.models = {...modelsObj};
    this.metadata.properties = {...propertiesObj};
    this.metadata.visibleGraph = {...visibleGraphObj};
    this.metadata.modelsByName = {...modelsByNameObj};
    this.metadata.relProperties = [...fkPropertiesObj];
    this.metadata.modelPk = {...modelPk};
    this.metadata.propertyByName = {...propertyByName};
  }

  setModel(model: any) {
    this.model = model;
  }

  /**
   * 设置模型是否有图
   * @param id
   * @param isVisible
   */
  setVisibleGraph(id: any, isVisible: boolean) {
    this.metadata.visibleGraph[id + ''] = isVisible;
    if (this.getModel(id)) {
      this.getModel(id)['visibleGraph'] = isVisible;
    }
  }

  getPropertyById(id: any): any {
    if (id && this.metadata.properties[id + '']) {
      return {...this.metadata.properties[id + '']};
    }
    return null;
  }

  getPropertyByName(model: any, name: any): any {
    return this.metadata.propertyByName[model + '_' + name] || {}
  }

  getModelPkByName(name: any): any {
    return this.metadata.modelPk[name];
  }

  getModels(): any[] {
    let ret: any[] = [];
    _.forEach(this.metadata.models, (value: any, key: any) => {
      value['visibleGraph'] = this.metadata.visibleGraph[key];
      ret.push(value);
    });
    return ret;
  }

  getRelPropertiesMatched(relProperties?: any): any {
    let relPropertyList = relProperties || this.metadata.relProperties;
    let proKv: any = {};
    let matchFlag: any = {};
    (relPropertyList || []).forEach((proItem: any) => {
      if (isRelProperty(proItem.type)) {
        let key = proItem.model + '_' + proItem.targetModel + '_' + proItem.mappedBy;
        proKv[key] = proItem;
      }
    });

    let ret: any = [];
    this.metadata.relProperties.forEach((proItem: any) => {
      if (isRelProperty(proItem.type)) {
        let key = proItem.model + '_' + proItem.targetModel + '_' + proItem.mappedBy;
        if (!matchFlag[key]) { // 没有匹配过, 匹配处理
          matchFlag[key] = true;
          if (proItem.mappedBy) {
            let matchKey = proItem.targetModel + '_' + proItem.model + '_' + proItem.name;
            matchFlag[matchKey] = true;
          }
          ret.push(proItem);
        }
      }
    });

    return ret;
  }

  getModel(key: any): any {
    let mk = key + '';
    if (this.metadata.models[mk]) {
      return {...this.metadata.models[mk]};
    }

    let nameKey = mk.toUpperCase();
    if (this.metadata.modelsByName[nameKey]) {
      return {...this.metadata.modelsByName[nameKey]};
    }
    return null;
  }

  updateModel(modelData: any) {
    let models: any[] = [];
    if (!modelData && !modelData.id) {
      return;
    }

    models.push(modelData);
    _.forEach(this.metadata.models, (value: any, key: any) => {
      if (key != modelData.id) {
        models.push(value);
      }
    });

    this.initMetaData(models);
  }

  removeModel(modelId: any) {
    let models: any[] = [];
    if (!modelId) {
      return;
    }

    _.forEach(this.metadata.models, (value: any, key: any) => {
      if (key != modelId) {
        models.push(value);
      }
    });

    this.initMetaData(models);
  }

  addProperty(property: any) {
    if (property) {
      this.metadata.properties[property.id + ''] = property;
      let model = this.metadata.models[property.modelId + ''];
      model.properties.push(property);
    }
  }

  removeProperty(propertyId: any) {
    if (propertyId) {
      let property = this.metadata.properties[propertyId + '']
      if (property) {
        let model = this.metadata.models[property.modelId + ''];
        if (model) {
          let properties: any = [];
          _.forEach(model.properties, (item) => {
            if (item.id != propertyId) {
              properties.push(item);
            }
          });
          model.properties = [...properties];

          this.updateModel(model);
        } else {
          console.log('removeProperty>>>', 'model is null:' + property.modelId);
        }
      } else {
        console.log('removeProperty>>>', 'property is null:' + propertyId);
      }
    }
  }

  getRelProperty(propertyId: any): RelPropertyType {
    let ret: RelPropertyType = {};
    let property = this.getPropertyById(propertyId) || {};
    let targetModel: any = this.getModel(property.targetModel) || {};
    ret.source = property;
    if (property.mappedBy) {
      ret.target = _.find(targetModel.properties, (item) => {
        return item.name == property.mappedBy
      });
    } else {
      ret.target = null;
    }
    return ret;
  }

  getMapperProperty(model: any, propertyName: any) {
    let properties = model?.properties || []
    let filterProperties = _.filter(properties, (item: any) => item.mappedBy == propertyName)
    if (filterProperties.length == 1) {
      return filterProperties[0]
    } else {
      return null;
    }
  }

  /**
   * 判断图的关系连线的source, target是否改变
   * @param propertyId: 已创建的目标关系的target属性ID
   * @param lineSourcePropertyId：图连线的source
   * @param lineTargetPropertyId: 图边线的target
   */
  lineRelIsChanged(propertyId: any, lineSourceModelId: any, lineTargetModelId: any): boolean {
    let relPro: RelPropertyType = this.getRelProperty(propertyId);
    let sourceModelId = relPro?.source?.modelId;
    let targetModelId = relPro?.target?.modelId;
    return !(sourceModelId == lineSourceModelId && targetModelId == lineTargetModelId);
  }

  /**
   * 更新属性
   * @param result
   */
  updateProperties(result: any) {
    _.forEach(result || [], property => {
      property.key = property.id;

      let p = this.getPropertyById(property.id);
      let m = this.getModel(property.model);
      if (!p) { // 新增的属性还没有同步到graphModel.metadata
        let properties = m.properties;
        this.metadata.models[m.id + ''].properties = [...properties, property];
        this.metadata.properties[property.id + ''] = {...property};
      } else {
        let properties: any = [];
        _.forEach(m.properties, item => {
          if (item.id == p.id) {
            properties.push({...property});
          } else {
            properties.push(item);
          }
        })
        this.metadata.properties[property.id + ''] = {...property};
        this.metadata.models[m.id + ''].properties = [...properties];
      }
    });
  }

  removeProperties(properties: any) {
    _.forEach(properties || [], property => {
      this.removeProperty(property.id);
      this.removeProperties(this.getRelatedPropertiesBy(property));
    });
  }

  getRelatedPropertiesBy(property: any) {
    let properties: any = [];
    if (property.type == PROPERTY_REL_TYPE.O2M || property.type == PROPERTY_REL_TYPE.M2O) {
      let model = this.getModel(property.modelId);
      if (model && model.properties) {
        _.forEach(model.properties, (item) => {
          if (item.type == PROPERTY_REL_TYPE.RELATED && item.targetModel == property.targetModel && item.mappedBy == item.name) {
            properties.push(item);
          }
        });
      }
    }
    return properties;
  }


  /**
   * 重新对属性排序
   * @param item
   * @private
   */
  reSortProperties(properties: any = []) {
    properties.forEach((item: any) => {
      item.key = item.id || item.key;
      if (getPropertyDefaultLength(item.type)) {
        item.length = item.length || getPropertyDefaultLength(item.type);
      } else {
        item.length = item.length == 0 ? null : item.length;
      }
    });
    return _.sortBy(properties, ['description', 'name']);
  }

  /**
   * 获取类型不"related"的属性
   * @param model
   */
  getNotRelatedProperties(model: any = {}) {
    let properties: any = [];
    _.filter(model.properties || [], (item) => {
      item.type != PROPERTY_REL_TYPE.RELATED && properties.push({...item});
    });

    return properties;
  }

  /**
   * 获取类型是“related”的属性
   * @param model
   */
  getRelatedProperties(model: any = {}) {
    let relatedProperties: any = [];
    _.filter(model.properties || [], (item) => {
      item.type == PROPERTY_REL_TYPE.RELATED && relatedProperties.push({...item});
    });

    return relatedProperties;
  }

  /**
   * 缓存图的节点
   * @param key
   * @param nodeCell
   */
  cacheCellOrEdge(key: any, nodeCell: any) {
    key && nodeCell && (this.nodeCache[key + ''] = nodeCell);
  }

  /**
   * 获取图的节点
   * @param key
   */
  getCellOrEdgeFromCache(key: any) {
    return this.nodeCache[key + ''];
  }

  /**
   * m2o 查询关联模型的主键类型
   * @param property
   */
  getPropertyType(property: any) {
    let type = property.type;
    if (type == PROPERTY_REL_TYPE.M2O) {
      let targetModel = this.getModel(property.targetModel);
      let pk = getModelPk(targetModel?.properties);
      type = pk ? pk.type : type;
    }

    return type;
  }

  getFkPropertyOptions(model: any, multiModel: any, fkPro: any) {
    let options: any = [];
    let modelPk = getModelPk(model.properties);
    if (modelPk) {
      _.forEach((multiModel.properties || []), (item) => {
        /*
        1. 属性没有关联过
        2. 不是主键
        3. 与模型主键类型一致
         */
        let itemType = this.getPropertyType(item);
        if ((fkPro?.id == item.id)
          || (!item.targertModel && !isPk(item) && modelPk.type == itemType && !item.targetModel)) {
          options.push({
            ...item,
            "label": item.description,
            "value": item.name,
          });
        }
      });
    }

    return options;
  }

  getModelOptions() {
    let options: any[] = []
    _.forEach(this.metadata.models || [], item => {
      options.push({
        "label": item.description,
        "value": item.name,
        "id": item.id,
      })
    })

    return options;
  }

  // 可用于mappedBy的所有属性
  // 1. 过滤主表自己
  // 2. o2m过滤主键
  // 3. 类型与主表主键一致（长整型）
  // 4. 没有建立关系的字段
  allMappedByProperties = (property: any = {}, mappedModel?: any, dataSource?: any) => {
    let allMappedBy: any = [];
    const type = property?.type
    const isRelPropertyFlag = isRelProperty(type);
    if (isRelPropertyFlag) {
      let allMappedByDefined: any = {}; // 可用作mappedBy的属性定义
      let allMappedByExist: any = {};   // 通过mappedBy建立关系的字段
      let models = mappedModel ? [mappedModel] : this.metadata.models;

      if (property?.type == PROPERTY_REL_TYPE.O2M) { // 一对多属性配置
        let modelPk = this.getModelPkByName(property?.model);
        modelPk && _.forEach(models, item => {
          if (item.name != property.model) { // 1. 过滤主表自己
            _.forEach(item.properties || [], pro => {
              // 2. 类型与主表主键一致（长整型）, 或者定义为m2o的属性
              if (!pro.primaryKey && (pro.type == modelPk.type || (pro.type == PROPERTY_REL_TYPE.M2O && property.model == pro.targetModel))) {
                allMappedByDefined[pro.model + '_' + pro.name] = true;
              }
            })
          }
        })

        // 当前模型已映射的属性
        let curModelProperties = dataSource || this.getModel(property.model)?.properties;
        _.forEach(curModelProperties || [], pro => {
          if (pro.mappedBy && pro.key != property.key) {
            allMappedByExist[pro.targetModel + '_' + pro.mappedBy] = true;
          }
        })

        // 3. 排除建立关系的字段
        _.forEach(this.metadata.models || [], item => {
          _.forEach(item.properties || [], pro => {
            let key = pro.model + '_' + pro.name;
            if (!allMappedByExist[key] && allMappedByDefined[key]) {
              allMappedBy.push(pro)
            }
          })
        })
      }
    }

    return allMappedBy;
  }

  getTargetModelOptions(property: any = {}) {
    let options: any[] = [];

    if (property?.type == PROPERTY_REL_TYPE.O2M) {
      let mappedByProperties: any = this.allMappedByProperties(property); // 可用户mappedBy的属性
      let optionMap: any = {};
      _.forEach(mappedByProperties || [], item => {
        let model = this.getModel(item.model);
        if (!optionMap[model.name]) {
          let option = {
            "label": model.description,
            "value": model.name,
            "id": model.id,
          };
          options.push(option);
          optionMap[model.name] = true;
        }
      })
    } else {
      _.forEach(this.metadata.models || [], model => {
        options.push({
          "label": model.description,
          "value": model.name,
          "id": model.id,
        });
      })
    }

    return options;
  }

  getModelPropertiesOptions(property: any = {}, dataIndex: any, dataSource?: any) {
    let modalName = dataIndex == 'mappedBy' ? property.targetModel : property.model;
    let isRelatedType = property.type == PROPERTY_REL_TYPE.RELATED;
    if (isRelatedType) {
      modalName = dataIndex == 'mappedBy' ? property.model : property.targetModel;
    }

    let options: any[] = []
    let model = this.getModel(modalName);
    if (property?.type == PROPERTY_REL_TYPE.O2M) {
      let mappedByProperties: any = this.allMappedByProperties(property, model, dataSource);
      _.forEach(mappedByProperties, item => {
        let option = {
          "label": item.description,
          "value": item.name,
          "id": item.id,
        };
        options.push(option);
      })
    } else if (isRelatedType) {
      _.forEach(dataSource || model.properties || [], pro => {
        options.push({
          "label": pro.description,
          "value": pro.name,
          "id": pro.name,
        })
      })
    }
    return options;
  }

  getRelatedByOrMappedByPropertyDesc(property: any = {}, dataIndex: any) {
    let modalName = dataIndex == 'mappedBy' ? property.targetModel : property.model;
    if (property.type == PROPERTY_REL_TYPE.RELATED) {
      modalName = dataIndex == 'mappedBy' ? property.model : property.targetModel;
    }
    let properties: any = this.getModel(modalName)?.properties || [];
    let pro = properties.find((item: any) => item.name == property[dataIndex])
    return pro?.description;
  }

  getModelDesc(property: any = {}, dataIndex: any) {
    let modelName = property[dataIndex] || '';
    let model = this.getModel(modelName);
    return model?.description || '';
  }

  /**
   * 是否创建了索引
   * @param property
   */
  isIndexRef(property: any) {
    let indexes = this.getModel(property.model)?.indexs || [];
    let refIndex = false;
    indexes.forEach((idx: any) => {
      if (!refIndex && idx.properties.indexOf(property.name) > -1) {
        refIndex = true;
      }
    });

    return refIndex;
  }

  /**
   * 根据属性ID获取连线
   * @param pid
   */
  getMxEdge(pid: any) {
    let cellPid = pid;
    let edge = null;
    let edges: any = this.getMxGraph().getChildEdges();
    _.forEach(edges || [], item => {
      if (item.pid == cellPid) {
        edge = item;
      }
    })

    return edge;
  }

  getMxGraph() {
    // @ts-ignore
    return window.graph;
  }

}

export default GraphModel;
