import React from 'react';
import {PictureOutlined} from "@ant-design/icons/lib";
import styled from "styled-components";

const ImageWrapper = styled.div<{
  borderRadius: string;
  boxShadow?: string;
}>`
  width: 100%;
  height: 100%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  border-radius: ${({borderRadius}) => borderRadius};
  box-shadow: ${({boxShadow}) => `${boxShadow}`} !important;
`

function DefaultImage(props: any) {
  return (
    <ImageWrapper {...props}>
      <PictureOutlined/>
    </ImageWrapper>
  )
}

export default DefaultImage;
