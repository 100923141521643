import React, {useEffect, useState} from 'react';
import DatePicker from './DatePicker';
import SelectorPicker from './SelectorPicker';
import TimePicker from './TimePicker';
import MultiPicker from './MultiPicker';
import MonthPicker from './MonthPicker';

const TPickerWidget = (props:any) => {
  const { mode, value:wValue, isVisible, updateWidgetProperty } = props;
  const [value, setValue] = useState<string>('');
  const onDateChange = (v:any)=>{
    setValue(v);
    updateWidgetProperty&&updateWidgetProperty(v);
  };

  useEffect(()=>{
    setValue(wValue);
  }, [wValue])

  return (
    <>
      {
        mode === 'month'&& <MonthPicker {...props} value={value} onDateChange={onDateChange} />
      }
      {
        mode === 'date'&& <DatePicker {...props} value={value} onDateChange={onDateChange} />
      }
      {
        mode === 'selector'&& <SelectorPicker {...props} value={value} onDateChange={onDateChange} />
      }
      {
        mode === 'time'&& <TimePicker {...props} value={value} onDateChange={onDateChange} />
      }
      {
        mode === 'multiSelector'&& <MultiPicker {...props} value={value} onDateChange={onDateChange} />
      }
    </>
  );
};

export default TPickerWidget;
