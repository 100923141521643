import React, { useRef, useState, useEffect } from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, Row, Col, Form, Input, message, Select, Switch, PageHeader, Layout } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import moment from 'moment';
import { uuid2 } from "@byk/utils/Utils";
import localStorage, {getTenant} from "utils/localStorage";
import {apiPath} from "@byk/routes";
import XModal from "@byk/pages/components/XModal";
class ScheduledTaskManagement extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.formRef = React.createRef();
    //this.focusTextInput = this.focusTextInput.bind(this);
  }
  state = {
    record: null,
    visible: false,
    rows: [],
    roleData: [],
    editData: null,
    LowcodeServiceList: [],
    initParams: {
      tenant: getTenant()
    },
    key: uuid2(),

    isModalForm: false,
    formCfg: {
      title: "编辑",
      okText: "确定",
      type: null
    },
    titeType: { "a": "编辑", "b": "编辑", "c": "新增" },
    btnTextType: { "a": "保存", "b": "保存", "c": "保存" },
  };

  bindLowCodeService = async () => {
    let result: any = await Api.get(`${apiPath}/${getTenant()}/subapp/LowcodeService/list`, { tenant: getTenant() });
    if (result.success) {
      this.setState({ LowcodeServiceList: result.result });
    }
  }
  async componentDidMount() {
    this.bindLowCodeService();
  }

  showModal(type: string) {
    this.setState({ isModalForm: true }, () => {
      let _data: any = this.state.formCfg.data;
      if (type == "b") {
        this.formRef.current?.setFieldsValue({
          code: _data.code, name: _data.name,
          cron: _data.cron, single: _data.single, enable: _data.enable
        });
      }
      else {
        this.formRef.current?.setFieldsValue({
          code: null, name: '',
          cron: '', single: true, enable: true
        });
      }
    });
  };

  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data,
      }
    }, () => { this.showModal(type); });
  };
  handleCancel = () => {
    this.setState({ isModalForm: false });
  };

  init() {
    let params: any = this.state.initParams;
    this.setState({ initParams: params }, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };

  //更新
  onFinish = async (value: any) => {
    let postUrl = `${apiPath}/${getTenant()}/subapp/SysTask`;
    let _data: any = {};
    let cfg: any = this.state.formCfg;
    let result: any = null;
    _data.code = value.code;
    _data.name = value.name;
    _data.cron = value.cron;
    _data.type = value.type;
    _data.single = value.single;
    _data.enable = value.enable;
    _data.tenant = getTenant();
    //创建
    if (cfg.type == "c") {
      //_data.id = value.service;
      result = await Api.post(postUrl, _data);
    }
    //编辑
    if (cfg.type == "b") {
      _data.id = cfg.data.id;
      result = await Api.put(postUrl, _data);
    }
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel();
      this.init();
    }
    else {
      //message.error(result.message);
    }

  }

  render() {
    const apiGet: string = `${apiPath}/${getTenant()}/subapp/SysTask/list`;
    const apiDel: string = `${apiPath}/${getTenant()}/subapp/SysTask`;

    //表格refresh
    const refreshTable = (params?: any) => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    };

    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        refreshTable();
      }
    };

    //查询项配置
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "任务名称",
        allowClear: true,
        col: 8,
      },
      // {
      //   itemType: "select",
      //   placeholder: "请输入",
      //   name: "type",
      //   label: "任务类型",
      //   allowClear: true,
      //   col: 8,
      //   data: categoryDic,
      // },
    ];
    const searchProps = {
      option: searchConfig,
      onSearch: refreshTable,
      btnBoolean: true,
    };



    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "ids",
        key: "ids",
        render: (text, record, index) => `${index + 1}`,
        width: 100,
      },
      {
        title: "任务名称",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "编号",
        dataIndex: "code",
        key: "code",
        width: 100,
      },
      {
        title: "运行时机",
        dataIndex: "cron",
        key: "cron",
        width: 100,
      },
      {
        title: "是否正在运行",
        dataIndex: "running",
        key: "running",
        render: (_, record: any) => (
          <>
            {record.running == true ? "是" : "否"}
          </>
        ),
      },
      {
        title: "是否为单例运行",
        dataIndex: "single",
        key: "single",
        render: (_, record: any) => (
          <>
            {record.single == true ? "是" : "否"}
          </>
        ),
      },
      {
        title: "是否启用",
        dataIndex: "enable",
        key: "enable",
        render: (_, record: any) => (
          <>
            {record.enable == true ? "是" : "否"}
          </>
        ),
      },
      {
        title: "上次运行的计算机",
        dataIndex: "beforeRunHost",
        key: "beforeRunHost",
        width: 100,
      },
      {
        title: "上次运行时间",
        dataIndex: "beforeRunTime",
        key: "beforeRunTime",
        render: (_, record: any) => (
          record.beforeRunTime && moment(record.beforeRunTime).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "上次运行使用时间(秒)",
        dataIndex: "useTime",
        key: "useTime",
        width: 100,
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        render: (_, record: any) => (
          moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <Space size="middle">
            <Button type="link" onClick={() => { this.openForm("b", record) }}>
              编辑
            </Button>
            <Popconfirm
              placement="left"
              title={"确认删除该定时任务？"}
              onConfirm={() => deleteClick(record)}
              okText="删除"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </Space>
        ),
        width: 100,
      },
    ];
    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams
    };

    return (
      <>
      <PageHeader className="site-page-header-main" title="定时任务管理"/>
      <Layout>

        <div className="x-main-box" key={this.state.key}>
          <SearchForm ref={this.searchRef} {...searchProps} />
          <div style={{ width: "100%", height: "50px" }}>
            <Button type="primary" onClick={() => this.openForm("c", {})} className="x-fr">
              新增
            </Button>
          </div>

          <TableList ref={this.tableRef} {...tableProps} />

          <Modal title={this.state.formCfg.title} width={"60%"} open={this.state.isModalForm} onOk={() => this.showModal} onCancel={this.handleCancel} footer={[]}  getContainer=".x-sys-manage-wrapper">
            <Row>
              <Col className="gutter-row" md={24}>
                <Form ref={this.formRef} labelCol={{ span: 3 }}
                  wrapperCol={{ span: 18 }} onFinish={this.onFinish}>
                  <Form.Item label="任务名称" name="name"
                    rules={[{ required: true, message: "请输入!" }]}>
                    <Input maxLength={100} placeholder="" />
                  </Form.Item>
                  <Form.Item label="服务" name="code"
                    rules={[{ required: true, message: "请选择!" }]}>
                    <Select
                      disabled={this.state.formCfg.type == "b"}
                      style={{ width: "100%" }}
                      options={this.state.LowcodeServiceList}
                      fieldNames={{ label: 'name', value: 'code' }}
                      showSearch
                      filterOption={(input: any, option: any) =>
                        (option["name"] ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="type"
                    hidden={true}
                    name="type"
                    rules={[{ required: false, message: "请输入!" }]}
                  >
                    <Input value="service" />
                  </Form.Item>
                  <Form.Item label="运行时机" name="cron"
                    rules={[{ required: true, message: "请输入!" }]}>
                    <Input placeholder=""  maxLength={100}/>
                  </Form.Item>
                  <Form.Item label="是否单例" name="single"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <Switch defaultChecked />
                  </Form.Item>
                  <Form.Item label="是否启用" name="enable"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <Switch defaultChecked />
                  </Form.Item>

                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Button onClick={this.handleCancel}>取消</Button>
                    <Button className="x-ml10" type="primary" htmlType="submit">
                      {this.state.formCfg.okText}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
        </Layout>
      </>
    );
  }
}
export default withRouter(ScheduledTaskManagement);
