import React from "react";
import type {WidgetCardProps} from "widgets/BaseWidget";
import styled from "styled-components";
import {useWidgetDragResize} from "utils/hooks/dragResizeHooks";
import AnalyticsUtil from "utils/AnalyticsUtil";
import {generateReactKey} from "utils/generators";
import {useWidgetSelection} from "utils/hooks/useWidgetSelection";
import {IconWrapper} from "constants/IconConstants";

type CardProps = {
  details: WidgetCardProps;
};

export const Wrapper = styled.div`
  padding: 10px 5px 10px 5px;
  border-radius: var(--ads-v2-border-radius);
  border: none;
  position: relative;
  color: var(--ads-v2-color-fg);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  img {
    cursor: grab;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: var(--ads-v2-color-bg-subtle);
  }

  & i {
    font-family: ${(props) => props.theme.fonts.text};
    font-size: ${(props) => props.theme.fontSizes[7]}px;
  }
`;

export const BetaLabel = styled.div`
  font-size: 10px;
  background: var(--ads-v2-color-bg-emphasis);
  margin-top: 3px;
  padding: 2px 4px;
  border-radius: 3px;
  position: absolute;
  top: 0;
  right: -2%;
`;

export const IconLabel = styled.h5`
  min-height: 32px;
  text-align: center;
  margin: 0;
  /* text-transform: uppercase; */
  font-weight: ${(props) => props.theme.fontWeights[1]};
  flex-shrink: 1;
  font-size: 11px;
  line-height: ${(props) => props.theme.lineHeights[2]}px;

  &::selection {
    background: none;
  }
`;

function WidgetCard(props: CardProps) {
  const {setDraggingNewWidget} = useWidgetDragResize();
  const {deselectAll} = useWidgetSelection();

  const onDragStart = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    AnalyticsUtil.logEvent("WIDGET_CARD_DRAG", {
      widgetType: props.details.type,
      widgetName: props.details.displayName,
    });
    setDraggingNewWidget &&
    setDraggingNewWidget(true, {
      ...props.details,
      widgetId: generateReactKey(),
    });
    deselectAll();
  };
  const nameList: any = {
    "AtNavBar": "T导航栏",
    "AtImagePicker": "T图片选择",
    "AtProgress": "T进度条",
    "AtDivider": "T分隔符",
    "AtIcon": "T图标按钮",
    "AtPicker": "T选择器",
    "AtSwitch": "T开关",
    "AtInputNumber": "T计数器",
    "AtTextArea": "T多行文本",
    "AtText": "T文本",
    "AtButton": "T按钮",
    "AtInput": "T输入框",
    "AtList": "T列表",
    "AtSwiper": "T滑动",
    "AtGrid": "T栅格",
    "AtTabBar": "T标签",
    "AtRadio": "T单选",
    "AtCheckbox": "T多选",

    "Text": "文字",
    "Button": "按钮",
    "Icon": "图标",
    "Button Group": "按钮组",
    "Divider": "分隔线",
    "Icon button": "图标按钮",
    "Table": "表格",
    "List": "列表",

    "XImage": "图片",
    "Carousel": "轮播",
    "Marquee": "跑马灯",
    "Video": "视频",

    "Container": "容器",
    "Form": "表单容器",
    "SearchForm": "查询表单",
    "JSON Form": "JSON表单",
    "Modal": "对话框",
    "Drawer": "抽屉",
    "DynamicForms": "动态表单",
    "XCollapse": "折叠面板",

    "Menu button": "菜单按钮",
    "Breadcrumb": "面包屑",
    "Tree": "树形导航",
    "Tabs": "标签页",
    "XStep": "步骤条",

    "Input": "输入框",
    "Select": "下拉框",
    "MultiSelect": "多选下拉框",
    "Cascader": "级联选择器",
    "DatePicker": "日期时间选择框",
    "RangeDatePicker": "日期时间区间",
    "Checkbox": "复选框",
    "Checkbox Group": "复选框组",
    "Radio Group": "单选组框",
    "Switch": "开关",
    "Switch Group": "开关组",
    "Upload": "上传文件",
    "UploadImage": "上传图片",
    "Slider": "滑块",
    "Search": "查询",
    "Calendar": "日历",
    "InputNumber": "计数器",
    "Chart": "报表",
    "Image": "图片",
    // "FilePicker": "文件上传【废弃】",
    "Multi TreeSelect": "",
    "Phone Input": "电话输入框",
    "Rich Text Editor": "富文本框",
    "TreeSelect": "树形下拉框",
    "FixedContainer": "固定容器",
    "AntdTable": "表格",
    "XTiming": "定时器",
    "XEchartsLine": "折线柱状图",
    "XEchartsPie": "饼图",
    "XEchartsGauge": "仪表图",
    "XEchartsDadar": "雷达图",
    "XEchartsFunnel": "漏斗图",
    "XEchartsMap": "地理坐标图",
    "XEcharts": "通用Echart",
    "Amap": "定位",
	  "AmapView": "地图",
    "SubTable":"子表",
    "Progress":"进度条"
  };
  const getCnName = (eName: any) => {
    let cnName = nameList[eName];
    if (cnName != null && cnName != "" && cnName != undefined) {
      return cnName;
    } else {
      return eName;
    }
  };

  const type = `${props.details.type.split("_").join("").toLowerCase()}`;
  const className = `t--widget-card-draggable-${type}`;
  return (
    <Wrapper
      className={className}
      data-guided-tour-id={`widget-card-${type}`}
      draggable
      onDragStart={onDragStart}
    >
      <div>
        <IconWrapper>
          <img className="w-6 h-6" src={props.details.icon}/>
        </IconWrapper>
        <IconLabel>{getCnName(props.details.displayName)}</IconLabel>
        {props.details.isBeta && <BetaLabel>Beta</BetaLabel>}
      </div>
    </Wrapper>
  );
}

export default WidgetCard;
