import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TTextWidget from "../component";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";

class TText extends TaroWidget<TTextWidgetProps, WidgetState> {
  constructor(props: TTextWidgetProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  static getPropertyPaneEventsConfig() {
    return super.getWidgetEvents("AtTextWidget");
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "样式",
        children: [
          {
            propertyName: "fontSize",
            label: "文字大小(单位:px)",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 12,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "fontStyle",
            label: "加粗&斜体",
            helpText: "Controls the font emphasis of the text displayed",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "color",
            label: "字体颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "bgColor",
            label: "背景颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "isCircle",
            label: "圆行",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.BOOLEAN,
            },
          },
          {
            propertyName: "textAlign",
            label: "对齐方式",
            helpText: "Controls the horizontal alignment of the text",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {
                startIcon: "align-left",
                value: "left",
              },
              {
                startIcon: "align-center",
                value: "center",
              },
              {
                startIcon: "align-right",
                value: "right",
              },
            ],
            defaultValue: "LEFT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          }
        ]
      }
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.label }}`
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
      color: "{{appsmith.theme.colors.textColor}}"
    };
  }
  onClick(){
    if(this.props.onClick){
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: this.props.onClick,
        event: {
          type: EventType.ON_CLICK
        },
      });
    }
  }
  getPageView() {
    return (
      <TTextWidget {...this.props} onClick={this.onClick} />
    );
  }
  static getWidgetType(): WidgetType {
    return "AT_TEXT_WIDGET";
  }
}

export interface TTextWidgetProps extends WidgetProps {
  value: any[];
  label: string;
  onClick?: any;
  borderRadius: string;
  boxShadow?: string;
}

export default TText;
