import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XCollapse",
  iconSVG: IconSVG,
  defaults: {
    imageShape: "RECTANGLE",
    rows: 24,
    columns: 48,
    data: [
      {header: 'This is panel header 1',},
      {header: 'This is panel header 2',},
    ],
    maxZoomLevel: 1,
    enableRotation: false,
    enableDownload: false,
    widgetName: "XCollapse",
    version: 1,
    animateLoading: true,
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "280px",
            minHeight: "40px",
          };
        },
      },
    ],
  },
};

export default Widget;
