import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Button, Popover} from "antd";
import "../css/Mx.css";
import CreateModel from "@byk/pages/modeling/MxGraph/CreateModel";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {isRelProperty, propertyUnEditable} from "@byk/pages/modeling/ModelDataUtil";
import ModelTree from "@byk/pages/modeling/MxGraph/ModelTree";
import point from "assets/images/point.gif";
import GraphModel from "@byk/pages/modeling/lib/GraphModel";
import CreateProperty from "@byk/pages/modeling/MxGraph/CreateProperty";
import CreateModelRelation from "@byk/pages/modeling/MxGraph/CreateModelRelation";
import ImportModelByDataSource from "@byk/pages/modeling/ImportModelByDataSource";
import moment from "moment";
import {setCurDynamicModel} from "@byk/store/modelingReducers";
import {useDispatch} from "react-redux";
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import styled from "styled-components";
import {DelConfirm} from "@byk/pages/modeling/MxGraph/DelConfirm";
import grid_png from "@byk/assets/images/grid.png";
import _ from "lodash";
import {PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";
import ImportModelByExcel from "../ImportModelByExcel";
import {DownOutlined} from "@ant-design/icons";
import ModelBizDataQuery from "@byk/pages/modeling/ModelData/ModalBizDataQuery";
import SQLQueryEditor from "@byk/pages/modeling/SqlQueryEditor";

const ModelBtn = styled(Button)<any>`
    margin-right: 5px;
    border-radius: 5px;
    height: 36px;
    font-weight: bold;
    z-index:1;

    ${({type}) =>
  type != 'primary' && `
          color: #4C5664;
    `};

    ${({type}) =>
  type == 'primary' && `
        &:hover,
        &:focus,
        &:active {
          color: white !important;
        }
    `};

    &.ant-btn>span {
      display: inline-block;
      height: 13px;
      margin-right: 7px;
    }
  `;

const ImportBtnUl = styled.ul`
  margin-top: -15px;
  background: #ffffff;
  border-radius: 8px;
  color: #182026;
  list-style: none;
  min-width: 180px;
  padding: 5px;
  text-align: left;
  li {
    padding: 9px 12px;
    border-radius: 5px;
    padding: 9px 12px;
    border-radius: 0px;
    cursor: pointer;
  }

  li:hover {
    background-color: rgba(167, 182, 194, 0.3);
    text-decoration: none;
  }
`;

const ToolWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  width: 100%;

  .toolBtn, .toolBtn2 {
    border: none;
    margin-right: 26px;
    margin-top: 7px;
    cursor: pointer;
  }

  .toolBtn {
    color: rgb(37, 43, 58);
  }

  .toolBtn2 {
    color: gray;
  }

  .toolBtn:hover {
    color: rgb(0, 108, 242);
  }

  .vLineBar {
    border-left: 3px solid #DEDEDE;
    display: inline-block;
    height: 14px;
    margin-top: 8px;
    margin-right: 20px;
  }
`

const mx = require("mxgraph")({});

const {
  mxEvent,
  mxGraph,
  mxUtils,
  mxRubberband,
  mxGraphHandler,
  mxConstants,
  mxClient,
  mxOutline,
  mxEventObject,
  mxCodec,
  mxEdgeStyle,
  mxCellState,
  mxImage,
  mxCellHighlight,
  mxCellTracker,
  mxShape,
  mxConnectionConstraint,
  mxPoint,
  mxPolyline,
  mxConstraintHandler,
  mxEllipse,
  mxEdgeHandler,
  mxVertexHandler,
  mxCell,
  mxGeometry,
  mxPerimeter,
  mxArrow,
  mxMarker,
  mxConnectionHandler
} = mx;

// 配置自定义事件, 模型节点事件
Object.assign(mxEvent, {
  MODEL_NODE_TYPE_CLICKED: 'MODEL_NODE_TYPE_CLICKED',
});

const MODEL_NODE_TYPE = "modelNodeType";
const MODEL_NODE_ID = "modelId";
const propertyH = 24; // 每个属性的高度
const eleHeaderH = 36 // 节点的头部高
const eleFooterH = 36 // 节点的尾部高
const eleW = 218;     // 节点宽

type IMXProps = {
  style?: React.CSSProperties;
};

export let graphModel: GraphModel;

let dynamicGraph: any;
let editCell: any; // 当前点击的节点
let newNodeX: any; // 新增节点X轴
let newNodeY: any; // 新增节点Y轴
let openEditRelationFlag = true; // 是否弹出编辑模型关系窗口
const STYLE_EDGE = mxConstants.EDGESTYLE_ORTHOGONAL;

// 创建类的外围矩形并应用渐变背景
const MODEL_NODE_STYLE = MODEL_NODE_TYPE + ';fillColor=#FAFCFF;gradientColor=#F2F8FF;gradientDirection=south;' +
  'rounded=1;arcSize=10;' +
  'align=center;verticalAlign=middle;html=1;connectable=0;';

const MODEL_NODE_STYLE_HIGHLIGHT = MODEL_NODE_TYPE + ';fillColor=#FAFCFF;gradientColor=#F2F8FF;gradientDirection=south;' +
  'rounded=1;arcSize=10;' +
  'align=center;verticalAlign=middle;html=1;strokeColor=#006CF2;strokeWidth=3;dashPattern=false;connectable=0;';

const getModelIdFromCell = (cell: any) => {
  const start_str = '>###';
  const end_str = "###<";
  if (cell && cell.value && cell.value.includes(start_str) && cell.value.includes(end_str)) {
    let start = cell.value.indexOf(start_str) + start_str.length;
    let end = cell.value.indexOf(end_str);
    return cell.value.substring(start, end);
  }

  return null;
}

const updateModelCell = (graph: any, model: any, cell: any) => {
  let modelId = getModelIdFromCell(cell);
  if (modelId) {
    let {value, eleH} = getModelCellValue(model);
    let geo = graph.getCellGeometry(cell);
    geo = geo.clone();
    geo.height = eleH;
    cell.value = value;
    graph.getModel().setGeometry(cell, geo);
  }
}

// 添加连线
function resetEdge(graph: any, fkProperties: any = []) {
  let cellFlag: any = {};
  if (graph && graph.getChildEdges) {
    graph.getChildEdges().forEach((cell: any) => {
      cellFlag[cell.pid] = true;
    });
  }

  fkProperties.forEach((item: any) => {
    if (isRelProperty(item.type)) {
      let isM2o = item.type == PROPERTY_REL_TYPE.M2O;
      let edge = graphModel.getCellOrEdgeFromCache(item.id);
      let model = graphModel.getModel(item.modelId);
      if (!edge && model) { // 需要创建连线
        let relModel = graphModel.getModel(item.targetModel);
        if (relModel) {
          let cell1 = graphModel.getCellOrEdgeFromCache(model.id);
          let cell2 = graphModel.getCellOrEdgeFromCache(relModel.id);
          if (cell1 && cell2 && !cellFlag[item.id]) {
            cellFlag[item.id] = true;
            if (isM2o) {
              let style = computeEdgeStyle(cell2, cell1, isM2o);
              edge = graph.insertEdge(graph.getDefaultParent(), null, null, cell2, cell1, style);
              edge.pid = item.id;
              cellFlag[item.id] = true;
              graphModel.cacheCellOrEdge(item.id, edge);
            } else {
              let style = computeEdgeStyle(cell1, cell2, isM2o);
              // 如果是o2m， 查找m2o的连线是否存在，不存在则创建新的连线
              let m2oProperty = graphModel.getPropertyByName(item.targetModel, item.mappedBy);
              let pid = m2oProperty && m2oProperty.id;
              if (!cellFlag[pid]) {
                edge = graph.insertEdge(graph.getDefaultParent(), null, null, cell1, cell2, style);
                edge.pid = m2oProperty.id;
                cellFlag[m2oProperty.id] = true;
                graphModel.cacheCellOrEdge(m2oProperty.id, edge);
              }
            }
          }
        }
      }
    }
  })
}

const getProperties4Cell = (model: any) => {
  let pk = null;
  let relProperty: any[] = [];
  if (model?.properties) {
    model.properties.forEach((item: any) => {
      if (item.primaryKey) {
        pk = item;
      }
      if (isRelProperty(item.type)) {
        relProperty.push(item);
      }
    })

    relProperty = _.sortBy(relProperty, (item: any) => {
      return item.description;
    })

    return [pk, ...relProperty];
  }

  return [];
}

const getModelCellValue = (model: any) => {
  let properties = getProperties4Cell(model);
  let value = `<p class="modelHeader"><b>${model.description}</b><span class="modelId">###${model.id}###</span></p>`;
  properties.forEach((item: any) => {
    if (item) {
      value += `<div class="modelProperty"><span class="lf">${item.description}</span><span class="rf">${item.type}</span></div>`
    }
  })
  let eleH = eleHeaderH + propertyH * properties.length + eleFooterH;

  return {
    value,
    eleH,
  };
}

const createModelCell = (graph: any, model: any, x: number, y: number) => {
  let {value, eleH} = getModelCellValue(model);
  let v1 = graph.insertVertex(graph.getDefaultParent(), null, value, x, y, eleW, eleH, MODEL_NODE_STYLE);
  v1.modelId = model.id;
  return v1;
}

// 更新选中cell样式状态
const _updateCellStyle = (graph: any, cell: any, isSelected: boolean = false) => {
  if (graph && cell) {
    if (cell.style.includes(MODEL_NODE_TYPE)) {
      cell.setStyle(isSelected ? MODEL_NODE_STYLE_HIGHLIGHT : MODEL_NODE_STYLE);
    }
  }
}

const _updateEdgeStyle4ModelRef = (graph: any, cell: any, startToEnd: boolean = true, xy: any = {}) => {
  if (graph && cell) {
    let newStyle = _getEdgeStyle(cell.style, startToEnd, xy);
    cell.setStyle(newStyle);
  }
}

const _getEdgeStyleJsonObj = (cellStyle: any = '') => {
  let styleSplit = cellStyle.split(";");
  let styleObj: any = {};
  for (let idx in styleSplit) {
    let st = styleSplit[idx];
    if (st && st.includes("=")) {
      let stArr = st.split("=");
      styleObj[stArr[0]] = stArr[1];
    }
  }
  return styleObj;
}

const _getEdgeStyle = (cellStyle: any = '', startToEnd: boolean = true, xy: any = {}) => {
  let styleObj: any = _getEdgeStyleJsonObj(cellStyle);
  Object.keys(xy).forEach(k => {
    styleObj[k] = xy[k];
  })

  styleObj['startArrow'] = startToEnd ? 'none' : 'classic';
  styleObj['startFill'] = startToEnd ? 0 : 1;
  styleObj['endArrow'] = startToEnd ? 'classic' : 'none';
  styleObj['endFill'] = startToEnd ? 1 : 0;
  styleObj['exitDx'] = 0;
  styleObj['exitDy'] = 0;
  styleObj['entryDx'] = 0;
  styleObj['entryDy'] = 0;
  let newStyle = "";
  Object.keys(styleObj).forEach(key => {
    newStyle += key + '=' + styleObj[key] + ';'
  })

  return newStyle;
}

function calculateDistance(x1: any, y1: any, x2: any, y2: any) {
  // 计算两点在x轴和y轴上的距离差
  let dx = x2 - x1;
  let dy = y2 - y1;
  // 应用勾股定理公式
  return Math.sqrt(dx * dx + dy * dy);
}

const getValidPoint = (cell: any) => {
  let init = [0.15, 0.3, 0.45, 0.6, 0.75];
  let left = [...init];
  let top = [...init];
  let right = [...init];
  let bottom = [...init];
  if (cell && cell.edges) {
    cell.edges.forEach((edge: any) => {
      let styleObj: any = _getEdgeStyleJsonObj(edge.style);
      let x = styleObj.exitX;
      let y = styleObj.exitY;
      if (cell.modelId != edge.source.modelId) {
        x = styleObj.entryX;
        y = styleObj.entryY;
      }
      if (x && y) {
        x = parseFloat(x);
        y = parseFloat(y);
        if (x == 0) { // left
          let idx = left.indexOf(y);
          (idx > -1) && left.splice(idx, 1);
        } else if (x == 1) { // right
          let idx = right.indexOf(y);
          (idx > -1) && right.splice(idx, 1);
        } else if (y == 0) { // top
          let idx = top.indexOf(x);
          (idx > -1) && top.splice(idx, 1);
        } else if (y == 1) { // bottom
          let idx = bottom.indexOf(x);
          (idx > -1) && bottom.splice(idx, 1);
        }
      }
    })
  }

  return {
    left, top, right, bottom
  };
}

const computeEdgeStyle = (cell1: any, cell2: any, startToEnd: boolean = true) => {
  let geo1 = cell1.geometry;
  let geo2 = cell2.geometry;

  let cell1ValidPoint: any = getValidPoint(cell1);
  let cell2ValidPoint: any = getValidPoint(cell2);

  let minDistance = -1;
  let cell1Dir = null;
  let cell2Dir = null;
  let cell1DirValue = -1;
  let cell2DirValue = -1;

  const getXY = (geo: any, key: any, val: any) => {
    let x = -1, y = -1;
    if (key == "left") {
      x = geo.x;
      y = geo.y + geo.height * val;
    } else if (key == "right") {
      x = geo.x + geo.width;
      y = geo.y + geo.height * val;
    } else if (key == "top") {
      x = geo.x + geo.width * val;
      y = geo.y;
    } else if (key == "bottom") {
      x = geo.x + geo.width * val;
      y = geo.y + geo.height;
    }

    return {x, y}
  }

  const getPointXy = (dir: any, value: any) => {
    let x = -1, y = -1;
    if (dir == "left") {
      x = 0;
      y = value;
    } else if (dir == "right") {
      x = 1;
      y = value;
    } else if (dir == "top") {
      x = value;
      y = 0;
    } else if (dir == "bottom") {
      x = value;
      y = 1;
    }
    return {x, y}
  }
  Object.keys(cell1ValidPoint).forEach(key1 => {
    let dirArr1 = cell1ValidPoint[key1] || [];
    dirArr1.forEach((val1: any) => {
      let {x: x1, y: y1} = getXY(geo1, key1, val1);
      Object.keys(cell2ValidPoint).forEach(key2 => {
        let dirArr2 = cell2ValidPoint[key2] || [];
        dirArr2.forEach((val2: any) => {
          let {x: x2, y: y2} = getXY(geo2, key2, val2);
          let _minDistance = calculateDistance(x1, y1, x2, y2);
          if (minDistance == -1 || _minDistance < minDistance) {
            minDistance = _minDistance;
            cell1DirValue = val1;
            cell2DirValue = val2;
            cell1Dir = key1;
            cell2Dir = key2;
          }
        })
      })
    });
  });

  let {x: exitX, y: exitY} = getPointXy(cell1Dir, cell1DirValue);
  let {x: entryX, y: entryY} = getPointXy(cell2Dir, cell2DirValue);

  return _getEdgeStyle('', startToEnd, {exitX, exitY, entryX, entryY});
}

export const MX = ({}: IMXProps) => {
  const dispatch = useDispatch();
  const [graph, setGraph] = useState<any>(null);
  const addModelRef = useRef<any>();
  const addPropertyRef = useRef<any>();
  const createModelRelationRef = useRef<any>();
  const attributeBoxRef = useRef<any>();
  const [treeModels, setTreeModels] = useState<any>(null);
  const importModelByDataSourceRef: any = useRef();
  const importModelByExcelRef: any = useRef();
  const modelBizDataQueryRef: any = useRef();
  const sqlQueryEditorRef: any = useRef();
  const [selectedCell, setSelectedCell] = useState<any>();
  const [isModelCell, setIsModelCell] = useState<any>(false);
  const [selectedEdge, setSelectedEdge] = useState<boolean>(false);
  const [importBtnUlOpen, setImportBtnUlOpen] = useState<boolean>(false);
  const [queryModelBizDataFlag, setQueryModelBizDataFlag] = useState<boolean>(false);
  const [queryEditorFlag, setQueryEditorFlag] = useState<boolean>(true);

  useEffect(() => {
    if (!selectedCell || selectedCell && selectedCell.edge) {
      setIsModelCell(false)
    } else {
      setIsModelCell(true)
    }
  }, [selectedCell]);

  const updateCellStyle = (curCell: any, preCell: any) => {
    const graph = getGraph();
    if (curCell && preCell && curCell.id == preCell.id) {
      return;
    }

    if (preCell && !(preCell && preCell.edge)) { // 不是线
      clearCellStyle(preCell);
      graph.refresh(preCell);
    }
    if (curCell && !(curCell && curCell.edge)) { // 不是线
      _updateCellStyle(graph, curCell, true);
      graph.refresh(curCell);
    }

    editCell = curCell;
    setSelectedCell(curCell);
  }

  const clearCellStyle = (cell: any) => {
    _updateCellStyle(getGraph(), cell);
  }

  const setVisibleGraph = (id: any, visible: boolean) => {
    graphModel.setVisibleGraph(id, visible);
    setTreeModels(graphModel.getModels());
  }

  const getGraph = () => {
    // @ts-ignore
    return window['graph'];
  }

  // 拖拽模型列表创建图
  const addDraggableDom = (dom: any, model: any) => {
    let graph = getGraph();
    if (graph && !model?.visibleGraph) {
      // 创建拖拽时的预览图形
      const createDragPreview = () => {
        return dom;
      };
      // drop的处理函数
      const dropHandler = (graph: any, evt: any, cell: any, x: any, y: any) => {
        newNodeX = x;
        newNodeY = y;
        if (model) {
          if (!model.visibleGraph) {
            mxAndModel(model);
          }
        } else {
          openAddModel();
        }
      };
      mxUtils.makeDraggable(dom, graph, dropHandler, createDragPreview(), 0, 0, false, true, true);
    }
  }

  // MX窗口
  const mxContainerRef: any = React.useCallback(async (container: HTMLDivElement) => {
    mxContainerRef.current = container;
    graphModel = new GraphModel();
    setTreeModels([]);
    if (!mxClient.isBrowserSupported()) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else if (container) {
      let graph = new mxGraph(container);
      // @ts-ignore
      window['graph'] = graph;

      container.style.backgroundImage = `url(${grid_png})`;
      container.style.height = "100%";

      _setDefaultConfig(graph);
      // 配置鼠标事件
      _configMouseEvent(graph);
      // 基础配置函数
      _eventCenter(graph);
      // 扩展mx
      _extMxObjectAndFunction(graph);
      // 配置右键菜单
      _configMenu(graph);
      // 渲染xml流程图
      await loadGraphXml();
      setGraph(graph);
      new mxOutline(graph, document.getElementById("outlineContainerRef"));
    }
  }, []);

  //配置右键菜单栏
  const _configMenu = (graph: any) => {
    // 禁用浏览器默认的右键菜单栏
    mxEvent.disableContextMenu(mxContainerRef.current);
  }

  const _extMxObjectAndFunction = (graph: any) => {
    mxVertexHandler.prototype.isConstrainedEvent = function (me: any) {
    }
    // 设置连线节点
    _setConstraint(graph);
  }

  const _setConstraint = (graph: any) => {
    graph.connectionHandler.isConnectableCell = () => false;
    mxEdgeHandler.prototype.isConnectableCell = () => false;

    // Overridden to define per-shape connection points
    mxGraph.prototype.getAllConnectionConstraints = function (terminal: any) {
      if (terminal != null && terminal.shape != null) {
        if (terminal.shape.stencil != null) {
          if (terminal.shape.stencil.constraints != null) {
            return terminal.shape.stencil.constraints;
          }
        } else if (terminal.shape.constraints != null) {
          return terminal.shape.constraints;
        }
      }

      return null;
    };

    mxConstraintHandler.prototype.pointImage = new mxImage(point, 5, 5);

    // Defines the default constraints for all shapes
    mxShape.prototype.constraints = [
      new mxConnectionConstraint(new mxPoint(0.2, 0), true),
      new mxConnectionConstraint(new mxPoint(0.35, 0), true),
      new mxConnectionConstraint(new mxPoint(0.5, 0), true),
      new mxConnectionConstraint(new mxPoint(0.65, 0), true),
      new mxConnectionConstraint(new mxPoint(0.8, 0), true),

      new mxConnectionConstraint(new mxPoint(0, 0.2), true),
      new mxConnectionConstraint(new mxPoint(0, 0.35), true),
      new mxConnectionConstraint(new mxPoint(0, 0.5), true),
      new mxConnectionConstraint(new mxPoint(0, 0.65), true),
      new mxConnectionConstraint(new mxPoint(0, 0.8), true),

      new mxConnectionConstraint(new mxPoint(1, 0.2), true),
      new mxConnectionConstraint(new mxPoint(1, 0.35), true),
      new mxConnectionConstraint(new mxPoint(1, 0.5), true),
      new mxConnectionConstraint(new mxPoint(1, 0.65), true),
      new mxConnectionConstraint(new mxPoint(1, 0.8), true),

      new mxConnectionConstraint(new mxPoint(0.2, 1), true),
      new mxConnectionConstraint(new mxPoint(0.35, 1), true),
      new mxConnectionConstraint(new mxPoint(0.5, 1), true),
      new mxConnectionConstraint(new mxPoint(0.65, 1), true),
      new mxConnectionConstraint(new mxPoint(0.8, 1), true)
    ];

    // Edges have no connection points
    mxPolyline.prototype.constraints = null;

    // Enables connect preview for the default edge style
    graph.connectionHandler.createEdgeState = function (me: any) {
      let edge = graph.createEdge(null, null, null, null, null);

      return new mxCellState(this.graph.view, edge, this.graph.getCellStyle(edge));
    };

    graph.connectionHandler.constraintHandler.createHighlightShape = function () {
      return new mxEllipse(null, this.highlightColor, this.highlightColor, 2)
    }
  }

  /**
   * 打开模型关系页面
   * @param evt
   */
  const openEditRelation = async (cell: any, isCreate: any) => {
    if (cell.edge) {
      setTimeout(() => {
        if (cell.pid) { // 修改
          let fkProperty = graphModel.getPropertyById(cell.pid);
          let fkModel = graphModel.getModel(fkProperty.modelId);
          let mapperModel = graphModel.getModel(fkProperty.targetModel);
          let mapperPro = graphModel.getMapperProperty(mapperModel, fkProperty.name);
          createModelRelationRef.current?.initModelRelation(graphModel, mapperModel, fkModel, mapperPro, fkProperty);
        } else { // 新建
          let startCell = cell.source;
          let endCell = cell.target;
          let o2mModelId = getModelIdFromCell(startCell);
          let m2oModelId = getModelIdFromCell(endCell);
          let o2mModel = graphModel.getModel(o2mModelId);
          let m2oModel = graphModel.getModel(m2oModelId);
          createModelRelationRef.current?.initModelRelation(graphModel, o2mModel, m2oModel);
        }
      }, createModelRelationRef.current ? 0 : 100);
    }
  }

  // MX基础配置函数 给graph添加事件
  const _eventCenter = (graph: any) => {
    // 监听自定义事件
    graph.addListener(mxEvent.MODEL_NODE_TYPE_CLICKED, (sender: any, evt: any) => {
      let cell = evt.properties.cell.state.cell;
      let modelId = getModelIdFromCell(cell);
      let propertyId: any = null;
      let action: any = null;
      if (evt.properties.cell.evt.path) {
        propertyId = evt.properties.cell.evt.path[0].getAttribute('pid');
        action = evt.properties.cell.evt.path[0].getAttribute('action');
      } else if (evt.properties.cell.evt.srcElement) {
        propertyId = evt.properties.cell.evt.srcElement.getAttribute('pid');
        action = evt.properties.cell.evt.srcElement.getAttribute('action');
      }

      if (action == 'createProperty') { // 创建属性
        let time = addPropertyRef.current ? 0 : 100;
        setTimeout(() => {
          addPropertyRef.current?.showModal(graphModel.getModel(modelId));
        }, time)
      } else if (action == 'createRelation') { // 创建关系
      } else {
        let property: any = graphModel.getPropertyById(propertyId);
        let canEdit = true;
        if (property) {
          canEdit = !propertyUnEditable(property) && canEdit;
        }
        setTimeout(() => {
          canEdit && attributeBoxRef.current?.changeAttributeBox(graphModel.getModel(modelId), propertyId);
        }, attributeBoxRef.current ? 0 : 100)
      }
    })

    // 新增节点事件
    graph.addListener(mxEvent.ADD_CELLS, async (sender: any, evt: any) => {
      let cell = evt.properties.cells[0];
      if (cell.edge && openEditRelationFlag) {
        editCell = cell;
        await openEditRelation(cell, true);
      } else {
        await saveGraphXml(graph);
      }
    });

    //拖动节点的事件
    graph.addListener(mxEvent.CELLS_MOVED, async (sender: any, evt: any) => {
      await saveGraphXml(graph);
    });

    // 删除节点触发事件
    graph.addListener(mxEvent.CELLS_REMOVED, async (sender: any, evt: any) => {
      await saveGraphXml(graph);
    });

    // 双击节点触发事件
    graph.addListener(mxEvent.DOUBLE_CLICK, async (sender: any, evt: any) => {
      let cell = evt.properties.cell;
      if (cell) {
        if (getModelIdFromCell(cell)) {
          openModelWithCell(cell)
        }

        if (cell.edge) {
          editCell = cell;
          setSelectedCell(cell);
          await openEditRelation(cell, false);
        }
      }
    });
  };

  // 打开模型编辑页面
  const openAddModel = (model: any = {}) => {
    dispatch(setCurDynamicModel(model));
    addModelRef.current?.showModal(model);
  }

  // 编辑模型
  const openModelWithCell = (cell: any) => {
    let modelId = getModelIdFromCell(cell);
    if (modelId) {
      openAddModel(graphModel.getModel(modelId))
    }
  }


  // MX基础配置函数
  const _configMouseEvent = (graph: any) => {
    graph.addMouseListener(
      {
        currentState: null,
        previousStyle: null,

        mouseDown: async (sender: any, evt: any) => {
          evt.mouseMoveFlag = false
          openEditRelationFlag = true;
          // 点击了画布
          if (!evt.state) {
            setSelectedEdge(false);
            updateCellStyle(null, editCell);
            return;
          }

          let curCell = evt.state.cell;
          // 点击了连线
          if (curCell.edge) {
            setSelectedEdge(true);
            updateCellStyle(null, editCell);
          } else { // 点击了节点
            setSelectedEdge(false);
            updateCellStyle(curCell, editCell);
            let clickNormalType = false;
            if (curCell.style !== undefined) {
              clickNormalType = curCell.style.includes(MODEL_NODE_TYPE);
            }
            if (clickNormalType) {
              // 使用 mxGraph 事件中心，注册自定义事件
              graph.fireEvent(new mxEventObject(mxEvent.MODEL_NODE_TYPE_CLICKED, 'cell', evt));
            }
          }
        },

        mouseMove: (sender: any, me: any) => {
          me.graphX = Math.ceil(me.graphX);
          me.graphY = Math.ceil(me.graphY);
          me.mouseMoveFlag = true;
        },

        mouseUp: async (sender: any, evt: any) => {
          // let selectionCell = getGraph().getSelectionCell();
          // console.log('------mouseUp--------->>>', sender, evt, selectionCell);
          //
          // if (evt.mouseMoveFlag && selectionCell?.edge == 1 && evt.sourceState) {
          //   let cellBounds:any = evt.sourceState.cellBounds;
          //   let dx = (evt.graphX - cellBounds.x) / cellBounds.width;
          //   let dy = (cellBounds.y - evt.graphY) / cellBounds.height;
          //   let xy = {
          //     //exitX:1,
          //     //exitY:0.25,
          //     entryX:dx,
          //     entryY:dy,
          //   };
          //   _updateEdgeStyle4ModelRef(getGraph(), selectionCell, true, xy);
          //   getGraph().refresh(selectionCell);
          // }
          openEditRelationFlag = true;
          if (evt.sourceState === undefined) {
            return false;
          } else {
            let cell = evt.sourceState.cell;
            if (cell) {
              evt.textValue = cell['value'] ? cell['value'] : cell['title'];
              cell.vertex ? evt.isNode = true : evt.isNode = false;
              let cellStyle = cell.getStyle() ? cell.getStyle() : null;
              if (!evt.isNode) { // 点击的不是节点
                cellStyle ? evt.edgeStyle = cellStyle : STYLE_EDGE;
              } else {
                // 模型关联连线
                if (editCell && cell && editCell != cell && editCell.source && editCell.target) {
                  let source = editCell.source;
                  let target = editCell.target;
                  clearCellStyle(source);
                  clearCellStyle(target);
                  let lineSourceModelId = getModelIdFromCell(source);
                  let lineTargetModelId = getModelIdFromCell(target);
                  let editPropertyId = editCell.pid;
                  // 图关联线的：source,target是否改变
                  if (editPropertyId && graphModel.lineRelIsChanged(editPropertyId, lineSourceModelId, lineTargetModelId)) {
                    await openEditRelation(editCell, true);
                  }
                }
              }
            }
          }
        },
      });

    // 设置导航图的任务节点的鼠标与移入移出效果
    let track = new mxCellTracker(graph);
    track.mouseMove = function (sender: any, me: any) {
      me.graphX = Math.ceil(me.graphX);
      me.graphY = Math.ceil(me.graphY);
      newNodeX = me.graphX;
      newNodeY = me.graphY;
    };
  }
  // MX配置
  const _setDefaultConfig = (graph: any) => {
    // 鼠标框选
    new mxRubberband(graph);

    // Disables foreignObjects
    mxClient.NO_FO = true;

    let style = graph.getStylesheet().getDefaultVertexStyle();
    style[mxConstants.STYLE_FILLCOLOR] = 'white';
    style[mxConstants.STYLE_OVERFLOW] = 'fill';
    style[mxConstants.STYLE_ROUNDED] = true;
    style[mxConstants.STYLE_ARCSIZE] = 6;
    style[mxConstants.STYLE_DASHED] = true;

    _setDefaultEdgeStyle(graph);

    // 允许连线的目标和源是同一元素
    graph.setAllowLoops(true);
    //线条是否可以移动
    graph.setCellsDisconnectable(true);
    graph.setAllowDanglingEdges(false);
    // 启用对齐线帮助定位
    mxGraphHandler.prototype.guidesEnabled = true;
    // 支持连线
    graph.setConnectable(true);
    // 禁用节点双击编辑
    graph.setCellsEditable(false);
    // 右键移动画布
    graph.setPanning(true);
    // 编辑时按回车键不换行，而是完成输入
    graph.setEnterStopsCellEditing(true);
    // 文本包裹效果必须开启此配置
    graph.setHtmlLabels(true);
    // 节点不可改变大小
    graph.setCellsResizable(false)

    graph.centerZoom = false;
    // @ts-ignore
    window['graph'] = graph;

    // 是否开启浮动自动连接
    graph.connectionHandler.isConnectableCell = function () {
      return true;
    };
  }

  const _setDefaultEdgeStyle = (graph: any) => {
    mxGraphHandler.prototype.guidesEnabled = true;
    mxEdgeHandler.prototype.snapToTerminals = true;

    // Registers and defines the custom marker
    // @ts-ignore
    mxMarker.addMarker('classic', function (canvas, shape, type, pe, unitX, unitY, size, source, sw, filled) {
      let nx = unitX * (size + sw + 1);
      let ny = unitY * (size + sw + 1);
      return function () {
        canvas.begin();
        canvas.moveTo(pe.x - nx - ny / 2, pe.y - ny + nx / 2);
        canvas.lineTo(pe.x - nx + ny / 2, pe.y - ny - nx / 2);
        canvas.moveTo(pe.x + ny / 2, pe.y - nx / 2);
        canvas.lineTo(pe.x - nx, pe.y - ny);
        canvas.lineTo(pe.x - ny / 2, pe.y + nx / 2);
        canvas.stroke();
      };
    });

    const style = graph.getStylesheet().getDefaultEdgeStyle();
    style[mxConstants.STYLE_EDGE] = STYLE_EDGE;
    style[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'white';
    style[mxConstants.STYLE_STROKEWIDTH] = '2';
    style[mxConstants.STYLE_NOLABEL] = 'noLabel';
  }

  // 在画布上新添加一个动态模型
  const mxAndModel = (modelData: any) => {
    graphModel.updateModel(modelData);
    setVisibleGraph(modelData.id, true);
    let graph = getGraph();
    graph.getModel().beginUpdate();

    try {
      let {x, y} = mxGetLatestCell(graph);
      let cell = createModelCell(graph, modelData, x, y);
      graphModel.cacheCellOrEdge(modelData.id, cell); // 缓存Cell
      openEditRelationFlag = false;
      resetEdge(graph, graphModel.getRelPropertiesMatched(modelData.properties));
      // onSelectCell(cell);
    } finally {
      graph.getModel().endUpdate();
    }
  }

  const mxGetLatestCell = (graph: any) => {
    let x = 340, y = 120;
    let cells = graph.getChildCells() || [];
    cells.forEach((cell: any) => {
      if (cell.vertex) {
        let geo = cell.getGeometry();
        x = x < geo.x ? geo.x : x;
        if (y < geo.y) {
          x = geo.x;
          y = geo.y;
        }
      }
    });

    return {
      x: x + 300,
      y,
    }
  }

  // 更新模型
  const mxUpdateModel = async (modelData?: any, relProperty?: any, cell?: any, saveGraphXmlFlag: boolean = true) => {
    let curCell = graphModel.getCellOrEdgeFromCache(modelData?.id) || cell || editCell;
    let graph = getGraph();
    if (curCell && graph) {
      if (modelData) {
        graphModel.updateModel(modelData);
        setTreeModels(graphModel.getModels());
      }
      graph.getModel().beginUpdate();
      try {
        if (relProperty) {
          let sourceModelId = getModelIdFromCell(curCell.source);
          let sourceModel = graphModel.getModel(sourceModelId) || {};
          let startToEnd = sourceModel.name != relProperty.model;
          curCell.pid = relProperty.id;
          curCell.model = relProperty.model;
          _updateEdgeStyle4ModelRef(graph, curCell, startToEnd)
        } else {
          updateModelCell(graph, modelData, curCell);
        }
        graph.refresh();
      } finally {
        graph.getModel().endUpdate();
      }

      saveGraphXmlFlag && await saveGraphXml(graph);
    }
  }

  // 删除模型
  const mxDeleteModel = async () => {
    if (editCell) {
      if (editCell.edge) {
        graph.removeCells([editCell]);
        updateCellStyle(null, editCell);
      } else {
        // 删除节点
        let modelId = getModelIdFromCell(editCell)
        let result: any = await ModelingApi.doDeleteModel(modelId);
        if (result.success) {
          graph.removeCells([editCell]);
          graphModel.removeModel(modelId);
          setVisibleGraph(modelId, false);
          updateCellStyle(null, editCell);
        }
      }
    }
  }

  const mxRemoveCell = (cell?: any) => {
    getGraph().removeCells([cell || editCell]);
    editCell = null;
  }

  const mxRemoveEdgeByPropertyId = (propertyId: any) => {
    let cell = graphModel.getMxEdge(propertyId);
    cell && getGraph().removeCells([cell]);
  }

  // 渲染xml流程图
  const loadGraphXml = async () => {
    graphModel.loadFlag = true;
    openEditRelationFlag = false;
    let graph = getGraph();

    // 删除多余的线和节点, 重置样式， 缓存Cell， 在画布上有显示
    function removeNotExistCellAndEdgeAndResetStyle() {
      if (graph.getChildEdges) {
        let edges = graph.getChildEdges() || [];
        let needDelEdges: any[] = [];
        edges.forEach((edge: any) => {
          let property = graphModel.getPropertyById(edge.pid);
          if (!property) {
            needDelEdges.push(edge);
          } else {
            graphModel.cacheCellOrEdge(edge.pid, edge); // 缓存Cell
          }
        })
        if (needDelEdges.length > 0) {
          graph.removeCells(needDelEdges);  // 删除多余的线
        }
      }

      if (graph.getChildCells) {
        let cells = graph.getChildCells() || [];
        let needDelCells: any[] = [];
        cells.forEach((cell: any) => {
          let modelId = getModelIdFromCell(cell);
          if (modelId) {
            setVisibleGraph(modelId, true); // 在画布上有显示
            clearCellStyle(cell); // 重置样式
            if (!graphModel.getModel(modelId)) {
              needDelCells.push(cell);
            } else {
              graphModel.cacheCellOrEdge(modelId, cell); // 缓存Cell
            }
          }
        })
        if (needDelCells.length > 0) {
          graph.removeCells(needDelCells, true); // 删除多余节点
        }
      }
    }

    if (graph) {
      let result: any = await ModelingApi.doApiGetModelMxXml();
      if (result && result.success) {
        graph.getModel().beginUpdate();
        try {
          if (result.result && result.result[0] && result.result[0]) {
            dynamicGraph = result.result[0];
            // 如果没有模型，删除画布
            if (!dynamicGraph.modelData || dynamicGraph.modelData.length == 0) {
              graph.removeCells(graph.getChildVertices(graph.getDefaultParent()));
            } else {
              graphModel.initMetaData(dynamicGraph.modelData);
              resetEdge(graph, graphModel.getRelPropertiesMatched());
              // xmlData图数据存在
              if (dynamicGraph.xmlData) {
                const xmlDocument = mxUtils.parseXml(dynamicGraph.xmlData);
                const decoder = new mxCodec();
                decoder.decode(xmlDocument.documentElement, graph.getModel())
                removeNotExistCellAndEdgeAndResetStyle();

                for (let idx in dynamicGraph.modelData) {
                  let modelItem = dynamicGraph.modelData[idx];
                  if (!graphModel.getCellOrEdgeFromCache(modelItem.id)) {
                    let {x, y} = mxGetLatestCell(graph);
                    let cell = createModelCell(graph, modelItem, x, y);
                    graphModel.cacheCellOrEdge(modelItem.id, cell); // 缓存Cell
                    setVisibleGraph(modelItem.id, true);
                  }
                }
              } else if (dynamicGraph.modelData && dynamicGraph.modelData.length > 0) {
                let startX = 30; // 起始X轴
                let startY = 40; // 起始Y轴
                let cnt = 5;     // 一行节点数据
                let offsetX = 320; // 节点X轴偏移
                let offsetY = 80; // 节点Y轴偏移
                let x1 = startX;
                let y1 = startY;
                let maxItemHPerLine = 0; // 每行节点的最大高度
                for (let idx in dynamicGraph.modelData) {
                  let modelItem = dynamicGraph.modelData[idx];
                  if (!graphModel.getCellOrEdgeFromCache(modelItem.id)) {
                    // @ts-ignore
                    if (idx % cnt == 0) {
                      x1 = startX;
                      y1 = y1 + offsetY + maxItemHPerLine;
                      maxItemHPerLine = 0;
                    }
                    x1 = x1 + offsetX;
                    let cell = createModelCell(graph, modelItem, x1, y1);
                    graphModel.cacheCellOrEdge(modelItem.id, cell); // 缓存Cell
                    if (maxItemHPerLine < cell.geometry.height) {
                      maxItemHPerLine = cell.geometry.height;
                    }
                  }
                }
              }
              resetEdge(graph, graphModel.getRelPropertiesMatched());
            }
          }
        } finally {
          setTreeModels(graphModel.getModels());
          graph.refresh();
          graph.getModel().endUpdate();
          // fitAndMove2Center(graph);

          graphModel.loadFlag = false;
          await saveGraphXml(graph);
        }
      } else {
        console.error("获取xmlData失败");
      }
    }
  }

  // 保存图xml数据
  const saveGraphXml = async (graph: any) => {
    if (graphModel.loadFlag) {
      return;
    }

    const encoder = new mxCodec();
    const graphData = encoder.encode(graph.getModel());
    let xmlData = mxUtils.getPrettyXml(graphData);
    let param = {
      ...dynamicGraph,
      xmlData
    }
    let result: any = await ModelingApi.doApiPostModelMxXml(param);
    if (!result.success) {
      console.error("保存xmlData失败");
    }
  }

  // mxGraph全图居中显示
  const fitAndMove2Center = (graph: any) => {
    graph.center(true, true, 0.5, 0.5);//将画布放到容器中间
  }

  const openImportModelByDataSource = () => {
    setImportBtnUlOpen(false);
    setTimeout(() => {
      importModelByDataSourceRef.current?.showModal();
    }, importModelByDataSourceRef.current ? 0 : 100);
  }

  const onUploadCreateModel = () => {
    setImportBtnUlOpen(false);
    setTimeout(() => {
      importModelByDataSourceRef.current.showModal("SQL");
    }, importModelByDataSourceRef.current ? 0 : 100);
  }

  const openImportModelByExcel = () => {
    setImportBtnUlOpen(false);
    setTimeout(() => {
      importModelByExcelRef.current.showModal();
    }, importModelByExcelRef.current ? 0 : 100);
  }

  const queryModelBizData = () => {
    setQueryModelBizDataFlag(true);
    setTimeout(() => {
      modelBizDataQueryRef.current.init();
    },  modelBizDataQueryRef.current ? 0 : 100);
  }

  const sqlQueryEditor = () => {
    setQueryEditorFlag(true);
    setTimeout(() => {
      sqlQueryEditorRef.current.init();
    },  sqlQueryEditorRef.current ? 0 : 100);
  }

  const exportModelSql = async () => {
    let res: any = await ModelingApi.doApiExportSql4Model();
    const blob = new Blob([res]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = "模型脚本(" + moment(new Date()).format("yyyyMMDD") + ").sql";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // 释放 URL 对象
    URL.revokeObjectURL(url);
  }

  const onSelectCellByModelId = (model: any) => {
    let modelId = model?.id;
    let flag = true
    getGraph().getChildCells().forEach((cell: any) => {
      let cellModelId = getModelIdFromCell(cell);
      let selectedCell = graph.getSelectionCell();
      let selectedId = getModelIdFromCell(selectedCell);
      if (flag && modelId == cellModelId && cellModelId !== selectedId) {
        onSelectCell(cell);
        flag = false
      }
    })
  }

  const onSelectCell = (cell: any) => {
    let height = mxContainerRef.current.offsetHeight;
    let width = mxContainerRef.current.offsetWidth
    let x = -cell.geometry.x + (width) / 2 - (width) / 4;
    let y = -cell.geometry.y + (height) / 2 - (height) / 4;
    getGraph().getView().setTranslate(x, y);
    getGraph().clearSelection();
    getGraph().addSelectionCell(cell);
    updateCellStyle(cell, editCell);
  }

  const openDelModel = () => {
    delConfirmRef.current?.showModal();
  }

  const deleteModel = async () => {
    dispatch(setCurDynamicModel({}));
    await mxDeleteModel();
  }

  const mxResetEdge = () => {
    let cells: any = graph.getSelectionCells();
    let saveFlag = false;
    cells.forEach((cell: any) => {
      if (cell.edge == 1) {
        graph.resetEdge(cell);
        saveFlag = true;
      }
    })

    saveFlag && saveGraphXml(graph).then();
  }

  const delConfirmRef = useRef<any>();

  return (
    <>
      {queryModelBizDataFlag &&
        <ModelBizDataQuery ref={modelBizDataQueryRef} goToModeling = {() => setQueryModelBizDataFlag(false)} dataMgrFlag={true} />
      }

      {
        queryEditorFlag && <SQLQueryEditor goToModeling = {() => setQueryEditorFlag(false)} />
      }

      {!queryModelBizDataFlag && !queryEditorFlag && <>
        <div style={{
          zIndex: 1,
          position: 'relative',
          padding: '7px 0 7px 19px',
          borderBottom: '1px solid rgba(228, 232, 235, 1)'
        }}>
          <Popover
            open={importBtnUlOpen} placement="bottomLeft" trigger="click"
            onOpenChange={(newOpen) => setImportBtnUlOpen(newOpen)}
            content={() => {
              return <ImportBtnUl>
                <li onClick={openImportModelByDataSource}>数据源导入模型</li>
                <li onClick={onUploadCreateModel}>模型SQL脚本导入</li>
                <li onClick={openImportModelByExcel}>Excel导入创建模型</li>
              </ImportBtnUl>
            }}>
            <ModelBtn type={"primary"}>导入创建模型 {<DownOutlined/>}</ModelBtn>
          </Popover>

          <ModelBtn icon={getIconPark('Export')} onClick={exportModelSql}>模型SQL脚本导出</ModelBtn>

          <ModelBtn icon={getIconPark('Query')} onClick={queryModelBizData}>模型业务数据管理</ModelBtn>
          <ModelBtn icon={getIconPark('Query')} onClick={sqlQueryEditor}>SQL执行器</ModelBtn>

          <ToolWrapper>
            <span className='toolBtn' onClick={() => graph.zoomIn()}>{getIconPark('ZoomIn')}</span>
            <span className='toolBtn' onClick={() => graph.zoomOut()}>{getIconPark('ZoomOut')}</span>
            <span className={selectedEdge ? 'toolBtn' : 'toolBtn2'}
                  onClick={() => mxResetEdge()}>{getIconPark('Connection')}</span>
            <span className="vLineBar"></span>
            <span className='toolBtn' onClick={() => openAddModel()}>{getIconPark('Add')}</span>
            <span className={isModelCell ? 'toolBtn' : 'toolBtn2'}
                  onClick={() => isModelCell && openModelWithCell(selectedCell)}>{getIconPark('Edit')}</span>
            <span className={isModelCell ? 'toolBtn' : 'toolBtn2'}
                  onClick={() => isModelCell && openDelModel()}>{getIconPark('Delete')}</span>
          </ToolWrapper>
        </div>
        {!queryModelBizDataFlag &&
        <div style={{display: 'flex'}}>
          <ModelTree
            style={{
              height: "calc(100vh - 118px)",
              contentH: 'calc(100vh - 190px)',
            }}
            models={treeModels}
            onSelectedItem={onSelectCellByModelId}
            addDraggableDom={addDraggableDom}
            source='modelingMx'
          />

          <div style={{
            position: "relative",
            width: "calc(100vw - 210px)",
            height: "calc(100vh - 118px)",
            overflow: "hidden",
          }}>

            <div ref={mxContainerRef}/>

            <div id="outlineContainerRef" style={{
              zIndex: "10",
              overflow: "hidden",
              width: "276px",
              height: "166px",
              border: '1px solid #D3D3D3',
              position: "absolute",
              padding: '5px',
              right: 0,
              bottom: 0,
            }}/>

          </div>
        </div>
        }

        <DelConfirm delCb={deleteModel} ref={delConfirmRef}/>

        <CreateProperty
          ref={addPropertyRef}
          mxUpdateModel={async (modelData: any) => {
            await mxUpdateModel(modelData)
          }}
        />

        <CreateModelRelation
          ref={createModelRelationRef}
          mxUpdateModel={async (model?: any, property?: any, saveGraphXmlFlag: boolean = true) => {
            await mxUpdateModel(model, property, null, saveGraphXmlFlag);
          }}
          mxRemoveCell={mxRemoveCell}
          graphModel={graphModel}
        />

        <ImportModelByDataSource mxLoadModel={loadGraphXml} ref={importModelByDataSourceRef}/>

        <ImportModelByExcel
          ref={importModelByExcelRef}
          graphModel={graphModel}
          mxAndModel={(modelData: any) => {
            mxAndModel(modelData)
          }}
          mxUpdateModel={async (modelData: any) => {
            await mxUpdateModel(modelData);
            openEditRelationFlag = false;
            resetEdge(graph, modelData.properties || []);
          }}
        />

        <CreateModel
          ref={addModelRef}
          graphModel={graphModel}
          mxUpdateModel={async (modelData: any) => {
            await mxUpdateModel(modelData);
            openEditRelationFlag = false;
            resetEdge(graph, modelData.properties || []);
          }}
          mxAndModel={(modelData: any) => {
            mxAndModel(modelData)
          }}
          mxDeleteModel={mxDeleteModel}
          mxRemoveCell={mxRemoveCell}
          mxRemoveEdgeByPropertyId={mxRemoveEdgeByPropertyId}
          style={{}}
        />
      </>}
    </>
  )
}
