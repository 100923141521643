import {Button, Space, Table} from 'antd';
import React, {forwardRef, useRef, useState} from 'react';
import {PropertyRelationModel, RelPropertyType} from "@byk/pages/modeling/lib/index";
import {ColumnsType} from "antd/es/table";
import {ModelProps} from "@byk/pages/modeling/MxGraph/CreateModel";
import {getPropertyRelTypeDes, PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";
import {graphModel} from "@byk/pages/modeling/MxGraph/ModelingMx";
import CreateModelRelation from "@byk/pages/modeling/MxGraph/CreateModelRelation";
import _ from "lodash";
import styled from "styled-components";

const TableWrapper = styled.div`
  width: 100%;
  margin-right: 18px;
  margin-left: 21px;
  margin-top: 21px;
  .ant-table.ant-table-bordered>.ant-table-container {
    border-left: 1px solid #f0f0f0;
  }

  .ant-table-cell {
    height: 45px;
    font-size: 16px;
    padding-left:
  }

  .formItemSelect {
    border: 1px solid #E4E8EB;
    border-radius: 5px;
  }
`

interface Props extends ModelProps {
  propertyRelationModel: PropertyRelationModel,
  mxRemoveCell?: (cell?: any) => void;
}

interface DataType {
  key: React.Key;
  id: any;
  model: string;
  type: string;
  targetModel: string;
}

const PropertyRelation: React.FC<Props> = forwardRef((
  {
    mxUpdateModel,
    propertyRelationModel,
    mxRemoveCell,
  }: Props, ref) => {
  const [dataSource, setDataSource] = useState<DataType[]>(propertyRelationModel.getRelationProperties());
  const createModelRelationRef: any = useRef();

  const delRelCallback = (delProperties: any = []) => {
    let ds: any = [];
    let delIdMap = _.keyBy(delProperties, "id");
    _.forEach(dataSource, item => {
      if (!delIdMap[item.id]) {
        ds.push(item);
      }
    });
    setDataSource([...ds]);
  }

  const edit = (record: any) => {
    setTimeout(() => {
      let property = graphModel.getPropertyById(record.id);
      let model = graphModel.getModel(property?.modelId);

      let targetModel = graphModel.getModel(property.targetModel);
      let relPropertyType: RelPropertyType = graphModel.getRelProperty(property.id);

      let mapperModel = null;
      let mapperPro = null;
      let fkModel = null;
      let fkPro = null;
      if (property.type == PROPERTY_REL_TYPE.O2M) {
        mapperModel = model;
        mapperPro = property;
        fkModel = targetModel;
        fkPro = relPropertyType.target;
      } else if (property.type == PROPERTY_REL_TYPE.M2O) {
        fkModel = model;
        fkPro = property;
        mapperModel = targetModel;
        mapperPro = relPropertyType.target;
      }

      createModelRelationRef.current?.initModelRelation(graphModel, mapperModel, fkModel, mapperPro, fkPro);
    }, createModelRelationRef.current ? 0 : 100);
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '模型',
      dataIndex: 'model',
    },
    {
      title: '关联类型',
      dataIndex: 'type',
      render: (value: any) => {
        return <>{getPropertyRelTypeDes(value)}</>
      }
    },
    {
      title: '关联模型',
      dataIndex: 'targetModel',
    },
    {
      key: 'x',
      title: '操作',
      dataIndex: 'operation',
      render: (_, record: any) => {
        return (
          <Space size="middle">
              <Button type='link' onClick={() => edit(record)}>编辑</Button>
          </Space>
        )
      }
    },
  ];

  return (
    <TableWrapper>
      <Table
        pagination={false}
        columns={columns}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
      />

      <CreateModelRelation
        ref={createModelRelationRef}
        mxUpdateModel={async (model?: any, property?: any) => {
          mxUpdateModel && await mxUpdateModel(model, property);
        }}
        mxRemoveCell={mxRemoveCell}
        graphModel={graphModel}
        callback={delRelCallback}
      />

    </TableWrapper>
  );
});

export default PropertyRelation;
