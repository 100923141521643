import React from "react";
import _ from 'lodash';
//import { historyPush } from "../../../pages/AppViewer/AppViewerMenu";
import Marquee from "./Marquee";
function MarqueeComponent(props:any){
  //console.log('MarqueeComponent==', props);
  let {currentData, directionX, speed, isPause,backgroundColor,fontFamily,fontSize,fontStyle,textColor,borderRadius,boxShadow,borderWidth,borderColor} = props;
  if(_.isString(currentData)&&!_.isEmpty(currentData)){
    currentData = JSON.parse(currentData);
  }
  // const toPage = (nodeData:any)=>{
  //   historyPush(nodeData);
  // }
  return (
    <Marquee pauseOnHover={true}
             direction={directionX}
             speed={speed}
             isPause={isPause}
             data={currentData} backgroundColor={backgroundColor} fontFamily={fontFamily}  fontSize={fontSize} fontStyle={fontStyle} textColor={textColor} borderWidth={borderWidth}
             borderRadius={borderRadius} boxShadow={boxShadow} borderColor={borderColor}
             />
  )
}

export default MarqueeComponent;
