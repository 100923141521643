import React, { useCallback, useEffect, useRef, useState } from "react";
import * as echarts from 'echarts';
import _ from 'lodash';
import 'echarts/extension/bmap/bmap';
import { EChartsType } from "echarts/types/dist/echarts";

function EchartsComponent(props: any) {
  const { currentData, maincanvasWidth, rightColumn, leftColumn,
    isDownload, fontColor, isDataView, backgroundColor, fontStyle, fontFamily,
    borderColor, borderWidth, boxShadow, borderRadius, isTitle, title, isLegend, radius, subtext, sublink, dataText, boundaryColor,
    landColor, waterColor } = props;
  const chartRef = useRef(null);
  const chartInstance = useRef<EChartsType>();
  const temp: any = [
    {
      "title": {
        "text": title,
        subtext: subtext,
        sublink: sublink,
        "show": isTitle,
        "left": 'center',
        textStyle: {
          color: fontColor
        }
      },
      "tooltip": {
        "trigger": "item"
      },
      "bmap": {
        "center": [

        ],
        "zoom": 5,
        "roam": true,
        "mapStyle": {
          "styleJson": [
            {
              "featureType": "water",
              "elementType": "all",
              "stylers": {
                "color": waterColor
              }
            },
            {
              "featureType": "land",
              "elementType": "all",
              "stylers": {
                "color": landColor
              }
            },
            {
              "featureType": "boundary",
              "elementType": "geometry",
              "stylers": {
                "color": boundaryColor
              }
            },
            {
              "featureType": "railway",
              "elementType": "all",
              "stylers": {
                "visibility": "off"
              }
            },
            {
              "featureType": "highway",
              "elementType": "geometry",
              "stylers": {
                "color": "#004981"
              }
            },
            {
              "featureType": "highway",
              "elementType": "geometry.fill",
              "stylers": {
                "color": "#005b96",
                "lightness": 1
              }
            },
            {
              "featureType": "highway",
              "elementType": "labels",
              "stylers": {
                "visibility": "off"
              }
            },
            {
              "featureType": "arterial",
              "elementType": "geometry",
              "stylers": {
                "color": "#004981"
              }
            },
            {
              "featureType": "arterial",
              "elementType": "geometry.fill",
              "stylers": {
                "color": "#00508b"
              }
            },
            {
              "featureType": "poi",
              "elementType": "all",
              "stylers": {
                "visibility": "off"
              }
            },
            {
              "featureType": "green",
              "elementType": "all",
              "stylers": {
                "color": "#056197",
                "visibility": "off"
              }
            },
            {
              "featureType": "subway",
              "elementType": "all",
              "stylers": {
                "visibility": "off"
              }
            },
            {
              "featureType": "manmade",
              "elementType": "all",
              "stylers": {
                "visibility": "off"
              }
            },
            {
              "featureType": "local",
              "elementType": "all",
              "stylers": {
                "visibility": "off"
              }
            },
            {
              "featureType": "arterial",
              "elementType": "labels",
              "stylers": {
                "visibility": "off"
              }
            },
            {
              "featureType": "boundary",
              "elementType": "geometry.fill",
              "stylers": {
                "color": "#029fd4"
              }
            },
            {
              "featureType": "building",
              "elementType": "all",
              "stylers": {
                "color": "#1a5787"
              }
            },
            {
              "featureType": "label",
              "elementType": "all",
              "stylers": {
                "visibility": "off"
              }
            }
          ]
        }
      },
      "series": [
        {
          "name": dataText,
          "type": "scatter",
          "coordinateSystem": "bmap",
          "data": [],
          "label": {
            "formatter": "{b}",
            "position": "right"
          },
          "itemStyle": {
            "color": "#ddb926"
          },
          "emphasis": {
            "label": {
              "show": true
            }
          },
          symbolSize: function (val: any) {
            return val[2] / 10;
          },
        }
      ],
      "backgroundColor": backgroundColor || "transparent"
    }
  ];




  const renderChart = () => {
    //console.log("xxxxxxxxxxxxxxx", props);
    if (!_.isArray(currentData)) {
      alert("error");
      return false;
    }
    let _currentData: any = _.cloneDeep(temp);
    _currentData[0].series[0].data = currentData[0]?.data || [];
    _currentData[0].bmap.center = currentData[0]?.center || [];
    chartInstance.current = echarts.init(chartRef.current);
    console.log("2222222", _currentData);
    const option = {
      ..._currentData[0]
    };
    chartInstance.current.setOption(option);
  };
  useEffect(() => {
    if (chartInstance.current) {
      setTimeout(() => {
        chartInstance.current?.resize();
      }, 200);
    }
  }, [maincanvasWidth, rightColumn, leftColumn]);

  useEffect(() => {
    try {
      renderChart();
    }
    catch (e: any) {

    }

    // if (!_.isEmpty(currentData)) {
    //   renderChart();
    // }
    //}, [currentData, sType, isDarkMode, color, isSmooth, isAreaStyle, isDownload, fontColor]);
  }, [props]);

  const DataDom = useCallback(() => {
    if (_.isEmpty(currentData)) {
      return <>暂无数据</>;
    } else {
      return <div ref={chartRef} style={{
        width: '100%', height: '100%', overflow: 'hidden', borderColor: borderColor || "#ccc",
        borderWidth: borderWidth, borderRadius: `${borderRadius}`, boxShadow: `${boxShadow}`
      }} />;
    }
  }, [props]);
  // }, [currentData]);

  return <DataDom />;
}

export default EchartsComponent;
