import React from 'react';
import {AtList, AtListItem} from "taro-ui";
import {Picker} from "@tarojs/components";
import {PickerWrapper} from "./MonthPicker";

const SelectorWidget = (props: any) => {
  const {label, value, selectorOptions, onDateChange, isVisible} = props;

  return (
    <PickerWrapper isVisible={isVisible}>
      <Picker mode='selector' range={selectorOptions} onChange={(e: any) => {
        onDateChange(selectorOptions[e.detail.value])
      }} value={value}>
        <AtList>
          <AtListItem title={label} extraText={selectorOptions.includes(value) ? value : selectorOptions[0]}/>
        </AtList>
      </Picker>
    </PickerWrapper>
  );
};

export default SelectorWidget;
