import {Alert, Button, Modal, Spin} from 'antd';
import React, {forwardRef, useImperativeHandle, useState} from 'react';

import styled from "styled-components";

const ButtonWrapper = styled(Button)`
  height: 40px;
  border-radius: 5px;
  margin:10px 8px 0 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`

export const DelConfirm: React.FC<any> = forwardRef((
    props, ref
  ) => {
    useImperativeHandle(ref, () => ({
      showModal
    }));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modelId, setModelId] = useState();
    const [spinning, setSpinning] = useState(false);

    const showModal = (modelId: any) => {
      setModelId(modelId)
      setIsModalOpen(true);
    };

    const handleOk = async () => {
      setSpinning(true);
      await props.delCb(modelId);
      setSpinning(false);
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <>
        <Modal maskClosable={false}
               open={isModalOpen}
               footer={null}>
          <Spin tip="Loading..." spinning={spinning}>
            <Alert style={{marginTop: '15px'}} message="注意： 删除模型同时也会删除物理表，请谨慎操作！" type="warning"/>

            <ButtonContainer>
              <ButtonWrapper onClick={handleCancel}>取消</ButtonWrapper>
              <ButtonWrapper type={"primary"} onClick={handleOk}>确认删除</ButtonWrapper>
            </ButtonContainer>
          </Spin>
        </Modal>
      </>
    )
  }
)


