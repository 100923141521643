import IconSVG from "./icon.svg";
import Widget from "./widget";
import { LabelPosition } from "../../components/constants";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "AmapView",
  iconSVG: IconSVG,
  dynamicHeight: "FIXED",
  needsMeta: true,
  defaults: {
    rows: 64,
    columns: 64,
    rowSpan: 1,
    labelText: '地图',
    labelPosition: LabelPosition.Left,
    widgetName: "AmapView",
    version: 1,
    animateLoading: true,
    defaultValue: "",
    value: null,
    isSearch: true,
    dragEnable: true,
    zoomEnable: true,
    isShowCard: true,
    zoomLevel: 17,
    borderWidth:1,
    securityJsCode: "d5e264d5d84a249e5f1e22be0fb864f3",
    securityJsKey: '60eae0021a24177a78d5838b6e2b01ad',
    currentData: [{
      "lnglat": [
        114.407101,
        30.478745
      ],
      "address": "武汉佰易开技术有限责任公司",
      "content": [
        "<video src='https://stream7.iqilu.com/10339/upload_transcode/202002/17/20200217021133Eggh6zdlAO.mp4' preload='auto' autoplay  controls=''></video>",
        "<b>地址</b>：<span>武汉市洪山区东湖开发区光谷软件园F1栋12-13层</span>",
        "电话：027-87776668",
        "法人：产品部-项目交付部", "<a style='color:blue;text-decoration: underline' href='#'>详细信息</a>"
      ]
    },
    {
      "lnglat": [
        114.410578,
        30.475968
      ],
      "address": "湖北省武汉市洪山区来菜·湖北头牌藕汤(光谷天地店)",
      "content": [
        "<video src='https://assets.appsmith.com/widgets/bird.mp4' autoplay  preload='auto' controls=''></video>",
        "红烧翅膀我喜欢吃",
        "北京烤鸭",
        "四川麻婆豆腐"
      ]
    }
    ]
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "400px",
            minHeight: "40px",
          };
        },
      },
    ],
    disableResizeHandles: {
      vertical: true,
    }
  },
};

export default Widget;
