import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import _ from "lodash";
import * as XLSX from "xlsx";
import ModelTree from "@byk/pages/modeling/MxGraph/ModelTree";
import ModelBasicInfo from "@byk/pages/modeling/MxGraph/ModelBasicInfo";
import {graphModel} from "@byk/pages/modeling/MxGraph/ModelingMx";
import ModelProperty from "@byk/pages/modeling/ModelProperty";
import {uuid2} from "@byk/utils/Utils";
import {Spin} from "antd";
import {PageEleModel} from "@byk/pages/modeling/lib/PageEleModel";
import {getPropertyType} from "@byk/pages/modeling/constants/ModelingOptions";
import {getPropertyDefaultLength} from "@byk/pages/modeling/ModelDataUtil";
import {setCurDynamicModel} from "@byk/store/modelingReducers";

const StyledViewer = styled.div`
  width: 100%;
  background: #fff;
  overflow: hidden;
  display: flex;
`;

const chars = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

// get excel column name from index, e.g. A,B,...,AA,AB
const numberToExcelHeader = (index: number): string => {
  const quotient = Math.floor(index / 26);
  if (quotient > 0) {
    return numberToExcelHeader(quotient - 1) + chars[index % 26];
  }
  return chars[index % 26];
};

interface DocumentViewerState {
  sheetNames: string[];
  currentTableData: RowData[];
  currentTableHeaders: HeaderCell[];
  tableData: Record<number, RowData[]>;
  headerData: Record<number, HeaderCell[]>;
}

interface IProps {
  blob?: Blob;
  pageEleModel:any;
  handleCancel: () => void;
  mxAndModel?: (modelData: any) => void,
  mxUpdateModel?: (modelData: any, property?: any) => void
}

interface HeaderCell {
  Header: string;
  accessor: string;
}

type RawSheetData = any[][];

type RowData = Record<string, unknown>; // key is column name, value is cell value

const checkExcelDesc = (model:any, isChange:boolean = false) => {
  if (!model.templateChange) {
    model.templateChange = isChange;
  }
}

const cellValue2BoolValue = (cellValue:string = '', defVal:boolean = false) => {
  if (cellValue == '' || cellValue == undefined) {
    return defVal;
  }

  let cellUpperCase = cellValue.trim().toUpperCase();
  if (cellUpperCase == '0' || cellUpperCase == '否' || cellUpperCase == 'NO' || cellUpperCase == 'N' || cellUpperCase == 'FALSE') {
    return false;
  }

  if (cellUpperCase == '1' || cellUpperCase == '是' || cellUpperCase == 'YES' || cellUpperCase == 'Y' || cellUpperCase == 'TRUE') {
    return true;
  }
}

const ModelExcelConfig = {
  modelRowIdx: 0,
  columnsRowIdx: 1,
  dataRowIdx: 2,
  model: [
    {
      idx: 0,
      desc: '模型编码',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '模型编码'),
    },
    {
      idx: 1,
      desc: '模型编码值',
      process: (model:any, cellValue: any) => model.name = cellValue,
    },
    {
      idx: 3,
      desc: '模型名称',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '模型名称'),
    },
    {
      idx: 4,
      desc: '模型名称值',
      process: (model:any, cellValue: any) => model.description = cellValue,
    },
  ],
  columns: [
    {
      idx: 0,
      desc: '属性编码',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '属性编码'),
    },
    {
      idx: 1,
      desc: '属性名称',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '属性名称'),
    },
    {
      idx: 2,
      desc: '属性类型',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '属性类型'),
    },
    {
      idx: 3,
      desc: '长度',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '长度'),
    },
    {
      idx: 4,
      desc: '精度',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '精度'),
    },
    {
      idx: 5,
      desc: '默认值',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '默认值'),
    },
    {
      idx: 6,
      desc: '允许为空',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '允许为空'),
    },
    {
      idx: 7,
      desc: '多语言',
      process: (model:any, cellValue: any) => checkExcelDesc(model, cellValue != '多语言'),
    },
  ],
  data: [
    {
      idx: 0,
      desc: '属性编码',
      process: (property:any, cellValue: any) => {
        property.name = cellValue;
        property.key = uuid2();
      },
    },
    {
      idx: 1,
      desc: '属性名称',
      process: (property:any, cellValue: any) => property.description = cellValue,
    },
    {
      idx: 2,
      desc: '属性类型',
      process: (property:any, cellValue: any) => {
        let type:any = getPropertyType(cellValue);
        property.type = type.value;
      },
    },
    {
      idx: 3,
      desc: '长度',
      process: (property:any, cellValue: any) => property.length =  getPropertyDefaultLength(property.type),
    },
    {
      idx: 4,
      desc: '精度',
      process: (property:any, cellValue: any) => {
        if (property.type == 'decimal') {
          property.scale = 2;
          if ('string' == typeof cellValue && !isNaN(Number(cellValue))) {
            property.scale = Number(cellValue);
          }
        }
      },
    },
    {
      idx: 5,
      desc: '默认值',
      process: (property:any, cellValue: any) => {
        property.defaultValue = cellValue;
        let cellUpperCase = cellValue.trim().toUpperCase();
        if (property.type == 'datetime') {
          property.defaultValue = (cellUpperCase == '当前时间' || cellValue == 'NOW()') ? 'now()' : null;
        } else if (property.type == 'bool') {
          property.defaultValue = cellValue2BoolValue(cellValue);
        }
      },
    },
    {
      idx: 6,
      desc: '允许为空',
      process: (property:any, cellValue: any) => property.nullable = cellValue2BoolValue(cellValue, true),
    },
    {
      idx: 7,
      desc: '多语言',
      process: (property:any, cellValue: any) => property.nullable = cellValue2BoolValue(cellValue, false),
    },
  ]
}

export default function ImportModelByExcelView(props: IProps) {
  const [sheetIndex, setSheetIndex] = useState<number>(-1);
  const [modelInfos, setModelInfos] = useState<any[]>([]);
  const [model, setModel] = useState<any>();
  const [state, setState] = useState<DocumentViewerState>(newStateInstance());

  useEffect(() => {
    if (!props.blob) {
      resetStates();
    } else {
      parseBlob(props.blob)
        .then(
          (jsonData: { sheetsData: RawSheetData[]; sheetNames: string[] }) => {
            const newState = newStateInstance();
            newState.sheetNames = jsonData.sheetNames;
            const newSheetIndex = jsonData.sheetsData.length > 0 ? 0 : -1;

            let _modelInfos: any[] = [];
            jsonData.sheetsData.forEach((data: RawSheetData, index: number) => {
              newState.tableData[index] = parseTableBody(data, _modelInfos, newState.sheetNames[index]);
              newState.headerData[index] = parseTableHeaders(data);
            });

            newState.currentTableData = newState.tableData[newSheetIndex];
            newState.currentTableHeaders = newState.headerData[newSheetIndex];

            setState(newState);
            setSheetIndex(newSheetIndex);
            setModelInfos(_modelInfos);
          },
        )
        .catch(() => {
          resetStates();
        });
    }
  }, [props.blob]);

  const convertWorkbookDataToJSON = (
    workbook: XLSX.WorkBook,
  ): { sheetsData: RawSheetData[]; sheetNames: string[] } => {
    const sheetsData: RawSheetData[] = [];
    const sheetNames: string[] = [];

    workbook.SheetNames.forEach((name, index) => {
      sheetNames.push(name);

      const result: RawSheetData = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[index]],
        {header: 1},
      );
      sheetsData.push(result);
    });

    return {sheetsData, sheetNames};
  };

  async function parseBlob(
    blob: Blob,
  ): Promise<{ sheetsData: RawSheetData[]; sheetNames: string[] }> {
    const buffer = await blob.arrayBuffer();
    const workbook = XLSX.read(buffer, {type: "array"});
    return convertWorkbookDataToJSON(workbook);
  }

  function newStateInstance() {
    const defaultTableData: Record<number, RowData[]> = {"-1": []};
    const defaultHeaderData: Record<number, HeaderCell[]> = {"-1": []};
    const newState: DocumentViewerState = {
      sheetNames: [],
      currentTableData: [],
      currentTableHeaders: [],
      tableData: {...defaultTableData},
      headerData: {...defaultHeaderData},
    };
    return newState;
  }

  const resetStates = () => {
    const newState = newStateInstance();
    setState(newState);
    setSheetIndex(-1);
    setModelInfos([]);
    setModel(null);
  };

  function parseTableBody(excelData: RawSheetData, modelInfos: any[], sheetName: string): RowData[] {
    const data: RowData[] = [];
    const model: any = {sheetName, properties: []};
    let rowIdx = -1;
    for (const row of excelData) {
      rowIdx++;
      let isModeBasicInfo = rowIdx == ModelExcelConfig.modelRowIdx;   // 获取模型编码和名称
      let isColumnName = rowIdx == ModelExcelConfig.columnsRowIdx;    // 获取模型字段名称
      let isDataRow = rowIdx >= ModelExcelConfig.dataRowIdx;          // 获取模型属性信息
      let config:any[] = isModeBasicInfo ? ModelExcelConfig.model : isColumnName ? ModelExcelConfig.columns : ModelExcelConfig.data;
      let property: any = {};
      for (let item of config) {
        let cellValue = row[item.idx];
        if (isDataRow && !cellValue) {
          continue;
        };

        item.process(isDataRow ? property : model, row[item.idx]);
      }

      if (Object.keys(property).length > 0) {
        model.properties.push(property);
      }
    }

    if (model.name || model.description || model.properties.length > 0) {
      modelInfos.push(model);
    }

    return data;
  }

  const parseTableHeaders = (tableData: RawSheetData): HeaderCell[] => {
    const newHeader: HeaderCell[] = [];
    if (tableData.length) {
      const headers = tableData[0];

      for (let i = 0; i < headers.length; i++) {
        const currHeader: string = numberToExcelHeader(i);
        newHeader.push({
          Header: currHeader,
          accessor: currHeader,
        });
      }
    }
    return newHeader;
  };

  const [spinning, setSpinning] = useState(false);
  const modelPropertyRef = useRef<any>();

  const updateModelState = (modelData: any) => {
    setModel({...modelData});
  }

  const setProperties = (properties: any = []) => {
    if (properties.length) {
      _.forEach(properties, item => {
        item.model = model.name;
        item.modelId = model.modelId;
      })
      let md = {
        ...model,
        properties: [...properties]
      }
      setModel({...md});
    }
  }

  return (
    <Spin tip="Loading..." spinning={spinning}>
      <StyledViewer>
        <ModelTree
          style={{
            height: 'calc(100vh - 155px)',
            contentH: 'calc(100vh - 210px)',
          }}
          models={modelInfos}
          onSelectedItem={(model) => {
            setModel(model);
          }}
        />

        {model &&
        <div style={{width: 'calc(100% - 171px)'}}>
          <ModelBasicInfo
            graphModel={graphModel}
            excelModel={model}
            setSpinning={setSpinning}
            updateModelState={updateModelState}
            modelPropertyRef={modelPropertyRef}
            closeMode={props.handleCancel}
            mxAndModel={props.mxAndModel}
            mxUpdateModel={props.mxUpdateModel}
          />

          <ModelProperty
            ref={modelPropertyRef}
            graphModel={graphModel}
            excelModel={model}
            updateModelState={updateModelState}
            pageEleModel={props.pageEleModel}
            setProperties={setProperties}
          />
        </div>
        }
      </StyledViewer>
    </Spin>
  );
}
