import _ from "lodash";
import {isRelProperty, propertyUnEditable} from "@byk/pages/modeling/ModelDataUtil";
import {DataType} from "@byk/pages/modeling/MxGraph/PropertyMapping";
import GraphModel from "@byk/pages/modeling/lib/GraphModel";
import {PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";

/**
 * 属性映射模型
 */
export class PropertyMappingModel {
  private _graphModel: GraphModel;
  private _cache: any = {};
  private _id: any;
  private property: any;
  private readonly model: any;
  private targetModel: any;
  private _mappingList: any = [];
  private _targetModelPropertyMapByDesc: any = {};
  private _targetModelPropertyMapByName: any = {};
  private _mappingDbCache: any = {};

  constructor(graphModel: GraphModel, property: any) {
    this._id = new Date().getTime();
    this._graphModel = graphModel || new GraphModel();
    if (!property || !property.modelId || !property.targetModel) {
      return;
    }

    this.property = property;
    this.model = graphModel.getModel(property.modelId);
    this.targetModel = graphModel.getModel(property.targetModel)
    this._cache[property.id] = this;

    _.forEach(this.targetModel?.properties || [], (item) => {
      item['key'] = item.key || item.id;
      this._targetModelPropertyMapByDesc[item.description] = item.name;
      this._targetModelPropertyMapByName[item.name] = item.description;
    });

    let mappingList: any = [];
    _.forEach(this.model?.properties || [], (item) => {
      if (item.targetModel == this.property.targetModel && item.mappedBy == this.property.name) {
        let newItem = {
          ...item,
          key: item.key || item.id,
          relatedBy: this._targetModelPropertyMapByName[item.relatedBy],
        };
        mappingList.push(newItem);
        this._mappingDbCache[newItem.id + ''] = newItem;
      }
    });
    this._mappingList = mappingList.length > 0 ? [...mappingList] : [];
  }

  getMappingOptions() {
    let options: any = [];
    let properties = this.targetModel.properties || [];
    let keyMap = _.keyBy(this._mappingList, "relatedBy");
    _.forEach(properties, (item: any) => {
      if (!keyMap[item.description]
        && !isRelProperty(item.type)
        && !propertyUnEditable(item)
        && PROPERTY_REL_TYPE.RELATED != item.type) {
        options.push({
          ...item,
          "value": item.description,
          "label": item.description,
        });
      }

    });
    return options;
  }

  addMappingProperty(newData: DataType) {
    this._mappingList.push(newData);
  }

  removeMappingProperty(keys: any[]) {
    let list = _.filter(this._mappingList, function (o) {
      return keys.indexOf(o.key) == -1;
    });

    this._mappingList = [...list];
    return this._mappingList;
  }

  removeProperties(keys: any[]) {
    let pro = _.filter(this.model.properties, (item) => {
      return keys.indexOf(item.key) == -1
    });

    this.model.properties = [...pro];
  }

  updateMappingProperty(mappingProperty: any) {
    let list: any = [];
    let existed = false;
    _.forEach(this._mappingList, (item: any) => {
      if (item.key == mappingProperty.key) {
        list.push({...mappingProperty});
        existed = true;
      } else {
        list.push(item);
      }
    })
    if (!existed) {
      list.push({...mappingProperty});
    }
    this._mappingList = [...list];
  }

  mappingCanSave(selectedKeys: any) {
    let ret = true;
    this._mappingList.forEach((value: any) => {
      if (ret && selectedKeys.indexOf(value.key) > -1) {
        ret = ret && !_.isEmpty(value.relatedBy) && !_.isEmpty(value.name) && !_.isEmpty(value.description);
      }
    })
    return ret && selectedKeys.length;
  }

  getMappingProperties(selectedKeys?: any) {
    let properties: any = [];
    _.forEach(this._mappingList, (item: any) => {
      if (!selectedKeys || selectedKeys.indexOf(item.key) >= 0) {
        let dbItem = this._mappingDbCache[item.key + ''] || {};
        properties.push({
          ...dbItem,
          ...item,
          targetModel: this.property.targetModel,
          mappedBy: this.property.name,
          type: "related",
          modelId: this.property.modelId,
          model: this.property.model,
        });
      }
    })
    return properties;
  }

  setPropertyRelatedBy(properties: any) {
    _.forEach(properties, (item: any) => {
      item.relatedBy = this._targetModelPropertyMapByDesc[item.relatedBy];
    });
    return properties;
  }

  /**
   * 根据_mappingList重新设置model的properties, 因为新增的mapping保存后没有更新到model的properties里
   */
  getModel() {
    let mappingIdMap = _.keyBy(this._mappingList || [], "id");
    let propertyIdMap = _.keyBy(this.model.properties || [], "id");
    let proList: any = [];
    // 更新
    _.forEach(this.model.properties, (item) => {
      let mappingItem = mappingIdMap[item.id] || item;
      proList.push({
        ...item,
        ...mappingItem,
      });
    })

    // 新增
    _.forEach(this._mappingList, (item) => {
      if (item.id && !propertyIdMap[item.id]) {
        proList.push(item);
      }
    })

    this.model.properties = [...proList];
    return this.model;
  }

  updateMappingPropertyAfterSave(result: any) {
    let mappingList: any = [];
    let keyMap = _.keyBy(result, "key");
    _.forEach(this._mappingList, (item) => {
      if (keyMap[item.key]) {
        mappingList.push({
          ...item,
          ...keyMap[item.key],
        });
      } else {
        mappingList.push(item);
      }
    });

    this._mappingList = [...mappingList];
  }

  getMappingDesc() {
    if (this.model && this.targetModel) {
      return "给模型(" + this.model.description + ")添加映射模型(" + this.targetModel.description + ")属性的属性";
    }
  }
}
