import React, { useCallback } from "react";
import { Input } from 'antd';
import styled from "styled-components";
import { getContrastYIQ, hexToRgb } from "@byk/utils/Utils";
const  baseBorderColor= '#d9d9d9';
export const DivContainer = styled.div<{
  inputBackgroundColor?:string,
  inputBorderColor?:string,
  iconColor?:string,
  iconBackGroundColor?:string,
  borderRadius?:number,
  boxShadow?:string,
  accentColor:string,
  }>`
  .ant-input-affix-wrapper{
    color:${({ inputBackgroundColor }) => { return inputBackgroundColor==""?'#000000d9':getContrastYIQ(inputBackgroundColor)}};
    background-color: ${({ inputBackgroundColor }) => { return inputBackgroundColor || '#fafafa' }};
    border-color: ${({ inputBorderColor }) => { return inputBorderColor || baseBorderColor }};
    border-radius:${({ borderRadius }) => { return `${borderRadius}  0  0  ${borderRadius} !important` }};
    box-shadow:0px 0px ${({ boxShadow }) => boxShadow}px 0px rgba(0,0,0,0.5);
    box-shadow: ${({boxShadow}) => `${boxShadow}`} !important;
  }
  input{
    color:${({ inputBackgroundColor }) => { return inputBackgroundColor==""?'#000000d9':getContrastYIQ(inputBackgroundColor)}};
    background-color: ${({ inputBackgroundColor }) => { return inputBackgroundColor || '#fafafa' }};
    //border-color: ${({ inputBorderColor }) => { return inputBorderColor || baseBorderColor }};
    //border-radius:${({ borderRadius }) => { return `${borderRadius}  0  0  ${borderRadius} !important` }};
    //box-shadow:0px 0px ${({ boxShadow }) => boxShadow}px 0px rgba(0,0,0,0.5);
    //box-shadow: ${({boxShadow}) => `${boxShadow}`} !important;
  }
  button{
    border-radius:${({ borderRadius }) => { return `0 ${borderRadius} ${borderRadius} 0 !important` }};
    background-color: ${({ iconBackGroundColor }) => { return iconBackGroundColor || '#fafafa' }};
    border-color: ${({ iconBackGroundColor }) => { return iconBackGroundColor || baseBorderColor }};
    box-shadow: ${({boxShadow}) => `${boxShadow}`} !important;
  }
  .ant-input-group-addon{
    //background-color: ${({ iconBackGroundColor }) => { return iconBackGroundColor || '#fafafa' }};
    background-color: transparent;
  }
  button svg{
    color:${({ iconColor }) => { return iconColor || '#000000d9'}};
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    // border-color: ${({ accentColor }) => accentColor } !important;
    border-color: transparent !important;
  }
  .ant-input-group-addon:hover{
    border-color: transparent !important;
  }
  .ant-input-search-button:hover{
    border-color: transparent !important;
    background-color: ${({ iconBackGroundColor }) => { return iconBackGroundColor || '#fafafa' }};
  }
  
`;

function CarouselComponent(props: any) {
  const { wrapperRef,
    compactMode,
    size,
    onChange,
    onEnter,
    onClick,
    value,
    placeholderText,
    inputBackgroundColor,
    inputBorderColor,
    iconColor,
    iconBackGroundColor,
    borderRadius,
    boxShadow,
    accentColor
  } = props;
  const { Search } = Input;
  const onSearchSelf = (e: any) => {
    onClick(e);
  }

  const onChangeSelf = (e: any) => {
    onChange(e.target.value);
  }

  const onPressEnterSelf = (e: any) => {
    onEnter(e.target.value);
  }

  return (
    <>
      <DivContainer compactMode={compactMode}
      inputBackgroundColor={inputBackgroundColor}
      inputBorderColor={inputBorderColor}
      iconColor={iconColor}
      iconBackGroundColor={iconBackGroundColor}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      accentColor = {accentColor}
        data-testid="radiogroup-container"
        >
          <Search
            style={{ width: "100%" }}
            placeholder={placeholderText}
            size={size}
            allowClear
            onPressEnter={onPressEnterSelf}
            onChange={onChangeSelf}
            onSearch={onSearchSelf}
          />
      </DivContainer>
    </>
  )
}

export default CarouselComponent;
