import React, { useEffect, useRef } from "react";
import type { ControlProps } from "./BaseControl";
import BaseControl from "./BaseControl";
import { Button, Form, Input, Space } from "antd";
import { MinusCircleOutlined, PaperClipOutlined, PlusOutlined } from "@ant-design/icons/lib";
import styled from "styled-components";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { X_LINKPAGESET, X_LINKPANNELOFFSET, X_OPENLINKPANNEL } from "@byk/store/XReducers";
import { getCurrentApplicationId } from "../../selectors/editorSelectors";

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`;

function BreadcrumbForm (props:any){
  const { propertyValue, valueChange } = props;
  const appId = useSelector(getCurrentApplicationId);
  const cacheValue = useRef(propertyValue);
  const cacheIndex = useRef(-1);
  const linkPannel = useSelector((state:any)=>{
    return state.XReducers.linkPannelSet;
  })
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onValuesChange = (changeValues:any, allValues:any)=>{
    const isTrim = _.find(changeValues?.lists, {title: ""});
    if(_.isEmpty(isTrim)){
      const validValues = allValues?.lists.filter((i:any)=>{
        return !!i;
      });
      //console.log('-----', validValues);
      cacheValue.current = validValues;
      valueChange(validValues);
    }
  }
  const onOpen = (item:any)=>{
    cacheIndex.current = item.name;
    //console.log('current--', cacheValue.current?.[cacheIndex.current]);
    dispatch({
      type: X_LINKPAGESET,
      data: {
        ...cacheValue.current?.[cacheIndex.current],
        appid: appId,
        save: false
      }
    })
    dispatch({
      type: X_LINKPANNELOFFSET,
      data: {
        left: 'auto',
        right: '293px',
        top: '226px',
        bottom: 'auto',
        isIcon: false
      }
    })
    dispatch({
      type: X_OPENLINKPANNEL,
      data: true
    })
  }
  useEffect(()=>{
    //console.log('linkPannel--', linkPannel, cacheIndex, cacheValue);
    if(cacheIndex.current == -1||!linkPannel.save){
      return ;
    }
    cacheValue.current = cacheValue.current.map((i:any, idx:number)=>{
      let value = { ...i };
      if(idx == cacheIndex.current){
        value = {
          ...value,
          ...linkPannel
        }
      }
      delete value.save
      return value;
    })
    //console.log('@@@@', cacheValue.current);
    valueChange(cacheValue.current);
  }, [linkPannel]);
  return (
    <>
      <StyledForm form={form} onValuesChange={onValuesChange} autoComplete="off">
        <Form.List name="lists" initialValue={propertyValue}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    rules={[{ required: true, message: 'Missing Title' }]}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                  <PaperClipOutlined onClick={() => onOpen({ key, name, ...restField })} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </StyledForm>
    </>
  )
}
export function EditPropertyData(props:any){
  const {propertyValue, valueChange, children} = props;
  if(propertyValue&&propertyValue.indexOf('{{appsmith.store') == 0){
    return <Button onClick={()=>{
      valueChange([]);
    }
    }>编辑数据</Button>
  }
  return (
    <>
      {children}
    </>
  )
}
class BreadcrumbControl extends BaseControl<CarouselControlProps> {
  render() {
    return (
      <EditPropertyData propertyValue={this.props.propertyValue} valueChange={this.valueChange}>
        <BreadcrumbForm valueChange={this.valueChange} {...this.props} />
      </EditPropertyData>
    )
  }
  valueChange = (result:any)=>{
    this.updateProperty(this.props.propertyName, result, true);
  }


  static getControlType() {
    return "BREADCRUMB_ARRAY";
  }
}

export interface CarouselControlProps extends ControlProps {
}

export default BreadcrumbControl;
