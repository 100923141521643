import type {ReactNode} from "react";
import React, {useState} from "react";
import styled, {css} from "styled-components";
import type {ComponentProps} from "widgets/BaseComponent";
import {generateClassName, getCanvasClassName} from "utils/generators";
import {scrollCSS} from "widgets/WidgetUtils";
import {Button, Steps} from "antd";
import "./index.css";
import _ from "lodash";

export interface StepsComponentProps extends ComponentProps {
  children?: ReactNode;
  shouldScrollContents?: boolean;
  selectedTabWidgetId: string;
  shouldShowTabs: boolean;
  borderRadius: string;
  boxShadow?: string;
  borderWidth?: number;
  borderColor?: string;
  accentColor?: string;
  primaryColor: string;
  onTabChange: (tabId: string) => void;
  onFinish: () => void;
  tabs: Array<{
    id: string;
    label: string;
    key: string;
    description: string;
    widgetId: string;
    isVisible?: boolean;
    index: number;
  }>;
  width: number;
  $noScroll: boolean;
  prevText?: string;
  nextText?: string;
  doneText?: string;
  selectedTab: string;
  showTabDesc: boolean;
  stepDirection: string;
  labelPlacement: string;
}

const TabsContainerWrapper = styled.div<{
  borderRadius: string;
  boxShadow?: string;
  borderWidth?: number;
  borderColor?: string;
  backgroundColor?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${({borderRadius}) => borderRadius};
  box-shadow: ${({boxShadow}) => `${boxShadow}`} !important;
  border-width: ${(props) => props.borderWidth}px;
  border-color: ${(props) => props.borderColor || "transparent"};
  background-color: ${(props) =>
  props.backgroundColor || "var(--wds-color-bg)"};
  border-style: solid;
  overflow: hidden;
  padding: 10px;
`;

const ScrollCanvas = styled.div<{ $shouldScrollContents: boolean }>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: 100%;
`;

const StepButton = styled(Button)<any>`
  ${({isPrimary, accentColor}) =>
  isPrimary &&
  css`
    color: white;
    background: ${() => (accentColor ? accentColor : `#006CF2`)};
    border: none !important;
  `}

  &:hover,
  &:active,
  &:focus {
    color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)} !important;
    border: 1px solid !important;
    border-color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)} !important;
  }
`

const CustomSteps = styled(Steps)<any>`
  ${({direction}) =>
  direction == 'vertical' &&
  css`
      max-width: 200px;
      flex: 1;
      min-width: 115px;
  `}

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)};
  }

  .ant-steps-item-process .ant-steps-item-icon::after {
    background-color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)};
  }

  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
    background-color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)};
  }

  .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    border-color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)};
    color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)};
  }

  .ant-steps-item-process .ant-steps-item-icon {
    border-color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)};
  }

  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after  {
    background-color: ${({accentColor}) => (accentColor ? accentColor : `#006CF2`)};
  }

  .ant-steps-item-title {
    line-height: 26px !important;
  }
  .ant-steps-item-description {
    line-height: 20px !important;
  }
`;
const XBox = styled.div<{
  direction: string
}>`
  width: 100%;
  display: flex;
  height: calc(100% - 32px);
  flex-direction: ${({direction}) => direction == 'vertical' ? 'row' : 'column'};
`

function TabsComponent(props: StepsComponentProps) {
  const {onTabChange, tabs, nextText, prevText, doneText, onFinish, selectedTab, showTabDesc} = props;
  const selectedTabItem: any = _.find(tabs, item => item.key == selectedTab)
  const [current, setCurrent] = useState<number>(selectedTabItem?.index);
  const setCurrentFn = (value: number) => {
    setCurrent(value);
    onTabChange(tabs[value].widgetId);
  }
  const next = () => {
    setCurrentFn(current + 1);
  };

  const prev = () => {
    setCurrentFn(current - 1);
  };

  const items = tabs.map(item => {
    return {...item, key: item.label, title: item.label, description: showTabDesc ? item.description : ''}
  });

  return (
    <TabsContainerWrapper
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
      borderRadius={props.borderRadius}
      borderWidth={props.borderWidth}
      boxShadow={props.boxShadow}
    >
      <XBox direction={props.stepDirection}>
        <CustomSteps
          direction={props.stepDirection} labelPlacement={props.labelPlacement}
          accentColor={props.accentColor} current={current} items={items}/>

        <ScrollCanvas
          $shouldScrollContents={!!props.shouldScrollContents && !props.$noScroll}
          className={`${
            props.shouldScrollContents ? getCanvasClassName() : ""
          } ${generateClassName(props.widgetId)}`}
        >
          {props.children}
        </ScrollCanvas>
      </XBox>

      <div>
        {current > 0 && (
          <StepButton accentColor={props.accentColor} style={{margin: '0 8px'}} onClick={() => prev()}>
            {prevText || '上一步'}
          </StepButton>
        )}
        {current === tabs.length - 1 && (
          <StepButton
            accentColor={props.accentColor}
            isPrimary={true}
            onClick={() => {
              onFinish && onFinish();
            }}>
            {doneText || '完成'}
          </StepButton>
        )}
        {current < tabs.length - 1 && (
          <StepButton
            accentColor={props.accentColor}
            isPrimary={true}
            onClick={() => next()}>
            {nextText || '下一步'}
          </StepButton>
        )}
      </div>

    </TabsContainerWrapper>
  );
}

export default TabsComponent;
