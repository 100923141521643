import React from 'react';
import {AtDivider} from "taro-ui";
import styled from "styled-components";
const AtDividerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
const TDividerWidget = (props:any) => {
  const {lineColor} = props;
  return (
    <AtDividerWrapper>
      <AtDivider lineColor={lineColor} />
    </AtDividerWrapper>
  );
};

export default TDividerWidget;
