import React, { useContext, useEffect, useRef, useState } from "react";
import { RoleContext } from "@byk/pages/AppSystemInit/RoleManager/index";
import { Button, Col, Divider, Drawer, Form, Input, Layout, message, Radio, Row, Tabs } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons/lib";
import _ from "lodash";
import Api from "@byk/api/Api";
import { useLocation } from "react-router";
import { AppState } from "../../../../ce/reducers";
import { connect, useDispatch, useSelector } from "react-redux";
import { X_PREVIEW_MENU_NUM, X_PREVIEW_USER } from "@byk/store/XReducers";
import MenuPermissions from "./MenuPermissions";
import PagePermissions from "./PagePermissions";
import { getAppCurrUser } from "../../../../ce/AppRouter";
import localStorage, { getTenant } from "../../../../utils/localStorage";
import history from 'utils/history';
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import { cutString } from "@byk/utils/Utils";
import { apiPath } from "@byk/routes";

function DrawerComponent(props: any) {
  const { onClose, level, children, type, onSave } = props;
  const [visible, setVisible] = useState(true);
  const close = (n: number) => {
    setVisible(false);
    onClose(n);
  }
  return (
    <Drawer
      width="60%"
      title={`${type}`}
      placement="right"
      closable={true}
      onClose={() => {
        close(level);
      }}
      open={visible}
    >
      {children}
    </Drawer>
  );
}

function RoleAdd(props: any) {
  const { updatePreviewMenuNum } = props;
  const dispatch = useDispatch();
  const userPermission = useSelector((state: any) => state.XReducers.userPermission);
  const isPt = props.isPt;
  const { applicationId, pageId, onToPage } = isPt ? props : useContext(RoleContext);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get('type');
  const username = queryParams.get('username');
  const roleId = queryParams.get('id');
  const roleName =decodeURIComponent(queryParams.get('roleName') as string);// atob(queryParams.get('roleName') as string);
  // const roleName =decodeURIComponent(escape(window.atob(queryParams.get('roleName') as string)));// atob(queryParams.get('roleName') as string);
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const permMapRef = useRef<any>({});
  const [drawerComp, setDrawerComp] = useState<any>([]);
  let level = 1;
  const [saveLoading, setSaveLoading] = useState(false);
  const menuRef = useRef([]);
  const pageRef = useRef<any[]>([]);
  const [defaultAppId, setDefaultAppId] = useState("");
  const [appList, setAppList] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  //const [roleNameView, setRoleNameView] = useState("");


  const dg = (oldData: any, newData: any) => {
    return oldData.map((i: any) => {
      let cache = {};
      _.each(newData, (j: any) => {
        if (i.id == j.id) {
          cache = {
            ...j
          };
        }
      })
      return {
        ...i,
        ...cache,
        children: i.children && dg(i.children, newData)
      }
    });
  }
  const setMenuRef = (data: any) => {
    menuRef.current = data;
  }
  const fn = (data: any, isToggle: boolean = false) => {
    _.each(data, (i: any) => {
      if (!roleId && !isToggle) {
        i.isEditor = 1;
        i.isVisable = 1;
      }
      let item = {
        "permissionId": i.id,
        "isVisable": i.isVisable ? 1 : 0,
        "isEditor": i.isEditor ? 1 : 0
      }
      if (i.isVisable || i.isEditor) {
        pageRef.current.push(item)
      }

      permMapRef.current[i.id] = item;
      if (i.children) {
        fn(i.children, isToggle);
      }
    })
  };
  const setPageRef = (data: any) => {
    let clonePData = _.cloneDeep(permissionsData);
    clonePData = dg(clonePData, data);
    // console.log('setPageRef', data, clonePData);
    pageRef.current = [];
    permMapRef.current = [];
    fn(clonePData, true);//缓存保存时的pagePermission
    setPermissionsData(clonePData);
  }

  const openDrawer = (comp: any) => {
    setDrawerComp((drawerComp: any) => {
      return _.concat([], drawerComp, comp);
    });
  }

  const closeDrawer = (level: any) => {
    if (level === 1) {
      setTimeout(() => {
        setDrawerComp([]);
      }, 301);
    }
  }
  const goBack = () => {
    if (isPt) {
      history.goBack();
    } else {
      onToPage('back', '');
    }
  }
  const openNext = (type: string, children: any) => {
    openDrawer(
      <DrawerComponent openNext={openNext} onClose={closeDrawer} level={level++} type={type}>
        {children}
      </DrawerComponent>
    );
  }
  //应用切换
  const appChange = (_id: any) => {
    //let _id = e.target.value;
    setDefaultAppId(_id);
    setTableData([]);
    setPermissionsData([]);
    bindMenuAndPage(_id);
  }
  const bindMenuAndPage = (applicationId: any) => {
    let params: any = { appId: applicationId };
    if (type == 'edit' && roleId) {
      params.roleId = roleId;
      form.setFieldValue('roleName', roleName);
      //set
    }

    //全菜单
    Api.get(`${apiPath}/${getTenant()}/subapp/admin/menu/list`, params)
      .then((res: any) => {
        if (res.success) {
          const result = res.result || [];
          const noOtherMenu: any = _.filter(result, (i: any) => {
            return i.menuKey != 'other';
          })
          const otherMenu = _.find(result, { menuKey: 'other' });
          const otherMenuChild = _.filter(otherMenu?.children || [], (i: any) => {
            return i.menuCheck;
          });
          //console.log('otherMenu', otherMenu);
          //console.log('otherMenuChild', otherMenuChild)
          if (otherMenuChild.length == 0) {
            setTableData(noOtherMenu);
          } else {
            const allMenu: any = _.concat([], noOtherMenu, [{
              ...otherMenu,
              children: [...otherMenuChild]
            }]);
            setTableData(allMenu);
          }

        }
      })
    //全权限与已有权限匹配
    Api.get(`${apiPath}/${getTenant()}/subapp/admin/pageElement/list`, params)
      .then((res: any) => {
        if (res.success) {
          fn(res.result);
          setPermissionsData(res.result);
        }
      })
  }

  useEffect(() => {
    //全应用
    Api.get(`${apiPath}/${getTenant()}/subapp/admin/getAppByAppId`, { appId: applicationId,roleId:roleId })
      .then((res: any) => {
        if (res.success) {
          let list = res.result;
          let _list: any = [];
          let _defaultAppId: any = "";
          _.each(list, (item: any, idx: any) => {
            _list.push({ label: item.name, value: item.id, hasPermiss: item.hasPermiss });
          })
          if (_list.length > 0) {
            _defaultAppId = _list[0].value;
          }
          setDefaultAppId(_defaultAppId);
          setAppList(_list);
          bindMenuAndPage(_defaultAppId);
        }
      })
  }, [type, roleId]);

  const beforeSave = () => {
    form.submit();
  }

  const save = (values: any) => {
    if(!defaultAppId){
      message.warn('请先选择应用！');
      return false;
    }
    let saveParams = {
      ...values,
      appId: defaultAppId,
      menu: menuRef.current,
      pageElement: pageRef.current,
      roleId:roleId
    };
    // console.log('savaParams--', saveParams);
    /*if (_.isEmpty(saveParams.menu)) {
      toast.show('请勾选功能菜单权限');
      return;
    }
    if (_.isEmpty(saveParams.pageElement)) {
      toast.show('请勾选页面操作权限');
      return;
    }*/
    //return ;
    setSaveLoading(true);
    let proxy = null;
    if (type == 'add') {
      proxy = Api.post(`${apiPath}/${getTenant()}/subapp/SubappRole`, saveParams);
    }
    if (type == 'edit') {
      saveParams.id = roleId;
      proxy = Api.put(`${apiPath}/${getTenant()}/subapp/SubappRole`, saveParams);
    }
    if (proxy) {
      proxy.then((res: any) => {
        if (res.success) {
          if (!isPt) {
            updatePreviewMenuNum();
            getAppCurrUser({
              appId: applicationId
            }).then((res: any) => {
              if (res.success) {
                dispatch({
                  type: X_PREVIEW_USER,
                  data: res.ext?.[0] || {}
                })
              }
            })
          }
          goBack()
        } else {
          //message.error(res.message);
        }
      }).finally(() => {
        setSaveLoading(false);
      })
    }

  }
  const currChildren = (data: any, key: any) => {
    let _children: any = [];
    const fnChildren = (data1: any) => {
      _.each(data1, (i: any) => {
        if (i.key == key) {
          _children = i.children;
        } else {
          fnChildren(i.children);
        }
      })
    };
    fnChildren(data);
    return _children;
  }
  const drawerType = (type: string, key: any) => {
    let newChildren = currChildren(permissionsData, key);
    //console.log('-**-',permissionsData, key, newChildren);
    let tChildren1: any = [], tChildren2: any = [];
    if (type === 'TABLE_WIDGET_V2') {
      tChildren1 = newChildren.filter((i: any) => {
        return i.widgetType != 'menuButton'
      })
      tChildren2 = newChildren.filter((i: any) => {
        return i.widgetType == 'menuButton'
      })
    }
    openNext(type,
      (
        <>
          {
            type === 'TABLE_WIDGET_V2' && (<Tabs
              items={[
                {
                  label: `字段权限`,
                  key: '1',
                  children: <PagePermissions roleId={roleId} permMapRef={permMapRef} setParams={setPageRef}
                    tableData={tChildren1}
                    drawerType={drawerType} drawerKey={key} />,
                },
                {
                  label: `操作权限`,
                  key: '2',
                  children: <PagePermissions roleId={roleId} permMapRef={permMapRef} setParams={setPageRef}
                    tableData={tChildren2}
                    drawerType={drawerType} drawerKey={key} />,
                }
              ]}
            />)
          }
          {
            type === 'TABS_WIDGET' && (
              <Tabs
                items={newChildren.map((i: any) => {
                  return {
                    label: i.title,
                    key: i.key,
                    children: <PagePermissions roleId={roleId} permMapRef={permMapRef} setParams={setPageRef}
                      tableData={[i]}
                      drawerType={drawerType} drawerKey={key} />,
                  }
                })}
              />
            )
          }
          {
            type !== 'TABLE_WIDGET_V2' && type !== 'TABS_WIDGET' &&
            <PagePermissions roleId={roleId} permMapRef={permMapRef} setParams={setPageRef} tableData={newChildren}
              drawerType={drawerType} drawerKey={key} />
          }

        </>
      )
    )
  }

  const appListComponent = appList.map((item: any, index) => {

    return <div className={"x-menu-normal " + (defaultAppId == item.value ? " x-menu-active" : " x-menu-default")} style={{ position: "relative" }} onClick={() => appChange(item.value)}>
      <span title={item.label} style={{ fontWeight: "bold" }}>{cutString(item.label, 9)}</span>
      <span style={{ float: "right", color: "gray", position: "absolute", right: "5px" }}>{item.hasPermiss == true ? "有权限" : "无权限"}</span>
    </div>;
  });
  return (
    <section style={{ flex: 1, padding: '10px 20px' }}>
      <div className="x-app-title"><ArrowLeftOutlined onClick={() => {
        goBack()

      }} /> 权限设置
      </div>
      <p style={{ lineHeight: "50px" }}>{`角色：${roleName}`}</p>
      <div style={{ margin: '10px 0', display: "none" }}>
        <Form form={form} onFinish={save}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          layout={'inline'}
          autoComplete="off">
          <Form.Item label="角色：" name='roleName'>
            {/* <Input maxLength={20} /> */}
            <span style={{ marginLeft: "10px" }}>{roleName}</span>
          </Form.Item>
        </Form>
      </div>
      <Divider style={{ margin: 0 }} />


      <Row style={{ height: 'calc(100% - 101px)' }}>
        <Col span={4} style={{ paddingRight: "10px" }}>
          <p style={{ lineHeight: "50px", fontSize: "16px", fontWeight: "bold" }}>应用列表</p>
          {appListComponent}
        </Col>

        <Col span={20} style={{ paddingLeft: "10px", borderLeft: "1px solid rgba(0, 0, 0, 0.06)" }}>
          <Tabs
            key={defaultAppId}
            defaultActiveKey="1"
            //activeKey={activeKey}
            items={[
              {
                label: `功能菜单权限`,
                key: '1',
                children: <MenuPermissions roleId={roleId} permMapRef={permMapRef} setParams={setMenuRef}
                  tableData={tableData} />
              },
              {
                label: `页面操作权限`,
                key: '2',
                children: <PagePermissions roleId={roleId} permMapRef={permMapRef} setParams={setPageRef}
                  tableData={permissionsData}
                  drawerType={drawerType} />
              }
            ]}
          />
          <Button type="primary" style={{ position: "absolute", top: 7, right: 0 }} loading={saveLoading} onClick={beforeSave}>保存</Button>
          {
            drawerComp.map((i: any, index: number) => {
              return (
                <div key={index}>
                  {i}
                </div>
              )
            })
          }
        </Col>
      </Row>
    </section>
  )
}

const mapStateToProps = (state: AppState) => ({});
const mapDispatchToProps = (dispatch: any) => ({
  updatePreviewMenuNum: () => {
    dispatch({
      type: X_PREVIEW_MENU_NUM
    })
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(RoleAdd);
