import {Modal, Spin} from 'antd';
import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from 'react';

import styled from "@emotion/styled";
import ModelBasicInfo from "@byk/pages/modeling/MxGraph/ModelBasicInfo";
import ModelProperty from "@byk/pages/modeling/ModelProperty";
import _ from "lodash";
import {PropertyRelationModel} from "@byk/pages/modeling/lib/index";
import PropertyRelation from "@byk/pages/modeling/MxGraph/PropertyRelation";
import GraphModel from "@byk/pages/modeling/lib/GraphModel";
import {PageEleModel} from "@byk/pages/modeling/lib/PageEleModel";
import {useDispatch, useSelector} from "react-redux";
import {getDynamicModel} from "@byk/pages/modeling/modelSelector";
import {setCurDynamicModel} from "@byk/store/modelingReducers";
import ModelIndex from "@byk/pages/modeling/MxGraph/ModelIndex";

const ModalWrapper = styled(Modal)`
  &.ant-modal {
    top: 40px !important;
  }
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-input {
    border-radius: 5px;
    border: 1px solid rgba(228, 228, 229, 1);
  }
`

export const ModelHeaderWrapper = styled.div`
  height: 20px;
  border-left: 5px solid #006CF2;
  margin: 15px 0 15px 16px;

  span {
    padding-left: 6px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    text-align: left;
    font-family: SourceHanSansSC-bold;
  }
`

export interface ModelProps {
  graphModel: GraphModel,
  ref?: any,
  modelPropertyRef?: any,
  model?: any,
  excelModel?: any,
  updateModelState?: (modelData: any) => void,
  mxAndModel?: (modelData: any) => void,
  mxUpdateModel?: (modelData: any, property?: any) => void
  mxDeleteModel?: () => void,
  mxRemoveCell?: (cell?: any) => void,
  mxRemoveEdgeByPropertyId?: (propertyId: any) => void,
  closeMode?: () => void,
  clickTime?: any,
  getModelInfo?: () => any,  // 获取快速建模的模型信息
  pageEleModel?: any,
  setProperties?: (properties: any) => any,
  saveModel?: () => void;
  style?: any;
  setSpinning?: any;
}

const MODEL_TAB = 1;
const INDEX_TAB = 2;
const RELATION_TAB = 3;

const ModelingContext = React.createContext<any>({});

export const ModelingContextContextFn = () => {
  const context = useContext(ModelingContext);
  return context;
}

const CreateModel: React.FC<ModelProps> = forwardRef((props, ref) => {
      useImperativeHandle(ref, () => ({
        showModal,
      }));
      const dispatch = useDispatch();
      const modelData = useSelector(getDynamicModel);

      const [spinning, setSpinning] = useState(false);
      const [isModalOpen, setIsModalOpen] = useState(false);
      const basicFormRef: any = useRef();
      const modalBodyRef: any = useRef();
      const modelPropertyRef = useRef<any>();
      const [tabIdx, setTabIdx] = useState<number>(MODEL_TAB);

      const [clickTime, setClickTime] = useState<any>();
      const [pageEleModel, setPageEleModel] = useState<PageEleModel>(new PageEleModel());

      const showModal = async (model?: any) => {
        setClickTime(new Date().getMilliseconds());
        await pageEleModel.init();
        setPageEleModel(pageEleModel);
        setTabIdx(MODEL_TAB);
        modelPropertyRef.current?.updateModel(model || modelData);
        setIsModalOpen(true);
      };

      const updateModelState = (modelData: any) => {
        dispatch(setCurDynamicModel(modelData));
      }

      const handleCancel = () => {
        if (modelData && modelData.properties) {
          // 返回有Id属性，当新建属性后，会在model里加入一条id为空的数据
          let properties = _.filter(modelData.properties, function (o) {
            return o.id;
          });

          modelData.properties = [...properties];
          dispatch(setCurDynamicModel(modelData));
        }
        setIsModalOpen(false);
      };

    const setProperties = (properties: any = []) => {
      setSpinning(true);
      _.forEach(properties, item => {
        item.model = modelData.name;
        item.modelId = modelData.modelId;
      })
      let md = {
        ...modelData,
        properties: [...properties]
      }
      dispatch(setCurDynamicModel(md));
      modelPropertyRef.current?.updateModel(md);
      setSpinning(false);
    }

      const getContext = () => {
        return {}
      }

      return (
        <ModalWrapper
          className={"createModel"}
          onCancel={handleCancel} open={isModalOpen}
          maskClosable={false}
          footer={null}
          title={modelData?.id ? "编辑模型" : "创建模型"}
          width={1715}
        >
          <Spin tip="Loading..." spinning={spinning}>
            <div ref={modalBodyRef} style={{display: "flex", height: 'calc(100vh - 126px)'}}>
              <ol style={{width: "171px", borderRight: '1px solid rgb(228, 232, 235)'}}>
                <ListLi className={tabIdx == MODEL_TAB ? "listLiActive" : ""}
                        onClick={() => setTabIdx(MODEL_TAB)}>基本信息</ListLi>
                {modelData?.id && (
                  <>
                    <ListLi className={tabIdx == RELATION_TAB ? "listLiActive" : ""}
                            onClick={() => setTabIdx(RELATION_TAB)}>关系</ListLi>
                    <ListLi className={tabIdx == INDEX_TAB ? "listLiActive" : ""}
                            onClick={() => setTabIdx(INDEX_TAB)}>索引</ListLi>
                  </>
                )}
              </ol>

              <ModelingContext.Provider value={getContext()}>
                {tabIdx == MODEL_TAB && (
                  <div style={{width: 'calc(100% - 171px)'}}>
                    <ModelBasicInfo
                      {...props}
                      style={{marginTop: '13px'}}
                      ref={basicFormRef}
                      modelPropertyRef={modelPropertyRef}
                      closeMode={handleCancel}
                      updateModelState={updateModelState}
                      setSpinning={setSpinning}
                    />

                    <ModelProperty
                      {...props}
                      style={{marginLeft: '6px', marginTop: '13px'}}
                      ref={modelPropertyRef}
                      clickTime={clickTime}
                      updateModelState={updateModelState}
                      pageEleModel={pageEleModel}
                      setProperties={setProperties}
                    />
                  </div>
                )}
              </ModelingContext.Provider>
              {tabIdx == RELATION_TAB && (
                <PropertyRelation
                  {...props}
                  propertyRelationModel={new PropertyRelationModel(props.graphModel, modelData)}
                  model={modelData}
                  clickTime={clickTime}
                  updateModelState={updateModelState}
                />
              )}
              {tabIdx == INDEX_TAB &&
              <ModelIndex
                {...props}
              />
              }
            </div>
          </Spin>
        </ModalWrapper>
      );
    }
  )
;

export default CreateModel;

const ListLi = styled.li`
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  padding-left: 23px;
  &:hover {
    background-color: #F3F8FF;
  }
`
