import IconSVG from "./icon.svg";
import Widget from "./widget";
import {LabelPosition} from "../../components/constants";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "DynamicForms",
  iconSVG: IconSVG,
  features: {
    dynamicHeight: {
      sectionIndex: 0,
      active: true,
    },
  },
  defaults: {
    rows: 32,
    columns: 60,
    widgetName: "DynamicForms",
    version: 1,
    submitText: '提交',
    resetText: '重置',
    currentData: [
      {
        field: 'name',
        fieldName: '姓名',
        fieldType: 'input',
        required: true,
        message: 'Please input'
      }, {
        field: 'birth',
        fieldName: '出生日期',
        fieldType: 'date',
        required: true,
        message: 'Please input'
      }, {
        field: 'age',
        fieldName: '年龄',
        fieldType: 'integer',
        message: 'Please input'
      }, {
        field: 'address',
        fieldName: '出生地',
        fieldType: 'cascader',
        message: 'Please input',
        options: [
          {
            label: '湖北省',
            value: '湖北省',
            children: [
              {
                label: '武汉市',
                value: '武汉市',
                children: [
                  {
                    label: '蔡甸区',
                    value: '蔡甸区'
                  }
                ]
              }
            ]
          }
        ]
      }, {
        field: 'gender',
        fieldName: '性别',
        fieldType: 'dict',
        message: 'Please input',
        options: [
          {
            label: '男',
            value: '男'
          },
          {
            label: '女',
            value: '女'
          }
        ]
      }, {
        field: 'marriage',
        fieldName: '婚否',
        fieldType: 'bool',
        message: 'Please input',
        options: [
          {
            label: '是',
            value: true
          }, {
            label: '否',
            value: false
          }
        ]
      }, {
        field: 'descript',
        fieldName: '自我介绍',
        fieldType: 'textarea',
        message: 'Please input'
      }, {
        field: 'pic',
        fieldName: '头像',
        fieldType: 'image',
        message: 'Please input'
      },
    ],
    labelPosition: LabelPosition.Top,
    colSpans: 24,
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
  }
};

export default Widget;
