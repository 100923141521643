import React from "react";
import type {ControlProps} from "./BaseControl";
import BaseControl from "./BaseControl";
import type {InputType} from "components/constants";
import {AtIcon} from "taro-ui";
import {Select} from "antd";
const {Option} = Select;
import taroUIIcon from "../../pages/Editor/MenuFrame/TaroUIIcon";


class CarouselControl extends BaseControl<CarouselControlProps> {
  render() {
    const {propertyValue} = this.props;
    return (
      <Select className={'isIcon'} allowClear onChange={(value) => {
        this.valueChange(value);
      }} value={propertyValue} placeholder="=请选择图标=" style={{width: "100%"}}
      >
        {
          (taroUIIcon as any).map((i: any) => {
            return (
              <Option value={i.value} label={i.label} key={i.label}>
                <div>
                  <AtIcon value={i.value} size='30' />
                  {i.label}
                </div>
              </Option>
            )
          })
        }
      </Select>
    );
  }

  valueChange = (result: any) => {
    this.updateProperty(this.props.propertyName, result, true);
  }


  static getControlType() {
    return "TARO_ICON_SELECT";
  }
}

export interface CarouselControlProps extends ControlProps {
  placeholderText: string;
  inputType: InputType;
  validationMessage?: string;
  isDisabled?: boolean;
  isDescription?: boolean;
  isCarousel?: boolean;
  defaultValue?: any;
  size?: number,
  onFocus?: () => void;
  onBlur?: () => void;
  iconSize?: number,
}

export default CarouselControl;
