import React, {forwardRef, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Form} from "antd";
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import {QueryEditorContext} from "@byk/pages/modeling/SqlQueryEditor/QueryEditorContext";
import Input from "antd/lib/input/Input";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";

const ContainerDiv = styled.div`
  margin: 0;
  color: #151515;
  padding: 0px 24px;

 .breadcrumb {
    display: flex;
    color: #101010;
    padding-bottom: 10px;
    padding-top: 10px;

    button {
      height: 33px;
      width: 118px;
      padding: 0px;
      span.iconPark {
        height: 13px;
      }
    }

    .title {
      margin-left: 12px;
      font-size: 20px;
    }

    .noSelectedModel {
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      color: #a00606;
    }

    .selectedModel {
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 0 12px;
    }
  }

  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    background: #f5f5f5 !important;
    border-color: var(--primary-color10) !important;
    background: var(--primary-color70) !important;
  }

  .edit-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    height: 32px;

    .input-name {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 372px;
    }

    .runBtn {
      margin-right: 15px;
    }

    .saveBtn {
      margin-right: 5px;
    }

    .ant-btn-primary {
       background: #E15615 !important;
       border-color: #E15615 !important;
    }

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      border-color: var(--ads-orange-accent)) !important;
      background: var(--ads-color-orange-500) !important;
    }
  }
`

const QueryEditorHeader: React.FC<any> = forwardRef((props, ref) => {
  const {gotoModeling, setState, sqlInfoAction, getState, codeMirrorSqlRef} = useContext(QueryEditorContext);
  const {sqlInfo} = getState();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('title', sqlInfo?.title || '');
  }, [sqlInfo])

  const runSql = async () => {
    let param = {sql: codeMirrorSqlRef.current || ''}
    let res: any = await ModelingApi.doExecuteSqlInfo(param);
    setState({executionResult: res, tabKey: 'Response'})
  }

  const onSave = () => {
    form.validateFields().then(() => {
      let type = sqlInfo.id ? 'update' : 'insert';
      sqlInfoAction(type, {...sqlInfo, sql: codeMirrorSqlRef.current});
    })
  }

  return (
    <ContainerDiv className={'queryEditorHeader'}>
      <div className='breadcrumb'>
        <Button icon={getIconPark('Left')} onClick={gotoModeling}>返回业务建模</Button>
      </div>
      <div className='edit-block'>
        <Form form={form}>
          <Form.Item
            label="标题"
            name="title"
            rules={[{required: true, message: ""}]}>
            <Input
              style={{width: '300px'}}
              onChange={(e: any) => {
                setState({sqlInfo: {...sqlInfo, title: e.target.value}});
              }}
            />
          </Form.Item>
        </Form>
        <div>
          <Button disabled={!sqlInfo?.title} type='primary' className='saveBtn' onClick={onSave}>保存</Button>
          <Button disabled={!sqlInfo?.sql} type='primary' className='runBtn' onClick={runSql}>执行</Button>
        </div>
      </div>
    </ContainerDiv>
  )
})

export default QueryEditorHeader;
