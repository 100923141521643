import {ASSETS_CDN_URL} from "constants/ThirdPartyConstants";
import IconSVG from "./icon.svg";
import Widget from "./widget";
import {getAssetUrl} from "@appsmith/utils/airgapHelpers";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Carousel",
  iconSVG: IconSVG,
  defaults: {
    defaultImage: getAssetUrl(`${ASSETS_CDN_URL}/widgets/default.png`),
    imageShape: "RECTANGLE",
    maxZoomLevel: 1,
    imageData: [],
    enableRotation: false,
    enableDownload: false,
    objectFit: "cover",
    rows: 12,
    columns: 40,
    widgetName: "Carousel",
    version: 1,
    animateLoading: true,
    mappingConfig: {
      url: {
        id: 'url',
        index: 0,
        label: '轮播图片',
        type: "text"
      }
    }
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "280px",
            minHeight: "40px",
          };
        },
      },
    ],
  },
};

export default Widget;
