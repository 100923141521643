import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TNavBarWidget from "../component";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";

class TNavBar extends TaroWidget<TNavBarWidgetProps, WidgetState> {
  constructor(props: TNavBarWidgetProps) {
    super(props);
    this.onClickRgIconSt = this.onClickRgIconSt.bind(this);
  }
  static getPropertyPaneEventsConfig() {
    return super.getWidgetEvents("AtNavBarWidget");
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "按钮样式",
        children: [
          {
            propertyName: "color",
            label: "颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "bgColor",
            label: "背景颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          }
        ],
      },
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.label }}`
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }
  onClickRgIconSt(){
    super.executeAction({
      triggerPropertyName: "onClickRgIconSt",
      dynamicString: this.props.onClickRgIconSt,
      event: {
        type: EventType.ON_CLICK
      },
    });
  }

  getPageView() {
    return (
      <TNavBarWidget {...this.props} onClickRgIconSt={this.onClickRgIconSt} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_NAVBAR_WIDGET";
  }
}

export interface TNavBarWidgetProps extends WidgetProps {
  value: any[];
  label: string;
  onClick?: any;
  borderRadius: string;
  boxShadow?: string;
}

export default TNavBar;
