import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "AtPicker",
  iconSVG: IconSVG,
  needsMeta: true,
  defaults: {
    rows: 3,
    columns: 128,
    widgetName: "AtPicker",
    version: 1,
    animateLoading: true,
    isDisabled: false,
    isVisible: true,
    label: 'label',
    value: '',
    mode: 'date',
    selectorOptions: ['男', '女'],
    multiSelectorOptions: [['a','b'], ['c','d']],
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventsConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  }
};

export default Widget;
