import React, {useEffect, useState} from 'react';
import {AtInputNumber} from "taro-ui";
import {LabelWidget} from "../../index";

const TInputNumberWidget = (props:any) => {
  const {label,value:wValue, updateWidgetProperty, isDisabled, disabledInput, min, max, step,
    fontSize,
    color,
    fontStyle,
    inputFontSize,
    inputColor,
    inputFontStyle,
    bgColor,
    borderColor} = props;
  const [value, setValue] = useState<number>(1);
  useEffect(()=>{
    value!=wValue&&setValue(wValue);
  }, [wValue]);
  return (
    <LabelWidget label={label} fontSize={fontSize}
                 color={color}
                 fontStyle={fontStyle}
                 inputFontSize={inputFontSize}
                 inputColor={inputColor}
                 inputFontStyle={inputFontStyle}
                 bgColor={bgColor}
                 borderColor={borderColor}>
      <AtInputNumber
        value={value}
        disabled={isDisabled}
        min={min}
        max={max}
        step={step}
        disabledInput={disabledInput}
        onChange={(v:any)=>{
          setValue(v);
          updateWidgetProperty&&updateWidgetProperty(v);
        }}
        type={'number'} />
    </LabelWidget>
  );
};

export default TInputNumberWidget;
