import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import type {DerivedPropertiesMap} from "utils/WidgetFactory";
import type {AutocompletionDefinitions} from "widgets/constants";
import XCollapseComponent from "../component";
import {EvaluationSubstitutionType} from "../../../entities/DataTree/types";

class XCollapseWidget extends BaseWidget<XCollapseWidgetProps, WidgetState> {
  constructor(props: XCollapseWidgetProps) {
    super(props);
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {};
  }

  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('XCollapseWidget');
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            propertyName: "data",
            label: "头部文字",
            controlType: "BREADCRUMB_ARRAY",
            placeholderText: '[{ "name": "John" }]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.ARRAY},
            evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE
          },
        ],
      },
      {
        sectionName: "面板设置",
        children: [],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "样式",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      },
      {
        sectionName: "颜色",
        children: [
          {
            propertyName: "backgroundColor",
            helpText:
              "Sets the background color of a menu item",
            label: "头部背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "backgroundColor",
            helpText:
              "Sets the background color of a menu item",
            label: "内容背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "textColor",
            helpText: "Sets the text color of a menu item",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "textColor",
            helpText: "Sets the text color of a menu item",
            label: "头部与内容间分隔颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "边框&阴影",
        children: [
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "文字",
        children: [
          {
            propertyName: "textSize",
            label: "头部文字大小",
            helpText: "Controls the size of text in the column",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
            ],
            defaultValue: "1rem",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "textColor",
            label: "头部文字颜色",
            helpText: "Controls the color of text in the column",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: '#27272a',
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "lineHeight",
            helpText: "Changes the width of the button",
            label: "头部文字行高",
            controlType: "INPUT_TEXT",
            isJSConvertible: false,
            isBindProperty: true,
            defaultValue: 3,
            validation: {type: ValidationTypes.NUMBER},
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getStylesheetConfig(): Stylesheet {
    return {};
  }

  getPageView() {

    return (
      <XCollapseComponent {...this.props}/>
    );
  }

  componentDidMount() {
  }

  static getWidgetType(): WidgetType {
    return "XCOLLAPSE_WIDGET";
  }
}

export interface XCollapseWidgetProps extends WidgetProps {
}

export default XCollapseWidget;
