import React from "react";
import UiWidget from '../component';
import BaseWidget, { WidgetState } from "../../../widgets/BaseWidget";
import { WidgetType } from "../../../constants/WidgetConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { EvaluationSubstitutionType } from "../../../entities/DataTree/types";
import { PropertyPaneConfig } from "../../../constants/PropertyControlConstants";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import {Stylesheet} from "../../../entities/AppTheming";
class Marquee extends BaseWidget<any, WidgetState> {
  constructor(props:any) {
    super(props);
  }
  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return super.getWidgetEvents('MarqueeWidget');
  }
  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          }
        ],
      },
      {
        sectionName: "Data",
        children: [
          {
            helpText: "Bind data from an API using {{}}",
            propertyName: "currentData",
            label: "data",
            controlType: "BREADCRUMB_ARRAY",
            placeholderText: '[{ "name": "John" }]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.ARRAY },
            evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE
          },
          {
            helpText: "Sets direction.",
            propertyName: "directionX",
            label: "滚动方向",
            controlType: "DROP_DOWN",
            defaultValue: "left",
            options: [
              {
                label: "左",
                value: "left",
              },
              {
                label: "右",
                value: "right",
              },
              {
                label: "上",
                value: "up",
              },
              {
                label: "下",
                value: "down",
              }
            ],
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: ["left", "right", "up", "down"],
              },
            },
          },
          {
            helpText:
              "Sets speed",
            propertyName: "speed",
            label: "移动速度",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            helpText: "",
            propertyName: "isPause",
            label: "是否逐条播放",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          }
        ]

      }
    ];
  }
  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "fontFamily",
            label: "字体",
            helpText: "Controls the font family being used",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "System Default",
                value: "System Default",
              },
              {
                label: "Nunito Sans",
                value: "Nunito Sans",
              },
              {
                label: "Poppins",
                value: "Poppins",
              },
              {
                label: "Inter",
                value: "Inter",
              },
              {
                label: "Montserrat",
                value: "Montserrat",
              },
              {
                label: "Noto Sans",
                value: "Noto Sans",
              },
              {
                label: "Open Sans",
                value: "Open Sans",
              },
              {
                label: "Roboto",
                value: "Roboto",
              },
              {
                label: "Rubik",
                value: "Rubik",
              },
              {
                label: "Ubuntu",
                value: "Ubuntu",
              },
            ],
            defaultValue: "System Default",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "fontSize",
            label: "字体大小",
            helpText: "Controls the size of the font used",
            controlType: "DROP_DOWN",
            defaultValue: "1rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          // {
          //   propertyName: "lineHeight",
          //   helpText: "Changes the width of the button",
          //   label: "行高",
          //   controlType: "INPUT_TEXT",
          //   isJSConvertible: false,
          //   isBindProperty: true,
          //   // isTriggerProperty: false,
          //   validation: { type: ValidationTypes.NUMBER },
          // },
          // {
          //   propertyName: "textAlign",
          //   label: "对齐方式",
          //   helpText: "Controls the horizontal alignment of the text",
          //   controlType: "ICON_TABS",
          //   fullWidth: true,
          //   options: [
          //     {
          //       startIcon: "align-left",
          //       value: "LEFT",
          //     },
          //     {
          //       startIcon: "align-center",
          //       value: "CENTER",
          //     },
          //     {
          //       startIcon: "align-right",
          //       value: "RIGHT",
          //     },
          //   ],
          //   defaultValue: "LEFT",
          //   isJSConvertible: true,
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.TEXT },
          // },
          {
            propertyName: "fontStyle",
            label: "加粗&斜体",
            helpText: "Controls the font emphasis of the text displayed",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "颜色",
        children: [
          {
            propertyName: "textColor",
            label: "字体颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "backgroundColor",
            label: "背景颜色",
            helpText: "Background color of the text added",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^((?![<|{{]).+){0,1}/,
                expected: {
                  type: "string (HTML color name or HEX value)",
                  example: `red | #9C0D38`,
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
          },
          {
            helpText: "Use a html color name, HEX, RGB or RGBA value",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "borderColor",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          // {
          //   propertyName: "truncateButtonColor",
          //   label: "Truncate button color",
          //   helpText: "Controls the color of the truncate button",
          //   controlType: "COLOR_PICKER",
          //   isJSConvertible: true,
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: {
          //     type: ValidationTypes.TEXT,
          //     params: {
          //       regex: /^((?![<|{{]).+){0,1}/,
          //     },
          //   },
          //   dependencies: ["overflow"],
          //   hidden: (props: TextWidgetProps) => {
          //     return props.overflow !== OverflowTypes.TRUNCATE;
          //   },
          // },
        ],
      },
      // {
      //   sectionName: "文字格式",
      //   children: [

      //   ],
      // },
      {
        sectionName: "边框&阴影",
        children: [
          {
            helpText:
              "Enter value for border width which can also use as margin",
            propertyName: "borderWidth",
            label: "边框宽度",
            placeholderText: "Enter value in px",
            controlType: "NUMERIC_INPUT",
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: { type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              }
            },
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角半径",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            min: 0,
            validation: { type: ValidationTypes.NUMBER ,
              params: {
                natural: true,
              }
            },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  getPageView(){
    return (
      <>
        <UiWidget {...this.props} />
      </>
    )
  }
  static getWidgetType(): WidgetType{
    return 'MARQUEE_WIDGET';
  }
}

export default Marquee;
