import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "AtIcon",
  iconSVG: IconSVG,
  needsMeta: true,
  defaults: {
    rows: 3,
    columns: 16,
    widgetName: "AtIcon",
    version: 1,
    animateLoading: true,
    isVisible: true,
    size: 24,
    color: '#000',
    label: '图标按钮',
    iconName: 'add-circle',
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventsConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  }
};

export default Widget;
