import * as React from "react";
import {Collapse} from 'antd';
import {generateClassName, getCanvasClassName} from "../../../utils/generators";
import styled from "styled-components";
import {scrollCSS} from "../../../widgets/WidgetUtils";
import WidgetFactory from "../../../utils/WidgetFactory";

const {Panel} = Collapse;

const ScrollCanvas = styled.div<{ $shouldScrollContents: boolean }>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: 100%;
`;

function XCollapseComponent(props: any) {
  const {data = []} = props;

  return (
    <Collapse accordion>
      {data.map((item: any) =>
        <Panel header="This is panel header 1" key="1">
          <ScrollCanvas
            $shouldScrollContents={!!props.shouldScrollContents && !props.$noScroll}
            className={`${
              props.shouldScrollContents ? getCanvasClassName() : ""
            } ${generateClassName(props.widgetId)}`}
          >
            #####
          </ScrollCanvas>
        </Panel>
      )}
    </Collapse>
  );
}

export default XCollapseComponent;
