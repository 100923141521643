import React, {useCallback, useEffect, useRef} from "react";
import * as echarts from 'echarts';
import _ from 'lodash';
import {EChartsType} from "echarts/types/dist/echarts";

function EchartsComponent(props:any){
  const {currentData, maincanvasWidth, rightColumn, leftColumn} = props;
  const chartRef = useRef(null);
  const chartInstance = useRef<EChartsType>();
  const renderChart = () => {
    let _currentData = _.cloneDeep(currentData);
    if(_.isString(_currentData)&&!_.isEmpty(_currentData)){
      _currentData = eval('(' + _currentData + ')');
    }
    chartInstance.current = echarts.init(chartRef.current);
    const option = {
      ..._currentData[0]
    };
    chartInstance.current.setOption(option);
  };
  useEffect(()=>{
    if(chartInstance.current){
      setTimeout(()=>{
        chartInstance.current?.resize();
      }, 200);
    }
  }, [maincanvasWidth, rightColumn, leftColumn]);

  useEffect(() => {
    if(!_.isEmpty(currentData)){
      renderChart();
    }
  }, [currentData]);
  const DataDom = useCallback(()=>{
    if(_.isEmpty(currentData)){
      return <>暂无数据</>;
    }else{
      return <div ref={chartRef} style={{width: '100%', height: '100%', overflow: 'hidden'}}/>;
    }
  }, [currentData]);

  return <DataDom />;
}

export default EchartsComponent;
