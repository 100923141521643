import React from "react";

import type { WidgetState } from "widgets/BaseWidget";
import BaseWidget, {WidgetProps} from "widgets/BaseWidget";
import {WithMeta} from "../../../widgets/MetaHOC";
import AntdTable from "../component";
import {WIDGET_PADDING, WidgetType} from "../../../constants/WidgetConstants";
import contentConfig from "./propertyConfig/contentConfig";
import {getStyleConfig} from "./propertyConfig/styleConfig";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import _ from "lodash";
import {AutocompletionDefinitions} from "../../../widgets/constants";
import {ExtraDef, generateTypeDef} from "../../../utils/autocomplete/dataTreeTypeDefCreator";
import {Stylesheet} from "../../../entities/AppTheming";
interface TableWidgetProps extends WidgetProps, WithMeta {

}
class TableWidget extends BaseWidget<TableWidgetProps, WidgetState> {
  constructor(props: TableWidgetProps) {
    super(props);
    this.updateWidgetProperty = this.updateWidgetProperty.bind(this);
  }
  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneEventsConfig() {
    return super.getWidgetEvents("TableWigetAntd");
  }
  static getPropertyPaneStyleConfig() {
    return getStyleConfig();
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return (widget: TableWidgetProps, extraDefsToDefine?: ExtraDef) => {
      const config = {
        currentItem: generateTypeDef(widget.currentItem, extraDefsToDefine),
        currPage: "number",
        pageSize: "number",
        selectId: "string"
      };
      return config;
    };
  }
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      currentItem: {},
      currPage: 1,
      pageSize: 10
    };
  }
  static getDefaultPropertiesMap(): Record<string, any> {
    return {
      currentItem: {},
    };
  }
  static getDerivedPropertiesMap() {
    return {
      currentItem: "{{this.currentItem}}",
      getSelectedRow: "{{this.currentItem}}",
      getSelectId: "{{this.currentItem.id}}",
      getSelectedRows: "{{this.selectedRows}}",
      getSelectIds: "{{this.selectedRowKeys}}",
      selectId: "{{this.currentItem.id}}",
      selectIds: "{{this.selectedRowKeys}}",
      primaryColor: "{{appsmith.theme.colors.primaryColor}}",
      backgroundColor: "{{appsmith.theme.colors.backgroundColor}}",
    }
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  updateWidgetProperty(propertyName: string, propertyValue: any): void {
    super.updateWidgetProperty(propertyName, propertyValue);
  }
  getPaddingAdjustedDimensions = () => {
    // eslint-disable-next-line prefer-const
    let { componentHeight, componentWidth } = this.getComponentDimensions();
    // (2 * WIDGET_PADDING) gives the total horizontal padding (i.e. paddingLeft + paddingRight)
    componentWidth = componentWidth - 2 * WIDGET_PADDING;
    return { componentHeight, componentWidth };
  };
  //单元格点击事件
  onCellClick = (rows:any, eventStr:any)=>{
    this.props.updateWidgetMetaProperty("currentItem", rows);
    if(!eventStr){
      return ;
    }
    let cloneEventStr:any = _.cloneDeep(eventStr);
    const fn = (data:any)=>{
      _.each(data, (i:any)=>{
        if(i.type == 'action'&&i.actionConfig&&(i.actionConfig.apiParams||i.actionConfig.navigateParams)){
          _.each(rows, (v:any, k:any)=>{
            if(i.actionConfig.apiParams){
              i.actionConfig.apiParams = i.actionConfig.apiParams.replace(`currentItem.${k}`, `"${v}"`);
            }
            if(i.actionConfig.navigateParams){
              i.actionConfig.navigateParams = i.actionConfig.navigateParams.replace(`currentItem.${k}`, `"${v}"`);
            }
          })
        }
        if(i.type == 'action' && i.actionConfig.navigateURL){
          _.each(rows, (v:any, k:any)=>{
            i.actionConfig.navigateURL = i.actionConfig.navigateURL.replace(/\s/g, '').replace(`{currentItem.${k}}`, `${v}`);
          })

        }
        i.children&&fn(i.children);
      })
    };
    if(_.isString(cloneEventStr)){
      _.each(rows, (v:any, k:any)=>{
        cloneEventStr = cloneEventStr
          .replace(`/{currentItem.${k}}`, `/${v}`)
          .replace(`{currentItem.${k}}`, `'${v}'`)
          .replace(`currentItem.${k}`, `'${v}'`);
      })
    }else{
      fn(cloneEventStr.children);
    }

    setTimeout(()=>{
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: cloneEventStr,
        event: {
          type: EventType.ON_CLICK,
        },
      });
    }, 600);
  }
  //currPage和pageSize 发生变化时
  paginationChange = (currPage:any, pageSize:any)=>{
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty('currPage', currPage);
    this.props.updateWidgetMetaProperty('pageSize', pageSize);
    let _onLoad = this.props.onLoad;
    super.executeAction({
      triggerPropertyName: "onPaginationChange",
      dynamicString: _onLoad,
      event: {
        type: EventType.ON_CLICK,
      },
      globalContext: {
        widgetName: this.props.widgetName
      }
    });
  }
  //行选择change事件
  onRowSelection = (selectedRowKeys:any, selectedRows:any) =>{
    this.props.updateWidgetMetaProperty('selectedRowKeys', selectedRowKeys);
    this.props.updateWidgetMetaProperty('selectedRows', selectedRows);
  }
  //切换组件是否可拖拽
  toggleDrag = (disable: boolean) => {
    this.disableDrag(disable);
  }

  getPageView() {
    const { componentHeight } = this.getPaddingAdjustedDimensions();
    // let btnTheme = this.getThemeSettingsByKey('button');
    let primaryColorTheme = this.getThemeSettingsByKey('colors', 'primaryColor');
    return <AntdTable {...this.props}
                      height={componentHeight}
                      onCellClick={this.onCellClick}
                      paginationChange={this.paginationChange}
                      onRowSelection={this.onRowSelection}
                      onToggleDrag={this.toggleDrag}
                      updateWidgetProperty={this.updateWidgetProperty}
                      // btnTheme={btnTheme}
                      primaryColorTheme={primaryColorTheme}/>
  }
  static getWidgetType(): WidgetType {
    return "TABLE_WIDGET_ANTD";
  }

}

export default TableWidget;
