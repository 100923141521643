import React, { useCallback, useEffect, useRef } from "react";
import * as echarts from 'echarts';
import _ from 'lodash';
import { EChartsType } from "echarts/types/dist/echarts";
import { indexOf } from 'lodash';
import { FontStyleTypes } from "design-system-old";
import { fontSizeUtility } from "widgets/WidgetUtils";
import { DEFAULT_FONT_SIZE } from "constants/WidgetConstants";
const  formatData: any = (data:any,tabsObj:any) => {
  let dataCfg:any=[];
  for (const key in tabsObj) {
    if (tabsObj.hasOwnProperty(key)) { 
      let obj:any=tabsObj[key];
      dataCfg.push({name:obj.label,isVisible:obj.isVisible,color:obj.color,
        type:obj.sType||"line",smooth:obj.isSmooth,isAreaStyle:obj.isAreaStyle});
    }
  }
  let _data:any= _.cloneDeep(data);
  let _rData:any=[];
  _.each(_data,(itm:any,idx:any)=>{
    let cfg:any=dataCfg.filter((obj:any)=>{
      return obj.name==itm.name;
    });
    _data[idx].type="line";
    if(cfg.length>0){
      cfg=cfg[0];
      if(cfg.color){
        _data[idx].itemStyle={color:cfg.color};
      } 
      _data[idx].type=cfg.type;
      if(cfg.type=="line"){
        _data[idx]={..._data[idx],...{smooth:cfg.smooth,areaStyle: cfg.isAreaStyle ? true : null}};
      }
      if(cfg.isVisible){
        _rData.push(_data[idx]);
      }
    }
    else{
      _rData.push(_data[idx]);
    }
  })
  return _rData;
}

function EchartsComponent(props: any) {
  const { currentData, maincanvasWidth, rightColumn, leftColumn, sType, isDarkMode, color, isSmooth,
    isAreaStyle, isDownload, fontColor, isDataView, backgroundColor, fontStyle, fontFamily, fontSize,
    lineHeight, borderColor, borderWidth, boxShadow, borderRadius,isTitle,title,isShowTab,tabsObj,xColor,isShowLabel,isLegend } = props;
  const chartRef = useRef(null);
  const chartInstance = useRef<EChartsType>();
  const temp: any = [{
    "tooltip": {
      "trigger": 'axis',
      show:isShowTab
    },
    "legend": {
      // "orient": 'vertical',
      // "left": 'left',
      "show": isLegend,
      data: [],
      "textStyle": {
        "color": fontColor,
        "fontStyle": fontStyle?.indexOf("italic") > -1 ? "italic" : "",
        "fontWeight": fontStyle?.indexOf("bold") > -1 ? "bold" : "normal",
        "fontFamily": fontFamily,
      },
    },
    "toolbox": {
      "feature": {
        "saveAsImage": { "title": "下载", "show": isDownload },
        "dataView": { "title": "数据视图", "show": isDataView, "lang": ['数据视图', '关闭', '刷新'] }
      }
    },
    label: {
      show: isShowLabel,
      position: 'top'
    },
    "xAxis": {
      "type": "category",
      axisLine:{
        lineStyle:{
          color:xColor
        }
      },
      "data": []
    },
    "yAxis": {
      "type": "value",
      boundaryGap: [0, 0.01]
    },
    "series": [],
    //"color": color,
    "backgroundColor": backgroundColor || "transparent",
    "textStyle": {
      "color": fontColor,
      "fontStyle": fontStyle?.indexOf("italic") > -1 ? "italic" : "",
      "fontWeight": fontStyle?.indexOf("bold") > -1 ? "bold" : "normal",
      "fontFamily": fontFamily,
      //"fontSize": fontSize || 12
    },
  }];

  const d1: any = [{
    xData: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    yData: [150, 230, 224, 218, 135, 147, 660],
  }]

  const renderChart = () => {
    //console.log("xxxxxxxxxxxxxxx", props);
    if (!_.isArray(currentData)) {
      alert("error");
      return false;
    }
    let _currentData: any = _.cloneDeep(temp);
    let _data= formatData(currentData[0]?.yData||[],tabsObj)||[];
    _currentData[0].xAxis.data = currentData[0]?.xData||[];
    _currentData[0].series = _data;
    _currentData[0].legend.data = _data||[];
    if(sType=="x"){
      let x=_currentData[0].xAxis;
      let y=_currentData[0].yAxis;
      _currentData[0].xAxis=y;
      _currentData[0].yAxis=x;
    }
    
    chartInstance.current = echarts.init(chartRef.current);
    console.log("2222222", _currentData);
    const option = {
      ..._currentData[0]
    };
    chartInstance.current.setOption(option);
  };
  useEffect(() => {
    if (chartInstance.current) {
      setTimeout(() => {
        chartInstance.current?.resize();
      }, 200);
    }
  }, [maincanvasWidth, rightColumn, leftColumn]);

  useEffect(() => {
    try{
      renderChart();
    }
    catch(e:any){

    }
    // if (!_.isEmpty(currentData)) {
    //   renderChart();
    // }
    //}, [currentData, sType, isDarkMode, color, isSmooth, isAreaStyle, isDownload, fontColor]);
  }, [props]);

  const DataDom = useCallback(() => {
    if (_.isEmpty(currentData)) {
      return <>暂无数据</>;
    } else {
      return <div ref={chartRef} style={{
        width: '100%', height: '100%', overflow: 'hidden', borderColor: borderColor || "#ccc",
        borderWidth: borderWidth, borderRadius:`${borderRadius }`, boxShadow: `${boxShadow }`
      }} />;
    }
  }, [props]);
  // }, [currentData]);

  return <DataDom />;
}

export default EchartsComponent;
