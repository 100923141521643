import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TInputWidget from "../component";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";
import {FontStyleTypes} from "constants/WidgetConstants";
import {fontSizeOptions} from "../../../pages/Editor/ThemePropertyPane/controls/ThemeFormControl";

class TInput extends TaroWidget<TInputWidgetProps, WidgetState> {
  constructor(props: TInputWidgetProps) {
    super(props);
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "defaultValue",
            label: "默认值",
            helpText: "设置该组件的默认值",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "inputType",
            propertyName: "inputType",
            label: "字段类型",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "单行文本",
                value: "text",
              },
              {
                label: "手机号码",
                value: "phone",
              },
              {
                label: "数字",
                value: "number",
              },
              {
                label: "密码",
                value: "password",
              },
              {
                label: "邮箱",
                value: "email",
              },
              {
                label: "身份证号码",
                value: "idcard",
              },
              {
                label: "小数",
                value: "dig",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          }
        ],
      },
      {
        sectionName: "验证",
        children: [
          {
            propertyName: "isRequired",
            label: "是否必填",
            helpText: "Makes input to the widget mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            helpText: "regex",
            propertyName: "regex",
            label: "正则",
            controlType: "INPUT_TEXT",
            placeholderText: "^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$",
            inputType: "TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.REGEX },
          }
        ]
      },

    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "fontSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: fontSizeOptions,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "color",
            label: "颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "fontStyle",
            label: "加粗&斜体",
            helpText: "Controls the font emphasis of the text displayed",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: FontStyleTypes.BOLD,
              },
              {
                icon: "text-italic",
                value: FontStyleTypes.ITALIC
              }
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          }
        ],
      },
      {
        sectionName: "输入文字样式",
        children: [
          {
            propertyName: "inputFontSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: fontSizeOptions,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "inputColor",
            label: "颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "inputFontStyle",
            label: "加粗&斜体",
            helpText: "Controls the font emphasis of the text displayed",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: FontStyleTypes.BOLD,
              },
              {
                icon: "text-italic",
                value: FontStyleTypes.ITALIC,
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          }
        ],
      },
      {
        sectionName: "输入框样式",
        children: [
          {
            propertyName: "bgColor",
            label: "背景颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "borderColor",
            label: "边框颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          }
        ],
      }
    ];
  }


  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
      fontSize: "{{appsmith.theme.form.label.fontSize}}",
      color: "{{appsmith.theme.form.label.fontColor}}",
      fontStyle: "{{appsmith.theme.form.label.fontStyle}}",
      inputFontSize: "{{appsmith.theme.form.inputText.fontSize}}",
      inputColor: "{{appsmith.theme.form.inputText.fontColor}}",
      inputFontStyle: "{{appsmith.theme.form.inputText.fontStyle}}",
      bgColor: "{{appsmith.theme.form.inputBorder.bgColor}}",
      borderColor: "{{appsmith.theme.form.inputBorder.bgBorderColor}}",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isDirty: false,
      value: ''
    };
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.value }}`
    };
  }
  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue"
    };
  }

  updateWidgetProperty = (propertyValues: string) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty("value", propertyValues);
  }

  getPageView() {

    return (
      <TInputWidget {...this.props} updateWidgetProperty={this.updateWidgetProperty} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_INPUT_WIDGET";
  }
}

export interface TInputWidgetProps extends WidgetProps {
  value: any[];
  enableDownload?: boolean;
  enableRotation?: boolean;
  onClick?: string;
  borderRadius: string;
  boxShadow?: string;
}

export default TInput;
