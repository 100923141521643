//表格配置
import {ColumnsType} from "antd/lib/table";
import {Button, Space} from "antd";
import React from "react";
import {QUERY_TASK_MSG_URL} from "@byk/pages/TaskLog/api";
import moment from "moment";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";

/**
 * Table配置项
 * @param deleteCallback
 */
export function tableProps(showMsg: any) {
  const columns: ColumnsType<any> = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => `${index + 1}`,
      width: 60,
    },
    {
      title: "traceId",
      dataIndex: "traceId",
      key: "traceId",
      width: 100,
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '描述',
      dataIndex: 'description',
    },
    {
      title: '任务总数',
      dataIndex: 'size',
    },
    {
      title: '已执行',
      dataIndex: 'current',
    },
    {
      title: '成功执行',
      dataIndex: 'success',
    },
    {
      title: '状态',
      dataIndex: 'finish',
      render: (_, record) => (
        <>{record.finish == 1 ? '已结束' : '执行中'}</>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      render: (_, record: any) => {
        return moment(record.createDate).format('YYYY-MM-DD hh:MM:ss');
      }
    },
    {
      title: '操作',
      dataIndex: 'x',
      render: (_, record) => (
        <Space size="middle">
          {record.message &&
          <Button type="link" onClick={() => showMsg(record)}>
            查看消息
          </Button>
          }
        </Space>
      ),
    },
  ];

  const tableProps = {
    columns: columns,
    api: QUERY_TASK_MSG_URL,
    pagination: {
      currPage: 1,
      pageSize: 10
    },
    initParams: {
      tenant: ModelingApi.getTenant()
    }
  }

  return tableProps
}

/**
 * 搜索配置项
 * @param refreshTable
 */
export function searchProps(refreshTable: any) {
  const searchConfig: any[] = [
    {
      itemType: "input",
      placeholder: "请输入",
      name: "traceId",
      label: "TraceId",
      allowClear: true,
      col: 4,
      labelCol:8
    },
    {
      name: 'name',
      label: '名称',
      itemType: 'input',
      placeholder: '',
      allowClear: true,
      col: 4
    },
    {
      name: 'description',
      label: '描述',
      itemType: 'input',
      placeholder: '',
      allowClear: true,
      col: 4
    },
    {
      itemType: 'select',
      name: 'finish',
      label: '状态',
      placeholder: '',
      allowClear: true,
      col: 4,
      data: [
        {name: '执行中', id: false},
        {name: '已结束', id: true},
      ]
    },
    // {
    //   itemType: 'DatePicker',
    //   name: 'createDate',
    //   label: '创建时间',
    //   placeholder: '',
    //   allowClear: true,
    //   col: 4,
    // },
  ]

  const searchProps = {
    option: searchConfig,
    onSearch: refreshTable,
    btnBoolean: true
  }

  return searchProps
}


