import React from "react";
import CarouselWidget from '../component';
import BaseWidget, { WidgetState } from "../../../widgets/BaseWidget";
import { WidgetType } from "../../../constants/WidgetConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { EventType, type ExecutionResult } from "constants/AppsmithActionConstants/ActionConstants";
import type { DerivedPropertiesMap } from "utils/WidgetFactory";
import { Stylesheet } from "../../../entities/AppTheming";

class Carousel extends BaseWidget<any, WidgetState> {
  constructor(props: any) {
    super(props);
  }
  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('PROGRESS_WIDGET');
  }
  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "标签",
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "labelText",
            label: "文本",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "数据",
        children: [
          {
            helpText:
              "Sets the label width of the widget as the number of columns",
            propertyName: "defaultValue",
            label: "默认值",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
        ],
      },
      {
        sectionName: "一般",
        children: [
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isVisible",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isShowInfo",
            label: "是否显示进度数值",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }
  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "stype",
            label: "类型",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              { label: "条形", value: "line" },
              { label: "圆形", value: "circle" },
              { label: "仪表", value: "dashboard" },
            ],
            defaultValue: "line",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "strokeColor",
            label: "进度条颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "trailColor",
            label: "进度背景色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {

            propertyName: "borderWidth",
            label: "宽度",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 1,
            defaultValue: 1,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
        ],
      },
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.value }}`,
      getValue: `{{ this.value }}`,
    };
  }
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      value: ''
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue"
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      strokeColor: "{{appsmith.theme.colors.primaryColor}}"
    };
  }

  componentDidMount(): void {
    this.onLoadFn();
  }

  onLoadFn() {
    const { onLoad } = this.props;
    if (onLoad) {
      super.executeAction({
        triggerPropertyName: "onLoad",
        dynamicString: onLoad,
        event: {
          type: EventType.ON_PAGE_LOAD,
        }
      })
    }
  }

  getPageView() {
    let primaryColorTheme = this.getThemeSettingsByKey('colors', 'primaryColor');
    return (
      <>
        <CarouselWidget
          {...this.props}
          value={this.props.value} widgetId={this.props.widgetId}
          key={this.props.widgetId}
          strokeColor={this.props.strokeColor || primaryColorTheme}
        />
      </>
    )
  }

  handleActionResult = (result: ExecutionResult) => { };

  static getWidgetType(): WidgetType {
    return 'PROGRESS_WIDGET';
  }
}

export default Carousel;
