import React from 'react';
import type {MenuProps} from 'antd';
import {Button, Dropdown, Image, Switch, Tooltip} from "antd";
import _ from "lodash";
import {StyledButton} from "../../../widgets/IconButtonWidget/component";
import styled from "styled-components";
import dayjs from "dayjs";
import IconPark from '@icon-park/react/es/all';
import ResizeTable from './ResizeTable';
import tinycolor from "tinycolor2";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../utils/localStorage";

const StyledTable = styled((props: any) => (<ResizeTable {...props} />))`
  .ant-table-body,
  .ant-table-body-inner {
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .ant-table-cell-scrollbar {
    display: none;
  }
  table{
    border: none!important;
  }
  table, th, td,.ant-table-container{
    border: none!important;
  }
  .ant-table-body{
    overflow: auto!important;
  }
  .ant-pagination a {color:${({textColorBody}) => textColorBody||"#40a9ff"}!important;}
  .ant-pagination .ant-pagination-item-active a {color:#40a9ff !important;}
  .ant-pagination .ant-pagination-item-link {color:${({textColorBody}) => textColorBody||"#40a9ff"}!important;}

  ${({boxShadow}) => `
    .ant-table-container{
      box-shadow: ${boxShadow}
    }
  `}
  ${({borderRadius}) => `
    .ant-table-container{
      border-radius: ${borderRadius}!important;
      overflow:hidden;
    }
    .ant-table{
      border-radius: ${borderRadius}!important;
    }
  `}

  ${({variant, borderColor, borderWidth}) => `
    ${variant === "DEFAULT" || !variant ? `
      .ant-table-container{
        border: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
      }
      .ant-table-header .ant-table-cell{
        border-left: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
        border-bottom: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
      }
      .ant-table-header .ant-table-cell:first-child{
        border-left: none!important;
      }
      .ant-table-tbody .ant-table-row .ant-table-cell{
        border-left: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
        border-bottom: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
      }
      .ant-table-tbody .ant-table-row:last-child .ant-table-cell{
        border-bottom: none!important;
      }
      .ant-table-tbody .ant-table-row .ant-table-cell:first-child{
        border-left: none!important;
      }
    ` : ``}
  `}
  ${({variant, borderColor, borderWidth}) => `
    ${variant === "VARIANT3" ? `
      .ant-table-thead .ant-table-cell,.ant-table-tbody .ant-table-row .ant-table-cell{
        border-bottom: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
      }
    ` : ``}
  `}
  th {
    font-size: ${({textSizeHead}) => textSizeHead}!important;
    color:${({textColorHead}) => textColorHead}!important;
    font-weight:${({fontStyleHead}) => fontStyleHead && fontStyleHead.split(',')[0]}!important;
    font-style:${({fontStyleHead}) => fontStyleHead && fontStyleHead.split(',')[1]}!important;
    text-align:${({horizontalAlignmentHead}) => horizontalAlignmentHead}!important;
    vertical-align:${({verticalAlignmentHead}) => verticalAlignmentHead}!important;
    background-color: ${({backgroundHead}) => backgroundHead}!important;
  }
  td {
    font-size: ${({textSizeBody}) => textSizeBody}!important;
    color:${({textColorBody}) => textColorBody}!important;
    font-weight:${({fontStyleBody}) => fontStyleBody && fontStyleBody.split(',')[0]}!important;
    font-style:${({fontStyleBody}) => fontStyleBody && fontStyleBody.split(',')[1]}!important;
    text-align:${({horizontalAlignmentBody}) => horizontalAlignmentBody}!important;
    vertical-align:${({verticalAlignmentBody}) => verticalAlignmentBody}!important;
  }

  tr:hover td{
    background-color:${({backgroundMouseOver}) => backgroundMouseOver}!important;
  }

  ${({isZebraStripe, backgroundBody1, backgroundBody2, backgroundMouseOver}) => `
    ${`
    tr:nth-child(odd) td{
      background-color: ${backgroundBody1};
    }
    tr:nth-child(even) td{
      background-color: ${isZebraStripe ? backgroundBody2 : backgroundBody1};
    }
    `}
  `}

  .tableDataCellCz {
    padding: 0 8px 8px 8px !important;

    &>div {
      margin-top: 8px !important;
    }
  }

  .ant-pagination {
    padding-right: 8px;

    .ant-pagination-item-active a {
       color:${({primaryColorTheme}) => primaryColorTheme} !important;
    }

    .ant-pagination-item-link {
      color:${({primaryColorTheme}) => primaryColorTheme} !important;
    }
  }

  .ant-pagination-item-active {
    border-color:${({primaryColorTheme}) => primaryColorTheme} !important;
  }

  .ant-image,.ant-image-img{
    display: block;
  }
`
const ButtonWrapper = styled.div<{
  borderRadiusBtn: string,
  boxShadowBtn: string,
  btnTextAlign: string,
  backgroundBtn: string,
  btnFontSize: any,
  btnFontColor: any,
  btnFontStyle: any,
}>`
  display: inline-block;
  vertical-align: middle;
  .ant-btn{
    border-radius: ${({borderRadiusBtn}) => borderRadiusBtn}px;
    box-shadow: ${({boxShadowBtn}) => boxShadowBtn};
    color: ${({btnFontColor}) => btnFontColor} !important;
  }
  .ant-btn-default{
    border-color: ${({backgroundBtn}) => backgroundBtn} !important;
    &:hover{
      color: ${({btnFontColor}) => btnFontColor} !important;
      border-color: ${({backgroundBtn}) => backgroundBtn} !important;
      background-color: ${({backgroundBtn}) => backgroundBtn ? tinycolor(backgroundBtn).setAlpha(0.1).toRgbString() : ''};
    }
  }
  .ant-btn-primary{
    background-color: ${({backgroundBtn}) => backgroundBtn}!important;
    border-color: ${({backgroundBtn}) => backgroundBtn};
    &:hover{
      opacity: .8;
    }
  }
  .ant-btn-text{
    border: none!important;
    &:hover{
      color: ${({btnFontColor}) => btnFontColor}!important;
      background-color: ${({backgroundBtn}) => tinycolor(backgroundBtn).setAlpha(0.1).toRgbString()};
    }
  }

  .ant-btn-two-chinese-chars>:not(.anticon) {
    margin-right: 0!important;
    letter-spacing: 0!important;
  }
`;

function ActionMenu(props: any) {
  const items = props.items as MenuProps['items'];
  return (
    <Dropdown menu={{items}}>
      {props.children}
    </Dropdown>
  )
}

function AntdTable(props: any) {
  const {
    onToggleDrag,
    updateWidgetProperty,
    widgetName,
    tableData,
    tableColumns,
    columnWidthMap,
    height,
    isShowNo,
    isActionVisible,
    isActionFixed,
    tableAction,
    isActionCollapse,
    tableActionNo,
    actionCollapseIcon,
    actionCollapseIconColor,
    selectType,
    isPagination,
    isRowSelection,
    pageSizeOptions,
    onCellClick,
    onRowSelection,
    paginationChange,
    textSizeBody,
    textSizeHead,
    textColorHead,
    textColorBody,
    horizontalAlignmentBody,
    horizontalAlignmentHead,
    verticalAlignmentBody,
    verticalAlignmentHead,
    fontStyleHead,
    fontStyleBody,
    backgroundHead,
    isZebraStripe,
    backgroundBody1,
    backgroundBody2,
    backgroundMouseOver,
    currPage,
    pageSize,
    total,
    variant,
    borderRadius,
    borderColor,
    borderWidth,
    boxShadow,
    fixTableColumns,
    compactMode,
    isDirty,
    primaryColorTheme,
  } = props;
  const reCellNode = (columnType: any, text: any, column: any, record: any, idx:number) => {
    if (_.isNil(text)||text==='') {
      return null;
    }
    if (columnType == 'button') {
      return reItems(column, record, 0);
    }else if(columnType=='switch'){
      return <Switch disabled defaultChecked={text} />
    } else if (columnType == 'date') {
      if(_.isString(text)){
        if(text.includes('~')){//日期区间
          let dateArr = text.split('~');
          let date1 = dateArr[0],
            date2 = dateArr[1],
          date1Num = parseInt(date1, 10),
          date2Num = parseInt(date2, 10);
          let isDate = dayjs(date1Num).isValid()&&dayjs(date2Num).isValid();
          if(isDate){
            let date1Str = dayjs(date1Num).format(column.dateFormat || "YYYY-MM-DD");
            let date2Str = dayjs(date2Num).format(column.dateFormat || "YYYY-MM-DD");
            return `${date1Str}~${date2Str}`;
          }else{
            return <>{text}</>;
          }

        }else{
          let textNum:number = parseInt(text, 10);
          let isDate = dayjs(textNum).isValid();
          return <>{isDate ? (dayjs(textNum).format(column.dateFormat || "YYYY-MM-DD")) : text}</>
        }
      }else if(_.isNumber(text)){
        let isDate = dayjs(text).isValid();
        return <>{isDate ? (dayjs(text).format(column.dateFormat || "YYYY-MM-DD")) : text}</>
      }else{
        return <>{text}</>
      }

    } else if (columnType == 'image') {
      let isJsonStr = text.startsWith('[{') && text.endsWith('}]');
      let _textObj: any;
      if (isJsonStr) {
        _textObj = JSON.parse(text)?.[0];
      }
      if(!isJsonStr&&_.isString(text)){
        _textObj = {
          url: `${apiPath}/${getTenant()}/file/${text}`
        }
      }
      return <>{_textObj ? <Image width={100} src={_textObj.url}/> : null}</>
    } else if (columnType == 'video') {
      let isJsonStr = text.startsWith('[{') && text.endsWith('}]');
      let _textObj: any;
      if (isJsonStr) {
        _textObj = JSON.parse(text)?.[0];
      }
      return <>{_textObj ? <video src={_textObj.url} controls={true}/> : null}</>
    } else if (columnType == 'rich') {
      return <div dangerouslySetInnerHTML={{__html: text}}/>
    } else if(_.isBoolean(text)){
      return <>{`${text}`}</>
    } else {
      if(column.columnReg === '$data'||!column.columnReg||column.columnReg == '[]'){
        return <>{text}</>
      }
      if(column.columnReg&&
        column.columnReg !=='$data'&&
        !column.columnReg.startsWith('{')&&
        !column.columnReg.startsWith('[')){
        return <div dangerouslySetInnerHTML={{__html: column.columnReg.replace('$data', text)}} />;
      }
      if(column.columnReg&&column.columnReg.startsWith('[')){
        let columnRegData:any = JSON.parse(column.columnReg);
        if(!columnRegData[idx]){
          return <>{text}</>
        }
        return <div dangerouslySetInnerHTML={{__html: columnRegData[idx].replace('$data', text)}} />;
      }

    }

  }
  //列处理
  let columnsValues = _.sortBy(Object.values(tableColumns), ['index']).filter((i: any) => {
    return i.isCellVisible != false;
  });
  let fixedLeft = _.filter(columnsValues, (i: any) => {
    return i.isFixed && i.sticky == 'left';
  });
  let fixedRight = _.filter(columnsValues, (i: any) => {
    return i.isFixed && i.sticky == 'right';
  });
  let fixedNo = _.filter(columnsValues, (i: any) => {
    return !i.isFixed || !i.sticky;
  });
  const reColumnsFun = (columns: any) => {
    return columns.map((i: any) => {
      let _column: any = {
        ...i,
        title: i.label,
        dataIndex: i.id,
        className: 'tableDataCell',
        width: columnWidthMap?.[i.id] || 150
      };
      if (i.isSort) {
        _column.sorter = (a: any, b: any) => {
          if (a[i.id] && !b[i.id]) {
            return -1;
          }
          if (!a[i.id] && b[i.id]) {
            return 1;
          }
          if (!a[i.id && !b[i.id]]) {
            return 0;
          }
          return a[i.id].localeCompare(b[i.id]);
        }
      }
      if (i.isEllipsis) {
        _column.ellipsis = {
          showTitle: false
        }
      }
      if (i.isFixed && i.sticky) {
        _column.fixed = i.sticky;
      }
      /*样式配置 begin*/
      let _cellStyle: any = {};
      let _divStyle: any = {};
      if (i.textSize) {
        _divStyle.fontSize = `${i.textSize}`;
      }
      if (i.textColor) {
        _divStyle.color = `${i.textColor}`;
      }
      if (i.fontStyle) {
        let [_fontWeight, _fontStyle] = i.fontStyle.split(',')
        _divStyle.fontWeight = _fontWeight;
        if (_fontStyle) {
          _divStyle.fontStyle = _fontStyle;
        }
      }
      if (i.verticalAlignment) {
        _cellStyle.verticalAlign = i.verticalAlignment;
      }
      if (i.horizontalAlignment) {
        _divStyle.textAlign = `${i.horizontalAlignment} !important`;
      }
      if (i.backgroundCell) {
        _cellStyle.backgroundColor = i.backgroundCell;
      }
      /*样式配置 end*/

      return {
        ..._column,
        onCell: () => ({
          style: _cellStyle
        }),
        render: (text: any, record: any, index: any) => {
          let _node = reCellNode(i.columnType, text, i, record, index);
          return (
            <div style={_divStyle} onClick={() => {
              i.onClick && onCellClick(record, i.onClick)
            }}>
              {i.isEllipsis ? (
                <Tooltip placement="topLeft" title={text}>
                  {_node}
                </Tooltip>
              ) : (_node)}

            </div>
          )
        }
      }
    })
  }
  let tableColumnsHandle: any = [
    ...reColumnsFun(fixedLeft),
    ...reColumnsFun(fixedNo),
    ...reColumnsFun(fixedRight)
  ];
  //是否显示序号
  if (isShowNo) {
    tableColumnsHandle.unshift({
      title: fixTableColumns?.['_xh']?.label || '序号',
      label: fixTableColumns?.['_xh']?.label || '序号',
      id: '_xh',
      className: 'tableDataCellXh',
      width: columnWidthMap?.['_xh'] || 80,
      fixed: fixedLeft.length == 0 ? false : 'left',
      render: (text: any, record: any, index: any) => {
        return <>{index + 1}</>
      }
    })
  }
  //是否显示操作列
  const reIsVisible = (data: any, index: number) => {
    if (_.isBoolean(data)) {
      return data;
    } else if (_.isArray(data)) {
      return data[index];
    } else if (_.isString(data) && data.startsWith("{{") && data.endsWith("}}")) {
      const ddd = data.replace(`${widgetName}.tableData`, "xData").replace("{{", "").replace("}}", "");
      const xData = _.cloneDeep(tableData);
      const visibleArr = eval(ddd);
      return visibleArr[index];
    }
    return data;
  }
  const reItems = (i: any, record: any, index: number) => {
    let isVis = reIsVisible(i.isCellVisible, index);
    let isDis = reIsVisible(i.isCellDisable, index);

    let backgroundBtn = i.backgroundBtn;
    let btnFontColor = i.fontColor;
    if (!backgroundBtn) {
      backgroundBtn = primaryColorTheme;
    }
    if (!btnFontColor) {
      btnFontColor = primaryColorTheme;
    }

    if (backgroundBtn == "{{appsmith.theme.colors.primaryColor}}") {
      backgroundBtn = props.primaryColor;
    } else  if (backgroundBtn == "{{appsmith.theme.colors.backgroundColor}}") {
      backgroundBtn = props.backgroundColor;
    }

    return (
      <ButtonWrapper
        borderRadiusBtn={i.borderRadiusBtn}
        backgroundBtn={backgroundBtn}
        boxShadowBtn={i.boxShadowBtn}
        btnTextAlign={i.btnTextAlign}
        btnFontSize={i.fontSize}
        btnFontStyle={i.fontStyle}
        btnFontColor={btnFontColor}
      >
        <Button size={`middle`} className={`x-table-btn`} disabled={isDis}
                onClick={() => {
                  onCellClick(record, i.onClick);
                }}
                type={i.btnType}
                style={{minWidth: '64px', margin: '0 5px 1px 0', display: isVis ? 'inline-block' : 'none'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: `${i.btnTextAlign || 'center'}`}}>
            {
              (i.btnIcon && i.btnIcon != 'none' && i.btnPosition != 'right') && <IconPark type={i.btnIcon}/>
            }
            <div>{i.label}</div>
            {
              (i.btnIcon && i.btnIcon != 'none' && i.btnPosition == 'right') && <IconPark type={i.btnIcon}/>
            }
          </div>
        </Button>
      </ButtonWrapper>
    );
  }
  if (isActionVisible) {
    tableColumnsHandle.push({
      title: fixTableColumns?.['_cz']?.label || '操作',
      label: fixTableColumns?.['_cz']?.label || '操作',
      id: '_cz',
      className: 'tableDataCellCz',
      width: columnWidthMap?.['_cz'] || 200,
      fixed: (fixedRight.length > 0 || isActionFixed) ? 'right' : false,
      render: (text: any, record: any, index: any) => {
        let items = Object.values(_.sortBy(tableAction, 'index'));
        let no = parseInt(tableActionNo, 10);
        if (isActionCollapse && _.isNumber(no) && no < items.length && no > 0) {
          let items1 = items.slice(0, no);
          let items2 = items.slice(no);
          let items1Temp = items1.map((i: any) => {
            return reItems(i, record, index);
          });
          const dropdownItems = items2.filter((i:any)=>{
            return reIsVisible(i.isCellVisible, index);
          }).map((i: any) => {
            return {
              key: `${i.index}`,
              disabled: i.isCellDisable,
              label: (
                <a rel="noopener noreferrer" onClick={()=>{
                  i.onClick&&onCellClick(record, i.onClick);
                }}>
                  {i.label}
                </a>
              )
            }
          });

          let items2Temp = (
            <ActionMenu items={dropdownItems}>
              <StyledButton
                buttonColor={actionCollapseIconColor}
                icon={actionCollapseIcon}
                dimension={36}
              />
            </ActionMenu>
          );
          return (
            <>
              {items1Temp}{items2Temp}
            </>
          )
        } else {
          return items.map((i: any) => {
            return reItems(i, record, index);
          })
        }
      }
    })
  }
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      onRowSelection(selectedRowKeys, selectedRows);
    }
  };

  let _pageSize = pageSize;
  if (!isDirty){
    _pageSize = 10;
    if (pageSizeOptions && pageSizeOptions.split.length > 0) {
      _pageSize = pageSizeOptions.split(',')[0] ;
    }
  }

  return <StyledTable
    onToggleDrag={onToggleDrag}
    updateWidgetProperty={updateWidgetProperty}
    boxShadow={boxShadow}
    borderWidth={borderWidth}
    borderColor={borderColor}
    borderRadius={borderRadius}
    variant={variant}
    bordered={variant === 'DEFAULT' || !variant}
    size={compactMode}
    dataSource={tableData || []}
    columns={tableColumnsHandle}
    rowKey={`id`}
    scroll={{x: 800, y: height - 110}}
    rowSelection={isRowSelection && {
      type: selectType,
      ...rowSelection,
    }}
    primaryColorTheme={primaryColorTheme}
    pagination={isPagination ? {
      current: currPage,
      pageSize: _pageSize,
      total: (tableData && total < tableData.length) ? tableData.length : total,
      showSizeChanger: true,
      pageSizeOptions: pageSizeOptions.split(','),
      onChange: (page: any, pageSize: any) => {
        paginationChange(page, pageSize);
      }
    } : false}
    textSizeBody={textSizeBody}
    textSizeHead={textSizeHead}
    horizontalAlignmentBody={horizontalAlignmentBody}
    horizontalAlignmentHead={horizontalAlignmentHead}
    verticalAlignmentBody={verticalAlignmentBody}
    verticalAlignmentHead={verticalAlignmentHead}
    fontStyleHead={fontStyleHead}
    fontStyleBody={fontStyleBody}
    textColorHead={textColorHead}
    textColorBody={textColorBody}
    backgroundHead={backgroundHead}
    isZebraStripe={isZebraStripe}
    backgroundBody1={backgroundBody1}
    backgroundBody2={backgroundBody2}
    backgroundMouseOver={backgroundMouseOver}
  />
}

export default AntdTable;
