import {  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Popconfirm, message } from 'antd';
import React, { useState } from 'react';
import Api from "@byk/api/Api";

const UploadFile = (props:any) => {
  const {logo, onEmit,onEmitClear} = props;
  const [loading, setLoading] = useState(false);
  const [imageCode, setImageCode] = useState(logo);
  const uploadChange = (ev: any) => {
    let files = ev.target.files;
    if (files[0]) {
      const isLt2M = files[0].size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   message.error(`上传图片大小不能超过 2MB!`);
      //   return false;
      // }
      let name = files[0]['name'];
      let ext = '';
      ext = name.substring(name.lastIndexOf('.'));
      //if(ext === '.png'||ext==='.jpg'||ext==='.jpeg'){
      uploadPic(files[0]);
      // }else{
      //   message.error('只能上传png、jpg、jpeg格式图片！');
      // }
    }
  };
  const clearFile=()=>{
    setImageCode(null);
    onEmitClear();
  }

  const uploadPic = (file:any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e:any) => {
      let base64 = e.target['result'];
      base64 = base64.split('base64,')[1];
      let params = {
        data: base64,
        ext: file.name.substring(file.name.lastIndexOf('.')),
        filename: file.name,
        type: file.type,
      };
      onEmit(params);

    };
  };

  return (
    <input  type="file" onChange={uploadChange} />
  );
};

export default UploadFile;
