import IconSVG from "./icon.svg";
import Widget from "./widget";
import {DynamicHeight} from "../../utils/WidgetFeatures";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "AtInput",
  iconSVG: IconSVG,
  needsMeta: true,
  features: {
    dynamicHeight: {
      sectionIndex: 0,
      defaultValue: DynamicHeight.AUTO_HEIGHT,
      active: true,
    }
  },
  defaults: {
    rows: 3,
    columns: 128,
    widgetName: "AtInput",
    version: 1,
    animateLoading: true,
    label: 'label',
    defaultValue: '',
    value: '',
    placeholder: '请输入',
    inputType: 'text',
    isRequired: false,
    regex: '',
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "280px",
            minHeight: "40px",
          };
        },
      },
    ],
  },
};

export default Widget;
