import React, {useEffect, useRef} from 'react';
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../utils/localStorage";
import Api from "../../../api/Api";

const WebRtcStreamer = (props:any) => {
  const {url} = props;
  const videoRef = useRef(null);
  //let url = 'rtsp://rtspstream:69db2521dca457aad6e2f642a23972f4@zephyr.rtsp.stream/movie';
  let webRtcServer:any = null;
  useEffect(() => {
    const loadScript = async () => {
      try {
        await import('../../../assets/js/adapter.min');
        // eslint-disable-next-line import/extensions
        const module = await import('../../../assets/js/webrtcstreamer');
        const WebRtcStreamer:any = module.default;
        const sysConfigResult:any = await Api.get(`${apiPath}/${getTenant()}/rest/SysConfig/list`);
        const webRtcStreamerConfig = sysConfigResult?.result.filter((i:any)=>{
          return i.name === 'WebRtcStreamerServer';
        });
        let webRtcStreamerServer = 'https://webrtc.k8s.billjc.com';
        if(webRtcStreamerConfig&&webRtcStreamerConfig[0]&&webRtcStreamerConfig[0]['value']){
          webRtcStreamerServer = webRtcStreamerConfig[0]['value'];
        }
        webRtcServer = new WebRtcStreamer(videoRef.current, webRtcStreamerServer);
        //webRtcServer = new WebRtcStreamer(videoRef.current, location.protocol + '//127.0.0.1:8000')
        webRtcServer.connect(url);

      } catch (error) {
        console.error('Script failed to load', error);
      }
    };

    loadScript();
    return ()=>{
      webRtcServer&&webRtcServer.disconnect()
      webRtcServer = null
    }
  }, []);

  return (
    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <video controls style={{maxHeight: '100%', maxWidth: '100%'}} ref={videoRef} id="video" autoPlay width="500" height="300" />
    </div>
  );
};

export default WebRtcStreamer;
