import {Alignment} from "@blueprintjs/core";
import {LabelPosition} from "components/constants";
import {FILL_WIDGET_MIN_WIDTH} from "constants/minWidthConstants";
import {ResponsiveBehavior} from "utils/autoLayout/constants";

import {TimePrecision, YYYY_MM_DD} from "./constants";
import IconSVG from "./icon.svg";
import Widget from "./widget";
import {DynamicHeight} from "../../utils/WidgetFeatures";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "DatePicker",
  iconSVG: IconSVG,
  needsMeta: true,
  searchTags: ["calendar"],
  features: {
    dynamicHeight: {
      sectionIndex: 0,
      defaultValue: DynamicHeight.AUTO_HEIGHT,
      active: true,
    }
  },
  defaults: {
    isDisabled: false,
    isReadonly: false,
    datePickerType: "DATE_PICKER",
    rows: 3,
    label: "Label",
    labelPosition: LabelPosition.Left,
    labelAlignment: Alignment.RIGHT,
    placeholderText: "请选择日期",
    labelWidth: 8,
    dateFormat: YYYY_MM_DD,
    columns: 28,
    widgetName: "DatePicker",
    defaultDate: "",
    minDate: "1920-12-31",
    maxDate: "2121-12-31",
    version: 2,
    isRequired: false,
    closeOnSelection: true,
    shortcuts: false,
    firstDayOfWeek: 0,
    timePrecision: TimePrecision.NONE,
    animateLoading: true,
    responsiveBehavior: ResponsiveBehavior.Fill,
    minWidth: FILL_WIDGET_MIN_WIDTH,
    checkIsValid:undefined, // 记录验证是否通过
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    disabledPropsDefaults: {
      labelPosition: LabelPosition.Left,
      labelTextSize: "0.875rem",
    },
    autoDimension: {
      height: true,
    },
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "120px",
          };
        },
      },
    ],
    disableResizeHandles: {
      vertical: true,
    },
  },
};

export default Widget;
