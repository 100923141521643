import React, { useRef } from "react";
import styled from "styled-components";
import LabelWithTooltip, {
  labelLayoutStyles, LABEL_CONTAINER_CLASS
} from "widgets/components/LabelWithTooltip";
import { LabelPosition } from "components/constants";
import _ from "lodash";
import { uuid2 } from "@byk/utils/Utils";
import EditableTable from "@byk/pages/components/EditableTableWidget";

interface ContainerProps {
  compactMode: boolean;
  labelPosition?: LabelPosition;
  textColorBody?: string,
  textSizeHead?: string,
  textColorHead?: string,
  backgroundHead?: string,
  backgroundBody1?: string,
  backgroundBody2?: string,
  backgroundMouseOver?: string,
  horizontalAlignmentHead?: string,
  verticalAlignmentHead?: string,
  horizontalAlignmentBody?: string,
  verticalAlignmentBody?: string,
  fontStyleHead?: string,
  fontStyleBody?: string,
  isZebraStripe?: boolean,
  boxShadow?: string,
  borderRadius?: string,
  variant?: string,
  borderColor?: string,
  borderWidth?: string,
  textSizeBody?: string,
  accentColor:string,
}

const DivContainer = styled.div<ContainerProps>`
  ${labelLayoutStyles}

  & .${LABEL_CONTAINER_CLASS} {
    align-self: center;
    ${({ labelPosition }) =>
    labelPosition === LabelPosition.Left && "min-height: 30px"};
  }

  .ant-table-body,
  .ant-table-body-inner {
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .ant-table-cell-scrollbar {
    display: none;
  }
  table{
    border: none!important;
  }
  table, th, td,.ant-table-container{
    border: none!important;
  }
  .ant-table-body{
    overflow: auto!important;
  }
  .ant-pagination a {color:${({ textColorBody }) => textColorBody || "#40a9ff"}!important;}
  .ant-pagination .ant-pagination-item-active a {color:#40a9ff !important;}
  .ant-pagination .ant-pagination-item-link {color:${({ textColorBody }) => textColorBody || "#40a9ff"}!important;}

  th {
    font-size: ${({ textSizeHead }) => textSizeHead}!important;
    color:${({ textColorHead }) => textColorHead}!important;
    font-weight:${({ fontStyleHead }) => fontStyleHead && fontStyleHead.split(',')[0]}!important;
    font-style:${({ fontStyleHead }) => fontStyleHead && fontStyleHead.split(',')[1]}!important;
    text-align:${({ horizontalAlignmentHead }) => horizontalAlignmentHead}!important;
    background-color: ${({ backgroundHead }) => backgroundHead}!important;
  }

  tbody td {
    font-size: ${({ textSizeBody }) => textSizeBody}!important;
    color:${({ textColorBody }) => textColorBody}!important;
    font-weight:${({ fontStyleBody }) => fontStyleBody && fontStyleBody.split(',')[0]}!important;
    font-style:${({ fontStyleBody }) => fontStyleBody && fontStyleBody.split(',')[1]}!important;
    text-align:${({ horizontalAlignmentBody }) => horizontalAlignmentBody}!important;
  }

  ${({ boxShadow }) => `
    .ant-table-container{
      box-shadow: ${boxShadow}
    }
  `}
  ${({ borderRadius }) => `
    .ant-table-container{
      border-radius: ${borderRadius}!important;
      overflow:hidden;
    }
    .ant-table{
      border-radius: ${borderRadius}!important;
    }
  `}

  .ant-table-placeholder .ant-table-cell {
     border: none!important;
     background-color: white !important;
  }
  ${({ variant, borderColor, borderWidth }) => `
    ${variant === "DEFAULT" || !variant ? `
      .ant-table-container {
        border: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
      }

      .ant-table-cell {
        border-left: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
        border-bottom: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
      }

      .ant-table-cell:first-child{
        border-left: none!important;
      }

      .ant-table-row:last-child .ant-table-cell{
        border-bottom: none!important;
      }
    ` : ``}
  `}

  ${({ variant, borderColor, borderWidth }) => `
    ${variant === "VARIANT3" ? `
      .ant-table-thead .ant-table-cell,.ant-table-tbody .ant-table-row .ant-table-cell{
        border-bottom: ${borderWidth ?? 1}px solid ${borderColor || '#f0f0f0'}!important;
      }
    ` : ``}
  `}

  tr:hover td{
    background-color:${({ backgroundMouseOver }) => backgroundMouseOver}!important;
  }

  ${({ isZebraStripe, backgroundBody1, backgroundBody2, backgroundMouseOver }) => `
    ${`
    tr:nth-child(odd) td{
      background-color: ${backgroundBody1};
    }
    tr:nth-child(even) td{
      background-color: ${isZebraStripe ? backgroundBody2 : backgroundBody1};
    }
    `}
  `}

  .tableDataCellCz {
    padding: 0 8px 8px 8px !important;

    &>div {
      margin-top: 8px !important;
    }
  }

   .ant-pagination {
    padding-right: 8px;

    .ant-pagination-item-active a {
       color:${({accentColor}) => accentColor} !important;
    }

    .ant-pagination-item-link {
      color:${({accentColor}) => accentColor} !important;
    }
  }

  .ant-pagination-item-active {
    border-color:${({accentColor}) => accentColor} !important;
  }
`;

const CarouselComponent = (props: any) => {
  const { wrapperRef,
    compactMode,
    isDisabled,
    isLoading,
    isRequired,//
    labelAlignment,//11
    labelPosition,//11
    labelStyle,//11
    labelText,//11
    labelTextColor,//11
    labelTextSize,//11
    labelTooltip,//11
    labelWidth,//11
    onChange,
    label,
    defaultValue,
    vertical,
    onValueChange,
    value,
    tableColumns,
    readonly,
    selectId,
    onOptionChange,
    tableSize,
    textSizeHead,
    textColorHead,
    fontStyleHead,
    horizontalAlignmentHead,
    backgroundHead,
    textSizeBody,
    textColorBody,
    fontStyleBody,
    horizontalAlignmentBody,
    borderRadius,
    boxShadow,
    variant,
    borderColor,
    borderWidth,
    backgroundMouseOver,
    isZebraStripe,
    backgroundBody1,
    backgroundBody2,
    newBtnType,
    newBtnColor,
    newBtnFontStyle,
    newBtnTextSize,
    newBtnBgColor,
    newBtnBorderRadius,
    newBtnBoxShadow,
    opBtnType,
    opBtnColor,
    opBtnBgColor,
    opBtnFontStyle,
    opBtnTextSize,
    opBtnBorderRadius,
    opBtnBoxShadow,
    widgetName,
    accentColor,
    //LABEL END
  } = props;
  //列处理
  const tableColumnsSort = _.sortBy(Object.values(props.tableColumns), ['index']);
  const onValChange = (value: any) => {
    onValueChange(value)
  };
  const onSelectOptionChange = (value: any) => {
    onOptionChange(value)
  };

  const dataCache = useRef([]);
  const dataCacheUUID = useRef([]);

  const getCols = (_tableColumns: any) => {
    let _cols: any = [];
    if (_tableColumns) {
      let keys = Object.keys(_tableColumns);
      for (let i = 0; i < keys.length; i++) {
        let _key: any = keys[i];
        let _val: any = { ..._tableColumns[keys[i]] };
        _val.title = _val.label;
        _val.dataIndex = _val.id;
        if (_val.columnType == "select") {
          _val.dataIndex = _val.id + ".value";
          _val.id = _val.id + ".value";
        }
        _val.required = _val.isCellRequired;
        //_val.editable = _val.isEdit;
        _val.onOptionChange = _val.onOptionChange || undefined;
        _val.inputType = _val.columnType;
        _val.editable = true;
        _cols.push(_val);
        _val.dataIndex = `${widgetName}.${_val.dataIndex}`;
        _val.id = `${widgetName}.${_val.id}`;
      }
    }
    console.log("_cols", _cols);
    return _cols;
  }

  const editRef = useRef<any>();

  const getDataSource = (data: any) => {
    if (_.isEqual(dataCache.current, data)) {
      return dataCacheUUID.current;
    }
    let _data = data || [];
    let _dataNew: any = [];
    _.each(_data, (i: any) => {
      let m: any = { ...i };
      if (!m.hasOwnProperty("key")) {
        if (m.hasOwnProperty("id")) {
          m["key"] = m.id;
          m["_hasId"] = true;
        }
        else {
          let _uid = uuid2();
          m["id"] = _uid;
          m["key"] = _uid;
          m["_hasId"] = false;
        }
      }
      if (!m.hasOwnProperty("_subTable_action")) {
        m["_subTable_action"] = "update";
      }
      _dataNew.push(m);
    })
    dataCache.current = data;
    dataCacheUUID.current = _dataNew;
    return _dataNew;
  }

  return (
    <>
      <DivContainer compactMode={compactMode}
        data-testid="radiogroup-container"
        labelPosition={labelPosition}
        textSizeHead={textSizeHead}
        textColorHead={textColorHead}
        fontStyleHead={fontStyleHead}
        horizontalAlignmentHead={horizontalAlignmentHead}
        backgroundHead={backgroundHead}
        textSizeBody={textSizeBody}
        textColorBody={textColorBody}
        fontStyleBody={fontStyleBody}
        horizontalAlignmentBody={horizontalAlignmentBody}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        variant={variant}
        borderColor={borderColor}
        borderWidth={borderWidth}
        backgroundMouseOver={backgroundMouseOver}
        isZebraStripe={isZebraStripe}
        backgroundBody1={backgroundBody1}
        backgroundBody2={backgroundBody2}
        accentColor={accentColor}
      >
        {label && (
          <LabelWithTooltip
            className={`datepicker-label`}
            text={label}
            cyHelpTextClassName="datepicker-tooltip"
            alignment={labelAlignment}
            color={labelTextColor}
            compact={compactMode}
            disabled={isDisabled}
            fontSize={labelTextSize}
            fontStyle={labelStyle}
            helpText={labelTooltip}
            //isDynamicHeightEnabled={this.props.isDynamicHeightEnabled}
            loading={isLoading}
            position={labelPosition}
            width={labelWidth}
            isRequired={isRequired}
          />
        )}
        <div style={{ width: labelPosition=="Left"?'80%':'100%',flex: 1 }}>
          <EditableTable
            ref={editRef} isDisabled={isDisabled} selectId={selectId}
            onOptionChange={onSelectOptionChange} size={tableSize}
            newBtnType={newBtnType}
            newBtnColor={accentColor}
            newBtnFontStyle={newBtnFontStyle}
            newBtnTextSize={newBtnTextSize}
            newBtnBgColor={newBtnBgColor}
            newBtnBorderRadius={newBtnBorderRadius}
            newBtnBoxShadow={newBtnBoxShadow}
            opBtnType={opBtnType}
            opBtnColor={accentColor}
            opBtnBgColor={opBtnBgColor}
            opBtnFontStyle={opBtnFontStyle}
            opBtnTextSize={opBtnTextSize}
            opBtnBorderRadius={opBtnBorderRadius}
            opBtnBoxShadow={opBtnBoxShadow}
            tableColumns={tableColumns}
            cols={getCols(tableColumnsSort)} onValChange={onValChange} data={getDataSource(value)} />
        </div>
      </DivContainer>
    </>
  )
}

class ViewComponent extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return <CarouselComponent {...this.props} />;
  }
}
export default ViewComponent;
