//表格配置
import {ColumnsType} from "antd/lib/table";
import {Button, Space, Tooltip} from "antd";
import React from "react";
import {QUERY_TASK_MSG_URL} from "@byk/pages/InterFaceLog/api";
import moment from "moment";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";

/**
 * Table配置项
 * @param deleteCallback
 */
export function tableProps(showMsg: any) {
  const columns: ColumnsType<any> = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => `${index + 1}`,
      width: 100,
    },
    {
      title: "traceId",
      dataIndex: "traceId",
      key: "traceId",
      width: 100,
    },
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (_, record: any) => (
        <Space size="middle">
          <Tooltip title={record.url}>
            <span style={{wordBreak:"break-word"}}>
              {record.url}
            </span>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'IP',
      dataIndex: 'serverIp',
    },
    {
      title: "用时",
      dataIndex: "useTime",
      key: "useTime",
      width: 100,
    },
 
    
    {
      title: '操作人',
      dataIndex: 'creator',
    },
    {
      title: "操作时间",
      dataIndex: "createDate",
      key: "createDate",
      render: (_, record: any) => (
        moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
      ),
      width: 160,
    },
    {
      title: '操作',
      dataIndex: 'x',
      render: (_, record) => (
        <Space size="middle">
          {record.message &&
          <Button type="link" onClick={() => showMsg(record)}>
            查看消息
          </Button>
          }
        </Space>
      ),
    },
  ];

  const tableProps = {
    columns: columns,
    api: QUERY_TASK_MSG_URL,
    pagination: {
      currPage: 1,
      pageSize: 10
    },
    initParams: {
      tenant: ModelingApi.getTenant()
    }
  }

  return tableProps
}

/**
 * 搜索配置项
 * @param refreshTable
 */
export function searchProps(refreshTable: any) {
  const searchConfig: any[] = [
    {
      itemType: "input",
      placeholder: "请输入",
      name: "traceId",
      label: "TraceId",
      allowClear: true,
      col: 4,
      labelCol:8
    },
    {
      name: 'name',
      label: '名称',
      itemType: 'input',
      placeholder: '',
      allowClear: true,
      col: 4
    },
    {
      name: 'url',
      label: 'url',
      itemType: 'input',
      placeholder: '',
      allowClear: true,
      col: 4
    },


  ]

  const searchProps = {
    option: searchConfig,
    onSearch: refreshTable,
    btnBoolean: true
  }

  return searchProps
}


