import {PROPERTY_REL_TYPE, RelAttrType, SysDefaultProperty} from "@byk/pages/modeling/constants/Constants";
import _ from "lodash";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";

/**
 * 根据Key从属性列表，查询属性
 * @param properties
 * @param key
 */
export function getPropertyByKey(properties: any, key: any) {
  let ret;
  if (properties && key) {
    properties.filter(function (item: any) {
      if (item.key === key) {
        ret = item;
      }
    });
  }
  return ret;
}

export function getPropertyById(model: any, id: any) {
  let ret;
  if (model && model.properties && id) {
    model.properties.filter(function (item: any) {
      if (item.id === id) {
        ret = item;
      }
    });
  }
  return ret;
}


/**
 * 根据Key从属性列表，查询属性
 * @param properties
 * @param key
 */
export function getPropertyByName(properties: any, name: any) {
  let ret: any;
  if (properties && name) {
    properties.filter(function (item: any) {
      if (item.name === name) {
        ret = item;
      }
    });
  }
  return ret;
}

/**
 * 更新ModelData对应的property
 */
export function updatePropertyToModelData(modelData: any, property: any) {
  let ret = modelData;
  if (ret && ret.properties && property) {
    let properties = [];
    for (let idx in modelData.properties) {
      let item = modelData.properties[idx];
      if ((item.key && property.key && item.key === property.key)
        || (item.id && property.id && item.id == property.id)) {
        properties.push({
          ...item,
          ...property,
        });
      } else {
        properties.push(item);
      }
    }
    ret.properties = properties;
  }

  return ret;
}

export function isPk(property: any) {
  return property.primaryKey;
}

export function isIndexType(property: any) {
  let ret = true;
  let noIndex = ['richtext', 'blob', 'o2m', 'm2o', 'm2m', 'related'];
  if (noIndex.indexOf(property.type) >= 0) {
    return false;
  }

  return ret;
}

/**
 * 检查属性是否可编辑
 * @param propertyName
 */
export function propertyUnEditable(property: any) {
  let isPk = property && property.primaryKey;
  return isPk
}

export function isSystemDefaultProperty(property: any) {
  let pn = property && property.name || '';
  return SysDefaultProperty.indexOf(pn.toLocaleString()) >= 0;
}

/**
 * 是关系类型属性： o2o, o2m, m2m, m2o
 * @param property
 */
export function isRelProperty(type: any) {
  return type == PROPERTY_REL_TYPE.O2M || type == PROPERTY_REL_TYPE.M2O || type == PROPERTY_REL_TYPE.O2O || type == PROPERTY_REL_TYPE.M2M;
}

export function isRelPropertyCanEdit(pro: any) {
  const editProperties = ['name', 'description', 'type', 'targetModel'];
  return editProperties.indexOf(pro) > -1;
}

/**
 * 检查是否有系统默认属性
 * @param properties
 */
export function hasSysDefaultProperty(properties: any) {
  let hasSysPro = false;
  if (properties && properties.length) {
    for (let idx in properties) {
      let property: any = properties[idx];
      if (!hasSysPro && property.name && SysDefaultProperty.indexOf(property.name.toLocaleString()) >= 0) {
        hasSysPro = true;
      }
    }
  }
  return hasSysPro;
}


const REQUIRED_PROPERTY = ["name", "description", "type"];

export function isModelPropertyRequired(name: any) {
  return REQUIRED_PROPERTY.indexOf((name || '').toLocaleString()) >= 0;
}

export const MODEL_NAME_PATTERN = /^[A-Z][A-Za-z0-9]{0,}$/;
export const MODEL_NAME_PATTERN_MSG = '字母,数字组合以大写字母开头!'

export const MODEL_PROPERTY_NAME_PATTERN = /^[a-z][A-Za-z0-9]{0,}$/;
export const MODEL_PROPERTY_NAME_PATTERN_MSG = '字母,数字组合以小写字母开头!'
export const MODEL_DESCRIPTION_PATTERN = /^(?![0-9]+$)[a-zA-Z_\u4e00-\u9fa5][a-zA-Z0-9_\u4e00-\u9fa5]{0,}$/;
export const MODEL_DESCRIPTION_PATTERN_MSG = '中文,字母,数字和下划线组合且不能以数字开头!!'

export const MODEL_FORM_RULES: any = {
  "name": [
    {
      pattern: MODEL_PROPERTY_NAME_PATTERN,
      message: MODEL_PROPERTY_NAME_PATTERN_MSG
    },
    {
      min: 0,
      max: 30,
      message: '属性编码不能超过30个字符!',
    }
  ],
  "description": [
    {
      pattern: MODEL_DESCRIPTION_PATTERN,
      message: MODEL_DESCRIPTION_PATTERN_MSG,
    },
    {
      max: 60,
      message: '属性名称不能超过60个字符!',
    }
  ],
};

const PROPERTY_DEFAULT_LENGTH: any = {
  "string": 255,
  "mail": 255,
  "textarea": 500,
  "file": 255,
  "image": 500,
  "decimal": 19,
  "integer": 11,
  "bigint": 20,
  "dict": 255,
  "dictName": 255,
}

export function getPropertyDefaultLength(type: any) {
  return PROPERTY_DEFAULT_LENGTH[type || ''] || null;
}

export function getModelPk(properties: any) {
  let pk: any = null;
  _.forEach(properties || [], (item) => {
    if (!pk && item.primaryKey) {
      pk = item;
    }
  });

  return pk;
}

export function downloadModelData(modelName: any, excelName: any, condition: any = {}, fields: any = [], isTemplate: boolean = false) {
  let url: any = ModelingApi.getExportExcelUrl(modelName, excelName, fields.join(","));
  Object.keys(condition).forEach((key) => url+= '&' + key + '=' + condition[key]);
  const link = document.createElement('a');
  if (isTemplate) {
    url += '&id=-9999';
  }
  link.href = url;
  link.target = "blank"
  link.download = excelName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadModelExcelTemplate(modelName: any, excelName: any) {
  downloadModelData(modelName, excelName, null,null, true)
}

/**
 * 没有默认值类型
 * @param type
 */
export function isNoDefVal(type:any = '') {
  return ['textarea', 'richtext', 'o2m', 'm2o', 'related', 'blob'].indexOf(type) > -1;
}

/**
 * 支持DB 字段类型
 * @param type
 */
export function isDbColumnType(type:any = '') {
  return ['string', 'textarea', 'richtext', 'integer', 'bigint', 'decimal', 'mail', 'bool', 'datetime', 'dict', 'file', 'image', 'blob', 'm2o'].indexOf(type) > -1;
}

/**
 * 日期类型
 * @param type
 */
export function isDateType(type: any) {
  return ['date', 'datetime'].indexOf(type) > -1
}

/**
 * 文件类型
 * @param type
 */
export function isUploadFileType(type: any) {
  return ['file', 'image'].indexOf(type) > -1
}

/**
 * blob文件类型
 * @param type
 */
export function isBlobType(type: any) {
  return ['blob'].indexOf(type) > -1
}
