import panelConfig from "./PanelConfig";
import {ValidationTypes} from "../../../../constants/WidgetValidation";
import {PropertyPaneConfig} from "../../../../constants/PropertyControlConstants";
import {SelectType} from "../../../../components/constants";

export default [
  {
    sectionName: "一般",
    children: [
      {
        helpText: "",
        propertyName: "label",
        label: "标签",
        controlType: "INPUT_TEXT",
        placeholderText: 'name',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "isVisible",
        label: "是否可见",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "isShowNo",
        label: "是否显示序号",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      }
    ]
  },
  {
    sectionName: "表格列",
    children: [
      {
        //helpText: "Bind data from an API using {{}}",
        propertyName: "tableColumns",
        label: "",
        controlType: "TABLE_COLUMNS",
        isBindProperty: true,
        isTriggerProperty: false,
        dependencies: ['tableData'],
        panelConfig: panelConfig as any
      }
    ]
  },
  {
    sectionName: "数据",
    children: [
      {
        helpText: "此处编辑的数据为静态数据，若想绑定动态数据，可前往事件面板配置onLoad事件以加载动态数据",
        propertyName: "tableData",
        label: "表格数据",
        controlType: "TABLE_DATA",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.ARRAY },
        dependencies: ['tableColumns'],
      }
    ]
  },
  {
    sectionName: "操作列",
    children: [
      {
        propertyName: "isActionVisible",
        label: "启用操作列",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        helpText: "Bind data from an API using {{}}",
        propertyName: "tableAction",
        label: "列",
        controlType: "TABLE_COLUMNS",
        isBindProperty: true,
        isTriggerProperty: false,
        panelConfig: panelConfig as any,
        hidden: (props: any) => {
          return !props.isActionVisible
        },
        dependencies: ["isActionVisible"]
      },
      {
        propertyName: "isActionCollapse",
        label: "是否折叠按钮",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
        hidden: (props: any) => {
          return !props.isActionVisible
        },
        dependencies: ["isActionVisible"]
      },
      {
        helpText: "超出数量的按钮通过点击折叠图标展示",
        propertyName: "tableActionNo",
        label: "按钮可见数",
        controlType: "INPUT_TEXT",
        placeholderText: '3',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
          params: {
            natural: true,
          }
        },
        hidden: (props: any) => {
          return !props.isActionCollapse||!props.isActionVisible;
        },
        dependencies: ["isActionCollapse", "isActionVisible"]
      },
      {
        propertyName: "actionCollapseIconColor",
        label: "图标颜色",
        helpText: "Changes the background color of the cell",
        controlType: "PRIMARY_COLUMNS_COLOR_PICKER_V2",
        isJSConvertible: true,
        isBindProperty: true,
        validation: {
          type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
          params: {
            type: ValidationTypes.TEXT,
            params: {
              regex: /^(?![<|{{]).+/,
            },
          },
        },
        isTriggerProperty: false,
        hidden: (props: any) => {
          return !props.isActionCollapse||!props.isActionVisible;
        },
        dependencies: ["isActionCollapse", "isActionVisible"]
      },
      {
        propertyName: "isActionFixed",
        label: "是否冻结",
        helpText: "isFixed",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.BOOLEAN},
        hidden: (props: any) => {
          return !props.isActionVisible;
        },
        dependencies: ["isActionVisible"]
      }
    ]
  },
  {
    sectionName: "行选择器",
    children: [
      {
        propertyName: "isRowSelection",
        label: "启用行选择",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        helpText: "Sets the label position of the widget",
        propertyName: "selectType",
        label: "选择类型",
        controlType: "ICON_TABS",
        fullWidth: false,
        options: [
          { label: "多选", value: SelectType.Multiple },
          { label: "单选", value: SelectType.Radio }
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ]
  },
  {
    sectionName: "分页",
    children: [
      {
        propertyName: "isPagination",
        label: "启用分页",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        helpText: "",
        propertyName: "pageSizeOptions",
        label: "每页多少条数据",
        controlType: "INPUT_TEXT",
        placeholderText: '10,20,50',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      }
    ]
  }
] as PropertyPaneConfig[];
