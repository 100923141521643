import React from "react";
import EchartsWidget from '../component';
import BaseWidget, { WidgetState } from "../../../widgets/BaseWidget";
import { WidgetType } from "../../../constants/WidgetConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { EvaluationSubstitutionType } from "../../../entities/DataTree/types";
import { PropertyPaneConfig } from "../../../constants/PropertyControlConstants";
import { Stylesheet } from "../../../entities/AppTheming";
import type { WidgetProperties } from "selectors/propertyPaneSelectors";
class XEchartsFunnel extends BaseWidget<any, WidgetState> {
  constructor(props: any) {
    super(props);
    this.updateWidgetProperty = this.updateWidgetProperty.bind(this);
  }
  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return super.getWidgetEvents('EchartsFunnelWidget');
  }
  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: '基础',
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          // {
          //   propertyName: "title",
          //   label: "标题",
          //   helpText: "设置该组件的标签",
          //   controlType: "INPUT_TEXT",
          //   placeholderText: "",
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.TEXT },
          // },
          
        ]
      },
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "isVisible",
            label: "是否可见",
            helpText: "Controls the visibility of the tab",
            controlType: "SWITCH",
            useValidationMessage: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isShowTab",
            label: "是否显示数据标签",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isLegend",
            label: "是否显示图例",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isDownload",
            label: "是否开启下载",
            controlType: "SWITCH",
            isJSConvertible: true,
            defaultValue: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isDataView",
            label: "是否开启数据视图",
            controlType: "SWITCH",
            isJSConvertible: true,
            defaultValue: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: '数据项',
        children: [
          {
            propertyName: "tabsObj",
            isJSConvertible: false,
            label: "",
            helpText: "",
            controlType: "TABS_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
            prefix: 'Data',
            updateRelatedWidgetProperties: (
              propertyPath: string,
              propertyValue: string,
              props: WidgetProperties,
            ) => {
              const propertyPathSplit = propertyPath.split(".");
              const property = propertyPathSplit.pop();
              if (property === "label") {
                const itemId = propertyPathSplit.pop() || "";
                const item = props.tabsObj[itemId];
                if (item) {
                  return [
                    {
                      widgetId: item.widgetId,
                      updates: {
                        modify: {
                          tabName: propertyValue,
                        },
                      },
                    },
                  ];
                }
              }
              return [];
            },
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              contentChildren:  [
                {
                  sectionName: "一般",
                  children: [
                    {
                      propertyName: "isVisible",
                      label: "是否可见",
                      helpText: "Controls the visibility of the tab",
                      controlType: "SWITCH",
                      useValidationMessage: true,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {type: ValidationTypes.BOOLEAN},
                    },
                  ],
                },
              ],
              styleChildren:  [
                {
                  sectionName: "颜色",
                  children: [
                    {
                      propertyName: "color",
                      label: "颜色",
                      helpText: "Control the color of the label associated",
                      controlType: "COLOR_PICKER",
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: { type: ValidationTypes.TEXT },
                    },
                  ],
                },  
              ],
            },
          },
        ]
      },
      {
        sectionName: '数据',
        children: [
          {
            helpText: "Bind data from an API using {{}}",
            propertyName: "currentData",
            label: "Data",
            controlType: "INPUT_TEXT",
            placeholderText: '[{ "name": "John" }]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.OBJECT_ARRAY,
              params: {
                default: [],
              },
            },
            evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
          }
        ]
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      color: "{{appsmith.theme.colors.primaryColor}}",
      fontColor: "{{appsmith.theme.colors.textColor}}",
      fontFamily: "{{appsmith.theme.fontFamily.appFont}}",
    };
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "fontFamily",
            label: "字体",
            helpText: "Controls the font family being used",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "System Default",
                value: "System Default",
              },
              {
                label: "Nunito Sans",
                value: "Nunito Sans",
              },
              {
                label: "Poppins",
                value: "Poppins",
              },
              {
                label: "Inter",
                value: "Inter",
              },
              {
                label: "Montserrat",
                value: "Montserrat",
              },
              {
                label: "Noto Sans",
                value: "Noto Sans",
              },
              {
                label: "Open Sans",
                value: "Open Sans",
              },
              {
                label: "Roboto",
                value: "Roboto",
              },
              {
                label: "Rubik",
                value: "Rubik",
              },
              {
                label: "Ubuntu",
                value: "Ubuntu",
              },
            ],
            defaultValue: "System Default",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "fontStyle",
            label: "加粗&斜体",
            helpText: "Controls the font emphasis of the text displayed",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "bold",
              },
              {
                icon: "text-italic",
                value: "italic",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "颜色",
        children: [
          {
            propertyName: "fontColor",
            label: "文字颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "borderColor",
            label: "边框颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "backgroundColor",
            label: "背景颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "边框&阴影",
        children: [
          {

            propertyName: "borderWidth",
            label: "边框宽度",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            defaultValue: 1,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText: "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }
  updateWidgetProperty(propertyName: string, propertyValue: any): void {
  }

  getPageView() {
    console.log('this.props>>', this.props);

    return (
      <>
        <EchartsWidget {...this.props} updateWidgetProperty={this.updateWidgetProperty} />
      </>
    )
  }
  static getWidgetType(): WidgetType {
    return 'X_ECHARTS_FUNNEL_WIDGET';
  }
}

export default XEchartsFunnel;
