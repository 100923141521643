import { THEMEING_TEXT_SIZES } from "constants/ThemeConstants";
import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Tree",
  iconSVG: IconSVG,
  features: {
    dynamicHeight: {
      sectionIndex: 1,
      active: true,
    },
  },
  defaults: {
    rows: 16,
    columns: 16,
    widgetName: "Tree",
    version: 1,
    animateLoading: true,
    isChecked: false,
    fieldNames:"{ title: 'label', key: 'key', children: 'children' }",
    defaultExpandAll:true,
    oneLineHeight:25,
    twoLineHeight:25,
    blockNode:true,
    oneFontSize:THEMEING_TEXT_SIZES.base,
    twoFontSize:THEMEING_TEXT_SIZES.sm,
    // currentData: [
    //   {
    //     key: "1",
    //     value: 'zhejiang',
    //     label: '浙江',
    //     children: [
    //       {
    //         key: "1-1",
    //         value: 'hangzhou',
    //         label: '杭州1',
    //       },
    //       {
    //         key: "1-2",
    //         value: 'hangzhou',
    //         label: '杭州2',
    //       },
    //       {
    //         key: "1-3",
    //         value: 'hangzhou',
    //         label: '杭州3',
    //       },
    //       {
    //         key: "1-4",
    //         value: 'hangzhou',
    //         label: '杭州4',
    //       },
    //     ],
    //   },
    //   {
    //     key: "2",
    //     value: 'jiangsu',
    //     label: '江苏',
    //     children: [
    //       {
    //         key: "2-1",
    //         value: 'nanjing',
    //         label: '南京1',
    //       },
    //       {
    //         key: "2-2",
    //         value: 'nanjing',
    //         label: '南京2',
    //       },
    //       {
    //         key: "2-3",
    //         value: 'nanjing',
    //         label: '南京3',
    //       },
    //     ],
    //   },
    // ],
    currentData: [
      {
        key: "1",
        value: 'zhejiang',
        label: '浙江',
        children: [
          {
            key: "1-2",
            value: 'hangzhou',
            label: '杭州',
            children: [
              {
                key: "1-2-1",
                value: 'xihu',
                label: '西湖',
              },
              {
                key: "1-2-2",
                value: 'shangcheng',
                label: '上城区',
              },
              {
                key: "1-2-3",
                value: 'xiacheng',
                label: '下城区',
              },
            ],
          },
        ],
      },
      {
        key: "2",
        value: 'jiangsu',
        label: '江苏',
        children: [
          {
            key: "2-1",
            value: 'nanjing',
            label: '南京',
            children: [
              {
                key: "2-1-1",
                value: 'zhonghuamen',
                label: '中华门',
              },
            ],
          },
        ],
      },
    ]
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "320px",
            minHeight: "80px",
          };
        },
      },
    ],
  },
};

export default Widget;
