import React from "react";
import styled from "styled-components";
import type { Alignment } from "@blueprintjs/core";
import { BlueprintRadioSwitchGroupTransform } from "constants/DefaultTheme";
import { LabelPosition } from "components/constants";
import type { TextSize } from "constants/WidgetConstants";
import { StyledSwitch } from "widgets/SwitchWidget/component";
import LabelWithTooltip, {
  labelLayoutStyles,
  LABEL_CONTAINER_CLASS,
} from "widgets/components/LabelWithTooltip";
import type { ThemeProp } from "widgets/constants";
import {FontStyleTypes} from "constants/WidgetConstants";

export interface SwitchGroupContainerProps {
  compactMode: boolean;
  labelPosition?: LabelPosition;
  it_color?: string;
  it_size?: string;
  it_style?: string;
}

export const SwitchGroupContainer = styled.div<SwitchGroupContainerProps>`
  ${labelLayoutStyles}
  & .${LABEL_CONTAINER_CLASS} {
    align-self: center;
    ${({ labelPosition }) =>
      labelPosition === LabelPosition.Left && "min-height: 30px"};
  }

   .bp3-control {
    color: ${({it_color}) => {
      return (it_color ? `${it_color}` : "var(--wds-color-text)") + ' !important'
    }};
        font-size: ${({it_size}) => {
      return (it_size ? `${it_size}` : "14px") + ' !important'
    }};
        font-style: ${({it_style}) => {
      return (it_style?.includes(FontStyleTypes.ITALIC) ? "italic" : "") + ' !important'
    }};
        font-weight: ${({it_style}) => {
      return (it_style?.includes(FontStyleTypes.BOLD) ? "bold" : "") + ' !important'
    }};
  }
`;

export interface InputContainerProps {
  alignment: Alignment;
  compactMode: boolean;
  height?: number;
  inline: boolean;
  labelPosition?: LabelPosition;
  optionCount: number;
  valid?: boolean;
  isDynamicHeightEnabled?: boolean;
}

export const InputContainer = styled.div<ThemeProp & InputContainerProps>`
  ${BlueprintRadioSwitchGroupTransform};

  border: 1px solid transparent;
  ${({ theme, valid }) =>
    !valid &&
    `
    border: 1px solid ${theme.colors.error};
  `}
`;

export interface OptionProps {
  label?: string;
  value: string;
}

function SwitchGroupComponent(props: SwitchGroupComponentProps) {
  const {
    accentColor,
    alignment,
    compactMode,
    disabled,
    height,
    inline,
    isDynamicHeightEnabled,
    labelAlignment,
    labelPosition,
    labelStyle,
    labelText,
    labelTextColor,
    labelTextSize,
    labelTooltip,
    labelWidth,
    onChange,
    options,
    selected,
    valid,
    it_color,
    it_size,
    it_style,
  } = props;

  const optionCount = (options || []).length;

  return (
    <SwitchGroupContainer
      compactMode={compactMode}
      data-testid="switchgroup-container"
      labelPosition={labelPosition}
      it_color={it_color}
      it_size={it_size}
      it_style={it_style}
    >
      {labelText && (
        <LabelWithTooltip
          alignment={labelAlignment}
          className={`switchgroup-label`}
          color={labelTextColor}
          compact={compactMode}
          disabled={disabled}
          fontSize={labelTextSize}
          fontStyle={labelStyle}
          helpText={labelTooltip}
          inline={inline}
          isDynamicHeightEnabled={isDynamicHeightEnabled}
          optionCount={optionCount}
          position={labelPosition}
          text={labelText}
          width={labelWidth}
        />
      )}
      <InputContainer
        alignment={alignment}
        compactMode={compactMode}
        height={height}
        inline={inline}
        isDynamicHeightEnabled={isDynamicHeightEnabled}
        labelPosition={labelPosition}
        optionCount={optionCount}
        valid={valid}
      >
        {Array.isArray(options) &&
          options.length > 0 &&
          options.map((option: OptionProps) => (
            <StyledSwitch
              $accentColor={accentColor}
              alignIndicator={alignment}
              checked={(selected || []).includes(option.value)}
              disabled={disabled}
              inline={inline}
              key={option.value}
              label={option.label}
              onChange={onChange(option.value)}
            />
          ))}
      </InputContainer>
    </SwitchGroupContainer>
  );
}

export interface SwitchGroupComponentProps {
  alignment: Alignment;
  disabled: boolean;
  inline: boolean;
  isDynamicHeightEnabled?: boolean;
  options: OptionProps[];
  onChange: (value: string) => React.FormEventHandler<HTMLInputElement>;
  required: boolean;
  selected: string[];
  valid?: boolean;
  compactMode: boolean;
  labelText?: string;
  labelPosition?: LabelPosition;
  labelAlignment?: Alignment;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  labelStyle?: string;
  labelWidth?: number;
  labelTooltip?: string;
  widgetId: string;
  height: number;
  accentColor: string;
  it_color?: string;
  it_size?: string;
  it_style?: string;
}

export default SwitchGroupComponent;
