import React, {useEffect, useRef, useState} from "react";
import {Checkbox, Table} from "antd";
import _ from "lodash";

function MenuPermissions(props: any) {
  const {roleId} = props;
  const [tableData, setTableData] = useState<any>([]);
  const [able, setAble] = useState(false);
  const [edit, setEdit] = useState(false);
  const [ableIndeterminate, setAbleIndeterminate] = useState(false);
  const [editIndeterminate, setEditIndeterminate] = useState(false);
  let saveParams: any = useRef([]);

  const columns = [
    {
      title: '功能菜单',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: () => {
        return (
          <>
            <Checkbox checked={able} indeterminate={ableIndeterminate} onChange={(ev: any) => {
              toggleSelected(ev, 'able');
            }}/> 是否可见
          </>
        )
      },
      key: 'able',
      render: (text: any, record: any) => {
        return <Checkbox indeterminate={record.ableIndeterminate} checked={record.able} onChange={(ev: any) => {
          singleToggleAble(ev.target.checked, record, 'able');
        }}/>
      },
      width: 150
    }
  ];
  const toggleType: any = {
    able: {
      setAll: setAble,
      setHalf: setAbleIndeterminate,
      indeterminate: 'ableIndeterminate'
    },
    edit: {
      setAll: setEdit,
      setHalf: setEditIndeterminate,
      indeterminate: 'editIndeterminate'
    }
  };
  const toggleSelected = (ev: any, type: string) => {
    const bool = ev.target.checked;
    const newTableData = loopTableData(type, bool);
    setTableData((newTableData));
    toggleType[type]?.setAll(bool);
    toggleType[type]?.setHalf(false);
  };
  const loopUpTableData = ({checked, record, key}: {
    checked: boolean,
    record: any,
    key: string
  }) => {
    let cloneTableData = _.cloneDeep(tableData);
    const fn = (data: any, recordKey: any) => {
      _.each(data, (i: any) => {
        if (i.menuKey == recordKey) {
          i[key] = checked;
          i[toggleType[key]?.indeterminate] = false;
          /*向下*/
          const fn2 = (data2: any) => {
            _.each(data2, (j: any) => {
              j[key] = checked;
              if (j.children) {
                fn2(j.children);
              }
            })
          };
          if (i.children) {
            fn2(i.children);
          }
          /*向上*/
          const fn3 = (data3: any, parent: any) => {
            _.each(data3, (j: any) => {
              if (j.menuKey == parent) {
                let _checked = true;
                let _half = false;
                _.each(j.children, (k: any) => {
                  if (!k[key]) {
                    _checked = false;
                  }
                  if (k[key]) {
                    _half = true;
                  }
                })
                j[key] = _checked;
                j[toggleType[key]?.indeterminate] = !_checked && _half;
                if (j.parentKey && j.parentKey != '0') {
                  fn3(cloneTableData, j.parentKey);
                }
              } else if (j.children) {
                fn3(j.children, parent);
              }
            })
          }
          if (i.parentKey && i.parentKey != '0') {
            fn3(cloneTableData, i.parentKey);
          }
          return false;
        } else if (!_.isEmpty(i.children)) {
          fn(i.children, recordKey);
        }
      })
    }
    fn(cloneTableData, record.menuKey);
    return cloneTableData;
  };
  const singleToggleAble = (checked: boolean, record: any, key: string) => {
    const newTableData = loopUpTableData({
      record: record,
      checked,
      key
    });
    let b1 = false;
    let b2 = false;
    const fn = (data: any) => {
      _.each(data, (i: any) => {
        if (i[key]) {
          b1 = true;
        } else {
          b2 = true;
        }
        if (i.children) {
          fn(i.children);
        }
      })
    };
    fn(newTableData);
    //console.log(b1, b2, newTableData);
    toggleType[key]?.setHalf(b1 && b2);
    toggleType[key]?.setAll(b1 && !b2);
    setTableData((newTableData));
  };
  const loopTableData = (key: string, value: boolean) => {
    let cloneTableData = _.cloneDeep(tableData);
    const fn = (data: any) => {
      _.each(data, (i: any) => {
        i[key] = value;
        i[toggleType[key]?.indeterminate] = false;
        if (!_.isEmpty(i.children)) {
          fn(i.children);
        }
      })
    }
    fn(cloneTableData);
    return cloneTableData;
  };
  const clearChildren = (data: any, type: string) => {
    let newData = _.cloneDeep(data);
    let b1 = false;
    let b2 = false;
    const fn = (data2: any) => {
      _.each(data2, (i: any) => {
        if (!roleId) {
          i.isVisable = 1;
        }

        //选择状态转换，save时用able判断
        i.able = !!i.isVisable;

        if (_.isEmpty(i.children)) {
          delete i.children;
        } else {
          fn(i.children);
        }
        if (i.able) {
          b1 = true;
        } else {
          b2 = true;
        }
      })
    }
    data && fn(newData);
    toggleType[type]?.setHalf(b1 && b2);
    toggleType[type]?.setAll(b1 && !b2);
    return newData || [];
  }
  useEffect(() => {
    if (!_.isEmpty(props.tableData)) {
      const result = clearChildren(props.tableData, 'able');
      setTableData(result);
    }
  }, [props.tableData]);
  const fn = (data: any) => {
    _.each(data, (i: any) => {
      if (i.able) {
        saveParams.current.push({
          "permissionId": i.id,
          "isVisable": 1,
        })
      }
      if (i.children) {
        fn(i.children);
      }
    })
  };
  useEffect(() => {
    saveParams.current = [];
    fn(tableData)
    props.setParams(saveParams.current);
  }, [tableData])
  return <Table
    columns={columns}
    dataSource={tableData}
    size="small"
    pagination={false} rowKey={`menuKey`}
  />
}

export default MenuPermissions;
