import React from "react";
import { Tabs, Layout, PageHeader } from "antd";

import Api from "@byk/api/Api";
import BusinessPage1 from "@byk/pages/ApplicationManager/Internationalize/language";
import BusinessPage2 from "@byk/pages/ApplicationManager/Internationalize/menu";
import BusinessPage3 from "@byk/pages/ApplicationManager/Internationalize/page";
import BusinessPage4 from "@byk/pages/ApplicationManager/Internationalize/text";
import BusinessPage5 from "@byk/pages/ApplicationManager/Internationalize/content";
import {getAppId, getProjectId, getTenant} from "utils/localStorage";
import _ from "lodash";
import {apiPath} from "@byk/routes";
const { TabPane } = Tabs;
export default class extends React.Component {
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.formRef = React.createRef();
  }
  state = {
    projectId: getProjectId(),
    subAppData: [{ id: "a", name: "语种维护" }, { id: "b", name: "菜单国际化" }, { id: "c", name: "页面国际化" }, { id: "d", name: "文本国际化" }],
    defaultSubId: getAppId(),
    appList: [],
    defaultAppId: "",
    tabKey: "a",
    key: "1"
  };

  async bindAppList() {
    let res: any = await Api.get(`${apiPath}/${getTenant()}/subapp/SysProject`, {
      id: getProjectId()
    });
    if (res.success) {
      let list = res.result[0].sysApps;
      let _list: any = [];
      let _defaultAppId: any = "";
      _.each(list, (item: any, idx: any) => {
        _list.push({ label: item.name, value: item.id });
      })
      if (_list.length > 0) {
        _defaultAppId = _list[0].value;
      }
      this.setState({ appList: _list, defaultAppId: _defaultAppId });
    }
  };

  onFinish = async (values: any) => {

  };

  componentDidMount() {
    this.bindAppList();
  }


  tabClick = (key: any) => {
    this.setState({ tabKey: key });
  }

  render() {
    return (
      <>
        <PageHeader className="site-page-header-main" title="应用国际化" />
        <Layout>
          <div className="x-main-box x-internationalize">
            <Tabs key={this.state.key} activeKey={this.state.tabKey} size="middle" destroyInactiveTabPane={true} onTabClick={(key: any) => this.tabClick(key)}>
              <TabPane tab="语种维护" key="a" forceRender={false}>
                <BusinessPage1 appList={this.state.appList} />
              </TabPane>
              <TabPane tab="菜单国际化" key="b" forceRender={false}>
                <BusinessPage2 appList={this.state.appList} defaultAppId={this.state.defaultAppId} />
              </TabPane>
              <TabPane tab="页面国际化" key="c" forceRender={false}>
                <BusinessPage3 appList={this.state.appList} defaultAppId={this.state.defaultAppId} />
              </TabPane>
              <TabPane tab="文本国际化" key="d" forceRender={false}>
                <BusinessPage4 appList={this.state.appList} defaultAppId={this.state.defaultAppId} />
              </TabPane>
              <TabPane tab="内容国际化" key="e" forceRender={false}>
                <BusinessPage5 />
              </TabPane>
            </Tabs>
          </div>
        </Layout>
      </>
    );
  }
}


