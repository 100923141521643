import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import CascaderComponent from "../component";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import type {DerivedPropertiesMap} from "utils/WidgetFactory";
import {EvaluationSubstitutionType} from "../../../entities/DataTree/types";
import {PropertyPaneConfig} from "../../../constants/PropertyControlConstants";
import {LabelPosition} from "../../../components/constants";
import {isAutoLayout} from "../../../utils/autoLayout/flexWidgetUtils";
import {Alignment} from "@blueprintjs/core";
import {SelectWidgetProps} from "../../../widgets/SelectWidget/widget";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import _ from "lodash";
import { GRID_DENSITY_MIGRATION_V1 } from "widgets/constants";

class CascaderWidget extends BaseWidget<CascaderWidgetProps, WidgetState> {
  constructor(props: CascaderWidgetProps) {
    super(props);
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "标签",
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "labelText",
            label: "文本",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Sets the label position of the widget",
            propertyName: "labelPosition",
            label: "位置",
            controlType: "ICON_TABS",
            fullWidth: false,
            options: [
              {label: "自动", value: LabelPosition.Auto},
              {label: "居左", value: LabelPosition.Left},
              {label: "居上", value: LabelPosition.Top},
            ],
            hidden: isAutoLayout,
            defaultValue: LabelPosition.Left,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Sets the label alignment of the widget",
            propertyName: "labelAlignment",
            label: "对齐方式",
            controlType: "LABEL_ALIGNMENT_OPTIONS",
            fullWidth: false,
            options: [
              {
                startIcon: "align-left",
                value: Alignment.LEFT,
              },
              {
                startIcon: "align-right",
                value: Alignment.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
            hidden: (props: SelectWidgetProps) => {
              return props.labelPosition !== LabelPosition.Left
            },
            dependencies: ["labelPosition"],
          },
          {
            helpText:
              "Sets the label width of the widget as the number of columns",
            propertyName: "labelWidth",
            label: "宽度（以列为单位）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 1,
            //max: 23,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
            hidden: (props: SelectWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          }
        ]
      },
      {
        sectionName: "数据",
        children: [
          {
            helpText: "Bind data from an API using {{}}",
            propertyName: "currentData",
            label: "Tree data",
            controlType: "TREE_EDIT_ARRAY",
            placeholderText: '[{ "name": "John" }]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.ARRAY},
            evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
            labelKey: "label",
            labelValue: "value"
          },
        ]
      },
      {
        sectionName: "设置",
        children: [

          {
            propertyName: "isLast",
            label: "父级可选",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isSearch",
            label: "支持搜索",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isMultiple",
            label: "支持多选",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "selectedCount",
            label: "最多可选",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER,},
            hidden: ({isMultiple}: SelectWidgetProps) => !isMultiple,
            dependencies: ["isMultiple"],
          }
        ],
      },
      {
        sectionName: "验证",
        children: [
          {
            propertyName: "isRequired",
            label: "是否必填",
            helpText: "Makes input to the widget mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      },
      {
        sectionName: "一般",
        children: [
          {
            helpText: "Show help text or details about current selection",
            propertyName: "labelTooltip",
            label: "提示",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isVisible",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isDisabled",
            label: "是否禁用",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isReadonly",
            label: "是否只读",
            helpText: "Readonly input to this widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "animateLoading",
            label: "加载动画",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      }
    ];
  }

  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return super.getWidgetEvents('CasaderWidget');
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "labelTextColor",
            label: "字体颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelTextSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            hidden: isAutoLayout,
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "输入文字",
        children: [
          {
            propertyName: "it_color",
            label: "字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "it_size",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            hidden: isAutoLayout,
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "it_style",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "输入框",
        children: [
          {
            propertyName: "ib_bgColor",
            label: "背景色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "ib_borderColor",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "ib_iconColor",
            label: "图标颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.value }}`,
      getValue: `{{ this.value }}`,
      getLabel: `{{ this.label }}`,
      label: `{{ this.label }}`,
      isValid: `{{ this.isRequired ? this.value.length > 0 : true }}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue",
    };
  }

  // TODO Find a way to enforce this, (dont let it be set)
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isDirty: false,
      isFirst: false,
      value: undefined,
      label: undefined
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
      it_color: "{{appsmith.theme.form.inputText.fontColor}}",
      it_size: "{{appsmith.theme.form.inputText.fontSize}}",
      it_style: "{{appsmith.theme.form.inputText.fontStyle}}",
      labelTextColor: "{{appsmith.theme.form.label.fontColor}}",
      labelTextSize: "{{appsmith.theme.form.label.fontSize}}",
      labelStyle: "{{appsmith.theme.form.label.fontStyle}}",
      ib_bgColor: "{{appsmith.theme.form.inputBorder.bgColor}}",
      ib_borderColor: "{{appsmith.theme.form.inputBorder.bgBorderColor}}",
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  updateWidgetProperty = (propertyValues: any, propertyLabels: any) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    const _str1 = super.reDynamicStringFn(this.props.onOptionChange as string);
    this.props.updateWidgetMetaProperty("label", propertyLabels);
    if (_str1) {
      this.props.updateWidgetMetaProperty("value", propertyValues ?? "", {
        triggerPropertyName: "onOptionChange",
        dynamicString: _str1,
        event: {
          type: EventType.ON_OPTION_CHANGE,
        },
      });
    } else {
      this.props.updateWidgetMetaProperty("value", propertyValues);
    }
  }
  onDropdownOpen = () => {
    super.executeAction({
      triggerPropertyName: "onDropdownOpen",
      dynamicString: this.props.onDropdownOpen,
      event: {
        type: EventType.ON_DROPDOWN_OPEN,
      },
    });
  };

  onDropdownClose = () => {
    super.executeAction({
      triggerPropertyName: "onDropdownClose",
      dynamicString: this.props.onDropdownClose,
      event: {
        type: EventType.ON_DROPDOWN_CLOSE,
      },
    });
  };

  shouldComponentUpdate(nextProps: WidgetProps) {
    const _diffProps: any = [];
    _.each(this.props, (value: any, key: any) => {
      if (value !== nextProps[key]) {
        _diffProps.push(key);
      }
    })
    if (_diffProps.length === 0 && this.props.currentData === nextProps.currentData && this.props.isDirty) {
      return false;
    }
    return true;
  }


  getPageView() {
    if (!this.props.isFirst) {
      this.props.updateWidgetMetaProperty("isFirst", true);
      this.props.updateWidgetMetaProperty("value", this.props.defaultValue);
    }
    return <CascaderComponent
      onClose={this.onDropdownClose}
      onOpen={this.onDropdownOpen}
      compactMode={!((this.props.bottomRow - this.props.topRow) / GRID_DENSITY_MIGRATION_V1 > 1)}
      {...this.props}
      updateWidgetProperty={this.updateWidgetProperty}
      it_color={this.props.it_color}
      it_size={this.props.it_size}
      it_style={this.props.it_style}
      ib_bgColor={this.props.ib_bgColor}
      ib_borderColor={this.props.ib_borderColor}
      ib_iconColor={this.props.ib_iconColor}
    />;
  }

  static getWidgetType(): WidgetType {
    return "CASCADER_WIDGET";
  }
}


export interface CascaderWidgetProps extends WidgetProps {
  isLast: boolean,
  isSearch: boolean,
  isMultiple: boolean,
}

export default CascaderWidget;
