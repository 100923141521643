import {Form, Input, message, Modal, Select} from 'antd';
import React, {forwardRef, useImperativeHandle, useState} from 'react';

import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {ModelingOptions} from "@byk/pages/modeling/constants/ModelingOptions";
import {MODEL_FORM_RULES,} from "@byk/pages/modeling/ModelDataUtil";
import styled from "@emotion/styled";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-input, .ant-select {
    border: 1px solid #E4E8EB;
    border-radius: 5px;
  }

  .ant-btn {
    height: 40px;
    border-radius: 5px;
  }
`

const layout = {
  labelCol: {span: 7},
  wrapperCol: {span: 14},
};

const CreateProperty: React.FC<any> = forwardRef(({mxUpdateModel}, ref) => {
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [form] = Form.useForm();
      const [modelData, setModelData] = useState<any>();
      const [fkProperty, setFkProperty] = useState<any>();

      useImperativeHandle(ref, () => ({
        showModal,
        createFkProperty,
      }));

      const createFkProperty = (oneModel: any, multiModel: any, callback: (property: any) => void) => {
        let values = {
          name: "",
          description: "",
          model: multiModel.name,
          modelId: multiModel.id,
          targetModel: oneModel.name,
          callback: callback,
        };
        form.setFieldsValue(values);
        setModelData(multiModel);
        setFkProperty(values);
        setIsModalOpen(true);
      }

      const showModal = (md: any) => {
        setModelData(md);
        form.resetFields();
        setIsModalOpen(true);
      };

      const handleOk = () => {
        form.validateFields().then(
          (values: any) => {
            let property = {
              ...values,
              modelId: modelData.id,
              model: modelData.name,
            }
            // 创建外键， 模型关系保存时，保存外键信息
            if (fkProperty) {
              property = {
                ...fkProperty,
                ...property,
              }
              fkProperty.callback && fkProperty.callback(property);
              setIsModalOpen(false);
            } else {
              let res: any = ModelingApi.doSaveProperty(property);
              res.then((result: any) => {
                if (!result.success) {
                  message.error(result.message).then(r => {
                  });
                } else {
                  let property = result.result[0];
                  modelData.properties.push(property);
                  setIsModalOpen(false);
                  mxUpdateModel(modelData);
                }
              });
            }
          }
        )
      };

      const handleCancel = () => {
        setIsModalOpen(false);
      };

      return (
        <>
          <ModalWrapper
            title="创建属性" onOk={handleOk} onCancel={handleCancel} open={isModalOpen}
            cancelText="取消" okText="确认" closable={false}
            maskClosable={false}
            style={{
              width: "445px",
            }}
          >
            <Form  {...layout} form={form}
                   wrapperCol={{span: 12}}
                   autoComplete="off"
            >
              <Form.Item
                label="属性编码"
                name="name"
                rules={MODEL_FORM_RULES['name']}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                label="属性名称"
                name="description"
                rules={MODEL_FORM_RULES['description']}
              >
                <Input/>
              </Form.Item>
              {!fkProperty && (
                <Form.Item
                  name="type"
                  label="属性类型"
                  rules={[
                    {
                      required: true,
                      message: `属性类型必填.`,
                    },
                  ]}
                >
                  <Select
                    bordered={false}
                    showArrow={false}
                    options={ModelingOptions}
                  />
                </Form.Item>
              )}
            </Form>
          </ModalWrapper>
        </>
      );
    }
  )
;

export default CreateProperty;
