import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TSwiperWidget from "../component";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";
import _ from "lodash";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";

class TSwiper extends TaroWidget<TSwiperWidgetProps, WidgetState> {
  constructor(props: TSwiperWidgetProps) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "imageData",
            label: "轮播图片",
            helpText: "Sets the label of the button",
            controlType: "CAROUSEL_ARRAY",
            placeholderText: "确定",
            isBindProperty: true,
            isCarousel: true,
            isTriggerProperty: true,
            isJSConvertible: false,
            validation: {type: ValidationTypes.ARRAY},
            iconSize: '58px',
          },
        ],
      },
      {
        sectionName: "设置",
        children: [
          {
            propertyName: "isIndicatorDots",
            label: "指示点",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isAutoplay",
            label: "自动播放",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isCircular",
            label: "循环播放",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "边框&阴影",
        children: [
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
    ];
  }


  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isDirty: false,
      value: undefined
    };
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.value }}`
    };
  }

  updateWidgetProperty = (propertyValues: string) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty("value", propertyValues);
  }
  onItemClick(item: any) {
    let url = item.topage ? item.topage : item.toUrl;
    let linkParams = _.isEmpty(item.linkParams) || {};
    url && super.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: `{{navigateTo("${url}", ${linkParams}, "${item.topagetype}")}}`,
      event: {
        type: EventType.ON_CLICK
      },
    });
  }
  getPageView() {
    return (
      <TSwiperWidget {...this.props} onItemClick={this.onItemClick} updateWidgetProperty={this.updateWidgetProperty} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_SWIPER_WIDGET";
  }
}

export interface TSwiperWidgetProps extends WidgetProps {
  value: any[];
  enableDownload?: boolean;
  enableRotation?: boolean;
  onClick?: string;
  borderRadius: string;
  boxShadow?: string;
}

export default TSwiper;
