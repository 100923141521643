import React from "react";
import BaseWidget, {WidgetState} from "../../../widgets/BaseWidget";
import {WidgetType} from "../../../constants/WidgetConstants";
import {PropertyPaneConfig} from "../../../constants/PropertyControlConstants";
import DynamicFormsWidget, {dateType} from "../component";
import {ValidationTypes} from "../../../constants/WidgetValidation";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import {LabelPosition} from "../../../components/constants";
import _ from "lodash";
import moment from "moment";
import {Stylesheet} from "../../../entities/AppTheming";

class DynamicForms extends BaseWidget<any, WidgetState> {
  constructor(props: any) {
    super(props);
  }

  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return super.getWidgetEvents('DynamicFormsWidget');
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "labelTextColor",
            label: "字体颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelTextSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "按钮样式",
        children: [
          {
            propertyName: "primaryBtnColor",
            label: "主要按钮颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "secondBtnColor",
            label: "次要按钮颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "btnBorderRadius",
            label: "圆角大小",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "背景",
        children: [
          {
            propertyName: "backgroundColor",
            helpText: "Sets the checked state color of the checkbox",
            label: "背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "borderColor",
            helpText: "Sets the checked state color of the checkbox",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "边框&阴影",
        children: [
          {
            helpText:
              "Enter value for border width which can also use as margin",
            propertyName: "borderWidth",
            label: "边框宽度",
            placeholderText: "Enter value in px",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER},
          },
          {
            propertyName: "borderRadius",
            label: "圆角大小",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
    ];
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "",
            propertyName: "labelPosition",
            label: "标签位置",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {label: "居左", value: LabelPosition.Left},
              {label: "居上", value: LabelPosition.Top},
            ],
            defaultValue: LabelPosition.Top,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER},
          },
          {
            helpText: "",
            propertyName: "colSpans",
            label: "布局",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {label: "一列", value: 24},
              {label: "二列", value: 12},
              {label: "三列", value: 8},
              {label: "四列", value: 6},
            ],
            defaultValue: 24,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER},
          },
          {
            propertyName: "isVisible",
            helpText:
              "Controls the visibility of the widget. Can also be configured the using {{currentItem}} binding.",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: true,
            validation: {
              type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
              params: {
                type: ValidationTypes.BOOLEAN,
              },
            },
            customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
            evaluatedDependencies: ["sourceData"],
          },
          {
            propertyName: "isDisabled",
            helpText:
              "Disables input to the widget. Can also be configured the using {{currentItem}} binding.",
            label: "是否禁用",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: false,
            validation: {
              type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
              params: {
                type: ValidationTypes.BOOLEAN,
              },
            },
            customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
            evaluatedDependencies: ["sourceData"],
          },
          {
            helpText: "Readonly input to this widget",
            propertyName: "isReadonly",
            label: "是否只读",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: false,
            validation: {type: ValidationTypes.BOOLEAN},
            customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
            evaluatedDependencies: ["sourceData"],
          },
        ],
      },
      {
        sectionName: "Data",
        children: [
          {
            propertyName: "currentData",
            helpText: "Input JSON sample for default form layout",
            label: "Source data",
            controlType: "INPUT_TEXT",
            placeholderText: '[{ "field": "name", "fieldName": "姓名", "fieldType": "input" }]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.ARRAY}
          }
        ]
      }
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
      primaryBtnColor: "{{appsmith.theme.colors.primaryColor}}",
      secondBtnColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }


  onFinish = (values: any) => {
    let formatValues:any = {};
    _.each(this.props.currentData, (item:any)=>{
      if(dateType.includes(item.fieldType)){//日期类型的值转换为时间戳
        formatValues[item.field] = values[item.field]?(moment(values[item.field]).valueOf()):values[item.field];
      }else{
        formatValues[item.field] = values[item.field];
      }

    })
    if (this.props.onSubmit) {
      this.setState({
        isLoading: true,
      });
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: this.props.onSubmit,
        event: {
          type: EventType.ON_SUBMIT,
        },
        globalContext: formatValues
      });
    }
  }

  getPageView() {
    return <DynamicFormsWidget onFinish={this.onFinish} {...this.props} />
  }

  static getWidgetType(): WidgetType {
    return 'DYNAMICFORMS_WIDGET';
  }
}

export default DynamicForms;
