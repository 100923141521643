import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import styled from "styled-components";
import {QueryEditorContextProvider} from "@byk/pages/modeling/SqlQueryEditor/QueryEditorContext";
import QueryEditorHeader from "@byk/pages/modeling/SqlQueryEditor/QueryEditorHeader";
import SqlCodeMirror from "@byk/pages/modeling/SqlQueryEditor/SqlCodeMirror";
import Schema from "@byk/pages/modeling/SqlQueryEditor/Schema";
import SqlList from "@byk/pages/modeling/SqlQueryEditor/SqlList";
import {Layout, message} from "antd";
import Sider from "antd/es/layout/Sider";
import {Content} from "antd/lib/layout/layout";
import {SqlQueryEditorModel} from "@byk/pages/modeling/lib/SqlQueryEditorModel";
import useResize, {CallbackResponseType, DIRECTION} from "../../../../utils/hooks/useResize";

const ContainerDiv = styled.div<any>`
  margin: 0;
  height: 100%;
  color: #151515;

  .layout-content {
    position: relative;
    height: 100%;
  }

  .ant-layout-sider {
    flex: 0 0 260px !important;
    max-width: 260px !important;
    min-width: 200px !important;
    width: 260px !important;
    height: calc(100vh - 68px) !important;
    border: 1px solid #f0f0f0!important;
  }

  .ant-tabs-ink-bar {
    background: #E15615 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover {
    color: #4C5664 !important;
  }

  .ant-tabs {
    width:100%;
  }

  .ant-tabs-tabpane, .ant-tabs-content{
    height: 100%;
    overflow-y: auto;
  }
`

const ResizeHandler = styled.div`
  text-align: center;
  height: 2px;
  background:var(--ads-v2-color-border);
  margin-top: 12px;
  cursor: ns-resize;
`;

export const page_header = 65;    // 页面导航高度
export const header_height = 100;
export const code_mirror_bottom_height = 15;
export const CODE_MIRROR_DEFAULT_HEIGHT = 300;
export const schema_tab_height = 60;
export const SUB_MAX_HEIGHT_schema = page_header + header_height + code_mirror_bottom_height; // 65 + 100 + 15 = 180
export const SUB_DEFAULT_HEIGHT_schema = SUB_MAX_HEIGHT_schema + CODE_MIRROR_DEFAULT_HEIGHT; // 180 + 300 = 480

const SQLQueryEditor: React.FC<any> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({}))
  const [updateTime, setUpdateTime] = useState<any>(new Date().getTime());
  const [schemaSubHeight, setSchemaSubHeight] = useState<any>(SUB_DEFAULT_HEIGHT_schema);
  const [codeMirrorHeight, setCodeMirrorHeight] = useState<any>(CODE_MIRROR_DEFAULT_HEIGHT);
  const [sqlInfo, setSqlInfo] = useState<any>({});
  const [tabKey, setTabKey] = useState<any>("Schema");
  const [executionResult, setExecutionResult] = useState<any>();
  const sqlCodeMirrorContainerRef = useRef(null);
  const codeMirrorSqlRef = useRef('');
  const sqlQueryEditorModelRef = useRef(new SqlQueryEditorModel());

  useEffect(() => {
    updateModelList();
  }, [])

  const refreshCmp = () => {
    codeMirrorSqlRef.current = sqlInfo?.sql || '';
    setUpdateTime(new Date().getTime());
  }

  const updateModelList = () => {
    sqlQueryEditorModelRef.current.init().then(() => {
      refreshCmp();
    });
  }

  const gotoModeling = () => {
    props.goToModeling && props.goToModeling();
  }

  const addNewSqlInfo = () => {
    sqlQueryEditorModelRef.current.newSqlInfo().then((data) => {
      setSqlInfo(data);
      refreshCmp();
    });
  }

  const setState = (param: any = {}) => {
    let keys = Object.keys(param);
    keys.forEach(key => {
      switch (key) {
        case 'sqlInfo':
          setSqlInfo(param[key]);
          break;
        case 'schemaSubHeight':
          setSchemaSubHeight(param[key]);
          break;
        case 'codeMirrorHeight':
          setCodeMirrorHeight(param[key]);
          break;
        case 'executionResult':
          setExecutionResult(param[key]);
          break
        case 'tabKey':
          setTabKey(param[key]);
          break;
      }
    })
  }

  const getState = () => {
    return {
      schemaSubHeight,
      executionResult,
      tabKey,
      sqlCodeMirrorContainerRef,
      codeMirrorHeight,
      sqlInfo,
    }
  }

  const resizeAfterCallback = (data: CallbackResponseType) => {
    let canMoveHeight = data.height + page_header + header_height + 75 < window.innerHeight;
    if (canMoveHeight) {
      setSchemaSubHeight(SUB_MAX_HEIGHT_schema + data.height);
      setCodeMirrorHeight(data.height);
    }
  };

  const {mouseDown, setMouseDown} = useResize(
    sqlCodeMirrorContainerRef,
    DIRECTION.vertical,
    resizeAfterCallback,
  );

  const sqlInfoAction = (type: any, params: any) => {

    if (type == 'update') {
      sqlQueryEditorModelRef.current.saveSqlInfo(params).then((data:any) => {
        message.success('更新成功!');
        setSqlInfo(data);
        refreshCmp();
      })
    } else if (type == 'insert') {
      sqlQueryEditorModelRef.current.insertSqlInfo(params).then((data:any) => {
        message.success('保存成功!');
        setSqlInfo(data);
        refreshCmp();
      })
    } else if (type == 'delete') {
      sqlQueryEditorModelRef.current.deleteSqlInfo(params).then(() => {
        message.success('删除成功！');
        refreshCmp();
      })
    }
  }


  return (
    <ContainerDiv updateTime={updateTime}>
      <QueryEditorContextProvider
        gotoModeling={gotoModeling}
        sqlQueryEditorMode={sqlQueryEditorModelRef.current}
        codeMirrorSqlRef={codeMirrorSqlRef}
        addNewSqlInfo={addNewSqlInfo}
        sqlInfoAction={sqlInfoAction}
        refreshCmp={refreshCmp}
        setState={setState}
        getState={getState}
      >
        <Layout>
          <Sider>
            <SqlList/>
          </Sider>

          <Content>
            <div className='layout-content'>
              <QueryEditorHeader/>
              <div ref={sqlCodeMirrorContainerRef}>
                <SqlCodeMirror/>

                <ResizeHandler
                  onMouseDown={(e) => {
                    setMouseDown(true);
                  }}
                  className={`w-full h-1 bg-transparent hover:bg-transparent transform transition ${mouseDown ? "" : ""}`}
                />
              </div>

              <Schema/>
            </div>
          </Content>
        </Layout>
      </QueryEditorContextProvider>
    </ContainerDiv>
  )
})

export default SQLQueryEditor;
