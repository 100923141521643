import React from 'react';
import BaseWidget, {WidgetProps, WidgetState} from "../widgets/BaseWidget";
import 'taro-ui/dist/style/index.scss';
import './resetTaro.css';
import styled from "styled-components";

class TaroIndex<T extends WidgetProps, K extends WidgetState, TCache = unknown> extends BaseWidget<T, K> {
  getPageView(): React.ReactNode {
    return undefined;
  }
}
const LabelWrapper = styled.div<{
  fontSize: string;
  color: string;
  fontStyle: string;
  inputFontSize: string;
  inputColor: string;
  inputFontStyle: string;
  bgColor: string;
  borderColor: string;
}>`
  display:flex;
  align-items:center;
  .label-left{
    width: 100px;
  }
  .label-right{
    flex: 1;
  }
  .label-left{
    padding-left: 4px;
    color: ${({color})=> color };
    font-size: ${({fontSize})=> fontSize };
    font-weight: ${({fontStyle})=> fontStyle&&fontStyle.indexOf('BOLD')>=0?'bold':'normal' };
    font-style: ${({fontStyle})=> fontStyle&&fontStyle.indexOf('ITALIC')>=0?'italic':'normal' };
  }
  .weui-input,.taro-textarea{
    background-color: ${({bgColor})=> bgColor };
    border: 1px solid ${({borderColor})=> borderColor };
    color: ${({inputColor})=> inputColor };
    font-size: ${({inputFontSize})=> inputFontSize };
    font-weight: ${({inputFontStyle})=> inputFontStyle&&inputFontStyle.indexOf('BOLD')>=0?'bold':'normal' };
    font-style: ${({inputFontStyle})=> inputFontStyle&&inputFontStyle.indexOf('ITALIC')>=0?'italic':'normal' };
  }
`;

export const LabelWidget = (props:any)=>{
  const {label, children, fontSize,
    color,
    fontStyle,
    inputFontSize,
    inputColor,
    inputFontStyle,
    bgColor,
    borderColor} = props;
  return (
    <LabelWrapper fontSize={fontSize}
                  color={color}
                  fontStyle={fontStyle}
                  inputFontSize={inputFontSize}
                  inputColor={inputColor}
                  inputFontStyle={inputFontStyle}
                  bgColor={bgColor}
                  borderColor={borderColor}>
      <div className={'label-left'}>{label}</div>
      <div className={'label-right'}>
        {children}
      </div>
    </LabelWrapper>
  )
}
export default TaroIndex;
