import React, { useRef, useState, useEffect, forwardRef } from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import XModal from "@byk/pages/components/XModal";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, message, Row, Form, Col, Input, Tooltip } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';
import { toString } from 'lodash';

import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/theme/ambiance.css';
import 'codemirror/addon/display/autorefresh';
import Editors from "@byk/pages/ApplicationManager/LogManagement/codeEdit";
import {appUrlParams} from "@byk/utils/Utils";
import { apiPath, routePath } from "@byk/routes";
import { getTenant } from "utils/localStorage";



class LogManagement extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  editorRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.editorRef = React.createRef();
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.formRef = React.createRef();
    //this.focusTextInput = this.focusTextInput.bind(this);
  }
  state = {
    record: null,
    visible: false,
    rows: [],
    roleData: [],
    editData: null,
    //
    title: '全部成员',
    type: 1,
    isModalForm: false,
    formCfg: {
      title: "日志信息",
      okText: "确定",
      type: null
    },
    roleList: [],
    titeType: { "a": "查看", "b": "查看", "c": "新增" },
    btnTextType: { "a": "保存", "b": "保存", "c": "保存" },
    rule: "",
    initParams: {
      includeRelated: true,
      includeList: true,
      //tenant: getTenant(),
      desc:"createDate",
      queryMode: "allLike"
    }
  };
  async componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.init();
  }

  callback(_data: any) {
    this.setState({}, () => {
      this.init();
    });

  };

  init() {
    let params: any = this.state.initParams;

    this.setState({ initParams: params }, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };
  showModal(type: string) {
    this.setState({ isModalForm: true },()=>{
      let _data: any = this.state.formCfg.data;
      let rule=""
      if (type == "b") {
        this.formRef.current?.setFieldsValue({
          id: _data.id, name: _data.name,
          description: _data.description, rule: _data.rule
        });
        rule=_data.message;
      }
      else{
        this.formRef.current?.setFieldsValue({
          id: null, name: "",
          description: "", rule: ""
        });
      }
      setTimeout(() => {
        this.editorRef.current.setValue(rule);
      }, 100);
    });

  };

  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data,
      }
    }, () => { this.showModal(type); });

    //this.formRef.current.resetFields();
  };
  handleOk1 = () => {
    this.setState({ isModalForm: false });
  };

  handleCancel = () => {
    this.setState({ isModalForm: false });
  };
  //更新
  onFinish = async(value: any) => {
    //console.log(this.editorRef.current.editor.getValue());
    value.rule=this.state.rule;
    let postUrl = `${apiPath}/platform/rest/DroolsRule`;
    let _data: any = {};
    let cfg: any = this.state.formCfg;
    let result: any = null;
    //创建
    if (cfg.type == "c") {
      _data.name = value.name;
      _data.description = value.description;
      _data.rule = value.rule;
      result = await Api.post(postUrl, _data);
    }
    //查看
    if (cfg.type == "b") {
      _data.id = cfg.data.id;
      _data.name = value.name;
      _data.description = value.description;
      _data.rule = value.rule;
      result = await Api.put(postUrl, _data);
    }
    console.log("_data", _data);
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel();
      this.init();
    }
    else {
      message.error(result.message);
    }

  }
  changeCode = (CodeMirror: any, changeObj: any, value: any) => {
    debugger;
    if (!value) return;
    // 获取 CodeMirror.doc.getValue()
    // 赋值 CodeMirror.doc.setValue(value) // 会触发 onChange 事件，小心进入无线递归。
    this.setState({ rule: value });
  };
  onChange = (editor: any, data: any, value: any) => {
    this.setState({rule:value});
  }

  setRule = (value: any) => {
    this.setState({rule:value});
  }

  render() {
    // api
    const apiGet: string = `${apiPath}/${getTenant()}/rest/SysLog/list`;
    const apiPost: string = `${apiPath}/${getTenant()}/rest/SysLog`;
    const apiPut: string = `${apiPath}/${getTenant()}/rest/SysLog`;
    const apiDel: string = `${apiPath}/${getTenant()}/rest/SysLog`;
    //表格refresh
    const refreshTable = (params?: any) => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    };


    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };
    //查看按钮
    const EditClick = async (record: any) => {
      let result: any = await Api.get(apiPost, { id: record.id });
      if (result.success) {
        if (result.result.length > 0) {
          let _record = result.result[0];
          let { id, name, description, rule } = record;
          this.modalRef.current?.edit({
            id, name, description, rule
          });
        }
      }
    };


    //查询项配置
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "traceId",
        label: "TraceId",
        allowClear: true,
        col: 4,
        labelCol:8
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "url",
        label: "接口地址",
        allowClear: true,
        col: 4,
        labelCol:8
      },
      {
        itemType: "select",
        placeholder: "请输入",
        name: "level",
        label: "日志级别",
        allowClear: true,
        col: 4,
        labelCol:8,
        data: [{ name: '错误', id: 'ERROR' }, { name: '警告', id: 'WARN' }, { name: '信息', id: 'INFO' }]
      },
      {
        itemType: "select",
        placeholder: "请选择",
        name: "method",
        label: "请求方法",
        allowClear: true,
        col: 4,
        labelCol:8,
        data: [{ name: 'GET', id: 'GET' }, { name: 'PUT', id: 'PUT' }, { name: 'POST', id: 'POST' }, { name: 'DELETE', id: 'DELETE' }]
      },
    ];
    const searchProps = {
      option: searchConfig,
      onSearch: refreshTable,
      btnBoolean: true,
    };



    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 100,
      },
      {
        title: "traceId",
        dataIndex: "traceId",
        key: "traceId",
        width: 100,
      },
      {
        title: "接口地址",
        dataIndex: "url",
        key: "url",
        render: (_, record: any) => (
          <Space size="middle">
            <Tooltip title={record.url}>
              <span style={{wordBreak:"break-word"}}>
                {record.url}
              </span>
            </Tooltip>
          </Space>
        ),
      },
      {
        title: "请求方法",
        dataIndex: "method",
        key: "method",
        width: 100,
      },
      {
        title: "日志级别",
        dataIndex: "level",
        key: "level",
        width: 100,
        render: (_, record: any) => (
          <>
           {
            { 'ERROR':'错误', 'WARN': '警告','INFO':'信息' }[record.level]
           }
          </>
        ),
      },
      {
        title: "服务器IP",
        dataIndex: "serverIp",
        key: "serverIp",
        width: 100,
      },
      {
        title: "操作时间",
        dataIndex: "createDate",
        key: "createDate",
        render: (_, record: any) => (
          moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <Space size="middle">
            <Button type="link" onClick={() => { this.openForm("b", record) }}>
              查看
            </Button>
          </Space>
        ),
        width: 100,
      },
    ];
    const searchParam=()=>{
      let params= {...this.searchRef.current.getSearchData(),...this.state.initParams};
      return params;
    }
    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      searchParam:searchParam,
      initParams:this.state.initParams,
      firstLoad: true
    };


    return (
      <>
        <div className="x-main-box">
          <SearchForm ref={this.searchRef} {...searchProps} />
          <div style={{ width: "100%", height: "50px" }}>
            <Button type="primary" onClick={()=>window.open(`${routePath}/LogOnline${appUrlParams()}`)} className="x-fr">
              在线查看日志
            </Button>
          </div>
          <TableList ref={this.tableRef} {...tableProps} />
          <Modal className="z-code-mirror" title={this.state.formCfg.title} width={"100%"} open={this.state.isModalForm} onOk={() => this.showModal} onCancel={this.handleCancel} footer={[]}>
            <Row>
              <Col className="gutter-row" md={24}>
                <Form ref={this.formRef} labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}>
                  <Form.Item label="" name="rule"
                    rules={[{ required: false, message: "请输入!" }]}>
                      <Editors  getValue={this.setRule}  ref={this.editorRef} />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}
export default withRouter(LogManagement);
