import IconSVG from "./icon.svg";
import Widget from "./widget";
import { DynamicHeight } from "utils/WidgetFeatures";
import { Alignment } from "@blueprintjs/core";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Slider",
  iconSVG: IconSVG,
  needsMeta: true,
  features: {
    dynamicHeight: {
      sectionIndex: 0,
      defaultValue: DynamicHeight.AUTO_HEIGHT,
      active: true,
    }
  },
  defaults: {
    rows: 3,
    columns: 20,
    widgetName: "Slider",
    version: 1,
    animateLoading: true,
    value:"",
    labelText:"Label",
    labelPosition:"Left",
    labelWidth:5,
    vertical:false,
    max:100,
    min:0,
    labelAlignment: Alignment.RIGHT,
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
