import React from "react";
import CarouselWidget from '../component';
import BaseWidget, {WidgetState} from "../../../widgets/BaseWidget";
import {WidgetType} from "../../../constants/WidgetConstants";
import {ValidationTypes} from "../../../constants/WidgetValidation";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import {PropertyPaneConfig} from "../../../constants/PropertyControlConstants";
import _ from "lodash";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";

class Carousel extends BaseWidget<any, WidgetState> {
  constructor(props: any) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "imageData",
            label: "轮播图片",
            helpText: "Sets the label of the button",
            controlType: "CAROUSEL_ARRAY",
            placeholderText: "确定",
            isBindProperty: true,
            isTriggerProperty: true,
            isJSConvertible: true,
            validation: {type: ValidationTypes.ARRAY},
            iconSize: '58px',
          },
          {
            propertyName: "autoplay",
            label: "自动轮播",
            helpText: " the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "objectFit",
            label: "填充方式",
            helpText: "",
            controlType: "DROP_DOWN",
            placeholderText: "确定",
            isBindProperty: true,
            isTriggerProperty: false,
            options: [
              {
                label: "适合Contain",
                value: 'contain',
              },
              {
                label: "平铺Cover",
                value: 'cover',
              },
              {
                label: "自动Fill",
                value: 'fill',
              },
            ],
            size: 1,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "isVisible",
            helpText:
              "Controls the visibility of the widget. Can also be configured the using {{currentItem}} binding.",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: true,
            validation: {
              type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
              params: {
                type: ValidationTypes.BOOLEAN,
              },
            },
            customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
            evaluatedDependencies: ["sourceData"],
          },
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "图片大小",
        children: [
          {
            helpText:
              "Sets the label width of the widget as the number of columns",
            propertyName: "width",
            label: "宽度（px）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "height",
            helpText: "Changes the height of the button",
            label: "高度（px）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          }
        ],
      },
      {
        sectionName: "圆角&阴影",
        children: [
          {
            propertyName: "borderRadius",
            label: "圆角半径（px）",
            helpText:
              "",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            // isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER},
          },
          {
            propertyName: "boxShadow",
            label: "阴影半径（px）",
            helpText:
              "",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER},
          },
        ],
      },
      {
        sectionName: "边距",
        children: [
          {
            propertyName: "margin",
            label: "外边距（px）",
            helpText:
              "",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            validation: {type: ValidationTypes.NUMBER},
          },
          {
            propertyName: "padding",
            label: "内边距（px）",
            helpText:
              "",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER},
          },
        ],
      },
    ];
  }

  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return super.getWidgetEvents('CAROUSEL_WIDGET');
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      isValid: `{{!!(this.imageData && this.imageData.length > 0 ? this.imageData : '')}}`,
      getValue: `{{ this.imageData && this.imageData.length > 0 ? this.imageData : '' }}`
    };
  }

  onItemClick(item: any) {
    let url = item.topage ? item.topage : item.toUrl;
    let linkParams = _.isEmpty(item.linkParams) || {};
    url && super.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: `{{navigateTo("${url}", ${linkParams}, "${item.topagetype}")}}`,
      event: {
        type: EventType.ON_CLICK
      },
    });
  }

  onHandleClick() {
    if (this.props.onClick) {
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: this.props.onClick,
        event: {
          type: EventType.ON_CLICK
        },
      });
    }
  }

  getPageView() {
    return (
      <>
        <CarouselWidget onHandleClick={this.onHandleClick} onItemClick={this.onItemClick} {...this.props} />
      </>
    )
  }

  static getWidgetType(): WidgetType {
    return 'CAROUSEL_WIDGET';
  }
}

export default Carousel;
