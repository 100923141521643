import React, {createContext} from "react";
import TableList from "@byk/pages/components/TableList";
import type {ColumnsType} from "antd/es/table";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space
} from "antd";
import Api from "@byk/api/Api";

import {withRouter} from "react-router-dom";
import moment from 'moment';
import localStorage, {getTenant, getTenantId} from "utils/localStorage";
import _ from "lodash";
import { apiPath } from "@byk/routes";
import XModal from "@byk/pages/components/XModal";
import {getPopupContainer, RoleContext} from "@byk/pages/AppSystemInit/RoleManager";
import {appTenantClass} from "@byk/utils/Utils";

const typeOptions = [
  {label: '文本', value: 'text'},
  {label: '多行文本', value: 'textarea'},
  {label: '富文本', value: 'richtext'},
  {label: '列表', value: 'select'},
  {label: '密码', value: 'password'},
  {label: '整数', value: 'integer'},
  {label: '开关', value: 'radio'},
];

class SysConfig extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  tableAddRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.tableAddRef = React.createRef();
    this.formRef = React.createRef();
  }

  state = {
    roleId: -1,
    tenantId: getTenantId(),
    tenant: getTenant(),
    visibleLoadTableModal: false,
    title: '全部成员',
    type: 1,
    isModalForm: false,
    initParams: {
      tenant: getTenant()
    },
    formCfg: {
      title: "编辑",
      okText: "确定",
      type: null,
      valType: "text",
      id: null,
    },
    roleList: [],
    titeType: {"a": "编辑", "b": "编辑", "c": "新增"},
    btnTextType: {"a": "保存", "b": "保存", "c": "保存"},
    //dict: null
  };


  openLoadTableModal = () => {
    this.setState({
      visibleLoadTableModal: true
    });
  };

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.init();
  };

  callback(_data: any) {
    this.setState({}, () => {
      this.init();
    });

  };

  init() {
    let params: any = this.state.initParams;

    this.setState({initParams: params}, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };

  showModal1(type: string) {
    this.setState({isModalForm: true}, () => {
      let _data: any = this.state.formCfg.data;
      if (type == "b") {
        let val = _data.value;
        this.formRef.current?.setFieldsValue({
          name: _data.name, id: _data.id, typeName: _data.typeName,
          description: _data.description, value: val
        });
      }
    });

  };

  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data,
        valType: _data.type || 'text',
        id: _data.id,
      }
    }, () => {
      this.showModal1(type);
    });
  };

  handleOk1 = () => {
    this.setState({isModalForm: false});
  };

  handleCancel1 = () => {
    this.setState({isModalForm: false});
  };
  //更新
  updateForm = async (value: any) => {
    let _data: any = {};
    let cfg: any = this.state.formCfg;
    if (cfg.type == "b") {
      _data.description = value.description;
      _data.value = value.value;
      _data.id = cfg.data.id;
      _data.name = value.name;
      _data.type = value.typeName;
      let result: any = null;
      if (_data.id) {
        let type = _.find(typeOptions, {label:_data.type})
        if (type) {
          _data.type = type.value;
        }
        result = await Api.put(`${apiPath}/${getTenant()}/subapp/SysConfig`, _data);
      } else {
        result = await Api.post(`${apiPath}/${getTenant()}/subapp/SysConfig`, _data);
      }
      if (result.success) {
        message.success("提交成功!");
        this.handleCancel1();
        this.init();
      }
    }
  }

  deleteClick = async (id: any) => {
    const apiDel: string = `${apiPath}/${getTenant()}/subapp/SysConfig`;
    let result:any = await Api.delete(apiDel, [id]);
    if (result.success) {
      this.init();
    }
  }

  render() {
    // api
    const apiGet: string = `${apiPath}/${getTenant()}/subapp/SysConfig/list`;

    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 80,
      },
      {
        title: "名称",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "描述",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "类型",
        dataIndex: "typeName",
        key: "typeName",
        width: 100,
      },
      {
        title: "值",
        dataIndex: "value",
        key: "value",
        render: (_, record: any) => (
          record.type == "password" ? "******" : record.value
        ),
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        render: (_, record: any) => (
          moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <>
            <Space size="middle">
              <Button type="link" onClick={() => {
                this.openForm("b", record)
              }}>
                编辑
              </Button>
              <Popconfirm placement="right" title={"确认删除吗？"} onConfirm={() => this.deleteClick(record.id)} okText="确定"
                          cancelText="取消">
                <Button type="link">删除</Button>
              </Popconfirm>
            </Space>
          </>
        ),
        width: 100,
      },
    ];


    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
      firstLoad: true
    };

    const valCompent: any = (_type: any) => {
      let type = _type;
      let Component: any = null;
      if (['text', 'textarea', 'richtext', 'select'].indexOf(type) > -1) {
        Component = <Input placeholder=""/>;
      }
      if (['password'].indexOf(type) > -1) {
        Component = <Input.Password placeholder=""/>;
      }
      if (['integer'].indexOf(type) > -1) {
        Component = <InputNumber precision={0} placeholder=""/>;
      }
      if (['radio'].indexOf(type) > -1) {
        Component = <Radio.Group>
          <Radio value={"true"}>是</Radio>
          <Radio value={"false"}>否</Radio>
        </Radio.Group>;
      }
      return Component;
    }

    return (
      <>
        <PageHeader className="site-page-header-main" title="系统配置"/>
        <Layout>
          <div className="x-main-box">
            <div style={{width: "100%", height: "50px"}}>
              <Button type="primary" onClick={() => this.openForm("b", {})} className="x-fl" style={{}}>
                新增
              </Button>
            </div>
            <TableList ref={this.tableRef} {...tableProps} />
            <Modal title={this.state.formCfg.title} open={this.state.isModalForm} onOk={() => this.showModal1}
                   onCancel={this.handleCancel1} footer={[]} getContainer=".x-sys-manage-wrapper">
              <Row>
                <Col className="gutter-row" md={24}>
                  <Form ref={this.formRef} labelCol={{span: 6}}
                        wrapperCol={{span: 18}} onFinish={this.updateForm} autoComplete='off'>

                    <Form.Item label="名称" name="name"
                               rules={[{required: true, message: "请输入!"}]}>
                      <Input placeholder="" disabled={!!this.state.formCfg.id}/>
                    </Form.Item>
                    <Form.Item label="类型" name="typeName"
                               rules={[{required: false, message: "请输入!"}]}>
                      <Select
                        disabled={!!this.state.formCfg.id}
                        options={typeOptions}
                        onChange={(value) => {
                          this.formRef.current.setFieldValue('value', null);
                          this.setState({
                            formCfg: {
                              ...this.state.formCfg,
                              valType: value,
                              data: {
                                ...this.formRef.current?.values,
                                value: null,
                              }
                            }
                          })
                        }}
                        getPopupContainer={getPopupContainer}
                      />
                    </Form.Item>
                    <Form.Item label="描述" name="description"
                               rules={[{required: false, message: "请输入!"}]}>
                      <Input placeholder=""/>
                    </Form.Item>
                    <Form.Item label="值" name="value"
                               rules={[{required: false, message: "请输入!"}]}>

                      {this.state.formCfg.valType && valCompent(this.state.formCfg.valType)}

                    </Form.Item>
                    <div style={{width: "100%", textAlign: "center"}}>
                      <Button onClick={this.handleCancel1}>取消</Button>
                      <Button className="x-ml10" type="primary" htmlType="submit">
                        {this.state.formCfg.okText}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Modal>
          </div>
        </Layout>
      </>
    );
  }
}

export default withRouter(SysConfig);
