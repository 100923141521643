import {Button, Col, message, Modal, PageHeader, Popconfirm, Row} from "antd";
import React, {useContext, useRef, useState} from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import XModal from "@byk/pages/components/XModal";
import TableList from "@byk/pages/components/TableList";
import {RoleContext} from "@byk/pages/AppSystemInit/RoleManager";
import {ColumnsType} from "antd/es/table";
import moment from "moment";
import Api from "@byk/api/Api";
import pattern from "@byk/utils/pattern";
import { apiPath } from "@byk/routes";
import { getTenant } from "utils/localStorage";



const Crud = (props:any) => {
  const isPt=props.isPt;
  const {applicationId, pageId, onToPage} = isPt?props:useContext(RoleContext);
  const searchRef = useRef<any>();
  const modalRef = useRef<any>();
  const tableRef = useRef<any>();
  const editModelRef = useRef<any>();
  const [mobileDisabled, setMobileDisabled] = useState<boolean>(false);
  const [roleData, setRoleData] = useState([]);

  const apiGet: string = `${apiPath}/${getTenant()}/subappService/SubappUser/list`;
  const apiPost: string = `${apiPath}/${getTenant()}/subapp/SubappUser`;
  const apiPut: string = `${apiPath}/${getTenant()}/subapp/SubappUser`;
  const apiDel: string = `${apiPath}/${getTenant()}/subapp/SubappUser`;
  const apiReset: string = `${apiPath}/${getTenant()}/subapp/admin/resetPasswdById`;
  const apiRoleGet: string = `${apiPath}/${getTenant()}/subappService/SubappRole/list`;

  useState(() => {
    const getRoles = async () => {
      let result: any = await Api.get(apiRoleGet, {
        appId: applicationId
      });
      if (result.success) {
        setRoleData(result.result);
        refreshTable();
      }
    }

    getRoles();
  })

  //表格配置
  const columns: ColumnsType = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => `${index + 1}`,
      width: 70,
      align: "center",
      fixed: 'left',
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "角色",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "创建人",
      dataIndex: "creator",
      key: "creator",
    },

    {
      title: "创建时间",
      dataIndex: "createDate",
      key: "createDate",
      render: (_, record: any) => (
        <>
          {moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}
        </>

      ),
    },
    {
      title: "操作",
      key: "action",
      render: (_, record: any) => (
        // <Space size="middle">
        <>
          <Button type="link" style={{display: record.name == "admin" ? "none" : ""}}
                  onClick={() => editClick(record)}>
            编辑
          </Button>
          <Popconfirm
            placement="left"
            title={"确认要重置密码吗？"}
            onConfirm={() => resetClick(record)}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link">重置密码</Button>
          </Popconfirm>
          {/* <Button type="link" onClick={() => this.EditClick(record)}>
            停用
          </Button> */}
          <Popconfirm
            placement="left"
            title={"确认要注销吗？"}
            onConfirm={() => deleteClick(record)}
            okText="确定"
            cancelText="取消"
          >
            <Button style={{display: record.name == "admin" ? "none" : ""}} type="link">注销</Button>
          </Popconfirm>
        </>
        // </Space>
      ),
      width: 250,
    },
  ];

  const tableProps = {
    columns: columns,
    api: apiGet,
    initParams: {
      appId: applicationId,
    },
    pagination: {
      currPage: 1,
      pageSize: 10,
    },
    firstLoad: true
  };

  //新增表单项
  const ModalConfig: any[] = [
    {
      itemType: "input",
      placeholder: "请输入",
      name: "name",
      label: "姓名",
      allowClear: true,
      col: 24,
      rules: [{required: true, message: "请输入!"}],
    },

    {
      itemType: "input",
      placeholder: "请输入",
      name: "phone",
      label: "手机号",
      allowClear: true,
      disabled: mobileDisabled,
      col: 24,
      rules: [
        {required: true, message: "请输入手机号!"},
        {
          pattern: pattern.mobile,
          message: "请输入正确的手机号"
        }],
    },
    {
      itemType: "select",
      placeholder: "请输入",
      name: "roleId",
      label: "角色",
      allowClear: true,
      col: 24,
      rules: [{required: true, message: "请输入!"}],
      data: roleData,
      fieldNames: {label: "roleName", value: "id"},
    },
  ];

  const reformData = (params?: any, type?: any) => {
    if (type == 0) {
      params.appId = applicationId;
    } else {
    }
    return params;
  };

  //查询项配置
  const searchConfig: any[] = [
    {
      itemType: "input",
      placeholder: "请输入",
      name: "name",
      label: "姓名",
      allowClear: true,
      col: 6,
    },
    {
      itemType: "input",
      placeholder: "请输入",
      name: "phone",
      label: "手机号",
      allowClear: true,
      col: 6,
    },
    {
      itemType: "select",
      placeholder: "请输入",
      name: "roleId",
      label: "角色",
      allowClear: true,
      col: 6,
      data: roleData,
      fieldNames: {label: "roleName", value: "id"}
    },
  ];

  //modal 表单配置
  const modalProps = {
    apiPost: apiPost,
    apiPut: apiPut,
    option: ModalConfig,
    refreshTable: (p: any) => {
      refreshTable(p);
    },
    reformData: reformData,
    isShowAdd: false
  };

  const searchProps = {
    option: searchConfig,
    onSearch: (p: any) => {
      refreshTable(p);
    },
    btnBoolean: true,
  };

  const add = () => {
    setMobileDisabled(false)
    modalRef.current?.add();
  }

  //编辑按钮
  const editClick = async (record: any) => {
    let result: any = await Api.get(apiPost, {id: record.id});
    if (result.success) {
      if (result.result.length > 0) {
        let {id, name, phone, roleId} = record;
        setMobileDisabled(true);
        modalRef.current?.edit({
          id, name, phone, roleId
        })
      }
    }
  };

  //重置密码
  const resetClick = async (record: any) => {
    let result: any = await Api.get(apiReset, {id: record.id, appId: applicationId});
    if (result.success) {
      message.success(result.message);
      refreshTable();
    }
  };

  const refreshTable = (params?: any) => {
    if (params == undefined) {
      params = {};
    }
    params.appId = applicationId;
    if (tableRef.current) {
      tableRef.current.getTableList(params);
    }
  };

  //删除
  const deleteClick = async (record: any) => {
    let {id} = record;
    let result: any = await Api.delete(apiDel, [id]);
    if (result.success) {
      refreshTable();
    }
  };

  return (
    <>
        <PageHeader className="site-page-header-main" title="用户管理"/>
        <div className="x-main-box">
          <SearchForm ref={searchRef} {...searchProps} />
          <Row>
            <Col span={24}>
              <Button className="x-fr" style={{float: "left"}} type="primary" onClick={() => add()}>
                新增
              </Button>
            </Col>
          </Row>
          <XModal ref={modalRef} {...modalProps} getContainer=".x-sys-manage-wrapper" />
          <TableList ref={tableRef} {...tableProps} />
        </div>

    </>
  );
}

export default Crud;
