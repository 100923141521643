import React from "react";
import TableList from "@byk/pages/components/TableList";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, Tooltip, Drawer, Form, Input, message, Row, Col, Select, Switch, Upload, Layout, PageHeader } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import moment from 'moment';
import UploadFile from "@byk/pages/components/UploadFile";
import {appTenantClass, downLoadClick} from "@byk/utils/Utils";
import localStorage, {getTenant, getTenantId} from "utils/localStorage";
import { apiPath } from "@byk/routes";
import XModal from "@byk/pages/components/XModal";
interface DataType {
  id: React.Key;
}

class MemberManagerList extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  tableAddRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.tableAddRef = React.createRef();
    this.formRef = React.createRef();
  }

  state = {
    roleId: -1,
    tenantId: getTenantId(),
    visibleLoadTableModal: false,
    title: '全部成员',
    type: 1,
    isModalForm: false,
    initParams: {
      desc: "createDate",
      tenant: getTenant(),
    },
    formCfg: {
      title: "编辑",
      okText: "确定",
      type: null
    },
    roleList: [],
    titeType: { "a": "编辑", "b": "编辑", "c": "新增" },
    btnTextType: { "a": "保存", "b": "保存", "c": "保存" },
    previewVisible: false,
    previewImage: '',
    fileList: [], //用来存图片列表
  };


  openLoadTableModal = () => {
    this.setState({
      visibleLoadTableModal: true
    });
  };
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.init();
  };
  callback(_data: any) {
    this.setState({}, () => {
      this.init();
    });

  };

  init() {
    let params: any = this.state.initParams;

    this.setState({ initParams: params }, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };
  showModal1(type: string) {
    this.setState({ isModalForm: true }, () => {
      let _data: any = this.state.formCfg.data;
      if (type == "b") {
        this.formRef.current?.setFieldsValue({
          filename: _data.filename, id: _data.id, ext: _data.ext,
          description: _data.description, value: _data.value
        });
      }
    });
  };

  downLoadFile = (code: any, filename: any) => {
    let url = `${apiPath}/${getTenant()}/file/` + code;
    downLoadClick(url, filename);
  }

  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data,
      }
    }, () => { this.showModal1(type); });
  };

  handleOk1 = () => {
    this.setState({ isModalForm: false });
  };

  handleCancel1 = () => {
    this.setState({ isModalForm: false });
  };
  //更新
  updateForm = async (value: any) => {
    let postUrl = `${apiPath}/${getTenant()}/rest/SysFile`;
    let result: any = await Api.post(postUrl, value);
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel1();
      this.init();
    }
  }

  render() {
    // api
    const apiGet: string = `${apiPath}/${getTenant()}/subapp/SysFile/list`;
    const apiDel: string = `${apiPath}/${getTenant()}/subapp/SysFile`;
    const apiUserGet: string = `${apiPath}/${getTenant()}/subapp/SysFile/list`;

    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };

    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 80,
      },
      {
        title: "文件名",
        dataIndex: "filename",
        key: "filename",
        render: (_: any, record: any) => (
          <>
            <Button type="link" onClick={() => { this.downLoadFile(record.code, record.filename) }}>
              {record.filename}
            </Button>

          </>
        ),
      },
      {
        title: "扩展名",
        dataIndex: "ext",
        key: "ext",
        width: 150,
      },
      {
        title: "上传人",
        dataIndex: "creator",
        key: "creator",
        width: 100,
      },
      {
        title: "存储方式",
        dataIndex: "storeMode",
        key: "storeMode",
        width: 100,
      },
      {
        title: "文件类型",
        dataIndex: "type",
        key: "type",
        width: 100,
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        render: (_, record: any) => (
          moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <>
            <Space size="middle">
              <Button type="link" onClick={() => { this.downLoadFile(record.code, record.filename) }}>
                下载
              </Button>
              <Popconfirm
                placement="left"
                title={"确认删除？"}
                onConfirm={() => deleteClick(record)}
                okText="删除"
                cancelText="取消"
              >
                <Button type="link">删除</Button>
              </Popconfirm>
            </Space>
          </>
        ),
        width: 160,
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      getCheckboxProps: (record: DataType) => ({
        // disabled: record.name === 'Disabled User', // Column configuration not to be checked
        // name: record.name,
      }),
    };

    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
      firstLoad: true
    };

    const setUploadData = (data: any) => {
      this.formRef.current.setFieldsValue(data);
    }
    const setLogoRef = (url: string, isClear: boolean = false) => {

    }

    return (
      <>
      <PageHeader className="site-page-header-main" title="文件管理"/>
      <Layout>
        <div className="x-main-box">
          <div style={{ width: "100%", height: "50px" }}>
            <Button type="primary" onClick={() => this.openForm("c", {})} className="x-fl" style={{}}>
              新增
            </Button>
          </div>
          <TableList ref={this.tableRef} {...tableProps} />

          <Modal destroyOnClose={true} title={this.state.formCfg.title} open={this.state.isModalForm} onOk={() => this.showModal1} onCancel={this.handleCancel1} footer={[]} getContainer=".x-sys-manage-wrapper">
            <Row>
              <Col className="gutter-row" md={24}>
                <Form ref={this.formRef} labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }} onFinish={this.updateForm}>
                  <Form.Item label="选择文件" name="data"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <UploadFile onEmit={(data: string) => {
                      setUploadData(data);
                    }} onEmitClear={() => {
                      setLogoRef("", true);
                    }} logo={""} />

                  </Form.Item>
                  <Form.Item label="文件名称" name="filename"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <Input placeholder="" disabled />
                  </Form.Item>
                  <Form.Item label="文件类型" name="type"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <Input placeholder="" disabled />
                  </Form.Item>
                  <Form.Item label="扩展名" name="ext"
                    rules={[{ required: false, message: "请输入!" }]}>
                    <Input placeholder="" disabled />
                  </Form.Item>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Button onClick={this.handleCancel1}>取消</Button>
                    <Button className="x-ml10" type="primary" htmlType="submit">
                      {this.state.formCfg.okText}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      </Layout>
      </>
    );
  }
}
export default withRouter(MemberManagerList);
