import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {ModalWrapper} from "../styled";
import ModelBizDataQuery from "@byk/pages/modeling/ModelData/ModalBizDataQuery";

const DmdModal: React.FC<any> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  const {addToForm} = props;
  const [isModalOpen, setIsModalOpen] = useState(false)
  const modelBizDataQueryRef = useRef<any>();
  const crudFormRef = useRef<any>();
  const [selectedId, setSelectedId] = useState<any>();

  const showModal = (m: any, ids: any = []) => {
    setTimeout(() => {
      modelBizDataQueryRef.current.init(m, ids);
      if (addToForm && ids && ids.length == 1) {
        setSelectedId(ids[0]);
        modelBizDataQueryRef.current.search4UiForm({id:ids[0]});
      }
      setIsModalOpen(true)
    }, modelBizDataQueryRef.current ? 0 : 100);
  }

  const handleCancel = () => {
    modelBizDataQueryRef.current.destroy();
    setIsModalOpen(false);
  }

  const handleOk = () => {
    modelBizDataQueryRef.current.onSelectedData();
    handleCancel();
  }

  return (
    <ModalWrapper
      maskClosable={false}
      forceRender={true}
      title={`${addToForm ?'选择关联数据' : '选择模型数据'}`}
      open={isModalOpen}
      width={'80%'}
      style={{top: '20px', bottom: '20px',}}
      bodyStyle={{height: 'calc(100vh - 217px)', padding: "15px"}}
      cancelText={'取消'}
      okText={"确定"}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <ModelBizDataQuery
        ref={modelBizDataQueryRef}
        addToScript={props.addToScript}
        addToForm={props.addToForm}
        selectedId={selectedId}
      />
    </ModalWrapper>
  )
})

export default DmdModal;
