/**
 * 关联属性
 */
export enum RelAttrType {
  REF_ATTR_TYPE = 'related',
}

/**
 * 属性类型
 */
export enum PROPERTY_REL_TYPE {
  O2M = 'o2m',
  O2O = 'o2o',
  M2O = 'm2o',
  M2M = 'm2m',
  RELATED = 'related',
}

export const PROPERTY_REL_OPTIONS = [
  // {label: '一对一', value: 'o2o'},
  {label: '一对多', value: 'o2m'},
  {label: '多对一', value: 'm2o'},
  // {label: '多对多', value: 'm2m'},
];

export function getPropertyRelTypeDes(type: any) {
  if (PROPERTY_REL_TYPE.O2M == type) {
    return "一对多";
  } else if (PROPERTY_REL_TYPE.M2O == type) {
    return "多对一";
  } else {
    return "";
  }
}

// 系统默认属性
export const SysDefaultProperty = ['creator', 'createDate', 'updater', 'updateDate', 'tenant', 'ipAddress'];
