import IconSVG from "./icon.svg";
import Widget from "./widget";
import { DynamicHeight } from "utils/WidgetFeatures";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XTiming",
  iconSVG: IconSVG,
  dynamicHeight: DynamicHeight.AUTO_HEIGHT,
  defaults: {
    value: "",
    isAutoTimes: false,
    times: 0,
    timesOpt: [0,5,10,30,60],
    rows: 3,
    columns: 14,
    widgetName: "XTiming",
    version: 1,
    isVisible:true,
    iconName: "Refresh",
    borderRadius:15,
    color:"#fafafa",
    minWidth: 120
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  }
};

export default Widget;
