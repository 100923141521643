import React, {useEffect, useState} from 'react';
import { AtGrid } from 'taro-ui';
import styled from "styled-components";
const AtGridWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`
const TGridWidget = (props:any) => {
  const {currentData, onItemClick, columnNum} = props;
  const [gridData, setGridData] = useState([]);
  useEffect(()=>{
    if(currentData&&currentData.length > 0){
      setGridData(currentData.map((i:any)=>{
        return {
          ...i,
          image: i.url,
          value: i.description
        }
      }))
    }else{
      setGridData([]);
    }
  }, [currentData]);
  return (
    <AtGridWrapper>
      <AtGrid columnNum={columnNum} data={gridData} onClick={(item:any)=>{
        onItemClick&&onItemClick(item);
      }} />
    </AtGridWrapper>
  );
};

export default TGridWidget;
