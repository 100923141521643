import React, {useEffect, useState} from 'react';
import {AtInput} from 'taro-ui';
import styled from "styled-components";
import {LabelWidget} from "../../index";
const TInputWidget = (props:any) => {
  const {label, value:wValue, updateWidgetProperty, inputType, isRequired, regex,
    fontSize,
    color,
    fontStyle,
    inputFontSize,
    inputColor,
    inputFontStyle,
    bgColor,
    borderColor} = props;
  const [value, setValue] = useState(wValue);
  const [status, setStatus] = useState(false);
  const handleChange = (value:any)=> {
    setValue(value);
    if(value){
      if(regex){
        const pattern = new RegExp(`${regex}`);
        setStatus(!pattern.test(value));
      }
    }
    updateWidgetProperty&&updateWidgetProperty(value);
  };
  useEffect(()=>{
    setValue(wValue);
  }, [wValue]);
  return (
    <LabelWidget
      label={label}
      fontSize={fontSize}
      color={color}
      fontStyle={fontStyle}
      inputFontSize={inputFontSize}
      inputColor={inputColor}
      inputFontStyle={inputFontStyle}
      bgColor={bgColor}
      borderColor={borderColor}
    >
      <AtInput
        error={status}
        required={isRequired}
        name='value'
        type={inputType}
        placeholder='请输入'
        value={value}
        onChange={handleChange}
      />
    </LabelWidget>
  );
};

export default TInputWidget;
