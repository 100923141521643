import type { IconName } from "@blueprintjs/icons";
import React from "react";

import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { WidgetType } from "constants/WidgetConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";

import { IconNames } from "@blueprintjs/icons";
import type { ButtonVariant } from "components/constants";
import { ButtonVariantTypes } from "components/constants";
import type { Stylesheet } from "entities/AppTheming";
import IconButtonComponent from "../component";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import type { AutocompletionDefinitions } from "widgets/constants";

export interface IconButtonWidgetProps extends WidgetProps {
  iconName?: IconName;
  iconSize?: number;
  backgroundColor: string;
  buttonVariant: ButtonVariant;
  borderRadius: string;
  boxShadow: string;
  boxShadowColor: string;
  isDisabled: boolean;
  isVisible: boolean;
  onClick?: string;
}

class IconButtonWidget extends BaseWidget<IconButtonWidgetProps, WidgetState> {
  static getPropertyPaneEventConfig(){
    return super.getWidgetEvents('IconButtonWidget');
  }
  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "iconName",
            label: "图标",
            helpText: "Sets the icon to be used for the icon button",
            controlType: "ICON_SELECT",
            defaultIconName: "plus",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                default: "",
              },
            },
          },
          {
            helpText: "Show helper text with button on hover",
            propertyName: "tooltip",
            label: "提示",
            controlType: "INPUT_TEXT",
            placeholderText: "Add Input Field",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "isVisible",
            helpText: "Controls the visibility of the widget",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isDisabled",
            helpText: "Disables input to the widget",
            label: "是否禁用",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "animateLoading",
            label: "加载动画",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "按钮样式",
        children: [
          {
            propertyName: "buttonVariant",
            label: "按钮类型",
            controlType: "ICON_TABS",
            fullWidth: true,
            helpText: "Sets the variant of the icon button",
            options: [
              {
                label: "主要",
                value: ButtonVariantTypes.PRIMARY,
              },
              {
                label: "次要",
                value: ButtonVariantTypes.SECONDARY,
              },
              {
                label: "文字",
                value: ButtonVariantTypes.TERTIARY,
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: [
                  ButtonVariantTypes.PRIMARY,
                  ButtonVariantTypes.SECONDARY,
                  ButtonVariantTypes.TERTIARY,
                ],
                default: ButtonVariantTypes.PRIMARY,
              },
            },
          },
          {
            propertyName: "buttonColor",
            helpText: "Sets the style of the icon button",
            label: "按钮颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            hidden: (props: any) =>
              props.buttonVariant === ButtonVariantTypes.TERTIARY,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "iconColor",
            helpText: "Sets the style of the icon button",
            label: "图标颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            helpText: "50~100",
            propertyName: "iconSize",
            label: "图标大小",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 50,
            max: 100,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
        ],
      },
      // {
      //   sectionName: "颜色",
      //   children: [
      //     {
      //       propertyName: "buttonColor",
      //       helpText: "Sets the style of the icon button",
      //       label: "按钮颜色",
      //       controlType: "COLOR_PICKER",
      //       isJSConvertible: true,
      //       isBindProperty: true,
      //       isTriggerProperty: false,
      //       validation: {
      //         type: ValidationTypes.TEXT,
      //         params: {
      //           regex: /^(?![<|{{]).+/,
      //         },
      //       },
      //     },
      //     {
      //       propertyName: "iconColor",
      //       helpText: "Sets the style of the icon button",
      //       label: "图标颜色",
      //       controlType: "COLOR_PICKER",
      //       isJSConvertible: true,
      //       isBindProperty: true,
      //       isTriggerProperty: false,
      //       validation: {
      //         type: ValidationTypes.TEXT,
      //         params: {
      //           regex: /^(?![<|{{]).+/,
      //         },
      //       },
      //     },
      //   ],
      // },
      {
        sectionName: "边框&阴影",
        children: [
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      buttonColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  getPageView() {
    const {
      borderRadius,
      boxShadow,
      buttonColor,
      buttonVariant,
      iconName,
      isDisabled,
      isVisible,
      tooltip,
      widgetId,
      iconColor,
      iconSize
    } = this.props;

    return (
      <IconButtonComponent
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        buttonColor={buttonColor}
        iconColor={iconColor}
        iconSize={iconSize}
        buttonVariant={buttonVariant}
        hasOnClickAction={!!this.props.onClick}
        height={
          (this.props.bottomRow - this.props.topRow) * this.props.parentRowSpace
        }
        iconName={iconName}
        isDisabled={isDisabled}
        isVisible={isVisible}
        onClick={this.handleClick}
        renderMode={this.props.renderMode}
        tooltip={tooltip}
        widgetId={widgetId}
        width={
          (this.props.rightColumn - this.props.leftColumn) *
          this.props.parentColumnSpace
        }
      />
    );
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc":
        "Icon button widget is just an icon, along with all other button properties.",
      "!url": "https://docs.appsmith.com/widget-reference/icon-button",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getWidgetType(): WidgetType {
    return "ICON_BUTTON_WIDGET";
  }

  handleClick = () => {
    const { onClick } = this.props;

    if (onClick) {
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      });
    }
  };
}

export default IconButtonWidget;
