import React, {createContext, useEffect, useState} from "react";
import history from 'utils/history';
import RoleList from './RoleList';
import RoleAdd from "./RoleAdd";
import AppUsers from '../UserManager/main';
import SysConfig from '@byk/pages/ApplicationManager/SysConfig/main';
import SysFile from '@byk/pages/ApplicationManager/SysFile/main';
import ScheduledTaskManagement from '@byk/pages/ApplicationManager/ScheduledTaskManagement/main';
import SysInternationalize from '@byk/pages/ApplicationManager/Internationalize/main';
import DictManager from '@byk/pages/AppSystemInit/DictManager/index';
import styled from "styled-components";
import { routePath } from "@byk/routes";
import {useSelector} from "react-redux";
import {getIsAppModeEdit} from "../../../../selectors/entitiesSelector";
import AppThemingApi from "../../../../api/AppThemingApi";
import MainLog from "@byk/pages/ApplicationManager/LogManagement/mainLog";

const Wrapper = styled.div<{isAppModeEdit:any, theme:any}>`
  width: 100%;
  .x-main-box{
    width: 100%;
  }
  ${
    ({isAppModeEdit, theme})=> isAppModeEdit||!theme.colors?``:
      `
        .ant-btn-primary{
          &:hover{ 
            opacity: .8;
          }
        }
        .ant-btn-primary,
        .ant-switch-checked,
        .ant-tabs-ink-bar,
        .x-internationalize .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
          background-color: ${theme.colors.primaryColor}!important;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
          background-color: ${theme.colors.primaryColor}20!important;
        }
        .ant-btn-link,
        .ant-btn-default:hover,
        .ant-pagination-item-active a,
        .x-menu-active,
        .ant-tabs-tab-active .ant-tabs-tab-btn,
        .ant-tabs-tab:hover,
        .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover,
        .ant-table-row-expand-icon:focus-visible, .ant-table-row-expand-icon:hover,
        .x-step-text,
        .ant-timeline-item-head-blue{
          color: ${theme.colors.primaryColor}!important;
        }
        .ant-input:hover,.ant-input:focus, .ant-input-focused,
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
        .ant-switch-checked,
        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
          box-shadow: none!important;
        }
        .ant-pagination-item-active,
        .ant-btn-primary,
        .ant-btn-default:hover,
        .ant-input:hover,.ant-input:focus, .ant-input-focused,
        .ant-pagination-item-active a,
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
        .x-menu-active,
        .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
        .x-internationalize .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
        .ant-timeline-item-head-blue{
          border-color: ${theme.colors.primaryColor}!important;
        }
        .x-menu-normal{
          height: 34px;
        }
        .ant-btn-icon-only:hover{
          border: none;
        }
        .ant-click-animating-node{
          animation: none;
        }
        .ant-form-item-label label{
          color: ${theme.form.label.fontColor}!important;
          font-size: ${theme.form.label.fontSize}!important;
          font-style: ${theme.form.label.fontStyle.indexOf('ITALIC')>=0?'italic':'normal'}!important;
          font-weight: ${theme.form.label.fontStyle.indexOf('BOLD')>=0?'bold':'none'}!important;
        }
        .ant-form{
          .ant-input, .ant-select-selection-item{
            color: ${theme.form.inputText.fontColor}!important;
            font-size: ${theme.form.inputText.fontSize}!important;
            font-style: ${theme.form.inputText.fontStyle.indexOf('ITALIC')>=0?'italic':'normal'}!important;
            font-weight: ${theme.form.inputText.fontStyle.indexOf('BOLD')>=0?'bold':'none'}!important;
          }
          .ant-input:hover,.ant-input:focus, .ant-input-focused,
          .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
            border-color: ${theme.form.inputBorder.bgBorderColor}!important;
            background-color: ${theme.form.inputBorder.bgColor}!important;
          }
        }
      `
  }
`;
export const getPopupContainer= () => document.getElementById('x-sys-manage-wrapper')  as HTMLElement;
export const RoleContext = createContext({
  applicationId: '',
  pageId: '',
  onToPage: (pageId:any, pageName:string, params?:string)=> {},
  getPopupContainer: () => document.body
});
function RoleManager(props:any){
  const {applicationSlug:applicationId, pageId} = props.match.params;
  const isAppModeEdit = useSelector(getIsAppModeEdit);
  const [theme, setTheme] = useState({});
  const toPage = (pageId:any, pageName:string, params:string='')=>{
    if(pageId=='back'){
      history.goBack();
    }else{
      history.push(`${routePath}/app/${applicationId}/${pageName}-${pageId}/system?${params}`);
    }
  };
  useEffect(()=>{
    AppThemingApi.fetchSelected(applicationId).then((res:any)=>{
      if(res.success&&res.result?.[0]){
        setTheme(res.result[0].properties);
      }
    });
    if(!isAppModeEdit){
      const root = document.querySelector("#root");
      if(root){
        let cls = 'x-preview-body';
        if(root.className){
           cls =  root.className+ " " + cls;
        }
        root.className = cls;
      }
    }
  }, []);
  const PageIdToPage:any = {
    "9999": <RoleList  isPt={false}/>,
    "9998": <RoleAdd  isPt={false}/>,
    "9997": <AppUsers isPt={false} />,
    "9996": <SysConfig />,
    "9995": <SysFile />,
    "9994": <ScheduledTaskManagement />,
    "9993": <SysInternationalize />,
    "9992": <DictManager hideHead={true} hideMenu={true} />,
    "9991": <MainLog />,
  }
  return (
    <RoleContext.Provider value={{
      applicationId,
      pageId,
      onToPage: toPage,
      getPopupContainer: () => document.getElementById('x-sys-manage-wrapper')  as HTMLElement
    }}>
      <Wrapper className="x-sys-manage-wrapper" id="x-sys-manage-wrapper" isAppModeEdit={isAppModeEdit} theme={theme}>
        {
          PageIdToPage[pageId]
        }
      </Wrapper>
    </RoleContext.Provider>
  )
}
export default RoleManager;
