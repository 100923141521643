import React from "react";
import TableList from "@byk/pages/components/TableList";
import type {ColumnsType} from "antd/es/table";
import {Button, Col, Form, Input, message, Modal, Popconfirm, Row, Select, Space, Tag} from "antd";
import Api from "@byk/api/Api";

import {withRouter} from "react-router-dom";

import {ExclamationCircleOutlined} from '@ant-design/icons';

import localStorage, {getAppId, getProjectId, getTenant, getTenantId} from "utils/localStorage";
import _ from "lodash";
import { apiPath } from "@byk/routes";
import XModal from "@byk/pages/components/XModal";
import {getPopupContainer} from "@byk/pages/AppSystemInit/RoleManager";
import {appTenantClass} from "@byk/utils/Utils";

interface DataType {
  id: React.Key;
}

class MemberManagerList extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  tableAddRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.tableAddRef = React.createRef();
    this.formRef = React.createRef();
  }

  state = {
    tenantId: getTenantId(),
    visibleLoadTableModal: false,
    title: '全部成员',
    type: 1,
    isModalForm: false,
    initParams: {
      projectId: getProjectId(),
      asc: "sort,createDate",
    },
    formCfg: {
      title: "",
      okText: "确定",
      type: null
    },
    roleList: [],
    titeType: { "u": "编辑", "c": "新增", "cc": "新增" },
    btnTextType: { "u": "保存", "c": "保存", "cc": "保存" },
    dict: null,
    appId: getAppId(),
    languageOpt:[],
    projectId: getProjectId(),
  };

  openLoadTableModal = () => {
    this.setState({
      visibleLoadTableModal: true
    });
  };
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.bindLang();
    this.init();
  };
  callback(_data: any) {
    // this.setState({ dict: _data }, () => {
    //   this.init();
    // });

  };
  async bindLang() {
    let res: any = await Api.get(`${apiPath}/${getTenant()}/i18n/allLocales`);
    if (res.success) {
      let j=res.ext.allLocales;
      let _data: any = [];
      _.each(j,(i:any)=>{
        for (let key in i) {
          //if (i.hasOwnProperty(key)) {  // 确保键在对象自身上，而不是从原型链继承
            _data.push({label:key,value:i[key]})
          //}
          //console.log("{"+key+","+i[key]+"}");
        }
      })
      console.log("_data",_data);
      this.setState({ languageOpt: _data });
    }
  };
  langChange = (v: any) => {
    let obj:any=this.state.languageOpt.filter((item:any)=>{
      return item.value==v;
    });
    let data:any={}
    if(obj.length>0){
      let langList=v.split("_");
      data={ languageName: obj[0].label, language: langList[0], region:langList[1]  };
    }
    else{
      data={ languageName: "", language:"", region:"" };
    }

    this.formRef.current?.setFieldsValue(data);
  };
  init() {
    let params: any = this.state.initParams;
    //params.dict = this.state.dict;
    this.setState({ initParams: params }, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };
  showModal1(type: string) {
    this.setState({ isModalForm: true }, () => {
      let _data: any = this.state.formCfg.data;
      if (type == "c" || type == "cc") {
        this.formRef.current?.setFieldsValue({ languageName: "", language: "", region: "",localeString:"" });
      }
      if (type == "u") {
        this.formRef.current?.setFieldsValue({ languageName: _data.languageName, language: _data.language,
           region: _data.region,localeString:_data.localeString });
      }
    });
  };

  openForm(type: string, _data: any) {
    this.setState({
      formCfg: {
        title: this.state.titeType[type],
        okText: this.state.btnTextType[type],
        type: type,
        data: _data,
      }
    }, () => { this.showModal1(type); });

    //this.formRef.current.resetFields();
  };
  handleOk1 = () => {
    this.setState({ isModalForm: false });
  };

  handleCancel1 = () => {
    this.setState({ isModalForm: false });
  };
  //更新
  updateForm = async (value: any) => {
    let postUrl = `${apiPath}/${getTenant()}/subapp/SubappLocale`;
    let _data: any = {};
    let cfg: any = this.state.formCfg;
    let result: any = null;
    //_data.sort=999999;
    if (cfg.type == "c" || cfg.type == "cc") {
      _data.languageName = value.languageName;
      _data.language = value.language;
      _data.region = value.region;
      _data.localeString = value.localeString;
      _data.projectId = this.state.projectId;
      if (cfg.type == "cc") {
        _data.sort=(cfg.data.sort||0);
        _data.sorts=0;
        //_data.sorts=1;
      }
      result = await Api.post(postUrl, _data);
    }
    if (cfg.type == "b" || cfg.type == "u") {
      _data.id = cfg.data.id;
      _data.languageName = value.languageName;
      _data.language = value.language;
      _data.localeString = value.localeString;
      _data.region = value.region;
      _data.projectId = this.state.projectId;
      result = await Api.put(postUrl, _data);
    }
    console.log("_data", _data);
    if (result.success) {
      message.success("提交成功!");
      this.handleCancel1();
      this.init();
    }
    else {
      message.error(result.message);
    }

  }

  render() {
    // api
    const apiGet: string = `${apiPath}/${getTenant()}/subapp/SubappLocale/list`;
    const apiPost: string = `${apiPath}/${getTenant()}/subapp/SubappLocale`;
    const apiPut: string = `${apiPath}/${getTenant()}/subapp/SubappLocale`;
    const apiDel: string = `${apiPath}/${getTenant()}/subapp/SubappLocale`;
    const apiUserGet: string = `${apiPath}/${getTenant()}/subapp/SubappLocale/list`;
    //表格refresh
    const refreshTable = (params: any = {}) => {

    };

    const onClose = () => {
      this.setState({ open: false });
    };


    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };

    //前移
    const MoveClick = async (record: any, type: any) => {
      let _data: any = {};
      let sorts = -1;
      if (type == 2) {
        sorts =  1
      }
      _data.id = record.id;
      _data.sorts = sorts;
      let result: any = await Api.put(apiPost, _data);
      if (result.success) {
        this.init();
      }
    };

    //驳回
    const rejectClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(apiDel, [id]);
      if (result.success) {
        this.init();
      }
    };
    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 80,
      },
      {
        title: "语种",
        dataIndex: "languageName",
        key: "languageName",
      },

      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          <>
            <Space size="middle">
              <Button type="link" onClick={() => { MoveClick(record, 1) }}>
                前移
              </Button>
              <Button type="link" onClick={() => { MoveClick(record, 2) }}>
                后移
              </Button>
              <Button type="link" onClick={() => { this.openForm("cc", record) }}>
                增行
              </Button>
              <Button type="link" onClick={() => { this.openForm("u", record) }}>
                编辑
              </Button>
              <Popconfirm placement="left" title={"确认删除吗？"} onConfirm={() => deleteClick(record)} okText="确定" cancelText="取消">
                <Button type="link" style={{ color: "red" }}>
                  删除
                </Button>
              </Popconfirm>
            </Space>
          </>
        ),
        width: 300,
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      getCheckboxProps: (record: DataType) => ({
        // disabled: record.name === 'Disabled User', // Column configuration not to be checked
        // name: record.name,
      }),
    };

    const tableProps = {
      columns: columns,
      api: apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
      firstLoad: true
    };


    const handleCancel = () => {
      this.setState({ visibleLoadTableModal: false });
    }
    return (
      <>
        <div className="x-main-box-in">
          <div style={{ width: "100%", marginBottom: "20px",display:"block" }}>
            <Button type="primary" onClick={() => this.openForm("c", {})} className="x-fl" >
              新增
            </Button></div>

            <Tag style={{margin:"40px 0px 10px 0px",display:"block",lineHeight:"30px"}}  icon={<ExclamationCircleOutlined /> } color="default">
              请先在此处完成语种维护后，再前往菜单国际化、页面国际化、文本国际化中完成语言配置
            </Tag>
          <TableList ref={this.tableRef} {...tableProps} />
          <Modal title={this.state.formCfg.title} open={this.state.isModalForm} onOk={() => this.showModal1} onCancel={this.handleCancel1} footer={[]}  getContainer=".x-sys-manage-wrapper">
            <Row>
              <Col className="gutter-row" md={24}>
                <Form ref={this.formRef} labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }} onFinish={this.updateForm}>
                  <Form.Item label="语种" name="localeString"
                    rules={[{ required: true, message: "请选择!" }]}>
                     <Select
                    style={{ width: "100%" }}
                    allowClear
                    autoFocus
                    filterOption={true}
                    options={this.state.languageOpt}
                    fieldNames={{ label: "label", value: "value" }}
                    onChange={(v: any) => { this.langChange(v) }}
                    getPopupContainer={getPopupContainer}
                  />
                  </Form.Item>
                  <Form.Item label="语言名称" name="languageName"
                    rules={[{ required: true, message: "请输入!" }]}>
                    <Input placeholder="请选择语种"  disabled/>
                  </Form.Item>
                  <Form.Item label="语言" name="language"
                    rules={[{ required: true, message: "请输入!" }]}>
                    <Input placeholder="请选择语种" disabled/>
                  </Form.Item>
                  <Form.Item label="国家缩写" name="region"
                    rules={[{ required: true, message: "请输入!" }]}>
                    <Input placeholder="请选择语种" disabled/>
                  </Form.Item>

                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Button onClick={this.handleCancel1}>取消</Button>
                    <Button className="x-ml10" type="primary" htmlType="submit">
                      {this.state.formCfg.okText}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}
export default withRouter(MemberManagerList);
