import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TButtonWidget from "../component";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";

class TButton extends TaroWidget<TButtonWidgetProps, WidgetState> {
  constructor(props: TButtonWidgetProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  static getPropertyPaneEventsConfig() {
    return super.getWidgetEvents("AtButtonWidget");
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      },
      {
        sectionName: "设置",
        children: [
          {
            propertyName: "isDisabled",
            label: "是否禁用",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "btnLoading",
            label: "载入状态",
            helpText: "Loading input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "按钮样式",
        children: [
          {
            propertyName: "buttonVariant",
            label: "类型",
            controlType: "ICON_TABS",
            fullWidth: true,
            helpText: "Sets the variant of the icon button",
            options: [
              {
                label: "主要按钮",
                value: "primary",
              },
              {
                label: "次要按钮",
                value: "secondary",
              }
            ],
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: [
                  "primary",
                  "secondary"
                ],
                default: "primary",
              },
            },
          },
          {
            propertyName: "buttonSize",
            label: "大小",
            controlType: "ICON_TABS",
            fullWidth: true,
            helpText: "Sets the variant of the icon button",
            options: [
              {
                label: "默认",
                value: "normal",
              },
              {
                label: "小",
                value: "small",
              }
            ],
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: [
                  "normal",
                  "small"
                ],
                default: "normal",
              },
            },
          },
          {
            propertyName: "isCircle",
            label: "圆角",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.BOOLEAN,
            },
          },
          {
            propertyName: "fontSize",
            label: "文字大小(单位:px)",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 12,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "color",
            label: "颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "bgColor",
            label: "背景颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          }
        ],
      },
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.label }}`
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      bgColor: "{{appsmith.theme.colors.primaryColor}}",
      color: "{{appsmith.theme.colors.textColor}}",
      boxShadow: "none",
    };
  }
  onClick(){
    super.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: this.props.onClick,
      event: {
        type: EventType.ON_CLICK
      },
    });
  }

  getPageView() {
    return (
      <TButtonWidget {...this.props} onClick={this.onClick} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_BUTTON_WIDGET";
  }
}

export interface TButtonWidgetProps extends WidgetProps {
  value: any[];
  label: string;
  onClick?: any;
  borderRadius: string;
  boxShadow?: string;
}

export default TButton;
