import {Alignment} from "@blueprintjs/core";
import {LabelPosition} from "components/constants";
import {FILL_WIDGET_MIN_WIDTH} from "constants/minWidthConstants";
import {ResponsiveBehavior} from "utils/autoLayout/constants";
import {DynamicHeight} from "utils/WidgetFeatures";

import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "MultiSelect",
  iconSVG: IconSVG,
  needsMeta: true,
  searchTags: ["dropdown", "tags"],
  features: {
    dynamicHeight: {
      sectionIndex: 0,
      defaultValue: DynamicHeight.AUTO_HEIGHT,
      active: true,
    }
  },
  defaults: {
    rows: 3,
    columns: 28,
    animateLoading: true,
    labelText: "Label",
    labelPosition: LabelPosition.Left,
    labelAlignment: Alignment.RIGHT,
    labelWidth: 10,
    selectId: null,
    options: [],
    widgetName: "MultiSelect",
    isFilterable: true,
    serverSideFiltering: false,
    defaultOptionValue: [],
    defaultOptionLabel: [],
    version: 1,
    isRequired: false,
    isDisabled: false,
    isReadonly: false,
    placeholderText: "请选择",
    responsiveBehavior: ResponsiveBehavior.Fill,
    minWidth: FILL_WIDGET_MIN_WIDTH,
  },

  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    disabledPropsDefaults: {
      labelPosition: LabelPosition.Left,
      labelTextSize: "0.875rem",
    },
    autoDimension: {
      height: true,
    },
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "160px",
          };
        },
      },
    ],
    disableResizeHandles: {
      vertical: true,
    },
  },
};

export default Widget;
