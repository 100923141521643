import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XEchartsMap",
  iconSVG: IconSVG,
  defaults: {
    rows: 70,
    columns: 120,
    widgetName: "XEchartsMap",
    version: 1,
    label: "label",
    isDownload: false,
    isDataView: false,
    isTitle: true,
    isLegend: true,
    title: "武汉佰钧成人力外包派遣分布图",
    subtext: '数据由人力资源管理部门提供',
    sublink: 'http://www.bill-jc.com',
    dataText: "人员数量",
    boundaryColor: "#064f85",
    landColor: "#004981",
    waterColor: "#044161",
    fontColor:'#fafafa',
    borderWidth:1,
    radius: 50,
    currentData: [
      {
        center: [
          104.114129,
          37.550339
        ],
        data: [
          {
            "name": "海门",
            "value": [
              121.15,
              31.89,
              119
            ]
          }
        ]
      }
    ]
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
