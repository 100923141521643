import React, {useEffect, useState} from 'react';
import {AtSwitch} from "taro-ui";
import {LabelWidget} from "../../index";

const TSwitchWidget = (props:any) => {
  const {label, value:wValue, border, isDisable, updateWidgetProperty,
    fontSize,
    color,
    bgColor,
    fontStyle} = props;
  const [value, setValue] = useState<boolean>(true);
  useEffect(()=>{
    setValue(wValue)
  }, [wValue]);
  return (
    <LabelWidget label={label} fontSize={fontSize}
                 color={color}
                 fontStyle={fontStyle} bgColor={bgColor}>
      <AtSwitch
        border={border}
        disabled={isDisable}
        checked={value}
        color={bgColor}
        onChange={(v:boolean)=>{
          setValue(v);
          updateWidgetProperty&&updateWidgetProperty(v);
        }} />

    </LabelWidget>
  );
};

export default TSwitchWidget;
