import { ValidationTypes } from "constants/WidgetValidation";

export default {
  editableTitle: true,
  titlePropertyName: "label",
  panelIdPropertyName: "id",
  updateHook: (props: any, propertyPath: string, propertyValue: string) => {
    return [
      {
        propertyPath,
        propertyValue,
      },
    ];
  },
  contentChildren: [
    {
      sectionName: "基本",
      children: [
        {
          propertyName: "label",
          helpText: "Sets the label of a menu item",
          label: "标签",
          controlType: "INPUT_TEXT",
          placeholderText: "Download",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
      ],
    },
    {
      sectionName: "一般",
      children: [
        {
          propertyName: "isVisible",
          helpText: "Controls the visibility of the widget",
          label: "是否可见",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          propertyName: "isDisabled",
          helpText: "Disables input to the widget",
          label: "是否禁用",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
      ],
    },
  ],
  styleChildren: [
    {
      sectionName: "图标",
      children: [
        {
          propertyName: "iconName",
          label: "图标",
          helpText: "Sets the icon to be used for a menu item",
          controlType: "ICON_SELECT",
          isBindProperty: false,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "iconAlign",
          label: "位置",
          helpText: "Sets the icon alignment of a menu item",
          controlType: "ICON_TABS",
          fullWidth: false,
          options: [
            {
              startIcon: "skip-left-line",
              value: "left",
            },
            {
              startIcon: "skip-right-line",
              value: "right",
            },
          ],
          isBindProperty: false,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
      ],
    },
    {
      sectionName: "颜色",
      children: [
        {
          propertyName: "iconColor",
          helpText: "Sets the icon color of a menu item",
          label: "图标颜色",
          controlType: "COLOR_PICKER",
          isBindProperty: false,
          isTriggerProperty: false,
        },
        {
          propertyName: "textColor",
          helpText: "Sets the text color of a menu item",
          label: "字体颜色",
          controlType: "COLOR_PICKER",
          isBindProperty: false,
          isTriggerProperty: false,
        },
        {
          propertyName: "backgroundColor",
          helpText: "Sets the background color of a menu item",
          label: "背景颜色",
          controlType: "COLOR_PICKER",
          isBindProperty: false,
          isTriggerProperty: false,
        },
      ],
    },
  ],
  eventChildren: [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is onRowClick",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
};
