import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {get} from "lodash";
import {isEllipsisActive} from "utils/helpers";
import {TooltipComponent,} from "@design-system/widgets-old";

import {useSelector} from "react-redux";

import {getSelectedAppTheme} from "selectors/appThemingSelectors";
import {getComplementaryGrayscaleColor} from "widgets/WidgetUtils";
import {TabPosition} from "../../../components/constants";
import {getIconPark} from "../../../components/propertyControls/IconSelectControl";

const PageTab = styled.div`
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const StyledBottomBorder = styled.div<{ primaryColor: string }>`
  position: relative;
  transition: all 0.3s ease-in-out;
  height: 2px;
  width: 100%;
  left: -100%;
  background-color: ${({primaryColor}) => primaryColor};
  ${PageTab}:hover & {
    position: relative;
    width: 100%;
    left: 0;
  }
`;

const StyledLeftBorder = styled.div<{ primaryColor: string, dir: string }>`
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 3px;
  height: 100%;
  top: -100%;
  ${({dir}) => `
     ${dir} : 0;
  `}
  background-color: ${({primaryColor}) => primaryColor};
  ${PageTab}:hover & {
    position: absolute;
    height: 100%;
    top: 0;
  }
`;

const StyleTabText = styled.div<{
  accentColor: string;
  backgroundColor?: string;
  dir?: string;
  lineH: string;
}>`
  overflow: hidden;
  position:relative;
  display: flex;
  flex-direction: ${({dir}) => (dir == 'left' || dir == 'right') ? 'row' : 'column'};
  align-items: center;
  justify-content: center;
  font-weight:  ${({lineH}) => lineH};
  font-size: ${({lineH}) => lineH};
  line-height: ${({lineH}) => lineH};
  letter-spacing: ${({lineH}) => lineH};
  color: ${(props) => getComplementaryGrayscaleColor(props.backgroundColor)};
  font-weight: normal;
  display: flex;

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & span {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &.is-selected {
      color: ${(props) => props.accentColor};
    }
  }
  ${PageTab}.is-active & {
    color: ${(props) => props.theme.colors.header.activeTabText};
    ${StyledBottomBorder} {
      left: 0;
    }
    ${StyledLeftBorder} {
      top: 0;
    }
  }

  & span.iconPark {
    width: 20px;
  }

  ${({dir}) => `
     ${dir == 'left'} : 0;
  `}

  ${({dir}) =>
  (dir == 'left') && `
    padding-right: 20px;
    margin-left: 20px;
    margin-bottom: 16px;
  `}

  ${({dir}) =>
  (dir == 'right') && `
    padding-left: 20px;
    margin-right: 20px;
    margin-bottom: 16px;
  `}
`;

const IconWrapperDiv = styled.div<{ stroke: string }>`
  & svg path, & svg:hover path {
    fill: white !important;
    stroke: ${({stroke}) => stroke} !important;
  }
  width: 16px;
  height: 16px;
`;

function PageTabName(
  {
    backgroundColor,
    id,
    name,
    primaryColor,
    selected,
    tabPosition,
    tabW,
    textSize,
    labelStyle,
    textColor,
    lineHeight,
    selectedTextSize,
    selectedLabelStyle,
    iconName,
    textSelectedColor,
  }: {
    id: string;
    name: string;
    primaryColor: string;
    backgroundColor?: string;
    selected: boolean;
    tabPosition: string;
    tabW: string;
    textSize: string;
    labelStyle: string;
    textColor: string;
    lineHeight: string;
    selectedTextSize: string;
    selectedLabelStyle: string;
    iconName: string;
    textSelectedColor: string;
  }) {
  const tabNameRef = useRef<HTMLSpanElement>(null);
  const [ellipsisActive, setEllipsisActive] = useState(false);
  const color = selected ? textSelectedColor : textColor;
  let spanStyle: any = {
    fontSize: selected ? selectedTextSize : textSize,
    color: color,
  }
  let fs = selected ? selectedLabelStyle : labelStyle;
  if (fs && fs.indexOf('BOLD') > -1) {
    spanStyle = {
      ...spanStyle,
      fontWeight: 'BOLD'
    }
  }
  if (fs && fs.indexOf('ITALIC') > -1) {
    spanStyle = {
      ...spanStyle,
      fontStyle: "italic",
    }
  }

  if (tabPosition == TabPosition.Right) {
    spanStyle = {
      ...spanStyle,
      lineHeight: '38px',
      paddingLeft: '4px',
      height: '38px',
    }
  } else if (tabPosition == TabPosition.Left) {
    spanStyle = {
      ...spanStyle,
      lineHeight: '38px',
      paddingLeft: '4px',
      height: '38px',
    }
  } else {
    spanStyle = {
      ...spanStyle,
      width: tabW ? tabW : '100%'
    }
  }

  const tabNameText = (
    <StyleTabText
      accentColor={primaryColor}
      backgroundColor={backgroundColor}
      className={`t--tab-${name} t--tabid-${id}`}
      dir={tabPosition}
      lineH={lineHeight}
    >
      {tabPosition == TabPosition.Bottom && <StyledBottomBorder primaryColor={primaryColor}/>}
      {tabPosition == TabPosition.Right && <StyledLeftBorder dir={TabPosition.Left} primaryColor={primaryColor}/>}
      {tabPosition == TabPosition.Left && <StyledLeftBorder dir={TabPosition.Right} primaryColor={primaryColor}/>}

      <div className="relative flex items-center justify-center flex-grow">
        {iconName && <IconWrapperDiv stroke={color}>{getIconPark(iconName)}</IconWrapperDiv>}
        <span style={{...spanStyle}} className={selected ? "is-selected" : ""} ref={tabNameRef}>
          {name}
        </span>
      </div>
      {tabPosition == TabPosition.Top && <StyledBottomBorder primaryColor={primaryColor}/>}
    </StyleTabText>
  );

  useEffect(() => {
    if (isEllipsisActive(tabNameRef?.current)) {
      setEllipsisActive(true);
    }
  }, [tabNameRef, tabNameText]);

  return (
    <TooltipComponent
      boundary="viewport"
      content={name}
      disabled={!ellipsisActive}
      maxWidth="400px"
      position="bottom"
    >
      {tabNameText}
    </TooltipComponent>
  );
}

function PageTabContainer(
  {
    children,
    isTabActive,
    setShowScrollArrows,
    tabsScrollable,
    tabPosition,
    tabWFlex1,
  }: {
    children: React.ReactNode;
    isTabActive: boolean;
    tabsScrollable: boolean;
    setShowScrollArrows: () => void;
    tabPosition: string;
    tabWFlex1: boolean;
  }) {
  const tabContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTabActive) {
      setShowScrollArrows();
    }
  }, [isTabActive, tabsScrollable]);

  let style: any = {
    display: 'flex',
    alignItems: 'flex-end',
  };
  if (tabWFlex1) {
    style = {flex: 1}
  } else {
    if (tabPosition == TabPosition.Bottom) {
      style = {
        height: '100%'
      }
    }
    if (tabPosition == TabPosition.Left || tabPosition == TabPosition.Right) {
      style = {
        marginBottom: '16px',
      }
    }
  }

  return <div
    ref={tabContainerRef}
    style={{...style}}>
    {children}
  </div>;
}

type Props = {
  tabs: Array<{
    id: string;
    label: string;
    widgetId: string;
    isVisible?: boolean;
    iconName?: string;
  }>;
  measuredTabsRef: (ref: HTMLElement | null) => void;
  tabsScrollable: boolean;
  setShowScrollArrows: () => void;
  tabChange: (tabId: string) => void;
  selectedTabWidgetId: string;
  backgroundColor?: string;
  accentColor?: string;
  float?: string;
  tabPosition: string;
  labelAlignment: string;
  tabW: string;
  tabWFlex1: boolean;
  textSize: string;
  labelStyle: string;
  textColor: string;
  lineHeight: string;
  selectedTextSize: string;
  selectedLabelStyle: string;
  textSelectedColor: string;
};

export function PageTabs(props: Props) {
  const {
    float,
    tabChange,
    tabs,
    tabPosition,
    labelAlignment,
    tabW,
    tabWFlex1,

    textSize,
    labelStyle,
    textColor,
    lineHeight,
    selectedTextSize,
    selectedLabelStyle,
    textSelectedColor,
  } = props;

  const selectedTheme = useSelector(getSelectedAppTheme);
  let className = "w-full h-full hidden-scrollbar gap-x-8" + ` pageTabs-${tabPosition}`;
  if (float == 'none') {
    className += ' flex '
  }

  let style: any = {
    justifyContent: labelAlignment,
    textAlign: 'center',
  }

  return (
    <div
      className={className}
      ref={props.measuredTabsRef}
      style={{...style}}
    >
      {tabs.map((tab, index) => (
        <PageTabContainer
          isTabActive={props.selectedTabWidgetId === tab.widgetId}
          key={index}
          setShowScrollArrows={props.setShowScrollArrows}
          tabsScrollable={props.tabsScrollable}
          tabPosition={tabPosition}
          tabWFlex1={tabWFlex1}
        >
          <PageTab
            className={`t--page-switch-tab ${
              props.selectedTabWidgetId === tab.widgetId ? "is-active" : ""
            }`}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              tabChange(tab.widgetId);
              event.stopPropagation();
            }}
          >
            <PageTabName
              backgroundColor={props.backgroundColor}
              id={tab.id}
              name={tab.label}
              primaryColor={
                props.accentColor ||
                get(selectedTheme, "properties.colors.primaryColor", "inherit")
              }
              selected={props.selectedTabWidgetId === tab.widgetId}
              tabPosition={tabPosition}
              tabW={tabWFlex1 ? '' : tabW}
              textSize={textSize}
              labelStyle={labelStyle}
              textColor={textColor}
              lineHeight={lineHeight}
              selectedTextSize={selectedTextSize}
              selectedLabelStyle={selectedLabelStyle}
              iconName={tab.iconName || ''}
              textSelectedColor={textSelectedColor}
            />
          </PageTab>
        </PageTabContainer>
      ))}
    </div>
  );
}

export default PageTabs;
