import { uuid2 } from "@byk/utils/Utils";
import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XEchartsLine",
  iconSVG: IconSVG,
  defaults: {
    rows: 30,
    columns: 80,
    widgetName: "XEchartsLine",
    version: 1,
    label: "label",
    isDarkMode: false,
    isDownload: false,
    isDataView: false,
    isTitle:true,
    title:"Echart",
    tabsObj:{
      // "2023":{
      //   "id": uuid2(),
      //   "index": 0,
      //   "label": "2023",
      //   "key": uuid2(),
      //   "widgetId": uuid2(),
      //   "isVisible": true,
      //   "sType": "bar",
      //   "isSmooth": false,
      //   "color": ""
      // },
      // "2024":{
      //   "id": uuid2(),
      //   "index": 1,
      //   "label": "2024",
      //   "key": uuid2(),
      //   "widgetId": uuid2(),
      //   "isVisible": true,
      //   "sType": "bar",
      //   "isSmooth": false,
      //   "color": ""
      // },
      // "2025":{
      //   "id": uuid2(),
      //   "index": 2,
      //   "label": "2025",
      //   "key": uuid2(),
      //   "widgetId": uuid2(),
      //   "isVisible": true,
      //   "sType": "line",
      //   "isSmooth": false,
      //   "color": ""
      // }
    },
    sType:"y",
    isShowTab:true,
    xColor:"#6E7079",
    isShowLabel:true,
    isLegend:true,
    borderWidth:1,
    currentData: [{
      "xData":['Brazil', 'Indonesia', 'USA', 'India', 'China', 'World'],
      "yData":  [
        {
          name: '2023',
          data: [68203, 43489, 29034, 40497, 13174, 63023]
        },
        {
          name: '2024',
          data: [49325, 73438, 81000, 52159, 73414, 48180]
        },
        {
          name: '2025',
          data: [32325, 19438, 51000, 18159, 19414, 58180]
        }
      ],
    }]
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
