import React, {forwardRef, useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Input, Popover, Tabs} from "antd";
import {QueryEditorContext} from "@byk/pages/modeling/SqlQueryEditor/QueryEditorContext";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";

const ContainerDiv = styled.div`
  width: 260px;
  height: 100%;
  border-right: 1px solid #f0f0f0;

  .ant-tabs-nav, .ant-tabs-content-holder {
    padding-left: 24px;
  }

  .search_add {
    display: flex;
    align-items: center;

    .anticon-plus {
      display: block;
      margin: 0 10px;
    }
  }

  .sqlInfoListUl {
    border-radius: 8px;
    margin-top: 5px;

    .sqlInfoListLi {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 5px 0px;
      border-bottom: 1px solid #f0f0f0!important;

      .anticon {
        margin-right: 8px;
      }
    }

    .sqlInfoListLiSelected {
      background: #e3e8ef;
      border-radius: 4px;
      color: #4c5664;
    }

    .sqlInfoListLi:hover {
      background: #f1f5f9;
      border-radius: 4px;
      color: #4c5664;
    }
  }
`

const SqlList: React.FC<any> = forwardRef((props, ref) => {
  const {
    addNewSqlInfo,
    sqlQueryEditorMode,
    setState,
    getState,
    refreshCmp,
    sqlInfoAction,
    codeMirrorSqlRef,
  } = useContext(QueryEditorContext);
  let {sqlInfo}: any = getState();
  const [hoverItem, setHoverItem] = useState<any>({});
  const [searchText, setSearchText] = useState<any>();
  const [sqlList, setSqlList] = useState<any>([]);

  useEffect(() => {
    setSqlList(sqlQueryEditorMode.filterSqlList(searchText));
  }, [sqlQueryEditorMode.sqlList, searchText])

  const doDeleteSqlInfo = () => {
    sqlInfoAction('delete', {ids: [hoverItem.id]})
  };

  const _sqlList = useCallback(() => {
    return <>
      <div className={'search_add'}>
        <Input.Search
          value={searchText}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          allowClear/>
        <PlusOutlined onClick={addNewSqlInfo}/>
      </div>
      <ul className={'sqlInfoListUl'}>
        {sqlList.map((item: any, index: any) => {
          let isSelected = sqlInfo?.id == item.id ? 'sqlInfoListLiSelected' : '';
          let showDelBtn = hoverItem?.id == item.id || sqlInfo?.id == item.id;
          return <li className={`sqlInfoListLi ${isSelected}`}
                     onMouseEnter={() => setHoverItem(item)}
                     onMouseLeave={() => setHoverItem({})}
          >
            <div style={{width: '100%'}}
                 onClick={() => {
                   codeMirrorSqlRef.current = item.sql || '';
                   setState({sqlInfo: {...item}});
                   refreshCmp();
                 }}>
              {item.title}
            </div>
            {showDelBtn &&
            <Popover
              placement="rightTop" title={<b>确认</b>}
              trigger={'click'}
              content={<a onClick={doDeleteSqlInfo}>确认删除SQL？</a>}
            >
              <DeleteOutlined/>
            </Popover>
            }
          </li>
        })}
      </ul>
    </>;
  }, [sqlList, sqlInfo, hoverItem])

  const items = [
    {label: 'SQL列表', key: 'sqlList', children: _sqlList()}, // 务必填写 key
  ];

  return (
    <ContainerDiv className={'sqlList'}>
      <Tabs items={items}/>
    </ContainerDiv>
  )
})

export default SqlList;
