import {Button, Modal, Upload, UploadFile, UploadProps} from 'antd';
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import styled from "@emotion/styled";
import ImportModelByExcelView from './ImportModelByExcelView';
import {UploadOutlined} from "@ant-design/icons";
import {ModelProps} from "@byk/pages/modeling/MxGraph/CreateModel";
import {PageEleModel} from "@byk/pages/modeling/lib/PageEleModel";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";

const ModalWrapper = styled(Modal)`
  &.ant-modal {
    top: 40px !important;
  }
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-input {
    border-radius: 5px;
    border: 1px solid rgba(228, 228, 229, 1);
  }

  .header-bar {
    display: flex;
    border-bottom: 1px solid #E4E8EB;
  }
`

const UploadWrapper = styled(Upload)`
  display: flex;
  .ant-upload-list-item-info {
    margin-left: 15px;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
  }
`

const ImportModelByExcel: React.FC<ModelProps> = forwardRef((props, ref) => {
      useImperativeHandle(ref, () => ({
        showModal,
      }));
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [excelBlob, setExcelBlob] = useState<any>();
      const blobUrlRef = useRef<any>({});
      const [fileList, setFileList] = useState<UploadFile[]>([]);
      const [pageEleModel, setPageEleModel] = useState<PageEleModel>(new PageEleModel());

      const showModal = async () => {
        setExcelBlob(null);
        setFileList([]);
        setIsModalOpen(true);
        await pageEleModel.init();
        setPageEleModel(pageEleModel);
      };

      const handleCancel = () => {
        setExcelBlob(null);
        setFileList([]);
        setIsModalOpen(false);
        updateFileList([], true);
      };

      const onFileChange = (files: any = []) => {
        setFileList(files);
        if (files.length == 1) {
          let uri = files[0].url;
          fetch(uri)
            .then(res => res.blob())
            .then(data => {
              setExcelBlob(data);
            });
        } else {
          setExcelBlob(null);
        }
      };

      const onRemove = async (file: any) => {
        updateFileList(file, true);
      }

      const uploadProps: UploadProps = {
        fileList: [],
        maxCount: 1,
        onRemove: onRemove,
        customRequest: async (option) => {
          let {file} = option;
          updateFileList(file)
        },

        beforeUpload: (file) => {
          const url = URL.createObjectURL(file);
          let key = file.uid;
          blobUrlRef.current[key] = url;
        }
      };

      const updateFileList = (file: any, isRemove: boolean = false) => {
        file.url = blobUrlRef.current[file.uid];
        let list: any = [] = fileList.filter((item: any) => {
          return item.uid != file.uid;
        });

        if (!isRemove) {
          list = [...list, file];
        }

        setFileList([...list]);
        onFileChange(list);
      }

      const downloadExcel = () => {
        ModelingApi.doDownloadExcelTemplate4CreateModel();
      }

      return (
        <ModalWrapper
          onCancel={handleCancel} open={isModalOpen}
          maskClosable={false}
          footer={null}
          title={'Excel导入创建模型'}
          width={1715}
          bodyStyle={{padding: '0px', height: "calc(100vh - 120px)",}}
        >
          <div className='header-bar'>
            <Button type={"link"} onClick={downloadExcel}>下载模板</Button>
            <UploadWrapper {...uploadProps} fileList={fileList} accept={".xls,.xlsx"}>
              <Button type={"link"} disabled={fileList.length == 1} icon={<UploadOutlined/>}>上传Excel文件</Button>
            </UploadWrapper>
          </div>


          <ImportModelByExcelView
            blob={excelBlob}
            pageEleModel={pageEleModel}
            handleCancel={handleCancel}
            mxAndModel={props.mxAndModel}
            mxUpdateModel={props.mxUpdateModel}
          />
        </ModalWrapper>
      );
    }
  )
;

export default ImportModelByExcel;
