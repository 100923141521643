import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XEcharts",
  iconSVG: IconSVG,
  defaults: {
    rows: 30,
    columns: 30,
    widgetName: "XEcharts",
    version: 1,
    currentData: [{
      "xAxis": {
        "type": "category",
        "data": ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      },
      "yAxis": {
        "type": "value"
      },
      "series": [
        {
          "data": [150, 230, 224, 218, 135, 147, 260],
          "type": "line"
        }
      ]
    }]
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
