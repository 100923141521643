import React from 'react';
import {Swiper, SwiperItem} from '@tarojs/components'
import styled from "styled-components";
const SwiperWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  taro-swiper-core{
    height: 100%;
  }
`;
const TSwiperWidget = (props:any) => {
  const {imageData, isAutoplay, isCircular, isIndicatorDots, onItemClick} = props;
  return (
    <SwiperWrapper>
      { imageData&&imageData.length>0?(<Swiper
        indicatorColor='#999'
        indicatorActiveColor='#333'
        circular={isCircular}
        indicatorDots={isIndicatorDots}
        autoplay={isAutoplay}>
        {
          imageData.map((i:any)=>{
            return (
              <SwiperItem>
                <img onClick={()=>{
                  onItemClick&&onItemClick(i);
                }} src={i.url} alt="" />
              </SwiperItem>
            )
          })
        }

      </Swiper>):(
        <div>请在属性栏编辑数据</div>
      ) }
    </SwiperWrapper>
  );
};

export default TSwiperWidget;
