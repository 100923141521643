import {Button, Divider, Form, Input, message, Modal, Select, Space, Spin} from 'antd';
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import CreateProperty from "@byk/pages/modeling/MxGraph/CreateProperty";
import {PROPERTY_REL_OPTIONS, PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import _ from "lodash";
import PropertyMapping from "@byk/pages/modeling/MxGraph/PropertyMapping";
import {PropertyMappingModel} from "@byk/pages/modeling/lib";
import GraphModel from "@byk/pages/modeling/lib/GraphModel";
import styled from 'styled-components';
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import {MODEL_FORM_RULES} from "@byk/pages/modeling/ModelDataUtil";

const layout = {
  labelCol: {span: 7},
  wrapperCol: {span: 14},
};

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .modelRelCenter {
    display: grid;
    align-items: center;
  }

  .modelRelSelector {
    margin: 0 20px;
    width: 188px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgba(228, 228, 229, 1);
    color: rgba(65, 80, 88, 1);
    font-size: 14px;
    text-align: left;
    font-family: Microsoft Yahei;
  }

  .modelRelSelector .ant-select-selector,
  .modelRelSelector .ant-select-selection-item {
    height: 40px;
    line-height: 40px;
  }

  .modelFkSelector {
    border-radius: 5px;
    border: 1px solid rgba(228, 228, 229, 1);
  }

  .modelFkSelector .ant-select-selector,
  .modelFkSelector .ant-select-selection-item {
    height: 40px;
    line-height: 40px;
  }

  .ant-input {
    border-radius: 5px;
    border: 1px solid rgba(228, 228, 229, 1);
  }

  .delBtn .i-icon-delete{
    height: 14px;
  }

  .delBtn:hover .i-icon-delete path{
    stroke: rgb(0, 108, 242) !important;
  }

  .ant-btn-link {
    border: none !important;
    color: rgb(90, 90, 90) !important;
  }
  .ant-btn-link:hover {
    color: var(--primary-color70) !important;
  }
`

const CreateModelRelation: React.FC<any> = forwardRef((
      {
        mxUpdateModel,
        mxRemoveCell,
        callback,
      }, ref) => {

      useImperativeHandle(ref, () => ({
        initModelRelation,
      }));

      const [isModalOpen, setIsModalOpen] = useState(false);
      const [form] = Form.useForm();
      const [mapperModel, setMapperModel] = useState<any>({});
      const [fkModel, setFkModel] = useState<any>({});
      const [mapperPro, setMapperPro] = useState<any>({});
      const [fkPro, setFkPro] = useState<any>({});
      const [isProMappingOpen, setIsProMappingOpen] = useState(false);
      const [fkOptions, setFkOptions] = useState<any[]>([]);
      const graphModelRef = useRef<GraphModel>(new GraphModel());
      const [leftModel, setLeftModel] = useState<any>({});
      const [rightModel, setRightModel] = useState<any>({});
      const [relType, setRelType] = useState(PROPERTY_REL_OPTIONS[0].value);
      const [isNew, setNew] = useState(false);
      const [spinning, setSpinning] = useState(false);

      const mappedBySelectRef = useRef<any>();
      const addPropertyRef = useRef<any>();

      const initModelRelation = (gm: GraphModel, mapperModel: any, fkModel: any, mapperPro?: any, fkPro?: any) => {
        if (!fkPro) { // 新建关系
          setNew(true);
        }
        graphModelRef.current = gm;
        setLeftModel(mapperModel);
        setRightModel(fkModel);
        setRelType(PROPERTY_REL_OPTIONS[0].value);
        _initModelRelation(mapperModel, fkModel, mapperPro, fkPro);
      }

      const _initModelRelation = (mapperModel: any, fkModel: any, mapperPro?: any, fkPro?: any) => {
        let graphModel = graphModelRef.current;
        setMapperModel(mapperModel || {});
        setFkModel(fkModel || {});
        setMapperPro(mapperPro || {model: mapperModel.name, modelId: mapperModel.id, targetModel: fkModel.name});
        setFkPro(fkPro || {model: fkModel.name, modelId: fkModel.id, targetModel: mapperModel.name});

        form.setFieldsValue({
          mapperProName: mapperPro?.name,
          mapperProDesc: mapperPro?.description,
          fkProName: fkPro?.name,
        });

        graphModel && setFkOptions(graphModel.getFkPropertyOptions(mapperModel, fkModel, fkPro));
        setIsModalOpen(true);
      }

      const handleOk = () => {
        let graphModel = graphModelRef.current;
        form.validateFields().then(
          async () => {
            setSpinning(true);
            fkPro.type = PROPERTY_REL_TYPE.M2O;
            fkPro.targetModel = mapperModel.name;
            fkPro.model = fkModel.name;
            fkPro.modelId = fkModel.id;
            fkPro.mappedBy = mapperPro.name;

            mapperPro.type = PROPERTY_REL_TYPE.O2M;
            mapperPro.persistent = false;
            mapperPro.targetModel = fkModel.name;
            mapperPro.model = mapperModel.name;
            mapperPro.modelId = mapperModel.id;
            mapperPro.mappedBy = fkPro.name;
            let properties = [fkPro];
            if (mapperPro && mapperPro.name) {
              properties.push(mapperPro)
            }

            let ret: any = await ModelingApi.doApiSavePropertyMapping(properties);
            if (ret.success && ret.result) {
              setNew(false);
              graphModel.updateProperties(ret.result);
              mxUpdateModel && mxUpdateModel(graphModel.getModel(mapperModel.name), null, false);
              mxUpdateModel && mxUpdateModel(graphModel.getModel(fkModel.name), null, false);
              _.forEach(ret.result, item => {
                if (item.name === fkPro.name) {
                  mxUpdateModel && mxUpdateModel(null, item);
                  setFkPro(item);
                }
                if (item.name === mapperPro.name) {
                  setMapperPro(item);
                }
              });
              message.success("保存成功!");
              setIsModalOpen(false);
              setSpinning(false);
            }
          }
        )
      };

      const handleCancel = () => {
        setIsModalOpen(false);
        if (!fkPro.id) {
          mxRemoveCell();
        }
      };

      const handleDelete = async () => {
        setSpinning(true);
        let graphModel = graphModelRef.current;
        let delProperties = [];
        let mxEdge = graphModel.getMxEdge(fkPro.id);
        mapperPro.id && delProperties.push(mapperPro);
        fkPro.id && delProperties.push(fkPro);
        let res: any = await ModelingApi.doApiDropProperties(delProperties);
        if (res.success) {
          graphModel.removeProperties(delProperties);
          _.forEach(delProperties, item => {
            mxUpdateModel && mxUpdateModel(graphModel.getModel(item.model));
          });

          if (mxEdge) {
            mxRemoveCell && mxRemoveCell(mxEdge);
            callback && callback(delProperties);
            setIsModalOpen(false);
          }
          message.success("删除成功");
        }
        setSpinning(false);
      }

      const updateFkPro = (option: any) => {
        setFkPro({...option});
      }

      const updateMapperPro = (value: any) => {
        setMapperPro({
          ...mapperPro,
          ...value,
        });
      }

      const createFkProCallback = (property: any) => {
        let graphModel = graphModelRef.current;
        let exist = false;
        let modelData = graphModel.getModel(property.modelId)
        modelData.properties.forEach((item: any) => {
          if (!exist && item.name == property.name) {
            exist = true;
          }
        })

        fkOptions.forEach((item: any) => {
          if (!exist && item.value == property.name) {
            exist = true;
          }
        })

        if (!exist) {
          let fko = fkOptions.concat([{
            ...property,
            "label": property.description,
            "value": property.name,
          }])
          setFkOptions(fko);
          form.setFieldValue("fkProName", property.name);
          setFkPro({
            ...fkPro,
            ...property
          });
        }
      }

      const onCreateFkPro = () => {
        setTimeout(() => {
          addPropertyRef.current?.createFkProperty(mapperModel, fkModel, createFkProCallback);
        }, addPropertyRef.current ? 0 : 100);
      }

      const changeRelType = (value: any) => {
        setRelType(value);
        _initModelRelation(fkModel, mapperModel);
      }

      function handleProMappingOk() {
        setIsProMappingOpen(false);
      }

      function handleProMappingCancel() {
        setIsProMappingOpen(false);
      }

      return (
        <div>
          <ModalWrapper
            title="模型关系" onOk={handleOk} onCancel={handleCancel} open={isModalOpen}
            footer={null}
            maskClosable={false}
            width={732}
            bodyStyle={{padding: "0px", height: "370px",}}
          >
            <Spin tip="Loading..." spinning={spinning}>
              <Form
                {...layout}
                form={form}
                autoComplete="off"
                style={{
                  height: '306px',
                }}
              >
                <div className="modelRelContainer">
                  <div className={"modelRelLeft"}> {leftModel.description}</div>
                  <div className='modelRelCenter'>
                    <Select
                      bordered={false}
                      className="modelRelSelector"
                      disabled={!isNew}
                      options={PROPERTY_REL_OPTIONS}
                      value={relType}
                      onSelect={(value => changeRelType(value))}
                    />
                  </div>
                  <div className={"modelRelRight"}> {rightModel.description}</div>
                </div>
                <div>
                  <Form.Item
                    style={{marginTop: '16px'}}
                    name="fkProName"
                    label="关联属性(外键)"
                    rules={[
                      {required: true, message: ''},
                    ]}
                  >
                    <Select
                      className="modelFkSelector"
                      bordered={false}
                      style={{width: '451px', height: '40px'}}
                      disabled={!isNew}
                      ref={mappedBySelectRef}
                      options={fkOptions}
                      onSelect={async (value, option) => {
                        await updateFkPro(option);
                      }}
                      dropdownRender={menu => {
                        return (
                          <>
                            {menu}
                            <>
                              <Divider style={{margin: '8px 0'}}/>
                              <Space style={{padding: '0 8px 4px'}}>
                                <Button style={{display: 'flex', alignItems: 'center'}}
                                        type={"link"}
                                        icon={getIconPark('AddOne')} onClick={onCreateFkPro}
                                >
                                  <span style={{paddingLeft: '5px'}}>新建关联属性</span>
                                </Button>
                              </Space>
                            </>
                          </>
                        )
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{marginTop: '16px'}}
                    name="mapperProName"
                    label="映射属性编码"
                    rules={[
                      {required: mapperPro.description, message: ''},
                      ...MODEL_FORM_RULES['name']
                    ]}
                  >
                    <Input
                      style={{width: '451px', height: '40px'}}
                      disabled={mapperPro.id}
                      onChange={(e) => updateMapperPro({name: e.target.value})}/>
                  </Form.Item>
                  <Form.Item
                    style={{marginTop: '16px'}}
                    name="mapperProDesc"
                    label="映射属性名称"
                    rules={[
                      {required: mapperPro.name, message: ''},
                      ...MODEL_FORM_RULES['description']
                    ]}
                  >
                    <Input
                      style={{width: '451px', height: '40px'}}
                      disabled={mapperPro.id}
                      onChange={(e) => updateMapperPro({description: e.target.value})}/>
                  </Form.Item>
                </div>
              </Form>

              <Divider style={{marginTop: '16px'}}/>
              <div style={{display: "flex", justifyContent: 'space-between'}}>
                <div>
                  <Button className='delBtn' icon={getIconPark("Delete", "#5A5A5A")} type={"link"} onClick={handleDelete}
                          style={{marginLeft: "28px", borderRadius: '5px', color: '#5A5A5A'}}>删除</Button>
                </div>
                <div>
                  <Button onClick={handleCancel} style={{marginRight: "12px", borderRadius: '5px'}}>取消</Button>

                  {(!mapperPro.id || (!mapperPro.id && !fkPro.id)) &&
                  <Button type={"primary"} onClick={handleOk}
                          style={{marginRight: "29px", borderRadius: '5px'}}>{(mapperPro.id || fkPro.id) ? "更新" : "保存"}</Button>
                  }

                </div>
              </div>
            </Spin>
          </ModalWrapper>

          <CreateProperty ref={addPropertyRef}/>

          <Modal
            title="关联查询属性映射配置" onOk={handleProMappingOk} onCancel={handleProMappingCancel} open={isProMappingOpen}
            footer={null}
            maskClosable={false}
            width={600}
            bodyStyle={{height: "500px", padding: "0px"}}
          >
            <PropertyMapping
              property={mapperPro}
              propertyMappingModel={new PropertyMappingModel(graphModelRef.current, fkPro)}
              graphModel={graphModelRef.current}
              mxUpdateModel={mxUpdateModel}
              style={{}}
            />
          </Modal>

        </div>
      )
        ;
    }
  )
;

export default CreateModelRelation;
