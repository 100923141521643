import {Button, message, Popconfirm, Space, Spin, Table} from 'antd';
import React, {forwardRef, useRef, useState} from 'react';
import {ColumnsType} from "antd/es/table";
import styled from "styled-components";
import CreateModelIndex from "@byk/pages/modeling/MxGraph/CreateModelIndex";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {setCurDynamicModel} from "@byk/store/modelingReducers";
import {useDispatch, useSelector} from "react-redux";
import {getDynamicModel} from "@byk/pages/modeling/modelSelector";
import {graphModel} from "@byk/pages/modeling/MxGraph/ModelingMx";

const TableWrapper = styled.div`
  width: 100%;
  margin-right: 18px;
  margin-left: 21px;
  margin-top: 21px;
  .ant-table.ant-table-bordered>.ant-table-container {
    border-left: 1px solid #f0f0f0;
  }

  .ant-table-cell {
    height: 45px;
    font-size: 16px;
    padding-left:
  }

  .formItemSelect {
    border: 1px solid #E4E8EB;
    border-radius: 5px;
  }
`

interface Props {
}

interface DataType {
  key: React.Key;
  id: any;
  model: string;
  type: string;
  targetModel: string;
}

const ModelIndex: React.FC<Props> = forwardRef((
      {}: Props, ref) => {
      const dispatch = useDispatch();
      const model = useSelector(getDynamicModel);
      const [dataSource, setDataSource] = useState<any>(model.indexs || []);
      const createRef = useRef<any>();
      const [spinning, setSpinning] = useState(false);

      const columns: ColumnsType<DataType> = [
        {
          title: '索引名称',
          dataIndex: 'name',
        },
        {
          title: '索引列',
          dataIndex: 'properties',
        },
        {
          title: '索引类型',
          dataIndex: 'unique',
          render: (_, record: any) => {
            return (
              <>
                {record.unique ? '唯一' : '非唯一'}
              </>
            )
          }
        },
        {
          key: 'x',
          title: '操作',
          dataIndex: 'operation',
          render: (_, record: any) => {
            return (
              <Space size="middle">
                <>
                  <Button type='link' onClick={() => edit(record)}>编辑</Button>
                  <Popconfirm placement="left" title={"确认删除吗？"} onConfirm={() => onDelete(record.id)} okText="确定"
                              cancelText="取消">
                    <Button type="link" style={{color: "red"}}>
                      删除
                    </Button>
                  </Popconfirm>
                </>
              </Space>
            )
          }
        },
      ];

      const openCrateIndex = () => {
        createRef.current.init();
      }

      const edit = (record: any) => {
        createRef.current.init(record)
      }

      const onDelete = async (id: any) => {
        setSpinning(true);
        await doDelete(id);
        setSpinning(false);
      }

      const doDelete = async (id: any) => {
        let _ds: any = [...dataSource];
        const index = _ds.findIndex((i: any) => id === i.id);
        _ds.splice(index, 1);
        model.indexs = _ds;
        await doSaveModel(model);
      }

      const updateDataSource = async (modelIdx: any) => {
        let _ds: any = [...dataSource];
        if (modelIdx.id) {
          const index = _ds.findIndex((i: any) => modelIdx.id === i.id);
          if (index > -1) {
            let tmp = _ds[index];
            _ds.splice(index, 1, {
              ...tmp,
              ...modelIdx,
            });
          }
        } else {
          _ds.push(modelIdx);
        }

        model.indexs = _ds;
        return await doSaveModel(model);
      }

      const doSaveModel = async (model: any) => {
        let res: any = await ModelingApi.doSaveModel(model);
        if (res.success) {
          let resultModelData = res.result[0];
          dispatch(setCurDynamicModel(resultModelData));
          setDataSource(resultModelData.indexs);
          graphModel.updateModel(resultModelData);
          message.success("保存成功");
        }

        return res.success;
      }

      return (
        <TableWrapper>
          <Spin tip='加载中...' spinning={spinning}>
            <Button className={'mb-5'} type={'primary'} onClick={openCrateIndex}>新建索引</Button>
            <Table
              pagination={false}
              columns={columns}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={dataSource}
            />
          </Spin>
          <CreateModelIndex
            ref={createRef} model={model}
            updateDataSource={updateDataSource}
            doDelete={doDelete}/>

        </TableWrapper>
      );
    }
  )
;

export default ModelIndex;
