import { ASSETS_CDN_URL } from "constants/ThirdPartyConstants";
import IconSVG from "./icon.svg";
import Widget from "./widget";
import { getAssetUrl } from "@appsmith/utils/airgapHelpers";
import { DynamicHeight } from "utils/WidgetFeatures";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "UploadImage",
  iconSVG: IconSVG,
  needsMeta: true,
  dynamicHeight: DynamicHeight.AUTO_HEIGHT,
  defaults: {
    defaultImage: getAssetUrl(`${ASSETS_CDN_URL}/widgets/default.png`),
    imageShape: "RECTANGLE",
    maxZoomLevel: 1,
    enableRotation: false,
    enableDownload: false,
    objectFit: "cover",
    image: "",
    rows: 9,
    columns: 26,
    widgetName: "UploadImage",
    version: 1,
    animateLoading: true,
    value: '[]',
    label: "图片上传",
    labelPosition: "Left",
    labelWidth: 10,
    fileSize: 10,
    fileCount: 1,
    disabled: false,
    labelAlignment: "right",
    btnText: "上传",
    accept: ".jpg,.jpeg,.png",
    defaultValue:'[]',
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
