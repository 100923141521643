import React from "react";
import UiWidget from '../component';
import BaseWidget, {WidgetState} from "../../../widgets/BaseWidget";
import {WidgetType} from "../../../constants/WidgetConstants";
import {ValidationTypes} from "../../../constants/WidgetValidation";
import {EvaluationSubstitutionType} from "../../../entities/DataTree/types";
import {PropertyPaneConfig} from "../../../constants/PropertyControlConstants";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";

class Breadcrumb extends BaseWidget<any, WidgetState> {
  constructor(props: any) {
    super(props);
    this.onClickItem = this.onClickItem.bind(this);
  }

  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return [];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "当前层级文字",
        children: [
          {
            propertyName: "fontColor1",
            helpText: "Changes the color of the button",
            label: "字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "fontSize1",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "fontStyle1",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "上级文字",
        children: [
          {
            propertyName: "fontColor2",
            helpText: "Changes the color of the button",
            label: "字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "fontSize2",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "fontStyle2",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
 
      {
        sectionName: "分割线",
        children: [
          {
            propertyName: "separatorColor",
            helpText: "Changes the color of the button",
            label: "颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ]
      }
    ];
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      },
      {
        sectionName: "数据",
        children: [
          {
            propertyName: "currentData",
            label: "",
            controlType: "BREADCRUMB_ARRAY2",
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.ARRAY},
            evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE
          }
        ]
      }
    ];
  }

  onClickItem(item: any) {
    let url = item.topage ? item.topage : item.toUrl;
    url && super.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: `{{navigateTo("${url}", "", "${item.topagetype}")}}`,
      event: {
        type: EventType.ON_CLICK
      },
    });
  }

  getPageView() {
    return (
      <>
        <UiWidget onClickItem={this.onClickItem}  {...this.props} />
      </>
    )
  }

  static getWidgetType(): WidgetType {
    return 'BREADCRUMB_WIDGET';
  }
}

export default Breadcrumb;
