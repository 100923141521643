import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TInputNumberWidget from "../component";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";
import {fontSizeOptions} from "../../../pages/Editor/ThemePropertyPane/controls/ThemeFormControl";
import {FontStyleTypes} from "constants/WidgetConstants";
import {Stylesheet} from "../../../entities/AppTheming";

class TInputNumber extends TaroWidget<TInputNumberWidgetProps, WidgetState> {
  constructor(props: TInputNumberWidgetProps) {
    super(props);
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "defaultValue",
            label: "默认值",
            helpText: "设置该组件的默认值",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "step",
            label: "间隔值",
            helpText: "间隔值",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "min",
            label: "最小值",
            helpText: "最小值",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "max",
            label: "最大值",
            helpText: "最大值",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      },
      {
        sectionName: "设置",
        children: [
          {
            propertyName: "isDisabled",
            label: "是否禁用",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "disabledInput",
            label: "禁用输入",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "fontSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: fontSizeOptions,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "color",
            label: "颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "fontStyle",
            label: "加粗&斜体",
            helpText: "Controls the font emphasis of the text displayed",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: FontStyleTypes.BOLD,
              },
              {
                icon: "text-italic",
                value: FontStyleTypes.ITALIC
              }
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          }
        ],
      },
      {
        sectionName: "输入文字样式",
        children: [
          {
            propertyName: "inputFontSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: fontSizeOptions,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "inputColor",
            label: "颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "inputFontStyle",
            label: "加粗&斜体",
            helpText: "Controls the font emphasis of the text displayed",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: FontStyleTypes.BOLD,
              },
              {
                icon: "text-italic",
                value: FontStyleTypes.ITALIC,
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          }
        ],
      },
      {
        sectionName: "输入框样式",
        children: [
          {
            propertyName: "bgColor",
            label: "背景颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "borderColor",
            label: "边框颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          }
        ],
      }
    ];
  }
  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
      fontSize: "{{appsmith.theme.form.label.fontSize}}",
      color: "{{appsmith.theme.form.label.fontColor}}",
      fontStyle: "{{appsmith.theme.form.label.fontStyle}}",
      inputFontSize: "{{appsmith.theme.form.inputText.fontSize}}",
      inputColor: "{{appsmith.theme.form.inputText.fontColor}}",
      inputFontStyle: "{{appsmith.theme.form.inputText.fontStyle}}",
      bgColor: "{{appsmith.theme.form.inputBorder.bgColor}}",
      borderColor: "{{appsmith.theme.form.inputBorder.bgBorderColor}}",
    };
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.value }}`
    };
  }
  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue"
    };
  }

  updateWidgetProperty = (propertyValues: string) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty("value", propertyValues);
  }

  getPageView() {
    return (
      <TInputNumberWidget {...this.props} updateWidgetProperty={this.updateWidgetProperty} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_INPUTNUMBER_WIDGET";
  }
}

export interface TInputNumberWidgetProps extends WidgetProps {
  value: any[];
  label: string;
  onClick?: any;
  borderRadius: string;
  boxShadow?: string;
}

export default TInputNumber;
