import { ASSETS_CDN_URL } from "constants/ThirdPartyConstants";
import IconSVG from "./icon.svg";
import Widget from "./widget";
import { getAssetUrl } from "@appsmith/utils/airgapHelpers";
import { DynamicHeight } from "utils/WidgetFeatures";
import { Alignment } from "@blueprintjs/core";

export const cellDefault = {
  isCellVisible: true,//是否显示属性
  isCellRequired: true,//是否显示属性
  isCellDisable: false,//是否禁用属性
  btnType: 'default',//按钮默认次要按钮
  boxShadowBtn: "none",//按钮投影
  borderRadiusBtn: "2",//按钮圆角
  btnIcon: "none",//按钮图标
  btnPosition: "left",//按钮图标位置
  btnTextAlign: "center",//按钮对齐方式
  // columnType:"text",//组件类型
  isRequired: true,//是否必填
  isEdit:true,//是否可编辑
  selectId:null,
  defaultOptionLabel:null,
  defaultOptionValue:null,
  width:null
}

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "SubTable",
  iconSVG: IconSVG,
  needsMeta: true,
  features: {
    dynamicHeight: {
      sectionIndex: 3,
      defaultValue: DynamicHeight.AUTO_HEIGHT,
      active: true,
    },
  },
  dynamicHeight: DynamicHeight.AUTO_HEIGHT,
  defaults: {
    rows: 24,
    columns: 60,
    rowSpan: 1,
    widgetName: "SubTable",
    version: 1,
    animateLoading: true,
    value: [],
    defaultValue: [],
    label: "Lable",
    labelPosition: "Left",
    labelWidth: 8,
    vertical: false,
    labelAlignment: Alignment.RIGHT,
    isDisabled:false,
    tableColumns: {
      name: {
        id: 'name',
        index: 0,
        label: '姓名',
        columnType: "text",
        ...cellDefault
      },
      age: {
        id: 'age',
        index: 1,
        label: '年龄',
        columnType: "number",
        ...cellDefault
      },
      address: {
        id: 'address',
        index: 2,
        label: '住址',
        columnType: "text",
        ...cellDefault
      },
      tech: {
        id: 'tech',
        index: 3,
        label: '技术方向',
        columnType: "select",
        ...cellDefault
      },
    },
    fixTableColumns: {
      id: {
        id: 'id',
        index: 0,
        label: 'id',
        columnType: "text"
      },
      action: {
        id: '_subTable_action',
        index: 1,
        label: '增删改标记',
        columnType: "text"
      },
    },
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "360px",
            minHeight: "40px",
          };
        },
      },
    ],
  },
};

export default Widget;
