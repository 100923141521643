import type {WidgetType} from "constants/WidgetConstants";
import {RenderModes} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import ImageComponent from "../component";

import {EventType} from "constants/AppsmithActionConstants/ActionConstants";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import type {DerivedPropertiesMap} from "utils/WidgetFactory";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";

class ImageWidget extends BaseWidget<ImageWidgetProps, WidgetState> {
  constructor(props: ImageWidgetProps) {
    super(props);
    this.onImageClick = this.onImageClick.bind(this);
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc":
        "Image widget is used to display images in your app. Images must be either a URL or a valid base64.",
      "!url": "https://docs.appsmith.com/widget-reference/value",
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('ImageWidget');
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      },
      {
        sectionName: "数据",
        children: [
          {
            propertyName: "value",
            label: "上传图片",
            helpText: "Sets the label of the button",
            controlType: "UPLOAD_IMAGE_CODE",
            placeholderText: "确定",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            size: 1,
            validation: {type: ValidationTypes.TEXT},
            dependencies: ['isDescription', 'value', 'uploadValue', 'description'],
          },
          {
            propertyName: "isDescription",
            helpText: "",
            label: "显示描述",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: true,
            validation: {
              type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
              params: {
                type: ValidationTypes.BOOLEAN,
              },
            }
          },
          {
            propertyName: "description",
            label: "描述",
            helpText: "设置覆盖在图片上的文字",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
            hidden: (props:any)=>{
              return !props.isDescription;
            },
            dependencies: ["isDescription"]
          }
        ],
      },
      {
        sectionName: "设置",
        children: [
          {
            propertyName: "objectFit",
            label: "填充方式",
            helpText: "",
            controlType: "DROP_DOWN",
            placeholderText: "确定",
            isBindProperty: true,
            isTriggerProperty: false,
            options: [
              {
                label: "适合Contain",
                value: 'contain',
              },
              {
                label: "平铺Cover",
                value: 'cover',
              },
              {
                label: "自动Fill",
                value: 'fill',
              },
            ],
            size: 1,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "isVisible",
            helpText:
              "Controls the visibility of the widget. Can also be configured the using {{currentItem}} binding.",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: true,
            validation: {
              type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
              params: {
                type: ValidationTypes.BOOLEAN,
              },
            },
            customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
            evaluatedDependencies: ["sourceData"],
          },
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "边框&阴影",
        children: [
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      isValid: `{{!!this.value}}`,
      getValue: `{{ this.value }}`
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  // TODO Find a way to enforce this, (dont let it be set)
  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  getPageView() {
    const {maxZoomLevel, objectFit, value, isDescription, description, uploadValue} = this.props;

    return (
      <ImageComponent
        borderRadius={this.props.borderRadius}
        boxShadow={this.props.boxShadow}
        defaultImageUrl={this.props.defaultImage}
        disableDrag={(disable: boolean) => {
          this.disableDrag(disable);
        }}
        enableDownload={this.props.enableDownload}
        enableRotation={this.props.enableRotation}
        value={value}
        uploadValue={uploadValue}
        isLoading={this.props.isLoading}
        maxZoomLevel={maxZoomLevel}
        objectFit={objectFit}
        onClick={this.props.onClick ? this.onImageClick : undefined}
        showHoverPointer={this.props.renderMode === RenderModes.PAGE}
        widgetId={this.props.widgetId}
        isDescription={isDescription}
        description={description}
      />
    );
  }

  componentDidMount() {
  }

  onImageClick() {
    if (this.props.onClick) {
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: this.props.onClick,
        event: {
          type: EventType.ON_CLICK,
        },
      });
    }
  }

  static getWidgetType(): WidgetType {
    return "X_IMAGE_WIDGET";
  }
}

export type ImageShape = "RECTANGLE" | "CIRCLE" | "ROUNDED";

export interface ImageWidgetProps extends WidgetProps {
  value: string;
  imageShape: ImageShape;
  defaultImage: string;
  maxZoomLevel: number;
  imageRotation?: number;
  enableDownload?: boolean;
  enableRotation?: boolean;
  objectFit: string;
  onClick?: string;
  borderRadius: string;
  boxShadow?: string;
}

export default ImageWidget;
