import IconSVG from "./icon.svg";
import Widget from "./widget";
import { DynamicHeight } from "utils/WidgetFeatures";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Calendar",
  iconSVG: IconSVG,
  needsMeta: true,
  // features: {
  //   dynamicHeight: {
  //     sectionIndex: 1,
  //     defaultValue: DynamicHeight.AUTO_HEIGHT,
  //     active: true,
  //   },
  // },
  defaults: {
    rows: 25,
    columns: 40,
    widgetName: "Calendar",
    version: 1,
    animateLoading: true,
    labelText: "Label",
    borderWidth: 1,
    dynamicHeight: DynamicHeight.AUTO_HEIGHT,
    currentData: [
      {
        "id": 1,
        "date": "2024-10-01"
      },
      {
        "id": 2,
        "date": "2024-10-12"
      },
      {
        "id": 3,
        "date": "2024-10-22"
      }
    ],
    borderRadius: 25,
    boxShadow: "none",

  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "360px",
            minHeight: "40px",
          };
        },
      },
    ],
  },
};

export default Widget;
