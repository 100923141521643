import IconSVG from "./icon.svg";
import Widget from "./widget";
import {LabelPosition} from "../../components/constants";
import {DynamicHeight} from "../../utils/WidgetFeatures";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Cascader",
  iconSVG: IconSVG,
  needsMeta: true,
  features: {
    dynamicHeight: {
      sectionIndex: 0,
      defaultValue: DynamicHeight.AUTO_HEIGHT,
      active: true,
    }
  },
  defaults: {
    isLast: false,
    isSearch: false,
    isMultiple: false,
    switchMode: true,
    rows: 3,
    columns: 20,
    labelText: 'Label',
    labelPosition: LabelPosition.Left,
    labelAlignment:"right",
    labelWidth:90,
    widgetName: "Cascader",
    version: 1,
    animateLoading: true,
    defaultValue: [],
    currentData: [
      {
        key: "1",
        value: 'zhejiang',
        label: '浙江',
        children: [
          {
            key: "1-2",
            value: 'hangzhou',
            label: '杭州',
            children: [
              {
                key: "1-3",
                value: 'xihu',
                label: '西湖',
              },
              {
                key: "1-4",
                value: 'shangcheng',
                label: '上城区',
              },
              {
                key: "1-5",
                value: 'xiacheng',
                label: '下城区',
              },
            ],
          },
        ],
      },
      {
        key: "2",
        value: 'jiangsu',
        label: '江苏',
        children: [
          {
            key: "2-1",
            value: 'nanjing',
            label: '南京',
            children: [
              {
                key: "2-2",
                value: 'zhonghuamen',
                label: '中华门',
              },
            ],
          },
        ],
      },
    ]
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "280px",
            minHeight: "40px",
          };
        },
      },
    ],
    disableResizeHandles: {
      vertical: true,
    }
  },
};

export default Widget;
