export default [
  { label: 'add-circle', value: 'add-circle' },
  { label: 'align-center', value: 'align-center' },
  { label: 'align-left', value: 'align-left' },
  { label: 'arrow-down', value: 'arrow-down' },
  { label: 'arrow-left', value: 'arrow-left' },
  { label: 'arrow-right', value: 'arrow-right' },
  { label: 'arrow-up', value: 'arrow-up' },
  { label: 'bell', value: 'bell' },
  { label: 'blocked', value: 'blocked' },
  { label: 'bookmark', value: 'bookmark' },
  { label: 'bullet-list', value: 'bullet-list' },
  { label: 'calendar', value: 'calendar' },
  { label: 'camera', value: 'camera' },
  { label: 'check-circle', value: 'check-circle' },
  { label: 'chevron-down', value: 'chevron-down' },
  { label: 'chevron-left', value: 'chevron-left' },
  { label: 'chevron-right', value: 'chevron-right' },
  { label: 'chevron-up', value: 'chevron-up' },
  { label: 'clock', value: 'clock' },
  { label: 'close-circle', value: 'close-circle' },
  { label: 'close', value: 'close' },
  { label: 'credit-card', value: 'credit-card' },
  { label: 'download-cloud', value: 'download-cloud' },
  { label: 'download', value: 'download' },
  { label: 'edit', value: 'edit' },
  { label: 'equalizer', value: 'equalizer' },
  { label: 'external-link', value: 'external-link' },
  { label: 'eye', value: 'eye' },
  { label: 'file-audio', value: 'file-audio' },
  { label: 'file-code', value: 'file-code' },
  { label: 'file-generic', value: 'file-generic' },
  { label: 'file-jpg', value: 'file-jpg' },
  { label: 'file-new', value: 'file-new' },
  { label: 'file-png', value: 'file-png' },
  { label: 'file-svg', value: 'file-svg' },
  { label: 'file-video', value: 'file-video' },
  { label: 'filter', value: 'filter' },
  { label: 'folder', value: 'folder' },
  { label: 'font-color', value: 'font-color' },
  { label: 'heart', value: 'heart' },
  { label: 'help', value: 'help' },
  { label: 'home', value: 'home' },
  { label: 'image', value: 'image' },
  { label: 'iphone-x', value: 'iphone-x' },
  { label: 'iphone', value: 'iphone' },
  { label: 'lightning-bolt', value: 'lightning-bolt' },
  { label: 'link', value: 'link' },
  { label: 'list', value: 'list' },
  { label: 'lock', value: 'lock' },
  { label: 'mail', value: 'mail' },
  { label: 'map-pin', value: 'map-pin' },
  { label: 'menu', value: 'menu' },
  { label: 'message', value: 'message' },
  { label: 'money', value: 'money' },
  { label: 'next', value: 'next' },
  { label: 'numbered-list', value: 'numbered-list' },
  { label: 'pause', value: 'pause' },
  { label: 'phone', value: 'phone' },
  { label: 'play', value: 'play' },
  { label: 'playlist', value: 'playlist' },
  { label: 'prev', value: 'prev' },
  { label: 'reload', value: 'reload' },
  { label: 'repeat-play', value: 'repeat-play' },
  { label: 'search', value: 'search' },
  { label: 'settings', value: 'settings' },
  { label: 'share-2', value: 'share-2' },
  { label: 'share', value: 'share' },
  { label: 'shopping-bag-2', value: 'shopping-bag-2' },
  { label: 'shopping-bag', value: 'shopping-bag' },
  { label: 'shopping-cart', value: 'shopping-cart' },
  { label: 'shuffle-play', value: 'shuffle-play' },
  { label: 'sketch', value: 'sketch' },
  { label: 'sound', value: 'sound' },
  { label: 'star', value: 'star' },
  { label: 'stop', value: 'stop' },
  { label: 'streaming', value: 'streaming' },
  { label: 'tag', value: 'tag' },
  { label: 'tags', value: 'tags' },
  { label: 'text-italic', value: 'text-italic' },
  { label: 'text-strikethrough', value: 'text-strikethrough' },
  { label: 'text-underline', value: 'text-underline' },
  { label: 'trash', value: 'trash' },
  { label: 'upload', value: 'upload' },
  { label: 'user', value: 'user' },
  { label: 'video', value: 'video' },
  { label: 'volume-minus', value: 'volume-minus' },
  { label: 'volume-off', value: 'volume-off' },
  { label: 'volume-plus', value: 'volume-plus' },
  { label: 'analytics', value: 'analytics' },
  { label: 'star-2', value: 'star-2' },
  { label: 'check', value: 'check' },
  { label: 'heart-2', value: 'heart-2' },
  { label: 'add', value: 'add' },
  { label: 'subtract-circle', value: 'subtract-circle' },
  { label: 'subtract', value: 'subtract' },
  { label: 'loading-3', value: 'loading-3' },
  { label: 'alert-circle', value: 'alert-circle' },
  { label: 'loading-2', value: 'loading-2' },
  { label: 'loading', value: 'loading' },
];
