import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XEchartsFunnel",
  iconSVG: IconSVG,
  defaults: {
    rows: 60,
    columns: 70,
    widgetName: "XEchartsFunnel",
    version: 1,
    label: "label",
    isDownload: false,
    isDataView: false,
    isTitle: true,
    isLegend: true,
    title: "Echart",
    radius: 50,
    isShowTab:true,
    borderWidth:1,
    tabsObj:{},
    currentData: [
      {
        legendData: ['Show', 'Click', 'Visit', 'Inquiry', 'Order'],
        data: [
          { value: 60, name: 'Visit' },
          { value: 40, name: 'Inquiry' },
          { value: 20, name: 'Order' },
          { value: 80, name: 'Click' },
          { value: 100, name: 'Show' }
        ]
      }
    ]
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
