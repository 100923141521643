import { ValidationTypes } from "constants/WidgetValidation";
import {ButtonVariantTypes} from "../../../../components/constants";
import {isAutoLayout} from "../../../../utils/autoLayout/flexWidgetUtils";

export default [
  {
    sectionName: "基本",
    children: [
      {
        propertyName: "tableSize",
        helpText: "Selects row height",
        label: "表格大小",
        controlType: "ICON_TABS",
        fullWidth: true,
        defaultValue: "DEFAULT",
        isBindProperty: true,
        isTriggerProperty: false,
        options: [
          {
            label: "小",
            value: "small",
          },
          {
            label: "中",
            value: "middle",
          },
          {
            label: "大",
            value: "default",
          },
        ],
      }
    ],
  },
  {
    sectionName: "标签样式",
    children: [
      {
        propertyName: "labelTextColor",
        label: "字体颜色",
        helpText: "Control the color of the label associated",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "labelTextSize",
        label: "字体大小",
        helpText: "Control the font size of the label associated",
        controlType: "DROP_DOWN",
        defaultValue: "0.875rem",
        hidden: isAutoLayout,
        options: [
          {
            label: "S",
            value: "0.875rem",
            subText: "0.875rem",
          },
          {
            label: "M",
            value: "1rem",
            subText: "1rem",
          },
          {
            label: "L",
            value: "1.25rem",
            subText: "1.25rem",
          },
          {
            label: "XL",
            value: "1.875rem",
            subText: "1.875rem",
          },
          {
            label: "XXL",
            value: "3rem",
            subText: "3rem",
          },
          {
            label: "3XL",
            value: "3.75rem",
            subText: "3.75rem",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "labelStyle",
        label: "加粗&斜体",
        helpText: "Control if the label should be bold or italics",
        controlType: "BUTTON_GROUP",
        options: [
          {
            icon: "text-bold",
            value: "BOLD",
          },
          {
            icon: "text-italic",
            value: "ITALIC",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "表头文字",
    children: [
      {
        propertyName: "textSizeHead",
        label: "字体大小",
        helpText: "Controls the size of text in the column",
        controlType: "DROP_DOWN",
        //updateHook: updateColumnStyles,
        dependencies: ["primaryColumns"],
        options: [
          {
            label: "S",
            value: "0.875rem",
            subText: "0.875rem",
          },
          {
            label: "M",
            value: "1rem",
            subText: "1rem",
          },
          {
            label: "L",
            value: "1.25rem",
            subText: "1.25rem",
          },
          {
            label: "XL",
            value: "1.875rem",
            subText: "1.875rem",
          },
        ],
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "textColorHead",
        label: "字体颜色",
        helpText: "Controls the color of text in the column",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "fontStyleHead",
        label: "加粗&斜体",
        helpText: "Controls the style of the text in the column",
        controlType: "BUTTON_GROUP",
        options: [
          {
            icon: "text-bold",
            value: "bold",
          },
          {
            icon: "text-italic",
            value: "italic",
          }
        ],
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "horizontalAlignmentHead",
        label: "水平对齐方式",
        helpText: "Sets the horizontal alignment of the content in the column",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          {
            startIcon: "align-left",
            value: "left",
          },
          {
            startIcon: "align-center",
            value: "center",
          },
          {
            startIcon: "align-right",
            value: "right",
          },
        ],
        defaultValue: "left",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["left", "center", "right"],
          },
        },
      },
    ],
  },
  {
    sectionName: "表格文字",
    children: [
      {
        propertyName: "textSizeBody",
        label: "字体大小",
        helpText: "Controls the size of text in the column",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "S",
            value: "0.875rem",
            subText: "0.875rem",
          },
          {
            label: "M",
            value: "1rem",
            subText: "1rem",
          },
          {
            label: "L",
            value: "1.25rem",
            subText: "1.25rem",
          },
          {
            label: "XL",
            value: "1.875rem",
            subText: "1.875rem",
          },
        ],
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "textColorBody",
        label: "字体颜色",
        helpText: "Controls the color of text in the column",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "fontStyleBody",
        label: "加粗&斜体",
        helpText: "Controls the style of the text in the column",
        controlType: "BUTTON_GROUP",
        options: [
          {
            icon: "text-bold",
            value: "bold",
          },
          {
            icon: "text-italic",
            value: "italic",
          },
        ],
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "horizontalAlignmentBody",
        label: "水平对齐方式",
        helpText: "Sets the horizontal alignment of the content in the column",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          {
            startIcon: "align-left",
            value: "left",
          },
          {
            startIcon: "align-center",
            value: "center",
          },
          {
            startIcon: "align-right",
            value: "right",
          },
        ],
        defaultValue: "left",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["left", "center", "right"],
          },
        },
      },
    ],
  },
  {
    sectionName: "表格背景",
    children: [
      {
        propertyName: "backgroundHead",
        label: "表头背景色",
        helpText: "Changes the background color of the cell",
        controlType: "COLOR_PICKER",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "isZebraStripe",
        label: "表格是否为斑马纹",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "backgroundBody1",
        label: "表格背景色1",
        helpText: "Changes the background color of the cell",
        controlType: "COLOR_PICKER",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "backgroundBody2",
        label: "表格背景色2",
        helpText: "Changes the background color of the cell",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (props: any) =>
          props.isZebraStripe!=true,
        dependencies: ["isZebraStripe"],
      },
      {
        propertyName: "backgroundMouseOver",
        label: "鼠标悬停背景色",
        helpText: "Changes the background color of the cell",
        controlType: "COLOR_PICKER",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      }
    ],
  },
  {
    sectionName: "边框&阴影",
    children: [
      {
        propertyName: "variant",
        helpText: "Selects the variant",
        label: "单元格边框",
        controlType: "DROP_DOWN",
        defaultValue: "DEFAULT",
        isBindProperty: true,
        isTriggerProperty: false,
        options: [
          {
            label: "默认",
            value: "DEFAULT",
          },
          {
            label: "无边框",
            value: "VARIANT2",
          },
          {
            label: "仅水平边框",
            value: "VARIANT3",
          },
        ],
      },
      {
        propertyName: "borderRadius",
        label: "边框圆角",
        helpText: "Rounds the corners of the icon button's outer border edge",
        controlType: "BORDER_RADIUS_OPTIONS",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "boxShadow",
        label: "阴影",
        helpText:
          "Enables you to cast a drop shadow from the frame of the widget",
        controlType: "BOX_SHADOW_OPTIONS",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText: "Use a html color name, HEX, RGB or RGBA value",
        placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
        propertyName: "borderColor",
        label: "外框颜色",
        controlType: "COLOR_PICKER",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText: "Enter value for border width",
        propertyName: "borderWidth",
        label: "外框",
        placeholderText: "Enter value in px",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.NUMBER },
      },
    ],
  },
  {
    sectionName: "操作列按钮",
    children: [
      {
        propertyName: "opBtnType",
        label: "类型",
        controlType: "ICON_TABS",
        fullWidth: true,
        helpText: "Sets the variant of the button",
        options: [
          {
            label: "主要按钮",
            value: ButtonVariantTypes.PRIMARY,
          },
          {
            label: "次要按钮",
            value: ButtonVariantTypes.SECONDARY,
          },
          {
            label: "文字按钮",
            value: ButtonVariantTypes.TERTIARY,
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: [
              ButtonVariantTypes.PRIMARY,
              ButtonVariantTypes.SECONDARY,
              ButtonVariantTypes.TERTIARY,
            ],
            default: ButtonVariantTypes.PRIMARY,
          },
        },
      },
      {
        propertyName: "opBtnBgColor",
        label: "按钮颜色",
        helpText: "Controls the color of text in the column",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (props: any) =>
          props.opBtnType == ButtonVariantTypes.TERTIARY,
      },
      {
        propertyName: "opBtnColor",
        label: "文字颜色",
        helpText: "Controls the color of text in the column",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "opBtnTextSize",
        label: "字体大小",
        helpText: "Controls the size of text in the column",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "S",
            value: "0.875rem",
            subText: "0.875rem",
          },
          {
            label: "M",
            value: "1rem",
            subText: "1rem",
          },
          {
            label: "L",
            value: "1.25rem",
            subText: "1.25rem",
          },
          {
            label: "XL",
            value: "1.875rem",
            subText: "1.875rem",
          },
        ],
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "opBtnFontStyle",
        label: "加粗&斜体",
        helpText: "Controls the style of the text in the column",
        controlType: "BUTTON_GROUP",
        options: [
          {
            icon: "text-bold",
            value: "BOLD",
          },
          {
            icon: "text-italic",
            value: "ITALIC",
          },
        ],
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "opBtnBorderRadius",
        label: "边框圆角半径",
        helpText:
          "Rounds the corners of the icon button's outer border edge",
        controlType: "BORDER_RADIUS_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.TEXT},
      },
      {
        propertyName: "opBtnBoxShadow",
        label: "阴影",
        helpText:
          "Enables you to cast a drop shadow from the frame of the widget",
        controlType: "BOX_SHADOW_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ]
  },
  {
    sectionName: "新增按钮",
    children: [
      {
        propertyName: "newBtnType",
        label: "类型",
        controlType: "ICON_TABS",
        fullWidth: true,
        helpText: "Sets the variant of the button",
        options: [
          {
            label: "主要按钮",
            value: ButtonVariantTypes.PRIMARY,
          },
          {
            label: "次要按钮",
            value: ButtonVariantTypes.SECONDARY,
          },
          {
            label: "文字按钮",
            value: ButtonVariantTypes.TERTIARY,
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: [
              ButtonVariantTypes.PRIMARY,
              ButtonVariantTypes.SECONDARY,
              ButtonVariantTypes.TERTIARY,
            ],
            default: ButtonVariantTypes.PRIMARY,
          },
        },
      },
      {
        propertyName: "newBtnBgColor",
        label: "按钮颜色",
        helpText: "Controls the color of text in the column",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (props: any) =>
          props.newBtnType == ButtonVariantTypes.TERTIARY,
      },
      {
        propertyName: "newBtnColor",
        label: "文字颜色",
        helpText: "Controls the color of text in the column",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "newBtnTextSize",
        label: "字体大小",
        helpText: "Controls the size of text in the column",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "S",
            value: "0.875rem",
            subText: "0.875rem",
          },
          {
            label: "M",
            value: "1rem",
            subText: "1rem",
          },
          {
            label: "L",
            value: "1.25rem",
            subText: "1.25rem",
          },
          {
            label: "XL",
            value: "1.875rem",
            subText: "1.875rem",
          },
        ],
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "newBtnFontStyle",
        label: "加粗&斜体",
        helpText: "Controls the style of the text in the column",
        controlType: "BUTTON_GROUP",
        options: [
          {
            icon: "text-bold",
            value: "BOLD",
          },
          {
            icon: "text-italic",
            value: "ITALIC",
          },
        ],
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "newBtnBorderRadius",
        label: "边框圆角半径",
        helpText:
          "Rounds the corners of the icon button's outer border edge",
        controlType: "BORDER_RADIUS_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.TEXT},
      },
      {
        propertyName: "newBtnBoxShadow",
        label: "阴影",
        helpText:
          "Enables you to cast a drop shadow from the frame of the widget",
        controlType: "BOX_SHADOW_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ]
  },
];
