import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TGridWidget from "../component";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";
import {LabelPosition} from "../../../components/constants";
import {MultiSelectWidgetProps} from "../../../widgets/MultiSelectWidgetV2/widget";

class TGrid extends TaroWidget<TGridWidgetProps, WidgetState> {
  constructor(props: TGridWidgetProps) {
    super(props);
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "currentData",
            label: "数据",
            helpText: "Sets the label of the button",
            controlType: "CAROUSEL_ARRAY",
            placeholderText: "确定",
            isBindProperty: true,
            isCarousel: true,
            isDescription: true,
            isTriggerProperty: true,
            isJSConvertible: false,
            validation: {type: ValidationTypes.ARRAY},
            iconSize: '58px',
          },
          {
            helpText:"",
            propertyName: "columnNum",
            label: "每列个数",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 1,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            }
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }


  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      isDirty: false,
      value: undefined
    };
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.value }}`
    };
  }

  updateWidgetProperty = (propertyValues: string) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty("value", propertyValues);
  }

  getPageView() {

    return (
      <TGridWidget {...this.props} updateWidgetProperty={this.updateWidgetProperty} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_GRID_WIDGET";
  }
}

export interface TGridWidgetProps extends WidgetProps {
  value: any[];
  enableDownload?: boolean;
  enableRotation?: boolean;
  onClick?: string;
  borderRadius: string;
  boxShadow?: string;
}

export default TGrid;
