import React from "react";
import styled from "styled-components";
import LabelWithTooltip, {LABEL_CONTAINER_CLASS, labelLayoutStyles} from "widgets/components/LabelWithTooltip";
import UploadWidget from "byk/pages/components/UploadWidget";
import {LabelPosition} from "components/constants";
import {strToJson} from "@byk/utils/Utils";

export const DivContainer = styled.div<any>`
  ${labelLayoutStyles}

  & .${LABEL_CONTAINER_CLASS} {
    align-self: flex-start;
    ${({labelPosition}) =>
      labelPosition === LabelPosition.Left && "min-height: 30px"};
  }

  .ant-btn {
     width: 100%;
     height: 100%!important;
     border-radius: ${({ borderRadius }) => borderRadius ||' 0.375rem'} !important;
     box-shadow: ${({ boxShadow }) => boxShadow ||'none'} !important;
     background:  ${({ib_bgColor}) => ib_bgColor ? ib_bgColor : 'var(--wds-color-bg)'} !important;
     border-width: ${({ borderWidth }) => `${borderWidth || 0}px  !important`};
     border-color: ${({ ib_borderColor }) => ib_borderColor} !important;
     border-style: solid !important;
     display: flex !important;
     align-items: center !important;
     color: ${({it_color}) => {
      return (it_color ? `${it_color}` : "var(--wds-color-text)") + ' !important'
    }};
  }

  .uploadWidget:hover .ant-btn {
    border-width: ${({ borderWidth }) => `${borderWidth || 1}px  !important`};
    border-color: ${({accentColor}) => accentColor} !important;
  }
`;

function CarouselComponent(props: any) {
  const {
    wrapperRef, imageData, autoplay, width, height, borderRadius, boxShadow, margin, padding, disabled,
    placeholderText1, placeholderText2,
    //LABEL START
    compactMode,
    isDisabled,
    isLoading,
    isRequired,//
    labelAlignment,//11
    labelPosition,//11
    labelStyle,//11
    label,//11
    labelTextColor,//11
    labelTextSize,//11
    labelTooltip,//11
    labelWidth,//11
    onRadioSelectionChange,
    value,
    fileSize,
    fileCount,
    btnText,
    accept,
    updateValue,
    isReadonly,
    fileList,
    it_color,
    it_size,
    it_style,
    ib_bgColor,
    ib_borderColor,
    ib_iconColor,
    borderWidth,
    accentColor,
  } = props;

  const setUploadData = (data: any) => {
    updateValue(data);
  }

  const getValue = (value: any, fileList: any) => {
    let _v = fileList || value;
    return strToJson(_v);
  }

  return (
    <>
      <DivContainer
        compactMode={compactMode}
        data-testid="radiogroup-container"
        labelPosition={labelPosition}
        borderRadius={borderRadius}
        borderWidth ={borderWidth}
        boxShadow={boxShadow}
        it_color={it_color}
        it_size={it_size}
        it_style={it_style}
        ib_bgColor={ib_bgColor}
        ib_borderColor={ib_borderColor}
        ib_iconColor={ib_iconColor}
        accentColor = {accentColor}
      >
        {label && (
          <LabelWithTooltip
            className={`datepicker-label`}
            text={label}
            cyHelpTextClassName="datepicker-tooltip"
            alignment={labelAlignment}
            color={labelTextColor}
            compact={compactMode}
            disabled={isDisabled}
            fontSize={labelTextSize}
            fontStyle={labelStyle}
            helpText={labelTooltip}
            isDynamicHeightEnabled={true}
            loading={isLoading}
            position={labelPosition}
            width={labelWidth}
            isRequired={isRequired}
          />
        )}
        <UploadWidget
          className={'uploadWidget'}
          onEmit={(data: string) => setUploadData(data)}
          accept={accept} disabled={disabled} isReadonly={isReadonly}
          files={getValue(value, fileList)}
          fileSize={fileSize} fileCount={fileCount} btnText={btnText}
        />

      </DivContainer>
    </>
  )
}

export default CarouselComponent;
