import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';


import 'codemirror/lib/codemirror.css';
import 'codemirror/lib/codemirror.js';

import 'codemirror/theme/dracula.css';
import 'codemirror/theme/base16-light.css';

import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/fold/foldcode.js';
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/hint/javascript-hint.js';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/lint/json-lint.js';
import 'codemirror/addon/lint/javascript-lint.js';
import 'codemirror/addon/display/placeholder.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/sql/sql.js';

const Editors = forwardRef((props: any, ref) => {
  const { option, editorList, editorListErr, getValue } = props;
  const [editorVal, sEditorVal] = useState('');
  const formRefs = useRef(null);
  const [editorBorder, sEditorBorder] = useState('none');

  // 将方法暴露给父组件使用
  useImperativeHandle(ref, () => ({
    setValue
  }));
  useEffect(() => {
    if (formRefs.current) {
    }
  }, [editorVal]);

  useEffect(() => {
    if (formRefs.current) {
    }
  }, [editorVal]);

  const onEditorDidMount = (editors: any) => {

  };//失焦点保存
  const setValue = (val: any) => {
    let aaa=val;
    sEditorVal(val);
  };
  const takeEditorValue = () => {
    let text = formRefs.current ? formRefs.current.editor.getValue() || '' : '';
    getValue(text);
  };
  const options = {
    autoRefresh: true,
    lineWrapping: true,//自动换行，取消横向滚动条
    lineNumbers: false,//显示行号
    mode: {name: "text/x-mysql"},
    extraKeys: { "Ctrl": "autocomplete" },
    theme: 'base16-light',
    width:"1200px",
    height: '800px',
    readOnly: false,
    autofocus:false,
    styleActiveLine: true,
    indentUnit: 4,
  };

  return (
    <CodeMirror
      ref={formRefs}
      editorDidMount={onEditorDidMount}
      value={editorVal}
      options={options}
      onBlur={() => {
        takeEditorValue();   //失去焦点保存
      }}
    />
  );
});

export default Editors;
