import IconSVG from "./icon.svg";
import Widget from "./widget";
import {SelectType} from "../../components/constants";
export const cellDefault = {
  isCellVisible: true,//是否显示属性
  isCellDisable: false,//是否禁用属性
  btnType: 'default',//按钮默认次要按钮
  boxShadowBtn: "none",//按钮投影
  borderRadiusBtn: "2",//按钮圆角
  btnIcon: "none",//按钮图标
  btnPosition: "left",//按钮图标位置
  btnTextAlign: "center",//按钮对齐方式
  columnReg: "$data",//默认值处理
}
export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "AntdTable",
  iconSVG: IconSVG,
  needsMeta: true,
  needsHeightForContent: true,
  defaults: {
    rows: 28,
    columns: 74,
    widgetName: "AntdTable",
    isVisible: true,
    isShowNo: true,
    label: '',
    currPage: 1,
    pageSize: 10,
    total: 10,
    selectedRowKeys: [],
    selectedRows: [],
    clickRow: {},
    pageSizeOptions: '10,20,50',//启用分页
    isPagination: true,//启用分页
    selectType: SelectType.Multiple,//行选择类型
    isRowSelection: false,//启用行选择
    actionCollapseIconColor: '#000',//折叠图标颜色
    actionCollapseIcon: 'drag-handle-vertical',//折叠图标
    tableActionNo: 3,//操作按钮可见数
    isActionCollapse: false,//是否折叠
    isActionVisible: true,//是否显示操作栏
    variant: 'DEFAULT',//表格边框类型
    compactMode:"middle",
    tableAction: {//操作栏
      edit: {
        id: 'edit',
        index: 0,
        label: '编辑',
        columnType: "button",
        ...cellDefault
      },
      delete: {
        id: 'delete',
        index: 1,
        label: '删除',
        columnType: "button",
        ...cellDefault
      }
    },
    mappingConfig: {
      currPage: {
        id: 'currPage',
        index: 0,
        label: '页码',
        type: "text"
      },
      pageSize: {
        id: 'pageSize',
        index: 1,
        label: '每页记录数',
        type: "text"
      },
      selectId: {
        id: 'selectId',
        index: 2,
        label: '单选Id',
        type: "text"
      },
      selectIds: {
        id: 'selectIds',
        index: 3,
        label: '多选Ids',
        type: "text"
      }
    },
    columnWidthMap: {
      name: 150,
      age: 150,
      address: 150
    },
    fixTableColumns: {
      _xh: {
        id: '_xh',
        index: 0,
        label: '序号',
        columnType: "text"
      },
      _cz: {
        id: '_cz',
        index: 1,
        label: '操作',
        columnType: "text"
      },
    },
    tableColumns: {
      id: {
        id: 'id',
        index: 0,
        label: 'id',
        columnType: "text",
        ...cellDefault,
        isCellVisible: false
      },
      name: {
        id: 'name',
        index: 1,
        label: '姓名',
        columnType: "text",
        ...cellDefault
      },
      age: {
        id: 'age',
        index: 2,
        label: '年龄',
        columnType: "text",
        ...cellDefault
      },
      address: {
        id: 'address',
        index: 3,
        label: '住址',
        columnType: "text",
        ...cellDefault
      }
    },
    tableData: [
      {
        id: '1',
        name: '胡彦斌',
        age: 32,
        address: '西湖区湖底公园1号',
      },
      {
        id: '2',
        name: '胡彦祖',
        age: 42,
        address: '西湖区湖底公园1号',
      }
    ],
    textSizeBody: '0.875rem',
    textSizeHead: '0.875rem',
    horizontalAlignmentBody: "left",
    horizontalAlignmentHead: "left",
    verticalAlignmentBody: "center",
    verticalAlignmentHead: "center",
    borderWidth: "1",
    version: 1,
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    eventConfig: Widget.getPropertyPaneEventsConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    loadingProperties: Widget.getLoadingProperties(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "280px",
            minHeight: "300px",
          };
        },
      },
    ],
  },
};

export default Widget;
