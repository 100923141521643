import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, message, Upload, UploadProps} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import Api from "@byk/api/Api";
import {uuid2} from "@byk/utils/Utils";
import {apiPath} from '@byk/routes';
import {getTenant} from 'utils/localStorage';
import styled from 'styled-components';

const UploadBtnContainerDiv = styled.div`
  position: relative;
  height: 35px;
  width: 160px;
  display: flex;
  justify-content: center;
  border: none;
  box-shadow: 0 2px #00000004;

  input {
    width: 160px;
    height: 35px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
`

const UploadFile = (props: any) => {
  const {fileSize, isPic, fileCount, files, isByk, onChange} = props;
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState(files);
  const accept = isPic ? ".jpg,.jpeg,.png" : "";

  useEffect(() => {
    setFileList(files);
  }, [files])

  const uploadChange = (file: any) => {
    const isLt = file.size / 1024 / 1024 < fileSize;
    if (!isLt) {
      message.warning(`上传文件大小不能超过 ${fileSize}MB!`).then();
      return false;
    } else {
      uploadPic(file);
    }
  };

  const onRemove = async (file: any) => {
    updateFileList(file, true);
  }

  const updateFileList = (file: any, isRemove: boolean = false) => {
    let list: any = [] = fileList.filter((item: any) => {
      return item.key != file.key;
    });
    if (!isRemove) {
      list = [...list, file];
    }

    setFileList([...list]);
    onChange && onChange(list);
  }

  const uploadPic = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      setLoading(true);
      let base64 = e.target['result'];
      base64 = base64.split('base64,')[1];
      let params = {
        data: base64,
        ext: file.name.substring(file.name.lastIndexOf('.')),
        filename: file.name,
        type: file.type,
      };
      let scope = isByk ? 'platform' : getTenant();
      let url: any = `${apiPath}/${scope}/rest/SysFile`;
      Api.post(url, params).then((res: any) => {
        if (res.success) {
          let data:any = res.result[0];
          let code = data['code'];
          let key = uuid2(16, 16);
          let url = `${apiPath}/${scope}/file/${code}`;
          let fileItem = {...file, name: data['filename'], status: 'done',code, key, url};
          updateFileList({...fileItem});
        } else {
          message.error(res.message).then();
        }
      }).finally(() => {
        setLoading(false);
      });
    };
  };

  // 文件上传前的检查
  const beforeUpload = (file: any) => {
    const fileType = file.name;
    const fileExtName = fileType.slice(fileType.lastIndexOf('.') + 1).toLowerCase();
    const isAccepted = accept.includes(fileExtName);
    if (!isAccepted && isPic) {
      message.error(`只能上传 ${accept} 格式的文件！`).then();
    }
    return isPic ? isAccepted : true;
  };

  const uploadProps: UploadProps = {
    fileList: fileList,
    listType: isPic ? 'picture-card' : 'text',
    maxCount: 5,
    accept: accept,
    onRemove: onRemove,
    beforeUpload: beforeUpload,
    customRequest: (option: any) => {
      let {file} = option;
      uploadChange(file);
    },
  };

  const uploadButton = () => {
    if (isPic) {
      return <div>
        <PlusOutlined/>
        <div style={{marginTop: 8}}>Upload</div>
      </div>
    } else {
      return <Button loading={loading} icon={<UploadOutlined/>}>上传文件</Button>
    }
  }

  return (
    <Upload {...uploadProps}>
      {fileList.length >= (fileCount || 3) ? null : uploadButton()}
    </Upload>
  );
};

export default UploadFile;
