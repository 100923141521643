import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import _ from "lodash";
import useSize from "./UseSize";
import { DEFAULT_FONT_SIZE, FontStyleTypes } from "constants/WidgetConstants";
import { fontSizeUtility, getCustomBorderColor } from "widgets/WidgetUtils";
import { stringToJS } from './../../../components/propertyControls/utils';
interface DataType {
  title: string,
  url: string
}
export interface MarqueeProps {
  pauseOnHover?: boolean,
  isPause: boolean,
  direction: string,
  speed: number,
  data: DataType[],
  backgroundColor:string,
  fontFamily:string,
  fontSize:string,
  fontStyle:string,
  textColor:string,
  borderRadius:string,
  boxShadow:string,
  borderWidth:string,
  borderColor:string
}

const GlobalStyle = createGlobalStyle<{
  start: number,
  end: number,
  size: number,
  xy: string
}>`
  @keyframes moving{
    from {
      transform: ${({start,xy})=> `translate${xy}(${start}px)`};
    }
    to {
      transform: ${({end, xy})=> `translate${xy}(${end}px)`};
    }
  }
  @keyframes movingItemized{
    0%{
      transform: ${({start, end, xy})=> `translate${xy}(${start}px)`};
    }
    ${ ({size, start, xy, end})=>{
      let n1 = 100/size;
      let _bol = end == 0;//变右下时
      let _width = _bol?start:end;
      let arr = Array.from({length:size}, (v,k) => k+1);
       return arr.map((i)=>{
         let _n = _bol?(size-i):i;
         return `
           ${n1*(i-1) + n1*0.2}% {
            transform: translate${xy}(${_n*_width/size}px);
           }
           ${n1*i}% {
            transform: translate${xy}(${_n*_width/size}px);
           }
           `
         })
         
      } 
    }
    
  }
`
//  border-width: ${({ borderWidth }) => `${ _.isEmpty(borderWidth)?0:borderWidth}px  !important`};
//border: ${({ borderColor,borderWidth }) =>  borderColor!==""?(borderWidth!==""?`${borderWidth} solid ${borderColor}`:`1px solid ${borderColor}`):'none'} !important;
export const UlWrapper = styled.div<{
  display: string,
  speed: number,
  width: number,
  size: number,
  backgroundColor:string,
  fontFamily:string,
  fontSize:string,
  fontStyle:string,
  textColor:string,
  borderRadius:string,
  boxShadow:string,
  borderWidth: any,
  borderColor:string
}>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow:hidden;
  border-radius: ${({ borderRadius }) => `${borderRadius==""?"0":(borderRadius)}px`} !important;
  box-shadow: ${({ boxShadow }) => `${boxShadow ?? "none"}`} !important;
  border-width: ${({ borderWidth }) => `${borderWidth}px  !important`};
  border-color: ${({ borderColor }) => `${ _.isEmpty(borderColor)?"rgba(0, 0, 0, 0.5)":borderColor}  !important`};
  ul{
    width: ${({width, size})=>width*size}px;
    height: 100%;
    display: ${({display})=>display};
    animation: moving  ${({speed})=>speed}s infinite linear;
  }
  .itemizedUl{
    animation: movingItemized  ${({speed})=>speed}s ease infinite;
  }
  li{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:${({backgroundColor})=>backgroundColor};
  }
  li span{
    font-family: ${({fontFamily})=>fontFamily};
    font-size: ${({ fontSize }) =>
    fontSizeUtility(fontSize) || DEFAULT_FONT_SIZE};
    font-style: ${({fontStyle}) =>
    fontStyle&&fontStyle.includes(FontStyleTypes.ITALIC) ? "italic" : ""};
    text-decoration: ${({fontStyle}) =>
    fontStyle&&fontStyle.includes(FontStyleTypes.UNDERLINE) ? "underline" : ""};
    font-weight: ${({fontStyle}) =>
    fontStyle&&fontStyle.includes(FontStyleTypes.BOLD) ? "bold" : "normal"};
    color:${({textColor})=>textColor};
  }
  
`;
function Marquee(props:MarqueeProps={
  pauseOnHover: true,
  direction: 'left',//left up right down
  speed: 5,
  data: [],
  isPause: false,
  backgroundColor: '#fff',
  fontFamily:"",
  fontSize:"",
  fontStyle:"",
  textColor:"",
  borderRadius:"",
  boxShadow:"",
  borderWidth: "",
  borderColor:""
}){
  const {data, direction, speed, isPause,backgroundColor,fontFamily,fontSize,fontStyle,textColor,borderRadius,boxShadow,
    borderWidth,
    borderColor} = props;
  const wrapperRef = useRef<any>(null);
  const { width, height } = useSize(wrapperRef);
  const [reg, setReg] = useState<{
    start: number,
    end: number,
    xy: string,
    display: string
  }>({
    start: 0,
    end: 0,
    xy: '',
    display: ''
  })

  useEffect(()=>{
    if(width==0||height==0){
      return ;
    }
    let _display = 'block',_xy = 'Y';
    const len = data.length;
    let _start = 0, _end = -width*len;
    if(['left','right'].includes(direction)){
      _display='flex';
      _xy = 'X';
    }
    if(direction == 'right'){
      _start = -width*len;
      _end = 0;
    }
    if(direction == 'up'){
      _end = -height*len;
    }
    if(direction == 'down'){
      _start = -height*len;
      _end = 0;
    }
    //console.log('*****', _start, _end, width, height, _xy, direction);
    setReg(reg=>({
      start: _start,
      end: _end,
      xy: _xy,
      display: _display
    }))
  }, [direction, data, width, height])

  //返回数据，在反向时，倒序data
  const reData = useCallback(()=>{
    let _data = _.clone(data)||[];
    _data = [..._data, ..._data];
    return _data;
  }, [data]);


  return (
    <UlWrapper ref={wrapperRef}
               display={reg.display}
               speed={speed}
               width={width}
               backgroundColor={backgroundColor}
               fontFamily={fontFamily}
               fontSize={fontSize}
               fontStyle={fontStyle}
               textColor={textColor}
               borderRadius={borderRadius}
               boxShadow={boxShadow}
               borderWidth={borderWidth}
               borderColor={borderColor}
               size={data.length*2}
    >
      <GlobalStyle start={reg.start} end={reg.end} xy={reg.xy} size={data.length} />
      <ul className={isPause?'itemizedUl':''}>
      {
        reData().map((i,idx:number)=>{
          return <li key={idx}><span>{i.title}</span></li>
        })
      }
      </ul>
    </UlWrapper>
  )
}
export default Marquee;
