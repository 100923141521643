import React from "react";
import BaseWidget, { WidgetState } from "../../../widgets/BaseWidget";
import { WidgetType } from "../../../constants/WidgetConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { PropertyPaneConfig } from "../../../constants/PropertyControlConstants";
import DrawerWidget from "../component";
import { EventType, type ExecutionResult } from "constants/AppsmithActionConstants/ActionConstants";

class Drawer extends BaseWidget<any, WidgetState> {
  constructor(props: any) {
    super(props);
  }
  static getPropertyPaneEventConfig(){
    return super.getWidgetEvents('DRAWER_WIDGET');
  }
  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: '基础',
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "label",
            label: "组件标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Sets the label text of the widget",
            propertyName: "title",
            label: "标题",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Sets direction.",
            propertyName: "placement",
            label: "抽屉方向",
            controlType: "DROP_DOWN",
            defaultValue: "left",
            options: [
              {
                label: "左",
                value: "left",
              },
              {
                label: "右",
                value: "right",
              },
              {
                label: "上",
                value: "top ",
              },
              {
                label: "下",
                value: "bottom",
              }
            ],
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: ["left", "right", "top", "bottom"],
              },
            },
          },
          {
            propertyName: "closable",
            helpText: "",
            label: "是否显示左上角的关闭按钮",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "keyboard",
            helpText: "",
            label: "是否支持键盘 esc 关闭",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "forceRender",
            helpText: "",
            label: "预渲染 Drawer 内元素",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ]
      }

    ];
  }
  handleActionResult = (result: ExecutionResult) => {};
  onClose = () => {
    if (this.props.onClose) {
      super.executeAction({
        triggerPropertyName: "onClose",
        dynamicString: this.props.onClose,
        event: {
          type: EventType.ON_CLOSE,
          callback: this.handleActionResult,
        },
      });
    }
  };
  onOpen = () => {
    if (this.props.onOpen) {
      super.executeAction({
        triggerPropertyName: "onOpen",
        dynamicString: this.props.onOpen,
        event: {
          type: EventType.ON_OPEN,
          callback: this.handleActionResult,
        },
      });
    }
  };
  getPageView() {
    return (
      <>
        <DrawerWidget {...this.props}  closeEvent={this.onClose}  openEvent={this.onOpen}/>
      </>
    )
  }
  static getWidgetType(): WidgetType {
    return 'DRAWER_WIDGET';
  }
}

export default Drawer;
