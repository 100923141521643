import React from "react";
import CarouselWidget from '../component';
import BaseWidget, { WidgetState } from "../../../widgets/BaseWidget";
import { WidgetType } from "../../../constants/WidgetConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { EventType, type ExecutionResult } from "constants/AppsmithActionConstants/ActionConstants";
import { GRID_DENSITY_MIGRATION_V1 } from "widgets/constants";
import {
  isAutoHeightEnabledForWidget
} from "widgets/WidgetUtils";
import type { DerivedPropertiesMap } from "utils/WidgetFactory";
import { EvaluationSubstitutionType } from "entities/DataTree/types";
import {Stylesheet} from "../../../entities/AppTheming";
class Carousel extends BaseWidget<any, WidgetState> {
  constructor(props: any) {
    super(props);
  }
  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('CalendarWidget');
  }
  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "labelText",
            label: "标签",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isVisible",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },

      {
        sectionName: "数据",
        children: [
          {
            propertyName: "currentData",
            helpText: "Reference or write an array to display in the List.",
            label: "Data",
            controlType: "INPUT_TEXT",
            placeholderText: "[{id: 1,date: '2024-10-01'}]",
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                default: [],
              },
            },
            evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
          }]
      }
    ];
  }
  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "月份文字",
        children: [
          {
            propertyName: "monthColor",
            helpText: "Sets the icon color of the widget",
            label: "文字颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "monthFontSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "monthFontStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "星期文字",
        children: [
          {
            propertyName: "weekColor",
            helpText: "Sets the icon color of the widget",
            label: "文字颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "weekFontSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "weekFontStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "日期文字",
        children: [
          {
            propertyName: "dateColor",
            helpText: "Sets the icon color of the widget",
            label: "文字颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "dateFontSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "dateFontStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "颜色",
        children: [
          {
            propertyName: "selectColor",
            helpText: "Sets the icon color of the widget",
            label: "日期选中色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            propertyName: "markColor",
            helpText: "Changes the color of the recorder button",
            label: "日程提醒色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ]
      },
      {
        sectionName: "边框&阴影",
        children: [
          {

            propertyName: "borderWidth",
            label: "边框宽度",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.value }}`,
      getValue: `{{ this.value }}`,
    };
  }
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      value: ''
    };
  }
  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue"
    };
  }

  getPageView() {
    let primaryColorTheme = this.getThemeSettingsByKey('colors', 'primaryColor');
    return (
      <>
        <CarouselWidget
          {...this.props}
          selectColor={this.props.selectColor || primaryColorTheme}
          value={this.props.value}
          currentData={this.props.currentData}
          widgetId={this.props.widgetId}
          key={this.props.widgetId}
          isDynamicHeightEnabled={isAutoHeightEnabledForWidget(this.props)}
          labelWidth={this.getLabelWidth()} compactMode={!((this.props.bottomRow - this.props.topRow) / GRID_DENSITY_MIGRATION_V1 > 1)}
          onChange={this.onChange}
          onClick={this.onClick}
          onEnter={this.onEnter}
          onValueChange={this.onValueChange}
        />
      </>
    )
  }

  onValueChange = (updatedValue: any) => {
    console.log(this.props);
    this.props.updateWidgetMetaProperty("value", updatedValue);
  };

  handleActionResult = (result: ExecutionResult) => { };
  onChange = (val: any) => {
    const _str = super.reDynamicStringFn(this.props.onTextChanged as string);
    this.props.updateWidgetMetaProperty(
      "value",
      val, {
      triggerPropertyName: "onTextChanged",
      dynamicString: _str,
      event: {
        type: EventType.ON_TEXT_CHANGE,
      },
    }
    );
  };

  componentDidMount(): void {
    this.onLoadFn();
  }

  onLoadFn() {
    const { onLoad } = this.props;
    if (onLoad) {
      super.executeAction({
        triggerPropertyName: "onLoad",
        dynamicString: onLoad,
        event: {
          type: EventType.ON_PAGE_LOAD,
        }
      })
    }
  }

  onClick = (val: any) => {
    this.props.updateWidgetMetaProperty(
      "value",
      val
    );
    if (this.props.onItemClick) {
      const _str = super.reDynamicStringFn(this.props.onItemClick as string);
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: _str,
        event: {
          type: EventType.ON_CLICK,
          callback: this.handleActionResult,
        },
      });
    }
  };

  static getWidgetType(): WidgetType {
    return 'CALENDAR_WIDGET';
  }
}

export default Carousel;
