import React from "react";
import SearchForm from "@byk/pages/components/SearchForm";
import TableList from "@byk/pages/components/TableList";
import XModal from "@byk/pages/components/XModal";
import PopupMapping from "./PopupMapping";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Modal, Tooltip, Drawer, Form, Input, Select, message, Divider, Table, Tabs, Affix, Row, Col, Tag, Descriptions } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import moment from 'moment';
import history from "utils/history";
import _, { set, stubFalse } from "lodash";
import { CheckCircleOutlined, RightSquareOutlined, ForwardOutlined, CheckSquareOutlined, ControlOutlined, BulbOutlined, CheckOutlined, CreditCardOutlined, ArrowLeftOutlined, ArrowRightOutlined, ApiOutlined, DisconnectOutlined } from '@ant-design/icons';
import { Container } from "design-system-old";
import localStorage, {getTenantId, setTenant} from "utils/localStorage";
import {appTenantClass, cutString, removeDuplicates} from "@byk/utils/Utils";
import {apiPath, routePath} from "@byk/routes";
const { TabPane } = Tabs;
class MyTask extends React.Component {
  searchRef: React.RefObject<any>;
  modalRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  formRef: React.RefObject<any>;
  formInputRef: React.RefObject<any>;
  formOutputRef: React.RefObject<any>;
  formOwerRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.searchRef = React.createRef();
    this.modalRef = React.createRef();
    this.tableRef = React.createRef();
    this.formRef = React.createRef();
    this.formInputRef = React.createRef();
    this.formOutputRef = React.createRef();
    this.formOwerRef = React.createRef();
  }
  state = {
    record: null,
    visible: false,
    rows: [],
    roleData: [],
    editData: null,
    detailData: {},
    open: false,
    open1: false,
    actionId: null,
    initialValues: {
      id: null, serviceCode: "",
    },
    tableArrayList0: [],
    tableArrayList: [],
    tableArrayList1: [],
    fieldNameData: {},
    fieldNameData1: {},
    fieldData: [],
    fieldData1: [],
    fieldArray: {},
    fieldArray1: {},
    serviceList: [],
    tabKey: "1",
    userList: [],//指派人
    appUserList: [],
    projectId: "",
    appId: "",
    initParams: {
      includeRelated: true,
      includeList: true,
      tenantId: localStorage.getItem(appTenantClass.getUserId() + '_tenantId'),
      appId: this.props.appId,
      pageId: this.props.pageId,
      projectId:this.props.projectId||"",
      queryMode: "allLike",
      desc: "createDate",
      taskOwner: this.props.type == "c" ? appTenantClass.getUserId() : ""
    },
    inputData: {},
    outData: {},
    fieldInfoAll: [],
    fieldInfoAll1: [],
    fieldAll: [],
    chooseProjectId: null,
    serviceId: null,
    isClosed: false,
    loading: false,
    type: this.props.type,
    openPopupRecord: {},
    isOpenPopup: false
  };
  that: any;
  componentDidMount() {
    if(this.props.type == "c"){
      let _initParams={...this.state.initParams};
      _initParams["taskOwner"]= appTenantClass.getUserId();
      console.log("_initParams",_initParams);
      this.setState({initParams:_initParams})
    }
    this.bindUserList(1);
    this.props.onRef && this.props.onRef(this);
  }
  // api
  apiGet: string = "/byk/platform/rest/Task/list";
  apiPost: string = "/byk/platform/rest/Task";
  apiPut: string = "/byk/platform/rest/Task";
  apiDel: string = "/byk/platform/rest/Task";
  apiActionPost: string = "/byk/platform/rest/AppAction";
  apiActionGet: string = "/byk/platform/rest/AppAction/list";
  apiLowcodeServiceGet: string = "/byk/platform/rest/LowcodeService/list";
  apiUserGet: string = "/byk/platform/rest/SysUser/list";
  apiAppUserGet: string = "/byk/platform/rest/UserApp/list";
  tenantId: any = getTenantId();

  async bindUserList(id: any) {
    let j = { tenantId: this.tenantId, projectId: this.props.projectId };
    this.bindAppUserList(j, "userList");
  };
  async bindAppUserList(obj: any, key: any) {
    let res: any = await Api.get(this.apiAppUserGet, { ...obj, includeRelated: true });;
    if (res.success) {
      let list = res.result;
      let _list: any = [];
      _.each(list, (item: any) => {
        _list.push({ label: `${item.name || item.userName}[${item.userMobile}]`, value: item.userId })
      })
      let m: any = {};
      let l = removeDuplicates(_list, "label", "value");
      m[key] = l;
      this.setState(m);
    }
  };

  callback(_data: any, type: any) {

  };

  render() {
    //表格refresh
    const refreshTable = (params: object = {}) => {
      let s: any = searchParam();
      if (this.tableRef?.current) {
        this.tableRef.current.getTableList({ ...params, ...s });
      }
    };
    const showDrawer = () => {
      this.setState({ open: true, isClosed: false }, () => {
        disabledBtn()
      });
      this.formRef.current.resetFields();
    };
    const showDrawer1 = (data?: any) => {
      this.setState({ open1: true }, () => {
        this.formOwerRef.current?.setFieldsValue({ taskOwner: data.taskOwner });
      });
    };

    const onClose = () => {
      this.setState({ open: false });
    };
    const onClose1 = () => {
      this.setState({ open1: false });
    };
    //保存成功后
    const afterSaveEvent = (params?: any) => {
      let _r = params.result[0];
      Modal.success({
        title: '保存成功',
        icon: <CheckCircleOutlined />,
        content: '',
        okText: '服务编排',
        cancelText: '返回列表',
        onOk() { redirectUrl(_r) },
      });
    };
    //改造提交的data
    const reformData = (params?: any, type?: any) => {
      return params;
    };
    const redirectUrl = (record: any) => {
      history.push(`${routePath}/ServiceSetup?id=${record.id}`);
    };


    // //绑定serviceList
    // const bindUserList = async (id: any) => {
    //   let res: any = await Api.get(this.apiUserGet, {});;
    //   if (res.success) {
    //     let list = res.result;
    //     this.setState({ userList: list });
    //   }
    // };
    const setEditUrl = async (id: any, cb?: (url?: string) => void) => {
      let result: any = await Api.get("/byk/platform/rest/SysProject", { id });
      let defaultSubAppId = result.result[0]['sysApps'][0]['id'];
      let resultPage: any = await Api.get("/byk/platform/rest/Page/list", {
        appId: defaultSubAppId
      });
      let defaultPage = {
        pageId: resultPage.result[0]['id'],
        pageName: resultPage.result[0]['slug']//['name']
      }
      if (result.success && resultPage.success) {
        let edit_url = `${routePath}/app/${defaultSubAppId}/${defaultPage.pageName}-${defaultPage.pageId}/edit`;
        cb && cb(edit_url);
      }
    }
    const editApp = (projectId: string, type?: string) => {
      setEditUrl(projectId, (url?: string) => {
        if (type == "new") {
          url && window.open(url);
        }
        else {
          url && history.push(url);
        }

      })
    };
    const switchTenant = async (tenant: string)=>{
      let prefix_url = `${apiPath}/platform/switchTenant`;
      return Api.get(prefix_url, { tenant });
    }
    //改版映射侧滑
    const openPopupMapping = async (record:any)=>{
      debugger;
      await switchTenant(record.tenant);
      setTenant(record.tenant);
      this.setState({
        isOpenPopup: true,
        openPopupRecord: record
      })
    }
    const updateIsOpenPopup = (bool:boolean)=>{
      this.setState({
        isOpenPopup: bool
      })
    }

    //状态变更
    const UpdateStatus = async (record: any, action: any, type: any) => {
      await switchTenant(record.tenant);
      setTenant(record.tenant);
      let status = null;
      if (action == 1 || action == 3 || action == 5) {
        status = "inProgress";
        if (action == 3) {
          status = "completed";
        }
        if (action == 5) {
          status = "inProgress";
        }
        let result: any = await Api.put(this.apiPost, { id: record.id, status: status });
        if (result.success) {
          refreshTable();
        }
        if (action == 1 && record.taskType == "frontendTask") {
          editApp(record.projectId.toString());
        }
      }
      if (action == 2) {
        editApp(record.projectId.toString());
      }
      if (action == 6) {
        this.setState({ detailData: {} });
        let _data = record;
        let result: any = await Api.get(this.apiGet, { id: record.id });
        if (result.success) {
          if (result.result && result.result.length > 0) {
            let r = result.result[0];
            this.setState({ detailData: r });
            let j = { projectId: record.projectId };
            this.bindAppUserList(j, "appUserList");
            this.bindUserList(1);
            showDrawer1(record);
          }
        }
      }
      if (action == 4) {
        logTime("开始");
        this.formInputRef.current?.resetFields();
        this.formOutputRef.current?.resetFields();
        this.setState({
          detailData: {},
          tableArrayList0: [],
          tableArrayList: [],
          tableArrayList1: [],
          fieldNameData: {},
          fieldNameData1: {},
          fieldData: [],
          fieldData1: [],
          fieldArray: {},
          fieldArray1: {},
          tabKey: "1",
          serviceList: [],
          inputData: {},
          outData: {},
          fieldInfoAll: [],
          fieldInfoAll1: [],
          fieldAll: [],
          serviceId: null,
          loading: false
        });
        let result: any = await Api.get(this.apiActionGet, { id: record.actionId });
        let serviceCode: any = null;
        let serviceId: any = null;
        this.setState({ chooseProjectId: record.projectId });
        if (result.success) {

          if (result.result && result.result.length > 0) {
            let actionInfo = result.result[0];
            serviceCode = actionInfo.serviceCode;
            serviceId = actionInfo.serviceId == null ? null : ("service" + actionInfo.serviceId);
            //debugger;
            let _serviceList = await bindServiceList(record.tenant);
            let r: any = getTableInfoByCode(serviceId, _serviceList);
            //bindServiceList(record.tenant);//绑定服务option
            this.setState({ serviceList: _serviceList }, () => {
              //let r:any=getTableInfoByCode(serviceCode);
            });
            // let result1: any = await Api.get("/byk/modeling/list", { tenant: actionInfo.projectId });
            // if (result1.success && result1.result.length > 0) {

            //   let _t = result1.result;
            let _tn: any = []
            //注释模型
            // _.each(_t, (itm) => {
            //   itm.tableName = itm.name;
            //   itm.name = (itm.name + "-【" + itm.description + "】");
            //   _tn.push(itm);
            // })
            _tn.push({ tableName: "NULL", name: "自定义", id: "NULL" })
            //console.log("表下拉数据",_tn);

            //this.setState({ tableArrayList: [..._tn,...r.input], tableArrayList1: [..._tn,...r.out] ,tableArrayList0:_tn})

            //初始化fieldListArray -模型-字段树形结构
            //bindFieldNameDataNew();

            let _fieldData = eval("(" + actionInfo.pageFieldsFormat + ")").dsl.children;
            //console.log("_fieldData",_fieldData);
            let _all = calleArr(_fieldData, []);
            //debugger;
            //console.log("_all",_all);
            //debugger;
            //this.formRef.current?.resetFields();
            this.setState({
              initialValues: { id: record.actionId, serviceCode: serviceCode, serviceId: serviceId, requestModelId: actionInfo.requestModelId, responseModelId: actionInfo.responseModelId },
              actionId: record.actionId, fieldData: _fieldData, fieldData1: _fieldData, tabKey: "1", fieldAll: _all,
              tableArrayList: [...r.input, ..._tn], tableArrayList1: [...r.out, ..._tn], tableArrayList0: _tn, serviceId: serviceId,
              detailData: record
            }, () => {
              bindFieldNameDataNew();
              showDrawer();
            })
            logTime("开始打开");
            //showDrawer();
            logTime("结束打开");
            logTime("开始绑定");
            bindInputValues(actionInfo, 1);//绑定requestFormData
            bindInputValues(actionInfo, 2);//绑定resposeFormData
            logTime("结束绑定");
            logTime("开始打开");

            // }
            // else {
            //   message.warning('任务对应的子应用还未创建模型！');
            // }
          }
        }
        logTime("结束");
      }
      if (type != 1) {
        onClose1();
      }

    };

    const calleArr = (array: any, _data: any) => {
      for (let i in array) {
        var data = array[i];
        if (data.children) {
          calleArr(data.children, _data) //自己调用自己
        } else {
          _data.push({ key: data.key, label: data.label })
        }
      }
      return _data;
    }
    const unAutoMap = () => {
      this.formInputRef.current?.resetFields();
      this.formOutputRef.current?.resetFields();
      message.success("取消关联成功！");
    }
    const viewPage = () => {
      editApp(this.state.chooseProjectId.toString(), "new");
    }
    const tabClick = (key: any) => {
      this.setState({ tabKey: key });
    }

    const logTime = (msg: any) => {
      let date = new Date();
      //分别获得年月日时分秒星期，并拼上相应的字符串
      //let year = date.getFullYear()+'年';
      //let month = date.getMonth()+1+'月';
      //let dat = date.getDate()+'日'
      let hours = date.getHours();
      let minu = date.getMinutes();
      let seco = date.getSeconds();

      //let day = '星期'+'日一二三四五六'.charAt(date.getDay())//字符串对应下标
      //let apm = hours>12?'pm':'am';//三元表达式判断上下午
      //可以自行改变位置
      //log-zz  console.log("【" + msg + "】" + hours + ':' + minu + ":" + seco);
    }
    //const [form1] = Form.useForm();
    const serviceCodeChange = (val: any) => {
      //debugger;
      let _serviceId = this.state.serviceId;
      if (_serviceId != val) {
        let inputMapData = this.formInputRef.current.getFieldsValue();
        let outputMapData = this.formOutputRef.current.getFieldsValue();
        let _dataInput: any = { ...inputMapData };
        let _dataOut: any = { ...outputMapData };
        for (let key in inputMapData) {
          if (inputMapData.hasOwnProperty(key)) {
            let val = inputMapData[key];
            if (!_.isEmpty(val) && val.indexOf("service") != -1) {
              _dataInput[key] = null;
            }
          }
        }
        for (let key in outputMapData) {
          if (outputMapData.hasOwnProperty(key)) {
            let val = outputMapData[key];
            if (!_.isEmpty(val) && val.indexOf("service") != -1) {
              _dataOut[key] = null;
            }
          }
        }
        this.formInputRef.current.setFieldsValue(_dataInput);
        this.formOutputRef.current.setFieldsValue(_dataOut);
      }

      console.log("服务code", val);
      console.log("服务", this.state.serviceList);
      console.log("表名", this.state.tableArrayList);
      console.log("字段", this.state.fieldNameData);
      console.log("字段ALL", this.state.fieldArray);
      let r: any = getTableInfoByCode(val);
      this.setState({
        tableArrayList: [...this.state.tableArrayList0, ...r.input], serviceId: val,
        tableArrayList1: [...this.state.tableArrayList0, ...r.out]
      }, () => {
        //debugger;
        //bindFieldNameDataNew();
        console.log("字段ALL0", this.state.tableArrayList);
        console.log("字段ALL1", this.state.tableArrayList1);
        autoMap();
      });

    }
    const getTableInfoByCode = (val: any, list?: any) => {
      let _list = _.isEmpty(list) ? [...this.state.serviceList] : list;
      let _obj = _list.filter((itm: any) => {
        return itm.id == val;
      })
      let r: any = {}
      if (_obj.length > 0) {
        let obj1: any = [...[_obj[0]]];
        let obj2: any = [...[_obj[0]]];
        obj1.tableName = obj1.name;
        obj2.tableName = obj2.name;
        obj1.properties = _.filter(obj1.param, item => item.name != "includeList" && item.name != "includeRelated");
        obj2.properties = obj1.result;
        r = { input: obj1, out: obj2 };
        // this.setState({ tableArrayList: m1, tableArrayList1: m2 },()=>{
        //   debugger;
        //   bindFieldNameDataNew();
        // });
      }
      else {
        r = { input: [], out: [] };
      }
      return r;
    }
    const autoMap = () => {
      //debugger;
      if (_.isEmpty(this.state.serviceId)) {
        message.warning("请先选择服务！");
        return false;
      }
      let _all = this.state.fieldAll;
      let _fieldInfoAll = this.state.fieldInfoAll;
      let _fieldInfoAll1 = this.state.fieldInfoAll1;
      let _d = this.state.fieldNameData;
      let _d1 = { ..._d };
      // let _m = this.state.fieldArray.filter((itm:any)=>{
      //     return itm.
      // });
      let _m: any = {};
      _m[this.state.serviceId || ""] = this.state.fieldArray[this.state.serviceId || ""]
      let _m1: any = {};
      _m1[this.state.serviceId || ""] = this.state.fieldArray1[this.state.serviceId || ""]
      //log-zz   console.log("_m", _m);
      //log-zz   console.log("_m1", _m1);
      //log-zz   console.log("_m_old", this.state.fieldArray)
      //let _m = this.state.fieldArray;
      let values: any = {};
      let values1: any = {};
      this.formInputRef.current?.setFieldsValue({})
      this.formOutputRef.current?.setFieldsValue({})
      _.each(_all, (itm: any, idx) => {
        let _res: any = _fieldInfoAll.filter((l: any) => {
          return l.description == itm.label && l.tableId == this.state.serviceId;
        })
        if (_res.length > 0) {
          let tableId = _res[0].tableId.toString();
          values["table." + itm.key.toString()] = _res[0].tableId;
          values[itm.key.toString()] = _res[0].id;
          let _f = _.get(_m, [tableId]);
          _.set(_d, itm.key, _f);
        }
      })

      _.each(_all, (itm: any, idx) => {
        let _res: any = _fieldInfoAll1.filter((l: any) => {
          return l.description == itm.label && l.tableId == this.state.serviceId;
        })
        if (_res.length > 0) {
          let tableId = _res[0].tableId.toString();
          values1["table." + itm.key.toString()] = _res[0].tableId;
          values1[itm.key.toString()] = _res[0].id;
          let _f = _.get(_m1, [tableId]);
          _.set(_d1, itm.key, _f);
        }
      })


      this.formInputRef.current?.setFieldsValue(values);
      this.setState({ fieldNameData: _d })
      this.formOutputRef.current?.setFieldsValue(values1);
      this.setState({ fieldNameData1: _d1 })
      message.success("关联成功！");
    }

    const bindInputValues = async (model: any, type: any) => {
      if (type == 1) {
        let _d = eval("(" + model.requestFieldMappings + ")");
        bindFieldNameDataAll(_d);
        this.setState({ inputData: _d });
        //log-zz   console.log("_d", _d);
        this.formInputRef.current?.setFieldsValue(_d);
      }
      else {
        let _d = eval("(" + model.responseFieldMappings + ")");
        bindFieldNameDataAll1(_d);
        this.setState({ outData: _d });
        //log-zz   console.log("_d", _d);
        this.formOutputRef.current?.setFieldsValue(_d);
      }
    };

    //绑定serviceList
    const bindServiceList = async (id: any) => {
      let res: any = await Api.get(this.apiLowcodeServiceGet, { tenant: id });
      let _l: any = [];
      if (res.success) {
        let list = res.result;
        _.each(list, (item: any) => {
          _l.push({ name: item.code + "-【" + item.name + "】", id: "service" + item.id, param: returnJson(item.paramObject), result: returnJson(item.resultObject), key: item.id, code: item.code });
        })
      }
      return _l
    };

    const returnJson = (obj: any) => {
      if (_.isEmpty(obj)) {
        return [];
      }
      else {
        return obj;
      }

    }

    //编辑状态一次性给 field 赋值
    const bindFieldNameDataAll = (obj: any) => {
      let _d = this.state["fieldNameData"];
      let that: any = this;
      _.forOwn(obj, function (value, key) {
        if (key.indexOf("table.") < 0) {
          let _f = _.get(that.state.fieldArray, [obj["table." + key.toString()]]);
          _.set(_d, key, _f);
        }
      });
      this.setState({ "fieldNameData": _d })
    }

    //编辑状态一次性给 field 赋值
    const bindFieldNameDataAll1 = (obj: any) => {
      let _d = this.state["fieldNameData1"];
      let that: any = this;
      _.forOwn(obj, function (value, key) {
        if (key.indexOf("table.") < 0) {
          let _f = _.get(that.state.fieldArray1, [obj["table." + key.toString()]]);
          _.set(_d, key, _f);
        }
      });
      this.setState({ "fieldNameData1": _d })
    }
    const submitAndClose = (isClosed: any) => {
      this.setState({ isClosed: isClosed }, () => {
        this.formRef.current.submit();
      })
    }
    const submitAndCloseUpdate = () => {
      this.formOwerRef.current.submit();
    }

    const onFinish = async (values: any) => {
      //console.log("values", values)
      values.id = this.state.actionId;
      let inputMapData = this.formInputRef.current.getFieldsValue();
      let outputMapData = this.formOutputRef.current.getFieldsValue();
      values.requestFieldMappings = JSON.stringify(inputMapData);
      values.responseFieldMappings = JSON.stringify(outputMapData);
      //let m: any = this.state.serviceList;
      let r: any = this.state.serviceList.filter((itm: any) => {
        return itm.id == values.serviceId;
      })[0];
      values.serviceId = r.key;
      values.serviceCode = r.code;
      // console.log("---------开始--------------------------------------------------------------------");
      // console.log(values.requestFieldMappings.length);
      // console.log(values.responseFieldMappings.length);
      // console.log("任务ID:", this.state.detailData.id);
      // console.log("任务名称:", this.state.detailData.taskDescription);
      // console.log("------请求数据-----------------------------");
      // console.log("Request-Data:", values);
      // console.log("Request-requestFieldMappings:", values.requestFieldMappings);
      // console.log("Request-responseFieldMappings:", values.responseFieldMappings);
      this.setState({ loading: true });
      let result1: any = await Api.put(this.apiActionPost, values);

      if (result1.success) {
        //let m = result1.result[0];
        // console.log("------返回数据-----------------------------");
        // console.log("Response-Data:", m);
        // console.log("Response-requestFieldMappings:", m.requestFieldMappings);
        // console.log("Response-responseFieldMappings:", m.responseFieldMappings);
        message.success("配置成功!");
        if (this.state.isClosed) {
          onClose();
        }
        refreshTable();
      }
      else {
        message.error(result1.message);
      }
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
      //console.log("---------结束-------------------------------------------------------------------");
    };
    const onFinish1 = async (values: any) => {
      console.log("values", values)
      values.id = this.state.detailData.id;
      let result1: any = await Api.put(this.apiPut, values);;
      if (result1.success) {
        message.success("指派成功!");
        onClose1();
        refreshTable();
      }
    };
    const formatDate = (val: any) => {
      return val == null ? "" : moment(val).format('YYYY-MM-DD HH:mm:ss');
    }
    //删除
    const deleteClick = async (record: any) => {
      let { id } = record;
      let result: any = await Api.delete(this.apiDel, [id]);
      if (result.success) {
        onClose1();
        refreshTable();
      }
    };

    const handleChange = async (value: any, key: any) => {
      let _f: any = { ...this.state.inputData };
      if (value != undefined) {
        //log-zz   console.log(key);
        _f["table." + key.toString()] = value;
        this.setState({ inputData: _f });
        this.formInputRef.current?.setFieldValue(key, null);
        bindFieldNameData(value.toString(), key.toString());
      }
      else {
        let _d = this.state.fieldNameData;
        _.set(_d, key, []);
        _f["table." + key.toString()] = null;

        this.setState({ fieldNameData: _d, inputData: _f })
        this.formInputRef.current?.setFieldValue(key, null);
      }
    };
    const handleChange1 = async (value: any, key: any) => {
      let _f: any = { ...this.state.outData };
      //debugger;
      if (value != undefined) {
        _f["table." + key.toString()] = value;
        this.setState({ outData: _f }, () => {
          this.formOutputRef.current?.setFieldValue(key, null);
        })
        this.formOutputRef.current?.setFieldsValue(key, null);
        bindFieldNameData1(value.toString(), key.toString());
      } else {
        let _d = this.state.fieldNameData1;
        _.set(_d, key, []);
        _f["table." + key.toString()] = null;

        this.setState({ fieldNameData1: _d, outData: _f }, () => {
          this.formOutputRef.current?.setFieldValue(key, null);
        })
        this.formOutputRef.current?.setFieldValue(key, null);
        console.log(this.formOutputRef.current.getFieldsValue());

      }
    };

    const bindFieldNameDataNew = () => {
      let tableList = this.state.tableArrayList;
      let tableList1 = this.state.tableArrayList1;
      let fieldList: any = {};
      let fieldList1: any = {};
      let _v: any = {};
      let _fieldInfoAll: any = [];
      let _fieldInfoAll1: any = [];
      _.each(tableList, (t: any) => {
        let fList: any = [];
        _.each(t.properties, (tc: any) => {
          _v = {
            "name": tc.name + "-【" + tc.description + "】", "id": t.tableName.toString()
              + "." + t.id.toString() + "."
              + tc.name.toString() + "."
              + tc.type.toString(),
            "tableId": t.id,
            "description": tc.description
            //"descript"
          }
          fList.push(_v);
          _fieldInfoAll.push(_v);

        });
        fieldList[t.id] = fList;

      })

      _.each(tableList1, (t: any) => {
        let fList: any = [];
        _.each(t.properties, (tc: any) => {
          _v = {
            "name": tc.name + "-【" + tc.description + "】", "id": t.tableName.toString()
              + "." + t.id.toString() + "."
              + tc.name.toString() + "."
              + tc.type.toString(),
            "tableId": t.id,
            "description": tc.description
            //"descript"
          }
          fList.push(_v);
          _fieldInfoAll.push(_v);

        });
        fieldList1[t.id] = fList;
      })
      //log-zz   console.log("this.state.serviceList", this.state.serviceList);
      _.each(this.state.serviceList, (t: any) => {
        let fList: any = [];
        _.each(t.param, (tc: any) => {
          _v = {
            "name": tc.name + "-【" + tc.description + "】", "id": t.id.toString()
              + "." + t.id.toString() + "."
              + tc.name.toString() + "."
              + tc.type.toString(),
            "tableId": "service" + t.key,
            "description": tc.description
            //"descript"
          }
          fList.push(_v);
          _fieldInfoAll.push(_v);

        });
        fieldList["service" + t.key] = fList;
      })


      _.each(this.state.serviceList, (t: any) => {
        let fList: any = [];
        _.each(t.result, (tc: any) => {
          _v = {
            "name": tc.name + "-【" + tc.description + "】", "id": t.id.toString()
              + "." + t.id.toString() + "."
              + tc.name.toString() + "."
              + tc.type.toString(),
            "tableId": "service" + t.key,
            "description": tc.description
            //"descript"
          }
          fList.push(_v);
          _fieldInfoAll1.push(_v);

        });
        fieldList1["service" + t.key] = fList;
      })


      //debugger;
      // let _obj=this.state.serviceList.filter((itm:any)=>{
      //   return itm.id==val;
      // })
      // if(_obj.length>0){
      //   let obj1:any=[...[_obj[0]]];
      //   let obj2:any=[...[_obj[0]]];
      //   obj1.tableName=obj1.name;
      //   obj2.tableName=obj2.name;
      //   obj1.properties=obj1.param;
      //   obj2.properties=obj1.result;
      //   let m1=[...this.state.tableArrayList0,...obj1];
      //   let m2=[...this.state.tableArrayList0,...obj2];
      //   this.setState({ tableArrayList: m1, tableArrayList1: m2 },()=>{
      //     debugger;
      //     bindFieldNameDataNew();
      //   });
      // }




      this.setState({ fieldArray: fieldList, fieldArray1: fieldList1, fieldInfoAll: _fieldInfoAll, fieldInfoAll1: _fieldInfoAll1 });
      //log-zz   console.log("字段input", fieldList);
      //log-zz   console.log("字段out", fieldList1);
      //log-zz   console.log("___all", _fieldInfoAll);
      //log-zz   console.log("___all1", _fieldInfoAll1);
      //this.setState({ fieldNameData: newFieldList });

    }

    //table 联动 fields  option  改变
    const bindFieldNameData = (value: string, key: any) => {
      let _d = this.state.fieldNameData;
      let _d1 = this.state.fieldArray;
      //log-zz   console.log("_d", _d);
      //log-zz   console.log("_d1", _d1);
      let _f = _.get(this.state.fieldArray, [value]);
      _.set(_d, key, _f);
      //log-zz   console.log("_d", _d);
      this.setState({ fieldNameData: _d })
    }


    const bindFieldNameData1 = (value: any, key: any) => {
      let _d = this.state.fieldNameData1;
      //log-zz   console.log("_d", _d);
      let _f = _.get(this.state.fieldArray1, [value]);
      _.set(_d, key, _f);
      //log-zz   console.log("_d", _d);
      this.setState({ fieldNameData1: _d })
    }
    //查询项配置
    const searchConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "taskDescription",
        label: "任务名称",
        allowClear: true,
        col: 6,
      },
      {
        itemType: "select",
        placeholder: "请输入",
        name: "taskType",
        label: "任务类型",
        allowClear: true,
        col: 6,
        data: [{ name: '前端任务', id: 'frontendTask' }, { name: '后端开发', id: 'backendTask' }]
      },
      {
        itemType: "select",
        placeholder: "请输入",
        name: "status",
        label: "状态",
        allowClear: true,
        col: 6,
        data: [{ name: '未启动', id: 'notStarted' }, { name: '进行中', id: 'inProgress' }, { name: '已完成', id: 'completed' }]
      },
      {
        itemType: "select",
        placeholder: "请输入",
        name: "taskOwner",
        label: "指派给",
        allowClear: true,
        col: 6,
        data: this.state.userList,
        fieldNames: { label: 'label', value: 'value' }
      },
      {
        itemType: "select",
        placeholder: "请输入",
        name: "taskFinisher",
        label: "完成者",
        allowClear: true,
        col: 6,
        data: this.state.userList,
        fieldNames: { label: 'label', value: 'value' }
      }
    ];

    const disabledBtn = () => {
      setTimeout(() => {
        let btnList: any = document.getElementsByClassName("ant-table-row-expand-icon-expanded");
        _.each(btnList, (item: any) => {
          item.disabled = true;
        })
      }, 800);
    }

    const searchProps = {
      option: searchConfig,
      onSearch: (p: any) => {
        refreshTable(p);
      },
      btnBoolean: true,
      btnWrapBoolean: stubFalse
    };

    //新增表单项
    const ModalConfig: any[] = [
      {
        itemType: "input",
        placeholder: "请输入",
        name: "code",
        label: "服务编码",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "name",
        label: "服务名",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "input",
        placeholder: "请输入",
        name: "version",
        label: "版本号",
        allowClear: true,
        col: 24,
        rules: [{ required: true, message: "请输入!" }],
      },
      {
        itemType: "textarea",
        placeholder: "请输入",
        name: "description",
        label: "服务描述",
        allowClear: true,
        maxlength: 1000,
        height: 80,
        col: 24,
      },
    ];
    const actionComponent = (record: any, type: any) => {
      return (
        <Space size="middle">
          {/* {name:'未启动',id:'notStarted'},{name:'进行中',id:'inProgress'},{name:'已完成',id:'completed'} */}
          <Tooltip title="开始任务">
            <Button type={record.status == "notStarted" ? "primary" : "default"} disabled={record.status != "notStarted"} icon={<RightSquareOutlined />} onClick={() => UpdateStatus(record, 1, type)} />
          </Tooltip>
          <Tooltip title="继续任务">
            <Button type={record.status == "inProgress" ? "primary" : "default"} disabled={record.status != "inProgress"} icon={<ForwardOutlined />} onClick={() => UpdateStatus(record, 2, type)} />
          </Tooltip>
          <Tooltip title="完成任务">
            <Button type={["inProgress", "notStarted"].indexOf(record.status) > -1 ? "primary" : "default"} disabled={["inProgress", "notStarted"].indexOf(record.status) < 0} icon={<CheckSquareOutlined />} onClick={() => UpdateStatus(record, 3, type)} />
          </Tooltip>
          <Tooltip title="服务配置" >
            <Button style={{ display: record.taskType == "backendTask" ? 'block' : 'none' }} type={record.status != "completed" ? "primary" : "default"} disabled={record.status == "completed"} shape="circle" icon={<ControlOutlined />} onClick={() => openPopupMapping(record)} />
          </Tooltip>
          <Tooltip title="激活任务">
            <Button type={record.status == "completed" ? "primary" : "default"} disabled={record.status != "completed"} shape="circle" icon={<BulbOutlined />} onClick={() => UpdateStatus(record, 5, type)} />
          </Tooltip>
        </Space>
      )
    }
    //表格配置
    const columns: ColumnsType = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        render: (text, record, index) => `${index + 1}`,
        width: 70,
        align: "center",
        fixed: 'left',
      },

      {
        title: "任务ID",
        dataIndex: "id",
        key: "id",
        width: 140,
        fixed: 'left'
      },
      {
        title: "任务名称",
        dataIndex: "taskDescription",
        key: "taskDescription",
        width: 350,
        fixed: 'left',
        render: (_, record: any) => (
          <Space size="middle">
            <Tooltip title={record.taskDescription}>
              <Button type="link" onClick={() => UpdateStatus(record, 6, 1)} >
                {cutString(record.taskDescription, 20)}
              </Button>
            </Tooltip>
          </Space>
        ),
      },
      {
        title: "所属组件",
        dataIndex: "widgetName",
        key: "widgetName",
        width: 140,
      },
      {
        title: "事件_动作",
        dataIndex: "eventString",
        key: "eventString",
        width: 140,
      },
      {
        title: "任务类型",
        dataIndex: "taskTypeName",
        key: "taskTypeName",
        width: 100,
        render: (_, record: any) => (
          <Container>
            <Tag style={{ display: record.taskType == "frontendTask" ? 'block' : 'none', textAlign: "center" }} color="cyan">前端</Tag>
            <Tag style={{ display: record.taskType == "backendTask" ? 'block' : 'none', textAlign: "center" }} color="green">后台</Tag>
          </Container>
        ),
      },
      {
        title: "状态",
        dataIndex: "statusName",
        key: "statusName",
        width: 80,
        render: (_, record: any) => (
          <Container>
            <Button danger style={{ display: record.status == "inProgress" ? 'block' : 'none', textAlign: "center" }} type="text">进行中</Button>
            <Button style={{ display: record.status == "notStarted" ? 'block' : 'none', textAlign: "center" }} type="text">
              未开始
            </Button>
            <Button style={{ display: record.status == "completed" ? 'block' : 'none', textAlign: "center", color: "#00da88" }} type="text">已完成</Button>
          </Container>
        ),
      },
      {
        title: "指派给",
        dataIndex: "ownerName",
        key: "ownerName",
        width: 130,
        render: (_, record: any) => (
          record.taskOwnerAlias || record.userName
        ),
      },
      {
        title: "开始时间",
        dataIndex: "taskStartDate",
        key: "taskStartDate",
        width: 160,
        render: (_, record: any) => (
          record.taskStartDate == null ? "" : moment(record.taskStartDate).format('YYYY-MM-DD HH:mm:ss')
        ),
      },
      {
        title: "完成者",
        dataIndex: "finisherName",
        key: "finisherName",
        width: 160,
      },
      {
        title: "完成时间",
        dataIndex: "taskCompleteDate",
        key: "taskCompleteDate",
        width: 160,
        render: (_, record: any) => (
          record.taskCompleteDate == null ? "" : moment(record.taskCompleteDate).format('YYYY-MM-DD HH:mm:ss')

        ),
      },
      {
        title: "最后修改时间",
        dataIndex: "updateDate",
        key: "updateDate",
        render: (_, record: any) => (
          moment(record.updateDate).format('YYYY-MM-DD HH:mm:ss')
        ),
        width: 160,
      },
      {
        title: "操作",
        key: "action",
        render: (_, record: any) => (
          actionComponent(record, 1)
        ),
        width: 250,
        fixed: 'right',
      },
    ];

    const columns1: any = [
      {
        title: '页面元素',
        align: "center",
        children: [
          {
            title: '组件',
            dataIndex: 'widgetName',
            key: 'widgetName',
            width: 300,
            align: "left",
          },
          {
            title: '字段',
            dataIndex: 'label',
            key: 'label',
            width: 150,
            align: "left",
            render: (_: any, record: any) => (
              <span title={record.label}>
                {record.label && (record.label == "null" ? "" : cutString(record.label, 20))}
              </span>
            ),
          },
        ]
      },
      {
        title: '',
        dataIndex: 'widgetName',
        key: 'widgetName',
        width: 110,
        align: "center",
        render: (_: any, record: any) => (
          <ArrowRightOutlined />
        ),
      },
      {
        title: "字段映射",
        key: "action",
        width: 740,
        render: (_: any, record: any) => (
          //return(
          <Row>
            <Col span={12} >
              <Form.Item
                label=""
                name={"table." + record.key.toString()}
                rules={[{ required: false, message: "请输入!" }]}
              >
                <Select
                  style={{ width: 370 }}
                  placeholder="请选择服务"
                  allowClear
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  key={"inputTable" + record.key}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={this.state.tableArrayList}
                  showSearch
                  filterOption={(input: any, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(val) => handleChange(val, record.key)}
                />
              </Form.Item>
            </Col>
            <Col span={12} >
              <Form.Item
                label=""
                colon={false}
                name={record.key}
                rules={
                  (this.state.inputData != null && this.state.inputData["table." + record.key] == "NULL") ?
                    [
                      // {
                      //   pattern: pattern.field,
                      //   message: "仅支持输入大小写字母"
                      // }
                    ] :
                    []
                }
              >
                {
                  (this.state.inputData != null && this.state.inputData["table." + record.key] == "NULL") ?
                    <Input style={{ width: 370 }}
                      placeholder="请输入字段"
                      maxLength={255}
                      allowClear
                      key={"inField" + record.key}
                    /> :
                    <Select
                      style={{ width: 370 }}
                      placeholder="请选择字段"
                      key={"inputModel" + record.key}
                      showSearch
                      allowClear
                      fieldNames={{ label: 'name', value: 'id' }}
                      filterOption={(input: any, option: any) =>
                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.fieldNameData[record.key.toString()]}
                    />

                }

              </Form.Item>
            </Col>
          </Row>
        ),
      },
    ];

    const columns2: any = [
      {
        title: '页面元素',
        align: "center",
        children: [
          {
            title: '组件',
            dataIndex: 'widgetName',
            key: 'widgetName',
            width: 300,
            align: "left",
          },
          {
            title: '字段',
            dataIndex: 'label',
            key: 'label',
            width: 150,
            align: "left",
            render: (_: any, record: any) => (
              <span title={record.label}>
                {record.label && (record.label == "null" ? "" : cutString(record.label, 20))}
              </span>
            ),
          },
        ]
      },
      {
        title: '',
        dataIndex: 'widgetName',
        key: 'widgetName',
        width: 110,
        align: "center",
        render: (_, record: any) => (
          <ArrowLeftOutlined />
        ),
      },
      {
        title: "字段映射",
        key: "action",
        width: 740,
        // align: "center",
        render: (_, record: any) => (
          <Row>
            <Col span={12} >
              <Form.Item
                label=""
                name={"table." + record.key.toString()}
                rules={[{ required: false, message: "请输入!" }]}
              >
                <Select
                  style={{ width: 370 }}
                  placeholder="请选择服务"
                  allowClear
                  showSearch
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  key={"outTable" + record.key}
                  fieldNames={{ label: 'name', value: 'id' }}
                  filterOption={(input: any, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={this.state.tableArrayList1}
                  onChange={(val) => handleChange1(val, record.key)}
                />
              </Form.Item>
            </Col>
            <Col span={12} >
              <Form.Item
                label=""
                colon={false}
                name={record.key}
                rules={
                  (this.state.outData != null && this.state.outData["table." + record.key] == "NULL") ?
                    [
                      // {
                      //   pattern: pattern.field,
                      //   message: "仅支持输入大小写字母"
                      // }
                    ] :
                    []
                }
              >
                {
                  (this.state.outData != null && this.state.outData["table." + record.key] == "NULL") ?
                    <Input style={{ width: 370 }}
                      placeholder="请输入字段"
                      maxLength={255}
                      allowClear
                    /> :
                    <Select
                      style={{ width: 370 }}
                      placeholder="请选择字段"
                      key={"outModel" + record.key}
                      showSearch
                      allowClear
                      fieldNames={{ label: 'name', value: 'id' }}
                      filterOption={(input: any, option: any) =>
                        (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.fieldNameData1[record.key.toString()]}
                    />
                }

              </Form.Item>
            </Col>
          </Row>
        ),
      },
    ];
    const searchParam = () => {
      let params = { ...this.searchRef.current.getSearchData(), ...this.state.initParams };
      return params;
    }
    const tableProps = {
      columns: columns,
      scroll: { x: 800 },
      api: this.apiGet,
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      searchParam: searchParam
    };

    const that = this;
    //modal 表单配置
    const modalProps = {
      apiPost: this.apiPost,
      apiPut: this.apiPut,
      option: ModalConfig,
      refreshTable: refreshTable,
      reformData,
      afterSaveEvent
    };


    return (
      <>
        <PopupMapping refreshTable={refreshTable} updateIsOpenPopup={updateIsOpenPopup} openPopupRecord={this.state.openPopupRecord} isOpenPopup={this.state.isOpenPopup} />
        <div className="x-main-box">
          <SearchForm ref={this.searchRef} {...searchProps} />
          <XModal ref={this.modalRef} isShowAdd={false}  {...modalProps} />
          <TableList ref={this.tableRef}  {...tableProps} initParams={this.state.initParams} />
          <Drawer
            forceRender={true}
            width="1400px"
            title="服务映射配置"
            // title="服务配置"
            placement="right"
            closable={true}
            onClose={onClose}
            open={this.state.open}
            key={"服务配置"}
            extra={
              <Space>
                <Button loading={this.state.loading} type="primary" onClick={() => submitAndClose(false)}>
                  保存
                </Button>
                <Button loading={this.state.loading} onClick={() => submitAndClose(true)}  >
                  保存并关闭
                </Button>
              </Space>
            }
          >
            <Container>
              <Tag color="#108ee9">{this.state.detailData["id"]}</Tag>
              <Tag color="#87d068">{this.state.detailData["taskDescription"]}</Tag>
              <Tag color="#2db7f5" style={{ display: _.isEmpty(this.state.detailData["taskDescription"]) ? "none" : "" }}>{this.state.detailData["taskDescription"]}</Tag>
            </Container>
            <Form
              ref={this.formRef}
              style={{ marginTop: "20px", marginBottom: "20px" }}
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              onFinish={onFinish}
              key="allForm"
              initialValues={this.state.initialValues}
              autoComplete="off"
            >
              <b>服务配置</b>
              <br></br>

              <Form.Item
                style={{ marginTop: "15px" }}
                label="服务"
                name="serviceId"
                rules={[{ required: true, message: "请输入!" }]}
              >
                <Select
                  // style={{ width: 160 }}
                  showSearch
                  placeholder="请选择服务"
                  allowClear
                  onChange={(v: any) => serviceCodeChange(v)}
                  fieldNames={{ label: 'name', value: 'id' }}
                  filterOption={(input: any, option: any) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={this.state.serviceList}
                />
                {/* <Input /> */}
              </Form.Item>
              <Divider dashed={true} />
              <b>字段映射</b>
              <Button type="primary" size="small" style={{ width: "auto", marginLeft: "20px" }} icon={<ApiOutlined />} onClick={() => autoMap()}>
                自动关联
              </Button>
              <Button size="small" danger style={{ width: "auto", marginLeft: "10px" }} icon={<DisconnectOutlined />} onClick={() => unAutoMap()}>
                取消关联
              </Button>
              <Button icon={<CreditCardOutlined />} size="small" style={{ width: "auto", marginLeft: "10px" }} onClick={() => viewPage()}>
                查看页面
              </Button>
              <br></br>

              <Tabs activeKey={this.state.tabKey} style={{ marginTop: "10px" }} className="x-task-service" type="card" onTabClick={(key: any) => tabClick(key)}>
                <TabPane tab="输入" key="1" forceRender={true}>
                  <Form
                    key="inputForm"
                    ref={this.formInputRef}
                  >
                    {
                      this.state.fieldData && this.state.fieldData.length > 0 &&
                      <Table columns={columns1} bordered={true} size="small" pagination={false}
                        expandable={{
                          defaultExpandAllRows: true,
                          rowExpandable: () => false
                        }}
                        dataSource={this.state.fieldData} />
                    }

                  </Form>
                </TabPane>
                <TabPane tab="输出" key="2" forceRender={true}>
                  <Form
                    key="outForm"
                    ref={this.formOutputRef}
                  >
                    {
                      this.state.fieldData1 && this.state.fieldData1.length > 0 &&
                      <Table columns={columns2} bordered={true} size="small" pagination={false}
                        expandable={{
                          defaultExpandAllRows: true
                        }}
                        dataSource={this.state.fieldData1} />
                    }

                  </Form>
                </TabPane>
              </Tabs>
              <Divider dashed={true} />
            </Form>
          </Drawer>


          <Drawer
            width="800px"
            title={<Container><Tag color="#108ee9">{this.state.detailData["id"]}</Tag> <Tag>{this.state.detailData["taskDescription"]}</Tag> <Tag color="green">{this.state.detailData["taskTypeName"]}</Tag></Container>}
            placement="right"
            closable={false}
            onClose={onClose1}
            open={this.state.open1}
            key={"任务详情"}
            extra={
              <Space>
                <Button type="primary" onClick={() => submitAndCloseUpdate()}>
                  修改
                </Button>
              </Space>
            }
          >
            <Form
              ref={this.formOwerRef}
              onFinish={onFinish1}
            >
              <Descriptions title="任务描述" size="middle" className="z-task-Detal">
                <Descriptions.Item label="" style={{ backgroundColor: "#f6f6f6" }}>
                  {this.state.detailData["taskDescription"]}
                </Descriptions.Item>
              </Descriptions>
              <Container className="x-mt20">
                {actionComponent(this.state.detailData, 2)}
              </Container>

              <Descriptions title="基本信息" size="middle" className="x-mt20" column={1} >
                <Descriptions.Item label="指派给">
                  <Form.Item
                    label=""
                    name="taskOwner"
                    rules={[{ required: true, message: "请输入!" }]}
                  >
                    <Select
                      style={{ width: 220 }}
                      placeholder="请选择"
                      showSearch
                      //fieldNames={{ label: 'alias', value: 'userId' }}
                      options={this.state.appUserList}
                    />
                  </Form.Item></Descriptions.Item>
                <Descriptions.Item label="指派人">{`${this.state.detailData["assignorAlias"] || this.state.detailData["assignorName"]}`}</Descriptions.Item>
                <Descriptions.Item label="指派时间">{formatDate(this.state.detailData["assignDate"])}</Descriptions.Item>
                <Descriptions.Item label="任务状态">{this.state.detailData["statusName"]}</Descriptions.Item>
                <Descriptions.Item label="开始时间">{formatDate(this.state.detailData["taskStartDate"])}</Descriptions.Item>
                <Descriptions.Item label="完成时间">{formatDate(this.state.detailData["taskCompleteDate"])}</Descriptions.Item>
              </Descriptions>
              <Divider dashed={true} />
              {/* <Affix offsetBottom={10}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Row>
                    <Col span="8">
                      <Popconfirm placement="left" title={"确定删除该任务吗？"} onConfirm={() => deleteClick(this.state.detailData)} okText="确定" cancelText="取消">
                        <Button danger style={{ width: "95%" }}>删除</Button>
                      </Popconfirm>
                    </Col>
                    <Col span="16">
                      <Button htmlType="submit" type="primary" style={{ width: "95%" }}>
                        保存修改
                      </Button></Col>
                  </Row>
                </div>
              </Affix> */}
            </Form>

          </Drawer>
        </div >
      </>
    );
  }
}
export default withRouter(MyTask);
