import {Button, Form, Input, InputNumber, InputRef, message, Select, Space, Switch, Table} from 'antd';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import ModelingApi from "./lib/ModelingApi";
import {PROPERTY_REL_TYPE} from "./constants/Constants";
import {
  getPropertyById,
  getPropertyDefaultLength,
  hasSysDefaultProperty,
  isNoDefVal,
  isPk,
  isRelProperty,
  MODEL_FORM_RULES,
  propertyUnEditable
} from "@byk/pages/modeling/ModelDataUtil";
import {ModelHeaderWrapper, ModelProps} from "@byk/pages/modeling/MxGraph/CreateModel";
import _ from "lodash";
import PageEleTree from "@byk/pages/modeling/MxGraph/PageEleTree";
import moment from "moment";
import DataRecovery from "@byk/pages/modeling/MxGraph/DataRecovery";
import {useDispatch, useSelector} from "react-redux";
import {getDynamicModel} from "@byk/pages/modeling/modelSelector";
import {setCurDynamicModel} from "@byk/store/modelingReducers";
import GraphModel from "@byk/pages/modeling/lib/GraphModel";
import {
  getDictOptions,
  getModelPropertyTypeLabel,
  ModelingOptions
} from "@byk/pages/modeling/constants/ModelingOptions";
import styled from 'styled-components';

const ButtonWrapper = styled(Button)`
  height: 36px;
  border-radius: 5px;
`
const ModelTable = styled(Table)`
  .ant-table.ant-table-bordered>.ant-table-container {
    border-left: 1px solid #f0f0f0;
  }

  .ant-table-cell {
    height: 38x;
    font-size: 14px;
    padding-left:
  }

  .formItemSelect {
    border: 1px solid #E4E8EB;
    border-radius: 5px;
    background: white;

    .ant-select-disabled {
      background: #f5f5f5;
    }
  }
`

// 对象定义数据类型，类型也可以是函数
interface ModelPropertyObj {
  id: any;
  modelId: any;
  model: any;
  key: any;
  name: any;
  description: any;
  type: any;
  mappedBy?: any;
  relatedBy?: any;
  targetModel?: any;
  targetModelId?: any;
  dicCode?: any;
  nullable: true;
  unique?: any;
  scale?: any;
  length?: any;
  defaultValue?: any;
  primaryKey?: boolean;
  initCategory?: any;
  multilingual: boolean;
  dictName: any;
  dictOptions?: any;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ModelPropertyObj;
  record: ModelPropertyObj;
  editing: boolean;
  formRef: any;
  confirm: (values: any) => void;
  graphModel: GraphModel;
  getEditKey: () => any;
  dataSource: any;
}

const rowBtnStyle = {
  padding: "0px",
  border: "none",
}

const EditableCell: React.FC<EditableCellProps> = (
    {
      title,
      editable,
      children,
      dataIndex,
      record,
      editing,
      formRef,
      confirm,
      graphModel,
      getEditKey,
      dataSource,
      ...restProps
    }) => {
    let formValues = formRef?.form.getFieldsValue();
    let type = record?.type;
    let key = getEditKey && getEditKey();
    if (key && record?.key == key) {
      type = formValues?.type || type;
    }
    if (record && record.scale == 0) {
      record.scale = '';
    }

    const inputRef = useRef<InputRef>(null);
    const defValRef = useRef<any>(null);
    let childNode = children;
    let canEditable: any = (!propertyUnEditable(record) || !record?.name) && editing && dataIndex;
    let isRelatedType = type == 'related';  // 引用关系
    let isRefType = isRelProperty(type); // o2m, m2o约束关系

    const [options, setOptions] = useState<any>({});

    useEffect(() => {
      dataIndex == 'name' && inputRef.current?.focus()
    }, [dataSource])

    const getFormValuesAndRecord = () => {
      let formValues = formRef?.form.getFieldsValue();
      let property = {...(record || {})}
      if (record?.key == key) {
        property = {...record, ...formValues}
      }
      return property;
    }

    const disabled = () => {
      let isDisabled = false;
      let relatedAttrs = ['targetModel', 'mappedBy', 'relatedBy']

      let relatedDisabledAttr = ['nullable', 'length', 'defaultValue', 'multilingual']  // related类型不可编辑属性
      let o2mDisabledAttr = ['relatedBy', 'nullable', 'length', 'defaultValue', 'multilingual'] // o2m类型不可编辑属性
      let m2oDisabledAttr = ['mappedBy', 'relatedBy', 'nullable', 'length', 'defaultValue', 'multilingual'] // m2o类型不可编辑属性

      // 关联模型	      关联属性	映射属性	  默认值	   允许为空	  长度限制	      多语言
      // targetModel mappedBy relatedBy nullable length   defaultValue   multilingual
      if (type == PROPERTY_REL_TYPE.O2M) {
        isDisabled = o2mDisabledAttr.indexOf(dataIndex) > -1;
      } else if (type == PROPERTY_REL_TYPE.M2O) {
        isDisabled = m2oDisabledAttr.indexOf(dataIndex) > -1;
      } else if (isRelatedType) {
        isDisabled = relatedDisabledAttr.indexOf(dataIndex) > -1;
      } else if (dataIndex == 'length' && (!isRefType && !isRelatedType)) {
        isDisabled = (dataIndex == 'length' && !getPropertyDefaultLength(type));
      } else if (dataIndex == 'scale' && type != 'decimal') {
        isDisabled = true;
      } else {
        isDisabled = relatedAttrs.indexOf(dataIndex) > -1
      }
      return isDisabled;
    }

    const addRequiredRule = () => {
      let requiredAttrs = ['name', 'description', 'type']
      if (isRelatedType) {
        requiredAttrs = [...requiredAttrs, 'targetModel', 'mappedBy', 'relatedBy']
      }
      if (isRefType) {
        requiredAttrs = [...requiredAttrs, 'targetModel']
      }

      if (type == PROPERTY_REL_TYPE.O2M) {
        requiredAttrs = [...requiredAttrs, 'mappedBy']
      }

      if (requiredAttrs.indexOf(dataIndex) > -1) {
        let rules: any = []
        if (MODEL_FORM_RULES[dataIndex]) {
          rules = [...MODEL_FORM_RULES[dataIndex]]
        }
        rules.push({required: true, message: ''})
        return rules;
      } else {
        return null;
      }
    }

    // 下拉选项
    const typeOptionOnChange = (value: any, option: any) => {
      let values = formRef.form.getFieldsValue();

      values.length = getPropertyDefaultLength(option.value);
      if (option.value == 'decimal') {
        values.scale = 2;
      } else {
        values.scale = null;
      }
      formRef.form.setFieldsValue({
        ...values,
        targetModel: null,
        mappedBy: null,
        relatedBy: null,
        dictName: null,
        defaultValue: null,
      });
    }

    let isDisabled = disabled();
    let rules = addRequiredRule();

    if (dataIndex == 'type') {
      childNode = canEditable ? (
        <Form.Item
          className='formItemSelect'
          style={{margin: 0}}
          name={dataIndex}
          rules={rules}
          key={dataIndex}
        >
          <Select
            bordered={false}
            disabled={isDisabled}
            style={{margin: 0}}
            options={ModelingOptions}
            onChange={typeOptionOnChange}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: "20px"}}>
          {getModelPropertyTypeLabel(record)}
        </div>
      );
    } else if (dataIndex == 'dictName') {  // 数据字典
      let type = getFormValuesAndRecord().type;
      childNode = canEditable ? (
        <Form.Item
          className='formItemSelect'
          style={{margin: 0}}
          name={dataIndex}
          rules={rules}
          key={dataIndex}
        >
          <Select
            bordered={false}
            allowClear={true}
            disabled={type != 'dict'}
            style={{margin: 0}}
            options={record?.dictOptions || []}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: "20px"}}>
          {children}
        </div>
      );

    } else if (dataIndex == 'targetModel') {  // 关联模型
      childNode = canEditable ? (
        <Form.Item
          className='formItemSelect'
          style={{margin: 0}}
          name={dataIndex}
          rules={rules}
          key={dataIndex}
        >
          <Select
            bordered={false}
            allowClear={true}
            disabled={isDisabled}
            style={{margin: 0}}
            options={graphModel?.getTargetModelOptions(getFormValuesAndRecord())}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: "20px"}}>
          {graphModel?.getModelDesc(getFormValuesAndRecord(), dataIndex)}
        </div>
      );
    } else if (dataIndex == 'relatedBy' || dataIndex == 'mappedBy') { // 映射属性 关联属性
      childNode = canEditable ? (
        <Form.Item
          className='formItemSelect'
          style={{margin: 0}}
          name={dataIndex}
          rules={rules}
          key={dataIndex}
        >
          <Select
            bordered={false}
            allowClear={true}
            disabled={isDisabled}
            style={{margin: 0}}
            options={options[dataIndex] || []}
            onDropdownVisibleChange={() => {
              let ds = type == PROPERTY_REL_TYPE.RELATED && dataIndex == 'relatedBy' ? null : dataSource;
              let opt = graphModel?.getModelPropertiesOptions(getFormValuesAndRecord(), dataIndex, ds)
              let ss = {...options};
              ss[dataIndex] = opt || [];
              setOptions({...ss})
            }}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: "20px"}}>
          {graphModel?.getRelatedByOrMappedByPropertyDesc(getFormValuesAndRecord(), dataIndex)}
        </div>
      );
    } else if (dataIndex == 'nullable' || dataIndex == 'multilingual') {
      let text = '';
      if (dataIndex == 'nullable') {
        text = record.nullable ? "允许" : "不允许"
      } else {
        text = record.multilingual ? "支持" : "不支持"
      }
      childNode = canEditable ? (
        <Form.Item
          style={{margin: 0}}
          name={dataIndex}
          valuePropName="checked"
          key={dataIndex}
        >
          <Switch disabled={isDisabled}/>
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: "20px"}}>
          {text}
        </div>
      );
    } else if (dataIndex == 'scale' && type == 'decimal') {  // 精度
      if (canEditable) {
        childNode = <Form.Item
          style={{margin: 0}}
          name={dataIndex}
          rules={rules}
          key={dataIndex}
        >
          <InputNumber min={1} max={12} precision={0} onChange={(v) => {
            let values = getFormValuesAndRecord();
            let defVal = values.defaultValue;
            if (defVal) {
              formRef.form.setFieldValue('defaultValue', new Number(defVal).toFixed(v || 2));
            }
          }
          }/>
        </Form.Item>
      }
    } else if (dataIndex == 'defaultValue') {  // 默认值
      let values = getFormValuesAndRecord();
      let noDefVal = isNoDefVal(values.type); // 没有默认值
      let selectDefVal = ['datetime', 'bool'].indexOf(values.type) > -1;   // input 默认值
      let options: any = [{'label': '当前时间', 'value': 'now()'}];
      if (values.type == 'bool') {
        options = [{'label': '真', 'value': 'true'}, {'label': '假', 'value': 'false'}];
      }

      let isInt = ['integer', 'bigint'].indexOf(values.type) > -1;
      let isDecimal = values.type == 'decimal';

      if (canEditable) {
        if (isInt || isDecimal) {
          childNode = <Form.Item
            style={{margin: 0}}
            name={dataIndex}
            rules={rules}
            key={dataIndex}
          >
            <InputNumber ref={defValRef} formatter={value => {
              let values = getFormValuesAndRecord();
              let pre = isDecimal ? values.scale || 2 : 0;
              return value ? new Number(value).toFixed(pre) : '';
            }}/>
          </Form.Item>
        } else if (selectDefVal) {
          childNode = <Form.Item
            style={{margin: 0}}
            name={dataIndex}
            rules={rules}
            key={dataIndex}
          >
            <Select allowClear={true} options={options}/>
          </Form.Item>
        } else {
          childNode = <Form.Item
            style={{margin: 0}}
            name={dataIndex}
            rules={rules}
            key={dataIndex}
          >
            <Input disabled={noDefVal} autoComplete={"off"}/>
          </Form.Item>
        }
      } else {
        let vk: any = {
          'now()': '当前时间',
          'true': '真',
          'false': '假',
        }
        childNode = (<div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: "20px"}}>
          {vk[record.defaultValue] || record.defaultValue}
        </div>)
      }
    } else {
      childNode = canEditable ? (
        <Form.Item
          style={{margin: 0}}
          name={dataIndex}
          rules={rules}
          key={dataIndex}
        >
          <Input disabled={isDisabled} ref={inputRef} autoComplete={"off"}/>
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{paddingRight: 24, minHeight: "20px"}}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  }
;

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const ModelProperty: React.FC<ModelProps> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    updateModel,
    getModelData,
    formValid,
  }));
  const dispatch = useDispatch();
  const {excelModel} = props;
  const modelData = excelModel != null ? excelModel : useSelector(getDynamicModel);
  const {graphModel} = props;
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<ModelPropertyObj[]>(modelData.properties || []);
  const count = useRef(1);
  const [editingKey, setEditingKey] = useState<any>();
  const pageEleTreeRef = useRef<any>();
  const dataRecoveryRef = useRef<any>();
  const editingPro = useRef<any>();
  const dictOptions = useRef<any>([]);

  // 组合快捷键切换展示/隐藏SQL: ctrl + enter
  // L的keyCode = 76
  const handleHotkeysPress = async (event: any) => {
    if (event.keyCode === 13 && event.ctrlKey) {
      event.preventDefault();
      await handleAdd();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleHotkeysPress);

    // if (dataSource) {
    //   modelData.properties = [...dataSource];
    //   props.updateModelState && props.updateModelState(modelData);
    // }

    return () => {
      window.removeEventListener('keydown', handleHotkeysPress);
    }
  }, [dataSource])

  const isEditing = (record: any) => (record.key || record.id) === editingKey;

  useEffect(() => {
    getDictOptions().then(res => {
      dictOptions.current = res;
    });
  }, [])

  useEffect(() => {
    if (excelModel) {
      setDataSource(excelModel.properties);
    }
  }, [excelModel])

  const updateModel = async (model: any) => {
    getDictOptions().then(res => {
      dictOptions.current = res;
    });
    setEditingKey(null);
    editingPro.current = null;
    setDataSource(model.properties || []);
    let formValues: any = {};
    _.forEach(model.properties, item => {
      let recordKey = (item.id || item.key || '') + '';
      _.forEach(_.keys(item), key => {
        let inputKey = key + "_" + (recordKey.replaceAll(" ", ""));
        formValues[inputKey] = item[key];
      });
    })
    form.setFieldsValue(formValues);
    try {
      await form.validateFields();
    } catch (errorInfo) {
    }
  }

  const handleCancel = async (record: any) => {
    editingPro.current = null;
    setEditingKey(null);
    if (!record.id) {
      await handleDelete(record);
    }
  }

  const handleDelete = async (record: any, saveCurrent?: any) => {
    // 检查是否创建了索引
    if (graphModel.isIndexRef(record)) {
      message.warn("该属性创建了索引，请选删除索引");
      return;
    }

    let property: any = getPropertyById(dataSource, record.id) || record;
    if (property.type == PROPERTY_REL_TYPE.M2O) {
      let res: any = await ModelingApi.doGetRelProperties(record.model);
      if (res.success) {
        let relModel: any = [];
        if (res.result && res.result.length > 0) {
          res.result.forEach((item: any) => {
            if (item.type == 'o2m' && relModel.indexOf(item.model) == -1) {
              relModel.push(item.model);
            }
          });
        }
        if (relModel.length > 0) {
          message.info(`请先删除一对多属性配置:[${relModel.join(',')}]`);
        } else {
          await doDeleteProperty(property, saveCurrent);
        }
      }
    } else {
      await doDeleteProperty(property, saveCurrent);
    }
  };

  const doDeleteProperty = async (record: any, saveCurrent?: any) => {
    const newData = dataSource.filter(item => (item.key != record.key || item.id !== record.id));
    props.mxRemoveEdgeByPropertyId && props.mxRemoveEdgeByPropertyId(record.id);
    let _newData: any = [];
    newData.forEach(i => {
      if (saveCurrent && i.key == saveCurrent.key) {
        _newData.push({...i, ...saveCurrent});
      } else {
        _newData.push(i);
      }
    })
    setDataSource(_newData);
    doSetModelProperties(_newData);
    setEditingKey(null);
    editingPro.current = null;
  }

  const handleAdd = async () => {
    let properties: any[] = [...dataSource]
    let isOk = true;
    if (editingPro.current) {
      await form?.validateFields().then((values: any) => {
        properties = updateDataSource({
          ...editingPro.current,
          ...values
        }).properties;
      }).catch(() => isOk = false)
    }
    if (!isOk) return;

    count.current += 1;
    const newData: ModelPropertyObj = {
      id: null,
      key: count.current.toString(),
      modelId: modelData.id,
      model: modelData.name,
      description: null,
      name: null,
      defaultValue: null,
      nullable: true,
      type: `string`,
      length: 255,
      multilingual: false,
      dictName: null,
      scale: null,
    };

    let _properties = [...properties || [], newData];
    setDataSource([..._properties]);
    doSetModelProperties(_properties);
    await edit(newData);
  };

  // 更新状态管理的属性数据
  const doSetModelProperties = (_properties: any) => {
    props.setProperties && props.setProperties(_properties);
  }

  const edit = async (record: any & { key: React.Key }) => {
    let ek = record.key || record.id
    record.dictOptions = dictOptions.current;
    if (canEditable(record) && editingKey != ek) {
      form.setFieldsValue({
        description: '',
        name: '',
        defaultValue: '',
        nullable: true,
        unique: false,
        length: '',
        multilingual: false,
        mappedBy: '',
        relatedBy: '',
        targetModel: '',
        targetModelId: '',
        scale: '',
        ...record
      });

      setEditingKey(ek);
      editingPro.current = record;
    }
  };

  /**
   * 添加系统辅助字段
   */
  const handleAddSysInitProperties = async () => {
    let res: any = await ModelingApi.doInitSysProperties(modelData.id);
    if (res.success) {
      let properties = res.result[0].properties;
      setDataSource(graphModel.reSortProperties(properties));
      props.mxUpdateModel && props.mxUpdateModel(res.result[0]);
    } else {
      message.error(res.message);
    }
  }

  const editConfirm = async (values: any) => {
    let modelData = updateDataSource(values);
    setDataSource(modelData.properties);
    setEditingKey(null);
    editingPro.current = null;
    doSetModelProperties(modelData.properties);
    dispatch(setCurDynamicModel(modelData));
  };

  const updateDataSource = (values: any) => {
    if (!values || (!values.id && !values.key)) {
      return modelData;
    }
    const newDataSource: any = [];
    _.forEach(dataSource, item => {
      if (values.key == item.key) {
        newDataSource.push({
          ...item,
          ...values,
        });
      } else {
        newDataSource.push(item);
      }
    })

    modelData.properties = [...newDataSource];
    return modelData;
  }

  const getModelData = () => {
    if (editingPro.current && editingKey && editingKey == editingPro.current.key) {
      let modelData = updateDataSource(editingPro.current);
      setDataSource(modelData.properties);
      setEditingKey(null);
      editingPro.current = null;
      return modelData;
    } else {
      return modelData;
    }
  }

  const formValid = async () => {
    let isValid = true;
    await form?.validateFields().then((values: any) => {
      editConfirm({
        ...editingPro.current,
        ...values
      });
    }).catch((e) => {
      isValid = false;
    });

    return isValid;
  }

  const canEditable = (record: any) => {
    return !propertyUnEditable(record) && !isRelProperty(record?.type) && record.name != 'language';
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      key: 'name',
      title: '属性编码',
      dataIndex: 'name',
      editable: true,
    },
    {
      key: 'description',
      title: '属性名称',
      dataIndex: 'description',
      editable: true,
    },
    {
      key: 'type',
      title: '属性类型',
      dataIndex: 'type',
      editable: true,
    },
    {
      key: 'dictName',
      title: '数据字典',
      dataIndex: 'dictName',
      editable: true,
    },
    {
      key: 'targetModel',
      title: '关联模型',
      dataIndex: 'targetModel',
      editable: true,
    },
    {
      key: 'mappedBy',
      title: '关联属性',
      dataIndex: 'mappedBy',
      editable: true,
    },
    {
      key: 'relatedBy',
      title: '映射属性',
      dataIndex: 'relatedBy',
      editable: true,
    },
    {
      key: 'defaultValue',
      title: '默认值',
      dataIndex: 'defaultValue',
      editable: true,
    },
    {
      key: 'nullable',
      title: '允许为空',
      dataIndex: 'nullable',
      editable: true,
    },
    {
      key: 'length',
      title: '长度',
      dataIndex: 'length',
      editable: true,
    },
    {
      key: 'length',
      title: '精度',
      dataIndex: 'scale',
      editable: true,
    },
    {
      key: 'multilingual',
      title: '多语言',
      dataIndex: 'multilingual',
      editable: true,
    },
    {
      key: 'x',
      title: '操作',
      fixed: 'right',
      dataIndex: 'operation',
      render: (_, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Space size="middle">
            {!propertyUnEditable(record) && (
              <>
                <Button style={{...rowBtnStyle}} type="link" onClick={async () => {
                  await formValid()
                }}>确定</Button>
                <Button style={{...rowBtnStyle}} type="link" onClick={async () => handleCancel(record)}>取消</Button>
              </>
            )
            }
          </Space>
        ) : (
          <Space size="middle">
            {canEditable(record) && (
              <Button style={{...rowBtnStyle}} type="link" onClick={async () => {
                if (editingPro.current && editingPro.current.key != record.key) {
                  await formValid()
                }
                await edit(record);
              }}>编辑</Button>
            )}
            {!isPk(record) && (
              <>
                <Button style={{...rowBtnStyle}} type="link" onClick={async () => {
                  let saveCurrent = null;
                  // 删除属性不是当前编辑的属性
                  if (editingPro.current && editingPro.current.key != record.key) {
                    await form?.validateFields().then(async (values: any) => {
                      saveCurrent = {
                        ...editingPro.current,
                        ...values
                      };

                      await handleDelete(record, saveCurrent);
                    });
                  } else {
                    await handleDelete(record, saveCurrent);
                  }
                }}>删除</Button>
              </>
            )}
          </Space>
        )
      }
    },
  ];

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const getEditKey = () => {
    return editingPro.current?.key
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ModelPropertyObj) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          formRef: {form},
          confirm: editConfirm,
          getEditKey: getEditKey,
          graphModel,
          dataSource: dataSource,
        }
      ),
    }
      ;
  });

  const openPageEleTree = () => {
    let titles: any = [];
    _.forEach(dataSource || [], item => titles.push(item.name));
    pageEleTreeRef?.current.init(titles);
  }

  return (
    <>
      <ModelHeaderWrapper><span>模型属性</span></ModelHeaderWrapper>
      <div style={{...props.style, marginLeft: '21px', marginRight: '20px'}}>
        <div style={{display: "flex", justifyContent: 'space-between', marginBottom: '11px'}}>
          <div>
            <ButtonWrapper onClick={handleAdd} type="primary">
              新增属性
            </ButtonWrapper>
            <ButtonWrapper type="primary"
                           onClick={openPageEleTree}
                           style={{marginLeft: 12}}>
              新增属性(UI元素)
            </ButtonWrapper>
            <ButtonWrapper onClick={handleAddSysInitProperties}
                           disabled={hasSysDefaultProperty(modelData) || !modelData.id}
                           style={{marginLeft: 12}}>
              添加系统默认属性
            </ButtonWrapper>
          </div>
        </div>

        <Form form={form} component={false}>
          <div>
            <ModelTable
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={dataSource}
              columns={columns as ColumnTypes}
              pagination={false}
              size="small"
              scroll={{y: 'calc(100vh - 405px)',  x: 100}}
              onRow={record => {
                return {
                  onDoubleClick: async event => {
                    if (!editingKey && await formValid()) {
                      await edit(record);
                    }
                  },
                };
              }}
            />
          </div>
        </Form>

        <PageEleTree
          {...props}
          setProperties={(newProperties:any = []) => {
            props.setProperties && props.setProperties([...dataSource, ...newProperties]);
          }}
          ref={pageEleTreeRef}
        />

        <DataRecovery ref={dataRecoveryRef}/>
      </div>
    </>
  );
});

export default ModelProperty;
