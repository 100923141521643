import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Alert, Button, Table} from "antd";
import UploadExcel from "@byk/pages/modeling/components/UploadExcel";
import _ from 'lodash';
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {Link} from "react-router-dom";
import {ModalWrapper} from "@byk/pages/modeling/styled";
import { routePath } from '@byk/routes';
import moment from "moment";
import {downloadModelExcelTemplate} from "@byk/pages/modeling/ModelDataUtil";

interface Props {
  ref: any;
}

const DataRecovery: React.FC<Props> = forwardRef(({}: Props, ref) => {
  useImperativeHandle(ref, () => ({
    showModal,
  }));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [properties, setProperties] = useState<any>([]);
  const uploadExcelRef = useRef<any>();
  const [model, setModel] = useState<any>({});
  const [dataSource, setDataSource] = useState<any>([]);
  const [importMsg, setImportMsg] = useState<any>();

  const columns = [
    {
      title: '没有匹配模型属性的表头',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const showModal = (model: any) => {
    setImportMsg(null);
    setDataSource([]);
    setModel(model);
    setProperties([...model.properties]);
    setTimeout(() => {
      uploadExcelRef.current.init();
    }, uploadExcelRef.current || 50);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const uploadedExcel = async (response: any) => {
    // message 是存储在服务器的文件名称
    // result[0] 是上传Excel的列名称
    const {message, result} = JSON.parse(response || "{}");
    let fileName = message;
    let mapping: any = {};
    let headers = result[0];
    let desMap = _.keyBy(properties, "description")

    let modelName = "";
    let notMatch: any = [];
    _.forEach(headers, item => {
      if (!desMap[item]) {
        notMatch.push({
          key: item,
          name: item
        });
      } else {
        mapping[desMap[item].name] = item;
        modelName = desMap[item].model;
      }
    })
    setDataSource([...notMatch]);

    let importParams = {
      service: 'modelImportExcelService',
      tenant: ModelingApi.getTenant(),
      fileName,
      mapping,
      modelName,
    }

    let res: any = await ModelingApi.doApiImportExport(importParams);
    if (res) {
      setImportMsg(res.message)
    }
  }

  const downloadExcelTemplate = async () => {
    let modelName = model.name;
    let excelName = "模型(" + modelName + ")模板_" + moment(new Date()).format("yyyyMMDD") + ".xlsx";
    downloadModelExcelTemplate(modelName, excelName);
  }

  return (
    <>
      <ModalWrapper
        onCancel={handleCancel}
        open={isModalOpen}
        maskClosable={false}
        footer={null}
        title={"数据导入"}
        width={600}
        bodyStyle={{
          padding: 12,
          height: "500px",
        }}
      >

        <div style={{display: 'flex'}}>
          <Button disabled={!model?.id} type='link' onClick={downloadExcelTemplate}>下载模板</Button>

          <UploadExcel
            ref={uploadExcelRef }
            onSuccess={(response) => uploadedExcel(response)}/>

          {dataSource.length == 0 && importMsg && <>
            {importMsg} <Link style={{paddingLeft: '8px', color: '#106ba3'}} to={`${routePath}/TaskLog`}
                              target={'_blank'}>点击查看</Link>
          </>}
        </div>


        {dataSource.length > 0 && (
          <>
            <Alert style={{margin: "5px"}} message={`上传的文件表头没有匹配模型(${model?.description})属性，请修改后重新上传文件`} type="warning"/>

            <Table
              className={'createModel'}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              scroll={{y: 280}}
            />
          </>
        )}
      </ModalWrapper>
    </>
  );
});

export default DataRecovery;
