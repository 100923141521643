import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XEchartsDadar",
  iconSVG: IconSVG,
  defaults: {
    rows: 60,
    columns: 70,
    widgetName: "XEchartsDadar",
    version: 1,
    label: "label",
    isDownload: false,
    isDataView: false,
    isTitle: true,
    isLegend: true,
    title: "Echart",
    isShowTab:true,
    isShowLabel:true,
    borderWidth:1,
    tabsObj: {
      // "AllocatedBudget": {
      //   label: "AllocatedBudget",
      //   id: "AllocatedBudget",
      //   widgetId: "",
      //   isVisible: true,
      //   index: 0,
      //   color: "",

      // },
      // "ActualSpending": {
      //   label: "ActualSpending",
      //   id: "ActualSpending",
      //   widgetId: "",
      //   isVisible: true,
      //   index: 1,
      //   color: "",
      // },
    },
    currentData: [{
      "legend": ['AllocatedBudget', 'ActualSpending'],
      "indicator": [
        { "name": 'Sales', "max": 6500 },
        { "name": 'Administration', "max": 16000 },
        { "name": 'Information Technology', "max": 30000 },
        { "name": 'Customer Support', "max": 38000 },
        { "name": 'Development', "max": 52000 },
        { "name": 'Marketing', "max": 25000 }
      ],
      "data": [
        {
          "value": [4200, 3000, 20000, 35000, 50000, 18000],
          "name": 'AllocatedBudget'
        },
        {
          "value": [5000, 14000, 28000, 26000, 42000, 21000],
          "name": 'ActualSpending'
        }
      ]
    }]
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
