import React from "react";
import { SheetsDropdown } from "./SheetsDropdown";
import { TableHeaderIndex } from "./TableHeaderIndex";

export function GoogleSheetControls() {
  return (
    <>
      <SheetsDropdown />
      <TableHeaderIndex />
    </>
  );
}
