import React, {useEffect, useState} from 'react';
import {AtRadio} from "taro-ui";

const TRadioWidget = (props:any) => {
  const {label, value:wValue, currentData, updateWidgetProperty} = props;
  const [value, setValue] = useState('');
  const onChange = (v:any)=>{
    setValue(v);
    updateWidgetProperty&&updateWidgetProperty(v);
  };
  useEffect(()=>{
    setValue(wValue);
  }, [wValue])
  return <AtRadio
    options={currentData}
    value={value}
    onClick={onChange}
  />;
};

export default TRadioWidget;
