import styled from "styled-components";
import {Modal} from "antd";

export const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

 .ant-modal-footer {
    border-top: 1px solid rgba(217, 217, 217, 1) !important;
  }

 .ant-btn-primary[disabled] {
    background-color: rgb(222, 222, 222) !important;
    color: rgba(0, 0, 0, 0.85) !important;
 }

 .btnContainer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f0f0f0;
    padding: 0px 24px;
    Button {
      border-radius: 8px;
      margin:10px 8px 0 0;
    }
 }
`


