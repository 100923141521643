import General, { GeneralStyle, GeneralEvent } from "./General";

export default {
  editableTitle: true,
  titlePropertyName: "label",
  panelIdPropertyName: "id",
  dependencies: ["tableColumns", "tableData"],
  contentChildren: General,
  styleChildren: GeneralStyle,
  eventChildren: GeneralEvent
};
