import React, {useEffect, useState} from 'react';
import {AtTextarea} from "taro-ui";
import {LabelWidget} from "../../index";

const TTextAreaWidget = (props:any) => {
  const {label, isDisabled, updateWidgetProperty, value:wValue, count, maxLength, height,
    fontSize,
    color,
    fontStyle,
    inputFontSize,
    inputColor,
    inputFontStyle,
    bgColor,
    borderColor} = props;
  const [value, setValue] = useState<string>('');
  useEffect(()=>{
    setValue(wValue);
  }, [wValue])
  return (
    <LabelWidget label={label} fontSize={fontSize}
                 color={color}
                 fontStyle={fontStyle}
                 inputFontSize={inputFontSize}
                 inputColor={inputColor}
                 inputFontStyle={inputFontStyle}
                 bgColor={bgColor}
                 borderColor={borderColor}>
      <AtTextarea height={height} disabled={isDisabled} maxLength={maxLength} count={count} placeholder='请输入...' value={value} onChange={(v:string)=>{
        setValue(v);
        updateWidgetProperty&&updateWidgetProperty(v);
      }} />
    </LabelWidget>
    )
  ;
};

export default TTextAreaWidget;
