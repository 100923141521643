import {Alert, Button, message, Popconfirm, Spin, Upload, UploadProps} from 'antd'
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import {ModalWrapper} from "@byk/pages/AppDeploy/styled";
import {importApp} from "@byk/pages/AppDeploy/api";

const ImportProjectModal: React.FC<any> = forwardRef((
  props, ref) => {
  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [fileList, setFileList] = useState<any>([]);
  const [spinning, setSpinning] = useState(false);
  const blobUrlRef = useRef<any>({});

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleConfirmCancel = () => {
    setIsConfirmModalOpen(false)
  }

  const showModal = async () => {
    setIsModalOpen(true)
  }

  const onRemove = async () => {
    setFileList([]);
  }

  const uploadProps: UploadProps = {
    fileList: fileList,
    maxCount: 1,
    onRemove: onRemove,
    customRequest: async (option) => {
      let {onSuccess, file, onProgress} = option;
      // @ts-ignore
      file.url = blobUrlRef.current[file.uid];
      // @ts-ignore
      setFileList([file]);
      // 模拟进度更新
      setTimeout(() => {
        onProgress && onProgress({percent: 100});
        onSuccess && onSuccess("fake_upload_response");
      }, 1000);
    },

    beforeUpload: (file) => {
      const url = URL.createObjectURL(file);
      let key = file.uid;
      blobUrlRef.current[key] = url;
    }
  };

  const onImportApp = async () => {
    setSpinning(true);
    let tenantId = localStorage.getItem("tenantId");
    let userId = localStorage.getItem("userId");
    const formData: any = new FormData();
    formData.append('file', fileList[0]);
    formData.set("tenantId", tenantId);
    formData.set("userId", userId);
    formData.set("force", false);
    let res = await importApp(formData);
    if (res.success) {
      await props.refreshTable();
      message.success(res.message + "!");
      setFileList([])
      handleCancel();
    }
    setSpinning(false);
  }

  return (
    <>
      <ModalWrapper
        forceRender={true}
        footer={null}
        title={'导入应用'}
        open={isModalOpen}
        onCancel={handleCancel}
        width={380}
        okButtonProps={{disabled: fileList.length == 0}}
        bodyStyle={{padding: "24px 0px 12px 0px"}}
      >
        <div style={{padding: "0px 24px 24px 24px"}}>
          <span>导入应用：</span>
          <Upload {...uploadProps}>
            <Button> + 添加应用</Button>
          </Upload>
        </div>
        <div className='btnContainer'>
          <div className='left'></div>
          <div className='right'>
            <Button onClick={handleCancel}>取消</Button>
            <Button disabled={fileList.length == 0} type={"primary"} onClick={() => setIsConfirmModalOpen(true)}>导入</Button>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper
        forceRender={true}
        footer={null}
        title={'导入应用'}
        open={isConfirmModalOpen}
        onCancel={handleConfirmCancel}
        width={380}
        okButtonProps={{disabled: fileList.length == 0}}
        bodyStyle={{padding: "24px 0px 12px 0px"}}
      >
        <Spin tip="应用导入中..." spinning={spinning}>
          <div style={{padding: "0px 24px 24px 24px"}}>
            <Alert
              message="提示:"
              description="应用导入(已存在，不存在无影响)操作会使用压缩包内的元数据替换平台上的元数据信息，请点击确认后操作覆盖"
              type="warning" />
          </div>
          <div className='btnContainer'>
            <div className='left'></div>
            <div className='right'>
              <Button onClick={handleConfirmCancel}>取消</Button>
              <Button disabled={fileList.length == 0} type={"primary"} onClick={onImportApp}>导入</Button>
            </div>
          </div>
        </Spin>
      </ModalWrapper>
    </>
  )
})

export default ImportProjectModal;
