import React from "react";
import type {ControlProps} from "./BaseControl";
import BaseControl from "./BaseControl";
import {Input, TreeSelect} from "antd";
import {useSelector} from "react-redux";
import {getMenus} from "../../selectors/editorSelectors";
import styled from "styled-components";


const TreeSelectStyle = styled(TreeSelect)<any>`
  border-radius: var(--ads-v2-border-radius);
  background-color: var(--ads-v2-color-bg);
  border-color: var(--ads-v2-color-border);
  height: 36px;

  & :focus {
    border-color: var(--ads-v2-color-border);
  }
`

const InputStyle = styled(Input)<any>`
  margin-top: 8px;
  border-radius: var(--ads-v2-border-radius);
  background-color: var(--ads-v2-color-bg);
  border-color: var(--ads-v2-color-border);
  height: 36px;

  &:focus {
    border-color: var(--ads-v2-color-border);
  }

  &:hover {
    border-color: var(--ads-v2-color-border);
  }

  &.ant-input-focused, &.ant-input:focus {
    border-color: gray;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }
`

function Breadcrumb2Form(props: any) {
  const {propertyValue, valueChange} = props;
  const menus: any = useSelector(getMenus);

  let menuKey = propertyValue && propertyValue.length > 0 && propertyValue[0] || undefined;
  let curKey = propertyValue && propertyValue.length == 2 && propertyValue[1] || undefined;
  let newPropertyValue = [menuKey, curKey]


  const _menuTreeItem = (item: any) => {
    let mItem: any = {
      ...item,
      title: item.title,
      value: item.menuKey,
      key: item.menuKey,
      children: [],
      isLeaf: true,
    }
    if (item.children && item.children.length > 0) {
      mItem.isLeaf = false;
      item.children.forEach((cItem: any) => {
        let mCItem = _menuTreeItem(cItem)
        mItem.children.push(mCItem)
      })
    }
    return mItem;
  }

  let treeData: any[] = [];
  menus.forEach((item: any) => {
    if (item.key != 'other') {
      let mItem = _menuTreeItem(item)
      treeData.push(mItem)
    }
  })

  const onValuesChange = (changeValues: any) => {
    newPropertyValue[0] = changeValues;
    valueChange(newPropertyValue);
  }

  return (
    <>
      <TreeSelectStyle
        style={{width: '100%'}}
        value={menuKey}
        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
        treeData={treeData}
        placeholder="请选择菜单"
        treeDefaultExpandAll
        onChange={onValuesChange}
      />

      <InputStyle
        value={curKey}
        onChange={(e: any) => {
          let value = e.target.value
          newPropertyValue[1] = value
          valueChange(newPropertyValue);
        }}/>
    </>
  )
}


class Breadcrumb2Control extends BaseControl<Breadcrumb2ControlProps> {
  render() {
    return (
      <Breadcrumb2Form valueChange={this.valueChange} {...this.props} />
    )
  }

  valueChange = (result: any) => {
    this.updateProperty(this.props.propertyName, result, true);
  }


  static getControlType() {
    return "BREADCRUMB_ARRAY2";
  }
}

export interface Breadcrumb2ControlProps extends ControlProps {
}

export default Breadcrumb2Control;
