import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, message } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react';
import Api from "@byk/api/Api";
import { strToJson, uuid2 } from '@byk/utils/Utils';
import _ from "lodash";
import { apiPath } from '@byk/routes';
import { getTenant } from 'utils/localStorage';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const UploadImageWidget = (props: any) => {
  const { onEmit, onEmitClear, fileSize, ext, fileCount, btnText, accept, files, disabled,isReadonly,value } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<any>([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ file: newFile, fileList: newFileList }) => {
  }

  const onRemove  =(obj:any)=>{
      updateFileList(obj.uid,[]);
  }

  const updateFileList = (uid: any, obj: any) => {
    let list: any = fileList.filter((item: any) => {
      return item.uid != uid;
    });
    let _o:any=[...list, ...obj];
    setFileList(_o);
    onEmit && onEmit(_o)
  }

  // 图片上传
  const doImgUpload = (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    // start：进度条相关
    // 伪装成 handleChange里面的图片上传状态
    let uid = uuid2(16, 16);
    let name = file.name;
    let ext = name.substring(name.lastIndexOf('.'));
    let type = file.type;

    const imgItem: any = {
      uid: uid, // 注意，这个uid一定不能少，否则上传失败
      name: uid,
      status: 'uploading',
      url: '',
      percent: 99,
    };
    setFileList([...fileList, ...[imgItem]]);
    const reader = new FileReader();
    reader.readAsDataURL(file); // 读取图片文件
    reader.onload = (file: any) => {
      let base64 = file.target.result;
      base64 = base64.split('base64,')[1];
      let params = {
        data: base64,
        ext: ext,
        filename: name,
        type: type,
      };
      // 上传图片的base64编码，调接口后，返回 imageId
      Api.post(`${apiPath}/${getTenant()}/rest/SysFile`, params)
        .then((r: any) => {
          let list: any = [];
          if (r.success) {
            let res: any = r.result[0];
            let a = [{ code: res.code, filename: res.filename, url: `${apiPath}/${getTenant()}/file/${res.code}` }]
            const imgItem: any = {
              uid: uid, // 注意，这个uid一定不能少，否则上传失败
              code: res.code,
              name: res.filename,
              filename: res.filename,
              status: 'done',
              url: `${apiPath}/${getTenant()}/file/${res.code}`, // url 是展示在页面上的绝对链接
            };
            list = [imgItem];
          }
          else {
            message.error(r.message);
          }
          updateFileList(uid, list)
        })
        .catch((e) => {
          updateFileList(uid, []);
          message.error('图片上传失败，请重试');
        });
    };
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{btnText}</div>
    </div>
  );



useEffect(() => {
  let _r:any=strToJson(value);
  setFileList(_r||[]);
}, [value]);

useEffect(() => {
  let _r:any=strToJson(value);
  if(_.isEqual(_r, fileList)){
      return ;
  }
}, [fileList]);


  return (
    <>
      <Upload
        action="2"
        className='z-UploadImage'
        listType="picture-card"
        fileList={fileList}
        maxCount={1}
        accept={accept}
        customRequest={doImgUpload}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={onRemove}
        disabled={disabled||isReadonly}
      >
        {(fileList.length >= fileCount||isReadonly==true) ? null : uploadButton}
      </Upload>
      <Modal  open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadImageWidget;
