import React from "react";
import type { WidgetState } from "widgets/BaseWidget";
import type { WidgetType } from "constants/WidgetConstants";
import type { PhoneInputComponentProps } from "../component";
import PhoneInputComponent from "../component";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { ValidationResponse } from "constants/WidgetValidation";
import { ValidationTypes } from "constants/WidgetValidation";
import {
  createMessage,
  FIELD_REQUIRED_ERROR,
} from "@appsmith/constants/messages";
import type { DerivedPropertiesMap } from "utils/WidgetFactory";
import {
  getCountryCode,
  ISDCodeDropdownOptions,
} from "../component/ISDCodeDropdown";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import _ from "lodash";
import BaseInputWidget from "widgets/BaseInputWidget";
import derivedProperties from "./parsedDerivedProperties";
import type { BaseInputWidgetProps } from "widgets/BaseInputWidget/widget";
import { mergeWidgetConfig } from "utils/helpers";
import type { CountryCode } from "libphonenumber-js";
import { AsYouType, parseIncompletePhoneNumber } from "libphonenumber-js";
import * as Sentry from "@sentry/react";
import log from "loglevel";
import { GRID_DENSITY_MIGRATION_V1 } from "widgets/constants";
import type { Stylesheet } from "entities/AppTheming";
import {
  isAutoHeightEnabledForWidget,
  DefaultAutocompleteDefinitions,
} from "widgets/WidgetUtils";
import type { AutocompletionDefinitions } from "widgets/constants";
import { LabelPosition } from "components/constants";
import { Alignment } from "@blueprintjs/core";


export function defaultValueValidation(
  value: any,
  props: PhoneInputWidgetProps,
  _?: any,
): ValidationResponse {
  const STRING_ERROR_MESSAGE = {
    name: "TypeError",
    message: "This value must be string",
  };
  const EMPTY_ERROR_MESSAGE = { name: "", message: "" };
  if (_.isObject(value)) {
    return {
      isValid: false,
      parsed: JSON.stringify(value, null, 2),
      messages: [STRING_ERROR_MESSAGE],
    };
  }
  let parsed = value;
  if (!_.isString(value)) {
    try {
      parsed = _.toString(value);
    } catch (e) {
      return {
        isValid: false,
        parsed: "",
        messages: [STRING_ERROR_MESSAGE],
      };
    }
  }
  return {
    isValid: _.isString(parsed),
    parsed: parsed,
    messages: [EMPTY_ERROR_MESSAGE],
  };
}

class PhoneInputWidget extends BaseInputWidget<
  PhoneInputWidgetProps,
  WidgetState
> {

  static getPropertyPaneEventConfig(){
    return super.getWidgetEvents('PhoneInputWidget');
  }

  static getPropertyPaneContentConfig() {
    return mergeWidgetConfig(
      [
        {
          sectionName: "标签",
          children: [
            {
              helpText: "Sets the label text of the widget",
              propertyName: "label",
              label: "文本",
              controlType: "INPUT_TEXT",
              placeholderText: "",
              isBindProperty: false,
              isTriggerProperty: false,
              validation: { type: ValidationTypes.TEXT },
            },
            // {
            //   helpText: "Sets the label position of the widget",
            //   propertyName: "labelPosition",
            //   label: "位置",
            //   controlType: "DROP_DOWN",
            //   options: [
            //     { label: "居左", value: LabelPosition.Left },
            //     { label: "居上", value: LabelPosition.Top },
            //     { label: "自动", value: LabelPosition.Auto },
            //   ],
            //   defaultValue: LabelPosition.Top,
            //   isBindProperty: false,
            //   isTriggerProperty: false,
            //   validation: { type: ValidationTypes.TEXT },
            // },
            // {
            //   helpText: "Sets the label alignment of the widget",
            //   propertyName: "labelAlignment",
            //   label: "对齐方式",
            //   controlType: "LABEL_ALIGNMENT_OPTIONS",
            //   fullWidth: false,
            //   options: [
            //     {
            //       startIcon: "align-left",
            //       value: Alignment.LEFT,
            //     },
            //     {
            //       startIcon: "align-right",
            //       value: Alignment.RIGHT,
            //     },
            //   ],
            //   isBindProperty: false,
            //   isTriggerProperty: false,
            //   validation: { type: ValidationTypes.TEXT },
            // },
            // {
            //   helpText:
            //     "Sets the label width of the widget as the number of columns",
            //   propertyName: "labelWidth",
            //   label: "宽度（以列为单位）",
            //   controlType: "NUMERIC_INPUT",
            //   isJSConvertible: true,
            //   isBindProperty: true,
            //   isTriggerProperty: false,
            //   min: 0,
            //   validation: {
            //     type: ValidationTypes.NUMBER,
            //     params: {
            //       natural: true,
            //     },
            //   },
            // },
          ],
        },
        {
          sectionName: "数据",
          children: [
            {
              helpText:
                "Sets the default text of the widget. The text is updated if the default text changes",
              propertyName: "defaultText",
              label: "默认值",
              controlType: "INPUT_TEXT",
              placeholderText: "(000) 000-0000",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {
                type: ValidationTypes.FUNCTION,
                params: {
                  fn: defaultValueValidation,
                  expected: {
                    type: "string",
                    example: `(000) 000-0000`,
                    autocompleteDataType: AutocompleteDataType.STRING,
                  },
                },
              },
            },
            {
              helpText: "Changes the country code",
              propertyName: "defaultDialCode",
              label: "默认国家编码",
              enableSearch: true,
              dropdownHeight: "156px",
              controlType: "DROP_DOWN",
              searchPlaceholderText: "Search by code or country name",
              options: ISDCodeDropdownOptions,
              virtual: true,
              isJSConvertible: true,
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {
                type: ValidationTypes.TEXT,
              },
            },
            {
              propertyName: "allowDialCodeChange",
              label: "启用切换国家编码",
              helpText: "Search by country",
              controlType: "SWITCH",
              isJSConvertible: true,
              isBindProperty: true,
              isTriggerProperty: false,
              validation: { type: ValidationTypes.BOOLEAN },
            },
          ],
        },
        {
          sectionName: "验证",
          children: [
            {
              propertyName: "isRequired",
              label: "是否必填",
              helpText: "Makes input to the widget mandatory",
              controlType: "SWITCH",
              isJSConvertible: true,
              isBindProperty: true,
              isTriggerProperty: false,
              validation: { type: ValidationTypes.BOOLEAN },
            },
          ],
        },
        {
          sectionName: "一般",
          children: [
            {
              helpText: "Sets a placeholder text for the input",
              propertyName: "placeholderText",
              label: "引导文字",
              controlType: "INPUT_TEXT",
              placeholderText: "请输入",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {type: ValidationTypes.TEXT},
            },

          ],
        },
        // {
        //   sectionName: "一般",
        //   children: [
        //     {
        //       propertyName: "allowFormatting",
        //       label: "Enable Formatting",
        //       helpText: "Formats the phone number as per the country selected",
        //       controlType: "SWITCH",
        //       isJSConvertible: true,
        //       isBindProperty: true,
        //       isTriggerProperty: false,
        //       validation: { type: ValidationTypes.BOOLEAN },
        //     },
        //   ],
        // },
      ],
      super.getPropertyPaneContentConfig(),
    );
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc":
        "An input text field is used to capture a phone number. Inputs are used in forms and can have custom validations.",
      "!url": "https://docs.appsmith.com/widget-reference/phone-input",
      text: {
        "!type": "string",
        "!doc": "The text value of the input",
        "!url": "https://docs.appsmith.com/widget-reference/phone-input",
      },
      value: {
        "!type": "string",
        "!doc": "The unformatted text value of the input",
        "!url": "https://docs.appsmith.com/widget-reference/phone-input",
      },
      isValid: "bool",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      isDisabled: "bool",
      countryCode: {
        "!type": "string",
        "!doc": "Selected country code for Phone Number",
      },
      dialCode: {
        "!type": "string",
        "!doc": "Selected dialing code for Phone Number",
      },
      dialCodeAndValue: "string"
    };
  }

  static getPropertyPaneStyleConfig() {
    return super.getPropertyPaneStyleConfig();
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      isValid: `{{(() => {${derivedProperties.isValid}})()}}`,
      dialCodeAndValue: `{{"("+this.dialCode+")"+this.value}}`,
      getValue: `{{"("+this.dialCode+")"+(this.value||this.text)}}`,
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return _.merge(super.getMetaPropertiesMap(), {
      value: "",
      dialCode: undefined
    });
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return _.merge(super.getDefaultPropertiesMap(), {
      dialCode: "defaultDialCode",
    });
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
      it_color: "{{appsmith.theme.form.inputText.fontColor}}",
      it_size: "{{appsmith.theme.form.inputText.fontSize}}",
      it_style: "{{appsmith.theme.form.inputText.fontStyle}}",
      labelTextColor: "{{appsmith.theme.form.label.fontColor}}",
      labelTextSize: "{{appsmith.theme.form.label.fontSize}}",
      labelStyle: "{{appsmith.theme.form.label.fontStyle}}",
      ib_bgColor: "{{appsmith.theme.form.inputBorder.bgColor}}",
      ib_borderColor: "{{appsmith.theme.form.inputBorder.bgBorderColor}}",
    };
  }

  getFormattedPhoneNumber(value: string) {
    const countryCode = getCountryCode(this.props.dialCode);
    let formattedValue;

    if (!value) {
      formattedValue = value;
    } else if (this.props.allowFormatting) {
      formattedValue = new AsYouType(countryCode as CountryCode).input(value);
    } else {
      formattedValue = parseIncompletePhoneNumber(value);
    }

    return formattedValue;
  }
  handlePhone(value:string){
    if(value.startsWith('(')){
      return value.substring(value.lastIndexOf(')'));
    }
    return value;
  }
  handleCode(value:string){
    if(value.startsWith('(')){
      return value.substring(1, value.indexOf(')'));
    }
    return value;
  }
  initValue(){
    if (!!this.props.text) {
      try {
        let _text = this.handlePhone(this.props.text);
        let _code = this.handleCode(this.props.text);
        const formattedValue = this.getFormattedPhoneNumber(_text);

        this.props.updateWidgetMetaProperty("value", _text);
        this.props.updateWidgetMetaProperty("text", formattedValue);
        this.onISDCodeChange(_code);
      } catch (e) {
        log.error(e);
        Sentry.captureException(e);
      }
    }
  }

  componentDidMount() {
    //format the defaultText and store it in text
    this.initValue();
  }

  componentDidUpdate(prevProps: PhoneInputWidgetProps) {
    if (prevProps.dialCode !== this.props.dialCode) {
      this.onISDCodeChange(this.props.dialCode);
    }

    if (prevProps.allowFormatting !== this.props.allowFormatting) {
      let _value = this.handlePhone(this.props.value);
      const formattedValue = this.getFormattedPhoneNumber(_value);
      this.props.updateWidgetMetaProperty("text", formattedValue);
    }

    // When the default text changes
    if (
      prevProps.text !== this.props.text &&
      this.props.text === this.props.defaultText
    ) {
      let _text = this.handlePhone(this.props.text);
      let _code = this.handleCode(this.props.text);
      const formattedValue = this.getFormattedPhoneNumber(_text);
      this.onISDCodeChange(_code);
      this.props.updateWidgetMetaProperty(
        "value",
        parseIncompletePhoneNumber(formattedValue),
      );
      this.props.updateWidgetMetaProperty("text", formattedValue);
    }

    // If defaultText property has changed, reset isDirty to false
    if (this.props.defaultText !== prevProps.defaultText) {
      if (this.props.isDirty) {
        this.props.updateWidgetMetaProperty("isDirty", false);
      }
    }
  }

  onISDCodeChange = (dialCode?: string) => {
    const countryCode = getCountryCode(dialCode);

    this.props.updateWidgetMetaProperty("dialCode", dialCode);
    this.props.updateWidgetMetaProperty("countryCode", countryCode);

    if (this.props.value && this.props.allowFormatting) {
      let _value = this.handlePhone(this.props.value);
      const formattedValue = this.getFormattedPhoneNumber(_value);

      this.props.updateWidgetMetaProperty("text", formattedValue);
    }
  };

  onValueChange = (value: string) => {
    let formattedValue;

    // Don't format, as value is typed, when user is deleting
    if (value && value.length > this.props.text?.length) {
      formattedValue = this.getFormattedPhoneNumber(value);
    } else {
      formattedValue = value;
    }

    this.props.updateWidgetMetaProperty(
      "value",
      parseIncompletePhoneNumber(formattedValue),
    );
    let _code = this.handleCode(this.props.text);
    const _str = super.reDynamicStringFn(this.props.onTextChanged as string);
    this.props.updateWidgetMetaProperty("text", formattedValue, {
      triggerPropertyName: "onTextChanged",
      dynamicString: _str,
      event: {
        type: EventType.ON_TEXT_CHANGE,
      },
    });
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
  };

  handleFocusChange = (focusState: boolean) => {
    if (focusState) {
      const _str = super.reDynamicStringFn(this.props.onFocus as string);
      this.props.updateWidgetMetaProperty("isFocused", focusState, {
        triggerPropertyName: "onFocus",
        dynamicString: _str,
        event: {
          type: EventType.ON_FOCUS,
        },
      });
    }
    if (!focusState) {
      const _str = super.reDynamicStringFn(this.props.onBlur as string);
      this.props.updateWidgetMetaProperty("isFocused", focusState, {
        triggerPropertyName: "onBlur",
        dynamicString: _str,
        event: {
          type: EventType.ON_BLUR,
        },
      });
    }
    super.handleFocusChange(focusState);
  };

  handleKeyDown = (
    e:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    super.handleKeyDown(e);
  };

  resetWidgetText = () => {
    super.resetWidgetText();
    this.props.updateWidgetMetaProperty("value", undefined);
  };

  getPageView() {
    let value = this.props.text ?? "";
    // if(!this.props.isDirty&&value==""&&(this.props.defaultText!=""||this.props.value!="")){
    //   let _value = this.props.value||this.props.defaultText;
    //   let valueArr:any = _value?.split(')');
    //   this.onISDCodeChange(valueArr[0].substring(1));
    //   this.onValueChange(valueArr[1]);
    // }


    const isInvalid =
      "isValid" in this.props && !this.props.isValid && !!this.props.isDirty;
    const countryCode = this.props.countryCode;
    const conditionalProps: Partial<PhoneInputComponentProps> = {};
    conditionalProps.errorMessage = this.props.errorMessage;
    if (this.props.isRequired && value.length === 0) {
      conditionalProps.errorMessage = createMessage(FIELD_REQUIRED_ERROR);
    }

    return (
      <PhoneInputComponent
        accentColor={this.props.accentColor}
        allowDialCodeChange={this.props.allowDialCodeChange}
        autoFocus={this.props.autoFocus}
        borderRadius={this.props.borderRadius}
        boxShadow={this.props.boxShadow}
        compactMode={
          !(
            (this.props.bottomRow - this.props.topRow) /
              GRID_DENSITY_MIGRATION_V1 >
            1
          )
        }
        countryCode={countryCode}
        defaultValue={this.props.defaultText}
        dialCode={this.props.dialCode}
        disableNewLineOnPressEnterKey={!!this.props.onSubmit}
        disabled={this.props.isDisabled}
        iconAlign={this.props.iconAlign}
        iconName={this.props.iconName}
        inputType={this.props.inputType}
        isDynamicHeightEnabled={isAutoHeightEnabledForWidget(this.props)}
        isInvalid={isInvalid}
        isLoading={this.props.isLoading}
        label={this.props.label}
        labelAlignment={this.props.labelAlignment}
        labelPosition={this.props.labelPosition}
        labelStyle={this.props.labelStyle}
        labelTextColor={this.props.labelTextColor}
        labelTextSize={this.props.labelTextSize}
        labelWidth={this.getLabelWidth()}
        onFocusChange={this.handleFocusChange}
        onISDCodeChange={this.onISDCodeChange}
        onKeyDown={this.handleKeyDown}
        onValueChange={this.onValueChange}
        placeholder={this.props.placeholderText}
        showError={!!this.props.isFocused}
        tooltip={this.props.tooltip}
        value={value}
        widgetId={this.props.widgetId}
        isRequired={this.props.isRequired}
        readonly={this.props.readonly}
        it_color={this.props.it_color}
        it_size={this.props.it_size}
        it_style={this.props.it_style}
        ib_bgColor={this.props.ib_bgColor}
        ib_borderColor={this.props.ib_borderColor}
        {...conditionalProps}
      />
    );
  }

  static getWidgetType(): WidgetType {
    return "PHONE_INPUT_WIDGET";
  }
}

export interface PhoneInputWidgetProps extends BaseInputWidgetProps {
  dialCode?: string;
  countryCode?: CountryCode;
  defaultText?: string;
  allowDialCodeChange: boolean;
}

export default PhoneInputWidget;
