import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Button, message, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import styled from "styled-components";
import Spin from "antd/es/spin";
import { apiPath } from "@byk/routes";
import { getTenant } from "utils/localStorage";

const UploadWrapper = styled(Upload)<any>`
  .ant-progress {
    display: none;
  }
`

interface IProps {
  ref?: any;
  onSuccess: (result: any) => void;
}

const UploadExcel: React.FC<IProps> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    init,
  }));

  // 只接受 xlsx 和 xls 格式
  const acceptTypes = '.xlsx, .xls';
  const [spinning, setSpinning] = useState<boolean>(false);
  const init = () => {
    setSpinning(false);
  }

  const handleUpload = (options: any) => {
    const {onError, file} = options;
    const formData = new FormData();
    formData.append("file", file);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `/byk/${getTenant()}/readExcel`);
    xhr.onload = () => {
      if (xhr.status !== 200) {
        onError("上传失败");
        return;
      }
      props.onSuccess(xhr.response);
      setSpinning(false);
    };
    xhr.onerror = () => {
      onError("上传失败");
      setSpinning(false);
    };
    xhr.send(formData);
  };

  // 文件上传前的检查
  const beforeUpload = (file: any) => {
    setSpinning(true);
    const fileType = file.name;
    const fileExtName = fileType.slice(fileType.lastIndexOf('.') + 1).toLowerCase();
    const isAccepted = acceptTypes.includes(fileExtName);
    if (!isAccepted) {
      message.error(`只能上传 ${acceptTypes} 格式的文件！`).then();
    }
    const isLt2M = file.size / 1024 / 1024 < 2; // 限制文件大小不超过 2MB
    if (!isLt2M) {
      message.error('文件大小不得超过 2MB！').then();
    }
    return isAccepted && isLt2M;
  };

  return (
    <Spin tip="文件上传中..." spinning={spinning}>
      <UploadWrapper
        {...props}
        customRequest={handleUpload}
        beforeUpload={beforeUpload}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined/>}>上传文件</Button>
      </UploadWrapper>
    </Spin>
  );
})

export default UploadExcel;
