import type {TreeProps} from 'antd/es/tree';
import Tree from "antd/es/tree";
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Button, Modal} from "antd";
import _ from 'lodash';
import pinyin from "pinyin";
import {PageEleModel} from "@byk/pages/modeling/lib/PageEleModel";
import styled from "@emotion/styled";
import {getPropertyDefaultLength, isNoDefVal} from "@byk/pages/modeling/ModelDataUtil";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-input {
    border-radius: 5px;
    border: 1px solid rgba(228, 228, 229, 1);
  }
`

const ButtonWrapper = styled(Button)<{disabled?:boolean}>`
  height: 40px;
  border-radius: 5px;
  margin:10px 8px 0 0;

  .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
`

export interface Props {
  setProperties: (properties: any) => void;
  pageEleModel: PageEleModel;
}

const PageEleTree: React.FC<any> = forwardRef(
  (
    {setProperties, pageEleModel}: Props, ref
  ) => {
    useImperativeHandle(ref, () => ({
      init,
    }));

    const [keys, setKeys] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const treeRef = useRef<any>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const checkedNodes = useRef<any>([]);

    const setTreeDisable = (titles: any) => {
      let checkKeys: any[] = [];

      function _setTreeDisable(dataList: any) {
        let data: any = [];
        _.forEach(dataList, item => {
          item.disabled = false;
          if (titles.indexOf(item.title) > -1) {
            // item.disabled = true;
            checkKeys.push(item.key);
          }
          data.push(item);
        })
        return data;
      }

      let data: any = [];
      _.forEach(pageEleModel.getPageEleTree(), item => {
          item.disabled = false;
          if (titles.indexOf(item.title) > -1) {
            // item.disabled = true;
            checkKeys.push(item.key);
          }
          if (item.children) {
            item.children = [..._setTreeDisable(item.children)];
          }
          data.push(item);
        }
      )
      setData(data);
      setKeys([...checkKeys]);
    }

    const init = (titles: any[]) => {
      setData(pageEleModel.getPageEleTree());
      setTreeDisable(titles);
      setIsModalOpen(true);
    }

    const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
      checkedNodes.current = info.checkedNodes;
      setKeys(checkedKeys)
    };

    const addProperty2Model = () => {
      let nodeMap = _.mapKeys(checkedNodes.current || [], 'key');
      let properties: any = [];
      let titles: any = [];
      _.forEach(keys, (key) => {
        let node = nodeMap[key];
        if (node.isLeaf) {
          let attr = pageEleModel.getAttrByKey(key);
          const pinyinArray = pinyin(attr, {style: pinyin.STYLE_NORMAL});
          // 拼接拼音数组为字符串
          const pinyinString = pinyinArray.map(item => item[0]).join('');
          let noDefVal = isNoDefVal(node.type);
          properties.push({
            name: pinyinString,
            description: attr,
            key: attr,
            id: null,
            type: node.type,
            defaultValue: !noDefVal ? node.defaultValue : null,
            nullable: node.nullable,
            length: node.length || getPropertyDefaultLength(node.type),
            dictName: node.dictName,
          });
          titles.push(attr);
        }
      })
      setProperties(properties);
      setTreeDisable(titles);
      handleCancel();
    }

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <ModalWrapper
        className={"createModel"}
        onCancel={handleCancel} open={isModalOpen}
        maskClosable={false}
        footer={null}
        title={"选择UI元素"}
        width={800}
        bodyStyle={{
          padding: 0,
          height: "500px",
        }}
        style={{
          marginRight: "260px",
        }}
      >
        <Tree
          ref={treeRef}
          checkable
          defaultExpandedKeys={[]}
          defaultSelectedKeys={[]}
          defaultCheckedKeys={[]}
          // onSelect={onSelect}
          onCheck={onCheck}
          checkedKeys={keys}
          treeData={data}
          style={{
            width: "100%",
            overflow: "auto",
            height: "calc(500px - 80px)"
          }}
        />
        <div style={{
          paddingRight: '16px',
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid rgb(228, 232, 235)'
        }}>
          <ButtonWrapper onClick={handleCancel}>
            取消
          </ButtonWrapper>
          <ButtonWrapper disabled={keys.length == 0} type={keys.length == 0 ? 'default': 'primary'} onClick={addProperty2Model}>
            添加为属性
          </ButtonWrapper>
        </div>
      </ModalWrapper>
    );
  }
)

export default PageEleTree;
