import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "AtButton",
  iconSVG: IconSVG,
  needsMeta: true,
  defaults: {
    rows: 3,
    columns: 40,
    widgetName: "AtButton",
    version: 1,
    animateLoading: true,
    isCircle: false,
    isDisabled: false,
    btnLoading: false,
    isVisible: true,
    label: '确定',
    bgColor: '#6190e8',
    borderColor: '#6190e8',
    color: '#fff',
    fontSize: 16,
    buttonVariant: 'primary',
    buttonSize: 'normal',
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventsConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  }
};

export default Widget;
