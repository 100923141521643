import { Button, Modal,message} from 'antd';
import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import SearchForm from './SearchForm';
import type { IProps } from './SearchForm';
import Api from "@byk/api/Api";

import styled from "styled-components";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }
`


export interface ModalIProops extends IProps {
    apiPost: string,
    apiPut: string,
    reformData?: (params?: any,type?:any) => any,//对提交的数据进行改造
    customValidFun?: (params?: any) => any,//自定义追加验证
    afterSaveEvent?: (params?: any,type?:any) => any,
    isShowAdd?: boolean,
    editTitle?:string,
    getContainer?:string,
    propsExt?:any
}

const XModal: React.FC<ModalIProops> = forwardRef(({
    option, apiPost, apiPut, refreshTable,reformData,customValidFun,afterSaveEvent,isShowAdd,editTitle,propsExt,getContainer
}, ref) => {

    useImperativeHandle(ref, () => ({
        showModal,
        handleCancel,
        edit,
        setConfirmLoading,
        add
    }))

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [formStatus, setFormStatus] = useState('add');
    const [recordId, setRecordId] = useState(0);
    const [editTitleName, setEditTitleName] = useState(editTitle||"编辑");
    const [propsExtObj, setPropsExtObj] = useState(propsExt||{});



    const showModal = () => {
        setIsModalOpen(true);
    };
    const add = () => {
        setFormStatus('add');
        formRef.current?.resetFields();
        showModal();
    };
    const edit = (values: any) => {
        setFormStatus('edit');
        formRef.current?.setFieldsValue({
            ...values
        });
        setRecordId(values.id);
        showModal();
    };
    const formRef = useRef<any>();

    const handleOk = () => {
        console.log('ok');
        formRef.current?.submit();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setConfirmLoading(false);
    };
    //刷新table数据
    const onClear = () => {
        refreshTable && refreshTable();
    }

    const doCustomValidFun= (params: any) => {
        let _r=true;
        if(customValidFun!=undefined){
            let valid:any = customValidFun(params);
            if(!valid.success){
                message.error(valid.message);
                _r= false;
            }
        }
        return _r;
    }

    //新增
    const addSubmit = async (params: any) => {
        let _p = params;
        if(reformData!=undefined){
            _p = reformData(params,0)
        }
        if(doCustomValidFun(params)==false){
            return false;
        }
        setConfirmLoading(true);
        let result:any = await Api.post(apiPost, _p);
        if (result.success) {
            //保存成功后的事件
            if(afterSaveEvent!=undefined){
                //0代表新增，1代表编辑
                afterSaveEvent(result,0)
            }
            handleCancel();
            onClear();
        }
        else{
            //handleCancel();
            setConfirmLoading(false);
            //message.error(result.message);
        }
    };
    //编辑
    const editSubmit = async (params: any) => {
        let _p = params;
        if(reformData!=undefined){
            _p = reformData(params,1)
        }
        if(doCustomValidFun(params)==false){
            return false;
        }
        setConfirmLoading(true);
        let result:any = await Api.put(apiPut, {
            ..._p,
            id: recordId
        });
        if (result.success) {
            handleCancel();
            onClear();
            //保存成功后的事件
            if(afterSaveEvent!=undefined){
                //0代表新增，1代表编辑
                afterSaveEvent(result,1)
            }
        }else{
            setConfirmLoading(false);
            message.error(result.message);
        }
    };

    const onSave = (values: any) => {
        if (formStatus === 'add') {
            addSubmit(values);
        } else if (formStatus === 'edit') {
            editSubmit(values);
        } else {
            console.log('formStatus', formStatus);
        }

    };
    let addButton:any=<Button type="primary" onClick={add}>
                        新增
                   </Button>;
    if(isShowAdd!=undefined&&(!isShowAdd)){
        addButton=null;
    }
    return (
        <>
            <div className='x-mt10 x-mb10'>
                {addButton}
            </div>
            <ModalWrapper getContainer={getContainer} forceRender={true} {...propsExtObj} confirmLoading={confirmLoading} cancelText={'取消'} title={formStatus === 'add' ? '新增' : editTitleName} okText={"提交"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <SearchForm formRef={formRef}
                    option={option}
                    onSearch={onSave}
                    onClear={onClear}
                />
            </ModalWrapper>
        </>
    );
});

export default XModal;
