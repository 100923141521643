import React, { useRef, useState, useEffect } from "react";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, Tooltip, Drawer, Form, Input, Select, message, Divider, Table, Tabs, Affix, Row, Col, Tag, Descriptions } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import localStorage, {getAppId, getTenant} from "utils/localStorage";
import _ from "lodash";
import TableList from "@byk/pages/components/TableList";
import { uuid2 } from "@byk/utils/Utils";
import {apiPath} from "@byk/routes";
class Menu extends React.Component {
  formInputRef: React.RefObject<any>;
  tableRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.formInputRef = React.createRef();
    this.tableRef = React.createRef();
  }
  state = {
    appId: getAppId(),
    initParams: {
      appId: getAppId()
    },
    menuData: [],
    languageName: {},
    inputData: { menuKey: "", enable: false },
    columnList: [],
    enable: false,
    code: "",
    isAdd: false,
    key:uuid2(16,16),
    isSavePage:true,
    savePageKey:"InternationalizeText",
    isSearchByOldPage:false,
  };
  that: any;
  componentDidMount() {
    //this.bindMenu();
    this.props.onRef && this.props.onRef(this);
  }
  // api
  apiGet: string = `${apiPath}/${getTenant()}/i18n/textI18N`;
  bindMenu(res:any) {
    //let res: any = await Api.get(this.apiGet, { appId: this.state.appId });
    //if (res.success) {
      //debugger;
      let list = res.ext
      this.bindColumns(list.languageName);
      let _list= [...res.result];
      if(_list.length==1&&_.isEmpty(_list[0].appId)){
        _list=[];
      }
      let j:any=[];
      _.each(_list,(item:any)=>{
        item.key=item.code;
        j.push(item);
      })
      this.setState({ menuData: j, languageName: list.languageName });
    //}
  };

  formatData(res:any){
    let list = res.ext
      let _list= [...res.result];
      if(_list.length==1&&_.isEmpty(_list[0].appId)){
        _list=[];
      }
      let j:any=[];
      _.each(_list,(item:any)=>{
        item.key=item.code;
        j.push(item);
      })
      return j;
      //this.setState({ menuData: j, languageName: list.languageName });
  }

  bindColumns(languageName: any) {
    let that = this;
    let list: any = [{
      title: 'code',
      dataIndex: 'code',
      key: 'code',
      width: 160,
      align: "left",
      render: (_: any, record: any) => (
        <>
          <Form.Item
            label=""
            colon={false}
            name={"code"}
            style={{ margin:0}}
          >
            {
              (that.state.inputData["menuKey"] == record.code && that.state.inputData["enable"] == true&&this.state.isAdd==true) ?
                <Input style={{ width: "100%" }}
                  placeholder="请输入字段"
                  maxLength={200}
                  allowClear
                  key={"code"}
                /> :
                <span>
                  {record["code"]}
                </span>
            }
          </Form.Item>
        </>
      ),
    }];
    for (let key in languageName) {
      //if (languageName.hasOwnProperty(key)) {  // 确保键在对象自身上，而不是从原型链继承
        list.push({
          title: languageName[key],
          dataIndex: key,
          key: key,
          // width: 110,
          align: "center",
          render: (_: any, record: any) => (
            <>
              <Form.Item
                label=""
                colon={false}
                name={key}
                style={{ margin:0}}
              >
                {
                  (that.state.inputData["menuKey"] == record.code && that.state.inputData["enable"] == true) ?
                    <Input style={{ width: "100%" }}
                      placeholder="请输入字段"
                      maxLength={200}
                      allowClear
                      key={"lang." + record.code}
                    /> :
                    <span>
                      {record[key]}
                    </span>
                }
              </Form.Item>
            </>
          ),
        });
      //}
    }

    list.push({
      title: "操作",
      key: "action",
      render: (_: any, record: any) => (
        <>
          <Space size="middle" style={{ display: that.state.inputData["menuKey"] == record.code && that.state.inputData["enable"] == true ? '' : 'none' }} >
            <Button type="link" htmlType="submit" >
              保存
            </Button>
            <Button type="link" onClick={() => { that.cancel(record) }}>
              取消
            </Button>
          </Space>
          <Space size="middle" style={{ display: (that.state.inputData["menuKey"] == record.code && that.state.inputData["enable"] == true) ? 'none' : '' }}>
            <Button type="link" onClick={() => { that.edit(record) }}>
              编辑
            </Button>
            {record.textType != 'System' &&
            <Popconfirm
              placement="left"
              title={"确认删除吗？"}
              onConfirm={() => { that.delete(record) }}
              okText="删除"
              cancelText="取消"
            >
              <Button type="link">
                删除
              </Button>
            </Popconfirm>

            }
          </Space>
        </>
      ),
      width: 100,
    });
    this.state.columnList = list;
  }
  add() {
    // let data = { appId: this.state.appId, code: "b", zh: "中文", en: "english" };
    // let result1: any = await Api.post(this.apiGet, data);;
    // if (result1.success) {
    //   message.success("保存成功!");
    //   this.cancel();
    //   this.bindMenu();
    // }
    let _tableData: any = [...this.state.menuData];
    let addList=_tableData.filter((itm:any)=>{
      return itm.mode=="add";
    });
    if(addList.length>0||this.state.inputData.enable){
      message.warn("请先保存！");
      return false;
    }
    let _code = (new Date()).valueOf();
    let langList = {...this.state.languageName};
    let _data: any = {};
    _data.code = _code;
    //_data.code = "";
    _data.mode = "add";
    for (let key in langList) {
      //if (langList.hasOwnProperty(key)) {  // 确保键在对象自身上，而不是从原型链继承
        _data[key] = null;
      //}
    };
    _tableData = [_data, ..._tableData];
    let data1 = { enable: true, menuKey: _code };
    this.tableRef.current.setTableData([..._tableData]);
    this.setState({ inputData: data1, menuKey: _code, enable: true, code: _code, menuData: [..._tableData], isAdd: true }, () => {
      this.formInputRef.current?.setFieldsValue(_data);
      console.log(this.state.menuData);
      console.log(this.state.inputData);
    });

  }
  edit(record: any) {
    let data = { enable: true, menuKey: record.code };
    let langList = this.state.languageName;
    this.bindColumns(langList);
    let _data: any = {};
    for (let key in langList) {
      //if (langList.hasOwnProperty(key)) {  // 确保键在对象自身上，而不是从原型链继承
        _data[key] = record[key];
      //}
    }
    this.setState({ inputData: data, menuKey: record.code, enable: true, code: record.code, isAdd: false }, () => {
      console.log(this.state.inputData)
      this.formInputRef.current?.setFieldsValue(_data);
    });

  };

  async delete(record?: any) {
    let data: any = [{ appId: record.appId, code: record.code }];
    let result1: any = await Api.delete(this.apiGet, data);
    if (result1.success) {
      message.success("删除成功!");
      this.cancel(record);
      this.init();
    }
  };

  init(isSearchByOldPage:any=false) {
    let params: any = this.state.initParams;
    let _key=uuid2(16,16);
    if(isSearchByOldPage){
      _key=this.state.key;
    }
    this.setState({ initParams: params,key:_key }, () => {
      if (this.tableRef.current) {
        this.tableRef.current.getTableList(params);
      }
    })
  };

  cancel(record?: any) {
    let _tableData: any = [...this.state.menuData];
    let data = { enable: false, menuKey: "" };
    if(record.mode=="add"){
      let j=_tableData.filter((item:any)=>{
        return item.code!=record.code;
      })
      this.tableRef.current.setTableData([...j]);
      this.setState({menuData: [...j],inputData: data})
    }
    else{
      this.setState({ inputData: data },()=>{
        this.init();
      });
    }
  };

  async onFinish(values: any) {
    console.log("values", values)
    let data: any = values;
    data.appId = this.state.appId;
    //data.code = this.state.code;
    console.log("data", data)
    let result1: any = null;
    if (this.state.isAdd) {
      result1 = await Api.post(this.apiGet, data);
    }
    else {
      data.code = this.state.inputData.menuKey;
      result1 = await Api.put(this.apiGet, data);
    }

    if (result1.success) {
      message.success("保存成功!");
      let data = { enable: false, menuKey: "" };
      this.setState({ inputData: data },()=>{
        this.init(true);
      });
    }
  };




  render() {
    const tableProps = {
      columns: this.state.columnList,
      api: this.apiGet,
      size:"small",
      pagination: {
        currPage: 1,
        pageSize: 10,
      },
      initParams: this.state.initParams,
      callback:(e:any)=>this.bindMenu(e),
      formatData:(e:any)=>this.formatData(e),
    };

    return (
      <>
        <div className="x-main-box-in">
          <Button type="primary" onClick={() => { this.add() }}>
            新增
          </Button>
          <Form
            style={{ marginTop: "10px" }}
            key="inputForm"
            ref={this.formInputRef}
            onFinish={(v) => this.onFinish(v)}
          >
            <TableList ref={this.tableRef} {...tableProps}   key={this.state.key}
            isSavePage={this.state.isSavePage}
            savePageKey={this.state.savePageKey}
            isSearchByOldPage={this.state.isSearchByOldPage}
            />
            {/* <Table columns={this.state.columnList} bordered={true} size="small" pagination={false} defaultExpandAllRows={true} dataSource={this.state.menuData} /> */}
          </Form>
        </div >
      </>
    );
  }
}
export default withRouter(Menu);
