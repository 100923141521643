import {ValidationTypes} from "../../../../../constants/WidgetValidation";
import {get} from "lodash";
import {AutocompleteDataType} from "../../../../../utils/autocomplete/AutocompleteDataType";
import {maxValueValidation, minValueValidation} from "../../../../../widgets/InputWidgetV2/widget";
import {DateFormatOptions} from "../../../../../widgets/DatePickerWidget2/widget/constants";
//根据配置字段类型，显示对应的属性
const hideByColumnType = (
  props: any,
  propertyPath: string,
  columnTypes: ColumnTypes[],
  isParent?: boolean
) => {
  let baseProperty = propertyPath.split('.');
  if(!isParent){
    baseProperty.pop();
  }
  const columnType = get(props, `${baseProperty.join('.')}.columnType`);
  return !columnTypes.includes(columnType);
};
//返回指定属性的值
const rePropertyPathValue = (
  props: any,
  propertyPath: string,
  key: string,
  isParent?: boolean
) => {
  let baseProperty = propertyPath.split('.');
  if(!isParent){
    baseProperty.pop();
  }
  return get(props, `${baseProperty.join('.')}.${key}`);
};
//字段类型
enum ColumnTypes {
  TEXT = "text",
  RICH = "rich",
  BUTTON = "button",
  IMAGE = "image",
  VIDEO = "video",
  DATE = "date",
  SELECT = "select",
  SWITCH = "switch",
}
//属性
export default [
  {
    sectionName: "一般",
    children: [
      {
        propertyName: "columnType",
        label: "列类型",
        helpText: "Type of column to be shown corresponding to the data of the column",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "纯文本",
            value: ColumnTypes.TEXT,
          },
          {
            label: "富文本",
            value: ColumnTypes.RICH,
          },
          {
            label: "日期",
            value: ColumnTypes.DATE,
          },
          {
            label: "按钮",
            value: ColumnTypes.BUTTON,
          },
          {
            label: "图片",
            value: ColumnTypes.IMAGE,
          },
          {
            label: "视频",
            value: ColumnTypes.VIDEO,
          },
          {
            label: "开关",
            value: ColumnTypes.SWITCH,
          }
        ],
        dependencies: ["tableColumns", "tableAction"],
        hidden: (props: any, propertyPath: string) => {
          return propertyPath.startsWith('tableAction');
        }
      },
      {
        propertyName: "columnReg",
        dependencies: ["tableColumns", "tableAction"],
        label: "格式化",
        helpText: "Controls the visibility of the cell in the column",
        controlType: "INPUT_TEXT",
        customJSControl: "TABLE_COMPUTE_VALUE",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText: "Sets the format of the selected date",
        propertyName: "dateFormat",
        label: "日期格式",
        controlType: "DROP_DOWN",
        isJSConvertible: true,
        optionWidth: "340px",
        options: DateFormatOptions,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.TEXT},
        hideSubText: true,
        dependencies: ["tableColumns", "tableAction"],
        hidden: (props: any, propertyPath: string) => {
          return hideByColumnType(props, propertyPath, [
            ColumnTypes.DATE
          ]);
        }
      },
      {
        propertyName: "isCellVisible",
        dependencies: ["tableColumns", "tableAction"],
        label: "是否可见",
        helpText: "Controls the visibility of the cell in the column",
        defaultValue: true,
        controlType: "SWITCH",
        customJSControl: "TABLE_COMPUTE_VALUE",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
          params: {
            type: ValidationTypes.BOOLEAN,
          },
        },
      },
      {
        propertyName: "isCellDisable",
        label: "是否禁用",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        customJSControl: "TABLE_COMPUTE_VALUE",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
          params: {
            type: ValidationTypes.BOOLEAN,
          },
        },
        dependencies: ["tableColumns", "tableAction"],
        hidden: (props: any, propertyPath: string) => {
          return hideByColumnType(props, propertyPath, [
            ColumnTypes.BUTTON
          ]);
        }
      },
      {
        propertyName: "isEdit",
        label: "是否可编辑",
        helpText: "Controls the visibility of the tab",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.BOOLEAN},
        dependencies: ["tableColumns", "tableAction"],
        hidden: (props: any, propertyPath: string) => {
          return hideByColumnType(props, propertyPath, [
            ColumnTypes.TEXT,
            ColumnTypes.DATE,
            ColumnTypes.SELECT,
            ColumnTypes.SWITCH
          ]);
        }
      }
    ]
  },
  {
    sectionName: "设置",
    hidden: (props: any, propertyPath: string) => {
      return hideByColumnType(props, propertyPath, [
        ColumnTypes.BUTTON
      ], true);
    },
    children: [
      {
        propertyName: "label",
        label: "按钮文字",
        helpText: "text",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.TEXT},
      },
    ],
  },
  {
    sectionName: "排序",
    hidden: (props: any, propertyPath: string) => {
      return hideByColumnType(props, propertyPath, [
        ColumnTypes.TEXT,
        ColumnTypes.DATE,
        ColumnTypes.SELECT
      ], true);
    },
    children: [
      {
        propertyName: "isSort",
        label: "开启排序",
        helpText: "sort",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.BOOLEAN},
      },
    ],
  },
  {
    sectionName: "其他",
    hidden: (props: any, propertyPath: string) => {
      return propertyPath.startsWith('tableAction')||hideByColumnType(props, propertyPath, [
        ColumnTypes.TEXT,
        ColumnTypes.DATE,
        ColumnTypes.SELECT,
        ColumnTypes.BUTTON,
        ColumnTypes.IMAGE,
        ColumnTypes.VIDEO,
        ColumnTypes.SWITCH
      ], true);
    },
    children: [
      {
        propertyName: "isEllipsis",
        label: "内容超出列宽时自动省略",
        helpText: "省略后通过tooltip显示全部内容",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.BOOLEAN},
        dependencies: ["tableColumns", "tableAction"],
        hidden: (props: any, propertyPath: string) => {
          return hideByColumnType(props, propertyPath, [
            ColumnTypes.TEXT,
            ColumnTypes.DATE,
            ColumnTypes.SELECT
          ]);
        }
      },
      {
        propertyName: "isFixed",
        label: "是否冻结",
        helpText: "isFixed",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.BOOLEAN},
      },
      {
        propertyName: "sticky",
        helpText:
          "Choose column that needs to be frozen left or right of the table",
        controlType: "ICON_TABS",
        defaultValue: 'none',
        label: "冻结列",
        fullWidth: true,
        isBindProperty: true,
        isTriggerProperty: false,
        options: [
          {
            label: "左边",
            value: 'left',
          },
          {
            label: "右边",
            value: 'right',
          },
        ],
        hidden: (props: any, propertyPath: string) => {
          return !rePropertyPathValue(props, propertyPath, 'isFixed');
        },
        dependencies: ["tableColumns", "tableAction"],
      }
    ],
  },
  {
    sectionName: "选项设置",
    hidden: (props: any, propertyPath: string) => {
      return hideByColumnType(props, propertyPath, [
        ColumnTypes.SELECT
      ], true);
    },
    children: [
      {
        helpText: "Select Dict.",
        propertyName: "selectOptions",
        label: "选项",
        controlType: "SELECT_DICT_CONTROL",
        placeholderText: '从数据字典中选择',
        isBindProperty: true,
        isTriggerProperty: false,
        dependencies: ["selectId", "defaultOptionLabel", "defaultOptionValue"]
      }
    ],
  },
  {
    sectionName: "校验",
    hidden: (props: any, propertyPath: string) => {
      let _columnType = rePropertyPathValue(props, propertyPath, 'columnType', true);
      let _isEdit = rePropertyPathValue(props, propertyPath, 'isEdit', true);
      return [
        ColumnTypes.TEXT,
        ColumnTypes.DATE,
        ColumnTypes.SELECT
      ].includes(_columnType)?!_isEdit:true;
    },
    children: [
      {
        propertyName: "isRequired",
        label: "是否必填",
        helpText: "",
        controlType: "SWITCH",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.BOOLEAN},
      },
      {
        helpText: "Changes the type of data captured in the input",
        propertyName: "inputType",
        label: "文本类型",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "单行文本",
            value: "TEXT",
          },
          {
            label: "多行文本",
            value: "MULTI_LINE_TEXT",
          },
          {
            label: "数字",
            value: "NUMBER",
          },
          {
            label: "密码",
            value: "PASSWORD",
          },
          {
            label: "邮箱",
            value: "EMAIL",
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        hidden: (props: any, propertyPath: string) => {
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType != ColumnTypes.TEXT;
        },
        dependencies: ["tableColumns", "tableAction"],
      },
      {
        propertyName: "maxChars",
        label: "最大字符数",
        helpText: "",
        placeholderText: "255",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.TEXT},
        hidden: (props: any, propertyPath: string) => {
          let inputType = rePropertyPathValue(props, propertyPath, 'inputType');
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType == ColumnTypes.TEXT?(!['TEXT', 'MULTI_LINE_TEXT'].includes(inputType)):true;
        },
        dependencies: ["tableColumns", "tableAction"],
      },
      {
        helpText: "Sets the minimum allowed value",
        propertyName: "minNum",
        label: "Min",
        controlType: "INPUT_TEXT",
        placeholderText: "1",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: minValueValidation,
            expected: {
              type: "number",
              example: `1`,
              autocompleteDataType: AutocompleteDataType.NUMBER,
            },
          },
        },
        hidden: (props: any, propertyPath: string) => {
          let inputType = rePropertyPathValue(props, propertyPath, 'inputType');
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType == ColumnTypes.TEXT?(inputType != 'NUMBER'):true;
        },
        dependencies: ["tableColumns", "tableAction"],
      },
      {
        helpText: "Sets the maximum allowed value",
        propertyName: "maxNum",
        label: "Max",
        controlType: "INPUT_TEXT",
        placeholderText: "100",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: maxValueValidation,
            expected: {
              type: "number",
              example: `100`,
              autocompleteDataType: AutocompleteDataType.NUMBER,
            },
          },
        },
        hidden: (props: any, propertyPath: string) => {
          let inputType = rePropertyPathValue(props, propertyPath, 'inputType');
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType == ColumnTypes.TEXT?(inputType != 'NUMBER'):true;
        },
        dependencies: ["tableColumns", "tableAction"],
      },
      {
        helpText:
          "Adds a validation to the input which displays an error on failure",
        propertyName: "regex",
        label: "正则表达式",
        controlType: "INPUT_TEXT",
        placeholderText: "^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.REGEX },
        hidden: (props: any, propertyPath: string) => {
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType != ColumnTypes.TEXT;
        },
        dependencies: ["tableColumns", "tableAction"],
      },
      {
        helpText: "Sets the input validity based on a JS expression",
        propertyName: "validation",
        label: "有效的",
        controlType: "INPUT_TEXT",
        placeholderText: "{{ Input1.text.length > 0 }}",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.BOOLEAN,
          params: {
            default: true,
          },
        },
        hidden: (props: any, propertyPath: string) => {
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType != ColumnTypes.TEXT;
        },
        dependencies: ["tableColumns", "tableAction"],
      },
      {
        helpText:
          "The error message to display if the regex or valid property check fails",
        propertyName: "errorMessage",
        label: "错误消息",
        controlType: "INPUT_TEXT",
        placeholderText: "",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (props: any, propertyPath: string) => {
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType != ColumnTypes.TEXT;
        },
        dependencies: ["tableColumns", "tableAction"],
      },
      {
        propertyName: "minDate",
        label: "最小日期",
        helpText: "Defines the min date for this widget",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.DATE_ISO_STRING},
        controlType: "DATE_PICKER2",
        hidden: (props: any, propertyPath: string) => {
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType != ColumnTypes.DATE;
        },
        dependencies: ["tableColumns", "tableAction"],
      },
      {
        propertyName: "maxDate",
        label: "最大日期",
        helpText: "Defines the max date for this widget",
        useValidationMessage: true,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {type: ValidationTypes.DATE_ISO_STRING},
        controlType: "DATE_PICKER2",
        hidden: (props: any, propertyPath: string) => {
          let _columnType = rePropertyPathValue(props, propertyPath, 'columnType');
          return _columnType != ColumnTypes.DATE;
        },
        dependencies: ["tableColumns", "tableAction"],
      }
    ],
  }
];
//样式
export const GeneralStyle = [
  {
    sectionName: "文字格式",
    hidden: (props: any, propertyPath: string) => {
      return hideByColumnType(props, propertyPath, [
        ColumnTypes.TEXT,
        ColumnTypes.DATE,
        ColumnTypes.SELECT
      ], true);
    },
    children: [
      {
        propertyName: "textSize",
        label: "字体大小",
        helpText: "Controls the size of text in the column",
        controlType: "DROP_DOWN",
        options: [
          {
            label: "S",
            value: "0.875rem",
            subText: "0.875rem",
          },
          {
            label: "M",
            value: "1rem",
            subText: "1rem",
          },
          {
            label: "L",
            value: "1.25rem",
            subText: "1.25rem",
          },
          {
            label: "XL",
            value: "1.875rem",
            subText: "1.875rem",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "fontStyle",
        label: "加粗&斜体",
        helpText: "Controls the style of the text in the column",
        controlType: "BUTTON_GROUP",
        options: [
          {
            icon: "text-bold",
            value: "bold",
          },
          {
            icon: "text-italic",
            value: "italic",
          }
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      }
    ],
  },
  {
    sectionName: "对齐",
    hidden: (props: any, propertyPath: string) => {
      return hideByColumnType(props, propertyPath, [
        ColumnTypes.TEXT,
        ColumnTypes.DATE,
        ColumnTypes.SELECT,
        ColumnTypes.SWITCH,
      ], true);
    },
    children: [
      {
        propertyName: "horizontalAlignment",
        label: "水平对齐方式",
        helpText: "Sets the horizontal alignment of the content in the column",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          {
            startIcon: "align-left",
            value: "left",
          },
          {
            startIcon: "align-center",
            value: "center",
          },
          {
            startIcon: "align-right",
            value: "right",
          },
        ],
        defaultValue: "left",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["left", "center", "right"],
          },
        },
      },
      {
        propertyName: "verticalAlignment",
        label: "垂直对齐方式",
        helpText: "Sets the vertical alignment of the content in the column",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          {
            startIcon: "vertical-align-top",
            value: "top",
          },
          {
            startIcon: "vertical-align-middle",
            value: "middle",
          },
          {
            startIcon: "vertical-align-bottom",
            value: "bottom",
          },
        ],
        defaultValue: "middle",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["top", "middle", "bottom"],
          },
        },
      },
    ],
  },
  {
    sectionName: "按钮样式",
    hidden: (props: any, propertyPath: string) => {
      return hideByColumnType(props, propertyPath, [
        ColumnTypes.BUTTON
      ], true);
    },
    children: [
      {
        propertyName: "btnType",
        label: "类型",
        helpText: "Sets the vertical alignment of the content in the column",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          {
            label: "主要按钮",
            value: "primary",
          },
          {
            label: "次要按钮",
            value: "default",
          },
          {
            label: "文字按钮",
            value: "text",
          },
        ],
        defaultValue: "default",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["primary", "default", "text"],
          },
        },
      },
      {
        propertyName: "backgroundBtn",
        label: "颜色",
        helpText: "Changes the background color of the cell",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "fontColor",
        label: "文字颜色",
        helpText: "Changes the background color of the cell",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText: "Enter value for border width",
        propertyName: "borderRadiusBtn",
        label: "边框圆角半径",
        placeholderText: "Enter value in px",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.NUMBER },
      },
      {
        propertyName: "boxShadowBtn",
        label: "阴影",
        helpText:
          "Enables you to cast a drop shadow from the frame of the widget",
        controlType: "BOX_SHADOW_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      }
    ],
  },
  {
    sectionName: "图片样式",
    hidden: (props: any, propertyPath: string) => {
      return hideByColumnType(props, propertyPath, [
        ColumnTypes.IMAGE
      ], true);
    },
    children: [
      {
        propertyName: "imgSize",
        label: "尺寸",
        helpText: "Sets the vertical alignment of the content in the column",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          {
            label: "默认",
            value: "L",
          },
          {
            label: "中",
            value: "ML",
          },
          {
            label: "大",
            value: "XL",
          },
        ],
        defaultValue: "L",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["L", "ML", "XL"],
          },
        },
      }
    ],
  },
  {
    sectionName: "颜色",
    children: [
      /*{
        propertyName: "backgroundCell",
        label: "单元格背景色",
        helpText: "Changes the background color of the cell",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },*/
      {
        propertyName: "textColor",
        label: "字体颜色",
        helpText: "Controls the color of text in the column",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        dependencies: ["tableColumns", "tableAction"],
        hidden: (props: any, propertyPath: string) => {
          return hideByColumnType(props, propertyPath, [
            ColumnTypes.TEXT
          ]);
        },
      },
      {
        propertyName: "switchColor",
        label: "开关强调色",
        helpText: "Controls the color of text in the column",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        dependencies: ["tableColumns", "tableAction"],
        hidden: (props: any, propertyPath: string) => {
          return hideByColumnType(props, propertyPath, [
            ColumnTypes.SWITCH
          ]);
        },
      }
    ],
  },
  {
    sectionName: "图标",
    hidden: (props: any, propertyPath: string) => {
      return hideByColumnType(props, propertyPath, [
        ColumnTypes.BUTTON
      ], true);
    },
    children: [
      {
        propertyName: "btnIcon",
        label: "选择图标",
        helpText: "Sets the icon to be used for the button",
        controlType: "ICON_SELECT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
        },
      },
      {
        propertyName: "btnPosition",
        label: "位置",
        helpText: "Sets the vertical alignment of the content in the column",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          {
            label: "左边",
            value: "left",
          },
          {
            label: "右边",
            value: "right",
          }
        ],
        defaultValue: "left",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["left", "right"],
          },
        },
      },
      {
        propertyName: "btnTextAlign",
        label: "对齐方式",
        helpText: "Sets the vertical alignment of the content in the column",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          {
            label: "居左对齐",
            value: "flex-start",
          },
          {
            label: "两边对齐",
            value: "space-between",
          },
          {
            label: "居中对齐",
            value: "center",
          }
        ],
        defaultValue: "center",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["flex-start", "space-between", "center"],
          },
        },
      }
    ],
  }
];

//事件
export const GeneralEvent = [
  {
    "sectionName": "Events",
    "children": [
      {
        "helpText": "when the button is onRowClick",
        "propertyName": "onClick",
        "label": "onClick",
        "controlType": "ACTION_SELECTOR",
        "isJSConvertible": true,
        "isBindProperty": true,
        "isTriggerProperty": true
      }
    ]
  }
];
