import React from 'react';
import {AtNavBar} from 'taro-ui';
import styled from "styled-components";
const AtNavBarWrapper = styled.div<{
  bgColor: string;
  color: string;
}>`
  height: 100%;
  .at-nav-bar__left-view .at-icon,
  .at-nav-bar__container .at-icon{
    font-size: 24px;
  }
  .at-nav-bar__title{
    color: ${({color})=> color };
  }
  .at-nav-bar{
    background-color: ${({bgColor})=> bgColor }
  }
`
const TNavBarWidget = (props:any) => {
  const {label, color, bgColor, onClickRgIconSt} = props;
  return (
    <AtNavBarWrapper bgColor={bgColor} color={color}>
      <AtNavBar onClickRgIconSt={onClickRgIconSt} leftIconType='chevron-left' color={color} title={label} rightFirstIconType='bullet-list' />
    </AtNavBarWrapper>
  );
};

export default TNavBarWidget;
