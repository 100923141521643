import React from "react";
import {InputNumber} from 'antd';
import styled from "styled-components";
import LabelWithTooltip, {LABEL_CONTAINER_CLASS, labelLayoutStyles} from "widgets/components/LabelWithTooltip";
import {LabelPosition} from "components/constants";
import {FontStyleTypes} from "../../../constants/WidgetConstants";
import {lightenColor} from "../../../widgets/WidgetUtils";

export const DivContainer = styled.div<any>`
  ${labelLayoutStyles}
  & .${LABEL_CONTAINER_CLASS} {
    align-self: center;
    ${({ labelPosition }) =>
    labelPosition === LabelPosition.Left && "min-height: 30px"};
  }

  .ant-input-number-input-wrap {
    align-content: center;
    height: 100%;

    .ant-input-number-input {
       width: 100%;
       height: inherit !important;
     }
  }

  .ant-input-number {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 30px;
    border-radius: ${({ borderRadius }) => borderRadius ||' 0.375rem'};
    box-shadow: ${({ boxShadow }) => boxShadow ||'none'};
  }

  .ant-input-number:focus-within {
    border-color: ${({accentColor}) => accentColor} !important;
    box-shadow: ${({accentColor}) => `0px 0px 0px 2px ${ lightenColor(accentColor)} !important;`};
  }
`;

function CarouselComponent(props: any) {
  const {
    disabled,
    //LABEL START
    compactMode,
    isDisabled,
    isLoading,
    isRequired,//
    labelAlignment,//11
    labelPosition,//11
    labelStyle,//11
    labelText,//11
    labelTextColor,//11
    labelTextSize,//11
    labelTooltip,//11
    labelWidth,//11
    onValueChange,
    Min,
    Max,
    value,
    readOnly,
    stringMode,
    borderRadius,
    boxShadow,
    it_color,
    it_size,
    it_style,
    ib_bgColor,
    ib_borderColor,
    accentColor,
  } = props;

  const onChange = (value: any) => {
    onValueChange(value);
  };

  return (
    <>
      <DivContainer compactMode={compactMode}
        data-testid="radiogroup-container"
        labelPosition={labelPosition}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        it_color={it_color}
        it_size={it_size}
        it_style={it_style}
        ib_bgColor={ib_bgColor}
        ib_borderColor={ib_borderColor}
        accentColor={accentColor}
      >
        {labelText && (
          <LabelWithTooltip
            className={`datepicker-label`}
            text={labelText}
            cyHelpTextClassName="datepicker-tooltip"
            alignment={labelAlignment}
            color={labelTextColor}
            compact={compactMode}
            disabled={isDisabled}
            fontSize={labelTextSize}
            fontStyle={labelStyle}
            helpText={labelTooltip}
            loading={isLoading}
            position={labelPosition}
            width={labelWidth}
            isRequired={isRequired}
          />

        )}
        <InputNumber
          min={Min} max={Max} value={value}
          disabled={disabled} onChange={onChange}  bordered={!readOnly}  readOnly={readOnly} stringMode={stringMode}
          style={{
            color: it_color ||"#000000",
            fontSize: it_size,
            backgroundColor: ib_bgColor ||"#FFFFFF",
            fontWeight: it_style?.includes(FontStyleTypes.BOLD) ? "bold" : "",
            fontStyle: it_style?.includes(FontStyleTypes.ITALIC) ? "italic" : "",
            borderColor: ib_borderColor ||"#D0D0D0",
          }}
        />

      </DivContainer>
    </>
  )
}

export default CarouselComponent;
