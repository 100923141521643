import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Marquee",
  iconSVG: IconSVG,
  defaults: {
    rows: 3,
    columns: 40,
    widgetName: "Marquee",
    directionX: "left",
    speed: 8,
    isPause: false,
    version: 1,
    animateLoading: true,
    currentData: [
      {
        title: "文字1",
        url: ""
      },
      {
        title: "文字2",
        url: ""
      },
      {
        title: "文字3",
        url: ""
      }
    ],
    mappingConfig: {
      title: {
        id: 'title',
        index: 0,
        label: '标题',
        type: "text"
      },
      url: {
        id: 'url',
        index: 1,
        label: '链接',
        type: "text"
      }
    },
    borderWidth:1,
    borderColor:"#d4d4d8",
    borderRadius:5
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "320px",
            minHeight: "80px",
          };
        },
      },
    ],
  },
};

export default Widget;
