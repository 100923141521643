import { uuid2 } from "@byk/utils/Utils";
import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XEchartsPie",
  iconSVG: IconSVG,
  defaults: {
    rows: 60,
    columns: 70,
    widgetName: "XEchartsPie",
    version: 1,
    label: "label",
    isDownload: false,
    isDataView: false,
    isLegend: true,
    radius:50,
    roundSize:0,
    itemBorderWidth:0,
    itemBorderColor:"#ffffff",
    sType:"pie",
    borderWidth:1,
    tabsObj:{
      // "Search Engine":{
      //   "id": uuid2(),
      //   "index": 0,
      //   "label": "Search Engine",
      //   "key": uuid2(),
      //   "widgetId": uuid2(),
      //   "isVisible": true,
      //   "color": ""
      // },
      // "Direct":{
      //   "id": uuid2(),
      //   "index": 0,
      //   "label": "Direct",
      //   "key": uuid2(),
      //   "widgetId": uuid2(),
      //   "isVisible": true,
      //   "color": ""
      // },
      // "Email":{
      //   "id": uuid2(),
      //   "index": 0,
      //   "label": "Email",
      //   "key": uuid2(),
      //   "widgetId": uuid2(),
      //   "isVisible": true,
      //   "color": ""
      // },
      // "Union Ads":{
      //   "id": uuid2(),
      //   "index": 0,
      //   "label": "Union Ads",
      //   "key": uuid2(),
      //   "widgetId": uuid2(),
      //   "isVisible": true,
      //   "color": ""
      // },
      // "Video Ads":{
      //   "id": uuid2(),
      //   "index": 0,
      //   "label": "Video Ads",
      //   "key": uuid2(),
      //   "widgetId": uuid2(),
      //   "isVisible": true,
      //   "color": ""
      // },
    },
    currentData: [
      { name: 'Search Engine', value: 1048 },
      { name: 'Direct', value: 735 },
      { name: 'Email', value: 580, },
      { name: 'Union Ads', value: 484 },
      { name: 'Video Ads', value: 300 }
    ]
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
