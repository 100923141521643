import {Button, Checkbox, Form, Input, message, Modal, Select, Spin, Table} from 'antd';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';

import styled from 'styled-components';
import {ArrowDownOutlined, ArrowUpOutlined, MinusCircleOutlined} from "@ant-design/icons";
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import _ from "lodash";
import {DefaultOptionType} from 'antd/lib/select';
import {isIndexType} from "@byk/pages/modeling/ModelDataUtil";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .selectClass {
    border-radius: 5px;
    width: 190px;
    border: 1px solid rgba(228, 228, 229, 1);

    .ant-select-selector, .ant-select-selection-item {
      line-height: 40px;
      height: 40px;
    }
  }

  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
`

const ButtonWrapper = styled(Button)<{disabled?:boolean}>`
  height: 40px;
  border-radius: 5px;
  margin:10px 8px 0 0;

  &.delBtn {
    color: #5A5A5A !important;
  }

  &.delBtn .i-icon-delete{
    height: 14px;
  }

  &.delBtn:hover,
  &.delBtn:hover .i-icon-delete path{
    stroke: rgb(0, 108, 242) !important;
    color: rgb(0, 108, 242) !important;
  }
`

interface Item {
  key: string;
  name: any;
  description: any;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: Item;
  index: number;
  children: React.ReactNode;
  model: any,
  save: any,
  data: any,
}

const EditableCell: React.FC<EditableCellProps> = (
  {
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    model,
    save,
    data,
    ...restProps
  }) => {
  let nameWithKey = dataIndex + '_' + record?.key;

  const getOptions = (): DefaultOptionType[] => {
    let options: DefaultOptionType[] = [];
    let keyMap = _.mapKeys(data || [], "name");
    (model?.properties || []).forEach((item: any) => {
      if (isIndexType(item)) {
        item.label = item.name;
        item.value = item.name;
        let tmp = keyMap[item.name];
        if (!tmp) {
          options.push(item);
        }
        if (tmp && tmp.key == record.key) {
          options.push(item);
        }
      }
    })
    return options;
  }

  const options = getOptions();
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={nameWithKey}
          style={{margin: 0}}
          rules={[{required: true, message: ``,}]}
        >
          <Select
            className='selectClass'
            fieldNames={{label: 'name', value: 'name'}}
            options={options}
            defaultValue={record?.name}
            onChange={(value, option) => {
              save && save(option, record?.key);
            }}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CreateModelIndex: React.FC<any> = forwardRef((
      {
        updateDataSource,
        doDelete,
        model,
        callback,
      }, ref) => {

      useImperativeHandle(ref, () => ({
        init
      }));

      const [isModalOpen, setIsModalOpen] = useState(false);
      const [id, setId] = useState(false);
      const [isUni, setUni] = useState(true);
      const [spinning, setSpinning] = useState(false);
      const keyCounter = useRef<number>(0);
      const [form] = Form.useForm();
      const [formName] = Form.useForm();
      const [data, setData] = useState<any>([]);
      const [indexName, setIndexName] = useState<any>();

      const init = (indexPro: any = {}) => {
        setId(indexPro.id);
        setUni(indexPro.unique == undefined ? true : indexPro.unique);
        setIndexName(indexPro.name);

        let _data: any = [];
        if (indexPro.id) {
          let formValues: any = {};
          let attrs: any = indexPro.properties.split(",");
          attrs.forEach((attr: any) => {
            let pro = (model.properties || []).find((item: any) => item.name == attr);
            let _dataItem = getDataItem(pro);
            _data.push(_dataItem);
            let formName = 'name_' + _dataItem.key;
            formValues[formName] = pro.name;
          })
          formValues.indexName = indexPro?.name || '';
          form.setFieldsValue(formValues);
          formName.setFieldsValue({indexName: formValues.indexName});
        } else {
          form.resetFields();
          formName.resetFields();
        }

        setData(_data);
        setIsModalOpen(true);
      }

      const handleOk = async () => {
        let {indexName} = await formName.validateFields();
        let exist = _.find(model.indexs || [], {name:indexName});
        if (exist) {
          message.warning("索引名称已存在！")
          return;
        }

        setSpinning(true);
        await form.validateFields().then(async () => {
          let properties = "";
          data.forEach((item: any) => properties += item.name + ',');
          let endIdx = properties.lastIndexOf(",");
          if (endIdx > 0) {
            properties = properties.substring(0, endIdx)
          }
          let isOk = await updateDataSource({
            id: id,
            properties,
            name: indexName,
            unique: isUni,
          });
          isOk && setIsModalOpen(false);
          setSpinning(false);
        }).catch(() => setSpinning(false));
      };

      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const save = async (property: any, columnKey: any) => {
        const newData = [...data];
        const index = newData.findIndex(i => columnKey === i.key);
        property.key = columnKey;
        if (index > -1) {
          newData.splice(index, 1, {
            ...property,
          });
          setData(newData);
        } else {
          newData.push(property);
          setData(newData);
        }
      };

      const columns = [
        {
          title: '属性编码',
          dataIndex: 'name',
          width: '80px',
          editable: true,
        },
        {
          title: '属性名称',
          dataIndex: 'description',
          editable: false,
          width: '100px',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '55px',
          render: (_: any, record: Item) => {
            let canUp = false;
            let canDown = false;
            if (data.length > 1) {
              canUp = data[0].key !== record.key;
              canDown = data[data.length - 1].key !== record.key;
            }
            return <>
              <Button style={{marginRight: '5px'}} disabled={!canUp} icon={<ArrowUpOutlined/>}
                      onClick={() => move(record.key)}/>
              <Button style={{marginRight: '5px'}} disabled={!canDown} icon={<ArrowDownOutlined/>}
                      onClick={() => move(record.key, false)}/>
              <Button icon={<MinusCircleOutlined/>} onClick={() => remove(record.key)}/>
            </>
          },
        },
      ];

      const mergedColumns = columns.map(col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: Item) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: col.editable,
            model,
            data,
            save,
          }),
        };
      });

      const move = (key: any, isUp: boolean = true) => {
        let newData = [...data];
        const index = newData.findIndex((i: any) => key === i.key);
        if ((isUp && index == 0) || (!isUp && index == newData.length - 1)) {
          return;
        }

        if (newData.length > 1) {
          let switchIndex = isUp ? index - 1 : index + 1;
          let tmp = newData[switchIndex];
          newData[switchIndex] = newData[index];
          newData[index] = tmp;
          setData(newData);
        }
      }

      const remove = (key: any) => {
        const newData = data.filter((item: any) => item.key !== key);
        setData(newData);
      }

      const handleAdd = () => {
        form.validateFields().then(() => {
          setData([...data, getDataItem()]);
        }).catch();
      }

      const getDataItem = (property: any = {}) => {
        keyCounter.current = keyCounter.current + 1;
        return {
          key: keyCounter.current,
          name: property.name || null,
          description: property.description || null,
        };
      }

      const onClickDel = async () => {
        setSpinning(true);
        doDelete && await doDelete(id);
        setSpinning(false);
        setIsModalOpen(false);
      }

      return (
        <ModalWrapper
          title="模型索引" onOk={handleOk} onCancel={handleCancel} open={isModalOpen}
          footer={null}
          maskClosable={false}
          width={640}
          bodyStyle={{height: "470px", padding: 0, margin: 0}}
        >
          <Spin tip='加载中...' spinning={spinning}>
            <Form form={formName} component={false}>
              <div style={{margin:'18px 18px 0 18px', display:'flex', }}>
                <Form.Item
                  label='索引名称'
                  name='indexName'
                  rules={[
                    {required: true, message: "请输入索引名称!"},
                    {max:120, message:"索引名称不能大于120个字符！"}
                  ]}
                  style={{width: '520px'}}
                >
                  <Input value={indexName} onChange={(e) => {
                    let value = e.target.value.trim();
                    setIndexName(value);
                  }}/>
                </Form.Item>
                <Button type={'link'} onClick={() => {
                  let properties = "";
                  data.forEach((item: any) => properties += item.name + ',');
                  let endIdx = properties.lastIndexOf(",");
                  if (endIdx > 0) {
                    properties = properties.substring(0, endIdx)
                  }
                  let defaultIndexName = properties.replaceAll(",", "_");
                  setIndexName(defaultIndexName);
                  formName.setFieldsValue({indexName: defaultIndexName});
                }}>自动生成</Button>
              </div>
            </Form>
            <Form form={form} component={false}>
              <Button onClick={handleAdd} type="primary" style={{margin: "8px 16px"}}>
                添加索引列
              </Button>
              <Checkbox checked={isUni} onChange={(e) => setUni(e.target.checked)}>
                唯一索引
              </Checkbox>

              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
                scroll={{y: 240}}
                style={{height: 290}}
                size='small'
              />
            </Form>

            <div style={{display: "flex", justifyContent: 'space-between', borderTop: '1px solid #efe6e6'}}>
              <div style={{marginLeft: "28px"}}>
                {id &&
                <ButtonWrapper
                  className='delBtn' icon={getIconPark("Delete", "#5A5A5A")} type={"link"}
                  onClick={onClickDel}
                  disabled={!id}
                >
                  删除
                </ButtonWrapper>
                }
              </div>
              <div style={{marginRight: '16px'}}>
                <ButtonWrapper
                  onClick={handleCancel}
                >
                  取消
                </ButtonWrapper>
                <ButtonWrapper
                  type={"primary"}
                  onClick={handleOk}
                >
                  {id ? "更新" : "保存"}
                </ButtonWrapper>
              </div>
            </div>
          </Spin>
        </ModalWrapper>
      )
    }
  )
;

export default CreateModelIndex;
