import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import WidgetCard from "./WidgetCard";
import {getIsMobileBreakPoint, getWidgetCards} from "selectors/editorSelectors";
import {debounce} from "lodash";
import Fuse from "fuse.js";
import type {WidgetCardProps} from "widgets/BaseWidget";
import {Collapse, Tabs} from "antd";
import {theme} from "../../constants/DefaultTheme";

export const formWidgetType = [
  "INPUT_WIDGET_V2",//输入框
  "PHONE_INPUT_WIDGET",//电话输入框
  "SELECT_WIDGET",//下拉框
  "MULTI_SELECT_WIDGET_V2",//多选下拉框
  'CASCADER_WIDGET',//级联选择器
  "DATE_PICKER_WIDGET2",//日期时间选择器
  "RANGEDATEPICKER_WIDGET",//日期时间区间
  "RICH_TEXT_EDITOR_WIDGET",//富文本框
  "CHECKBOX_WIDGET",//复选框
  "CHECKBOX_GROUP_WIDGET",//复选框组
  "RADIO_GROUP_WIDGET",//单选框组
  "SWITCH_WIDGET",//开关
  "SWITCH_GROUP_WIDGET",//开关组
  "UPLOAD_WIDGET",//上传文件
  "FILE_PICKER_WIDGET_V2",//上传文件
  "UPLOAD_IMAGE_WIDGET",//上传图片
  "SLIDER_WIDGET",//滑块
  "INPUTNUMBER_WIDGET",//计数器
  "AMAP_WIDGET",//定位
  "SUB_TABLE_WIDGET"//子表
];

function WidgetSidebar({isActive}: { isActive: boolean }) {
  const cards = useSelector(getWidgetCards);
  const isMobile = useSelector(getIsMobileBreakPoint);
  const [filteredCards, setFilteredCards] = useState(cards);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<any>();
  const mobileWidgetType:Array<string> = [
    'AT_IMAGE_PICKER_WIDGET',
    'AT_NAVBAR_WIDGET',
    'AT_PROGRESS_WIDGET',
    'AT_BUTTON_WIDGET',
    'AT_GRID_WIDGET',
    'AT_INPUTNUMBER_WIDGET',
    'AT_INPUT_WIDGET',
    'AT_LIST_WIDGET',
    'AT_PICKER_WIDGET',
    'AT_SWIPER_WIDGET',
    'AT_SWITCH_WIDGET',
    'AT_TABBAR_WIDGET',
    'AT_TEXTAREA_WIDGET',
    'AT_TEXT_WIDGET',
    'AT_RADIO_WIDGET',
    'AT_CHECKBOX_WIDGET',
    'AT_ICON_WIDGET',
    'AT_DIVIDER_WIDGET',
    'CONTAINER_WIDGET',
    'LIST_WIDGET_V2',
    'X_IMAGE_WIDGET',
  ];
  const templateWidgetType = [
    "SEARCH_FORM_WIDGET",//查询组件
  ];
  const baseWidgetType = [
    "TEXT_WIDGET",//文字
    "BUTTON_WIDGET",//按钮
    //"BUTTON_GROUP_WIDGET",
    "ICON_BUTTON_WIDGET",//图标按钮
    "DIVIDER_WIDGET",//分割线
    "TABLE_WIDGET_ANTD",//表格
    "LIST_WIDGET_V2",//列表
    "X_IMAGE_WIDGET",//图片
    "CONTAINER_WIDGET",//容器
    "FORM_WIDGET",//表单容器
    "MODAL_WIDGET",//对话框
    "TABS_WIDGET",//标签页
    "SEARCH_FORM_WIDGET",//查询组件
    "SEARCH_WIDGET",//查询组件
    "PROGRESS_WIDGET",//进度条组件
    //"TABLE_WIDGET_V2",
    //"ICON_WIDGET"//图标
  ];

  const mediaWidgetType = [
    "X_IMAGE_WIDGET",//图片
    "CAROUSEL_WIDGET",//轮播
    "MARQUEE_WIDGET",//跑马灯
    "VIDEO_WIDGET",//视频
    "X_TIMING_WIDGET",//定时器
    "AMAP_VIEW_WIDGET",//地图
  ];

  const containerWidgetType = [
    "CONTAINER_WIDGET",//容器
    "FORM_WIDGET",//表单容器
    "IFRAME_WIDGET",//IFRME
    "MODAL_WIDGET",//对话框
    "DYNAMICFORMS_WIDGET",//动态表单
    "SEARCH_FORM_WIDGET",
    //"XCOLLAPSE_WIDGET",
    //'FIXED_CONTAINER_WIDGET',
    //"DRAWER_WIDGET",
    //"JSON_FORM_WIDGET",
  ];

  const navigationWidgetType = [
    "MENU_BUTTON_WIDGET",//菜单按钮
    "BREADCRUMB_WIDGET",//面包屑
    "TREE_WIDGET",//树形导航
    "TABS_WIDGET",//标签页
    "X_STEP_WIDGET",//步骤条
  ]

  const chartWidgetType = [
    "X_ECHARTS_LINE_WIDGET",//折线图
    "X_ECHARTS_PIE_WIDGET",//饼图
    "X_ECHARTS_GAUGE_WIDGET",//仪表
    "X_ECHARTS_RADAR_WIDGET",//雷达图
    "X_ECHARTS_FUNNEL_WIDGET",//漏斗
    "X_ECHARTS_MAP_WIDGET",//地理坐标图
    "X_ECHARTS_WIDGET",//通用图标
  ];

  const HeightWidgetType = [
    "CALENDAR_WIDGET",//日历
  ];

  const fuse = useMemo(() => {
    const options = {
      keys: [
        {
          name: "displayName",
          weight: 0.9,
        },
        {
          name: "searchTags",
          weight: 0.1,
        },
      ],
      threshold: 0.2,
      distance: 100,
    };

    return new Fuse(cards, options);
  }, [cards]);
  const {Panel} = Collapse;
  const filterCards = (keyword: string) => {
    if (keyword.trim().length > 0) {
      const searchResult = fuse.search(keyword);
      setFilteredCards(searchResult as WidgetCardProps[]);
    } else {
      setFilteredCards(cards);
    }
  };
  const WidgetCollapse = useMemo(()=> {
    if(isMobile){
      return (<Collapse defaultActiveKey={["a"]} ghost style={{width: "240px", paddingBottom: "20px"}}>

        <Panel header="移动端组件" key="a">
          <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
            {mobileWidgetType.map(type => {
              const card = filteredCards.find(item => item.type == type);
              if (card) {
                return <WidgetCard details={card} key={card.key}/>
              }
            })}
          </div>
        </Panel>
      </Collapse>)
    }
    return (
      <Tabs className="z-widget-box-tab" type="card" style={{marginTop:"5px"}}>
        <Tabs.TabPane  tab="基础组件" key="item-1">
            <Collapse defaultActiveKey={["1", "2", "3", "4", "5"]} ghost style={{width: "240px", paddingBottom: "20px"}}>
              <Panel header="常用组件" key="1">
                <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {baseWidgetType.map(type => {
                    const card = filteredCards.find(item => item.type == type);
                    if (card) {
                      return <WidgetCard details={card} key={card.key}/>
                    }
                  })}
                </div>
              </Panel>


              <Panel header="表单组件" key="3">
                <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {formWidgetType.map(type => {
                    const card = filteredCards.find(item => item.type == type);
                    if (card) {
                      return <WidgetCard details={card} key={card.key}/>
                    }
                  })}
                </div>
              </Panel>

              <Panel header="容器组件" key="2">
                <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {containerWidgetType.map(type => {
                    const card = filteredCards.find(item => item.type == type);
                    if (card) {
                      return <WidgetCard details={card} key={card.key}/>
                    }
                  })}
                </div>
              </Panel>

              <Panel header="导航组件" key="2">
                <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {navigationWidgetType.map(type => {
                    const card = filteredCards.find(item => item.type == type);
                    if (card) {
                      return <WidgetCard details={card} key={card.key}/>
                    }
                  })}
                </div>
              </Panel>

              <Panel header="多媒体组件" key="5">
                <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {mediaWidgetType.map(type => {
                    const card = filteredCards.find(item => item.type == type);
                    if (card) {
                      return <WidgetCard details={card} key={card.key}/>
                    }
                  })}
                </div>
              </Panel>

              <Panel header="图表组件" key="4">
                <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {chartWidgetType.map(type => {
                    const card = filteredCards.find(item => item.type == type);
                    if (card) {
                      return <WidgetCard details={card} key={card.key}/>
                    }
                  })}
                  {/* {filteredCards.filter((itm: any) => {
                    return chartWidgetType.indexOf(itm.type) > -1;
                  }).map((card) => (
                    <>
                      <WidgetCard details={card} key={card.key}/>
                    </>
                  ))} */}
                </div>
              </Panel>
              <Panel header="高级组件" key="5">
                <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {filteredCards.filter((itm: any) => {
                    return HeightWidgetType.indexOf(itm.type) > -1;
                  }).map((card) => (
                    <>
                      <WidgetCard details={card} key={card.key}/>
                    </>
                  ))}
                </div>
              </Panel>

            </Collapse>
        </Tabs.TabPane>
        <Tabs.TabPane   tab="模板组件" key="item-2">
          <div className="ant-collapse-content-box">
          <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {templateWidgetType.map(type => {
                    const card = filteredCards.find(item => item.type == type);
                    if (card) {
                      return <WidgetCard details={card} key={card.key}/>
                    }
                  })}
                </div>
          </div>
        {/* <Collapse defaultActiveKey={["1", "2", "3", "4", "5"]} ghost style={{width: "240px", paddingBottom: "20px"}}>
              <Panel header="" key="1">
                <div className="grid items-stretch grid-cols-3 gap-3 justify-items-stretch">
                  {templateWidgetType.map(type => {
                    const card = filteredCards.find(item => item.type == type);
                    if (card) {
                      return <WidgetCard details={card} key={card.key}/>
                    }
                  })}
                </div>
              </Panel>
            </Collapse> */}
        </Tabs.TabPane>
      </Tabs>

    )
  }, [isMobile]);

  useEffect(() => {
    if (isActive) searchInputRef.current?.focus();
  }, [isActive]);

  const search = debounce((value: string) => {
    filterCards(value.toLowerCase());
  }, 300);

  useEffect(() => {
    if (containerRef.current) {
      let headerHeight = parseInt(theme.smallHeaderHeight.replaceAll("px", ''));
      let bottomBarHeight = parseInt(theme.bottomBarHeight.replaceAll("px", ''));
      containerRef.current.style.height = `calc(100vh - ${containerRef.current.offsetTop + headerHeight + bottomBarHeight}px)`;
    }
  }, [])

  return (
    <div
      className={`flex flex-col  overflow-hidden ${isActive ? "" : "hidden"}`}
    >
      {/* <div className="sticky top-0 px-3 mt-0.5">
        <SearchInput
          autoComplete="off"
          autoFocus
          id={ENTITY_EXPLORER_SEARCH_ID}
          onChange={search}
          placeholder="搜索组件"
          ref={searchInputRef}
          type="text"
        />
      </div> */}
      <div id="z-widget-sidebar"
           className="flex-grow  overflow-y-scroll"
           data-testid="widget-sidebar-scrollable-wrapper"
           ref={containerRef}
      >

        {WidgetCollapse}
      </div>
    </div>
  );
}

WidgetSidebar.displayName = "WidgetSidebar";

export default WidgetSidebar;
