import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TPickerWidget from "../component";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";
import moment from "moment";

class TPicker extends TaroWidget<TPickerWidgetProps, WidgetState> {
  constructor(props: TPickerWidgetProps) {
    super(props);
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "mode",
            propertyName: "mode",
            label: "模式",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "普通",
                value: "selector",
              },
              {
                label: "多列",
                value: "multiSelector",
              },
              {
                label: "时间",
                value: "time",
              },
              {
                label: "日期",
                value: "date",
              },
              {
                label: "月",
                value: "month",
              }
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          }
        ],
      },
      {
        sectionName: "设置",
        children: [
          {
            propertyName: "isDisabled",
            label: "是否禁用",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isVisible",
            label: "是否显示文字",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.value }}`
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }
  updateWidgetProperty = (propertyValues: string) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty("value", propertyValues);
  }

  getPageView() {
    let initValue = this.props.value?this.props.value : moment().format('YYYY-MM-DD')
    return (
      <TPickerWidget {...this.props} value={initValue} updateWidgetProperty={this.updateWidgetProperty} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_PICKER_WIDGET";
  }
}

export interface TPickerWidgetProps extends WidgetProps {
  value: any[];
  label: string;
  onClick?: any;
  borderRadius: string;
  boxShadow?: string;
}

export default TPicker;
