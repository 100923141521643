import type {ReactNode} from "react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import type {MaybeElement} from "@blueprintjs/core";
import type {IconName} from "@blueprintjs/icons";
import type {ComponentProps} from "widgets/BaseComponent";
import {Icon} from "@design-system/widgets-old";
import {generateClassName, getCanvasClassName} from "utils/generators";
import {Colors} from "constants/Colors";
import PageTabs from "./PageTabs";
import {scrollCSS} from "widgets/WidgetUtils";
import {TabPosition} from "../../../components/constants";

interface TabsComponentProps extends ComponentProps {
  children?: ReactNode;
  shouldScrollContents?: boolean;
  selectedTabWidgetId: string;
  shouldShowTabs: boolean;
  borderRadius: string;
  boxShadow?: string;
  borderWidth?: number;
  borderColor?: string;
  accentColor?: string;
  primaryColor: string;
  onTabChange: (tabId: string) => void;
  tabs: Array<{
    id: string;
    label: string;
    widgetId: string;
    isVisible?: boolean;
    iconName: string;
  }>;
  width: number;
  $noScroll: boolean;
  tabPosition: string;
  labelAlignment: string;
  tabW: string;
  tabWFlex1: boolean;
  textSize: string;
  labelStyle: string;
  textColor: string;
  lineHeight: string;
  selectedTextSize: string;
  selectedLabelStyle: string;
  textSelectedColor: string;
}

const TabsContainerWrapper = styled.div<{
  borderRadius: string;
  boxShadow?: string;
  borderWidth?: number;
  borderColor?: string;
  backgroundColor?: string;
  float: string;
}>`
  position: relative;
  flex-direction: column;
  display: ${(props) => props.float ? 'block' : 'flex'} ;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${({borderRadius}) => borderRadius};
  box-shadow: ${({boxShadow}) => `${boxShadow}`} !important;
  border-width: ${(props) => props.borderWidth}px;
  border-color: ${(props) => props.borderColor || "transparent"};
  background-color: ${(props) =>
  props.backgroundColor || "var(--wds-color-bg)"};
  border-style: solid;
  overflow: hidden;
`;

export interface TabsContainerProps {
  isScrollable: boolean;
}

const Container = styled.div<{
  tabH100?: boolean;
  float?: string;
  tabPosition: string;
}>`
  align-items: flex-end;
  float: ${(props) => props.float || "none"};
  & {
    svg path,
    svg:hover path {
      fill: ${Colors.BLACK};
      stroke: ${(props) => props.theme.colors.header.tabText};
    }
  }
  border-top: ${(props) => props.tabPosition == TabPosition.Bottom ? '1px solid var(--wds-color-border-onaccent)' : ''};
  border-right: ${(props) => props.tabPosition == TabPosition.Left ? '1px solid var(--wds-color-border-onaccent)' : ''};
  border-bottom: ${(props) => props.tabPosition == TabPosition.Top ? '1px solid var(--wds-color-border-onaccent)' : ''};
  border-left: ${(props) => props.tabPosition == TabPosition.Right ? '1px solid var(--wds-color-border-onaccent)' : ''};

  .pageTabs-right{
    height: calc(100% - 60px);
    margin-top: 30px;
  }

  .pageTabs-left{
    height: calc(100% - 60px);
    margin-top: 30px;
  }

`;

const ScrollBtnContainer = styled.div<{ visible: boolean, isTopOrBottom: boolean }>`
  cursor: pointer;
  display: flex;
  position: absolute;
  padding: 0 10px;

  & > span {
    background: white;
    position: relative;
    z-index: 1;
  }

   ${({isTopOrBottom}) =>
  isTopOrBottom
    ? `
     height: 100%;
    `
    : `
      height: 30px;
    `}

  ${(props) =>
  props.visible
    ? `
      visibility: visible;
      opacity: 1;
      z-index: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    `
    : `
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    `}
`;

export interface ScrollNavControlProps {
  onClick: () => void;
  icon: IconName | MaybeElement;
  disabled?: boolean;
  className?: string;
}

const ScrollCanvas = styled.div<{ $shouldScrollContents: boolean }>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)};
`;

function TabsComponent(props: TabsComponentProps) {
  const {
    onTabChange,
    tabs,
    tabPosition = TabPosition.Top,
    labelAlignment,
    tabW,
    tabWFlex1,
    textSize,
    labelStyle,
    textColor,
    lineHeight,
    selectedTextSize,
    selectedLabelStyle,
    textSelectedColor,
  } = props;

  const tabsRef = useRef<HTMLElement | null>(null);
  const [tabsScrollable, setTabsScrollable] = useState(false);
  const [shouldShowLeftArrow, setShouldShowLeftArrow] = useState(false);
  const [shouldShowRightArrow, setShouldShowRightArrow] = useState(true);
  const cacheScrollLeft = useRef(0);
  let tabH100 = tabPosition == TabPosition.Left || tabPosition == TabPosition.Right
  let float = TabPosition.Left == tabPosition ? "left" : TabPosition.Right == tabPosition ? "right" : "none";

  const setShowScrollArrows = useCallback(() => {
    if (tabsRef.current) {
      const {offsetWidth, scrollWidth} = tabsRef.current;
      const scrollLeft = cacheScrollLeft.current;
      setShouldShowLeftArrow(scrollLeft > 0);
      setShouldShowRightArrow(scrollLeft + offsetWidth < scrollWidth);
    }
  }, [tabsRef.current, tabs, tabW]);

  const measuredTabsRef = useCallback(
    (node: HTMLElement | null) => {
      tabsRef.current = node;
      if (node !== null) {
        const {offsetWidth, scrollWidth} = node;
        setTabsScrollable(scrollWidth > offsetWidth);
        setShowScrollArrows();
      }
    },
    [tabs, tabW],
  );

  const scroll = useCallback(
    (isScrollingLeft: any) => {
      const currentOffset = tabsRef.current?.scrollLeft || 0;
      const _n1 = isScrollingLeft
        ? currentOffset - parseInt(tabW)
        : currentOffset + parseInt(tabW);

      if (tabsRef.current) {
        cacheScrollLeft.current = _n1;
        tabsRef.current.scrollLeft = _n1;
        setShowScrollArrows();
      }
    },
    [tabsRef.current],
  );
  useEffect(() => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft = cacheScrollLeft.current;
    }
  }, [shouldShowLeftArrow, shouldShowRightArrow])


  const PageTableTopOrBottom = () => {
    let containerClassName = tabH100 ? "relative h-full" : "relative flex px-6";
    let isTopOrBottom = true;
    if (['left', 'right'].includes(tabPosition)) {
      isTopOrBottom = false;
    }

    return <Container
      tabPosition={tabPosition} tabH100={tabH100} float={float}
      className={containerClassName}>
      <ScrollBtnContainer
        isTopOrBottom={isTopOrBottom}
        className={`left-0 cursor-pointer scroll-nav-left-button tab-arrow-${tabPosition}`}
        onClick={() => scroll(true)}
        visible={shouldShowLeftArrow}
      >
        <Icon name="left-arrow-2"/>
      </ScrollBtnContainer>

      <PageTabs
        accentColor={props.accentColor}
        backgroundColor={props.backgroundColor}
        measuredTabsRef={measuredTabsRef}
        selectedTabWidgetId={props.selectedTabWidgetId}
        setShowScrollArrows={setShowScrollArrows}
        tabChange={onTabChange}
        tabs={tabs}
        tabsScrollable={tabsScrollable}
        float={float}
        tabPosition={tabPosition}
        labelAlignment={labelAlignment}
        tabW={tabW}
        tabWFlex1={tabWFlex1}
        textSize={textSize}
        labelStyle={labelStyle}
        textColor={textColor}
        lineHeight={lineHeight}
        selectedTextSize={selectedTextSize}
        selectedLabelStyle={selectedLabelStyle}
        textSelectedColor={textSelectedColor}
      />

      <ScrollBtnContainer
        isTopOrBottom={isTopOrBottom}
        className={`right-0 cursor-pointer scroll-nav-right-button tab-arrow-${tabPosition}`}
        onClick={() => scroll(false)}
        visible={shouldShowRightArrow}
      >
        <Icon name="right-arrow-2"/>
      </ScrollBtnContainer>
    </Container>
  }

  return (
    <TabsContainerWrapper
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
      borderRadius={props.borderRadius}
      borderWidth={props.borderWidth}
      boxShadow={props.boxShadow}
      float={float}
    >
      {props.shouldShowTabs && (tabPosition != TabPosition.Bottom) && (
        <PageTableTopOrBottom/>
      )}

      <ScrollCanvas
        $shouldScrollContents={!!props.shouldScrollContents && !props.$noScroll}
        className={`${
          props.shouldScrollContents ? getCanvasClassName() : ""
        } ${generateClassName(props.widgetId)}`}
      >
        {props.children}
      </ScrollCanvas>

      {props.shouldShowTabs && tabPosition == TabPosition.Bottom && (
        <PageTableTopOrBottom/>
      )}
    </TabsContainerWrapper>
  );
}

export default TabsComponent;
