import React, {useContext, useEffect, useRef, useState} from "react";
import BaseControl, {ControlProps} from "./BaseControl";
import {Button, Form, FormInstance, Input, InputRef, Modal, Popconfirm, Table} from "antd";
import {CloseOutlined, EditOutlined, RollbackOutlined} from "@ant-design/icons/lib";
import {OpenLinkpageWrapper} from "../../pages/Editor/MenuFrame/LinkPageSet";
import {createPortal} from "react-dom";
import _ from "lodash";
import styled from "styled-components";
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const AWrapper = styled.a`
  color: #006CF2;
  &:hover{
    opacity: .7;
  }
`

interface EditableRowProps {
  index: number;
}
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: any;
  record: any;
  handleSave: (record: any) => void;
}
const EditableCell: React.FC<EditableCellProps> = ({
                                                     title,
                                                     editable,
                                                     children,
                                                     dataIndex,
                                                     record,
                                                     handleSave,
                                                     ...restProps
                                                   }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
type EditableTableProps = Parameters<typeof Table>[0];


type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const TableData = (props: any) => {
  const {propertyValue, widgetProperties, onBatchUpdateProperties} = props;
  const {tableColumns} = widgetProperties;
  const [visible, setVisible] = useState(false);
  const [scrollConfig, setScrollConfig] = useState({x:1200, y: 400});
  const [dataSource, setDataSource] = useState(propertyValue);
  const setDataSourceBefore = (newData:any)=>{
    setDataSource(newData);
    onBatchUpdateProperties({
      "tableData": newData
    });
  }
  //console.log('propertyValue_tableDataControl', propertyValue);
  //console.log('tableColumns', tableColumns);

  const nullToStr:string = "";
  const linkPannelOffset = useRef({
    width: 600,
    top: '200px',
    right: '300px',
    bottom: 'auto',
    left: 'auto'
  })

  const handleDelete = (key: any) => {
    const newData = dataSource.filter((item:any) => item.id != key);
    setDataSourceBefore(newData);
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    ..._.sortBy(Object.values(tableColumns||{}), 'index').map((i:any, idx:number)=>{
      return {
        title: i.label,
        dataIndex: i.id,
        width: 140,
        index: idx,
        editable: true,
        render: (text:any)=>{
          return <span style={{display:'block', width:'100%', height: '28px'}}>{text||nullToStr}</span>
        }
      }
    }),
    {
      title: '操作',
      width: 80,
      dataIndex: 'operation',
      render: (text:any, record: any, index:any) =>{
        return (<AWrapper onClick={() => handleDelete(record.id)}>删除</AWrapper>)
      }
    },
  ];
  const handleAdd = () => {
    let keys = Object.keys(tableColumns||{});
    let rowId = 1;
    let keysValue:any = {}
    if(_.isArray(keys)){
      _.each(keys, (i:any)=>{
        keysValue[i] = nullToStr;
      })
    }
    while(_.filter(dataSource, (i:any)=>{
      return i.id == rowId;
    }).length!=0){
      rowId += 1;
    }
    keysValue.id = rowId;
    //console.log(`dataSource`, dataSource, keysValue);
    setDataSourceBefore([...dataSource, keysValue]);
  };
  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item:any) => row.id == item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    //console.log(`dataSource`, dataSource, newData);
    setDataSourceBefore(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  useEffect(()=>{
    if(_.isEmpty(tableColumns)||!dataSource){
      return ;
    }
    let _x = _.keys(tableColumns).length>6?1200:_.keys(tableColumns).length*140;
    let _y = dataSource.length>6?400:dataSource.length*60;
    setScrollConfig({
      x: _x,
      y: _y
    })
  }, [tableColumns, dataSource])
  const PopupEditTableData = ()=>{
    return (
      <OpenLinkpageWrapper linkPannelOffset={linkPannelOffset.current}>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '0 0 10px 0'}}>
          <div>编辑数据</div>
          <div style={{cursor: 'pointer'}} onClick={closePopup}><CloseOutlined /></div>
        </div>
        <Table
          scroll={scrollConfig}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={columns.length+1}>
                  <div style={{textAlign: 'center'}}>
                    <AWrapper onClick={handleAdd}>添加一行</AWrapper>
                  </div>

                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </OpenLinkpageWrapper>
    )
  }
  const closePopup = ()=>{
    setVisible(false);
  }
  const editDataPopupTop = 200;
  const editDataClick = (ev:any)=>{
    let pa:any = {
      top: `${ev.pageY}px`,
      width: _.keys(tableColumns).length*200
    };
    if(ev.pageY > editDataPopupTop){
      pa.top = 'auto';
      pa.bottom = '20px';
    }
    linkPannelOffset.current = {
      ...linkPannelOffset.current,
      ...pa
    }
    setVisible(true);
  }
  if(!_.isArray(propertyValue)){
    return <>
      <Button title={`绑定数据状态重置。需要重新绑定`} onClick={()=>{
        setDataSourceBefore([]);
      }} style={{width: '100%'}} icon={<RollbackOutlined />}>返回编辑数据</Button>
    </>
  }

  return (
    <>
      {visible&&createPortal(<PopupEditTableData />, document.body)}
      <Button style={{width: '100%'}} icon={<EditOutlined />} onClick={editDataClick}>编辑数据</Button>
    </>

  )
}


class TableDataControl extends BaseControl<TableDataControlProps> {
  render() {
    return <TableData {...this.props} />;
  }

  static getControlType() {
    return "TABLE_DATA";
  }
}

export interface TableDataControlProps extends ControlProps {
  placeholderText?: string
}

export default TableDataControl;
