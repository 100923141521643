import type {InputRef} from 'antd';
import {Alert, Button, Form, Input, message, Select, Table} from 'antd';
import type {FormInstance} from 'antd/es/form';
import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import {ModelProps} from "@byk/pages/modeling/MxGraph/CreateModel";
import {PropertyMappingModel} from "@byk/pages/modeling/lib/index";
import {MODEL_FORM_RULES} from "@byk/pages/modeling/ModelDataUtil";
import _ from "lodash";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";

export interface DataType {
  key: React.Key;
  id: any;
  relatedBy: string;
  name: string;
  description: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof DataType;
  record: DataType;
  propertyMappingModel: PropertyMappingModel;
  updateMapping: (mappingProperty: any) => void;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableCell: React.FC<EditableCellProps> = (
  {
    title,
    editable,
    children,
    dataIndex,
    record,
    propertyMappingModel,
    updateMapping,
    ...restProps
  }) => {
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    form.setFieldsValue(record);
  }, []);

  const formValid = async () => {
    try {
      await form.validateFields();
    } catch (e) {
    }
  }

  const updateMappingProperty = async () => {
    try {
      let values = form.getFieldsValue();
      updateMapping({...values, key: record.key});
      await form.validateFields();
    } catch (e) {
    }
  }

  let childNode = children;
  if (editable) {
    if (dataIndex == 'relatedBy') {
      childNode = (
        <Form.Item
          style={{margin: 0}}
          name={dataIndex}
          rules={MODEL_FORM_RULES[dataIndex]}
        >
          <Select
            options={propertyMappingModel.getMappingOptions()}
            onSelect={async (value, option) => {
              await updateMappingProperty();
            }}
          />
        </Form.Item>
      );
    } else {
      childNode = (
        <Form.Item
          style={{margin: 0}}
          name={dataIndex}
          rules={MODEL_FORM_RULES[dataIndex]}
        >
          <Input
            ref={inputRef}
            onChange={async () => await updateMappingProperty()}
            onBlur={formValid} autoComplete={"off"}
          />
        </Form.Item>
      );
    }
  } else {
    childNode = (
      <div className="editable-cell-value-wrap" style={{paddingRight: 24, height: "24px", width: "100%"}}>
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface MappingProps extends ModelProps {
  property: any,
  propertyMappingModel: PropertyMappingModel,
}

const PropertyMapping: React.FC<MappingProps> = forwardRef((props, ref) => {
  const propertyMappingModel: PropertyMappingModel = props.propertyMappingModel;
  const [dataSource, setDataSource] = useState<DataType[]>(propertyMappingModel.getMappingProperties());
  const [count, setCount] = useState(2);
  const [selectedKeys, setSelectedKeys] = useState<any>([]);
  const [canSave, setCanSave] = useState(false);
  const [editable, setEditable] = useState(true);

  useEffect(() => {
    setCanSave(propertyMappingModel.mappingCanSave(selectedKeys));
  }, [dataSource, selectedKeys]);

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: '关联模型属性',
      dataIndex: 'relatedBy',
      width: '30%',
      editable: true,
    },
    {
      title: '映射属性编码',
      dataIndex: 'name',
      editable: true,
    },
    {
      title: '映射属性名称',
      dataIndex: 'description',
      editable: true,
    },
  ];

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      id: '',
      relatedBy: ``,
      name: '',
      description: ``,
    };
    setEditable(true)
    propertyMappingModel.addMappingProperty(newData);
    if (dataSource) {
      setDataSource([...dataSource, newData]);
    } else {
      setDataSource([newData]);
    }
    setCount(count + 1);
  };

  const handleSave = async () => {
    let properties = propertyMappingModel.getMappingProperties(selectedKeys);
    let nameMap = _.keyBy(properties, 'name');
    let ret: any = await ModelingApi.doApiSavePropertyMapping(propertyMappingModel.setPropertyRelatedBy(properties));
    if (ret.success) {
      message.success("保存成功!");
      if (ret.result) {
        _.forEach(ret.result, (item) => item.key = nameMap[item.name].key);
        let keyMap = _.keyBy(ret.result, "key");

        let ds: any = [];
        _.forEach(dataSource, (item) => {
          let dsItem = keyMap[item.key] || item;
          ds.push({...dsItem});
        })
        propertyMappingModel.updateMappingPropertyAfterSave(ret.result);
        setDataSource(propertyMappingModel.getMappingProperties());
        props.mxUpdateModel && props.mxUpdateModel(propertyMappingModel.getModel());
      }
    } else {
      message.error(ret.message);
    }
  };

  const handleDelete = async () => {
    let selectedProperties = propertyMappingModel.getMappingProperties(selectedKeys);
    let ids = _.map(selectedProperties, "id");
    let res: any = await ModelingApi.doDeleteProperties(_.filter(ids, (id) => !!id));
    if (res.success) {
      let ds = propertyMappingModel.removeMappingProperty(selectedKeys);
      propertyMappingModel.removeProperties(selectedKeys);
      setSelectedKeys([]);
      setDataSource(ds);

      props.mxUpdateModel && props.mxUpdateModel(propertyMappingModel.getModel());
    } else {
      console.error(res);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const updateMapping = (mappingProperty: any) => {
    propertyMappingModel.updateMappingProperty(mappingProperty);
    let ds: any = [];
    _.forEach(dataSource, (item) => {
      if (item.key == mappingProperty.key) {
        item = {...mappingProperty}
      }
      ds.push(item);
    })
    setDataSource(ds);
  }

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable && editable,
        dataIndex: col.dataIndex,
        title: col.title,
        propertyMappingModel: propertyMappingModel,
        updateMapping: updateMapping,
      }),
    };
  });

  return (
    <div style={{marginBottom: "5px", ...props.style,}}>
      <Alert style={{margin: "5px"}} message="一对多关系，查询多方模型时关联查询一方属性" type="info"/>
      <Button onClick={handleAdd} type="link">
        新增映射
      </Button>
      <Button disabled={!canSave} onClick={handleSave} type="link">
        保存
      </Button>
      <Button disabled={!selectedKeys.length} onClick={handleDelete} type="link">
        删除
      </Button>
      <Table
        className={"createModel"}
        rowSelection={{
          type: "checkbox",
          onSelect: (record, selected, selectedRows) => {
            setSelectedKeys(_.map(selectedRows, 'key'));
          },
          onSelectAll: (selected: boolean, selectedRows) => {
            setSelectedKeys(_.map(selectedRows, 'key'));
          }
        }}
        pagination={false}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
    </div>
  );
});

export default PropertyMapping;
