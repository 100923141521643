import Api from "@byk/api/Api";
import {message} from "antd";
import _ from "lodash";
import {getAppId, getProjectId, getTenant} from "utils/localStorage";

class ModelingApi {
  // 模型 API
  static apiList: string = '/byk/platform/rest/LcDynamicModel/list';
  static apiPost: string = '/byk/platform/rest/LcDynamicModel';
  static apiPut: string = '/byk/platform/rest/LcDynamicModel';
  static apiDel: string = '/byk/platform/rest/LcDynamicModel';
  static apiGet: string = '/byk/platform/rest/LcDynamicModel';
  static apiSelect: string = '/byk/platform/modeling/tagTree';
  static apiSave: string = '/byk/platform/modeling/save';
  // 批量创建模型
  static apiCreateModelsFromDb: string = '/byk/platform/modeling/createModelsFromDb';
  // 数据源导入创建模型
  static apiImportLoadModelsFromDataSource: string = '/byk/platform/modeling/ds/loadModels';
  static apiExportSql4Model: string = '/byk/platform/modeling/ds/export/sql';
  static apiUploadSalCreateModel: string = '/byk/platform/modeling/ds/executeDbSql';

  // 模型属性 API
  static apiPostProperty: string = '/byk/platform/rest/LcDynamicModelProperty';
  static apiPutProperty: string = '/byk/platform/rest/LcDynamicModelProperty';
  static apiDelProperty: string = '/byk/platform/rest/LcDynamicModelProperty';
  static apiGetProperty: string = '/byk/platform/rest/LcDynamicModelProperty';
  static apiListProperty: string = '/byk/platform/rest/LcDynamicModelProperty/list';
  static apiSavePropertyRel: string = '/byk/platform/modeling/line/newRel';
  // 添加系统辅助字段
  static apiInitSysProperties: string = '/byk/platform/modeling/initSysProperties';
  // 批量删除字段属性
  static apiDropProperties: string = '/byk/platform/modeling/property/drop';
  // 获取模型列表
  static apiGetModelNames: string = '/byk/platform/modeling/getModelNames';
  // 批量保存映射属性
  static apiBatchSaveProperty: string = '/byk/platform/modeling/property';
  // 模型图接口
  static apiPostModelMxXml: string = '/byk/platform/modeling/mx/save';
  static apiGetModelMxXml: string = '/byk/platform/modeling/mx/load';
  // 更新模型前，查询需要执行删除属性的属性集合
  static apiGetNeedDelColumn: string = '/byk/platform/modeling/getNeedDelColumn';
  // 查询当前模型发布的差异
  static apiPublishModelDiffVer: string = '/byk/platform/modeling/publish/diffVer';
  // 生成模型数据SQL
  static apiPublishModelDataSql: string = '/byk/platform/modeling/publish/dataSql';
  // 生成模型数据SQL
  static apiPublishChangeSqlFromFile: string = '/byk/platform/modeling/publish/genChangeSqlFromSql';
  // 下载创建模型Excel模板
  static apiDownloadExcelTemplate4CreateModel: string = 'byk/platform/modeling/download/excelTemplate/createModel';
  // 执行SQL
  static apiExecuteSqlInfo: string = 'byk/platform/modeling/ds/executeSqlInfo';

  // 调用 SqlInfo Rest API
  static doSqlInfoRestUrl(type: any = 'list', _params: any = {}) {
    let sqlInfoRestUrl = `/byk/${getTenant()}/rest/SqlInfo`;
    let params = {
      ..._params,
      tenant: getTenant(),
      projectId: getProjectId(),
      appId: getAppId(),
    }
    if (type == 'insert') {
      return Api.post(sqlInfoRestUrl, params);
    } else if (type == 'update') {
      return Api.put(sqlInfoRestUrl, params);
    } else if (type == 'delete') {
      return Api.delete(sqlInfoRestUrl, params?.ids || [-1]);
    } else {
      return Api.get(sqlInfoRestUrl + "/list", params);
    }
  }

  /**
   *  执行SQL
   * @param property
   */
  static doExecuteSqlInfo(_params: any = {}) {
    if (ModelingApi.checkTenant()) {
      let params = {
        ..._params,
        tenant: ModelingApi.getTenant(),
      }
      return Api.post(this.apiExecuteSqlInfo, params);
    }
  }

  /**
   *   下载创建模型Excel模板
   * @param property
   */
  static doDownloadExcelTemplate4CreateModel() {
    const link = document.createElement('a');
    link.href = this.apiDownloadExcelTemplate4CreateModel;
    link.download = "创建模型模板.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   *  保存发布模型
   * @param property
   */
  static doPublishChangeSqlFromFile(_params: any = {}) {
    if (ModelingApi.checkTenant()) {
      let params = {
        ..._params,
        tenant: ModelingApi.getTenant(),
      }
      return Api.post(this.apiPublishChangeSqlFromFile, params);
    }
  }

  /**
   *  保存发布模型
   * @param property
   */
  static doPublishModelDataSql(dataParams?: any) {
    if (ModelingApi.checkTenant()) {
      let params = {
        ...dataParams,
        tenant: ModelingApi.getTenant(),
      }
      return Api.post(this.apiPublishModelDataSql, params);
    }
  }

  /**
   *  保存发布模型
   * @param property
   */
  static doPublishModelDiffVer(version?: any) {
    if (ModelingApi.checkTenant()) {
      let params = {
        version: version,
        tenant: ModelingApi.getTenant(),
      }
      return Api.post(this.apiPublishModelDiffVer, params);
    }
  }

  /**
   * 导入Excel
   * @param property
   */
  static doApiImportExport(params: any) {
    if (ModelingApi.checkTenant()) {
      return Api.post(`/byk/${getTenant()}/importExcel`, params);
    }
  }

  /**
   * 导出模型
   * @param property
   */
  static getExportExcelUrl(model: string, excelName: string, fields: any) {
    return `/byk/${getTenant()}/rest/${model}/list?fields=${fields}&export=${excelName}`
  }

  /**
   * 导出模型
   * @param property
   */
  static getModelDataUrl(model: string) {
    return `/byk/${getTenant()}/rest/${model}/list?1=1`
  }

  /**
   * 删除模型业务数据
   * @param property
   */
  static doDeleteModelDataById(model: any, ids: any = []) {
    let url = `/byk/${getTenant()}/rest/${model?.name}`;
    return Api.delete(url, ids);
  }

  /**
   * 删除模型业务数据
   * @param property
   */
  static doSaveModelData(model: any, data: any = {}) {
    let url = `/byk/${getTenant()}/rest/${model?.name}`;
    if (data?.id) {
      return Api.put(url, data);
    } else {
      return Api.post(url, data);
    }
  }

  /**
   * 创建模型关系
   * @param property
   */
  static doApiGetNeedDelColumn(model: any) {
    if (ModelingApi.checkTenant()) {
      return Api.post(this.apiGetNeedDelColumn, model);
    }
  }

  /**
   * 创建模型关系
   * @param property
   */
  static doApiExportSql4Model() {
    if (ModelingApi.checkTenant()) {
      return Api.get(this.apiExportSql4Model + "?tenant=" + ModelingApi.getTenant());
    }
  }


  /**
   * 创建模型关系
   * @param property
   */
  static doApiCreateModelsFromDb(models: any, dsCfg: any) {
    if (ModelingApi.checkTenant()) {
      return Api.post(this.apiCreateModelsFromDb, {tenant: ModelingApi.getTenant(), models, dsCfg});
    }
  }

  /**
   * 创建模型关系
   * @param property
   */
  static doApiSavePropertyMapping(properties: any) {
    if (ModelingApi.checkTenant()) {
      _.forEach(properties, item => item.tenant = ModelingApi.getTenant());
      return Api.post(this.apiBatchSaveProperty, properties);
    }
  }

  /**
   * 创建模型关系
   * @param property
   */
  static doApiCreateOrChangePropertyRel(property: any) {
    if (ModelingApi.checkTenant()) {
      let params = {
        ...property,
        tenant: ModelingApi.getTenant()
      };
      return Api.post(this.apiSavePropertyRel, params);
    }
  }

  static doApiDropProperties(properties: any) {
    return Api.post(this.apiDropProperties, properties);
  }

  /**
   * 查询MX xmlData
   */
  static doApiGetModelMxXml() {
    if (ModelingApi.checkTenant()) {
      return Api.get(this.apiGetModelMxXml + "?tenant=" + ModelingApi.getTenant());
    }
  }

  /**
   * 保存Mx xmlData
   */
  static doApiPostModelMxXml(param: any) {

    if (ModelingApi.checkTenant()) {
      let tenant = ModelingApi.getTenant();
      return Api.post(this.apiPostModelMxXml, {...param, tenant});
    }
  }

  /**
   * 保存更新模型
   * @param modelData
   */
  static doSaveModel(modelData: any) {
    if (ModelingApi.checkTenant()) {
      if (modelData.id) {
        return Api.put(this.apiPut, modelData);
      } else {
        let tenant = ModelingApi.getTenant();
        let data = {
          ...modelData,
          tenant
        }
        return Api.post(this.apiPost, data);
      }
    }
  }

  /**
   * @param id
   */
  static doSaveProperty(property: any) {
    if (ModelingApi.checkTenant()) {
      let params = {
        ...property,
        tenant: ModelingApi.getTenant(),
      }
      if (property.id) {
        return Api.put(this.apiPutProperty, params);
      } else {
        return Api.post(this.apiPostProperty, params);
      }
    }
  }

  /**
   * 根据Id加载模型
   * @param record
   */
  static doGetModelList(appId?: number | string | undefined | null) {
    let appIdParam = appId;
    if (!appIdParam) {
      if (ModelingApi.checkTenant()) {
        appIdParam = ModelingApi.getTenant();
      }
    }

    return Api.get(this.apiList + "?includeList=true&tenant=" + appIdParam);
  }

  /**
   * 删除属性
   * @param id
   */
  static doDeleteModel(id: any) {
    if (ModelingApi.checkTenant()) {
      return Api.delete(this.apiDel, [id]);
    }
  }

  /**
   * 删除属性
   * @param id
   */
  static doDeleteProperty(id: any) {
    if (ModelingApi.checkTenant()) {
      return Api.delete(this.apiDelProperty, [id]);
    }
  }

  /**
   * 删除属性
   * @param id
   */
  static doDeleteProperties(ids: any) {
    if (ModelingApi.checkTenant()) {
      return Api.delete(this.apiDelProperty, ids);
    }
  }

  /**
   * 根据Id加载模型
   * @param record
   */
  static getModelById(id: any) {
    if (ModelingApi.checkTenant()) {
      return Api.get(this.apiGet + "?id=" + id);
    }
  }

  /**
   * 根据Id加载模型
   * @param record
   */
  static getModelPropertyById(id: any) {
    if (ModelingApi.checkTenant()) {
      return Api.get(this.apiGetProperty + "?id=" + id);
    }
  }

  /**
   * 根据Id加载模型
   * @param record
   */
  static getModelPropertiesByModelId(id: any) {
    return Api.get(this.apiListProperty + "?modelId=" + id + "&desc=createDate");
  }

  /**
   * 删除属性
   * @param id
   */
  static doInitSysProperties(modelId: any) {
    if (ModelingApi.checkTenant()) {
      return Api.post(this.apiInitSysProperties + "?tenant=" + ModelingApi.getTenant() + "&modelId=" + modelId);
    }
  }

  /**
   * 更新属性
   * @param property
   */
  static doUpdateProperty(property: any) {
    if (ModelingApi.checkTenant()) {
      return Api.put(this.apiPutProperty, property);
    }
  }

  /**
   * 查询和ModelId有多对一关系的属性列表
   * @param record
   */
  static doGetRelProperties(targetModel: any) {
    if (ModelingApi.checkTenant()) {
      return Api.get(this.apiListProperty
        + "?targetModel=" + targetModel
        + '&tenant=' + ModelingApi.getTenant());
    }
  }

  /**
   * 数据源导入建模
   * @param record
   */
  static doApiLoadModelsFromDataSource(dsConfig: any) {
    if (ModelingApi.checkTenant()) {
      return Api.post(this.apiImportLoadModelsFromDataSource, dsConfig);
    }
  }

  /**
   * 检查子应用ID是否存在
   * @private
   */
  static checkTenant() {
    let tenant = ModelingApi.getTenant();
    let hasTenant = !!tenant;
    if (!hasTenant) {
      message.error('应用信息过期，请重新登录！').then(() => {
      });
    }

    return tenant;
  }

  /**
   * tenantId
   */
  static getTenant() {
    return getTenant();
  }

  /**
   * appId
   */
  static getAppIdRaw() {
    let appId: any = getAppId();
    return appId;
  }

  /**
   * projectId
   */
  static getProjectIdRaw() {
    let projectId: any = getProjectId()
    return projectId;
  }
}

export default ModelingApi;
