import React, { useContext, useEffect, useState } from "react";
import { RoleContext } from "@byk/pages/AppSystemInit/RoleManager/index";
import { Button, Col, Form, Input, message, Modal, PageHeader, Popconfirm, Row, Space, Table } from "antd";
import moment from 'moment';
import Api from "@byk/api/Api";
import { useLocation } from "react-router";
import history from 'utils/history';
import { apiPath, routePath } from "@byk/routes";
import { getTenant } from "utils/localStorage";
import {appUrlParams} from "@byk/utils/Utils";

function RoleList(props: any) {
  const isPt = props.isPt;
  const { applicationId, pageId, onToPage } = isPt ? props : useContext(RoleContext);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [mode, setMode] = useState(0);
  const [saveMode, setSaveMode] = useState(0);
  const [isModalForm, setIsModalForm] = useState(false);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const username = queryParams.get('username');

  const [form] = Form.useForm();
  const [formModel] = Form.useForm();


  const addClick = () => {
    openRoleModel();
    setMode(0);
    formModel.setFieldsValue({ id: null, roleName: null, roleDesc: null });
  }

  const powerClick = (record: any) => {
    const base64Data_roleName = encodeURIComponent(record.roleName);
    let _parms: any = `${appUrlParams()}&type=edit&id=${record.id}&roleName=${base64Data_roleName}`;
    _parms = _parms.substring(1);

    if (isPt) {
      history.push(`${routePath}/SysAppRoleEdit?${_parms}`);
    }
    else {
      onToPage('9998', 'systemRole', _parms);
    }

  }
  const columns: any = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any, index: any) => `${index + 1}`,
      width: 70,
      align: "center",
      fixed: 'left',
    },
    {
      title: '角色名称',
      dataIndex: 'roleName',
      key: 'roleName',
      width: 120
    },
    {
      title: '角色描述',
      dataIndex: 'roleDesc',
      key: 'roleDesc'
    },
    {
      title: '拥有权限的应用数',
      dataIndex: 'hasAppNumbers',
      key: 'hasAppNumbers',
      width: 150
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      key: 'creator',
      width: 120
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (text: any) => {
        return moment(text).format('YYYY-MM-DD');
      },
      width: 120
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="small" className="z-rolelist-action" >
          <Button type={'link'} style={{ display: (record.roleName == "管理员" || record.roleName == "游客") ? "none" : "" }} onClick={() => {
            editClick(record)
          }}>编辑</Button>
          <Button type={'link'} style={{ display: record.roleName == "管理员" ? "none" : "" }} onClick={() => {
            powerClick(record)
          }}>权限设置</Button>
          <Popconfirm
            placement="topRight"
            title="确认删除吗？"
            onConfirm={() => {
              deleteRecord(record.id);
            }}
            okText="确认"
            cancelText="取消"
          >
            <Button style={{ display: (record.roleName == "管理员" || record.roleName == "游客") ? "none" : "" }} type={'link'}>删除</Button>
          </Popconfirm>
        </Space>
      ),
      width: 180
    }
  ];

  const openRoleModel = () => {
    setIsModalForm(true);
    setMode(0);
  }
  const deleteRecord = (id: any) => {
    Api.delete(`${apiPath}/${getTenant()}/subapp/SubappRole`, [id]).then((res: any) => {
      if (res.success) {
        getData();
      }
    });
  };
  //翻页时
  const onPageChange = (currPage: number, pageSize: number) => {
    console.log(currPage, pageSize);
    getData(form.getFieldsValue(), {
      currPage,
      pageSize
    })
  }
  const editClick = (record: any) => {
    setIsModalForm(true);
    setMode(1);
    formModel.setFieldsValue({ id: record.id, roleName: record.roleName, roleDesc: record.roleDesc });
  }

  const updateForm = async (values: any) => {
    let _data: any = {};
    _data.id = values.id;
    _data.roleName = values.roleName;
    _data.roleDesc = values.roleDesc;
    let result: any = null;
    //新增
    if (mode == 0) {
      delete _data.id;
      _data.appId = applicationId;
      _data.menu = [];
      _data.pageElement = [];
      result = await Api.post(`${apiPath}/${getTenant()}/subapp/SubappRole`, _data);
    }
    else {
      result = await Api.put(`${apiPath}/${getTenant()}/subapp/SubappRole`, _data);
    }
    if (result.success) {
      message.success("提交成功!");
      let data: any = result.result[0];
      getData();
      if (saveMode == 0) {
        setIsModalForm(false);
      }
      else {
        powerClick(data);
      }
    }
  };

  const getData = (values?: any, page?: any) => {
    let params = {
      appId: applicationId,
      currPage: 1,
      pageSize: 10
    };
    if (page) {
      params = {
        ...params,
        ...page
      }
    }
    if (values?.roleName) {
      params = {
        ...values,
        ...params,
        exp: 'allLike'
      }
    }
    Api.get(`${apiPath}/${getTenant()}/subappService/SubappRole/list`, params)
      .then((res: any) => {
        const result = res.result || [];
        setTableData(result);
        setPagination(pagination => {
          return {
            ...pagination,
            current: params.currPage,
            total: res.size,
            showTotal: () => (
              <span>总共{res.size}项</span>
            ),
            onChange: onPageChange
          }
        })
      });
  };
  const { TextArea } = Input;
  const onReset = () => {
    form.resetFields();
    getData();
  };
  useEffect(() => {
    getData();
  }, [applicationId]);
  return (
    <>
      <PageHeader className="site-page-header-main" title="角色管理" />
      <div className="x-main-box">
        <div >
          <Form form={form} onFinish={getData}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout={'inline'}
            autoComplete="off">
            <Form.Item label="角色名称" name={'roleName'}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">查询</Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="button" onClick={onReset}>重置</Button>
            </Form.Item>
          </Form>
        </div>
        <div style={{ margin: '10px 0' }}>
          <Button type={'primary'} onClick={() => {
            addClick()
          }}>新增</Button>
        </div>
        <Table bordered={true} columns={columns} pagination={pagination} dataSource={tableData} rowKey="id" />

        <Modal title={mode == 0 ? "新增" : "编辑"} open={isModalForm} onOk={() => { }} forceRender={false} onCancel={() => { setIsModalForm(false) }} footer={[]} getContainer=".x-sys-manage-wrapper">
          <Row>
            <Col className="gutter-row" md={24}>
              <Form form={formModel} layout={"vertical"} onFinish={updateForm}>
                <Form.Item label="id" hidden={true} name="id">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item label="角色名称" name="roleName"
                  rules={[{ required: true, message: "请输入!" }]}>
                  <Input placeholder="请输入" maxLength={30} />
                </Form.Item>
                <Form.Item label="角色描述" name="roleDesc">
                  <TextArea showCount maxLength={100} style={{ height: 100, wordBreak: "break-all" }} placeholder={"请输入"} />
                </Form.Item>

                <div style={{ width: "100%", textAlign: "right" }}>
                  <Button onClick={() => { setIsModalForm(false) }}>取消</Button>
                  <Button className="x-ml10" type="primary" htmlType="submit" onClick={() => { setSaveMode(1) }}>
                    保存并前往设置权限
                  </Button>
                  <Button className="x-ml10" type="primary" htmlType="submit" onClick={() => { setSaveMode(0) }}>
                    保存并关闭
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    </>

  )
}
export default RoleList;
