import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "XEchartsGauge",
  iconSVG: IconSVG,
  defaults: {
    rows: 60,
    columns: 70,
    widgetName: "XEchartsGauge",
    version: 1,
    label: "label",
    isDownload: false,
    isDataView: false,
    isTitle: true,
    isLegend: true,
    title: "Echart",
    radius: 50,

    borderWidth:1,
    isProgress: true,//是否显示进度
    isAxisTick: false,//是否显示小刻度
    isAnchor: true,//是否显示圆心
    isValueAnimation: true,//是否开启数值动画
    progressWidth: 18,//进度条宽度
    axisLineWidth: 18,//圆周宽度
    splitLineLength: 15,//大刻度长度
    splitLineWidth: 2,//大刻度宽度
    splitLineColor: "#999",//大刻度颜色
    axisLabelDistance: 25,//离圆周距离
    axisLabelColor: "#999",//刻度文字颜色 
    axisLabelFontSize: 20,//刻度文字大小
    anchorSize: 25,//圆心大小
    anchorBorderWidth: 10,//圆心宽度
    detailFontSize: 80,//数值文字大小 
    detailOffsetCenter: 70,//数值垂直位置
    progressColor: "blue",   //进度条颜色
    pointerColor: "blue",//指针颜色  
    anchorColor: "#fff",//圆心空白颜色  
    anchorBorderColor: "blue",//圆心圆周颜色 
    currentData: [
      {
        "value": 80
      }
    ]
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
  }
};

export default Widget;
