import React from 'react';
import {AtList, AtListItem} from "taro-ui";
import {Picker} from "@tarojs/components";
import _ from "lodash";
import {PickerWrapper} from "./MonthPicker";

const MultiWidget = (props:any) => {
  const {label, value, multiSelectorOptions, onDateChange, isVisible} = props;
  const reValue = (v:any)=>{
    let newValue:any = [];
    _.each(multiSelectorOptions, (i:any, idx:number)=>{
      if(i[v[idx]]!==undefined){
        newValue.push(i[v[idx]]);
      }
    });
    if(_.isEmpty(newValue)||newValue.length !== 2){
      return [multiSelectorOptions[0][0], multiSelectorOptions[1][0]];
    }
    return newValue;
  }

  return (
    <PickerWrapper isVisible={isVisible}>
      <Picker mode='multiSelector' range={multiSelectorOptions} onChange={(e:any)=>{
        onDateChange(e.detail.value);
      }} value={value}>
        <AtList>
          <AtListItem title={label} extraText={reValue(value)} />
        </AtList>
      </Picker>
    </PickerWrapper>
  );
};

export default MultiWidget;
