import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Alert, Button, List, Modal} from "antd";
import moment from "moment";
import {downloadModelData} from "@byk/pages/modeling/ModelDataUtil";

interface Props {
  ref: any
  saveModel: (modelData: any, isNew: boolean) => void;
}

interface DataType {
  key: React.Key;
  id: any;
  name: string;
  type: string;
  targetModel: string;
}

const DataBackup: React.FC<Props> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showModal,
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<any>([]);
  const [model, setModel] = useState<any>([]);
  const [isNew, setIsNew] = useState<any>([]);

  const showModal = (properties: any, model: any, isNew: boolean) => {
    setData(properties || []);
    setModel(model);
    setIsNew(isNew);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const exportData = async () => {
    let modelName = data[0].model;
    let excelName = "模型(" + modelName + ")数据_" + moment(new Date()).format("yyyyMMDD") + ".xlsx";
    downloadModelData(modelName, excelName);
  }

  return (
    <>
      <Modal
        onCancel={handleCancel} open={isModalOpen}
        maskClosable={false}
        footer={null}
        title={"数据备份"}
        width={600}
        bodyStyle={{
          padding: 0,
        }}
      >
        <Alert style={{margin: "5px"}} message="模型修改，下面属性涉及物理表字段删除，请做好数据备份！" type="info"/>
        <Button type="link" style={{margin: "5px"}} onClick={exportData}>数据备份</Button>
        <Button
          type="link" style={{margin: "5px"}}
          onClick={() => {
            props.saveModel(model, isNew);
            setIsModalOpen(false);
          }}
        >
          更新模型(已备份数据)
        </Button>
        <List
          header={null}
          footer={null}
          bordered
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item>
              {item.description}
            </List.Item>
          )}
        />

      </Modal>
    </>
  );
});

export default DataBackup;
