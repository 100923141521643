import React from "react";
import history from "utils/history";

import {Button, Card, Checkbox, Col, Dropdown, Empty, Form, Input, message, Modal, Row, Select, Space, Tabs, Tooltip} from "antd";
// import app_svg from "@byk/assets/svg/application.svg";
// import app_task from "@byk/assets/svg/task.svg";
// import app_notTask from "@byk/assets/svg/noTask.svg";
import img1 from "@byk/assets/images/e3.png";
import more1 from "@byk/assets/images/more1.png";

import {ApiOutlined, AppstoreOutlined, ConsoleSqlOutlined, DeleteOutlined, LaptopOutlined, MinusCircleOutlined, PlusCircleOutlined, TeamOutlined,} from "@ant-design/icons";
import Api from "@byk/api/Api";
import {connect} from "react-redux";
import {X_Edit_Url, X_SUBAPPID} from '@byk/store/XReducers';
import type {integer} from "vscode-languageserver-types";
import _ from "lodash";
import {cutString, uuid2} from "@byk/utils/Utils";
import localStorage from "../../../../utils/localStorage";
import { getCookie, setCookie } from "../../../../utils/CookieUtils";
import {apiPath, routePath} from "@byk/routes";
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import ImportProjectModal from "@byk/pages/AppDeploy/ImportProjectModal";
import {AppState} from "../../../../ce/reducers";
import {getIsMyTask} from "../../../../sagas/selectors";

const { Search } = Input;
const {TextArea} = Input;
const app_svg = (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>我的项目</title>
    <g id="2-主页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="2-1主页" transform="translate(-309.000000, -174.000000)">
        <g id="我的项目" transform="translate(284.000000, 151.000000)">
          <g transform="translate(25.000000, 23.000000)">
            <path
              d="M3,0 L7,0 C8.65685425,-3.04359188e-16 10,1.34314575 10,3 L10,7 C10,8.65685425 8.65685425,10 7,10 L3,10 C1.34314575,10 2.02906125e-16,8.65685425 0,7 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 Z M3,12 L7,12 C8.65685425,12 10,13.3431458 10,15 L10,19 C10,20.6568542 8.65685425,22 7,22 L3,22 C1.34314575,22 2.02906125e-16,20.6568542 0,19 L0,15 C-2.02906125e-16,13.3431458 1.34314575,12 3,12 Z M15,12 L19,12 C20.6568542,12 22,13.3431458 22,15 L22,19 C22,20.6568542 20.6568542,22 19,22 L15,22 C13.3431458,22 12,20.6568542 12,19 L12,15 C12,13.3431458 13.3431458,12 15,12 Z"
              id="形状结合" fill="#327EED"></path>
            <rect id="矩形备份" fill="#46D0FF" x="12" y="0" width="10" height="10" rx="3"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const app_task = (
  <svg width="19px" height="22px" viewBox="0 0 19 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>我的任务</title>
    <g id="2-主页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="2-1主页" transform="translate(-638.000000, -174.000000)" fill-rule="nonzero">
        <g id="我的任务" transform="translate(612.000000, 151.000000)">
          <g transform="translate(26.000000, 23.000000)">
            <path
              d="M14.2260054,22 L4.77399462,22 C2.13770255,22 0,19.7405833 0,16.9541889 L0,5.04581113 C0,2.25941673 2.13770255,0 4.77399462,0 L10.6959916,0 C10.9851735,0 11.2631372,0.118569974 11.4725448,0.330678484 L18.6559732,7.6227319 C18.8755889,7.84610545 18.9997831,8.15429395 19,8.47643571 L19,16.9528714 C19,19.7405833 16.8622974,22 14.2260054,22 Z"
              id="路径" fill="#746EF6"></path>
            <path
              d="M8.7320551,11.7517946 L4.28897124,11.7517946 C3.57745912,11.7517946 3,11.1670655 3,10.4465956 C3,9.72612573 3.57745912,9.14139656 4.28897124,9.14139656 L8.7320551,9.14139656 C9.44356723,9.14139656 10.0210263,9.72612573 10.0210263,10.4465956 C10.0210263,11.1670655 9.44356723,11.7517946 8.7320551,11.7517946 L8.7320551,11.7517946 Z M13.3723516,17 L4.28897124,17 C3.57745912,17 3,16.4152708 3,15.694801 C3,14.9743311 3.57745912,14.3896019 4.28897124,14.3896019 L13.3723516,14.3896019 C14.0838637,14.3896019 14.6613228,14.9743311 14.6613228,15.694801 C14.6613228,16.4152708 14.0838637,17 13.3723516,17 Z"
              id="形状" fill="#FFFFFF"></path>
            <path
              d="M10.1564115,0 L10.1564115,5.73702207 C10.1564115,6.98663351 11.1571786,8 12.3912533,8 L19,8 C19,7.69536836 18.8772065,7.40441405 18.6610899,7.19428039 L11.5845,0.312092011 C11.3789169,0.111854854 11.104748,0 10.8194964,0 L10.1564115,0 L10.1564115,0 Z"
              id="路径" fill="#514AE5"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const app_notTask = (
  <svg width="19px" height="22px" viewBox="0 0 19 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>未完成任务</title>
    <g id="2-主页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="2-1主页" transform="translate(-964.000000, -174.000000)" fill-rule="nonzero">
        <g id="未完成的任务" transform="translate(938.000000, 151.000000)">
          <g id="未完成任务" transform="translate(26.000000, 23.000000)">
            <path
              d="M14.2260054,22 L4.77399462,22 C2.13770255,22 0,19.7405833 0,16.9541889 L0,5.04581113 C0,2.25941673 2.13770255,0 4.77399462,0 L10.6959916,0 C10.9851735,0 11.2631372,0.118569974 11.4725448,0.330678484 L18.6559732,7.6227319 C18.8755889,7.84610545 18.9997831,8.15429395 19,8.47643571 L19,16.9528714 C19,19.7405833 16.8622974,22 14.2260054,22 Z"
              id="路径" fill="#FEC82D"></path>
            <path
              d="M8.7320551,11.7517946 L4.28897124,11.7517946 C3.57745912,11.7517946 3,11.1670655 3,10.4465956 C3,9.72612573 3.57745912,9.14139656 4.28897124,9.14139656 L8.7320551,9.14139656 C9.44356723,9.14139656 10.0210263,9.72612573 10.0210263,10.4465956 C10.0210263,11.1670655 9.44356723,11.7517946 8.7320551,11.7517946 L8.7320551,11.7517946 Z M13.3723516,17 L4.28897124,17 C3.57745912,17 3,16.4152708 3,15.694801 C3,14.9743311 3.57745912,14.3896019 4.28897124,14.3896019 L13.3723516,14.3896019 C14.0838637,14.3896019 14.6613228,14.9743311 14.6613228,15.694801 C14.6613228,16.4152708 14.0838637,17 13.3723516,17 Z"
              id="形状" fill="#FFFFFF"></path>
            <path
              d="M10.1564115,0 L10.1564115,5.73702207 C10.1564115,6.98663351 11.1571786,8 12.3912533,8 L19,8 C19,7.69536836 18.8772065,7.40441405 18.6610899,7.19428039 L11.5845,0.312092011 C11.3789169,0.111854854 11.104748,0 10.8194964,0 L10.1564115,0 L10.1564115,0 Z"
              id="路径" fill="#EA9B01"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const initValues1 = [
  {type: "input", name: "name", label: "应用名称", initValue: ""},
];
const initValues2 = [
  {type: "select", name: "displayType", label: "应用尺寸", initValue: ""},
];

const formItemLayout = {
  labelCol: {span: 24},
  wrapperCol: {span: 24},
};

const initRules = [{required: true, message: "字段必填！"}];

interface FormItemProps {
  type: string;
  name: string;
  label: string;
  initValue: string;
  rules: {}[];
}

interface TypeProps {
  initValues: FormItemProps[];
}

const pageSize=20;

const handleChange = () => {
  //form.setFieldsValue({sights: []});
};
const sizeOption = [
  {
    value: "PC",
    label: "电脑端(1440*1024px)",
    width: 1440,
    height: 1024,
  },
  {
    value: "Mobile",
    label: "移动端(750*750px)",
    width: 750,
    height: 750,
  }
];
const sizeObj:Record<string, any> = {
  "PC": {
    size: "电脑端(1440*1024px)",
    displayType: 'PC',
    width: 1440,
    height: 1024,
  },
  "Mobile": {
    size: "移动端(750*750px)",
    displayType: 'Mobile',
    width: 750,
    height: 750,
  }
}
class Main extends React.Component<any> {
  formRef: React.RefObject<any>;
  formDeleteRef: React.RefObject<any>;
  importProjectModalRef: React.RefObject<any>;

  constructor(props: any) {
    super(props);
    this.formRef = React.createRef();
    this.formDeleteRef = React.createRef();
    this.importProjectModalRef = React.createRef();
  }

  state = {
    importProjectOpened:false,
    isModalOpen: false,
    setIsModalOpen: false,
    title: "新增应用",
    appData: [],
    appDataView: [],
    visibleCount: pageSize,
    projectId: null,
    indexCount: {
      myAppNum: 0,
      noFinishTaskNum: 0,
      myTaskNum: 0
    },
    key:uuid2(),
    type: 1,
    isLoading: true,
    delLoading: false,
    formCfg: {id: 0, title: "", isShow: false, name: null},
    addLoading:false,
    moduleDefaultList:[],
    moduleDefaultListIds:[],
    moduleChooseList:[],
    moduleChooseListEdit:[],
    moduleChooseListIds:[],
    moduleIds:[],
  };

   // 加载更多数据
   loadMore = () => {
    // 检查是否还有更多数据可以加载
    const { appDataView, visibleCount } = this.state;
    const totalItems = appDataView.length;
    const newVisibleCount = Math.min(visibleCount + pageSize, totalItems);

    // 更新状态以显示更多数据
    this.setState({ visibleCount: newVisibleCount });
  }
  onSearch = (value: string) => {
    const appData:any=[...this.state.appData];
    let appSearchList:any=[];
    if(value.trim()==""){
      appSearchList=appData;
    }
    else{
      appSearchList= appData.filter((itm:any)=>{
        return itm.name.indexOf(value)>-1;
      })
    }
    this.setState({appDataView:appSearchList,visibleCount:pageSize});
  };

  Cancel = () => {
    let cfg = {...this.state.formCfg};
    cfg.isShow = false;
    this.setState({formCfg: cfg});
  };
  showDeleteModal = (obj: any) => {
    let cfg = {...this.state.formCfg};
    cfg.isShow = true;
    cfg.title = "删除项目“" + obj.name + "”";
    cfg.id = obj.id;
    cfg.name = obj.name;
    this.setState({formCfg: cfg},()=>{
      this.formDeleteRef.current.setFieldsValue({name:""});
    });
  };
  deleteForm = (values: any) => {
    if (values.name != this.state.formCfg.name) {
      message.warn("输入的项目名称不正确!");
      return false;
    } else {
      this.setState({
        delLoading: true
      });
      this.deleteApp(this.state.formCfg.id.toString()).then(() => {
        this.setState({
          delLoading: false
        });
      });
    }
  };

  showModal = async (type: number, id?: string) => {
    //绑定模块
    //console.log("this.state.moduleChooseList",this.state.moduleChooseList);
    let that:any=this;
    let _key=uuid2();
    let _chooseListEditDefault:any=[];
    let _moduleChooseList=_.clone(this.state.moduleChooseList);
    _.each(_moduleChooseList,(item:any)=>{
      item.disabled=false;
      _chooseListEditDefault.push(item);
    })
    if (type == 1) {
      let chooseListEdit=_.clone(this.state.moduleChooseList);
      let _o:any={};
      _o[_key]=chooseListEdit;
      //console.log("_o",_o);
      this.setState({isModalOpen: true,addLoading:false, type: 1,moduleChooseListEdit:_chooseListEditDefault,key:_key}, () => {
        setTimeout(() => {
          this.formRef.current?.setFieldsValue({
            name: "", description:"",
            moduleIds1:this.state.moduleDefaultListIds,
            moduleIds2:[],
            sysApps:[],sysApps_base:[{
              name: "",
              size: "web端(1440*1024px)",
              appType:"IntegrationApp"
            }]
          });
        }, 300);
      });
    } else {
      let res: any = await Api.get(this.apiPost, {id: id});
      if (res.success) {
        if (res.result.length > 0) {
          let _record = res.result[0];
          let _sysApps:any=[];
          let _sysApps_base:any=[];
          let _module="";
          let _module2:any=[]

          _.each(_record.sysApps,(itm,idx)=>{
            let _o={ id:itm.id, name:itm.name, displayType: itm.displayType, appType:itm.appType};
            if(itm.appType=="IntegrationApp"){
              _module=itm.moduleIds;
              _sysApps_base.push(_o);
            }
            else{
              _sysApps.push(_o);
            }
          })
          if(!_.isEmpty(_module)){
            let _l=_module.split(",");
            _.each(_l,(item:any)=>{
              if(that.state.moduleChooseListIds.indexOf(item)>-1){
                _module2.push(item);
              }
            })
          }
          let _chooseListEdit:any=[];

          _.each(_chooseListEditDefault,(item:any)=>{
            if(_module2.indexOf(item.value)>-1){
              item.disabled=true;
            }
            _chooseListEdit.push(item);
          })
          let value={
            id: _record.id, name: _record.name, description: _record.description,
            moduleIds1:this.state.moduleDefaultListIds,
            moduleIds2:_module2,
            sysApps: _sysApps,sysApps_base:_sysApps_base
          }
          this.setState({isModalOpen: true,addLoading:false, type: 2,moduleChooseListEdit:_chooseListEdit,key:_key}, () => {
            setTimeout(() => {
              this.formRef.current?.setFieldsValue(value);
            }, 300);

          });
        }
      }
    }
  };
  handleOk = () => {
    this.setState({isModalOpen: false});
  };

  handleCancel = () => {
    this.setState({isModalOpen: false,addLoading:false});
  };
  apiGet: string = "/byk/platform/rest/SysProject/list";
  apiPost: string = "/byk/platform/rest/SysProject";
  apiPut: string = "/byk/platform/rest/SysProject";
  apiDel: string = "/byk/platform/rest/SysProject";
  apiGetPage: string = "/byk/platform/rest/Page/list";
  apiGetApplication: string = "/byk/platform/rest/SysApplication/list";
  getTenantId = ()=>{
    return localStorage.getItem(this.props.userId+'_tenantId');
  }
  formatSubmitData=(values:any)=>{
    let newData={...values};
    let  base=newData.sysApps_base[0];
    let _models=[...this.state.moduleDefaultListIds,...values.moduleIds2];
    base = {
      ...base,
      ...sizeObj['PC'],
      appType: 'IntegrationApp',
      moduleIds:_models.join(",")
    }
    newData.sysApps=newData.sysApps.map((i:any)=>{
      return {
        ...i,
        ...sizeObj[i.displayType],
        appType: 'FrontendApp'
      }
    });
    newData.sysApps.unshift(base);
    delete newData.moduleIds1;
    delete newData.moduleIds2;
    return newData;
  }
  onFinish = async (values: any) => {
    this.setState({addLoading:true})
    let result:any={};
    let newValues=this.formatSubmitData({
      ...values,
      tenantId: this.getTenantId()
    });
    delete newValues.sysApps_base;
    if(this.state.type==1){
      delete newValues.id;
      result = await Api.post(this.apiPost, newValues);
    }
    else{
      result = await Api.put(this.apiPost, newValues);
    }
    if (result.success) {
      message.success("保存成功!");
      await this.refreshTable();
      this.handleCancel();
    } else {
      this.setState({addLoading:false});
    }
  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  componentDidMount() {
    this.bindModuleIds();
    this.doAction();
  }
  componentDidUpdate(prevProps:any){
    if(prevProps.projectId&&
      this.props.projectId
      &&!_.isEqual(prevProps.projectId, this.props.projectId)){
      this.doAction();
    }
  }

  doAction() {
    this.refreshTable();
  };
  bindModuleIds = async () => {
    let result: any = await Api.get("/byk/platform/module/list", {});
    if (result.success) {
      let _list=result.result;
      let _listDefault:any=[];
      let _listChoose:any=[];
      let _moduleDefaultListIds:any=[];
      let _moduleChooseListIds:any=[];

      _.each(_list,(itm:any)=>{
        let _obj:any={label: itm.moduleName, value: itm.id.toString()};
        if(itm.defaultInstall){
          _listDefault.push(_obj)
          _moduleDefaultListIds.push(_obj.value);
        }
        else{
          _listChoose.push(_obj);
          _moduleChooseListIds.push(_obj.value);
        }
      })
      this.setState({
        moduleDefaultList:_listDefault,
        moduleChooseList:_listChoose,
        moduleDefaultListIds:_moduleDefaultListIds,
        moduleChooseListIds:_moduleChooseListIds
      });
    }
  };

  refreshTable = async () => {
    if (!_.isEmpty(this.getTenantId())) {
      let result: any = await Api.get("/byk/platform/tenant/index", {tenantId: this.getTenantId()});
      if (result.success) {
        this.setState({
          indexCount: result.ext,
          appData: result.result ?? [],
          appDataView:result.result ?? [],
          visibleCount:pageSize,
          isLoading: false
        });
      }
    }
  };
  appDev=(data:any)=>{
    let menu:any=this.returnAction(data,false);
    if(menu.length>0){
      this.onMenuClick({key:"AppDev"}, data);
    }
  }
  getMenuIcon=(item:any)=>{
    let key:any=item.code;
    let icon:any=null;
    switch (key) {
      case 'AppInfo':
        icon = <AppstoreOutlined />;
        break;
      case 'AppUserManage':
        icon = <TeamOutlined />;
        break;
      case 'OnlineModeling':
        icon = <ConsoleSqlOutlined />;
        break;
      case 'ServiceDesign':
        icon = <ApiOutlined/>;
        break;
      case 'AppDeploy':
        icon = <LaptopOutlined />;
        break;
      case 'AppDelete':
        icon = <DeleteOutlined style={{color:"#FF0000"}}/>;
        break;
      default:
        icon = null;
    }
    return icon;
  }

  returnAction = (data: any,isMenu:boolean=true) => {
    let res: any = [];
    if (!_.isEmpty(data.sysMenus)) {
      let _m: any = [];
      _.each(data.sysMenus, (item: any, idx: integer) => {
        _m.push({
          key: item.code,
          label:item.code=="AppDelete"?<><span className="x-application-item-bottom-action-delete" style={{color:"#FF0000"}}>{item.name}</span></>: item.name,
          icon:this.getMenuIcon(item)
        });
      })
      res = _m;
    }
    if(isMenu){
      res=res.filter((item:any)=>{
        return item.key!="AppDev";
      })
    }
    else{
      res=res.filter((item:any)=>{
        return item.key=="AppDev";
      })
    }
    return res;
  }
  appConfigClick = async (item: any) => {
    let result: any = await Api.get(this.apiPost, {id: item.id});
    let defaultSubAppId = result.result[0]['sysApps'][0]['id'];
    localStorage.setItem(this.props.userId+'_appId', defaultSubAppId);
    this.setEditUrl(item.id, () => {
      history.push(`${routePath}/ApplicationConfig`)
    })
  }

  appService = async (item: any) => {
    let result: any = await Api.get(this.apiPost, {id: item.id});
    let defaultSubAppId = result.result[0]['sysApps'][0]['id'];
    let result1: any = await Api.get(this.apiGetApplication, {id: defaultSubAppId});
    localStorage.setItem(this.props.userId+'_appId', defaultSubAppId);
    this.setEditUrl(item.id, () => {
      history.push(`${routePath}/AppServicesManager`)
    })
  }

  appModel = async (item: any) => {
    let result: any = await Api.get(this.apiPost, {id: item.id});
    let defaultSubAppId = result.result[0]['sysApps'][0]['id'];
    localStorage.setItem(this.props.userId+'_appId', defaultSubAppId);
    this.setEditUrl(item.id, () => {
      history.push(`${routePath}/Modeling`)
    })
  }

  toTask = (type: any) => {
    history.push(`${routePath}/MyTask?type=${type}`);
  }
  menuClick=(e: any)=>{
    e.stopPropagation();
  }
  onMenuClick = (e: any, item: any) => {
    let key = e.key;
    localStorage.setItem(this.props.userId+'_projectId', item.id);
    localStorage.setItem(this.props.userId+'_projectName', item.name);
    localStorage.setItem(this.props.userId+'_tenant', item.tenant);
    localStorage.setItem(this.props.userId+'_tenantId', item.tenantId);
    localStorage.setItem(this.props.userId+'_P_adminAble', item.currentUserAdminFlag);

    if (key == "AppDev") {
      //页面开发
      this.editApp(item.id);
    }
    if (key == "AppConfig") {
      //应用设置
      this.appConfigClick(item);
    }
    if (key == "ServiceDesign") {
      //服务编排
      this.appService(item);
    }
    if (key == "OnlineModeling") {
      //业务建模
      this.appModel(item);
    }
    if (key == "AppUserManage") {
      //成员管理
      this.setEditUrl(item.id, () => {
        history.push(`${routePath}/MembersManager`)
      })
    }
    if (key == "AppInfo") {
      //项目及应用维护
      this.showModal(2, item.id);
    }
    if (key == "AppCopy") {
      //应用维护
      message.success("应用复制");
    }
    if (key == "AppExport") {
      //应用维护
      message.success("应用导出");
    }
    //删除项目
    if (key == "AppDelete") {
      this.showDeleteModal(item);
    }
    if (key == "TaskAction") {
      //我的任务
      this.setEditUrl(item.id, () => {
        history.push(`${routePath}/MyTask?projectId=${item.id}`)
      })
    }
    //应用发布
    if (key == "AppDeploy") {
      this.setEditUrl(item.id, () => {
        history.push(`${routePath}/AppDeploy`);
      })
      localStorage.setItem(this.props.userId+'_appId', item.sysApps?.[0].id);
    }
  };
  setEditUrl = async (id: any, cb?: (url?: string) => void) => {
    let result: any = await Api.get(this.apiPost, { id });
    let userId = this.props.userId;

    let cookieAppId = getCookie("appId_" + id + "_" + userId);

    let defaultSubAppId = result.result[0]['sysApps'][0]['id'];
    let tenant = result.result[0]['sysApps'][0]['tenant'];

    if ( cookieAppId != null ) {
      let apps = result.result[0]['sysApps'].filter((item: any) => {
        return item.id == cookieAppId
      });

      if (apps.length > 0) {
        defaultSubAppId = cookieAppId;
        tenant = apps[0]['tenant'];
      }
    }

    localStorage.setItem(this.props.userId+'_tenant', tenant);
    let resultPage: any = await Api.get(this.apiGetPage, {
      appId: defaultSubAppId
    });

    let pageIdTemp = resultPage.result[0]['id'];
    let pageNameTemp = resultPage.result[0]['slug'];

    let cookiePageId = getCookie("pageId_" + defaultSubAppId + "_" + userId);

    if (cookiePageId != null) {
      let pages = resultPage.result.filter((item: any) => {
        return item.id == cookiePageId
      });

      if (pages.length > 0) {
        pageIdTemp = cookiePageId;
        pageNameTemp = pages[0]['slug'];
      }
    }

    let defaultPage = {
      pageId: pageIdTemp,
      pageName: pageNameTemp//['name']
    }
    if (result.success && resultPage.success) {
      setCookie("pageId_" + defaultSubAppId + "_" + userId, pageIdTemp);
      setCookie("appId_" + id + "_" + userId, defaultSubAppId);
      let prefix_url = `${apiPath}/platform/switchTenant`;
      let switchTenant: any = await Api.get(prefix_url, { tenant });
      let edit_url = `${routePath}/app/${defaultSubAppId}/${defaultPage.pageName}-${defaultPage.pageId}/edit`;
      this.props.setEditUrl(edit_url, defaultSubAppId);
      localStorage.setItem(this.props.userId+'_projectId', id);
      localStorage.setItem(this.props.userId+'_appId', defaultSubAppId);
      localStorage.setItem(this.props.userId+'_editUrl', edit_url);
      cb && cb(edit_url);
    }
  }

  deleteApp = async (projectId: string) => {
    let result: any = await Api.delete(this.apiPost, [projectId]);
    if (result.success) {
      await this.refreshTable();
      message.success("删除成功!");
      this.Cancel();
    }
  };
  editApp = (projectId: string) => {
    this.setEditUrl(projectId, (url?: string) => {
      url && history.push(url);
    })
  }

  handleRecordRemoval = async (remove:any, index:any) => {
    try {
      let j=this.formRef.current?.getFieldValue(`sysApps`)[index];
      if(j&&j.appType&&j.appType=="IntegrationApp"){
        message.warn("该应用为整合应用，不允许删除！");
        return false;
      }
      else{
        remove(index);
      }
    } catch (errorInfo) {
      // 在校验失败时的处理逻辑
      console.log('校验失败:', errorInfo);
    }
  };

  showImportProject = () => {
    this.importProjectModalRef.current?.showModal();
  }

  render() {
    const { appDataView, visibleCount } = this.state;
    // 渲染当前可见的元素
    const visibleItems = appDataView.slice(0, visibleCount);
    // 渲染加载更多按钮（如果未显示所有元素）
    const showLoadMore = visibleCount < appDataView.length;

    return (
      <div className="x-main-box x-main-appList">
        <Modal className="x-app-delete" title={this.state.formCfg.title} open={this.state.formCfg.isShow}
               onOk={() => this.showDeleteModal} onCancel={() => this.Cancel()} footer={[]}>
          <Row className="x-mb20">
            <Col className="gutter-row" md={24}>
              <span className="x-bold">注意：删除项目会连同项目下的应用及应用下所有的配置与数据一并删除，删除后可在项目回收站中恢复。</span>
            </Col>
            <Col className="gutter-row" md={24}>
              <span className="x-bold">请确认所有项目成员都不再需要此项目后，再执行此操作！</span>
            </Col>
          </Row>
          <Row>
            <Col className="gutter-row" md={24}>

              <Form style={{}} ref={this.formDeleteRef} layout={"vertical"} onFinish={this.deleteForm}>

                <Form.Item label="请输入项目名称，表示您确认删除该项目" name="name"
                           rules={[{required: true, message: "请输入项目名称!"}]}>
                  <Input placeholder="请输入项目名称" maxLength={200}/>
                </Form.Item>
                <div style={{width: "100%", textAlign: "right"}}>
                  <Button onClick={() => this.Cancel()}>取消</Button>
                  <Button loading={this.state.delLoading} className="x-ml10" type="primary" htmlType="submit">
                    确定删除
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={(this.state.type == 1 ? "新建" : "编辑") + "项目"}
          width="1000px"
          key={this.state.key}
          style={{minWidth:"1000px"}}
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          maskClosable={false}
          // forceRender={true}
          destroyOnClose={true}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <Form
            name="basic"
            ref={this.formRef}
            labelCol={{span: 3}}
            wrapperCol={{span: 21}}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            className="z-main-app-form"
            initialValues={{
              sysApps: [], // 默认有一行
              sysApps_base: [{
                name: "",
                size: "web端(1440*1024px)",
                appType:"IntegrationApp"
              }], // 默认有一行
            }}
            autoComplete="off"
          >
            <Form.Item
              label="id"
              hidden={true}
              name="id"
              rules={[{required: false, message: "请输入!"}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="项目名称"
              name="name"
              rules={[{required: true, message: "请输入!"}]}
            >
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item
              label="项目说明"
              name="description"
              rules={[{required: false, message: "请输入!"}]}
            >
              <TextArea showCount maxLength={80} style={{height: 80}}/>
            </Form.Item>

            <Tabs defaultActiveKey="1">
              <Tabs.TabPane style={{position:"relative"}} tab="基础应用" key="1">
                <span style={{position:"absolute", top:-48,left:70,color:"gray",fontSize:"12px"}}>默认为web端应用，且包含了后台服务</span>
                <Form.List name="sysApps_base">
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field) => (
                        <Space key={field.key} align="baseline">
                          {initValues1.map((item) => (
                            <Form.Item
                              {...field}
                              label={item.label}
                              {...formItemLayout}
                              name={[field.name, item.name]}
                              rules={initRules}
                              initialValue={item.initValue}
                            >
                              {item.name === "name" ? (
                                <Input  maxLength={50}  style={{width: 952}}/>
                              ) : null
                              }
                            </Form.Item>
                          ))}
                        </Space>
                      ))}
                    </>
                  )}
                </Form.List>
                <p className="z-smith-label"  style={{marginTop:"10px"}}>模块安装</p>
                <Form.Item
                  label="默认安装模块"
                  name="moduleIds1"
                  labelCol={{span:24}}
                  style={{marginTop:"0px",fontWeight:"bold",paddingLeft:"50px"}}
                  rules={[{required: false, message: "请输入!"}]}
                >
                  <Checkbox.Group
                    options={this.state.moduleDefaultList}
                    disabled
                />
                </Form.Item>
                <Form.Item
                  label="可选安装模块"
                  labelCol={{span:24}}
                  style={{fontWeight:"bold",paddingLeft:"50px"}}
                  name="moduleIds2"
                  rules={[{required: false, message: "请输入!"}]}
                >
                  <Checkbox.Group
                    options={this.state.moduleChooseListEdit}
                />
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="多端应用" key="1">
              <span style={{position:"absolute", top:-48,left:70,color:"gray",fontSize:"12px"}}>和基础应用共用后台服务</span>
                <Form.List name="sysApps">
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field) => (
                        <Space key={field.key} align="baseline">
                          {initValues1.map((item) => (
                            <Form.Item
                              {...field}
                              label={item.label}
                              {...formItemLayout}
                              name={[field.name, item.name]}
                              rules={initRules}
                              initialValue={item.initValue}
                            >
                              {item.name === "name" ? (
                                <Input  maxLength={50}  style={{width: 455}}/>
                              ) : null
                              }
                            </Form.Item>
                          ))}
                          {initValues2.map((item) => (
                            <Form.Item
                              {...field}
                              label={item.label}
                              {...formItemLayout}
                              name={[field.name, item.name]}
                              rules={initRules}
                              initialValue={item.initValue}
                            >
                              {item.name === "displayType" ? (
                                <Select
                                  style={{width: 455}}
                                  onChange={handleChange}
                                  options={sizeOption}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <MinusCircleOutlined
                            style={{color:"#FF0000",position:"relative",bottom:"-35px"}}
                            onClick={() => this.handleRecordRemoval(remove, field.name)}
                          />
                        </Space>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          style={{width: "925px"}}
                          onClick={() => add()}
                          block
                          icon={<PlusCircleOutlined />}
                        >
                          添加多端应用
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Tabs.TabPane>
            </Tabs>

            {/* <Form.Item wrapperCol={{offset: 8, span: 16}}>

            </Form.Item> */}
            <div style={{width: "100%", textAlign: "center"}}>
              <Button type="primary" loading={this.state.addLoading} htmlType="submit">
                保存
              </Button>
              <Button className="x-ml10" onClick={this.handleCancel}>取消</Button>
            </div>
          </Form>
        </Modal>
        <Row gutter={20} className="c-edit1">
          <Col className="gutter-row" md={6}>
            <div className="x-application-box">
              <Card bordered={true} hoverable={true}>
                <div className="clear y-center">
                  <div className="clear">
                    <Row>
                      <Col span={18}>
                        <Space align="center" className="x-vcenter" style={{height: "100%"}}>
                          {app_svg}
                          <span className="x-f16 x-ml10 x-mt10">我的项目</span>
                        </Space>
                      </Col>
                      <Col span={6}>
                        <Space align="center" className="x-vcenter x-fr">
                          <span className="x-f30 x-bold x-vcenter x-fr">{this.state.indexCount.myAppNum}</span>
                        </Space>
                      </Col>
                    </Row>

                  </div>
                </div>
              </Card>
            </div>
          </Col>
          <Col className="gutter-row" md={6}>
            <div className="x-application-box">
              <Card bordered={true} hoverable={true} onClick={() => {
                this.toTask("c")
              }}>
                <div className="clear y-center">
                  <div className="clear">
                    <Row>
                      <Col span={18}>
                        <Space align="center" className="x-vcenter" style={{height: "100%"}}>
                          {/* <img src={app_task} alt="" /> */}
                          {app_task}
                          <span className="x-f16 x-ml10">我的任务</span>
                        </Space>
                      </Col>
                      <Col span={6}>
                        <Space align="center" className="x-vcenter x-fr">
                          <span className="x-f30 x-bold x-vcenter x-fr">{this.state.indexCount.myTaskNum}</span>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </div>
          </Col>
          <Col className="gutter-row" md={6}>
            <div className="x-application-box">
              <Card bordered={true} hoverable={true} onClick={() => {
                this.toTask("b")
              }}>
                <div className="clear y-center">
                  <div className="clear">
                    <Row>
                      <Col span={18}>
                        <Space align="center" className="x-vcenter" style={{height: "100%"}}>
                          {app_notTask}
                          <span className="x-f16 x-ml10">未完成的任务</span>
                        </Space>
                      </Col>
                      <Col span={6}>
                        <Space align="center" className="x-vcenter x-fr">
                          <span className="x-f30 x-bold x-vcenter x-fr">{this.state.indexCount.noFinishTaskNum}</span>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </div>
          </Col>
          <Col className="gutter-row" md={6}>
          </Col>
        </Row>
        <Row gutter={100} className="x-mt20 x-mb20">
          <Col span={12}>
            <span className="x-title">我的项目</span>
            <Search placeholder="搜索项目" onSearch={this.onSearch} allowClear style={{ width: 250,marginLeft:"20px" }} />
          </Col>
          <Col span={12}>
            <Button className="x-fr" type="primary" icon={<PlusCircleOutlined/>} onClick={() => this.showModal(1)}>
              新建项目
            </Button>
            <Button style={{
                marginRight:"13px",
                display:'flex',
                alignItems:'center'
              }}
              className="x-fr" icon={getIconPark("Afferent")} onClick={() => this.showImportProject()}>
              导入应用
            </Button>
          </Col>
        </Row>
        <Row gutter={24} className="c-edit2" style={{marginRight:"0px"}}>
          {visibleItems.map((item: any) => (
            <Col className="gutter-row" lg={4} md={4} key={item.id}>
              <div style={{position:"relative"}} className="x-application-box x-application-item">
                <Dropdown.Button
                    onClick={(e)=>{this.menuClick(e)}}
                    icon={<img style={{zIndex:1}} src={more1} alt="" />}
                    style={{zIndex:9,position:"absolute",bottom:"0px",width:"0px",height:"0px",right:"0px"}}
                    className="x-application-item-bottom-action"
                        menu={{
                          items: this.returnAction(item,true), onClick: (e) => {
                            this.onMenuClick(e, item);
                          }
                        }}
                      />
                  <Card  hoverable={true} style={{zIndex:1}}  onClick={()=>{this.appDev(item)}}>
                    <img className="x-app1" src={img1} alt=""/>
                    <span className="x-application-item-title"> {item.name}</span>
                    <p className="x-application-item-center"> {_.isEmpty(item.description)?"暂无说明":cutString(item.description,30) }</p>
                    <span  className="x-application-item-bottom-count">{item.sysApps&&item.sysApps?.length}个应用</span>
                  </Card>
              </div>
            </Col>
          ))}
        </Row>
        {visibleItems.length==0 && (
          <Row gutter={24} >
            <Col className="gutter-row" lg={24} md={24} style={{textAlign:"center"}} >
              <Empty description={"没有数据"} />
            </Col>
        </Row>
        )}
        {showLoadMore && (
          <Row gutter={24} >
            <Col className="gutter-row" lg={24} md={24} style={{textAlign:"center"}} >
              <Button type="link" style={{fontWeight:"bold"}} onClick={this.loadMore}>加载更多</Button>
            </Col>
        </Row>
        )}

        <ImportProjectModal ref={this.importProjectModalRef} refreshTable={this.refreshTable}/>
      </div>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  userId: state.ui.users.currentUser?.id,
  projectId: state.XReducers.projectId
});
const mapDispatchToProps = (dispatch: any) => ({
  setEditUrl: (data: string, appId: string) => {
    dispatch({
      type: X_Edit_Url,
      data
    })
    dispatch({
      type: X_SUBAPPID,
      data: appId
    })
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Main);

