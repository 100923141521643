import {ReduxActionTypes} from "@appsmith/constants/ReduxActionConstants";
import {LayoutDirection, Positioning} from "utils/autoLayout/constants";
import {EventType} from "constants/AppsmithActionConstants/ActionConstants";
import {WIDGET_PADDING} from "constants/WidgetConstants";
import type {ValidationResponse} from "constants/WidgetValidation";
import {ValidationTypes} from "constants/WidgetValidation";
import {find} from "lodash";
import React from "react";
import {AppPositioningTypes} from "reducers/entityReducers/pageListReducer";
import type {WidgetProperties} from "selectors/propertyPaneSelectors";
import WidgetFactory from "utils/WidgetFactory";
import type {WidgetState} from "../../../widgets/BaseWidget";
import BaseWidget from "../../../widgets/BaseWidget";
import TabsComponent, {StepsComponentProps} from "../component";
import type {TabContainerWidgetProps, TabsWidgetProps} from "../constants";
import derivedProperties from "./parseDerivedProperties";
import type {Stylesheet} from "entities/AppTheming";
import {
  DefaultAutocompleteDefinitions,
  isAutoHeightEnabledForWidget,
  isAutoHeightEnabledForWidgetWithLimits,
} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import {PropertyPaneConfig} from "../../../constants/PropertyControlConstants";

export function selectedTabValidation(
  value: unknown,
  props: TabContainerWidgetProps,
): ValidationResponse {
  const tabs: Array<{
    label: string;
    id: string;
    description: string;
  }> = props.tabsObj ? Object.values(props.tabsObj) : props.tabs || [];
  const tabNames = tabs.map((i: { label: string; id: string }) => i.label);
  return {
    isValid: value === "" ? true : tabNames.includes(value as string),
    parsed: value,
    messages: [
      {
        name: "ValidationError",
        message: `Tab name ${value} does not exist`,
      },
    ],
  };
}

class TabsWidget extends BaseWidget<TabsWidgetProps<TabContainerWidgetProps>,
  WidgetState> {
  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return super.getWidgetEvents('StepsWidget');
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      },
      {
        sectionName: "数据",
        children: [
          {
            propertyName: "showTabDesc",
            label: "显示步骤条描述",
            controlType: "SWITCH",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "tabsObj",
            isJSConvertible: false,
            label: "",
            helpText: "",
            controlType: "TABS_INPUT",
            isBindProperty: false,
            isTriggerProperty: false,
            prefix: 'Step',
            updateRelatedWidgetProperties: (
              propertyPath: string,
              propertyValue: string,
              props: WidgetProperties,
            ) => {
              const propertyPathSplit = propertyPath.split(".");
              const property = propertyPathSplit.pop();
              if (property === "label") {
                const itemId = propertyPathSplit.pop() || "";
                const item = props.tabsObj[itemId];
                if (item) {
                  return [
                    {
                      widgetId: item.widgetId,
                      updates: {
                        modify: {
                          tabName: propertyValue,
                        },
                      },
                    },
                  ];
                }
              }
              return [];
            },
            panelConfig: {
              editableTitle: true,
              titlePropertyName: "label",
              panelIdPropertyName: "id",
              updateHook: (
                props: any,
                propertyPath: string,
                propertyValue: string,
              ) => {
                return [
                  {
                    propertyPath,
                    propertyValue,
                  },
                ];
              },
              children: [
                {
                  sectionName: "数据",
                  children: [
                    {
                      propertyName: "label",
                      label: "标题",
                      helpText: "",
                      controlType: "INPUT_TEXT",
                      placeholderText: "",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {type: ValidationTypes.TEXT},
                    },
                    {
                      propertyName: "description",
                      label: "描述",
                      helpText: "",
                      controlType: "INPUT_TEXT",
                      placeholderText: "",
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {type: ValidationTypes.TEXT},
                    },
                  ],
                },
                {
                  sectionName: "一般",
                  children: [
                    {
                      propertyName: "isVisible",
                      label: "是否可见",
                      helpText: "Controls the visibility of the tab",
                      controlType: "SWITCH",
                      useValidationMessage: true,
                      isJSConvertible: true,
                      isBindProperty: true,
                      isTriggerProperty: false,
                      validation: {type: ValidationTypes.BOOLEAN},
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "autoLoadData",
            label: "自动加载数据",
            helpText: "打开时，页面加载时自动加载所有标签页下的数据，关闭后，只加载选中的标签页下的数据",
            controlType: "SWITCH",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isVisible",
            label: "是否可见",
            helpText: "Controls the visibility of the widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "animateLoading",
            label: "加载动画",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "stepDirection",
            label: "步骤条方向",
            controlType: "ICON_TABS",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            fullWidth: true,
            options: [
              {
                label: "横向",
                value: "horizontal",
              },
              {
                label: "纵向",
                value: "vertical",
              },
            ],
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: ["horizontal", "vertical"],
              },
            },
          },
          {
            propertyName: "labelPlacement",
            label: "步骤数字位置",
            controlType: "ICON_TABS",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            fullWidth: true,
            options: [
              {
                label: "居上",
                value: "vertical",
              },
              {
                label: "居左",
                value: "horizontal",
              },
            ],
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: ["horizontal", "vertical"],
              },
            },
            hidden: ({stepDirection}: StepsComponentProps) => {
              return stepDirection === 'vertical';
            },
          },
        ],
      },
      {
        sectionName: "颜色",
        children: [
          {
            propertyName: "accentColor",
            helpText: "Sets the color of the selected tab's underline ",
            label: "强调色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Use a html color name, HEX, RGB or RGBA value",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "backgroundColor",
            label: "背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Use a html color name, HEX, RGB or RGBA value",
            placeholderText: "#FFFFFF / Gray / rgb(255, 99, 71)",
            propertyName: "borderColor",
            label: "边框颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "边框&阴影",
        children: [
          {
            helpText: "Enter value for border width",
            propertyName: "borderWidth",
            label: "边框宽度",
            placeholderText: "Enter value in px",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.NUMBER},
            postUpdateAction: ReduxActionTypes.CHECK_CONTAINERS_FOR_AUTO_HEIGHT,
          },
          {
            propertyName: "borderRadius",
            label: "圆角大小",
            helpText:
              "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",

            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
    ];
  }

  callDynamicHeightUpdates = () => {
    const {checkContainersForAutoHeight} = this.context;
    checkContainersForAutoHeight && checkContainersForAutoHeight();
  };

  callPositionUpdates = (tabWidgetId: string) => {
    const {updatePositionsOnTabChange} = this.context;
    updatePositionsOnTabChange &&
    updatePositionsOnTabChange(this.props.widgetId, tabWidgetId);
  };

  onTabChange = (tabWidgetId: string) => {
    const _str = super.reDynamicStringFn(this.props.onStepSelected as string);
    this.props.updateWidgetMetaProperty("selectedTabWidgetId", tabWidgetId, {
      triggerPropertyName: "onStepSelected",
      dynamicString: _str,
      event: {
        type: EventType.ON_TAB_CHANGE,
      },
    });
    setTimeout(this.callDynamicHeightUpdates, 0);
    setTimeout(() => this.callPositionUpdates(tabWidgetId), 0);
  };

  onFinish = () => {
    if (this.props.onSubmit) {
      this.setState({
        isLoading: true,
      });
      super.executeAction({
        triggerPropertyName: "onClick",
        dynamicString: this.props.onSubmit,
        event: {
          type: EventType.ON_SUBMIT,
        },
      });
    }
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  static getDerivedPropertiesMap() {
    return {
      selectedTab: `{{(()=>{${derivedProperties.getSelectedTab}})()}}`,
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      selectedTab: "string",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedTabWidgetId: undefined,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  getPageView() {
    const {leftColumn, parentColumnSpace, rightColumn} = this.props;

    const tabsComponentProps = {
      ...this.props,
      tabs: this.getVisibleTabs(),
      width:
        (rightColumn - leftColumn) * parentColumnSpace - WIDGET_PADDING * 2,
    };
    const isAutoHeightEnabled: boolean =
      isAutoHeightEnabledForWidget(this.props) &&
      !isAutoHeightEnabledForWidgetWithLimits(this.props);
    return (
      <TabsComponent
        {...tabsComponentProps}
        $noScroll={isAutoHeightEnabled}
        backgroundColor={this.props.backgroundColor}
        borderColor={this.props.borderColor}
        borderRadius={this.props.borderRadius}
        borderWidth={this.props.borderWidth}
        boxShadow={this.props.boxShadow}
        onTabChange={this.onTabChange}
        onFinish={this.onFinish}
        primaryColor={this.props.primaryColor}
        selectedTabWidgetId={this.getSelectedTabWidgetId()}
        shouldScrollContents={this.props.shouldScrollContents &&
        this.props.appPositioningType !== AppPositioningTypes.AUTO}
        selectedTab={this.props.selectedTab}
        showTabDesc={this.props.showTabDesc}
        stepDirection={this.props.stepDirection}
        labelPlacement={this.props.labelPlacement}
      >
        {this.renderComponent()}
      </TabsComponent>
    );
  }

  renderComponent = () => {
    const selectedTabWidgetId = this.getSelectedTabWidgetId();
    const childWidgetData = {
      ...this.props.children?.filter(Boolean).filter((item) => {
        return selectedTabWidgetId === item.widgetId;
      })[0],
    };
    if (!childWidgetData) {
      return null;
    }

    childWidgetData.canExtend = this.props.shouldScrollContents;
    const {componentHeight, componentWidth} = this.getComponentDimensions();
    childWidgetData.rightColumn = componentWidth;
    childWidgetData.isVisible = this.props.isVisible;
    childWidgetData.bottomRow = this.props.shouldScrollContents
      ? childWidgetData.bottomRow
      : componentHeight - 1;
    childWidgetData.parentId = this.props.widgetId;
    childWidgetData.minHeight = componentHeight;
    const selectedTabProps = Object.values(this.props.tabsObj)?.filter(
      (item) => item.widgetId === selectedTabWidgetId,
    )[0];
    const positioning: Positioning =
      this.props.appPositioningType == AppPositioningTypes.AUTO
        ? Positioning.Vertical
        : Positioning.Fixed;
    childWidgetData.positioning = positioning;
    childWidgetData.useAutoLayout = positioning !== Positioning.Fixed;
    childWidgetData.direction =
      positioning === Positioning.Vertical
        ? LayoutDirection.Vertical
        : LayoutDirection.Horizontal;
    childWidgetData.alignment = selectedTabProps?.alignment;
    childWidgetData.spacing = selectedTabProps?.spacing;

    return WidgetFactory.createWidget(childWidgetData, this.props.renderMode);
  };

  private getSelectedTabWidgetId() {
    let selectedTabWidgetId = this.props.selectedTabWidgetId;
    if (this.props.children) {
      selectedTabWidgetId =
        this.props.children.find((tab) =>
          this.props.selectedWidgetAncestry?.includes(tab.widgetId),
        )?.widgetId ?? this.props.selectedTabWidgetId;
    }
    return selectedTabWidgetId;
  }

  static getWidgetType(): string {
    return "X_STEP_WIDGET";
  }

  componentDidUpdate(prevProps: TabsWidgetProps<TabContainerWidgetProps>) {
    const visibleTabs = this.getVisibleTabs();
    const selectedTab = find(visibleTabs, {
      widgetId: this.props.selectedTabWidgetId,
    });

    if (this.props.defaultTab !== prevProps.defaultTab || !selectedTab) {
      this.setDefaultSelectedTabWidgetId();
    }
  }

  getVisibleTabs = () => {
    const tabs = Object.values(this.props.tabsObj || {});
    if (tabs.length) {
      return tabs
        .filter(
          (tab) => tab.isVisible === undefined || !!tab.isVisible === true,
        )
        .sort((tab1, tab2) => tab1.index - tab2.index);
    }
    return [];
  };

  setDefaultSelectedTabWidgetId = () => {
    const visibleTabs = this.getVisibleTabs();
    // Find the default Tab object
    const defaultTab = find(visibleTabs, {
      label: this.props.defaultTab,
    });
    // Find the default Tab id
    const defaultTabWidgetId =
      defaultTab?.widgetId ?? visibleTabs?.[0]?.widgetId; // in case the default tab is deleted

    // If we have a legitimate default tab Id and it is not already the selected Tab
    if (
      defaultTabWidgetId &&
      defaultTabWidgetId !== this.props.selectedTabWidgetId
    ) {
      // Select the default tab
      this.props.updateWidgetMetaProperty(
        "selectedTabWidgetId",
        defaultTabWidgetId,
      );
      setTimeout(this.callDynamicHeightUpdates, 0);
    }
  };

  componentDidMount() {
    Object.keys(this.props.tabsObj || {}).length &&
    this.setDefaultSelectedTabWidgetId();
  }
}

export default TabsWidget;
