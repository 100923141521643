import {DeleteOutlined, LoadingOutlined, PaperClipOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Popconfirm, message} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import Api from "@byk/api/Api";
import {uuid2, downLoadClick, cutString} from "@byk/utils/Utils";
import {apiPath} from '@byk/routes';
import {getTenant} from 'utils/localStorage';
import styled from 'styled-components';

const UploadBtnContainerDiv = styled.div`
  position: relative;
  height: 35px;
  width: 160px;
  display: flex;
  justify-content: center;
  border: none;
  box-shadow: 0 2px #00000004;

  input {
    width: 160px;
    height: 35px;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
`

const UploadFile = (props: any) => {
  const {onEmit, className, fileSize, ext, fileCount, btnText, accept, files, disabled, isReadonly, isByk} = props;
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState(files);
  const fileListRef = useRef(fileList);
  fileListRef.current = fileList;
  const [key, setKey] = useState(uuid2(16, 16));
  const keyRef = useRef(key);
  keyRef.current = key;

  const uploadChange = (ev: any) => {
    let files = ev.target.files;
    if (files[0]) {
      const isLt = files[0].size / 1024 / 1024 < fileSize;
      if (!isLt) {
        message.warning(`上传文件大小不能超过 ${fileSize}MB!`);
        return false;
      }
      uploadPic(files[0]);
    }
  };

  const deleteClick = (obj: any) => {
    let r = fileList.filter((item: any) => {
      return item.code != obj.code;
    })
    setFileList(r);
    onEmit && onEmit(r);
  }

  const uploadPic = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      let base64 = e.target['result'];
      base64 = base64.split('base64,')[1];
      let params = {
        data: base64,
        ext: file.name.substring(file.name.lastIndexOf('.')),
        filename: file.name,
        type: file.type,
      };
      setLoading(true);
      let scope = isByk ? 'platform' : getTenant();
      let url: any = `${apiPath}/${scope}/rest/SysFile`;
      Api.post(url, params).then((res: any) => {
        if (res.success) {
          let r: any = res.result[0];
          let a = [{code: r.code, filename: r.filename, url: `${apiPath}/${scope}/file/${r.code}`}]
          let b = [...files, ...a];
          setKey(uuid2(16, 16));
          setFileList(b);
          onEmit && onEmit(b);
        } else {
          message.error(res.message);
        }
      }).finally(() => {
        setLoading(false);
      });
    };
  };

  return (
    <div className={className} style={{width: "100%", height: "100%"}}>
      <UploadBtnContainerDiv style={{display: isReadonly ? "none" : "flex"}}>
        <Button
          disabled={(fileList.length >= fileCount) || disabled}
          style={{width: "100%", height: "100%"}}
          icon={<UploadOutlined/>}>
          {btnText}
        </Button>

        {loading &&
        <div style={{position: 'absolute', zIndex: 3, left: '50%', top: '50%', margin: '-10px 0 0 -10px'}}>
          <LoadingOutlined color={'#40a9ff'}/>
        </div>
        }
        <input
          key={key} disabled={(fileList.length >= fileCount) || disabled}
          accept={accept} type="file"
          onChange={uploadChange}
        />
      </UploadBtnContainerDiv>
      <ul className='x-file-ul'>
        {
          fileList.map((item: any) => {
            return <li className={"x-file-li"} style={{}} key={item.code}>
              <PaperClipOutlined style={{color: "#00000073"}}/>
              <span title={item.filename}
                    onClick={() => downLoadClick(item.url, item.filename)}>{cutString(item.filename, 40)}</span>
              <Popconfirm
                placement="right" title={"确认删除吗？"}
                onConfirm={() => deleteClick(item)} okText="确定"
                cancelText="取消">
                <Button className='x-file-delete' style={{
                  border: "none",
                  position: "absolute",
                  right: "0px",
                  display: isReadonly ? "none" : ""
                }} shape="circle" icon={<DeleteOutlined style={{color: "gray"}}/>}/>
              </Popconfirm>
            </li>;
          })
        }
      </ul>
    </div>
  );
};

export default UploadFile;
