import React, {useEffect, useState} from 'react';
import {AtCheckbox} from "taro-ui";

const TCheckboxWidget = (props:any) => {
  const {label, value:wValue, currentData, updateWidgetProperty} = props;
  const [value, setValue] = useState<any>([]);
  const onChange = (v:any)=>{
    setValue(v);
    updateWidgetProperty&&updateWidgetProperty(v);
  };
  useEffect(()=>{
    setValue(wValue);
  }, [wValue])
  return <AtCheckbox
    options={currentData}
    selectedList={value}
    onChange={onChange}
  />;
};

export default TCheckboxWidget;
