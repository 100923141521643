import React from "react";
import Head from "@byk/pages/components/Header";
import Navbar from "@byk/pages/components/Navbar";
import { Layout, Tabs, PageHeader, Tree, Row, Col, Space } from 'antd';
import BusinessPage from "@byk/pages/MyTask/main";
import { useLocation, useParams } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import Api from "@byk/api/Api";
import { DownOutlined } from '@ant-design/icons';
import {appTenantClass, cutString, truncateString, uuid2} from "@byk/utils/Utils";
import localStorage, {getProjectId, getTenantId} from "utils/localStorage";
import Icon from "@icon-park/react/es/all";
const { Content, Sider } = Layout;
const { TabPane } = Tabs;

class MyTask extends React.Component {
    childRef: React.RefObject<any>;
    constructor(props: any) {
        super(props);
        this.childRef = React.createRef();
    }

    state = {
        projData: [
        ],
        appData: [

        ],
        tenantId: getTenantId(),
        defaultKey: 0,
        appId:"",
        projectId:"",
        tabKey:"a",
        pageId:"",
        key:uuid2(16,16),
        cutSum:{
            "project":4,
            "application":2,
            "page":0,
            "group":0
        },
        source:this.props.location.pathname=="/MyTask"?"MyTask":"DevTask"
    };

    changeTable(data: any) {
        const type=data.type?.toLowerCase();
        let _appId="";
        let _projectId="";
        let _pageId="";
        //项目
        if(type=="project"&&data.id!=0){
            //_appId=data.id;
            _projectId=data.id;
        }
        //应用
        if(type=="application"&&data.id!=0){
            _appId=data.id;
            _projectId=data.projectId;
        }
        if(type=="page"){
            _appId=data.appId;
            _pageId=data.id;
            //_projectId=data.projectId;
            // if(!data.children){
            //     _appId="";
            //     _pageId=data.id;
            // }
        }
        this.setState({appId:_appId,projectId:_projectId,pageId:_pageId,key:uuid2(16,16)});
    };

    tabClick = (key: any) => {
        this.setState({ tabKey: key });
    };

    bindAppTree = async () => {
        let result: any = await Api.get("/byk/platform/app/getProjectAndAppInfo", { tenantId: this.state.tenantId });
        let projectId = getProjectId();
        if (result.success) {
            let _list = result.result;
            if(_list==null){
                _list=[];
            }
            if(this.state.source=="DevTask"){
                _list=_list.filter((itm:any)=>{
                    return itm.id==projectId;
                })
            }
            //_list.unshift({ id: 0, name: "全部应用", sysApps: [] });
            //console.log(result.result);
            const params = new URLSearchParams(window.location.search);
            let id = params.get("projectId");
            let _data:any={};
            if(this.state.source=="DevTask"){
                id=projectId;
                _data.projectId=projectId;
                _data.key=uuid2(16,16);
            }
            this.setState({...{ appData: _list, defaultKey: id != null ? parseInt(id) : 0 },..._data}, () => {
                if (id != null) {
                    this.childRef.callback({ id: id }, 1);
                }
                console.log("defaultKey",this.state.defaultKey)
            });
        }
    };
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        this.setState({tabKey: type==null?"a":type});
        localStorage.removeItem('FixedData');
        this.bindAppTree();
    }

    render() {
        const content:any=<>
            <Sider width={280} className="x-border-right" >
                                    <Tree
                                        style={{ marginTop: "30px" }}
                                        showLine
                                        showIcon
                                        key="key"
                                        blockNode={true}
                                        defaultExpandAll={true}
                                        defaultSelectedKeys={[this.state.defaultKey]}
                                        fieldNames={{ title: "name", key: "key", children: "children" }}
                                        autoExpandParent={true}
                                        treeData={this.state.appData}
                                        switcherIcon={<DownOutlined />}
                                        titleRender={(data: any) => {
                                            return (
                                                <div style={{ width: "100%" }}>
                                                    <Row style={{ position:"relative"}} onClick={() => { this.changeTable(data) }}>
                                                        <Col span={24}   >
                                                            <Space>
                                                                {
                                                                     <>
                                                                     <span style={{ display:"flex",justifyContent:"start",alignItems:"center" }}>
                                                                        {
                                                                            data.type=="project"&&(<Icon  type="AllApplication"/>)
                                                                        }
                                                                        {
                                                                            data.type=="application"&&(
                                                                                <Icon  type="WebPage"/>
                                                                                )
                                                                        }
                                                                        {
                                                                            ['page','Page','Group'].includes(data.type)&&( <Icon  type="Notes"/>)
                                                                        }
                                                                        &nbsp;<span title={data.name}>{truncateString(data.name, 9+this.state.cutSum[data.type?.toLowerCase()])}</span> </span>
                                                                     <span className="x-task-count">{data.taskCount}</span>
                                                                     </>
                                                                }
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        }}
                                    />
                                    {/* <Menu
                                        mode="inline" //导航栏列出来显示
                                        defaultSelectedKeys={['ApplicationConfig']}
                                        defaultOpenKeys={[]}
                                        selectedKeys={["ApplicationConfig"]}
                                        theme="light"
                                        id="navBarMenu"
                                    >
                                        {
                                            this.state.projData.map((item: any, i) => (
                                                <Menu.Item key={item.key} icon={<AppstoreOutlined />}>
                                                    {item.name}
                                                </Menu.Item>
                                            ))
                                        }
                                    </Menu> */}
            </Sider>
            <Content>
                <div className="x-main-box">
                    <Tabs activeKey={this.state.tabKey} size="middle" destroyInactiveTabPane={true} onTabClick={(key: any) => this.tabClick(key)}>
                        <TabPane tab="所有任务" key="a" forceRender={false}>
                            <BusinessPage onRef={node => this.childRef = node} key={this.state.key+"a"} type="a" projectId={this.state.projectId} appId={this.state.appId}  pageId={this.state.pageId}/>
                        </TabPane>
                        <TabPane tab="未完成" key="b" forceRender={false}>
                            <BusinessPage onRef={node => this.childRef = node} key={this.state.key+"b"} type="b" projectId={this.state.projectId} appId={this.state.appId}   pageId={this.state.pageId}/>
                        </TabPane>
                        <TabPane tab="指派给我" key="c" forceRender={false}>
                            <BusinessPage onRef={node => this.childRef = node} key={this.state.key+"c"} type="c"  projectId={this.state.projectId} appId={this.state.appId}   pageId={this.state.pageId}/>
                        </TabPane>
                    </Tabs>
                </div>
            </Content>
        </>
        return this.state.source=="DevTask"?(
            <>
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head title={this.state.source} />
                    <Layout>
                        <Content>
                            <Layout>
                                {content}
                            </Layout>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        </>
        ):
        (
            <div>
                <Layout>
                    {/*引入头部 */}
                    <Head title="MyTask" />
                    <Layout>
                        <Sider className="Sider-Navbar"><Navbar title="MyTask" /></Sider>
                        <Content>
                            <PageHeader
                                className="site-page-header"
                                title="我的任务"
                            />
                            <Layout>
                                {content}
                            </Layout>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
        ;
    }
}

export default withRouter(MyTask);
