import React, { useEffect, useState } from "react";
import type { ControlProps } from "./BaseControl";
import BaseControl from "./BaseControl";
import type { InputType } from "components/constants";
import { Input, Space, Tree } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons/lib";
import _ from "lodash";
import { uuid4 } from "@sentry/utils";
import { EditPropertyData } from "./BreadcrumbControl";



const TreeEdit = (props:any)=>{
  const {propertyValue, labelKey, labelValue, valueChange} = props;
  const [gData, setGData] = useState(propertyValue);
  const temp:any = {
    [labelKey]: '',
    [labelValue]: '',
  };
  const loop = (
    data: any,
    key: React.Key,
    callback: (node: any, i: number, data: any) => void,
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children!, key, callback);
      }
    }
  };
  const onAdd = (key?:any) => {
    const data:any = gData?_.cloneDeep(gData):[];
    if(key){
      loop(data, key, (item) => {
        const children = Array.isArray(item.children) ? [...item.children] : []
        children.push({
          key: uuid4(),
          pid: item.key,
          ...temp
        })
        item.children = children
      });
    }else{
      data.push({
        key: uuid4(),
        ...temp
      })
    }
    setGData(data);
  };
  const onDelete = (key:any) => {
    const data = gData?_.cloneDeep(gData):[];
    loop(data, key, (_, index, arr) => {
      arr.splice(index, 1);
    });
    setGData(data as any);
  };
  // input 改变
  const onChange = (e:any, key:any) => {
    const data:any = gData?_.cloneDeep(gData):[];
    loop(data, key, (item) => {
      item[labelKey] = e.target.value;
      item[labelValue] = e.target.value;
    });
    setGData(data);
  };
  useEffect(()=>{
    valueChange(gData);
  }, [gData]);
  return (
    <>
      <div style={{width:'100%', height: '10px', position:'relative'}} onClick={()=>{onAdd();}}>
        <PlusOutlined style={{position: 'absolute', right: 0, top:'-15px', cursor: 'pointer'}} />
      </div>
      <Tree
        defaultExpandAll={true}
        treeData={gData}
        titleRender={(nodeData:any)=>{
          return (
            <Space>
              <Input placeholder={'请输入'}
                     defaultValue={nodeData[labelKey]}
                     onChange={(e) => onChange(e, nodeData.key)} />
              <PlusOutlined onClick={() => onAdd(nodeData.key)} />
              <MinusOutlined onClick={() => onDelete(nodeData.key)} />
            </Space>
          )
        }}
      />
    </>

  )
}


class TreeEditControl extends BaseControl<TreeEditControlProps> {
  render() {
    return (
      <EditPropertyData propertyValue={this.props.propertyValue} valueChange={this.valueChange}>
        <TreeEdit {...this.props} valueChange={this.valueChange} />
      </EditPropertyData>

    );
  }
  valueChange = (result:any)=>{
    this.updateProperty(this.props.propertyName, result, true);
  }


  static getControlType() {
    return "TREE_EDIT_ARRAY";
  }
}

export interface TreeEditControlProps extends ControlProps {
  placeholderText: string;
  inputType: InputType;
  validationMessage?: string;
  isDisabled?: boolean;
  defaultValue?: any;
  size?: number,
  onFocus?: () => void;
  onBlur?: () => void;
}

export default TreeEditControl;
