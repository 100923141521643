import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/theme/ambiance.css';
import React, {forwardRef, useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {QueryEditorContext} from "@byk/pages/modeling/SqlQueryEditor/QueryEditorContext";

const ContainerDiv = styled.div`
  padding: 0px 24px;
`

const CodeMirrorWrapper = styled<any>(CodeMirror)`
  .cm-s-ambiance.CodeMirror {
    height: ${({codeMirrorHeight}) => codeMirrorHeight};
  }
`

const SqlCodeMirror: React.FC<any> = forwardRef((props, ref) => {
  const {sqlQueryEditorMode, getState, codeMirrorSqlRef} = useContext(QueryEditorContext);
  const {codeMirrorHeight, sqlInfo} = getState();
  const [sql, setSql] = useState<any>('');

  useEffect(() => {
    codeMirrorSqlRef.current = sqlInfo?.sql || '';
    setSql(codeMirrorSqlRef.current);
  }, [sqlInfo])

  const onChange = (editor: any, data: any, value: any) => {
    codeMirrorSqlRef.current = value;
  }

  const options = {
    lineWrapping: true,//自动换行，取消横向滚动条
    lineNumbers: true,//显示行号
    mode: {name: "text/x-mysql"},
    extraKeys: {"Ctrl": "autocomplete"},
    theme: 'ambiance',
    readOnly: false,
  };

  const onCursorActivity = (cm: any) => {
    if (!cm.getSelection()) {
      console.log(cm.getSelection()); // 获取到选中部分内容，用来实现执行部分内容
    }
  }

  const onInputRead = async (cm: any, change: any) => {
    const {text} = change;
    const dechars = [
      '.',
    ];
    const autocomplete = dechars.includes(text[0]);
    // 自动补全主要是依靠设置CodeMirror中的hintOptions来实现的，当然首先需要将依赖的hint包都引入。
    if (autocomplete) {
      cm.setOption('hintOptions', {
        tables: sqlQueryEditorMode.codeMirrorHintOptions,
        completeSingle: false,
        custom: {
          "DROP": null // 移除DROP命令
        }
      });
      cm.execCommand('autocomplete');
    } else if (text.length == 1) { // 复制多个字不提示
      cm.execCommand('autocomplete');
    }
  }

  let _codeMirrorHeight = '';
  if (typeof codeMirrorHeight == 'boolean') {
    _codeMirrorHeight = codeMirrorHeight ? 'calc(100vh - 216px)' : '300px';
  } else {
    _codeMirrorHeight = codeMirrorHeight + 'px';
  }

  return (
    <ContainerDiv>
      <CodeMirrorWrapper
        codeMirrorHeight={_codeMirrorHeight}
        value={sql}
        options={options}
        onChange={onChange} // 变化事件
        onCursorActivity={(cm: any) => onCursorActivity(cm)} // 用来完善选中监听
        onInputRead={(editor: any, changeObj: any) => onInputRead(editor, changeObj)} // 自动补全
      />
    </ContainerDiv>
  );
});

export default SqlCodeMirror;
