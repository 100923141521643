import {Layout, Modal} from "antd";
import React, {useRef, useState} from "react";
import TableList from "@byk/pages/components/TableList";
import {Content} from "antd/lib/layout/layout";
import {searchProps, tableProps} from "@byk/pages/InterFaceLog/config";
import SearchForm from "@byk/pages/components/SearchForm";
import styled from "styled-components";

const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }
`

const TaskLog = () => {
  const tableRef = useRef<any>();
  const searchRef = useRef<any>();
  const [msg, setMsg] = useState<any>();
  const [isOpen, setIsOpen] = useState<any>(false);

  function showMsg(record: any) {
    setMsg(record.message || '');
    setIsOpen(true);
  }

  // 表格refresh
  const doRefreshTable = (searchData?: any) => {
    tableRef.current?.getTableList({...searchData});
  }

  const handleCancel = () => {
    setIsOpen(false);
  }

  return (
    <>
      <Layout>
        <Content style={{
          padding: 24,
          margin: 0,
          height: "100%",
        }}>
          <div style={{marginTop: "8px"}}>
            <SearchForm ref={searchRef} {...searchProps(doRefreshTable)} />
            <TableList ref={tableRef} {...tableProps(showMsg)} />
          </div>
        </Content>
      </Layout>

      <ModalWrapper
        onCancel={handleCancel}
        open={isOpen}
        footer={null}
        maskClosable={false}
        title={"任务消息"}
        width={'60%'}
        bodyStyle={{
          padding: 5,
          height: 600,
          overflowY: 'auto',
        }}
      >
        <p style={{whiteSpace: 'pre-wrap'}}>{msg}</p>
      </ModalWrapper>
    </>
  )
}

export default TaskLog;
