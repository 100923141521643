import * as React from "react";
import type {ComponentProps} from "widgets/BaseComponent";
import styled from "styled-components";
import DefaultImage from "./DefaultImage";
import _ from "lodash";
import {previewImagePathFun} from "../../../utils/hooks/useUploadImageCode";

const WrapperImg = styled.img<{
  borderRadius: string;
  boxShadow?: string;
  objectFit: string;
}>`
  border-radius: ${({borderRadius}) => borderRadius};
  object-fit: ${({objectFit}) => objectFit};
  box-shadow: ${({boxShadow}) => `${boxShadow}`} !important;
  width:100%;
  height:100%;
  margin:0 auto;
`;
const MaskWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  color: #fff;
  background-color: rgba(0,0,0,.8);
`;
class ImageComponent extends React.Component<ImageComponentProps,
  {
    imageError: boolean;
    showImageControl: boolean;
    imageRotation: number;
  }> {
  constructor(props: ImageComponentProps) {
    super(props);
  }

  render() {
    return (
      <div onClick={this.props.onClick} style={{height:"inherit", position: 'relative'}}>
        {
          (_.isEmpty(this.props.value) ||
          (this.props.value as string) =='[]') &&
          <DefaultImage
            borderRadius={this.props.borderRadius}
            boxShadow={this.props.boxShadow}/>
        }
        {
          !_.isEmpty(this.props.value) &&
          this.props.value !='[]' &&
          <WrapperImg
            objectFit={this.props.objectFit || 'cover'}
            borderRadius={this.props.borderRadius}
            boxShadow={this.props.boxShadow}
            alt={this.props.widgetName}
            src={previewImagePathFun(this.props.value)}
          />
        }
        {
          this.props.isDescription &&
          <MaskWrapper>{this.props.description}</MaskWrapper>
        }

      </div>
    );
  }
}

export interface ImageComponentProps extends ComponentProps {
  value: string,
  uploadValue: any[];
  defaultImageUrl: string;
  isLoading: boolean;
  showHoverPointer?: boolean;
  maxZoomLevel: number;
  enableRotation?: boolean;
  enableDownload?: boolean;
  objectFit: string;
  isDescription?: boolean;
  description?: string;
  disableDrag: (disabled: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  borderRadius: string;
  boxShadow?: string;
}

export default ImageComponent;
