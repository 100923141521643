import {Table} from "antd";
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import Api from "@byk/api/Api";

export interface TableProps {
  columns: any,
  api: string,
  pagination?: any,
  initParams?: any,
  ref?: any,
  scroll?: any,
  callback?: (res?: any) => any,
  selectedIds: any,
  selectedDataCallback: (data: any, callback: any) => any,
  byIdsFlag: boolean,
  selectType: any,
}

const DmTable: React.FC<TableProps> = forwardRef((
  {
    columns,
    api,
    initParams,
    pagination,
    scroll,
    callback,
    selectedIds,
    selectedDataCallback,
    byIdsFlag,
    selectType,
  }, ref) => {
  useImperativeHandle(ref, () => ({
    getTableList,
    setInitParams,
    cleanData,
    setSearchInIds,
    getTableData,
  }));
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initParamsNew, setInitParamsNew] = useState({});
  const [paginationProps, setPaginationProps] = useState<any>({
    current: pagination ? pagination.currPage : 1,//当前页
    pageSize: pagination ? pagination.pageSize : 10,//每页条数
    onChange: (page: number) => handlePageChange(page), //改变页码的函数
    hideOnSinglePage: false,
    showSizeChanger: false,
  });
  const inIdsRef = useRef<any>();

  const setInitParams = (_data: any) => {
    setInitParamsNew(_data);
  }

  const setSearchInIds = (ids: any) => {
    inIdsRef.current = ids
  }

  //查询
  const getTableList = async (params?: any, paginationParams?: any) => {
    let _initParams = initParams;
    let _params = {
      ...params,
      ..._initParams,
      currPage: pagination.currPage,
      pageSize: pagination.pageSize,
      tenantData: true,
    };
    if (paginationParams) {
      _params = {
        ..._params,
        ...paginationParams,
      };
    } else {
      _params.currPage = 1;
    }

    let inIds = inIdsRef.current;
    // byIdsFlag 标记必须要有id作为查询参数
    if (byIdsFlag) {
      if (!inIds || inIds.length == 0) {
        setTableData([])
        setPaginationProps({});
        return
      }
    }

    setLoading(true);
    let reqParams: any = {}
    if (byIdsFlag) {
      let idsStartIdx = (_params.currPage - 1) * _params.pageSize
      let idsEndIdx = idsStartIdx + _params.pageSize
      idsEndIdx = inIds.length < idsEndIdx ? inIds.length : idsEndIdx
      _params.currPage = 1 // 根据ID查询，已计算分页的的id了，
      reqParams = {
        id: inIds.slice(idsStartIdx, idsEndIdx),
        ..._params,
        exp: 'in'
      }
    } else {
      reqParams = {..._params}
    }

    let result: any = await Api.get(api, reqParams);
    setLoading(false);
    if (!result.success) {
      setTableData([])
      return;
    }

    if (callback != undefined) {
      callback(result);
    }

    //tableList 数据
    setTableData(result.result.map((item: any) => {
      return {
        ...item,
        key: item.id,
      };
    }));

    // 翻页数据
    let _pageParams = {
      ...pagination,
      queryParams: params,
      ...paginationProps,
      total: byIdsFlag ? inIds.length : result.size,
      showTotal: () => (
        <span>总共{result.size}项</span>
      ),
    };

    if (paginationParams) {
      _pageParams = {
        ..._pageParams,
        ...paginationParams,
        current: paginationParams.currPage,
      };
    } else {
      _pageParams.current = 1;
    }
    setPaginationProps(() => (_pageParams));
  };

  // 改变页码的回调 page代表页码数 pageSize代表每页条数
  const handlePageChange = (page: number) => {
    getTableList && getTableList({
      ...pagination.queryParams,
    }, {
      currPage: page,
    });
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(selectedIds || []);
  const setSelectedRowKeysCallback = (ids: any) => {
    setSelectedRowKeys(ids)
  }
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    selectedDataCallback(selectedRows, setSelectedRowKeysCallback)
  }

  const cleanData = () => {
    setTableData([])
  }

  const getTableData = () => {
    return tableData
  }

  return (
    <Table
      columns={columns}
      bordered={true}
      loading={loading}
      scroll={scroll}
      dataSource={tableData}
      pagination={paginationProps}
      rowSelection={{
        type: selectType || 'checkbox',
        selectedRowKeys,
        onChange: onSelectChange,
      }}
    />
  );
});

export default DmTable;
