import React from "react";
import {Breadcrumb} from "antd";
import {useSelector} from "react-redux";
import {getMenus} from "../../../selectors/editorSelectors";
import _ from "lodash";
import styled from "styled-components";
import { FontStyleTypes } from "design-system-old";

const _menuTreeItem = (item: any, menuKeyMap: any) => {
  menuKeyMap[item.menuKey] = item;
  if (item.children && item.children.length > 0) {
    item.children.forEach((cItem: any) => {
      _menuTreeItem(cItem, menuKeyMap)
    })
  }
}
const DivWrapper = styled.div<{
  fontColor1?: string,
  fontSize1?: string,
  fontStyle1?: string,
  fontColor2?: string,
  fontSize2?: string,
  fontStyle2?: string,
  separatorColor?: string,
}>`
  .z-Breadcrumb1{
    color: ${({fontColor1}) => fontColor1}  !important;
    font-style: ${({fontStyle1}) => fontStyle1?.includes(FontStyleTypes.ITALIC) ? "italic" : ""}  !important;
    font-weight: ${({fontStyle1}) => fontStyle1?.includes(FontStyleTypes.BOLD) ? "BOLD" : ""}  !important;
    font-size: ${({fontSize1}) => fontSize1}  !important;
  }
  .z-Breadcrumb0{
    color: ${({fontColor2}) => fontColor2}  !important;
    font-style: ${({fontStyle2}) => fontStyle2?.includes(FontStyleTypes.ITALIC) ? "italic" : ""}  !important;
    font-weight: ${({fontStyle2}) => fontStyle2?.includes(FontStyleTypes.BOLD) ? "BOLD" : ""}  !important;
    font-size: ${({fontSize2}) => fontSize2}  !important;
  }
  .ant-breadcrumb-separator{
    color: ${({separatorColor}) => separatorColor}  !important;
  }
`;

function BreadcrumbComponent2(props: any) {
  let {currentData = [], onClickItem,
    fontColor1,
    fontSize1,
    fontStyle1,
    fontColor2,
    fontSize2,
    fontStyle2,
    separatorColor} = props;
  const menus: any = useSelector(getMenus);

  let menuKey = currentData && currentData.length > 0 && currentData[0];
  let menuKey2 = currentData && currentData.length == 2 && currentData[1];
  let breadCrumbData: any = [];

  if (menuKey) {
    let menuKeyMap: any = {};
    menus.forEach((item: any) => {
      if (item.key != 'other') {
        _menuTreeItem(item, menuKeyMap);
      }
    })

    let mData = menuKeyMap[menuKey];
    if (mData) {
      breadCrumbData.push(mData);
      while (mData.pid) {
        mData = menuKeyMap[mData.pid];
        breadCrumbData.push(mData);
      }
    }

    _.reverse(breadCrumbData);
    menuKey2 && breadCrumbData.push({title: menuKey2, menuKey: menuKey2})
  }

  if (!breadCrumbData || breadCrumbData.length == 0) {
    breadCrumbData = [
      {title: 'Home'}, {title: 'List'}
    ]
  }

  const toPage = (nodeData: any) => {
    onClickItem && onClickItem(nodeData);
  }

  return (
    <DivWrapper
    fontColor1={fontColor1}
    fontSize1={fontSize1}
    fontStyle1={fontStyle1}
    fontColor2={fontColor2}
    fontSize2={fontSize2}
    fontStyle2={fontStyle2}
    separatorColor={separatorColor}
    >
      <Breadcrumb>
        {
          breadCrumbData && breadCrumbData.map((i: any,idx:any) => {
            return <Breadcrumb.Item key={i.menuKey}>
              <span onClick={() => toPage(i)} className={"z-Breadcrumb"+idx}>{i.title}</span>
            </Breadcrumb.Item>
          })
        }
      </Breadcrumb>
    </DivWrapper>
    
  )
}

export default BreadcrumbComponent2;
