import React, {useEffect, useRef, useState} from "react";
import type {ControlProps} from "./BaseControl";
import BaseControl from "./BaseControl";
import styled from "styled-components";
import {DraggableList} from "design-system-old";
import {Input, Popconfirm} from "antd";
import _ from "lodash";
import {Button, Icon} from "design-system";
import {cellDefault} from "../../widgetsExtend/TableWidget";

const TableColumnsWrapper = styled.div`
  width: 256px;
`;

const StyledIcon = styled(Icon)`
  cursor: move;
  z-index: 1;
  margin-left: 4px;
`;

const ItemWrapper = styled.div<any>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--ads-v2-color-border-emphasis);
  border-radius: 5px;
  height: 36px;

  input, input:focus {
    border: none;
    box-shadow:none;
    flex: 1;
    margin-left: -12px;
    position: relative;
    top: 0;
  }

  ${props => props.focused && `
    border: 1px solid var(--ads-v2-color-border-emphasis-plus);
  `}
`;

function defaultColumn(columnList: any, propertyName: string) {
  let len = 1;
  let str = 'column';
  while (_.find(columnList, {id: `${str}${len}`})) {
    len += 1;
  }
  let column = {
    id: `${str}${len}`,
    index: columnList.length,
    label: `${str}${len}`,
    columnType: propertyName == 'tableAction' ? "button" : "text",
    isCellDisable: false,
    isCellVisible: true,
    isCellRequired:true,
  };
  columnList.push(column);
  return columnList;
}

const DraggableListCard = (props: any) => {
  const {onEdit, onDelete, onChangeColumn, item, focusedIndex, setFocusedIndex} = props;
  const [inputValue, setInputValue] = useState(item.label);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<any>();

  useEffect(() => {
    setFocused(item.index == focusedIndex)
    if (item.index == focusedIndex) {
      inputRef?.current.focus();
    }
  }, [item])

  const inputChange = (ev: any) => {
    setInputValue(ev.target.value);
  }

  const inputBlur = () => {
    if (inputValue !== item.label) {
      onChangeColumn(item.id, 'label', inputValue);
    }
    setFocused(false)
  }

  const inputOnFocus = () => {
    setFocused(true)
    setFocusedIndex(item.index)
  }

  return (
    <ItemWrapper focused={focused}>
      <StyledIcon name="drag-control" size="md"/>
      <Input ref={inputRef}
             value={inputValue}
             onChange={inputChange}
             onBlur={inputBlur}
             onFocus={inputOnFocus}
      />
      <Button
        className="t--edit-column-btn"
        isIconButton
        kind="tertiary"
        onClick={() => {
          onEdit && onEdit(item.id);
        }}
        onFocus={(e) => e.stopPropagation()}
        size="sm"
        startIcon="settings-2-line"
      />
      <Popconfirm
        placement="topRight"
        title="确认删除吗？"
        onConfirm={()=>{
          onDelete && onDelete(item.id);
        }}
        okText="确认"
        cancelText="取消"
      >
      <Button
        className="t--delete-column-btn"
        isIconButton
        kind="tertiary"
        size="sm"
        startIcon="delete-bin-line"
      />
      </Popconfirm>
    </ItemWrapper>
  )
}


const TableColumns = (props: any) => {
  const {propertyValue, propertyName, onBatchUpdateProperties, openNextPanel, widgetProperties} = props;
  //列数据处理
  const [columnsList, setColumnsList] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState<any>(-1);

  const onEdit = (id: number) => {
    const originalColumn: any = _.find(columnsList, {id});
    openNextPanel({
      ...originalColumn,
      propPaneId: widgetProperties.widgetId,
    });
  };
  const batchUpdateProperties = (modify: any) => {
    let newModify: any = {};
    _.each(modify, (i: any, idx: number) => {
      newModify[i.id] = {
        ...i,
        index: idx
      }
    })
    onBatchUpdateProperties({
      [propertyName]: newModify
    });
  }
  const onDelete = (id: string) => {
    let cloneColumnList: any = _.clone(columnsList);
    cloneColumnList = _.filter(cloneColumnList, (i: any) => i.id != id);
    batchUpdateProperties(cloneColumnList);
  }
  const onAdd = () => {
    let cloneColumnList = _.clone(columnsList);
    let newColumnList = defaultColumn(cloneColumnList, propertyName);
    setFocusedIndex(newColumnList.length - 1);
    batchUpdateProperties(newColumnList);
  }
  const onChangeColumn = (id: string, key: string, value: any) => {
    let cloneColumnList = _.clone(columnsList);
    const index = _.findIndex(cloneColumnList, (i: any) => i.id == id)
    _.set(cloneColumnList, `[${index}].${key}`, value);
    batchUpdateProperties(cloneColumnList);
  }
  const onUpdate = (
    itemsOrder: number[],
    originalIndex: number,
    newIndex: number,
  ) => {
    let cloneColumns = Object.values(propertyValue);
    cloneColumns.splice(newIndex, 0, ...cloneColumns.splice(originalIndex, 1));
    setFocusedIndex(newIndex);
    batchUpdateProperties(cloneColumns)
  };

  const updateDragging = (isDragging: boolean) => {
  };

  useEffect(() => {
    let _data: any = _.sortBy(Object.values(propertyValue), ['index']).map((i: any) => {
      return {
        ...cellDefault,
        ...i
      }
    });
    setColumnsList(_data);
  }, [propertyValue])

  return (
    <TableColumnsWrapper>
      <span><span style={{fontWeight: "bold"}}>{columnsList?.length}</span>列</span>
      <DraggableList
        ItemRenderer={(props: any) =>
          DraggableListCard({
            ...props,
            onEdit,
            onDelete,
            onChangeColumn,
            focusedIndex,
            setFocusedIndex,
          })
        }
        fixedHeight={370}
        itemHeight={45}
        focusedIndex={focusedIndex}
        items={columnsList}
        keyAccessor={'id'}
        onUpdate={onUpdate}
        shouldReRender={false}
        updateDragging={updateDragging}
      />
      <div style={{textAlign: 'right'}}>
        <Button
          className="self-end t--add-column-btn"
          kind="tertiary"
          onClick={onAdd}
          size="sm"
          startIcon="plus"
        >
          添加新列
        </Button>
      </div>
    </TableColumnsWrapper>

  )
}


class TableColumnsControl extends BaseControl<TableColumnsControlProps> {
  render() {
    return <TableColumns {...this.props} />;
  }

  static getControlType() {
    return "TABLE_COLUMNS";
  }
}

export interface TableColumnsControlProps extends ControlProps {
  placeholderText?: string,
  selectId?: any
}

export default TableColumnsControl;
