import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, DatePicker, Form, Input, InputNumber, Select,} from "antd";
import {excludesRelProperties} from "@byk/pages/modeling/ModelData/config";
import {isDateType, isUploadFileType} from "@byk/pages/modeling/ModelDataUtil";
import moment from "moment";
import Api from "@byk/api/Api";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../../../utils/localStorage";
import TextArea from "antd/es/input/TextArea";
import DmdModal from "@byk/pages/modeling/ModelData/DmdModal";
import UploadFileCmp from "@byk/pages/modeling/components/UploadFileCmp";

const labelCol = {span: 6}

const getSysDataDictItemAsync = async (dictName: any) => {
  let options: any = [];
  if (!dictName) {
    return options;
  }

  let dictResult: any = await Api.get(`${apiPath}/${getTenant()}/subapp/SysDataDict/list`, {
    name: dictName,
    enable: true
  });

  if (dictResult.success && dictResult.result && dictResult.result.length == 1) {
    let dictId = dictResult.result[0].id;
    let res: any = await Api.get(`${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`, {
      dict: dictId,
      asc: "sort,createDate",
      enable: true
    });

    if (res.success) {
      res.result.forEach((i: any) => {
        options.push({
          id: i.id,
          label: i.name,
          value: i.code
        });
      })
    }
  }

  return options;
}

const UiForm =forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    getUploadFiles,
  }));

  const {model, modelData, modelByName, clickTime} = props;
  const dmdModelRef = useRef<any>();
  const [dictMap, setDictMap] = useState<any>({
    'bool': [{'label': '真', 'value': 'true'}, {
      'label': '假',
      'value': 'false'
    }]
  });
  const [m2oFormItemName, setM2oFormItemName] = useState<any>();
  const uploadFileData = useRef<any>({});

  useEffect(() => {
    setM2oFormItemName(null);
    let keys = Object.keys(modelData || {});
    // 查询数据字典
    model.properties.forEach((item: any) => {
      if (item.type == 'dict' && item.dictName) {
        getSysDataDictItemAsync(item.dictName).then((options: any = []) => {
          let _dictMap: any = {...dictMap};
          _dictMap[item.dictName] = options;
          setDictMap({..._dictMap});
        });
      }
    })

    if (keys.length > 0) { // 修改数据
      let formValues = {...modelData}
      let _uploadFiles: any = {};
      model.properties.forEach((item: any) => {
        if (modelData[item.name]) {
          if (isDateType(item.type)) {
            formValues[item.name] = moment(formValues[item.name]);
          } else if (isUploadFileType(item.type)) {
            _uploadFiles[item.name] = JSON.parse(modelData[item.name]);
          }
        }
      })
      uploadFileData.current = _uploadFiles;
      props.form.setFieldsValue(formValues);
    } else {  // 新增数据
      uploadFileData.current = {};
      let values: any = {};
      model.properties.forEach((item: any) => {
        values[item.name] = null;
      })
      props.form.setFieldsValue(values);
    }
  }, [modelData, clickTime]);

  const getUploadFiles = () => {
    return uploadFileData.current || {};
  };

  // DatePicker
  const DatePickerRender = (item: any) => {
    return (
      <Form.Item label={item.description} name={item.name} labelCol={labelCol} rules={item.rules}>
        <DatePicker
          style={{width: "100%"}}
          format="YYYY-MM-DD HH:mm:ss"
          placeholder={''}
          picker={item.picker} showTime={true}/>
      </Form.Item>
    );
  };

  // Input
  const InputRender = (item: any) => {
    return (
      <Form.Item label={item.description} name={item.name} labelCol={labelCol} rules={item.rules}>
        <Input hidden={item.hidden} disabled={item.disabled} maxLength={item.maxLength} style={{width: "100%"}}
               placeholder={item.placeholder} autoComplete="off"/>
      </Form.Item>
    );
  };

  //  TextArea
  const TextAreaRender = (item: any) => {
    return (
      <Form.Item label={item.description} name={item.name} labelCol={labelCol} rules={item.rules}>
        <TextArea rows={3}/>
      </Form.Item>
    );
  };

  // InputNumber
  const InputNumberRender = (item: any = {}) => {
    return (
      <Form.Item label={item.description} name={item.name} labelCol={labelCol} rules={item.rules}>
        <InputNumber style={{width: "100%"}} precision={item.type == 'decimal' ? item.scale : 0}/>
      </Form.Item>
    );
  };

  //下拉框
  const SelectRender = (item: any) => {
    let fieldNames = {label: 'label', value: 'value'};
    let filterKey = "name";
    if (item.fieldNames != undefined) {
      fieldNames = item.fieldNames;
      filterKey = item.fieldNames.label;
    }

    let dictMapKey = '____noDictKey___';
    if (item.type == 'bool') {
      dictMapKey = 'bool';
    } else if (item.type == 'dict') {
      if (item.dictName) {
        dictMapKey = item.dictName || '____noDictKey___';
      }
    }

    return (
      <Form.Item label={item.description} name={item.name} labelCol={labelCol} rules={item.rules}>
        <Select
          mode={item.mode}
          style={{width: "100%"}}
          options={dictMap[dictMapKey] || []}
          showSearch
          fieldNames={fieldNames}
          allowClear
          filterOption={(input: any, option: any) =>
            (option[filterKey] ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
    );
  };

  //m2o关联模型数据
  const InputM2oRender = (item: any) => {
    return (<div style={{position: 'relative'}}>
        <Form.Item label={item.description} name={item.name} labelCol={labelCol} rules={item.rules}>
          <Input hidden={item.hidden} disabled={item.disabled} maxLength={item.maxLength}
                 style={{width: "calc(100% - 85px)"}}
                 placeholder={item.placeholder} autoComplete="off"/>
        </Form.Item>
        <Button style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }} type='link' onClick={() => openDmdModal(item)}>选择数据</Button>
      </div>
    )
  };

  const onChangeUploadFileType = (data:any, item:any) => {
    let fileData: any = {...(uploadFileData.current || [])};
    if (!fileData[item.name]) {
      fileData[item.name] = [];
    }

    if (fileData[item.name].indexOf(data) == -1) {
      fileData[item.name].push(data);
    }
    fileData[item.name] = data;
    uploadFileData.current = fileData;
  }

  const UploadFileRender = (item: any, onChange:any) => {
    return <UploadFileCmp
      onChange={(data: any) => onChange(data, item)}
      files={uploadFileData.current[item.name] || []}
      fileSize={2}
      fileCount={1}
      isPic={item.type == 'image'}
      btnText='上传文件'
    />
  }


  // 上传文件
  const UploadRender = (item: any) => {
    return (
      <Form.Item label={item.description} name={item.name} labelCol={labelCol} rules={item.rules}>
        {UploadFileRender(item, onChangeUploadFileType)}
      </Form.Item>
    );
  }

  const onChangeUploadFileBlobType = (data:any, item:any) => {
    onChangeUploadFileType(data, item);
    props.form.setFieldValue(item.name, data);
  }

  const BlobRender = (item: any) => {
    let value = (modelData || {})[item.name];
    return (
      <Form.Item label={item.description} name={item.name} labelCol={labelCol} rules={item.rules}>
        {UploadFileRender(item, onChangeUploadFileBlobType)}
        {value && <TextArea disabled={true} rows={3} value={value} allowClear={true}/>}
      </Form.Item>
    )
  }

  const renderForm = useCallback((model: any = {}) => {
    let _properties = excludesRelProperties(model?.properties);
    return _properties.map((config: any, index: any) => (
      <React.Fragment key={index}>
        {!config.primaryKey && renderSwatch(config)}
      </React.Fragment>
    ))
  }, [model, modelData, dictMap, uploadFileData.current]);

  // render 表单类型判定
  const renderSwatch = (item: any) => {
    if (!item.nullable) {
      item.rules = [{required: true, message: ""}]
    }

    switch (item.type) {
      case "datetime":
      case "date":
        return DatePickerRender(item);
      case "integer":
      case "bigint":
      case "decimal":
        return InputNumberRender(item);
      case "bool":
      case "dict":
        return SelectRender(item);
      case "m2o":
        return InputM2oRender(item);
      case "textarea":
      case "richtext":
        return TextAreaRender(item);
      case "file":
      case "image":
        return UploadRender(item);
      case "blob":
        return BlobRender(item);
      default:
        return InputRender(item);
    }
  }

  const openDmdModal = (item: any) => {
    if (item.targetModel) {
      setM2oFormItemName(item.name);
      let selectedId = props.form.getFieldValue(item.name) || null;
      dmdModelRef.current?.showModal(modelByName[item.targetModel], selectedId && [selectedId]);
    } else {
      setM2oFormItemName(null);
    }
  }
  const addToFormFiled = (selectData: any) => {
    if (selectData && selectData.length == 1) {
      props.form.setFieldValue(m2oFormItemName, selectData[0]);
    }
  }

  return (
    <>
      <Form form={props.form}>
        {renderForm(model)}
      </Form>
      <DmdModal ref={dmdModelRef} addToForm={addToFormFiled} scrollY={props.scrollY}></DmdModal>
    </>
  );
});

export default UiForm;


