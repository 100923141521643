import React, {forwardRef, useImperativeHandle, useState} from "react";
import {Form, Modal, Spin} from "antd";
import {ButtonWrapper} from "@byk/pages/modeling/MxGraph/ModelBasicInfo";
import styled from "styled-components";
import UiForm from "@byk/pages/modeling/ModelData/DynamicForm/UiForm";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {isBlobType, isUploadFileType} from "@byk/pages/modeling/ModelDataUtil";

const ModalWrapper = styled(Modal)<any>`
  .ant-modal-body {
    padding: 0px;
  }

  &.ant-modal {
    top: 40px !important;
  }
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-input {
    border-radius: 5px;
    border: 1px solid rgba(228, 228, 229, 1);
  }

  .ant-spin-container {
    height: ${({spinH}) => spinH};
  }
`

const UiFormModal = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    showModal
  }));

  const {modelByName, addToForm} = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelData, setModelData] = useState<any>();
  const [model, setModel] = useState<any>();
  const [form] = Form.useForm();
  const uiFormRef = React.createRef<any>();
  const [clickTime, setClickTime] = useState<any>();

  const showModal = async (model: any, modelData: any = {}) => {
    setClickTime(new Date().getMilliseconds());
    setModelData(modelData);
    setModel(model);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setModelData(null);
    setIsModalOpen(false);
  };

  const saveModelData = async () => {
    const values = await form.validateFields();
    let uploadFiles:any = uiFormRef.current?.getUploadFiles();
    model.properties.forEach((item:any) => {
      let type = item.type;
      let name = item.name;
      if (isUploadFileType(type)) {
        if (uploadFiles[name]) {
          if (uploadFiles[name].length > 0) {
            values[name] = JSON.stringify(uploadFiles[name]);
          } else {
            values[name] = null;
          }
        }
      } else if (isBlobType(type)) {
        if (uploadFiles[name]) {
          if (uploadFiles[name].length > 0) {
            values[name] = uploadFiles[name];
          } else {
            values[name] = null;
          }
        }
      }
    });
    let result: any = await ModelingApi.doSaveModelData(model, {...values, id: modelData.id});
    if (result.success) {
      setModelData(result.result[0]);
      props.submitCallback && props.submitCallback();
      handleCancel();
    }
  }

  return (
    <ModalWrapper
      onCancel={handleCancel}
      open={isModalOpen}
      maskClosable={false}
      footer={null}
      title={modelData?.id ? "编辑数据" : "新增数据"}
      bodyStyle={{height: 'calc(100vh - 275px)'}}
      spinH={'calc(100vh - 275px)'}
    >
      <div
        style={{
          height: 'calc(100vh - 335px)',
          overflowY: 'auto',
          padding: '12px',
        }}
      >
        <UiForm ref={uiFormRef} form={form} className='uiForm' model={model} modelData={modelData} addToForm={addToForm}
                modelByName={modelByName} clickTime={clickTime}/>
      </div>

      <div style={{
        position: "absolute",
        bottom: "10px",
        borderTop: '1px solid rgba(228, 232, 235, 1)',
        background: 'white',
        zIndex: 1,
        width: "100%"
      }}>
        <div style={{display: "flex", justifyContent: 'space-between'}}>
          <div style={{marginLeft: "28px"}}></div>
          <div style={{marginRight: '16px'}}>
            <ButtonWrapper onClick={handleCancel}> 取消 </ButtonWrapper>
            <ButtonWrapper type={"primary"} onClick={saveModelData}>
              {modelData?.id ? "更新" : "保存"}
            </ButtonWrapper>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
});

export default UiFormModal;
