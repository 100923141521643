import IconSVG from "./icon.svg";
import Widget from "./widget";
import { LabelPosition } from "../../components/constants";
import { Alignment } from "@blueprintjs/core";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Amap",
  iconSVG: IconSVG,
  features: {
    dynamicHeight: {
      sectionIndex: 3,
      active: true,
    },
  },
  needsMeta: true,
  defaults: {
    rows: 3,
    columns:20,
    label: 'Label',
    labelPosition: LabelPosition.Left,
    labelAlignment: Alignment.RIGHT,
    labelWidth: 10,
    widgetName: "Amap",
    version: 1,
    animateLoading: true,
    defaultValue: "",
    value: null,
    btnText: "设置位置",
    securityJsCode: "d5e264d5d84a249e5f1e22be0fb864f3",
    securityJsKey: '60eae0021a24177a78d5838b6e2b01ad',
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "400px",
            minHeight: "40px",
          };
        },
      },
    ],
    disableResizeHandles: {
      vertical: true,
    }
  },
};

export default Widget;
