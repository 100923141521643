import styled from "styled-components";
import {Modal} from "antd";

export const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-footer {
    border-top: 1px solid rgba(217, 217, 217, 1) !important;
  }
`
