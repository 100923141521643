import {SubTextPosition} from "components/constants";
import moment from "moment";
import {
  YYYY,
  YYYY_MM,
  YYYY_MM_DD,
  YYYY_MM_DD_HH_MM,
  YYYY_MM_DD_HH_MM_SS,
  HH_MM_SS,
  HH_MM,
  MM_DD_YYYY,
  MM_DD_YYYY_HH_MM,
  MM_DD_YYYY_HH_MM_SS
} from "../constants";

export const DateFormatOptions = [
  {
    label: moment().format(YYYY),
    subText: YYYY,
    value: YYYY,
  },
  {
    label: moment().format(YYYY_MM),
    subText: YYYY_MM,
    value: YYYY_MM,
  },
  {
    label: moment().format(YYYY_MM_DD),
    subText: YYYY_MM_DD,
    value: YYYY_MM_DD,
  },
  {
    label: moment().format(YYYY_MM_DD_HH_MM),
    subText: YYYY_MM_DD_HH_MM,
    value: YYYY_MM_DD_HH_MM,
  },
  {
    label: moment().format(YYYY_MM_DD_HH_MM_SS),
    subText: YYYY_MM_DD_HH_MM_SS,
    value: YYYY_MM_DD_HH_MM_SS,
  },
  {
    label: moment().format(HH_MM_SS),
    subText: HH_MM_SS,
    value: HH_MM_SS,
  },
  {
    label: moment().format(HH_MM),
    subText: HH_MM,
    value: HH_MM,
  },
  {
    label: moment().format(MM_DD_YYYY),
    subText: MM_DD_YYYY,
    value: MM_DD_YYYY,
  },
  {
    label:moment().format(MM_DD_YYYY_HH_MM),
    subText: MM_DD_YYYY_HH_MM,
    value: MM_DD_YYYY_HH_MM,
  },
  {
    label: moment().format(MM_DD_YYYY_HH_MM_SS),
    subText: MM_DD_YYYY_HH_MM_SS,
    value:MM_DD_YYYY_HH_MM_SS,
  },
].map((x) => ({
  ...x,
  subTextPosition: SubTextPosition.BOTTOM,
}));

export const SubTableDateFormatOptions = [
  {
    label: moment().format(YYYY_MM_DD),
    subText: YYYY_MM_DD,
    value: YYYY_MM_DD,
  }

].map((x) => ({
  ...x,
  subTextPosition: SubTextPosition.BOTTOM,
}));
