import React from 'react';
import styled from "styled-components";
import {AtIcon} from "taro-ui";
const AtIconWrapper = styled.div<{size:number}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: ${({size})=> size}px;
`
const TIconWidget = (props:any) => {
  const {size, color, iconName, onClick} = props;
  return (
    <AtIconWrapper size={size} onClick={onClick}>
      <AtIcon value={iconName} color={color} />
    </AtIconWrapper>
  );
};

export default TIconWidget;
