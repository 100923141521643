import React, { useCallback } from "react";
import { Slider } from 'antd';
import styled from "styled-components";
import LabelWithTooltip, {
  labelLayoutStyles, LABEL_CONTAINER_CLASS
} from "widgets/components/LabelWithTooltip";
import { LabelPosition } from "components/constants";
import { hexToRgb } from "@byk/utils/Utils";

export const DivContainer = styled.div<{
  sliderColor?:string,sliderColor40?:string,sliderColor70?:string,sliderColor10?:string, sliderBgColor?:string, sliderBgColor70?:string
}>`
  ${labelLayoutStyles}

  & .${LABEL_CONTAINER_CLASS} {
    align-self: center;
    ${({ labelPosition }) =>
    labelPosition === LabelPosition.Left && "min-height: 30px"};
  }
  .ant-slider-handle.ant-tooltip-open {
    border-color:${({ sliderColor }) => { return `${sliderColor} !important` }};
  }
  .ant-slider-handle {border:${({ sliderColor70 }) => { return `2px solid ${sliderColor70} !important` }}; }
  .ant-slider-track  {background-color:${({ sliderColor40 }) => { return sliderColor40}}; }
  .ant-slider:hover .ant-slider-track {background-color:${({ sliderColor70 }) => { return sliderColor70}}; }
  .ant-slider-handle:focus {
    box-shadow:${({ sliderColor10 }) => { return `0 0 0 5px  ${sliderColor10} !important` }};
  }

  ${({ sliderBgColor, sliderBgColor70 }) => {
    return sliderBgColor && `
      .ant-slider-rail {
        background-color: ${sliderBgColor70} !important;
      }

      .ant-slider:hover .ant-slider-rail {
        background-color: ${sliderBgColor} !important;
      }
    `
  }}
`;

function CarouselComponent(props: any) {
  const { wrapperRef,
    compactMode,
    isDisabled,
    isLoading,
    isRequired,//
    labelAlignment,//11
    labelPosition,//11
    labelStyle,//11
    labelText,//11
    labelTextColor,//11
    labelTextSize,//11
    labelTooltip,//11
    labelWidth,//11
    onChange,
    min,
    max,
    defaultValue,
    vertical,
    onValueChange,
    value,
    sliderBgColor,
  } = props;
  const { clientWidth, clientHeight } = wrapperRef.current;
  const sliderColor=props.sliderColor;
  const sliderColor10=hexToRgb(sliderColor,0.1);
  const sliderColor40=hexToRgb(sliderColor,0.4);
  const sliderColor70=hexToRgb(sliderColor,0.7);
  const sliderBgColor70=hexToRgb(sliderBgColor,0.7);
  const onValChange = (value: number | [number, number]) => {
    onValueChange(value)
    onChange(value)
  };
  return (
    <>
      <DivContainer compactMode={compactMode}
        sliderColor={sliderColor}
        sliderColor10={sliderColor10}
        sliderColor40={sliderColor40}
        sliderColor70={sliderColor70}
        sliderBgColor={sliderBgColor}
        sliderBgColor70={sliderBgColor70}
        data-testid="radiogroup-container"
        labelPosition={labelPosition}>
        {labelText && (
          <LabelWithTooltip
            className={`datepicker-label`}
            text={labelText}
            cyHelpTextClassName="datepicker-tooltip"
            alignment={labelAlignment}
            color={labelTextColor}
            compact={compactMode}
            disabled={isDisabled}
            fontSize={labelTextSize}
            fontStyle={labelStyle}
            helpText={labelTooltip}
            loading={isLoading}
            position={labelPosition}
            width={labelWidth}
            isRequired={isRequired}
          />
        )}
        <Slider
          style={{ width: "100%" }}
          max={max} min={min}
          value={value}
          onAfterChange={onValChange}
          disabled={isDisabled} />
      </DivContainer>
    </>
  )
}

export default CarouselComponent;
