import React, { useRef, useState, useEffect } from "react";
import type { ColumnsType } from "antd/es/table";
import { Space, Button, Popconfirm, Modal, Tooltip, Drawer, Form, Input, Select, message, Divider, Table, Tabs, Affix, Row, Col, Tag, Descriptions, Radio } from "antd";
import Api from "@byk/api/Api";

import { withRouter } from "react-router-dom";
import {uuid2} from "@byk/utils/Utils";
import _ from "lodash";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../../utils/localStorage";
class Menu extends React.Component {
  formInputRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.formInputRef = React.createRef();
  }
  state = {
    appId: this.props.defaultAppId||"",
    appList:this.props.appList,
    menuData: [],
    languageName: {},
    inputData: { menuKey: "", enable: false },
    columnList: [],
    enable: false,
    key:uuid2(16,16),
  };
  that: any;
  componentDidMount() {
    this.bindMenu();
    this.props.onRef && this.props.onRef(this);
  }
  // api
  apiGet: string = `${apiPath}/${getTenant()}/i18n/menuI18N`;
  async bindMenu() {
    let res: any = await Api.get(this.apiGet, { appId: this.state.appId });
    if (res.success) {
      let list = res.ext
      this.bindColumns(list.languageName);
      this.setState({ menuData: this.bindPage(list.menuTree), languageName: list.languageName,key:uuid2(16,16) });
    }
  };
  bindPage(data: any) {
    let _data: any = [];
    _.each(data,(itm:any)=>{
      itm.key=itm.menuKey;
      let obj:any=[];
      let n:any= itm.children;
      _.each(n,(_itm:any)=>{
        itm.key=_itm.menuKey+uuid2(16,16);
        obj.push(_itm);
      })
      itm.children = obj;
      _data.push(itm)
    })
    return _data;
  }
  bindColumns(languageName: any) {
    let that = this;
    let list: any = [{
      title: '菜单',
      dataIndex: 'title',
      key: 'title',
      align: "left",
      width: 360,
      fixed: 'left',
    }];
    for (let key in languageName) {
      //if (languageName.hasOwnProperty(key)) {  // 确保键在对象自身上，而不是从原型链继承
        list.push({
          title: languageName[key],
          dataIndex: key,
          key: key,
          align: "center",
          render: (_: any, record: any) => (
            <>
              <Form.Item
                label=""
                colon={false}
                name={key}
                style={{ margin:0}}
              >
                {
                  (that.state.inputData["menuKey"] == record.menuKey && that.state.inputData["enable"] == true) ?
                    <Input style={{ width: "100%" }}
                      placeholder="请输入字段"
                      maxLength={200}
                      allowClear
                      key={"lang." + record.menuKey}
                    /> :
                    <span>
                      {record[key]}
                    </span>
                }
              </Form.Item>
            </>
          ),
        });
     // }
    }
    list.push({
      title: "操作",
      key: "action",
      render: (_: any, record: any) => (
        <>
          <Space size="middle" style={{ display: that.state.inputData["menuKey"] == record.menuKey && that.state.inputData["enable"] == true ? '' : 'none' }} >
            <Button type="link" htmlType="submit" >
              保存
            </Button>
            <Button type="link" onClick={() => { that.cancel(record) }}>
              取消
            </Button>
          </Space>
          <Space size="middle" style={{ display: that.state.inputData["menuKey"] == record.menuKey && that.state.inputData["enable"] == true ? 'none' : '' }}>
            <Button type="link" onClick={() => { that.edit(record) }}>
              编辑
            </Button>
          </Space>
        </>
      ),
      width: 150,
      fixed: 'right',
    });
    this.state.columnList = list;
  }
  edit(record: any) {
    let data = { enable: true, menuKey: record.menuKey };
    const newData = { inputData: data, menuKey: record.menuKey, enable: true };
    let langList = this.state.languageName;
      this.bindColumns(langList);
      let _data: any = {};
      for (let key in langList) {
        //if (langList.hasOwnProperty(key)) {  // 确保键在对象自身上，而不是从原型链继承
          _data[key] = record[key];
        //}
      }
    this.setState({...newData}, () => {
      console.log(this.state.inputData)
      this.formInputRef.current?.setFieldsValue(_data);
    });
  };
  cancel(record?: any) {
    let data = { enable: false, key: "" };
    this.setState({ inputData: data },()=>{
      this.bindMenu();
    });
  };
  appChange=(e:any)=>{
      let _id=e.target.value;
      this.setState({appId:_id},()=>{
        this.bindMenu();
      });
  }

  async onFinish(values: any) {
    console.log("values", values)
    let data: any = values;
    data.appId = this.state.appId;
    data.menuKey = this.state.menuKey;
    console.log("data", data)
    let result1: any = await Api.put(this.apiGet, data);;
    if (result1.success) {
      message.success("保存成功!");
      this.cancel();
    }
  };
  render() {
    return (
      <>
        <div className="x-main-box-in">
          <Radio.Group style={{marginBottom:"10px"}} onChange={(e:any)=>{this.appChange(e)}} defaultValue={this.props.defaultAppId} options={this.state.appList}  optionType="button"
          buttonStyle="solid"/>
          <Form
            key={this.state.key}
            ref={this.formInputRef}
            onFinish={(v) => this.onFinish(v)}
          >
            <Table columns={this.state.columnList}  bordered={true} size="small" pagination={false}
            expandable={{
              defaultExpandAllRows: true,
              rowExpandable:()=>false
            }}
            scroll={ {x: 1200 }}
            dataSource={this.state.menuData} />
          </Form>
        </div >
      </>
    );
  }
}
export default withRouter(Menu);
