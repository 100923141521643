import log from "loglevel";
import ButtonGroupWidget, {CONFIG as BUTTON_GROUP_CONFIG,} from "widgets/ButtonGroupWidget";
import ButtonWidget, {CONFIG as BUTTON_WIDGET_CONFIG,} from "widgets/ButtonWidget";
import SelectWidget, {CONFIG as SELECT_WIDGET_CONFIG,} from "widgets/SelectWidget";
import CanvasWidget, {CONFIG as CANVAS_WIDGET_CONFIG,} from "widgets/CanvasWidget";
import ChartWidget, {CONFIG as CHART_WIDGET_CONFIG,} from "widgets/ChartWidget";
import CheckboxGroupWidget, {CONFIG as CHECKBOX_GROUP_WIDGET_CONFIG,} from "widgets/CheckboxGroupWidget";
import CheckboxWidget, {CONFIG as CHECKBOX_WIDGET_CONFIG,} from "widgets/CheckboxWidget";
import CircularProgressWidget, {CONFIG as CIRCULAR_PROGRESS_WIDGET_CONFIG,} from "widgets/CircularProgressWidget";
import ContainerWidget, {CONFIG as CONTAINER_WIDGET_CONFIG,} from "widgets/ContainerWidget";
import DatePickerWidget, {CONFIG as DATE_PICKER_WIDGET_CONFIG,} from "widgets/DatePickerWidget";
import DatePickerWidget2, {CONFIG as DATE_PICKER_WIDGET_2_CONFIG,} from "widgets/DatePickerWidget2";
import DividerWidget, {CONFIG as DIVIDER_WIDGET_CONFIG,} from "widgets/DividerWidget";
import MultiSelectWidgetV2, {CONFIG as MULTI_SELECT_WIDGET_V2_CONFIG,} from "widgets/MultiSelectWidgetV2";
import DropdownWidget, {CONFIG as DROPDOWN_WIDGET_CONFIG,} from "widgets/DropdownWidget";
import FilePickerWidget, {CONFIG as FILEPICKER_WIDGET_CONFIG,} from "widgets/FilepickerWidget";
//import FilePickerWidgetV2, {CONFIG as FILEPICKER_WIDGET_V2_CONFIG,} from "widgets/FilePickerWidgetV2";
import FormButtonWidget, {CONFIG as FORM_BUTTON_WIDGET_CONFIG,} from "widgets/FormButtonWidget";
import FormWidget, {CONFIG as FORM_WIDGET_CONFIG} from "widgets/FormWidget";
import SearchFormWidget, {CONFIG as SEARCH_FORM_WIDGET_CONFIG} from "widgetsExtend/SearchFormWidget";
import IconButtonWidget, {CONFIG as ICON_BUTTON_WIDGET_CONFIG,} from "widgets/IconButtonWidget";
import IconWidget, {CONFIG as ICON_WIDGET_CONFIG} from "widgets/IconWidget";
import IframeWidget, {CONFIG as IFRAME_WIDGET_CONFIG,} from "widgets/IframeWidget";
import ImageWidget, {CONFIG as IMAGE_WIDGET_CONFIG,} from "widgets/ImageWidget";
import InputWidget, {CONFIG as INPUT_WIDGET_CONFIG,} from "widgets/InputWidget";
import InputWidgetV2, {CONFIG as INPUT_WIDGET_V2_CONFIG,} from "widgets/InputWidgetV2";
import ListWidget, {CONFIG as LIST_WIDGET_CONFIG} from "widgets/ListWidget";
import MenuButtonWidget, {CONFIG as MENU_BUTTON_WIDGET_CONFIG,} from "widgets/MenuButtonWidget";
import ModalWidget, {CONFIG as MODAL_WIDGET_CONFIG,} from "widgets/ModalWidget";
import MultiSelectTreeWidget, {CONFIG as MULTI_SELECT_TREE_WIDGET_CONFIG,} from "widgets/MultiSelectTreeWidget";
import MultiSelectWidget, {CONFIG as MULTI_SELECT_WIDGET_CONFIG,} from "widgets/MultiSelectWidget";
import PhoneInputWidget, {CONFIG as PHONE_INPUT_WIDGET_V2_CONFIG,} from "widgets/PhoneInputWidget";
import ProgressBarWidget, {CONFIG as PROGRESSBAR_WIDGET_CONFIG,} from "widgets/ProgressBarWidget";
import RadioGroupWidget, {CONFIG as RADIO_GROUP_WIDGET_CONFIG,} from "widgets/RadioGroupWidget";
import RichTextEditorWidget, {CONFIG as RICH_TEXT_EDITOR_WIDGET_CONFIG,} from "widgets/RichTextEditorWidget";
import SingleSelectTreeWidget, {CONFIG as SINGLE_SELECT_TREE_WIDGET_CONFIG,} from "widgets/SingleSelectTreeWidget";
import SkeletonWidget, {CONFIG as SKELETON_WIDGET_CONFIG,} from "widgets/SkeletonWidget";
import JSONFormWidget, {CONFIG as JSON_FORM_WIDGET_CONFIG,} from "widgets/JSONFormWidget";
import SwitchGroupWidget, {CONFIG as SWITCH_GROUP_WIDGET_CONFIG,} from "widgets/SwitchGroupWidget";
import SwitchWidget, {CONFIG as SWITCH_WIDGET_CONFIG,} from "widgets/SwitchWidget";
import TableWidget, {CONFIG as TABLE_WIDGET_CONFIG,} from "widgets/TableWidget";
import TabsMigratorWidget, {CONFIG as TABS_MIGRATOR_WIDGET_CONFIG,} from "widgets/TabsMigrator";
import TabsWidget, {CONFIG as TABS_WIDGET_CONFIG} from "widgets/TabsWidget";
import TextWidget, {CONFIG as TEXT_WIDGET_CONFIG} from "widgets/TextWidget";
import VideoWidget, {CONFIG as VIDEO_WIDGET_CONFIG,} from "widgets/VideoWidget";
import {registerWidget} from "./WidgetRegisterHelpers";
import type {WidgetConfiguration} from "widgets/constants";
import TableWidgetV3, {CONFIG as TABLE_WIDGET_CONFIG_V3,} from "widgets/TableWidgetV3";
import ListWidgetV2, {CONFIG as LIST_WIDGET_CONFIG_V2,} from "widgets/ListWidgetV2";

//----extend widgets
import CarouselWidget, {CONFIG as CAROUSEL_WIDGET,} from "widgetsExtend/CarouselWidget";
import TreeWidget, {CONFIG as TREE_WIDGET,} from "widgetsExtend/TreeWidget";
import BreadcrumbWidget, {CONFIG as BREADCRUMB_WIDGET,} from "widgetsExtend/BreadcrumbWidget";
import XImageWidget, {CONFIG as X_IMAGE_WIDGET,} from "widgetsExtend/ImageWidget";
import CascaderWidget, {CONFIG as CASCADER_WIDGET,} from "widgetsExtend/CascaderWidget";
import MarqueeWidget, {CONFIG as MARQUEE_WIDGET,} from "widgetsExtend/MarqueeWidget";
import RangeDatePickerWidget, {CONFIG as RANGEDATEPICKER_WIDGET,} from "widgetsExtend/RangeDatePickerWidget";
import InputNumberWidget, {CONFIG as INPUTNUMBER_WIDGET,} from "widgetsExtend/InputNumberWidget";
import SliderWidget, {CONFIG as SLIDER_WIDGET,} from "widgetsExtend/SliderWidget";
import SearchWidget, {CONFIG as SEARCH_WIDGET,} from "widgetsExtend/SearchWidget";
import ProgressWidget, {CONFIG as PROGRESS_WIDGET,} from "widgetsExtend/ProgressWidget";
import CalendarWidget, {CONFIG as CALENDAR_WIDGET,} from "widgetsExtend/CalendarWidget";
import DrawerWidget, {CONFIG as DRAWER_WIDGET,} from "widgetsExtend/DrawerWidget";
import FixedContainerWidget, {CONFIG as FIXED_DRAWER_WIDGET,} from "widgetsExtend/FixedContainerWidget";
import StepWidget, {CONFIG as X_STEP_WIDGET,} from "widgetsExtend/StepsWidget";
import DynamicFormsWidget, {CONFIG as DYNAMICFORMS_WIDGET,} from "widgetsExtend/DynamicForms";
import UploadWidget, {CONFIG as UPLOAD_WIDGET,} from "widgetsExtend/UploadWidget";
import UploadImageWidget, {CONFIG as UPLOAD_IMAGE_WIDGET,} from "widgetsExtend/UploadImageWidget";
import TableAntdWidget, {CONFIG as TABLE_ANTD_WIDGET,} from "widgetsExtend/TableWidget";
import TimingWidget, {CONFIG as X_TIMING_WIDGET,} from "widgetsExtend/TimingWidget";
import EchartsWidget, {CONFIG as X_ECHARTS_WIDGET,} from "widgetsExtend/EchartsWidget";
import EchartsLineWidget, {CONFIG as X_ECHARTS_LINE_WIDGET,} from "widgetsExtend/EchartsLineWidget";
import EchartsRadarWidget, {CONFIG as X_ECHARTS_RADAR_WIDGET,} from "widgetsExtend/EchartsRadarWidget";
import EchartsPieWidget, {CONFIG as X_ECHARTS_PIE_WIDGET,} from "widgetsExtend/EchartsPieWidget";
import EchartsGaugeWidget, {CONFIG as X_ECHARTS_GAUGE_WIDGET,} from "widgetsExtend/EchartsGaugeWidget";
import EchartsFunnelWidget, {CONFIG as X_ECHARTS_FUNNEL_WIDGET,} from "widgetsExtend/EchartsFunnelWidget";
import EchartsMapWidget, {CONFIG as X_ECHARTS_MAP_WIDGET,} from "widgetsExtend/EchartsMapWidget";
import XCollapseWidget, {CONFIG as XCOLLAPSE_WIDGET,} from "widgetsExtend/XCollapseWidget";
import AmapWidget, {CONFIG as AMAP_WIDGET,} from "widgetsExtend/AmapWidget";
import AmapViewWidget, {CONFIG as AMAP_VIEW_WIDGET,} from "widgetsExtend/AmapViewWidget";
import SubTableWidget, {CONFIG as SUB_TABLE_WIDGET,} from "widgetsExtend/SubTableWidget";
//taroUI widgets
import AtInputWidget, {CONFIG as AT_INPUT_WIDGET,} from "widgetsTaro/AtInputWidget";
import AtButtonWidget, {CONFIG as AT_BUTTON_WIDGET,} from "widgetsTaro/AtButtonWidget";
//import AtListWidget, {CONFIG as AT_LIST_WIDGET,} from "widgetsTaro/AtListWidget";
import AtSwiperWidget, {CONFIG as AT_SWIPER_WIDGET,} from "widgetsTaro/AtSwiperWidget";
import AtGridWidget, {CONFIG as AT_GRID_WIDGET,} from "widgetsTaro/AtGridWidget";
//import AtTabBarWidget, {CONFIG as AT_TABBAR_WIDGET,} from "widgetsTaro/AtTabBarWidget";
import AtTextWidget, {CONFIG as AT_TEXT_WIDGET,} from "widgetsTaro/AtTextWidget";
import AtTextAreaWidget, {CONFIG as AT_TEXTAREA_WIDGET,} from "widgetsTaro/AtTextAreaWidget";
import AtInputNumberWidget, {CONFIG as AT_INPUTNUMBER_WIDGET,} from "widgetsTaro/AtInputNumberWidget";
import AtPickerWidget, {CONFIG as AT_PICKER_WIDGET,} from "widgetsTaro/AtPickerWidget";
import AtSwitchWidget, {CONFIG as AT_SWITCH_WIDGET,} from "widgetsTaro/AtSwitchWidget";
import AtRadioWidget, {CONFIG as AT_RADIO_WIDGET,} from "widgetsTaro/AtRadioWidget";
import AtCheckboxWidget, {CONFIG as AT_CHECKBOX_WIDGET,} from "widgetsTaro/AtCheckboxWidget";
import AtIconWidget, {CONFIG as AT_ICON_WIDGET,} from "widgetsTaro/AtIconWidget";
import AtDividerWidget, {CONFIG as AT_DIVIDER_WIDGET,} from "widgetsTaro/AtDividerWidget";
import AtProgressWidget, {CONFIG as AT_PROGRESS_WIDGET,} from "widgetsTaro/AtProgressWidget";
import AtNavBarWidget, {CONFIG as AT_NAVBAR_WIDGET,} from "widgetsTaro/AtNavBarWidget";
import AtImagePickerWidget, {CONFIG as AT_IMAGE_PICKER_WIDGET,} from "widgetsTaro/AtImagePickerWidget";
export const ALL_WIDGETS_AND_CONFIG: [any, WidgetConfiguration][] = [
  //taro begin
  [AtImagePickerWidget, AT_IMAGE_PICKER_WIDGET],
  [AtNavBarWidget, AT_NAVBAR_WIDGET],
  [AtProgressWidget, AT_PROGRESS_WIDGET],
  [AtDividerWidget, AT_DIVIDER_WIDGET],
  [AtIconWidget, AT_ICON_WIDGET],
  [AtCheckboxWidget, AT_CHECKBOX_WIDGET],
  [AtRadioWidget, AT_RADIO_WIDGET],
  [AtSwitchWidget, AT_SWITCH_WIDGET],
  [AtPickerWidget, AT_PICKER_WIDGET],
  [AtInputNumberWidget, AT_INPUTNUMBER_WIDGET],
  [AtTextAreaWidget, AT_TEXTAREA_WIDGET],
  [AtTextWidget, AT_TEXT_WIDGET],
  //[AtTabBarWidget, AT_TABBAR_WIDGET],
  [AtGridWidget, AT_GRID_WIDGET],
  [AtSwiperWidget, AT_SWIPER_WIDGET],
  [AtButtonWidget, AT_BUTTON_WIDGET],
  [AtInputWidget, AT_INPUT_WIDGET],
  //[AtListWidget, AT_LIST_WIDGET],
  //taro end
  //--extend begin
  [EchartsWidget, X_ECHARTS_WIDGET],
  [EchartsLineWidget, X_ECHARTS_LINE_WIDGET],
  [EchartsRadarWidget, X_ECHARTS_RADAR_WIDGET],
  [EchartsPieWidget, X_ECHARTS_PIE_WIDGET],
  [EchartsGaugeWidget, X_ECHARTS_GAUGE_WIDGET],
  [EchartsFunnelWidget, X_ECHARTS_FUNNEL_WIDGET],
  [EchartsMapWidget, X_ECHARTS_MAP_WIDGET],
  [TimingWidget, X_TIMING_WIDGET],
  [TableAntdWidget, TABLE_ANTD_WIDGET],
  [DynamicFormsWidget, DYNAMICFORMS_WIDGET],
  [StepWidget, X_STEP_WIDGET],
  [ProgressWidget, PROGRESS_WIDGET],
  [SliderWidget, SLIDER_WIDGET],
  [CalendarWidget, CALENDAR_WIDGET],
  [SearchWidget, SEARCH_WIDGET],
  [UploadWidget, UPLOAD_WIDGET],
  [UploadImageWidget, UPLOAD_IMAGE_WIDGET],
  [InputNumberWidget, INPUTNUMBER_WIDGET],
  [RangeDatePickerWidget, RANGEDATEPICKER_WIDGET],
  [DrawerWidget, DRAWER_WIDGET],
  [MarqueeWidget, MARQUEE_WIDGET],
  [CascaderWidget, CASCADER_WIDGET],
  [XImageWidget, X_IMAGE_WIDGET],
  [TreeWidget, TREE_WIDGET],
  [CarouselWidget, CAROUSEL_WIDGET],
  [BreadcrumbWidget, BREADCRUMB_WIDGET],
  [FixedContainerWidget, FIXED_DRAWER_WIDGET],
  //--extend end
  [CanvasWidget, CANVAS_WIDGET_CONFIG],
  [SkeletonWidget, SKELETON_WIDGET_CONFIG],
  [ContainerWidget, CONTAINER_WIDGET_CONFIG],
  [TextWidget, TEXT_WIDGET_CONFIG],
  [TableWidget, TABLE_WIDGET_CONFIG],
  [CheckboxWidget, CHECKBOX_WIDGET_CONFIG],
  [RadioGroupWidget, RADIO_GROUP_WIDGET_CONFIG],
  [ButtonWidget, BUTTON_WIDGET_CONFIG],
  [ImageWidget, IMAGE_WIDGET_CONFIG],
  [VideoWidget, VIDEO_WIDGET_CONFIG],
  [TabsWidget, TABS_WIDGET_CONFIG],
  [ModalWidget, MODAL_WIDGET_CONFIG],
  [ChartWidget, CHART_WIDGET_CONFIG],
  [RichTextEditorWidget, RICH_TEXT_EDITOR_WIDGET_CONFIG],
  [DatePickerWidget2, DATE_PICKER_WIDGET_2_CONFIG],
  [SwitchWidget, SWITCH_WIDGET_CONFIG],
  [FormWidget, FORM_WIDGET_CONFIG],
  [SearchFormWidget, SEARCH_FORM_WIDGET_CONFIG],
  [IframeWidget, IFRAME_WIDGET_CONFIG],
  [TabsMigratorWidget, TABS_MIGRATOR_WIDGET_CONFIG],
  [DividerWidget, DIVIDER_WIDGET_CONFIG],
  [MenuButtonWidget, MENU_BUTTON_WIDGET_CONFIG],
  [IconButtonWidget, ICON_BUTTON_WIDGET_CONFIG],
  [CheckboxGroupWidget, CHECKBOX_GROUP_WIDGET_CONFIG],
  //--[FilePickerWidgetV2, FILEPICKER_WIDGET_V2_CONFIG],
  [ButtonGroupWidget, BUTTON_GROUP_CONFIG],
  [MultiSelectTreeWidget, MULTI_SELECT_TREE_WIDGET_CONFIG],
  [SingleSelectTreeWidget, SINGLE_SELECT_TREE_WIDGET_CONFIG],
  [SwitchGroupWidget, SWITCH_GROUP_WIDGET_CONFIG],
  [ProgressBarWidget, PROGRESSBAR_WIDGET_CONFIG],
  [SelectWidget, SELECT_WIDGET_CONFIG],
  [MultiSelectWidgetV2, MULTI_SELECT_WIDGET_V2_CONFIG],
  [InputWidgetV2, INPUT_WIDGET_V2_CONFIG],
  [PhoneInputWidget, PHONE_INPUT_WIDGET_V2_CONFIG],
  [JSONFormWidget, JSON_FORM_WIDGET_CONFIG],
  [TableWidgetV3, TABLE_WIDGET_CONFIG_V3],
  [ListWidgetV2, LIST_WIDGET_CONFIG_V2],
  [XCollapseWidget, XCOLLAPSE_WIDGET],
  [AmapWidget, AMAP_WIDGET],
  [AmapViewWidget, AMAP_VIEW_WIDGET],
  [SubTableWidget, SUB_TABLE_WIDGET],

  //Deprecated Widgets
  [InputWidget, INPUT_WIDGET_CONFIG],
  [DropdownWidget, DROPDOWN_WIDGET_CONFIG],
  [DatePickerWidget, DATE_PICKER_WIDGET_CONFIG],
  [IconWidget, ICON_WIDGET_CONFIG],
  [FilePickerWidget, FILEPICKER_WIDGET_CONFIG],
  [MultiSelectWidget, MULTI_SELECT_WIDGET_CONFIG],
  [FormButtonWidget, FORM_BUTTON_WIDGET_CONFIG],
  [CircularProgressWidget, CIRCULAR_PROGRESS_WIDGET_CONFIG],
  [ListWidget, LIST_WIDGET_CONFIG],

  /*
   * If a newly added widget works well inside the list widget,
   * please add widget type in the List widget's allowed widget
   * list, to make the new widget be droppable inside List widget.
   */
];

export const registerWidgets = () => {
  const start = performance.now();
  for (const widget of ALL_WIDGETS_AND_CONFIG) {
    registerWidget(widget[0], widget[1] as WidgetConfiguration);
  }

  log.debug("Widget registration took: ", performance.now() - start, "ms");
};
