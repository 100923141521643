import React from "react";
import {Carousel} from 'antd';
import styled from "styled-components";
import _, {isString} from "lodash";
import DefaultImage from "../../ImageWidget/component/DefaultImage";
import {useSelector} from "react-redux";

const CarouselWrapepr = styled.div<any>`
  width: 100%;
  height: 100%;

  .ant-carousel {
    height: 100%;

    .slick-slider {
      height: 100%;

      .slick-list{
        height: 100%;

       .slick-track {
          height: 100%;
       }
      }

      .slick-slide > div:first-child {
        height: 100%;
      }
    }
  }
`

const ImageWrapper = styled.div<{
  isBgColor?: boolean,
  borderRadius?: number,
  boxShadow?: number,
  padding?: number,
  margin?: number,
}>`
  cursor:pointer;
  width: 100%;
  height: 100%;
  background-color: ${({isBgColor}) => {
  return isBgColor ? '#000' : ''
}};
  img{
    width: 100%;
    height: 100%;
    margin: ${({margin}) => {
  return margin == 0 ? "auto" : margin + "px"
}};
    padding: ${({padding}) => {
  return padding == 0 ? "auto" : padding + "px"
}};
    border-radius:${({borderRadius}) => {
  return borderRadius == 0 ? "0" : borderRadius + "px"
}};
    box-shadow:0px 0px ${({boxShadow}) => boxShadow}px 0px rgba(0,0,0,0.5);
  };
`;

function CarouselComponent(props: any) {
  const {
    wrapperRef,
    imageData,
    autoplay,
    width,
    height,
    borderRadius,
    boxShadow,
    margin,
    padding,
    onItemClick,
    objectFit,
    onClick,
    onHandleClick
  } = props;
  const {clientWidth, clientHeight} = wrapperRef.current;
  // 不要删除： eval会用到  appsmith
  const appsmith = useSelector((state: any) => {
    return state.entities.app;
  })

  let data = imageData;
  if (isString(imageData) && imageData.startsWith("{{")) {
    data = data.replace('{{', '').replace('}}', '');
    data = eval(data);
  }
  if (isString(imageData) && !imageData.startsWith("{{")) {
    try {
      data = JSON.parse(imageData);
    } catch (e) {
      data = []
    }
  }

  try {
    let dataTmp:any = [];
    data.forEach((item: any) => {
      if (item.url && item.url.startsWith && item.url.startsWith('[{')) {
        let jsonObj:any = JSON.parse(item.url);
        if (jsonObj.forEach) {
          jsonObj.forEach((imgItem: any) => {
            dataTmp.push(imgItem);
          })
        }
      } else {
        if (item.url) {
          dataTmp.push(item);
        }
      }
    });

    data = dataTmp || [];
  } catch (e) {
    data = []
  }

  const imgOnClick = (itemData: any) => {
    onItemClick && onItemClick(itemData);
  }

  return (
    <CarouselWrapepr onClick={onClick && onHandleClick}>
      <Carousel autoplay={autoplay}>
        {
          !_.isEmpty(data) && data.map((i: any) => {
            return (
              <ImageWrapper
                key={i.uid}
                borderRadius={borderRadius}
                boxShadow={boxShadow}
                margin={margin}
                padding={padding}
              >
                <img
                  style={{
                    objectFit: objectFit,
                  }}
                  src={i.url} alt={``} onClick={() => !onClick && imgOnClick(i)}/>
              </ImageWrapper>
            )
          })
        }
        {/*无数据时，默认状态*/}
        {
          _.isEmpty(data) &&
          <ImageWrapper isBgColor={_.isEmpty(data)}>
            <DefaultImage/>
          </ImageWrapper>
        }

      </Carousel>
    </CarouselWrapepr>
  )
}

export default CarouselComponent;
