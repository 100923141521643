import { Colors } from "constants/Colors";
import { FILL_WIDGET_MIN_WIDTH } from "constants/minWidthConstants";
import { cloneDeep, set } from "lodash";
import { ResponsiveBehavior } from "utils/autoLayout/constants";
import {
  combineDynamicBindings,
  getDynamicBindings,
} from "utils/DynamicBindingUtils";
import type { WidgetProps } from "widgets/BaseWidget";
import { BlueprintOperationTypes } from "widgets/constants";
import { StickyType } from "./component/Constants";
import { InlineEditingSaveOptions } from "./constants";
import IconSVG from "./icon.svg";
import Widget from "./widget";
import { escapeString } from "./widget/utilities";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Table",
  iconSVG: IconSVG,
  needsMeta: true,
  needsHeightForContent: true,
  defaults: {
    responsiveBehavior: ResponsiveBehavior.Fill,
    minWidth: FILL_WIDGET_MIN_WIDTH,
    rows: 28,
    canFreezeColumn: true,
    columnUpdatedAt: Date.now(),
    columns: 34,
    animateLoading: true,
    // defaultSelectedRowIndex:0,
    // defaultSelectedRowIndices: [0],
    defaultSelectedRowIndex:"",
    defaultSelectedRowIndices: "[0]",
    label: "",
    widgetName: "Table",
    searchKey: "",
    textSize: "0.875rem",
    horizontalAlignment: "LEFT",
    verticalAlignment: "CENTER",
    totalRecordsCount: 0,
    defaultPageSize: 0,
    dynamicPropertyPathList: [],
    borderColor: Colors.GREY_5,
    borderWidth: "1",
    dynamicBindingPathList: [
      {
        key: "primaryColumns.step.computedValue",
      },
      {
        key: "primaryColumns.task.computedValue",
      },
      {
        key: "primaryColumns.status.computedValue",
      },
      {
        key: "primaryColumns.action.computedValue",
      },
      {
        key: "primaryColumns.action.buttonColor",
      },
      {
        key: "primaryColumns.action.borderRadius",
      },
      {
        key: "primaryColumns.action.boxShadow",
      },
    ],
    primaryColumns: {
      id: {
        index: 0,
        width: 150,
        id: "id",
        originalId: "id",
        alias: "id",
        allowSameOptionsInNewRow: true,
        horizontalAlignment: "LEFT",
        verticalAlignment: "CENTER",
        columnType: "text",
        textSize: "0.875rem",
        enableFilter: true,
        enableSort: true,
        isVisible: true,
        isCellVisible: true,
        isCellEditable: false,
        isDerived: false,
        label: "序号",
        computedValue: `{{Table1.processedTableData.map((currentRow, currentIndex) => ( currentRow["id"]))}}`,
        validation: {},
        sticky: StickyType.NONE,
      },
      name: {
        index: 1,
        width: 150,
        id: "name",
        originalId: "name",
        alias: "name",
        allowSameOptionsInNewRow: true,
        horizontalAlignment: "LEFT",
        verticalAlignment: "CENTER",
        columnType: "text",
        textSize: "0.875rem",
        enableFilter: true,
        enableSort: true,
        isVisible: true,
        isCellVisible: true,
        isCellEditable: false,
        isDerived: false,
        label: "姓名",
        computedValue: `{{Table1.processedTableData.map((currentRow, currentIndex) => ( currentRow["name"]))}}`,
        validation: {},
        sticky: StickyType.NONE,
      },
      sex: {
        index: 2,
        width: 150,
        id: "sex",
        originalId: "sex",
        alias: "sex",
        allowSameOptionsInNewRow: true,
        horizontalAlignment: "LEFT",
        verticalAlignment: "CENTER",
        columnType: "text",
        textSize: "0.875rem",
        enableFilter: true,
        enableSort: true,
        isVisible: true,
        isCellVisible: true,
        isCellEditable: false,
        isDerived: false,
        label: "性别",
        computedValue: `{{Table1.processedTableData.map((currentRow, currentIndex) => ( currentRow["sex"]))}}`,
        validation: {},
        sticky: StickyType.NONE,
      },
      mobile: {
        index: 2,
        width: 150,
        id: "mobile",
        originalId: "mobile",
        alias: "mobile",
        allowSameOptionsInNewRow: true,
        horizontalAlignment: "LEFT",
        verticalAlignment: "CENTER",
        columnType: "text",
        textSize: "0.875rem",
        enableFilter: true,
        enableSort: true,
        isVisible: true,
        isCellVisible: true,
        isCellEditable: false,
        isDerived: false,
        label: "手机号",
        computedValue: `{{Table1.processedTableData.map((currentRow, currentIndex) => ( currentRow["mobile"]))}}`,
        validation: {},
        sticky: StickyType.NONE,
      },
      action: {
        index: 3,
        width: 150,
        id: "action",
        originalId: "action",
        alias: "action",
        allowSameOptionsInNewRow: true,
        horizontalAlignment: "LEFT",
        verticalAlignment: "CENTER",
        columnType: "button",
        textSize: "0.875rem",
        enableFilter: true,
        enableSort: true,
        isVisible: true,
        isCellVisible: true,
        isCellEditable: false,
        isDisabled: false,
        isDerived: false,
        label: "操作",
        onClick:
          "showAlert('操作成功！', 'success')",
        computedValue: `{{Table1.processedTableData.map((currentRow, currentIndex) => ( currentRow["action"]))}}`,
        validation: {},
        sticky: StickyType.NONE,
      },
    },
    tableData: [
      {
        id: "1",
        name: "张三",
        sex:"男",
        mobile:"13912345678",
        action: "",
      }
    ],
    columnWidthMap: {
      id: 100,
      name: 200,
      sex: 125,
      action:150
    },
    columnOrder: ["id", "name", "sex", "cardId","mobile","code","status","action"],
    blueprint: {
      operations: [
        {
          type: BlueprintOperationTypes.MODIFY_PROPS,
          fn: (widget: WidgetProps & { children?: WidgetProps[] }) => {
            const primaryColumns = cloneDeep(widget.primaryColumns);
            const columnIds = Object.keys(primaryColumns);
            columnIds.forEach((columnId) => {
              set(
                primaryColumns,
                `${columnId}.computedValue`,
                `{{${
                  widget.widgetName
                }.processedTableData.map((currentRow, currentIndex) => ( currentRow["${escapeString(
                  primaryColumns[columnId].alias,
                )}"]))}}`,
              );
              set(primaryColumns, `${columnId}.labelColor`, Colors.WHITE);

              Object.keys(
                widget.childStylesheet[primaryColumns[columnId].columnType] ||
                  [],
              ).map((propertyKey) => {
                const { jsSnippets, stringSegments } = getDynamicBindings(
                  widget.childStylesheet[primaryColumns[columnId].columnType][
                    propertyKey
                  ],
                );

                const js = combineDynamicBindings(jsSnippets, stringSegments);

                set(
                  primaryColumns,
                  `${columnId}.${propertyKey}`,
                  `{{${widget.widgetName}.processedTableData.map((currentRow, currentIndex) => ( ${js}))}}`,
                );
              });
            });

            const updatePropertyMap = [
              {
                widgetId: widget.widgetId,
                propertyName: "primaryColumns",
                propertyValue: primaryColumns,
              },
            ];
            return updatePropertyMap;
          },
        },
      ],
    },
    enableClientSideSearch: true,
    isVisibleSearch: false,
    isVisibleFilters: false,
    isVisibleDownload: false,
    isVisiblePagination: true,
    isSortable: false,
    delimiter: ",",
    version: 1,
    inlineEditingSaveOption: InlineEditingSaveOptions.ROW_LEVEL,
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    eventConfig: Widget.getPropertyPaneEventsConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    loadingProperties: Widget.getLoadingProperties(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "280px",
            minHeight: "300px",
          };
        },
      },
    ],
  },
};

export default Widget;
