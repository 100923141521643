import Api from "@byk/api/Api";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {message} from "antd";
import _ from "lodash";
import {getAppId} from "../../../utils/localStorage";
import {uuid2} from "@byk/utils/Utils";

export const LISE_DEPLOY_URL = '/byk/platform/subapp/SubappDeploy/list'
export const DEPLOY_URL = '/byk/platform/subapp/SubappDeploy'
export const APP_RES_URL = '/byk/platform/subapp/admin/showAppResc/'
export const DYNAMIC_MODEL = 'DYNAMIC_MODEL'
export const DEPLOY_EXPORT_URL = '/byk/platform/subapp/admin/appExport'
export const DEPLOY_DETAIL_URL = '/byk/platform/subapp/admin/showAppDeployDetail'
export const ONE_KEY_DEPLOY = '/byk/platform/subapp/admin/appInstall'
export const GET_APP_BY_APPID_URL = '/byk/platform/subapp/admin/getAppByAppId'
export const IMPORT_APP_URL = '/byk/platform/subapp/admin/appImport'

export type AppResType = {
  value: string,
  label: string,
  rescType: string,
  columnIgnore: string,
  columnShow: string,
  columnTitle: string,
  isAllDeploy: string,
  data: ResItem[],
  ext: any,
  amount: number,
  isShow: boolean,
}

export type ResItem = {
  remark: string,
  rescId: string,
  rescType: string,
  dataAction: string,
  dataActionContent: string,
}

/**
 *  保存发布模型
 * @param property
 */
export async function appResByAppId(): Promise<any> {
  // 查询模型发布差异
  let diffVo: any = {}
  let modelDiffRes: any = await ModelingApi.doPublishModelDiffVer()
  if (modelDiffRes.success) {
    diffVo = modelDiffRes.result?.[0] || {}
  }
  let resTypeList: AppResType[] = []
  let columnsByResType = {}
  if (ModelingApi.getAppIdRaw()) {
    let res: any = await Api.get(APP_RES_URL + ModelingApi.getAppIdRaw())
    if (res.success) {
      columnsByResType = getColumnsByResType(res.ext)
      let resTypeExt: any = res.ext || {}
      let resultData = res.result[0] || {}

      _.keys(resultData).forEach(key => {
        let columnIgnore = resTypeExt[key].column_ignore
        let columnShow = resTypeExt[key].column_show
        let columnTitle = resTypeExt[key].column_title
        let isAllDeploy = resTypeExt[key].is_all_deploy
        let isShow = resTypeExt[key].is_show
        let itemName = resTypeExt[key].itemname
        let primaryKey = resTypeExt[key].primaryKey

        let resData: ResItem[] = []
        let typeData = resultData[key] || []

        typeData.forEach((item: any) => {
          resData.push({
            rescType: key,
            rescId: item[primaryKey],
            remark: item[columnTitle],
            dataAction: '',
            dataActionContent: '',
            ...item,
          })
        })

        let resTypeItem: any = {
          label: itemName,
          value: key,
          rescType: key,
          data: resData,
          amount: resData.length,
          columnIgnore,
          columnShow,
          columnTitle,
          isAllDeploy,
          isShow,
        }

        if (DYNAMIC_MODEL == key) {
          resTypeItem.ext = diffVo || {}
          resTypeItem.amount = diffVo.news.length + diffVo.updates.length + diffVo.deletes.length
        }

        resTypeItem.isShow = resTypeItem.isShow && resTypeItem.amount > 0
        resTypeList.push(resTypeItem)
      })

    } else {
      message.error(res.message)
    }
  }

  return {
    resTypeList,
    columnsByResType
  }
}


/**
 * 新增发布
 * @param record
 */
export async function oneKeyDeploy(record: any) {
  let {appId, id} = record
  return await Api.get(ONE_KEY_DEPLOY + "?appId=" + appId + "&depId=" + id)
}

/**
 * 新增发布
 * @param params
 */
export async function appPublish(params: any) {
  return await Api.post(DEPLOY_URL, params)
}

/**
 * 删除发布
 * @param id
 */
export async function deletePublish(id: any) {
  return await Api.delete(DEPLOY_URL, [id]);
}

/**
 * 下载发资源
 * @param id
 */
export async function downloadPublish(appId: any, id: any) {
  const link = document.createElement('a');
  link.href =  DEPLOY_EXPORT_URL + '?appId=' + appId + '&depId=' + id;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * 发布详情
 * @param id
 */
export async function appPublishDetail(record: any) {
  let {appId, id, version} = record
  let diffVo: any = {}
  let modelDiffRes: any = await ModelingApi.doPublishModelDiffVer(version)
  if (modelDiffRes.success) {
    diffVo = modelDiffRes.result?.[0] || {}
  }
  let res: any = await Api.get(DEPLOY_DETAIL_URL + "?depId=" + id + "&appId=" + appId + "&rescType=")
  let resTypeList: AppResType[] = []
  let columnsByResType: any = {}
  if (res.success) {
    let resTypeExt: any = res.ext || {}
    let resultData = res.result[0] || {}
    columnsByResType = getColumnsByResType(res.ext)

    _.keys(resultData).forEach((type: any) => {
      let itemName = resTypeExt[type]['itemname']
      let isShow = resTypeExt[type]['is_show']
      let dataLength = resultData[type].length || 0
      let resTypeItem: any = {
        label: itemName,
        value: type,
        rescType: type,
        data: resultData[type],
        amount: dataLength,
        isShow: isShow && dataLength > 0,
      }

      if (DYNAMIC_MODEL == type && diffVo.news) {
        resTypeItem.ext = diffVo
        resTypeItem.amount = diffVo.news.length + diffVo.updates.length + diffVo.deletes.length
        resTypeItem.isShow = resTypeItem.amount > 0
      }

      resTypeList.push(resTypeItem)
    })
  }

  return {
    resTypeList,
    columnsByResType
  }
}

/**
 * 资源类型对应的表头
 * @param ext
 */
function getColumnsByResType(ext: any = {}) {
  let columnsMap: any = {}
  _.keys(ext).forEach(key => {
    let columns: any = []
    let {column_name, column_title} = ext[key]
    let columnNames = column_name.split(",") || []
    let columnTitles = column_title.split(",") || []
    for (let i = 0; i < columnNames.length; i++) {
      if (columnTitles.length > i) {
        let key = uuid2();
        columns.push({
          title: columnTitles[i],
          dataIndex: columnNames[i],
          key: key,
          width: 255,
          ellipsis: true,
        })
      }
    }

    columnsMap[key] = columns
  })

  return columnsMap
}

export const getAppOptionsByAppid:any = async () => {
  let appId = getAppId();
  let res: any = await Api.get(GET_APP_BY_APPID_URL + "?appId=" + appId);
  let options: any = [];
  if (res.success && res.result) {
    res.result.forEach((item: any) => {
      let checked = item.appType == "BackendApp" || item.appType == "IntegrationApp";
      options.push({label: item.name , value: item.id, checked});
    });
  }

  return options;
}

export const importApp = async (formData:any) => {
  let res: any = await Api.post(IMPORT_APP_URL, formData);
  return res;
}
