import React from "react";
import type {ControlData, ControlProps} from "./BaseControl";
import BaseControl from "./BaseControl";
import moment from "moment-timezone";
import type {WidgetProps} from "widgets/BaseWidget";
import {isDynamicValue} from "utils/DynamicBindingUtils";
import {datePickerShowTime, isTimePicker, pickerMap, YYYY_MM_DD} from "widgets/DatePickerWidget2/constants";
import type {DatePickerProps} from 'antd';
import {DatePicker} from "antd";
import styled from "styled-components";

const DatePickerWrapper = styled(DatePicker)<any>`
  width: 100%;
  border: 1px solid var(--ads-v2-color-border-emphasis);
  border-radius: 5px;
  box-shadow:none !important;
  height: 36px;

  .ant-picker-focused, &:hover {
    border-color: var(--ads-v2-color-border-emphasis-plus) !important;
  }

  ${props => props.focused && `
    border: 1px solid var(--ads-v2-color-border-emphasis-plus);
  `}
`

class DatePicker2Control extends BaseControl<DatePickerWidget2Props, DatePickerControlState> {
  now = moment();
  year = this.now.get("year");
  maxDate: Date = this.now
    .clone()
    .set({month: 11, date: 31, year: this.year + 100})
    .toDate();
  minDate: Date = this.now
    .clone()
    .set({month: 0, date: 1, year: this.year - 150})
    .toDate();

  private wrapperRef = React.createRef<HTMLInputElement>();
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props: DatePickerWidget2Props) {
    super(props);
    this.state = {
      selectedDate: props.propertyValue,
      focused: false,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  }

  private handleKeydown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "Enter":
      case " ":
        if (document.activeElement === this.wrapperRef?.current) {
          this.inputRef?.current?.focus();
          e.preventDefault();
        }
        break;
      case "Escape":
        if (document.activeElement === this.inputRef?.current) {
          this.wrapperRef?.current?.focus();
          e.preventDefault();
        }
        break;
    }
  };

  onChange: DatePickerProps['onChange'] = (date, dateString) => {
    this.setState({selectedDate: dateString});
    this.updateProperty(this.props.propertyName, dateString);
  };

  render() {
    const dateFormat = this.props.selectId || YYYY_MM_DD;
    const isValid = this.state.selectedDate
      ? this.validateDate(moment(this.state.selectedDate, dateFormat).toDate())
      : true;
    const value =
      this.props.propertyValue && isValid
        ? moment(this.state.selectedDate, dateFormat)
        : null;

    return (
      <div ref={this.wrapperRef} tabIndex={0} className='datePicker2Control'>
        <DatePickerWrapper
          className='z-DatePickerWidget2'
          popupClassName='datePicker2Control__popover'
          format={dateFormat}
          onChange={this.onChange}
          value={value}
          placeholder={dateFormat}
          onFocus={() => {
            this.setState({focused: true})
          }}
          onBlue={() => {
            this.setState({focused: false})
          }}
          focused={this.state.focused}
          picker={pickerMap[dateFormat]}
          showTime={datePickerShowTime(dateFormat)}
          showNow={!!datePickerShowTime(dateFormat)}
          showToday={!isTimePicker(dateFormat)}
        />
      </div>
    );
  }

  getValidDate = (date: string, format: string) => {
    const _date = moment(date, format);
    return _date.isValid() ? _date.toDate() : undefined;
  };

  /**
   * here we put the selected state into state
   * before putting it into state, we check if widget date is in range
   * of property value ( min /max range )
   *
   * @param date
   */
  onDateSelected = (date: Date | null, isUserChange: boolean): void => {
    if (isUserChange) {
      const selectedDate = date ? this.formatDate(date) : undefined;
      const isValid = date ? this.validateDate(date) : true;
      if (!isValid) return;
      // if everything is ok, put date in state
      this.setState({selectedDate: selectedDate});
      this.updateProperty(this.props.propertyName, selectedDate);
    }
  };

  /**
   * checks if date is of valid date format
   */
  validateDate = (date: Date): boolean => {
    const dateFormat = this.props.selectId || YYYY_MM_DD;
    return date ? moment(date, dateFormat).isValid() : true;
  };

  formatDate = (date: Date): string => {
    const dateFormat = this.props.selectId || YYYY_MM_DD;
    let fmtDate = moment(date).format(dateFormat);
    return fmtDate;
  };

  parseDate = (dateStr: string): Date | null => {
    if (!dateStr) {
      return null;
    } else {
      const dateFormat = this.props.selectId || YYYY_MM_DD;
      const date = moment(dateStr, dateFormat);

      if (date.isValid()) return moment(dateStr, dateFormat).toDate();
      else return moment().toDate();
    }
  };

  static canDisplayValueInUI(config: ControlData, value: any): boolean {
    return !isDynamicValue(value);
  }

  static getControlType() {
    return "DATE_PICKER2";
  }
}

export interface DatePickerWidget2Props extends ControlProps {
  placeholderText: string;
  propertyValue: string;
  widgetProperties: WidgetProps;
}

interface DatePickerControlState {
  selectedDate?: string;
  focused: boolean;
}

export default DatePicker2Control;
