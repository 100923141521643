import React from "react";
import styled from "styled-components";
import type { ControlData, ControlProps } from "./BaseControl";
import BaseControl from "./BaseControl";
import type { SegmentedControlOption } from "design-system";
import { SegmentedControl } from "design-system";
import type { DSEventDetail } from "utils/AppsmithUtils";
import {
  DSEventTypes,
  DS_EVENT,
  emitInteractionAnalyticsEvent,
} from "utils/AppsmithUtils";
import {getBtnTypeTheme} from "../../widgets/WidgetUtils";
import {connect} from "react-redux";

const StyledSegmentedControl = styled(SegmentedControl)`
  > .ads-v2-segmented-control__segments-container {
    flex: 1 1 0%;
  }
`;

export interface IconTabControlProps extends ControlProps {
  options: SegmentedControlOption[];
  defaultValue: string;
  fullWidth: boolean;
  relDefaultVale?:any;
  btnTheme?:any;
  primaryColorTheme?:any;
}

class IconTabControl extends BaseControl<IconTabControlProps> {
  componentRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.componentRef.current?.addEventListener(
      DS_EVENT,
      this.handleAdsEvent as (arg0: Event) => void,
    );
  }

  componentWillUnmount() {
    this.componentRef.current?.removeEventListener(
      DS_EVENT,
      this.handleAdsEvent as (arg0: Event) => void,
    );
  }

  handleAdsEvent = (e: CustomEvent<DSEventDetail>) => {
    if (
      e.detail.component === "ButtonGroup" &&
      e.detail.event === DSEventTypes.KEYPRESS
    ) {
      emitInteractionAnalyticsEvent(this.componentRef.current, {
        key: e.detail.meta.key,
      });
      e.stopPropagation();
    }
  };

  selectOption = (value: string, isUpdatedViaKeyboard = false) => {
    if (this.props.propertyValue === value) {
      this.updateProperty(
        this.props.propertyName,
        this.props.defaultValue,
        isUpdatedViaKeyboard,
      );
    } else {
      this.updateProperty(this.props.propertyName, value, isUpdatedViaKeyboard);
      // let btnTypeTheme = getBtnTypeTheme(value);
      // let btnTheme = this.props.btnTheme;
      // let buttonColor = btnTypeTheme == 'primary' ? this.props.primaryColorTheme : btnTheme.bgColor[btnTypeTheme];
      // if (btnTheme) {
      //   let fontColorWidget = this.props.widgetProperties.textColor;
      //   let fontSizeWidget = this.props.widgetProperties.fontSize;
      //   let fontStyleWidget = this.props.widgetProperties.fontStyle;
      //   let buttonColorWidget = this.props.widgetProperties.buttonColor;
      //   !buttonColorWidget && this.updateProperty('buttonColor', buttonColor, isUpdatedViaKeyboard);
      //   !fontColorWidget && this.updateProperty('textColor', btnTheme.fontColor[btnTypeTheme], isUpdatedViaKeyboard);
      //   !fontSizeWidget && this.updateProperty('fontSize', btnTheme.fontSize[btnTypeTheme], isUpdatedViaKeyboard);
      //   !fontStyleWidget && this.updateProperty('fontStyle', btnTheme.fontStyle[btnTypeTheme], isUpdatedViaKeyboard);
      // }
    }
  };

  render() {
    return (
      <StyledSegmentedControl
        isFullWidth={this.props.fullWidth}
        onChange={this.selectOption}
        options={this.props.options}
        ref={this.componentRef}
        value={this.props.propertyValue}
      />
    );
  }

  static getControlType() {
    return "ICON_TABS";
  }

  static canDisplayValueInUI(config: ControlData, value: any): boolean {
    if (
      (config as IconTabControlProps)?.options
        ?.map((x: { value: string }) => x.value)
        .includes(value)
    )
      return true;
    return false;
  }
}
const stateToProps = (state:any)=>{
  return {
    // btnTheme: state.ui.appTheming.selectedTheme.properties.button,
    // primaryColorTheme: state.ui.appTheming.selectedTheme.properties.colors.primaryColor,
  }
}
export default connect(stateToProps, null)(IconTabControl);
