import {Modal} from 'antd';
import React, {forwardRef, useImperativeHandle, useState} from 'react';
import TextArea from "antd/es/input/TextArea";

const SqlErrMsg: React.FC<any> = forwardRef((
  {
    mxLoadModel,
  }, ref) => {
  useImperativeHandle(ref, () => ({
    showModal,
  }));
  const [sqlMsg, setSqlMsg] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (sqlMsg: any) => {
    setSqlMsg(sqlMsg);
    setIsModalOpen(true);
  }

  function handleCancel() {
    setIsModalOpen(false);
  }

  return (
    <>
      <Modal
        className={"createModel"}
        title="SQL执行异常信息" onCancel={handleCancel} open={isModalOpen}
        footer={null}
        maskClosable={false}
        width={700}
        style={{height: "400px"}}
      >
        <TextArea value={sqlMsg} rows={18}></TextArea>
      </Modal>
    </>
  );
});

export default SqlErrMsg;
