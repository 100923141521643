import React from 'react';
import {AtButton} from 'taro-ui';
import styled from "styled-components";
const ButtonWrapper = styled.div<{
  color: string;
  bgColor: string;
  borderColor: string;
  fontSize: number;
}>`
  width: 100%;
  height: 100%;
  .at-button{
    background-color: ${({bgColor})=> bgColor};
    border-color: ${({bgColor})=> bgColor};
    color: ${({color})=> color};
    font-size: ${({fontSize})=> fontSize}px;
    &:hover{
      opacity: .9;
    }
  }
`;
const TButtonWidget = (props:any) => {
  const {
    label,
    onClick,
    buttonVariant,
    buttonSize,
    isCircle,
    isDisabled,
    btnLoading,
    bgColor,
    fontSize,
    color,
    borderColor
  } = props;
  return (
    <ButtonWrapper bgColor={bgColor} fontSize={fontSize} color={color} borderColor={borderColor}>
      <AtButton
        loading={btnLoading}
        type={buttonVariant}
        onClick={onClick}
        size={buttonSize}
        circle={isCircle}
        disabled={isDisabled}
      >{label}</AtButton>
    </ButtonWrapper>
  );
};

export default TButtonWidget;
