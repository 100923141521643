import {Alignment} from "@blueprintjs/core";
import {compact, isArray, isNumber} from "lodash";
import React from "react";

import {LabelPosition} from "components/constants";
import {EventType} from "constants/AppsmithActionConstants/ActionConstants";
import type {TextSize, WidgetType} from "constants/WidgetConstants";
import type {ValidationResponse} from "constants/WidgetValidation";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import type {AutocompletionDefinitions} from "widgets/constants";
import {GRID_DENSITY_MIGRATION_V1} from "widgets/constants";
import {DefaultAutocompleteDefinitions, isAutoHeightEnabledForWidget,} from "widgets/WidgetUtils";
import type {WidgetProps, WidgetState} from "../../BaseWidget";
import BaseWidget from "../../BaseWidget";
import RadioGroupComponent from "../component";
import type {RadioOption} from "../constants";
import {isAutoLayout} from "utils/autoLayout/flexWidgetUtils";

/**
 * Validation rules:
 * 1. This property will take the value in the following format: Array<{ "label": "string", "value": "string" | number}>
 * 2. The `value` property should consists of unique values only.
 * 3. Data types of all the value props should be the same.
 */
export function optionsCustomValidation(
  options: unknown,
  props: any,
  _: any,
): ValidationResponse {
  const validationUtil = (
    options: { label: string; value: string | number }[],
    _: any,
  ) => {
    let _isValid = true;
    let message = {name: "", message: ""};
    let valueType = "";
    const uniqueLabels: Record<string | number, string> = {};

    for (let i = 0; i < options.length; i++) {
      const {label, value} = options[i];
      if (!valueType) {
        valueType = typeof value;
      }
      //Checks the uniqueness all the values in the options
      if (!uniqueLabels.hasOwnProperty(value)) {
        uniqueLabels[value] = "";
      } else {
        _isValid = false;
        message = {
          name: "ValidationError",
          message: "path:value must be unique. Duplicate values found",
        };
        break;
      }

      //Check if the required field "label" is present:
      if (!label) {
        _isValid = false;
        message = {
          name: "ValidationError",
          message:
            "Invalid entry at index: " + i + ". Missing required key: label",
        };
        break;
      }

      //Validation checks for the the label.
      if (
        _.isNil(label) ||
        label === "" ||
        (typeof label !== "string" && typeof label !== "number")
      ) {
        _isValid = false;
        message = {
          name: "ValidationError",
          message:
            "Invalid entry at index: " +
            i +
            ". Value of key: label is invalid: This value does not evaluate to type string",
        };
        break;
      }

      //Check if all the data types for the value prop is the same.
      if (typeof value !== valueType) {
        _isValid = false;
        message = {
          name: "TypeError",
          message: "All value properties in options must have the same type",
        };
        break;
      }

      //Check if the each object has value property.
      if (_.isNil(value)) {
        _isValid = false;
        message = {
          name: "TypeError",
          message:
            'This value does not evaluate to type Array<{ "label": "string", "value": "string" | number }>',
        };
        break;
      }
    }

    return {
      isValid: _isValid,
      parsed: _isValid ? options : [],
      messages: [message],
    };
  };

  const invalidResponse = {
    isValid: false,
    parsed: [],
    messages: [
      {
        name: "TypeError",
        message:
          'This value does not evaluate to type Array<{ "label": "string", "value": "string" | number }>',
      },
    ],
  };
  try {
    if (_.isString(options)) {
      options = JSON.parse(options as string);
    }

    if (Array.isArray(options)) {
      return validationUtil(options, _);
    } else {
      return invalidResponse;
    }
  } catch (e) {
    return invalidResponse;
  }
}

function defaultOptionValidation(
  value: unknown,
  props: any,
  _: any,
): ValidationResponse {
  //Checks if the value is not of object type in {{}}
  if (_.isObject(value)) {
    return {
      isValid: false,
      parsed: JSON.stringify(value, null, 2),
      messages: [
        {
          name: "TypeError",
          message: "This value does not evaluate to type: string or number",
        },
      ],
    };
  }

  //Checks if the value is not of boolean type in {{}}
  if (_.isBoolean(value)) {
    return {
      isValid: false,
      parsed: value,
      messages: [
        {
          name: "TypeError",
          message: "This value does not evaluate to type: string or number",
        },
      ],
    };
  }

  return {
    isValid: true,
    parsed: value,
  };
}

class RadioGroupWidget extends BaseWidget<RadioGroupWidgetProps, WidgetState> {
  constructor(props: RadioGroupWidgetProps) {
    super(props);
    let dictId = super.getDictId();
    if (dictId) {
      super.initDictOptions(dictId);
    }
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc":
        "Radio widget lets the user choose only one option from a predefined set of options. It is quite similar to a SingleSelect Dropdown in its functionality",
      "!url": "https://docs.appsmith.com/widget-reference/radio",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      options: "[$__dropdownOption__$]",
      selectedOptionValue: "string",
      isRequired: "bool",
    };
  }

  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('RadioGroupWidget');
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "标签",
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "label",
            label: "文本",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Sets the label position of the widget",
            propertyName: "labelPosition",
            label: "位置",
            controlType: "ICON_TABS",
            fullWidth: true,
            hidden: isAutoLayout,
            options: [
              {label: "自动", value: LabelPosition.Auto},
              {label: "居左", value: LabelPosition.Left},
              {label: "居上", value: LabelPosition.Top},
            ],
            defaultValue: LabelPosition.Top,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Sets the label alignment of the widget",
            propertyName: "labelAlignment",
            label: "对齐方式",
            controlType: "LABEL_ALIGNMENT_OPTIONS",
            fullWidth: false,
            options: [
              {
                startIcon: "align-left",
                value: Alignment.LEFT,
              },
              {
                startIcon: "align-right",
                value: Alignment.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
            hidden: (props: RadioGroupWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          },
          {
            helpText:
              "Sets the label width of the widget as the number of columns",
            propertyName: "labelWidth",
            label: "宽度（以列为单位）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
            hidden: (props: RadioGroupWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          },
        ],
      },
      {
        sectionName: "数据",
        children: [
          {
            helpText: "Select Dict.",
            propertyName: "options",
            label: "选项",
            controlType: "SELECT_DICT_CONTROL",
            placeholderText: '从数据字典中选择',
            isBindProperty: true,
            isTriggerProperty: false,
            selectId: (props: RadioGroupWidgetProps) => props.selectId,
            dependencies: ["selectId", "defaultOptionLabel", "defaultOptionValue"]
          },
        ],
      },
      {
        sectionName: "验证",
        children: [
          {
            propertyName: "isRequired",
            label: "是否必填",
            helpText: "Makes input to the widget mandatory",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      },
      {
        sectionName: "一般",
        children: [
          {
            helpText: "Show help text or details about current input",
            propertyName: "labelTooltip",
            label: "提示",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isVisible",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isDisabled",
            label: "是否禁用",
            helpText: "Disables input to this widget",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "isInline",
            helpText:
              "Whether the radio buttons are to be displayed inline horizontally",
            label: "启用内联",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
          {
            propertyName: "animateLoading",
            label: "加载动画",
            controlType: "SWITCH",
            helpText: "Controls the loading of the widget",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.BOOLEAN},
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "labelTextColor",
            label: "字体颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelTextSize",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
          {
            propertyName: "labelStyle",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
      {
        sectionName: "选项文字",
        children: [
          {
            propertyName: "it_color",
            label: "字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "it_size",
            label: "字体大小",
            helpText: "Control the font size of the label associated",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            hidden: isAutoLayout,
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "it_style",
            label: "加粗&斜体",
            helpText: "Control if the label should be bold or italics",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "alignment",
            helpText: "Sets the alignment of the widget",
            label: "对齐方式",
            controlType: "ICON_TABS",
            fullWidth: true,
            isBindProperty: true,
            isTriggerProperty: false,
            options: [
              {
                startIcon: "skip-left-line",
                value: Alignment.LEFT,
              },
              {
                startIcon: "skip-right-line",
                value: Alignment.RIGHT,
              },
            ],
          },
        ],
      },
      {
        sectionName: "颜色",
        children: [
          {
            propertyName: "accentColor",
            helpText: "Sets the accent color of the widget",
            label: "强调色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap() {
    return {
      selectedOption:
        "{{_.find(this.options, { value: this.selectedOptionValue })}}",
      isValid: `{{ this.isRequired ? !!this.selectedOptionValue : true }}`,
      value: `{{this.selectedOptionValue}}`,
      getValue: `{{this.selectedOptionValue}}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedOptionValue: "defaultOptionValue",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedOptionValue: undefined,
      isDirty: false,
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      boxShadow: "none",
      labelTextColor: "{{appsmith.theme.form.label.fontColor}}",
      labelTextSize: "{{appsmith.theme.form.label.fontSize}}",
      labelStyle: "{{appsmith.theme.form.label.fontStyle}}",
    };
  }

  componentDidUpdate(prevProps: RadioGroupWidgetProps): void {
    if (
      this.props.defaultOptionValue !== prevProps.defaultOptionValue &&
      this.props.isDirty
    ) {
      this.props.updateWidgetMetaProperty("isDirty", false);
    }
  }

  getPageView() {
    const {
      alignment,
      bottomRow,
      isDisabled,
      isInline,
      isLoading,
      label,
      labelAlignment,
      labelPosition,
      labelStyle,
      labelTextColor,
      labelTextSize,
      options,
      selectedOptionValue,
      topRow,
      widgetId,
    } = this.props;

    const {componentHeight} = this.getComponentDimensions();

    return (
      <RadioGroupComponent
        accentColor={this.props.accentColor}
        alignment={alignment}
        compactMode={!((bottomRow - topRow) / GRID_DENSITY_MIGRATION_V1 > 1)}
        disabled={isDisabled}
        height={componentHeight}
        inline={Boolean(isInline)}
        isDynamicHeightEnabled={isAutoHeightEnabledForWidget(this.props)}
        key={widgetId}
        labelAlignment={labelAlignment}
        labelPosition={labelPosition}
        labelStyle={labelStyle}
        labelText={label}
        labelTextColor={labelTextColor}
        labelTextSize={labelTextSize}
        labelTooltip={this.props.labelTooltip}
        labelWidth={this.getLabelWidth()}
        loading={isLoading}
        onRadioSelectionChange={this.onRadioSelectionChange}
        options={isArray(options) ? compact(options) : []}
        required={this.props.isRequired}
        selectedOptionValue={selectedOptionValue}
        widgetId={widgetId}
        it_color={this.props.it_color}
        it_size={this.props.it_size}
        it_style={this.props.it_style}
      />
    );
  }

  onRadioSelectionChange = (updatedValue: string) => {
    let newVal;
    if (isNumber(this.props.options[0].value)) {
      newVal = parseFloat(updatedValue);
    } else {
      newVal = updatedValue;
    }
    // Set isDirty to true when the selection changes
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    const _str = super.reDynamicStringFn(this.props.onSelectionChange);
    this.props.updateWidgetMetaProperty("selectedOptionValue", newVal, {
      triggerPropertyName: "onSelectionChange",
      dynamicString: _str,
      event: {
        type: EventType.ON_OPTION_CHANGE,
      },
      widgetCache: {
        [`${this.props.widgetName}.getValue`]: newVal
      }
    });
  };

  static getWidgetType(): WidgetType {
    return "RADIO_GROUP_WIDGET";
  }
}

export interface RadioGroupWidgetProps extends WidgetProps {
  options: RadioOption[];
  selectId?: any,
  selectedOptionValue: string;
  onSelectionChange: string;
  defaultOptionValue: string;
  isRequired?: boolean;
  isDisabled: boolean;
  isInline?: boolean;
  alignment: Alignment;
  label: string;
  labelPosition?: LabelPosition;
  labelAlignment?: Alignment;
  labelWidth?: number;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  labelStyle?: string;
  isDirty: boolean;
  accentColor: string;
}

export default RadioGroupWidget;
