import * as React from "react";
import type { ComponentProps } from "widgets/BaseComponent";
import { Select } from "antd";
import { getIconPark } from "components/propertyControls/IconSelectControl";
import styled from "styled-components";
import type { BoxShadow } from "components/designSystems/appsmith/WidgetStyleContainer";
import type { Color } from "constants/Colors";
import _ from "lodash";
import { DEFAULT_FONT_SIZE, FontStyleTypes, type TextSize } from "constants/WidgetConstants";
import { fontSizeUtility } from "widgets/WidgetUtils";
import {getAntdCascaderPopupStyeld, getAntdSelectPopupStyled} from "../../../widgets/widgetStyled";

const ContainerWrapper = styled.div<{
  backGroundColor?: string,
  isVisible?: boolean,
  borderWidth?: number;
  borderRadius?: string;
  boxShadow?: BoxShadow;
  borderColor?: Color;

  fontSize?: TextSize,
  color?: string,
  fontStyle?: string,
}>`
   width: 100%;
   overflow: hidden;
  .TimingWidgetContainer{
    min-width: 120px;
    height: 100%;
    display: ${({ isVisible }) => { return isVisible ? "flex" : "none" }};
    align-items: center;
    justify-content:center;
    background-color: ${({ backGroundColor }) => { return backGroundColor }};
    border-width: ${({ borderWidth }) => borderWidth || 0}px;
    border-color: ${({ borderColor }) => borderColor || "transparent"};
    border-radius: ${({ borderRadius }) => `${borderRadius || "0"}px`} !important;
    box-shadow: ${({ boxShadow }) => `${boxShadow ?? "none"}`} !important;
  }
  .TimingWidgetContainer .ant-select{
    border-radius:999px;
  }
  .TimingWidgetContainer .ant-select-selector{
    background-color:${({ backGroundColor }) => { return backGroundColor}};
    border:none;
    border-radius:999px;
  }
  .TimingWidgetContainer .ant-select-arrow{
    margin-top:-4px;
    color:white;
  }
  .TimingWidgetContainer .ant-select-selection-item{
    color:${({ color }) => { return color || 'black' }};
      font-style: ${(fontStyle: any) =>
    _.includes(fontStyle, FontStyleTypes.ITALIC) ? "italic" : ""};
      font-weight: ${(fontStyle: any) =>
    _.includes(fontStyle, "BOLD") ? "bold" : "normal"};
      font-size: ${({ fontSize }) =>
    fontSizeUtility(fontSize) || DEFAULT_FONT_SIZE};
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-color:none;
    box-shadow:none;
    border-right-width:0px;
  }
`;

class TimingComponent extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { iconName } = this.props;
    const backGroundColor = this.props.backGroundColor || this.props.primaryColorTheme;
    const dynamicStyles = `
    .antdSelectPopup {
      ${getAntdSelectPopupStyled(backGroundColor)}
    }
  `;
    return (
      <>
        <style>{dynamicStyles}</style>
        <ContainerWrapper {...this.props} backGroundColor={backGroundColor}>
          <div className="TimingWidgetContainer">
            <div style={{ backgroundColor: backGroundColor, padding: "8px", borderRadius: "999px", paddingLeft: "15px" }} onClick={this.props.onClick}>
              {getIconPark(iconName || "Refresh", "white")}
            </div>

            <Select popupClassName={'antdSelectPopup' } style={{ backgroundColor: backGroundColor, width: "100px", border: "none", color: "white" }} onChange={(value) => {
              this.props.updateWidgetProperty('times', value);
            }} value={this.props.times} options={this.props.timesOpt.map((i: any) => {
              return {
                label: i == 0 ? 'none' : `${i}s`,
                value: i
              }
            })} />
          </div>
        </ContainerWrapper>
      </>
    );
  }
}

export interface TimingComponentProps extends ComponentProps {
  updateWidgetProperty: (p: string, v: any) => void,
  onClick: () => void,
  timesOpt: any[],
  times: number
}

export default TimingComponent;
