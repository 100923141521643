import React, { useCallback, useEffect, useRef } from "react";
import * as echarts from 'echarts';
import _ from 'lodash';
import { EChartsType } from "echarts/types/dist/echarts";
const  formatData: any = (data:any,tabsObj:any) => {
  let dataCfg:any=[];
  for (const key in tabsObj) {
    if (tabsObj.hasOwnProperty(key)) { 
      let obj:any=tabsObj[key];
      dataCfg.push({name:obj.label,isVisible:obj.isVisible,color:obj.color});
    }
  }
  let _data:any= _.cloneDeep(data);
  let _rData:any=[];
  _.each(_data,(itm:any,idx:any)=>{
    let cfg:any=dataCfg.filter((obj:any)=>{
      return obj.name==itm.name;
    });
    if(cfg.length>0){
      cfg=cfg[0];
      if(cfg.color){
        _data[idx].itemStyle={color:cfg.color};
      } 
      if(cfg.isVisible){
        _rData.push(_data[idx]);
      }
    }
    else{
      _rData.push(_data[idx]);
    }
  })
  return _rData;
}
function EchartsComponent(props: any) {
  const { currentData, maincanvasWidth, rightColumn, leftColumn,
    isDownload, fontColor, isDataView, backgroundColor, fontStyle, fontFamily,
    borderColor, borderWidth, boxShadow, borderRadius, isTitle, title,isLegend,radius,sType,tabsObj,roundSize,insideSize ,isShowTab,itemBorderColor,itemBorderWidth} = props;
  const chartRef = useRef(null);
  const chartInstance = useRef<EChartsType>();
  const temp: any = [{
    // "title": {
    //   "text": title,
    //   "show": isTitle,
    //   "left": 'center'
    // },
    "tooltip": {
      "trigger": 'item',
       show:true
    },
    "toolbox": {
      "feature": {
        "saveAsImage": { "title": "下载", "show": isDownload },
        "dataView": { "title": "数据视图", "show": isDataView, "lang": ['数据视图', '关闭', '刷新'] }
      }
    },
    "legend": {
      "orient": 'vertical',
      "left": 'left',
      "show": isLegend,
      "textStyle": {
        "color": fontColor,
        "fontStyle": fontStyle?.indexOf("italic") > -1 ? "italic" : "",
        "fontWeight": fontStyle?.indexOf("bold") > -1 ? "bold" : "normal",
        "fontFamily": fontFamily,
      },
    },
    "series": [
      {
        "type": 'pie',
        "radius":sType=="rose"?[radius+'%',insideSize+'%']:radius+'%',
        roseType: sType=="rose"?'radius':"",
        itemStyle: {
          borderRadius: roundSize,
          borderColor: itemBorderColor||'#ffffff',
          borderWidth: itemBorderWidth||0
        },
        "data": [],
        "emphasis": {
          "itemStyle": {
            "shadowBlur": 10,
            "shadowOffsetX": 0,
            "shadowColor": 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ],
    "backgroundColor": backgroundColor || "transparent",
    "textStyle": {
      "color": fontColor,
      "fontStyle": fontStyle?.indexOf("italic") > -1 ? "italic" : "",
      "fontWeight": fontStyle?.indexOf("bold") > -1 ? "bold" : "normal",
      "fontFamily": fontFamily,
    },
  }];


  const renderChart = () => {
    //console.log("xxxxxxxxxxxxxxx", props);
    if (!_.isArray(currentData)) {
      alert("error");
      return false;
    }
    let _currentData: any = _.cloneDeep(temp);
    let _data= formatData(currentData||[],tabsObj)||[];
    _currentData[0].series[0].data = _data;
    chartInstance.current = echarts.init(chartRef.current);
    console.log("2222222", _currentData);
    const option = {
      ..._currentData[0]
    };
    chartInstance.current.setOption(option);
  };
  useEffect(() => {
    if (chartInstance.current) {
      setTimeout(() => {
        chartInstance.current?.resize();
      }, 200);
    }
  }, [maincanvasWidth, rightColumn, leftColumn]);

  useEffect(() => {
    try{
      renderChart();
    }
    catch(e:any){

    }
    // if (!_.isEmpty(currentData)) {
    //   renderChart();
    // }
    //}, [currentData, sType, isDarkMode, color, isSmooth, isAreaStyle, isDownload, fontColor]);
  }, [props]);

  const DataDom = useCallback(() => {
    if (_.isEmpty(currentData)) {
      return <>暂无数据</>;
    } else {
      return <div ref={chartRef} style={{
        width: '100%', height: '100%', overflow: 'hidden', borderColor: borderColor || "#ccc",
        borderWidth: borderWidth, borderRadius: `${borderRadius}`, boxShadow: `${boxShadow}`
      }} />;
    }
  }, [props]);
  // }, [currentData]);

  return <DataDom />;
}

export default EchartsComponent;
