import React from "react";
import {DatePicker, TimePicker} from 'antd';
import styled from "styled-components";
import locale from 'antd/es/date-picker/locale/zh_CN';
import LabelWithTooltip, {LABEL_CONTAINER_CLASS, labelLayoutStyles} from "widgets/components/LabelWithTooltip";
import {LabelPosition} from "components/constants";
import moment from 'moment';
import {
  datePickerShowTime,
  HH_MM,
  HH_MM_SS,
  MM_DD_YYYY, MM_DD_YYYY_HH_MM, MM_DD_YYYY_HH_MM_SS,
  pickerMap,
  YYYY,
  YYYY_MM,
  YYYY_MM_DD,
  YYYY_MM_DD_HH_MM,
  YYYY_MM_DD_HH_MM_SS
} from "widgets/DatePickerWidget2/constants";
import {getEndOfMonth, hexToRgb, uuid2} from "@byk/utils/Utils";
import {FontStyleTypes} from "@design-system/widgets-old";
import {getAntdDatePickerPopupStyled} from "../../../widgets/widgetStyled";
import {lightenColor} from "../../../widgets/WidgetUtils";

const {RangePicker} = DatePicker;

export const DivContainer = styled.div<any>`
  ${labelLayoutStyles}

  & .${LABEL_CONTAINER_CLASS} {
    align-self: center;
    ${({labelPosition}) =>
  labelPosition === LabelPosition.Left && "min-height: 30px"};
  }
  .inputReadonly input{color:#000000d9 !important}
  .inputReadonly .ant-picker-suffix{display:none}

  .ant-picker {
     border-radius: ${({borderRadius}) => borderRadius || ' 0.375rem'};
     box-shadow: ${({boxShadow}) => boxShadow || 'none'};
     background:  ${({ib_bgColor}) => ib_bgColor ? ib_bgColor : 'var(--wds-color-bg)'} !important;
     border-color: ${({ ib_borderColor }) => ib_borderColor} !important;

    .ant-picker-input > input {
      color: ${({it_color}) => {
        return (it_color ? `${it_color}` : "var(--wds-color-text)") + ' !important'
      }};
      font-size: ${({it_size}) => {
        return (it_size ? `${it_size}` : "14px") + ' !important'
      }};
      font-style: ${({it_style}) => {
        return (it_style?.includes(FontStyleTypes.ITALIC) ? "italic" : "") + ' !important'
      }};
      font-weight: ${({it_style}) => {
        return (it_style?.includes(FontStyleTypes.BOLD) ? "bold" : "") + ' !important'
      }};
    }

    &:focus-within {
      border-color: ${({accentColor}) => accentColor} !important;
      box-shadow: ${({accentColor}) => `0px 0px 0px 2px ${lightenColor(accentColor)} !important;`};
    }
  }

  .ant-picker-separator, .anticon-calendar, .anticon-close-circle {
    svg {
      fill:  ${(props) => props.ib_iconColor ? props.ib_iconColor : "var(--wds-color-icon)"} !important;
      path {
        fill: ${({ $isDisabled, ib_iconColor}) =>
          $isDisabled
            ? "var(--wds-color-icon-disabled)"
            : ib_iconColor ? ib_iconColor : "var(--wds-color-icon)"} !important;
        stroke: ${({ $isDisabled, ib_iconColor }) =>
          $isDisabled
            ? "var(--wds-color-icon-disabled)"
            :  ib_iconColor ? ib_iconColor : "var(--wds-color-icon)"} !important;
      }
    }
  }

  .ant-picker-range .ant-picker-active-bar {
     background: ${(props) => props.accentColor} !important;
  }
`;

function RangeDatePickerComponent(props: any) {
  const {
    borderRadius, boxShadow, picker, disabled,
    placeholderText1, placeholderText2,
    //LABEL START
    compactMode,
    isDisabled,
    isLoading,
    isRequired,//
    labelAlignment,//11
    labelPosition,//11
    labelStyle,//11
    labelText,//11
    labelTextColor,//11
    labelTextSize,//11
    labelTooltip,//11
    labelWidth,//11
    onRadioSelectionChange,
    value,
    accentColor,
    defaultStartDate,
    defaultEndDate,
    isReadonly,
    dateFormat,
    isDirty,
    it_color,
    it_size,
    it_style,
    ib_bgColor,
    ib_borderColor,
    ib_iconColor,
  } = props;
  const key=uuid2();
  const isMinuteOrSecond:boolean=(dateFormat=="HH:mm"||dateFormat=="HH:mm:ss");
  const isNotMinuteAndSecond:boolean=(dateFormat!="HH:mm"&&dateFormat!="HH:mm:ss");

  const getPicker=()=>{
    let _props:any={};
    let type:any=pickerMap[dateFormat];
    if(type=="showHour"||type=="showTime"){
      _props.showTime=true;
    }
    else{
      _props.picker=type;
    }
    return _props
  }
  //不同时间格式，对应补全尾数
  const handleChange = (val: any) => {
    if (val) {
      let startDate = val[0].format(dateFormat),
          endDate = val[1].format(dateFormat);

      let now = moment().format(YYYY_MM_DD);

      if (dateFormat==YYYY) {
        startDate = `${startDate}-01-01 00:00:00`;
        endDate = `${endDate}-12-31 23:59:59`;
      } else if(dateFormat==YYYY_MM){
        startDate = `${startDate} 00:00:00`;
        endDate = `${endDate} 23:59:59`;
        endDate =getEndOfMonth(endDate)
      } else if(dateFormat==YYYY_MM_DD || dateFormat==MM_DD_YYYY){
        startDate = `${startDate} 00:00:00`;
        endDate = `${endDate} 23:59:59`;
      } else if(dateFormat==YYYY_MM_DD_HH_MM || dateFormat==MM_DD_YYYY_HH_MM){
        startDate = `${startDate}:00`;
        endDate = `${endDate}:59`;
      } else if(dateFormat==YYYY_MM_DD_HH_MM_SS || dateFormat==MM_DD_YYYY_HH_MM_SS){
        startDate = `${startDate}`;
        endDate = `${endDate}`;
      } else if(dateFormat==HH_MM_SS){
        startDate = `${now.toString()} ${startDate}:00`;
        endDate = `${now.toString()} ${endDate}:59`;
        endDate =getEndOfMonth(endDate)
      } else if(dateFormat==HH_MM){
        startDate = `${now.toString()} ${startDate}:00`;
        endDate = `${now.toString()} ${endDate}:59`;
        endDate =getEndOfMonth(endDate)
      }

      onRadioSelectionChange([
        moment(startDate).valueOf(),
        moment(endDate).valueOf()
      ]);
    } else {
      onRadioSelectionChange([]);
    }
  }
  const valueDateFormat = (v:any)=>{
    let _v:Array<any> = [];
    if(Array.isArray(v)){
      _v = v;
    }else{
      _v = v.split('~');
    }
    _v = _v.map((i:any)=>{
      if (i) {
        if(typeof i === 'number'){
          return i;
        }
        if(i.includes('-')){
          return i;
        }
        return parseInt(i, 10);
      }
    })

    let ret:any = [null, null];
    if (_v[0]) {
      ret[0] = moment(_v[0]);
    }
    if (_v[1]) {
      ret[1] = moment(_v[1]);
    }

    return ret;
  }
  const valueFormat = (v:any)=>{
    if(v && v.length > 0){
      return valueDateFormat(v);
    }else if(!isDirty){
      return valueDateFormat([defaultStartDate, defaultEndDate]);
    }else{
      return v;
    }
  }

  // 动态生成CSS样式
  const dynamicStyles = `
    .rangeDatePicker2Widget__popover {
      ${getAntdDatePickerPopupStyled(accentColor)}
    }
  `;

  return (
    <>
      <style>{dynamicStyles}</style>
      <DivContainer compactMode={compactMode}
                    data-testid="radiogroup-container"
                    labelPosition={labelPosition}
                    borderRadius={borderRadius}
                    boxShadow={boxShadow}
                    it_color={it_color}
                    it_size={it_size}
                    it_style={it_style}
                    ib_bgColor={ib_bgColor}
                    ib_borderColor={ib_borderColor}
                    ib_iconColor={ib_iconColor}
                    accentColor={accentColor}
      >
        {labelText && (
          <LabelWithTooltip
            className={`datepicker-label`}
            text={labelText}
            cyHelpTextClassName="datepicker-tooltip"
            alignment={labelAlignment}
            color={labelTextColor}
            compact={compactMode}
            disabled={isDisabled}
            fontSize={labelTextSize}
            fontStyle={labelStyle}
            helpText={labelTooltip}
            loading={isLoading}
            position={labelPosition}
            width={labelWidth}
            isRequired={isRequired}
          />

        )}
        {
          isNotMinuteAndSecond&&
          <>
            <RangePicker
              key={key}
              popupClassName="rangeDatePicker2Widget__popover"
              value={valueFormat(value)} className={isReadonly ? "inputReadonly" : ""}
              style={{width: "100%"}}
              {...getPicker()}
              allowClear
              placeholder={isReadonly ? ["", ""] : [placeholderText1, placeholderText2]}
              bordered={!isReadonly}
              locale={locale}
              disabled={disabled || isReadonly}
              onChange={(v: any) => handleChange(v)}
              format={dateFormat}
              showTime={datePickerShowTime(dateFormat)}
              showNow={!!datePickerShowTime(dateFormat)}
              showToday={true}
            />
          </>
        }
        {
          isMinuteOrSecond&&
          <>
            <TimePicker.RangePicker
              key={key}
              popupClassName="rangeDatePicker2Widget__popover"
              value={valueFormat(value)} className={isReadonly ? "inputReadonly" : ""}
              style={{width: "100%"}}
              allowClear
              placeholder={isReadonly ? ["", ""] : [placeholderText1, placeholderText2]}
              bordered={!isReadonly}
              locale={locale}
              disabled={disabled || isReadonly}
              onChange={(v: any) => handleChange(v)}
              format={dateFormat}
            />
          </>
        }

      </DivContainer>
    </>
  )
}

export default RangeDatePickerComponent;
