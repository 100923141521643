import * as React from "react";
import { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { Tree } from 'antd';
import { DEFAULT_FONT_SIZE, type TextSize } from "constants/WidgetConstants";
import { fontSizeUtility } from "widgets/WidgetUtils";


const ContainerWrapper = styled.div<{
  backGroundColor?: string,
  oneBackGroundColor?: string,
  oneFontSize?: TextSize,
  oneColor?: string,
  onefontStyle?: string,
  oneLineHeight?: number,
  twoBackGroundColor?: string,

  twoFontSize?: TextSize,
  twoColor?: string,
  twofontStyle?: string,
  twoLineHeight?: number,

  selectedBackground?: string,
  selectedFontSize?: TextSize,
  selectedColor?: string,
  selectedfontStyle?: string,
  selectedLineHeight?: number,
  fontSize?: TextSize,
  color?: string,
}>`
  .ant-tree-title{
      font-size: ${({ fontSize }) => fontSizeUtility(fontSize) || DEFAULT_FONT_SIZE};
      color:${({ color }) => { return color || 'black' }};
  }
  .ant-tree{
    background:${({ backGroundColor }) => { return backGroundColor || 'white' }};
  }


`;

const TreeWidget = (props: any) => {
  let {
    currentData, isChecked, disabled, defaultExpandAll, blockNode, fieldNames, showLine,
    onSelect
  } = props;
  //const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(getAllKeys(currentData));
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);
  if (_.isString(currentData) && !_.isEmpty(currentData)) {
    currentData = JSON.parse(currentData);
  }

  const onExpand = (newExpandedKeys: React.Key[]) => {
    
  };
  const onSelectEvent = (key: any, data: any) => {
    onSelect(key, data)
  }

  const getAllKeys = (data: any, keys = []) => {
    if (defaultExpandAll != true) {
      return [];
    }
    (data || []).forEach((item: any) => {
      keys.push(item.key);
      if (item.children) {
        getAllKeys(item.children, keys);
      }
    });
    return keys;
  }

  return (
    <>
      <ContainerWrapper {...props}>
        <Tree
          showLine={showLine} //是否展示连接线
          key="key"
          blockNode={blockNode} //是否节点占据一行
          checkable={isChecked} //节点前添加 Checkbox 复选框
          defaultExpandAll={defaultExpandAll} //默认展开所有树节点
          //autoExpandParent={autoExpandParent} //是否自动展开父节点
          // expandedKeys={expandedKeys}
          disabled={disabled}
          fieldNames={{ title: 'label', key: 'key', children: 'children' }}
          //onExpand={onExpand}
          treeData={currentData}
          onSelect={onSelectEvent}
        />
      </ContainerWrapper>
    </>
  )
};

class TreeComponent extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return <TreeWidget {...this.props} />;
  }
}



export default TreeComponent;
