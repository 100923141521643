import React, {useState} from 'react';
import styled from "@emotion/styled";
import {Tabs} from 'antd';
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";

export interface Props {
  style?: any;
  models: any[];
  addDraggableDom?: (dom: any, item?: any) => void;
  onSelectedItem: (modelId: any) => void;
  source?: string;
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    position: absolute;
    background: #E15615;
    pointer-events: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover  {
    color: #4C5664 !important;
    font-size: 14px;
  }

  >.ant-tabs-nav, >div>.ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    padding-left: 25px;
    height: 40px;
  }

  .ant-tabs-tabpane {
    outline: none;
    margin-left: 25px;
  }

  .iconPark:hover {
     color: #E15615;
  }
`

const ModelTree: React.FC<Props> = ({style, models, addDraggableDom, onSelectedItem, source}: Props) => {
  const [selectedId, setSelectedId] = useState<any>();

  const doOnSelectedItem = (item:any) => {
    setSelectedId(item.id || item.uid);
    onSelectedItem(item);
  }

  return (
    <div style={{
      width: "260px",
      height: style.height,
      borderRight: "1px solid #E4E8EB",
    }}>
      <StyledTabs>
        <Tabs.TabPane tab="模型列表">
          <ModelListWrapper height={style.contentH}>
            {models?.map((item: any, index: any) => {
              let selected = (source != 'modelingMx' && selectedId && (item.id == selectedId || item.uid == selectedId)) ? 'selected' : '';
              return (
                <>
                  {!item.templateChange &&
                    <div className={`item ${selected}`} key={index} onClick={() => doOnSelectedItem(item)}>
                      {getIconPark('Data', '#4C5664')}
                        <div className='description' onMouseEnter={(e) => {
                        if (!item.visibleGraph && addDraggableDom) {
                        addDraggableDom && addDraggableDom(e.target, item);
                      }
                      }}>{item.description || ' '} {item.sheetName && <div className='subText'>{item.sheetName}</div>}</div>
                    </div>
                  }

                  {item.templateChange &&
                    <div className={`item ${selected}`}>
                      {getIconPark('Data', '#4C5664')}
                      <div className='description' style={{color: '#dc0000'}}>
                        模板不正确({item.sheetName})
                      </div>
                    </div>
                  }
                </>
              );
            })}
          </ModelListWrapper>
        </Tabs.TabPane>
      </StyledTabs>
    </div>
  );
}

export default ModelTree;

const ModelListWrapper = styled.div<{height:any}>`
  height: ${({height}) => height };
  overflow-y: scroll;

  .item:focus, .item:hover, .item:active, .selected {
    background-color: var(--primary-color10);
    cursor: pointer;
  }

  .item {
    display: flex;
    align-items: center;
  }

  .description {
    padding-left: 8px;
    width: 213px;
    word-wrap: break-word;
  }

  .subText {
    font-size: 12px;
    color: #605f5f;
  }

  span {
    line-height: 16px;
  }
`
