export default class MySqlCrudTemplate {
  static addCrudTemplate(model: any) {
    const name = model._table_name;
    const columnNames: any = []
    const columnValues: any = []
    let setFragments = '';
    let value = "''";
    model.properties.forEach((item: any) => {
      const type = item.type;
      switch (type) {
        case 'integer':
        case 'bigint':
        case 'm2o':
          value = "-1";
          break;
        case 'decimal':
          value = "1.0";
          break;
        case 'bool':
          value = "0";
          break;
        case 'datetime':
          value = "'2019-07-01 10:00:00'";
          break;
        case 'date':
          value = "'2019-07-01'";
          break;
        case 'string':
        case 'mail':
        case 'textarea':
        case 'richtext':
        case 'dict':
        case 'file':
        case 'image':
        case 'enumeration':
        case 'blob':
        default:
          value = "''";
      }

      columnNames.push(item._column_name);
      columnValues.push(value);
      setFragments += "\n    ";
      setFragments += item._column_name + " = " + value + ",";
    })

    if (setFragments.length > 0) {
      setFragments = setFragments.substring(0, setFragments.length - 1);
    }

    let templates: any = {};
    templates["SELECT"] = "SELECT * FROM " + name + " LIMIT 10;";
    templates["INSERT"] = "INSERT INTO " + name
      + " (" + columnNames.join(", ") + ")\n"
      + "  VALUES (" + columnValues.join(",") + ");";
    templates["UPDATE"] = "UPDATE " + name + " SET"
      + setFragments + "\n"
      + "  WHERE 1 = 0;";
    templates["DELETE"] = "DELETE FROM " + name
      + "\n  WHERE 1 = 0; ";

    model.crudTemplates = templates;
  }
}
