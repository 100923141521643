import React from "react";
import { Tabs, Layout, PageHeader } from "antd";

import Api from "@byk/api/Api";
import BusinessPage1 from "@byk/pages/ApplicationManager/LogManagement/main";
import BusinessPage2 from "@byk/pages/TaskLog/main";
import BusinessPage3 from "@byk/pages/InterFaceLog/main";

import {getAppId, getProjectId, getTenant} from "utils/localStorage";
import _ from "lodash";
import {apiPath} from "@byk/routes";
const { TabPane } = Tabs;
export default class extends React.Component {
  formRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.formRef = React.createRef();
  }
  state = {
   
    tabKey: "a",
    key: "1"
  };



 

  componentDidMount() {

  }


  tabClick = (key: any) => {
    this.setState({ tabKey: key });
  }

  render() {
    return (
      <>
        <PageHeader className="site-page-header-main" title="日志中心" />
        <Layout>
          <div className="x-main-box x-internationalize">
            <Tabs key={this.state.key} activeKey={this.state.tabKey} size="middle" destroyInactiveTabPane={true} onTabClick={(key: any) => this.tabClick(key)}>
              <TabPane tab="数据操作日志" key="a" forceRender={false}>
                <BusinessPage1  />
              </TabPane>
              <TabPane tab="文件导入日志" key="b" forceRender={false}>
                <BusinessPage2 />
              </TabPane>
              <TabPane tab="外部接口日志" key="c" forceRender={false}>
                <BusinessPage3 />
              </TabPane>
         
            </Tabs>
          </div>
        </Layout>
      </>
    );
  }
}


