import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Table, Input, Button, Popconfirm, Form, InputNumber, Select, message, Row, Col, DatePicker} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {formatDateTimestamp, hexToRgb, isTimestamp, uuid2} from '@byk/utils/Utils';
import _ from 'lodash';
import moment from 'moment';
import Api from 'api/Api';
import {apiPath} from '@byk/routes';
import {getTenant} from 'utils/localStorage';
import styled from "styled-components";
import {FontStyleTypes} from "@design-system/widgets-old";

const BtnWrapper = styled(Button) <{
  btnColor?: string,
  btnBgColor?: string,
  btnFontStyle?: string,
  btnFontSize?: string,
  btnColor70?: string,
  btnBgColor70?: string,
  btnBorderRadius?: string,
  btnBoxShadow?: string,
  btnType?: string,
}>`
  height: auto !important;
  &,&:focus{
    color:  ${({btnColor}) => btnColor}!important;
    border-color:  ${({btnType, btnBgColor}) => btnType == "SECONDARY" ? btnBgColor : 'none'} !important;
  }
  border-style: ${({btnType}) => btnType == "SECONDARY" ? 'solid' : 'none'}!important;
  border-radius:  ${({btnBorderRadius}) => btnBorderRadius}!important;
  box-shadow:  ${({btnBoxShadow}) => btnBoxShadow}!important;
  background:  ${({btnType, btnBgColor}) => btnType == "PRIMARY" ? btnBgColor : 'none'} !important;

  &:hover {
    color:  ${({btnColor70}) => btnColor70}!important;
    background:  ${({btnType, btnBgColor70}) => btnType == "PRIMARY" ? btnBgColor70 : 'none'} !important;
    border-color:  ${({btnType, btnBgColor70}) => btnType == "SECONDARY" ? btnBgColor70 : 'none'} !important;
  }
  font-size: ${({btnFontSize}) => {
  return (btnFontSize ? `${btnFontSize}` : "14px") + ' !important'
}};
  font-style: ${({btnFontStyle}) => {
  return (btnFontStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : "") + ' !important'
}};
  font-weight: ${({btnFontStyle}) => {
  return (btnFontStyle?.includes(FontStyleTypes.BOLD) ? "bold" : "") + ' !important'
}};
`

export interface EditableTableIProops {
  cols: any,
  data: any,
  ref?: any,
  isDisabled?: boolean,
  onValChange?: (params?: any) => any,
  selectId?: any,
  onOptionChange?: (params?: any) => any,
  size?: any,
  newBtnType?: any,
  newBtnColor?: any,
  newBtnFontStyle?: any,
  newBtnBgColor?: any,
  newBtnTextSize?: any,
  newBtnBorderRadius?: any,
  newBtnBoxShadow?: any,
  opBtnType?: any,
  tableColumns?: any,
  opBtnColor?: string,
  opBtnFontStyle?: string,
  opBtnTextSize?: any,
  opBtnBorderRadius?: string,
  opBtnBoxShadow?: string,
  opBtnBgColor?: string,
}

const EditableTable: React.FC<EditableTableIProops> = forwardRef((
  {
    cols, data, onValChange, isDisabled, selectId,
    onOptionChange,
    size = "default",
    newBtnType = '',
    newBtnColor,
    newBtnBgColor,
    newBtnFontStyle,
    newBtnTextSize,
    newBtnBorderRadius,
    newBtnBoxShadow,
    opBtnType = '',
    opBtnColor,
    opBtnBgColor,
    opBtnFontStyle,
    opBtnTextSize,
    opBtnBorderRadius,
    opBtnBoxShadow,
    tableColumns
  }, ref) => {

  useImperativeHandle(ref, () => ({
    getData
  }))
  const [dataSource, setDataSource] = useState(data || []);
  const [delDataSource, setDelDataSource] = useState([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [addModel, setAddModel] = useState(false);
  const [dictList, setDictList] = useState({});
  const [subName, setSubName] = useState('');
  const [dictListUUID, setDictListUUID] = useState({});
  const [colsUUID, setColsUUID] = useState([]);

  const isEditing = (record: any) => record.key === editingKey;
  const opBtnColor70 = hexToRgb(opBtnColor, 0.7);
  const opBtnBgColor70 = hexToRgb(opBtnBgColor, 0.7);
  const newBtnColor70 = hexToRgb(newBtnColor, 0.7);
  const newBtnBgColor70 = hexToRgb(newBtnBgColor, 0.7);

  const formatEditData = (record: any) => {
    let _record: any = {...record};
    let dateKey: any = [];
    _.each(cols, (item: any) => {
      if (item && item["inputType"] == "date") {
        dateKey.push(item["dataIndex"]);
      }
    })
    _.each(dateKey, (itm: any) => {
      const _v: any = record[itm];
      try {
        if (_v && _v != null && _v != undefined) {
          _record[itm] = moment(_v);
        }
      } catch (error) {
        _record[itm] = null;
      }
    })
    return _record;
  }

  const formatAddData = (record: any) => {
    let _record: any = {...record};
    let dateKey: any = [];
    _.each(cols, (item: any) => {
      if (item && item["inputType"] == "date") {
        dateKey.push(item["dataIndex"]);
      }
    })
    _.each(dateKey, (itm: any) => {
      const _v: any = record[itm];
      try {
        if (_v) {
          let date1: any = _v ? new Date(_v).getTime() : null;
          _record[itm] = date1;
        }
      } catch (error) {
        record[itm] = null;
      }
    })

    return _record
  }

  const edit = (record: any, isFlag: boolean = false) => {
    setEditingKey(record.key);
    setAddModel(isFlag);
    let _record: any = formatEditData(record)
    form.setFieldsValue({
      ..._record,
    });
  };

  //取消
  const cancel = (record: any) => {
    if (record["_subTable_action"] == "add_1") {
      handleDelete(record);
    }
    setEditingKey('');
  };

  //保存
  const save = async (key: any) => {
    try {
      const _row = await form.validateFields();
      let row: any = formatAddData(_row);

      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        let item: any = {...newData[index]};
        if (item["_subTable_action"] == "add_1") {
          item["_subTable_action"] = "add"
        }
        newData.splice(index, 1, {...item, ...row});
        setDataSource(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey('');
      }
    } catch (errorInfo) {
      return false;
    }

  };

  const getFilter = (data: any) => {
    return data.filter((item: any) => item["_subTable_action"] !== "delete");
  };

  //删除
  const handleDelete = (record: any) => {
    const newData = dataSource.filter((item: any) => item.key !== record.key);
    let _d: any = [];
    let _record: any = {...record};
    if (_record.hasOwnProperty("_hasId")) {
      _record["_subTable_action"] = "delete";
      _d.push(_record);
    }
    setDataSource([...newData, ..._d]);
  };

  const colsObj = () => {
    let obj: any = {};
    _.each(cols, (itm: any) => {
      obj[itm.dataIndex] = null;
    })
    return obj;
  }

  //添加
  const handleAdd = () => {
    if (editingKey != "") {
      message.warn("请先保存记录！");
      return false;
    }
    let newData: any = colsObj();
    newData.key = uuid2();
    newData["_subTable_action"] = "add_1";
    setAddModel(true);
    setDataSource([...dataSource, newData]);
    edit(newData, false);
    //setCount(count + 1);
  };

  const getData = () => {
    let _data = dataSource;
    console.log("dataSource", _data);
    console.log("dictList", dictList);
    console.log("AddModel", addModel);
    console.log("editingKey", editingKey);
    console.log("subName", subName);
    return _data;
  };

  const [dateList, setDateList] = useState({});
  // 处理日期选择
  const handleDateChange = (date: any, dateString: any, dataIndex: any) => {

  };

  const isNotObject = (value: any) => {
    return typeof value !== 'object' || value === null;
  }

  const getVal = (data: any, type: any, dataIndex: any, obj: any = {}) => {

    try {
      if (type == "select") {
        let option: any = dictList[dataIndex];
        if (data) {
          //debugger;
          let m: any = option.filter((itm: any) => {
            return itm.code == data
          });
          return m.length > 0 ? m[0].name : "";
        }
      }
      if (type == "date") {
        let _d: any = data;
        try {
          _d = formatDateTimestamp(data)
        } catch (error) {

        }
        return _d;
      }
    } catch (error) {
      return data;
    }
    return data;
  }

  const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          required,
                          dateFormat,
                          children,
                          ...restProps
                        }) => {
    ///console.log("children0722",children);
    let _m = children && children[1];
    if (typeof (_m) != "object") {
      children = [undefined, getVal(_m, inputType, dataIndex)]
    }
    let inputNode: any = null;
    switch (inputType) {
      case 'number':
        inputNode = <InputNumber style={{width: "100%"}} min={0}/>;
        break;
      case 'text':
        inputNode = <Input/>;
        break;
      case 'date':
        inputNode = <DatePicker onChange={(date: any, dateString: any) => handleDateChange(date, dateString, dataIndex)}
                                placeholder={"请选择"}
          //  defaultValue={dateList[dataIndex]?moment(dateList[dataIndex]):null}
        />;
        break;
      case 'select':
        inputNode = <Select
          allowClear
          style={{width: "100%"}}
          fieldNames={{label: 'name', value: 'code'}}
          options={dictList[dataIndex]}
          onChange={(val: any) => {
            handleChange(val, dataIndex)
          }}
        />;
        break;
      default:
        inputNode = null;
    }
    return (
      <td {...restProps}>
        {(editing && isDisabled == false) ? (
          <Form.Item
            name={dataIndex}
            style={{margin: 0}}
            rules={[
              {
                required: required,
                message: `请输入${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (

          children
        )}
      </td>
    );
  };
  const deleteCompent = (record: any) => {
    return <>
      <Popconfirm okText="是" cancelText="否" title="确定要删除吗?" onConfirm={() => handleDelete(record)}>
        <BtnWrapper
          style={{
            marginRight: "5px",
          }}
          btnType={opBtnType}
          btnColor={opBtnColor}
          btnBgColor={opBtnBgColor}
          btnFontStyle={opBtnFontStyle}
          btnFontSize={opBtnTextSize}
          btnColor70={opBtnColor70}
          btnBgColor70={opBtnBgColor70}
          btnBorderRadius={opBtnBorderRadius}
          btnBoxShadow={opBtnBoxShadow}
          type={opBtnType == "TERTIARY" ? "text" : "default"}
        >
          删除
        </BtnWrapper>
      </Popconfirm>
    </>
  }
  const columns = [
    {
      title: <><span onClick={getData}>操作</span></>,
      dataIndex: 'operation',
      width: "180px",
      fixed: "right",
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
                        <BtnWrapper
                          style={{
                            marginRight: "5px",
                          }}
                          btnType={opBtnType}
                          btnColor={opBtnColor}
                          btnBgColor={opBtnBgColor}
                          btnFontStyle={opBtnFontStyle}
                          btnFontSize={opBtnTextSize}
                          btnColor70={opBtnColor70}
                          btnBgColor70={opBtnBgColor70}
                          btnBorderRadius={opBtnBorderRadius}
                          btnBoxShadow={opBtnBoxShadow}
                          type={opBtnType == "TERTIARY" ? "text" : "default"}
                          onClick={() => save(record.key)}
                        >
                            保存
                        </BtnWrapper>
                        <Popconfirm title="确定要取消吗?" okText="是" cancelText="否" onConfirm={() => cancel(record)}>
                            <BtnWrapper
                              style={{
                                marginRight: "5px",
                              }}
                              btnType={opBtnType}
                              btnColor={opBtnColor}
                              btnBgColor={opBtnBgColor}
                              btnFontStyle={opBtnFontStyle}
                              btnFontSize={opBtnTextSize}
                              btnColor70={opBtnColor70}
                              btnBgColor70={opBtnBgColor70}
                              btnBorderRadius={opBtnBorderRadius}
                              btnBoxShadow={opBtnBoxShadow}
                              type={opBtnType == "TERTIARY" ? "text" : "default"}
                            >
                                取消
                            </BtnWrapper>
                        </Popconfirm>
                    </span>
        ) : (
          <span>
                        <BtnWrapper
                          style={{
                            marginRight: "5px",
                          }}
                          btnType={opBtnType}
                          btnColor={opBtnColor}
                          btnBgColor={opBtnBgColor}
                          btnFontStyle={opBtnFontStyle}
                          btnFontSize={opBtnTextSize}
                          btnColor70={opBtnColor70}
                          btnBgColor70={opBtnBgColor70}
                          btnBorderRadius={opBtnBorderRadius}
                          btnBoxShadow={opBtnBoxShadow}
                          type={opBtnType == "TERTIARY" ? "text" : "default"}
                          disabled={editingKey !== ''} onClick={() => edit(record, false)}
                        >
                            编辑
                        </BtnWrapper>
            {
              deleteCompent(record)
            }
                    </span>
        );
      },
    },
  ];

  const getSelectOptions = async (obj: any) => {
    //185429787734320
    let key: any = obj[0];
    let actionId: any = obj[1];
    let apiResult = await Api.get(`${apiPath}/${getTenant()}/subapp/AppAction`, {
      id: actionId
    });
    const {requestType, url} = (apiResult as any).result[0]
    let apiData = await Api.methods(requestType, `${apiPath}/${getTenant()}/${url}`, {});
    return apiData;
  }

  const formatList = (list: any) => {
    if (list.length == 0) {
      return [];
    }
    let _obj = {...list[0]};
    let label: string, value: string = "";
    for (let key in _obj) {
      // if(key.indexOf(".")>-1){
      //     let l:any=key.split(".");
      //     setSubName(l[0])
      // }
      if (key.indexOf(".label") > -1) {
        label = key;
      }
      if (key.indexOf(".value") > -1) {
        value = key;
      }
    }
    if (label == undefined || value == undefined || label == "" || value == "") {
      return [];
    }
    let _list = [];
    _list = list.map((obj: any) => {
      return {
        ...obj,
        name: obj[label], // 将原来的name值赋给name1
        code: obj[value],
      };
    });
    return _list;
  }
  const handleChange = (val: any, dataIndex: string) => {

    try {
      let col: any = cols.filter((itm: any) => {
        return itm.dataIndex == dataIndex;
      })[0];
      let event: any = col["onOptionChange"] || null
      if (event) {
        onOptionChange && onOptionChange(event);
      }
      let options = dictList[dataIndex];
      let _objList = options.filter((itm: any) => {
        return itm.code == val;
      })
      if (_objList.length > 0) {
        let obj: any = _objList[0];
        let updateFormData: any = {};
        _.each(cols, (item: any) => {
          for (let key in obj) {
            if (key == `${item.dataIndex}`) {
              updateFormData[item.dataIndex] = obj[key];
            }
          }
        })
        form.setFieldsValue(updateFormData);
      }
    } catch (error) {
    }

  }
  const dataCache = useRef({});
  const dataCacheUUID = useRef({});
  const colsCacheUUID = useRef({});
  const bindDict = async () => {

    let apiList = cols.filter((item: any) => {
      return item.columnType == "select" && item.onDropdownOpen;
    });
    let _cols = cols.filter((item: any) => {
      // return item.columnType == "select" && item.selectId && !item.onDropdownOpen;
      return item.columnType == "select" && !item.onDropdownOpen;
    });
    _.each(apiList, async (itm: any) => {
      let actionId: any = null;
      try {
        actionId = itm.onDropdownOpen.children[0].children[0].actionConfig.actionId;
        let apiResult: any = await getSelectOptions([itm.dataIndex, actionId]);
        let _list: any = apiResult.data.body.data[0]['result'] || [];
        console.log("_list", formatList(_list));
        console.log("_key", itm);
        let _dictList: any = {...dictList};
        _dictList[itm.id] = formatList(_list);
        setDictList(_dictList);
      } catch (error) {
      }
    })

    let _obj: any = {};
    _.each(_cols, (itm: any) => {
      _obj[itm.dataIndex] = itm.selectId||null;
    })
    for (let key in _obj) {
      if (_obj.hasOwnProperty(key)) { // 确保key是对象自身的属性
        console.log(key, _obj[key]);
        let _selectId = _obj[key];
        let _dictList = {...dictList};
        if(_selectId==null){
          _dictList[key] = [];
          setDictList(_dictList);
        }
        else{
          let _parms = {dict: _selectId, enable: true, asc: "sort,createDate"}
          let res: any = await Api.get(`${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`, _parms);
          if (res.success) {
            let list = res.result;
            _dictList[key] = list;
            setDictList(_dictList);
          }
        }

      }
    }
  }
  const columnsNo = [
    {
      title: '序号',
      dataIndex: '序号',
      width: 65,
      editable: false,
      inputType: "text",
      required: false,
      fixed: "left",
      render: (_: any, record: any, index: any) => index + 1,
    }]

  const mergedColumns = _.concat(columnsNo, cols, isDisabled ? [] : columns).map((col) => {
    if (!col.editable) {
      return col;
    }
    if(col.width==0||col.width==null){
      delete col.width;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        required: col.required,
        dateFormat: col.dateFormat,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    bindDict()
  }, [tableColumns]);

  useEffect(() => {
    if (_.isEqual(data, dataSource)) {
      return;
    }
    onValChange && onValChange(dataSource);
  }, [dataSource]);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  useEffect(() => {
    console.log('onLoad');
  }, [])

  return (
    <Form form={form} component={false}>
      <Row>
        <Col span={24}>
          <Table
            style={{width: "100%"}}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            scroll={{x: 800}}
            dataSource={getFilter(dataSource)}
            columns={mergedColumns}
            rowClassName="editable-row"
            size={size}
          />
        </Col>
      </Row>
      {
        !isDisabled && <>
          <Row>
            <Col span={24}>
              <BtnWrapper
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                btnType={newBtnType}
                btnColor={newBtnColor}
                btnBgColor={newBtnBgColor}
                btnFontStyle={newBtnFontStyle}
                btnFontSize={newBtnTextSize}
                btnColor70={newBtnColor70}
                btnBgColor70={newBtnBgColor70}
                btnBorderRadius={newBtnBorderRadius}
                btnBoxShadow={newBtnBoxShadow}
                icon={<PlusOutlined/>}
                onClick={handleAdd}
                type={newBtnType == "TERTIARY" ? "text" : "default"}
              >
                添加一行
              </BtnWrapper>
            </Col>
          </Row>
        </>
      }

    </Form>
  );
});
export default EditableTable;
