import React, { useEffect, useRef, useState, type RefObject } from "react";
import { Drawer, DrawerProps, RadioChangeEvent } from "antd";
import styled from "styled-components";
import { scrollCSS } from "widgets/WidgetUtils";
import { getCanvasClassName } from "utils/generators";

function DrawerWidget(props: any) {
  const [open, setOpen] = useState(true);
  //const [placement, setPlacement] = useState<DrawerProps["placement"]>("left");
  const drawerRef = useRef(null);
  const {
    placement, keyboard, closable, title,closeEvent,openEvent
  } = props;
  const showDrawer = () => {
    setOpen(true);
    openEvent();
  };

  const onClose = () => {
    setOpen(false);
    closeEvent();
  };

  const onChange = (e: RadioChangeEvent) => {
    //setPlacement(e.target.value);
  };
  const modalContentRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);
  const Content = styled.div<{ $scroll: boolean }>`
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  ${scrollCSS}
  ${(props) => (!props.$scroll ? `overflow: hidden;` : ``)}
`;

  return (
    <>
      <div ref={drawerRef} />
      <Drawer
        title={title}
        placement={placement}
        mask={false}
        keyboard={keyboard}
        closable={closable}
        onClose={onClose}
        style={{ width: "100%", height: "100%" }}
        contentWrapperStyle={{  width: "-webkit-fill-available",height:"-webkit-fill-available"}}
        bodyStyle={{  }}
        drawerStyle={{  }}
        open={open}
        key={placement}
        getContainer={false}
      >
        <Content
            $scroll={!!props.scrollContents}
            className={`${getCanvasClassName()} ${
              props.className
            } scroll-parent`}
            id={props.widgetId}
            ref={modalContentRef}
            tabIndex={0}
          >
            {props.children}
          </Content>

        {/* <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p> */}
      </Drawer>
    </>
  );
}

export default DrawerWidget;
