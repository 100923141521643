import React from 'react';
import styled from "styled-components";
const AtText = styled.div<{
  isCircle: boolean;
  bgColor: string;
  textAlign: string;
  fontSize: string;
  color: string;
  fontStyle: string;
}>`
  width: 100%;
  height: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: ${({isCircle})=> isCircle?'50%':'0'};
  background-color: ${({bgColor})=> bgColor?bgColor:'transparent'};
  text-align: ${({textAlign})=> textAlign};
  .x-text-span{
    display: block;
    flex: 1;
    fontSize: ${({fontSize})=> fontSize}px;
    color: ${({color})=> color};
    fontStyle: ${({fontStyle})=> fontStyle.includes('ITALIC')?'italic':'normal'};
    fontWeight: ${({fontStyle})=> fontStyle.includes('BOLD')?'bold':'0'};
  }
`
const TTextWidget = (props:any) => {
  const {label, fontSize, color, fontStyle, isCircle, bgColor, textAlign, onClick} = props;
  return (
    <AtText isCircle={isCircle} bgColor={bgColor} textAlign={textAlign} fontSize={fontSize} color={color} fontStyle={fontStyle}>
      <span className="x-text-span" onClick={onClick}>{label}</span>
    </AtText>
  );
};

export default TTextWidget;
