import React from "react";
import {Button, Col, Form, Input, message, Radio, Row, Space, Table} from "antd";
import Api from "@byk/api/Api";

import {withRouter} from "react-router-dom";
import {uuid2} from "@byk/utils/Utils";
import _ from "lodash";
import {apiPath} from "@byk/routes";
import {getTenant} from "../../../../utils/localStorage";

class Menu extends React.Component {
  formInputRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    // 创建一个ref去储存textInput DOM元素
    this.formInputRef = React.createRef();
  }
  state = {
    appId: this.props.defaultAppId||"",
    appList:this.props.appList,
    pageId: "",
    initParams: {
      appId:this.props.defaultAppId,
      pageId: ""
    },
    menuData: [],
    languageName: {},
    inputData: { menuKey: "", enable: false },
    columnList: [],
    enable: false,
    attribute: "",
    pageList: [],
    elementId:null,
    key:uuid2()
  };
  that: any;
  componentDidMount() {
    this.bindPageList();
    //this.bindMenu();
    this.props.onRef && this.props.onRef(this);
  }
  // api
  apiGet: string = `${apiPath}/${getTenant()}/i18n/pageI18N`;
  apiPageGet: string = `${apiPath}/${getTenant()}/subapp/Page/list`;
  async bindMenu() {
    let res: any = await Api.get(this.apiGet, this.state.initParams);
    if (res.success) {
      let list = res.ext
      this.bindColumns(list.languageName);
      this.setState({ menuData: this.bindPage(list.pageElement), languageName: list.languageName,key:uuid2(16,16) });
    }
  };

  async bindPageList() {
    let res: any = await Api.get(this.apiPageGet, { appId: this.state.appId });
    if (res.success) {
      let j=res.result;
      this.setState({ pageList: j });
      if(j.length>0){
        this.changePage(j[0]);
      }

    }
  };

  bindPage(data: any) {
    let _data: any = [];
    for (let key in data) {
      //if (data.hasOwnProperty(key)) {
        let _d: any = {};
        _d.showAttribute = key;
        _d.key=key;
        let obj:any=[];
        let m:any= data[key];
        _.each(m,(itm:any)=>{
          itm.key=itm.fullAttributeString+uuid2(16,16);
          obj.push(itm);
        })
        _d.children = m;
        //_d.key=key;
        _data.push(_d)
      //}
    }
    return _data;
  }

  bindColumns(languageName: any) {
    let that = this;
    let list: any = [{
      title: '组件',
      dataIndex: 'showAttribute',
      key: 'showAttribute',
      width: 330,
      align: "left",
      fixed: 'left',
    },
    {
      title: '默认',
      dataIndex: 'text',
      key: 'text',
      width: 160,
      align: "left",
      fixed: 'left',
    }];
    for (let key in languageName) {
      //if (languageName.hasOwnProperty(key)) {  // 确保键在对象自身上，而不是从原型链继承
        list.push({
          title: languageName[key],
          dataIndex: key,
          key: key,
          align: "center",
          render: (_: any, record: any) => (
            <>
              <Form.Item
                label=""
                colon={false}
                name={key}
                style={{ margin:0}}
              >
                {
                  (that.state.inputData["menuKey"] == record.fullAttributeString && that.state.inputData["enable"] == true) ?
                    <Input style={{ width: "100%" }}
                      placeholder="请输入字段"
                      maxLength={200}
                      allowClear
                      key={"lang." + record.fullAttributeString}
                    /> :
                    <span>
                      {record[key]}
                    </span>
                }
              </Form.Item>
            </>
          ),
        });
      //}
    }
    list.push({
      title: "操作",
      key: "action",
      render: (_: any, record: any) => (
        <>
          <Space size="middle" style={{ display: that.state.inputData["menuKey"] == record.fullAttributeString && that.state.inputData["enable"] == true ? '' : 'none' }} >
            <Button type="link" htmlType="submit" >
              保存
            </Button>
            <Button type="link" onClick={() => { that.cancel(record) }}>
              取消
            </Button>
          </Space>
          <Space size="middle" style={{ display: (that.state.inputData["menuKey"] == record.fullAttributeString && that.state.inputData["enable"] == true) || record.fullAttributeString == undefined ? 'none' : '' }}>
            <Button type="link" onClick={() => { that.edit(record) }}>
              编辑
            </Button>
          </Space>
        </>
      ),
      width: 150,
      fixed: 'right',
    });
    this.state.columnList = list;
  }
  edit(record: any) {
    let data = { enable: true, menuKey: record.fullAttributeString };
    let langList = this.state.languageName;
    this.bindColumns(langList);
    let _data: any = {};
    for (let key in langList) {
      //if (langList.hasOwnProperty(key)) {  // 确保键在对象自身上，而不是从原型链继承
        _data[key] = record[key];
      //}
    }
    this.setState({ inputData: data, menuKey: record.fullAttributeString, enable: true, pageId: record.pageId,elementId:record.elementId, attribute: record.attribute }, () => {

      console.log(this.state.inputData)
      this.formInputRef.current?.setFieldsValue(_data);
    });

  };
  cancel(record?: any) {
    let data = { enable: false, menuKey: "" };
    this.setState({ inputData: data });
    this.bindMenu();
  };

  async onFinish(values: any) {
    console.log("values", values)
    let data: any = values;
    data.appId = this.state.appId;
    data.pageId = this.state.pageId;
    data.elementId = this.state.elementId;
    data.attribute = this.state.attribute;
    console.log("data", data)
    //return false;
    let result1: any = await Api.put(this.apiGet, data);;
    if (result1.success) {
      message.success("保存成功!");
      this.cancel();
    }
  };
  appChange=(e:any)=>{
    let _id=e.target.value;
    let data: any = {...this.state.initParams};
    data.appId = _id;
    this.setState({appId:_id,initParams:data},()=>{
      this.bindPageList();
    });
  }
  changePage(_data: any) {
    let data: any = {...this.state.initParams};
    data.pageId = _data.id;
    this.setState({ initParams: data }, () => {
      this.bindMenu();
    })
  }
  pageListComponent(treeData:any, level=0){
    return treeData.map((item: any) => {
      return (
        <>
          <div style={{marginLeft: `${level*10}px`}} className={"x-menu-normal "+ (this.state.initParams.pageId==item.id?" x-menu-active":" x-menu-default")} onClick={() => this.changePage(item)}>{item.name}</div>
          {
            item.children&&
              this.pageListComponent(item.children, level+1)
          }
        </>
      );
    });
  }
  render() {
    return (
      <>
        <div className="x-main-box-in">
        <Radio.Group style={{marginBottom:"10px"}} onChange={(e:any)=>{this.appChange(e)}} defaultValue={this.props.defaultAppId} options={this.state.appList}  optionType="button"
          buttonStyle="solid"/>
          <Row>
            <Col span={3}>
              <div style={{ paddingRight: "5px" }}>
                {this.pageListComponent(this.state.pageList)}
              </div>

            </Col>
            <Col span={21}>
              <Form
                key="inputForm"
                ref={this.formInputRef}
                onFinish={(v) => this.onFinish(v)}
              >
                <Table columns={this.state.columnList} key={this.state.key}
                expandable={{
                  defaultExpandAllRows: true,
                  rowExpandable:()=>false
                }}
                scroll={ {x: 1200 }} bordered={true} size="small" pagination={false} dataSource={this.state.menuData} />
              </Form>
            </Col>

          </Row>

        </div >
      </>
    );
  }
}
export default withRouter(Menu);
