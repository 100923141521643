import {Button, Checkbox, message, Modal, Spin, Table} from 'antd';
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import ConfigDataSource from "@byk/pages/modeling/ConfigDataSource";
import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import {Key} from "antd/lib/table/interface";
import styled from "@emotion/styled";
import {CheckboxValueType} from "antd/lib/checkbox/Group";
import _ from "lodash";
import Upload, {UploadProps} from 'antd/es/upload';
import {UploadOutlined} from "@ant-design/icons";
import SqlErrMsg from "@byk/pages/modeling/MxGraph/SqlErrMsg";

const ModalWrapper = styled(Modal)<any>`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th {
    position: relative;
    padding: 7px;
    overflow-wrap: break-word;
  }

  .ant-btn-link[disabled] {
    border: none !important;
    ${({createModelFlag}) => createModelFlag ? `
      color: var(--primary-color) !important;
      ` : `
      color: gray !important;
      `
}
  }

  .ant-upload-list-item {
    display:none;
  }
`

const SpinEx = styled(Spin)`
    background: #e6e6e675;
    position: absolute;
    width: 100%;
    height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
`;

const ImportModelByDataSource: React.FC<any> = forwardRef(({mxLoadModel}, ref) => {
      useImperativeHandle(ref, () => ({
        showModal,
      }));
      const [isModalOpen, setIsModalOpen] = useState(false);
      const configDataSourceRef: any = useRef();
      const [selectedModels, setSelectedModels] = useState<any>([]);
      const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
      const [dsConfig, setDsConfig] = useState<any>();
      const [dataSource, setDataSource] = useState<any>([]);
      const [loading, setLoading] = useState(false);
      const cacheDataSource = useRef<any>({});
      const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
      const [type, setType] = useState<any>(null);
      const [filterDisabled, setFilterDisabled] = useState<any>(true);

      const showModal = (type?: string) => {
        setType(type || null);
        setCheckedList([]);
        setFilterDisabled(true);
        setDataSource([]);
        setSelectedModels([]);
        setSelectedKeys([]);
        cacheDataSource.current = {};
        setIsModalOpen(true);
        setLoading(false);
      }

      const handleCancel = () => {
        setIsModalOpen(false);
      }

      const handleOk = () => {
        setIsModalOpen(false);
      }

      const onOpenDbConfig = () => {
        setTimeout(() => {
            configDataSourceRef.current?.showModal();
          },
          configDataSourceRef.current ? 0 : 100);
      }

      const columns: any = [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: '8%',
          render: (text: any, record: any, index: any) => `${index + 1}`,
        },
        {
          title: '表名称',
          dataIndex: 'tableName',
          key: 'dbName',
          width: '25%',
        },
        {
          title: '模型编码',
          dataIndex: 'name',
          key: 'name',
          width: '25%',
          onFilter: (value: string, record: any) => record.name,
        },
        {
          title: '信息',
          dataIndex: 'msg',
          key: 'msg',
        },
      ];

      const createModels = async () => {
        setLoading(true);
        let res: any = await ModelingApi.doApiCreateModelsFromDb(selectedModels, dsConfig);
        if (res.success) {
          selectedModels.forEach((item: any) => {
            item.msg = '模型创建成功';
            cacheDataSource.current[item.tableName] = item;
          })
          let _dataSource: any = [];
          dataSource.forEach((item: any) => _dataSource.push(cacheDataSource.current[item.tableName]))
          setDataSource([]);
          setDataSource(_dataSource);
          onChange(checkedList);
          mxLoadModel();
          message.success("创建模型成功");
        }
        setLoading(false);
      }

      const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
          setSelectedKeys(selectedRowKeys);
          setSelectedModels(selectedRows);
        },
        getCheckboxProps: (record: any) => ({
          disabled: record.msg,
          name: record.name,
        }),
      };

      const setDataSourceCfg = (dataSourceCfg: any) => {
        setDsConfig(dataSourceCfg);
      }

      const onChange = (list: CheckboxValueType[]) => {
        setSelectedKeys([]);
        setSelectedModels([]);
        setCheckedList(list);
        if (cacheDataSource.current) {
          let ds: any = [];
          let keys = _.keys(cacheDataSource.current);
          for (let idx in keys) {
            let item: any = cacheDataSource.current[keys[idx]];
            let isChecked = false;
            if (list.includes(0)) { // 已创建模型
              isChecked = true;
              if (item.msg && item.msg == '模型创建成功') {
                ds.push(item);
                continue;
              }
            }
            if (list.includes(1)) { // 未创建模型
              isChecked = true;
              if (item.name && !item.msg) {
                ds.push(item);
                continue;
              }
            }
            if (list.includes(2)) { // 模型已存在
              isChecked = true;
              if (item.msg && item.msg == '模型已存在') {
                ds.push(item);
                continue;
              }
            }
            if (list.includes(3)) { // 物理表已存在
              isChecked = true;
              if (item.msg && item.msg == '物理表已存在') {
                ds.push(item);
                continue;
              }
            }
            if (list.includes(4)) { // 命名不规范
              isChecked = true;
              if (item.msg && item.msg.indexOf('表名和字段名') == 0) {
                ds.push(item);
                continue;
              }
            }

            if (!isChecked) {
              ds.push(item);
            }
          }

          setDataSource(ds);
        }
      }

      const loadModels = (models: any = []) => {
        models.forEach((item: any) => cacheDataSource.current[item.tableName] = item)
        setDataSource(models);
        setFilterDisabled(false);
      }


      const [sqlMsg, setSqlMsg] = useState<any>();
      const sqlErrMsgRef = useRef<any>();

      function openSqlErrMsg() {
        setTimeout(() => {
          sqlErrMsgRef.current.showModal(sqlMsg);
        }, sqlErrMsgRef.current ? 0 : 100);
      }

      const props: UploadProps = {
        accept: '.sql',
        name: 'file',
        action: ModelingApi.apiUploadSalCreateModel + "?tenant=" + ModelingApi.getTenant(),
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          let res: any = info.file.response || {};
          let status = info.file.status;
          if (status === 'done') {
            if (res.success) {
              loadModels && loadModels(res.result);
            } else {
              setSqlMsg(res.message);
            }
            setLoading(false);
          } else if (status === 'error') {
            setLoading(false);
            message.error(`${info.file.name} 文件上传失败.`);
          }
        },

        beforeUpload() {
          setSqlMsg(null);
          setLoading(true);
        },

      };

      return (
        <>
          <ModalWrapper
            onCancel={handleCancel}
            onOk={handleOk}
            open={isModalOpen}
            title="数据源导入创建模型"
            cancelText="取消" okText="确认"
            maskClosable={false}
            width={1000}
            footer={null}
            createModelFlag={selectedModels.length > 0}
          >
            {loading && <SpinEx tip="加载中..."/>}
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {type &&
                <div style={{margin: "5px"}}>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined/>}>SQL脚本上传</Button>
                  </Upload>
                  {sqlMsg &&
                  <Button
                    style={{
                      float: "left",
                      marginTop: "-33px",
                      marginLeft: "140px",
                    }}
                    type={"link"}
                    onClick={openSqlErrMsg}
                  >
                    <div style={{color: "red"}}>SQL异常信息</div>
                  </Button>
                  }
                </div>
                }
                {!type && <Button type={"link"} onClick={onOpenDbConfig}>配置数据源</Button>}
                <Button disabled={selectedModels.length == 0} type={"link"} onClick={createModels}>创建模型</Button>
              </div>
              <div>
                <Checkbox.Group
                  disabled={filterDisabled}
                  options={[
                    {label: '已创建模型', value: 0},
                    {label: '未创建模型', value: 1},
                    {label: '模型已存在', value: 2},
                    {label: '物理表已存在', value: 3},
                    {label: '命名不规范', value: 4},
                  ]}
                  value={checkedList}
                  onChange={onChange}
                />
              </div>
            </div>
            <Table
              rowKey={"tableName"}
              bordered
              dataSource={dataSource}
              columns={columns}
              rowSelection={{
                selectedRowKeys: selectedKeys,
                type: "checkbox",
                ...rowSelection,
              }}
              scroll={{y: 418}}
              style={{
                height: "460px",
              }}
              pagination={false}
            />

            <ConfigDataSource
              ref={configDataSourceRef}
              setDataSourceCfg={setDataSourceCfg}
              loadModels={loadModels}/>

            <SqlErrMsg ref={sqlErrMsgRef}/>

          </ModalWrapper>
        </>
      );
    }
  )
;

export default ImportModelByDataSource;

