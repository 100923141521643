import {useState, useEffect, useCallback} from 'react'

export const getTargetElement = (target:any, defaultElement = window) => {
  if (!target) {
    return defaultElement
  }

  let targetElement;

  if (typeof target === 'function') {
    targetElement = target()
  } else if ('current' in target) {
    if (target.current) {
      targetElement = target.current
    } else {
      return defaultElement
    }
  } else {
    targetElement = target
  }

  return targetElement
}

const useSize = (target:any) => {
  const [state, setState] = useState({
    width: 0,
    height: 0
  })

  const elResizeChange = useCallback(
    (entries:any) => {
    // 每次被观测的元素尺寸发生改变这里都会执行
    entries.forEach((entry:any) => {
      const { width, height } = entry.target.getBoundingClientRect();
      setState(prevState => {
        if (prevState.width !== width || prevState.height !== height) {
          return { width, height };
        }
        return prevState;
      });
    });
  }, // Adjust debounce delay as needed
    []
  );

  useEffect(() => {
    const targetElement = getTargetElement(target)
    if (!targetElement) {
      return
    }

    const observer = new ResizeObserver(elResizeChange)
    observer.observe(targetElement) // 观测DOM元素

    return () => {
      observer.disconnect()
    }
  }, [target, elResizeChange])

  return {
    ...state
  }
}

export default useSize;
