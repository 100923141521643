import * as React from "react";
import { useRef, useState } from "react";
import type { ComponentProps } from "widgets/BaseComponent";
import _ from "lodash";
import "./index.css";
import styled from "styled-components";
import MapWidget from "@byk/pages/components/MapWidget";
import { strToJson } from "@byk/utils/Utils";

const ContainerWrapper = styled.div<{
}>`
  height:'300px';
`;

const AmapViewWidget = (props: any) => {
  const {
    value,
    defaultValue,
    updateWidgetProperty,
    onChange,
    isSearch,
    dragEnable,
    zoomEnable,
    zoomLevel,
    currentData,
    securityJsKey,
    securityJsCode,
    isShowCard,
    borderColor,
    borderWidth,
    borderRadius,
    boxShadow
  } = props;

  const chooseMapRef = useRef(null);
  const [text, setText] = useState<any>(null);
  const setValue = (v: any) => {
    let str_v = "";
    if (!_.isEmpty(v)) {
      str_v = JSON.stringify(v);
    }
    setText(v);
    updateWidgetProperty(str_v);
    onChange && onChange();
  }

  const getValue = (value: any, defaultValue: any) => {
    let _v = defaultValue || value;
    if (_.isEmpty(_v)) {
      return null;
    }
    return strToJson(_v);
  }

  const getData = (value: any) => {
    let _v =  value;
    if (_.isEmpty(_v)) {
      return null;
    }
    return strToJson(_v);
    // if(_.isString(currentData)&&!_.isEmpty(currentData)){
    //   currentData = JSON.parse(currentData);
    // }
  }

  return (
    <>
      <ContainerWrapper className="z-AmapViewWidget-ContainerWrapper" style={{ height: "inherit",
       border: `${borderWidth}px solid ${borderColor||"#bcb3b3"}`,
       boxShadow:boxShadow,
        borderRadius: borderRadius 
        }} {...props}>
        <MapWidget ref={chooseMapRef} callback={setValue} mode={"view"} defaultLngLat={getValue(value, defaultValue)}
          currentData={getData(currentData)} securityJsKey={securityJsKey} securityJsCode={securityJsCode}
          isShowCard={isShowCard} dragEnable={dragEnable} zoomLevel={zoomLevel} zoomEnable={zoomEnable} isSearch={isSearch} 
          borderColor={borderColor}
          borderWidth={borderWidth}
          borderRadius={borderRadius}
          boxShadow={boxShadow}
          ></MapWidget>
      </ContainerWrapper>
    </>
  )
};

class AmapComponent extends React.Component<AmapComponentProps> {
  constructor(props: AmapComponentProps) {
    super(props);
  }

  render() {
    return <AmapViewWidget {...this.props} />;
  }
}

export interface AmapComponentProps extends ComponentProps {
  updateWidgetProperty: any,
  onChange?: () => void,
}

export default AmapComponent;
