//表格配置
import {ColumnsType} from "antd/lib/table";
import React from "react";
import {getTenant} from "../../../../utils/localStorage";
import { PROPERTY_REL_TYPE } from "../constants/Constants";
import moment from "moment";
import {uuid} from "@tinymce/tinymce-react/lib/es2015/main/ts/Utils";
import {uuid2} from "@byk/utils/Utils";

const ColumnWidthByType:any = {
  "datetime": 160,
  "string": 120,
  "mail": 120,
  "textarea": 120,
  "file": 120,
  "image": 120,
  "decimal": 80,
  "integer": 80,
  "bigint": 80,
  "dict": 120,
  "dictName": 120,
}

export function excludesRelProperties(properties: any = []) {
  return properties.filter((item:any) => (item.type !== PROPERTY_REL_TYPE.O2M && item.type !== PROPERTY_REL_TYPE.RELATED));
}

export function buildTableColumns(model: any) {
  const columns: ColumnsType = []
  let _properties = excludesRelProperties(model?.properties);

  _properties.forEach((item: any) => {
    let width = ColumnWidthByType[item.type] || 120;
    let col: any = {
      title: item.description,
      dataIndex: item.name,
      key: item.name,
      ellipsis: true,
      width: item.primaryKey ? 150 : width,
    }
    if (item.type == 'datetime') {
      col.render = (_:any, record: any = {}) => {
        if (record[item.name]) {
          return moment(record[item.name]).format('YYYY-MM-DD HH:mm:ss')
        } else {
          return record[item.name] ||　'';
        }
      }
    }
    if (_properties.length < 8) {
      col.width = null
    }
    columns.push(col)
  })

  return columns
}

/**
 * Table配置项
 * @param deleteCallback
 */
export function buildTableProps(model: any) {
  const tableProps = {
    rowSelection: {},
    columns: buildTableColumns(model),
    scroll: {y: 345},
    api: `/byk/${getTenant()}/rest/${model?.name}/list`,
    pagination: {
      currPage: 1,
      pageSize: 10
    },
  }

  return tableProps
}

export function buildSearchOptions(model: any) {
  let searchOptions: any = []
  if (model?.id && model.properties) {
    let _properties = excludesRelProperties(model?.properties);
    _properties.forEach((item: any) => {
      let key = uuid2();
      searchOptions.push({
        key: key,
        label: item.description,
        value: item.name,
      })
    })
  }

  return searchOptions
}

/**
 * 搜索配置项
 * @param refreshTable
 */
export function searchProps(model: any, refreshTable: any) {
  let properties: any = model?.properties || []
  let _properties = properties.filter((item:any) => (item.type !== PROPERTY_REL_TYPE.O2M && item.type !== PROPERTY_REL_TYPE.RELATED));

  const searchConfig: any[] = []
  _properties.forEach((item: any) => {
    searchConfig.push({
      key : uuid2(),
      itemType: 'input',
      placeholder: '',
      name: item.name,
      label: item.description,
      allowClear: true,
      col: 4
    },)
  })

  const searchProps = {
    option: searchConfig,
    onSearch: refreshTable,
    btnBoolean: true
  }

  return searchProps
}


