import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import { Modal, Button, Steps, Upload, message, Form, Input, Table, Select, Progress, Tag } from 'antd';
import { CloudUploadOutlined, SwapRightOutlined, UploadOutlined } from '@ant-design/icons';
import Api from "@byk/api/Api";
import { apiRequestConfig } from 'api/Api';
import _ from 'lodash';
import { uuid2 } from '@byk/utils/Utils';
import { apiPath } from '@byk/routes';
import { getTenant } from 'utils/localStorage';

const { Step } = Steps;
export interface IProps {
    ref?: any,
    failBack?: (v?: any) => void;
    successBack?: (v?: any) => void;
    actionId?: string;
    onModalClose?: () => void;
}
const ImportDataComponent: React.FC<IProps> = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        openExcelModal
    }))
    const { failBack, actionId,onModalClose, successBack } = props;
    const [visible, setVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(2);
    const [fileList, setFileList] = useState([]);
    const [files, setFiles] = useState("");
    const [pageId, setPageId] = useState("");
    const [appId, setAppId] = useState("");
    const [excelth, setExcelth] = useState([]);//excel 表头
    const [dataTh, setDataTh] = useState([]);//数据库字段
    const [speed, setSpeed] = useState(0);//上传进度
    const [uuid, setUuid] = useState("");
    const [count, setCount] = useState(0);
    const [failCount, setFailCount] = useState(0);
    const [serviceCode, setServiceCode] = useState("");
    const [readApi, setReadApi] = useState(apiRequestConfig.baseURL + `${apiPath}/${getTenant()}/readExcel`);
    const readExcelApi = apiRequestConfig.baseURL + `${apiPath}/${getTenant()}/readExcel`;
    const readExcelMockApi = apiRequestConfig.baseURL + `/byk/platform/mock/mockReadExcel`;
    const importExcelApi = `${apiPath}/${getTenant()}/importExcel`;
    const importExcelMockApi = `/byk/platform/mock/mockImportExcel`;
    const lowcodeServiceApi = `/byk/platform/rest/LowcodeService/list`;
    const sysTaskLogApi = `/byk/${getTenant()}/rest/SysTaskLog/list`;
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [fileName, setFileName] = useState("");
    const [mockFlag, setMockFlag] = useState(false);
    const appActionApi = `/byk/platform/rest/AppAction/list`;

    const openExcelModal = async (_actionId: any) => {
        setVisible(true);
        setCurrentStep(0);
        setFileList([]);
        setFileName("");
        setDataTh([]);
        setFiles("");
        setExcelth([]);
        setSpeed(0);
        setCount(0);
        setUuid("");
        setFailCount(0);
        let result: any = await Api.get(appActionApi, { id: _actionId });;
        if (result.success) {
            if (result.result?.length > 0) {
                let _r = result.result[0];
                let serviceId = _r.serviceId;
                let serviceCode = _r.serviceCode;
                let mockFlag=_r.mockFlag;
                if(mockFlag==true&&_.isEmpty(serviceCode)){
                    setMockFlag(true);
                    setPageId(_r.pageId);
                    setAppId(_r.appId);
                    setReadApi(readExcelMockApi);
                }
                if (_.isEmpty(serviceCode)&&mockFlag!=true) {
                    message.warning("请先配置服务！");
                    return false;
                }
                getMatchFields(serviceId);
            }
        }
    };
    useEffect(() => {
        openExcelModal(actionId);
    }, [actionId])

    const handleCancel = () => {
        onModalClose && onModalClose();
    };

    //获取要匹配的字段
    const getMatchFields = async (serviceId: any) => {
        let result: any = await Api.get(lowcodeServiceApi, { id: serviceId });;
        if (result.success) {
            if (result.result?.length > 0) {
                let r: any = result.result[0];
                let _paramObject = r.paramObject;
                setServiceCode(r.code);
                let _l: any = [];
                _.each(_paramObject, (item: any) => {
                    _l.push({
                        field: item.name,
                        fieldName: item.description
                    })
                })
                setDataTh(_l);
            }
        }
    }

    const beforeUpload = (file: any) => {
        setFileName(file.name);
    }

    //上传后取文件名，列名
    const handleSuccess = (res: any, file: any) => {
        // 假设服务器返回了一个包含文件名的对象
        if (res && res.success) {
            let _excelTh: any = res.result[0];
            setExcelth(_excelTh);
            buildForm(_excelTh);
            setFiles(res.message);
            message.success('上传成功，请完成字段匹配！');
            //handleNext();
        } else {
            message.success(`文件 ${file.name} 上传失败`);
        }
    };

    //上一步
    const handlePre = () => {
        setCurrentStep(currentStep - 1);
    }
    //下一步
    const handleNext = () => {
        if (currentStep === 0) {
            if (_.isEmpty(files)) {
                message.warning("请先上传文件！");
                return false;
            }
        }
        if (currentStep === 1) {
            form.submit();
        }
        setCurrentStep(currentStep + 1);
    };

    const renderStepContent = (step: any) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <ul className='file-notice'>
                            <li>• 仅10M以内且数据行数不超过5000行的*.xls 、 *.xlsx文件
                            </li>
                            <li>
                                • 目前仅支持上传第一个sheet页，请将您要导入的数据放在第一个sheet页中
                            </li>
                            <li>
                                • 目前暂不支持导入图片/附件数据</li>
                        </ul>
                        <Upload
                            action={readApi}
                            listType="picture-card"
                            fileList={fileList}
                            style={{ width: "100%" }}
                            showUploadList={false}
                            accept=".xls,.xlsx"
                            onSuccess={handleSuccess}
                            beforeUpload={beforeUpload}
                        >
                            {fileList.length < 1 ? (
                                <div>
                                    <p className="ant-upload-drag-icon">
                                        <CloudUploadOutlined style={{ fontSize: "76px", color: "#B9BDC7" }} />
                                    </p>
                                    <p className="ant-upload-text">点击或拖拽文件到此处上传</p>
                                    <p className="ant-upload-hint">支持xls,xlsx等类型的文件</p>
                                </div>
                            ) : null}
                        </Upload>
                    </>
                );
            case 1:
                return (
                    <Form form={form} style={{ marginTop: "20px" }} onFinish={onFinish}>
                        <Form.Item label="">
                            <Table scroll={{ y: '480px' }} dataSource={dataSource} size="middle" bordered={true} columns={columns} pagination={false} />
                        </Form.Item>
                    </Form>
                );
            case 2:
                return (
                    <div className='x-step2'>
                        <Progress type="circle" width={100} percent={speed} />
                        {speed != 100 && (
                            <p className='x-success'>数据导入中</p>
                        )}
                        {speed == 100 && (
                            <p className='x-success'>导入数据完成，成功<span style={{ color: "#009D53" }}>{count}</span>条{failCount > 0 && (<>,失败<span style={{ color: "red" }}>{failCount}</span>条</>)}</p>
                        )}
                        {/* <p className='x-notice'>关闭弹窗后，可在「应用设置-任务日志」中查看导入日志</p> */}
                        
                        <Button style={{marginTop: '35px'}} type="primary" onClick={handleCancel} >
                            关闭
                        </Button>
                        <p style={{ fontSize: "12px" }} className='x-notice'>关闭弹框不会中断数据导入，关闭后可前往「应用设置-日志中心」中查看导入进度</p>
                    </div>
                );
            default:
                return null;
        }
    };



    //创建匹配数据
    const buildForm = (_excelth: any) => {
        let _l: any = [];
        _.each(_excelth, (itm: any) => {
            let _v: any = "";
            let m: any = dataTh.filter((d: any) => {
                return d.fieldName == itm;
            })
            if (m.length > 0) {
                _v = m[0].field;
            }
            _l.push({ key: uuid2(), label: itm, value: _v });
        })
        console.log(_l);
        setDataSource(_l);
    }

    const columns = [
        {
            title: '导入表头',
            dataIndex: 'label',
            render: (text: any, record: any) => (
                <span>{record.label}</span>
            )
        },
        {
            title: '',
            dataIndex: '',
            render: (text: any, record: any) => (
                <SwapRightOutlined style={{ color: "#006cf2", fontSize: "24px" }} />
            ),
            width: "60px",
            align: "center"
        },
        {
            title: '字段',
            dataIndex: 'value',
            render: (text: any, record: any) => (
                <Select
                    style={{ width: "100%" }}
                    defaultValue={record.value || ""}
                    onChange={(e: any) => {
                        debugger;
                        const newData: any = [...dataSource];
                        const index = newData.findIndex((item: any) => record.key === item.key);
                        newData[index].value = e;
                        setDataSource(newData);
                    }}
                    fieldNames={{ label: "fieldName", value: "field", options: dataTh }}
                    options={dataTh}
                />
            ),
        }
    ];

    const onFinish = async (values?: any) => {
        console.log('Table data:', dataSource);
        let mapping: any = {};
        _.each(dataSource, (item: any) => {
            if (!_.isEmpty(item.value)) {
                mapping[item.value] = item.label;
            }
        });
        let api=importExcelApi;
        let params:any = {
            fileName: files,  //上传并读取Excel表头接口返回fileName
            serviceCode: serviceCode,//服务编排的服务编号
            mapping: mapping,//字段映射
            isAsync: true
        }
        if(mockFlag){
            api=importExcelMockApi;
            params={
                fileName: files,
                appId: appId,
                pageId:pageId,
                isAsync: true
            }
        }
        let result: any = await Api.post(api, params);
        if (result.success) {
            let _uid = result.ext;
            setUuid(_uid);
            let timer: NodeJS.Timeout, speed = 200;
            let fn = async () => {
                let resSpeed: any = await Api.get(sysTaskLogApi, {
                    uuid: _uid
                });
                if (!_.isEmpty(resSpeed.result)) {
                    let da = resSpeed.result[0];
                    let _speed: any = Math.ceil(100 * da.current / da.size);
                    setSpeed(_speed);
                    if (da.finish) {
                        setCount(da.success);
                        setFailCount(da.size - da.success);
                        clearTimeout(timer);
                        successBack && successBack();
                    } else {
                        timer = setTimeout(fn, speed);
                    }
                } else {
                    timer = setTimeout(fn, speed);
                }
            };
            fn();
        }
        else {
            failBack&&failBack();
            return false;
        }
    };


    return (
        <div className='x-mt30'>
            <Steps current={currentStep}>
                <Step title="上传文件" />
                <Step title="字段匹配" />
                <Step title="导入数据" />
            </Steps>
            <div className="step-content">{renderStepContent(currentStep)}</div>
            {
                !_.isEmpty(files) && currentStep == 0 && (
                    <Tag
                        color="#006cf2"
                        closable
                        onClose={e => {
                            setFiles("");
                            setFileName("");
                        }}
                    >
                        {fileName}
                    </Tag>
                )
            }
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            {
              currentStep == 1 && (
                <Button onClick={handlePre} >
                  上一步
                </Button>
              )
            }
            {
              currentStep != 2 && (
                <Button type="primary" style={{ marginLeft: "19px" }} onClick={handleNext}>
                  下一步
                </Button>
              )
            }

          </div>
        </div>
    );
});

export default ImportDataComponent;
