import React, {forwardRef, useCallback, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Popover, Table, Tabs} from "antd";
import {getIconPark} from "../../../../components/propertyControls/IconSelectControl";
import {QueryEditorContext} from "@byk/pages/modeling/SqlQueryEditor/QueryEditorContext";
import {DownOutlined, PlusOutlined, UpOutlined} from "@ant-design/icons";
import {PROPERTY_REL_TYPE} from "@byk/pages/modeling/constants/Constants";
import {getModelTableName} from "@byk/pages/modeling/lib/SqlQueryEditorModel";
import {CODE_MIRROR_DEFAULT_HEIGHT, schema_tab_height, SUB_DEFAULT_HEIGHT_schema, SUB_MAX_HEIGHT_schema,} from ".";

const ContainerDiv = styled.div<any>`
  position: relative;
  width: 100%;
  height: ${({height}) => height};
  border-top: 1px solid #f0f0f0;
  z-index: 1;
  bottom: 0px;
  position: absolute;

  .ant-tabs-nav {
    padding-left: 12px;
  }
  .ant-tabs-content-holder {
    padding-left: 5px;
  }

  .schema_list_container {
    display: flex;
  }

  .schema_list {
    height: calc(100vh - ${({schemaSubHeight}) => schemaSubHeight + schema_tab_height}px);
    border-right: 1px solid #f0f0f0;
    padding-right: 8px;
    overflow-y: auto;

    ul {
      padding-top: 16px;
    }
  }

  .schema_properties{
    width: 650px;
    overflow-y: auto;

    ul {
      padding: 0px 12px;
    }
  }

  .schema_list_item, .schema_properties_item{
    display: flex;
    align-items: center;
    position: relative;
    width: 320px;
    padding: 5px 0px;

    .anticon-plus {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0px;
      background: #d6d4d4;
      border-radius: 4px;
      height: 100%;
      padding: 0 5px;
    }
  }

  .schema_properties_item{
    justify-content: space-between;
    width: 100%;
    color: #4c5664;
  }

  .schema_properties_item_right{
    display: flex;
    width: 100%;
    cursor: pointer;
    align-items: center;
  }

  .schema_list_item_selected {
    background: #e3e8ef;
    border-radius: 4px;
    color: #4c5664;
  }

  .schema_list_item:hover, .schema_properties_item:hover {
    background: #f1f5f9;
    border-radius: 4px;
    color: #4c5664;
  }

  .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0px 0;
  }

  .tabSwitch {
    position: absolute;
    right: 16px;
    top: 12px;
    cursor: pointer;
  }

  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    padding: 8px !important;
  }

  .schema_response_container {
    .ant-tabs-tab {
      padding: 4px !important;
    }

    .ant-tabs-nav, .ant-tabs-content-holder {
      padding-left: 0px !important;
    }
  }

`

const addSuggestedSqlPopupStyled = `
  .addSqlPopoverUl {
    border-radius: 8px;

    .addSqlPopoverUlLi {
      padding: 4px;
      margin: 3px 0;
      width: 120px;
      cursor: pointer;
    }

    .addSqlPopoverUlLi:hover {
      background: #f1f5f9;
      border-radius: 5px;
    }
  }
`

const Schema: React.FC<any> = forwardRef((props, ref) => {
  const {sqlQueryEditorMode, setState, getState} = useContext(QueryEditorContext);
  const {executionResult, tabKey, schemaSubHeight, codeMirrorHeight, sqlInfo} = getState();
  const [selectedModel, setSelectedModel] = useState<any>(null);
  const [hoverItem, setHoverItem] = useState<any>(null);
  const [closeTab, setCloseTab] = useState<any>(false);
  const [addSqlPopover, setAddSqlPopover] = useState<any>(false);
  const schemaContainerDivRef = useRef<any>();
  const resultTableRef = useRef<any>();
  const schemaListRef = useRef<any>();
  const [tableScroll, setTableScroll] = useState({y: `calc(100vh - ${schemaSubHeight + schema_tab_height}px)`});
  const [responseTabItems, setResponseTabItems] = useState<any>([]);

  useEffect(() => {
    let _executionResult = executionResult || {};
    const _tabItems: any = [];

    Object.keys(_executionResult).map((key: any) => {
      let result = _executionResult[key];
      let countExceed = result?.countExceed;
      let data = result?.data;
      let rows = result?.rows;
      let typeError = result?.typeError;
      let exception = result?.exception;

      let children = <div>{JSON.stringify(result)}</div>;
      if (data && data.length > 0) {
        children = <Table ref={resultTableRef}
                          scroll={tableScroll}
                          dataSource={data} columns={result.tableColumns} pagination={false}/>
      } else if (countExceed != undefined) {
        children = <div>{countExceed}</div>;
      } else if (rows != undefined) {
        children = <div>{rows}</div>;
      } else if (typeError != undefined) {
        children = <div>{typeError}</div>;
      } else if (exception != undefined) {
        children = <div>{exception}</div>;
      }
      _tabItems.push({label: key, key: key, children: children,});
    })
    setResponseTabItems(_tabItems);
    let addTabH = _tabItems.length > 1 ? 30 : 0;
    setTableScroll({y: `calc(100vh - ${schemaSubHeight + schema_tab_height + 35 + addTabH}px)`});
  }, [executionResult, schemaSubHeight]);

  const AddSuggestedSqlPopupContent = useCallback(() => {
    const addSql = (type: any) => {
      let sql: any = hoverItem.crudTemplates[type];
      setAddSqlPopover(false);
      setHoverItem(null);
      setState({sqlInfo: {...sqlInfo, sql: sql}})
    }

    return <>
      <ul className={'addSqlPopoverUl'}>
        <li className={'addSqlPopoverUlLi'} onClick={() => addSql('SELECT')}>Select</li>
        <li className={'addSqlPopoverUlLi'} onClick={() => addSql('INSERT')}>Insert</li>
        <li className={'addSqlPopoverUlLi'} onClick={() => addSql('UPDATE')}>Update</li>
        <li className={'addSqlPopoverUlLi'} onClick={() => addSql('DELETE')}>Delete</li>
      </ul>
    </>
  }, [hoverItem])

  const schemaList = useCallback(() => {
    let properties = selectedModel?.properties || [];
    return <div className={'schema_list_container'}>
      <style>{addSuggestedSqlPopupStyled}</style>
      <div ref={schemaListRef} className={'schema_list'}>
        <ul>
          {sqlQueryEditorMode.modelList.map((item: any) => {
            let isSelected = selectedModel?.id == item.id ? 'schema_list_item_selected' : '';
            let showDelBtn = hoverItem?.id == item.id || selectedModel?.id == item.id;
            return <li className={`schema_list_item ${isSelected}`}>
              <div className={'schema_properties_item_right'}
                   onMouseEnter={() => {
                     setHoverItem(item);
                     setAddSqlPopover(false);
                   }}
                   onClick={() => setSelectedModel(item)}>
                {getIconPark('Table', 'gray')}
                <div>{item._table_name}</div>
              </div>
              {showDelBtn &&
              <Popover
                placement="rightTop"
                content={AddSuggestedSqlPopupContent}
                trigger={'click'}
              >
                <PlusOutlined style={{cursor: 'pointer'}} onMouseEnter={() => setHoverItem(item)}/>
              </Popover>
              }
            </li>
          })}
        </ul>
      </div>
      <div className={'schema_properties'}>
        <ul>
          {properties.map((item: any) => {
            let isPrimary = item.primaryKey;
            let isFk = item.type == PROPERTY_REL_TYPE.M2O;
            return <li className={`schema_properties_item`}>
              <div>{item?._column_name}</div>
              <div
                style={{fontSize: '12px'}}>{isPrimary ? 'primary key' : isFk ? `foreign key(${getModelTableName(item.targetModel)})` : ''}</div>
            </li>
          })}
        </ul>
      </div>
    </div>;
  }, [selectedModel, hoverItem, tabKey, addSqlPopover])

  const sqlResponse = useCallback(() => {

    if (responseTabItems.length == 1) {
      return <div className={'schema_response_container'}>{responseTabItems[0].children}</div>;
    } else {
      return <div className={'schema_response_container'}><Tabs items={responseTabItems}/></div>;
    }
  }, [executionResult, tabKey, responseTabItems])

  const items = [
    {label: '物理表', key: 'Schema', children: schemaList()}, // 务必填写 key
    {label: '响应', key: 'Response', children: sqlResponse()}, // 务必填写 key
  ];

  const onCloseTab = () => {
    if (!closeTab) {
      setState({schemaSubHeight: SUB_MAX_HEIGHT_schema, codeMirrorHeight: true});
    } else {
      setState({schemaSubHeight: SUB_DEFAULT_HEIGHT_schema, codeMirrorHeight: CODE_MIRROR_DEFAULT_HEIGHT});
    }
    setCloseTab(!closeTab);
  }

  let containerDivHeight: any = `calc(100vh - ${schemaSubHeight}px)`;
  if (typeof codeMirrorHeight == 'boolean') {
    containerDivHeight = '46px';
  }

  return (
    <ContainerDiv ref={schemaContainerDivRef} className={'schema'}
                  height={containerDivHeight}
                  schemaSubHeight={schemaSubHeight}>
      <Tabs activeKey={tabKey} items={items} onTabClick={(key) => {
        setState({tabKey: key})
      }}/>

      <div className={'tabSwitch'} onClick={onCloseTab}>
        {closeTab ? <UpOutlined/> : <DownOutlined/>}
      </div>
    </ContainerDiv>
  )
})

export default Schema;
