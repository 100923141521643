import React from 'react';
import {AtProgress} from 'taro-ui';
import styled from "styled-components";
const ProgressWrapper = styled.div<{
  color: string;
  bgColor: string;
  borderColor: string;
  fontSize: number;
}>`
  width: 100%;
  height: 100%;
  .at-button{
    background-color: ${({bgColor})=> bgColor};
    border-color: ${({borderColor})=> borderColor};
    color: ${({color})=> color};
    font-size: ${({fontSize})=> fontSize}px;
  }
`;
const TProgressWidget = (props:any) => {
  const {isHidePercent, percent, color} = props;
  return (
    <AtProgress color={color} isHidePercent={isHidePercent} percent={percent} />
  );
};

export default TProgressWidget;
