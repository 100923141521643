import React, { useCallback } from "react";
import { Progress } from 'antd';
import styled from "styled-components";

export const DivContainer = styled.div<{
  
}>`


`;

function CarouselComponent(props: any) {
  const {
    value,
    isShowInfo,
    stype,
    strokeColor,
    trailColor,
    borderWidth
  } = props;

  return (
    <>
      <DivContainer>
        <Progress percent={value} 
        showInfo={isShowInfo} 
         type={stype}
         strokeColor={strokeColor}
         trailColor={trailColor}
         strokeWidth={borderWidth||8}
        style={{ width: "100%" }}/>
      </DivContainer>
    </>
  )
}

export default CarouselComponent;
