import React from "react";
import styled from "styled-components";
import { Button, Calendar, Col, Row, Typography } from 'antd';
import type { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import type { Moment } from 'moment';
import 'moment/locale/zh-cn';
import moment from 'moment';
import zh_CN from 'antd/es/date-picker/locale/zh_CN';
import { FontStyleTypes } from 'design-system-old';
import {hexToRgb} from "@byk/utils/Utils";
moment.locale('zh-cn'); // 设置moment的locale为中文

const defaultConfig: any = {
  monthColor: "black",
  monthFontSize: "1rem",
  monthFontStyle: "BOLD",

  weekColor: "gray",
  weekFontSize: "",
  weekFontStyle: "",

  dateColor: "",
  dateFontSize: "",
  dateFontStyle: "",

  selectColor: "#2561ef",
  markColor: "#fa746b",
}

const DivContainer = styled.div<{
  monthColor?: string,
  monthFontSize?: string,
  monthFontStyle?: string,

  weekColor?: string,
  weekFontSize?: string,
  weekFontStyle?: string,

  dateColor?: string,
  dateFontSize?: string,
  dateFontStyle?: string,

  selectColor?: string,
  markColor?: string,

  boxShadow?: string,

  borderWidth?: string,
  borderRadius?: string,
  selectColor10?: string,
}>`
    width: 100%;
    height:100%;
    min-width:255px;
    .z-Calendar-main{
      box-shadow: ${({ boxShadow }) => `${boxShadow}`} !important;
      border-width: ${({ borderWidth }) => `${borderWidth}px`} !important;
      border-radius: ${({ borderRadius }) => (borderRadius)};
    }
    .z-Calendar-main .ant-picker-panel{
      border-radius: ${({ borderRadius }) => (`0 0 ${borderRadius} ${borderRadius}`)};
    }
    //月切换字体设置
    .z-Calendar-date{
      font-size:${({ monthFontSize }) => monthFontSize || defaultConfig.monthFontSize}!important;
      color: ${({ monthColor }) => monthColor || defaultConfig.monthColor}!important;
      font-style: ${({ monthFontStyle }) => monthFontStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : ""}  !important;
      font-weight: ${({ monthFontStyle }) => monthFontStyle?.includes(FontStyleTypes.BOLD) ? "BOLD" : ""}  !important;
    }
    //星期字体设置
    .ant-picker-calendar-mini .ant-picker-content th {
      height: auto;
      padding: 0;
      line-height: 22px;
      font-size:${({ weekFontSize }) => weekFontSize || defaultConfig.weekFontSize}!important;
      color: ${({ weekColor }) => weekColor || defaultConfig.weekColor}!important;
      font-style: ${({ weekFontStyle }) => weekFontStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : ""}  !important;
      font-weight: ${({ weekFontStyle }) => weekFontStyle?.includes(FontStyleTypes.BOLD) ? "BOLD" : ""}  !important;
    }
    .ant-picker-cell-inner .ant-picker-calendar-date-value{
      font-size:${({ dateFontSize }) => dateFontSize || defaultConfig.dateFontSize}!important;
      color: ${({ dateColor }) => dateColor || defaultConfig.dateColor};
      font-style: ${({ dateFontStyle }) => dateFontStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : ""}  !important;
      font-weight: ${({ dateFontStyle }) => dateFontStyle?.includes(FontStyleTypes.BOLD) ? "BOLD" : ""}  !important;
    }
    .ant-picker-cell .ant-picker-cell-inner {
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 20px;
    }
    //选中背景色
    .ant-picker-cell-selected .ant-picker-cell-inner {
      background:${({ selectColor }) => selectColor || defaultConfig.selectColor}!important;
    }
    .ant-picker-cell-selected  .ant-picker-cell-inner .ant-picker-calendar-date-value
      color: white !important;
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{border: none;}
    //提醒色颜色
    .dot-bottom::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background-color: ${({ markColor }) => markColor || defaultConfig.markColor}!important;
      border-radius: 50%;
      margin-top: -6px;
      margin-left: 13px;
    }
    .ant-picker-cell-selected  .dot-bottom::after {
      background-color: white !important; //选中的圆点颜色
    }

    .ant-btn:focus, .ant-btn:hover {
      color: ${({ selectColor }) => selectColor}!important;
      border-color: ${({ selectColor }) => selectColor }!important;
      background-color: ${({ selectColor10 }) => selectColor10 }!important;
    }
`;

function CarouselComponent(props: any) {
  const {
    onClick,
    value,
    monthColor,
    monthFontSize,
    monthFontStyle,

    weekColor,
    weekFontSize,
    weekFontStyle,

    dateColor,
    dateFontSize,
    dateFontStyle,

    selectColor,
    markColor,

    boxShadow,
    borderWidth,
    borderRadius,
    currentData
  } = props;
  const selectColor10 = hexToRgb(selectColor, 0.1);
  const _value = value || currentData;
  const onPanelChange = (value: Moment, mode: CalendarMode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };
  const onItemClick = () => {
    onClick && onClick();
  }
  return (
    <>
      <DivContainer
        monthColor={monthColor}
        monthFontSize={monthFontSize}
        monthFontStyle={monthFontStyle}

        weekColor={weekColor}
        weekFontSize={weekFontSize}
        weekFontStyle={weekFontStyle}

        dateColor={dateColor}
        dateFontSize={dateFontSize}
        dateFontStyle={dateFontStyle}

        selectColor={selectColor}
        selectColor10={selectColor10}
        markColor={markColor}

        boxShadow={boxShadow}
        borderWidth={borderWidth}
        borderRadius={borderRadius}
      >
        <Calendar
          fullscreen={false}
          locale={zh_CN}
          className="z-Calendar-main"
          dateFullCellRender={(date) => {
            let strDate = date.format('YYYY-MM-DD');
            let len = (Array.isArray(_value)) ? _value.filter((itm: any) => {
              return itm.date == strDate;
            }).length : 0;

            return (
              <>
                <div className="ant-picker-cell-inner ant-picker-calendar-date" onClick={onItemClick}>
                  <div className={`ant-picker-calendar-date-value ${len > 0 ? "dot-bottom" : ""}`}>
                    {date.date()}
                  </div>
                </div>
              </>
            )
          }}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const change = (type: number) => {
              let newDate = type == 1 ? value.add(1, 'months') : value.subtract(1, 'months');
              onChange(newDate);
            }

            const changeNow = () => {
              onChange(moment());
            }

            const year = value.year();
            const month = value.month() + 1;

            return (
              <div style={{ padding: 8 }}>
                <Row >
                  <Col span={12} className='z-Calendar-date'>
                    <span style={{ cursor: "pointer" }} onClick={() => change(0)}>{"<"}&nbsp;</span>
                    <span>{year}年{month}月</span>
                    <span style={{ cursor: "pointer" }} onClick={() => change(1)}>&nbsp;{">"}</span>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Button size={"small"} onClick={changeNow}>今天</Button>
                  </Col>
                </Row>
              </div>
            );
          }}
          onPanelChange={onPanelChange}
        />
      </DivContainer>
    </>
  )
}

export default CarouselComponent;
