import type { WidgetType } from "constants/WidgetConstants";
import * as React from "react";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import AmapComponent from "../component";
import { ValidationTypes } from "constants/WidgetValidation";
import type { Stylesheet } from "entities/AppTheming";
import type { DerivedPropertiesMap } from "utils/WidgetFactory";
import { EvaluationSubstitutionType } from "../../../entities/DataTree/types";
import { PropertyPaneConfig } from "../../../constants/PropertyControlConstants";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";

class AmapViewWidget extends BaseWidget<AmapWidgetProps, WidgetState> {
  constructor(props: AmapWidgetProps) {
    super(props);
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "标签",
        children: [
          {
            helpText: "Sets the label text of the widget",
            propertyName: "labelText",
            label: "文本",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          }
        ]
      },
      {
        sectionName: '数据',
        children: [
          {
            helpText: "Bind data from an API using {{}}",
            propertyName: "currentData",
            label: "Data",
            controlType: "INPUT_TEXT",
            placeholderText: '[]',
            inputType: "ARRAY",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.OBJECT_ARRAY,
              params: {
                default: [],
              },
            },
            evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
          }

        ]
      },
      {
        sectionName: "设置",
        children: [
          {
            propertyName: "dragEnable",
            label: "支持拖动地图",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "zoomEnable",
            label: "支持缩放地图",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isSearch",
            label: "允许搜索",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isShowCard",
            label: "点击标记点打开记录详情卡片",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "zoomLevel",
            label: "默认地图层级",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 3,
            max: 18,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            }
          },
        ]
      },
      {
        sectionName: "地图API设置",
        children: [
          {
            propertyName: "securityJsKey",
            label: "key",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "securityJsCode",
            label: "安全密钥",
            controlType: "INPUT_TEXT",
            placeholderText: "Enter label text",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ]
      },
      {
        sectionName: "一般",
        children: [
          {
            helpText: "Controls the visibility of the widget",
            propertyName: "isVisible",
            label: "是否可见",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          }
        ],
      }
    ];
  }
  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "颜色",
        children: [

          {
            propertyName: "borderColor",
            label: "边框颜色",
            helpText: "Control the color of the label associated",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "边框&阴影",
        children: [
          {

            propertyName: "borderWidth",
            label: "边框宽度",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            defaultValue: 1,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText: "Rounds the corners of the icon button's outer border edge",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText:
              "Enables you to cast a drop shadow from the frame of the widget",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getPropertyPaneEventConfig(): PropertyPaneConfig[] {
    return super.getWidgetEvents('AmapViewWidget');
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      value: `{{ this.value }}`,
      getValue: `{{ this.value }}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      value: "defaultValue",
    };
  }

  // TODO Find a way to enforce this, (dont let it be set)
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      value: "",
    };
  }

  updateWidgetProperty = (propertyValues: any) => {
    this.props.updateWidgetMetaProperty("value", propertyValues);
  }

  onChange = () => {
    super.executeAction({
      triggerPropertyName: "onChange",
      dynamicString: this.props.onChange,
      event: {
        type: EventType.ON_CHANGE,
      },
    });
  };

  getPageView() {
    return <AmapComponent
      {...this.props}
      onChange={this.onChange}
      updateWidgetProperty={this.updateWidgetProperty} />;
  }

  static getWidgetType(): WidgetType {
    return "AMAP_VIEW_WIDGET";
  }
}

export interface AmapWidgetProps extends WidgetProps {
  isLast: boolean,
  isSearch: boolean,
  isMultiple: boolean
}

export default AmapViewWidget;
