import React, {useCallback, useEffect, useState} from 'react';
import { Resizable } from 'react-resizable';
import { Table } from 'antd';
import styled from "styled-components";
import _ from "lodash";

const StyledResizable = styled((props: any) => (<Resizable {...props} />))`
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  
  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }
`;
const ResizableTitle: React.FC<any> = ({ onResize, onResizeStop, onResizeStart, width, onToggleDrag, columns, ...restProps }) => {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <StyledResizable
      width={width}
      minWidth={200}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(ev)=>{
            ev.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </StyledResizable>
  );
};

const ResizeTable: React.FC = (props:any) => {
  const {
    onToggleDrag,
    columns:parentColumns,
    dataSource,
    bordered,
    updateWidgetProperty,
    scroll,
    ...otherProps} = props;
  const [columns, setColumns] = useState<any[]>([]);
  const [totalWidth, setTotalWidth] = useState(800);
  const handleResize = (index: number) => (e: any, { size }: any) => {
    setColumns((prevColumns) => {
      const nextColumns:any = [...prevColumns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      const total = nextColumns.reduce((acc:any, column:any) => acc + (column.width??0), 0);
      setTotalWidth(total);
      return nextColumns;
    });
  };
  const handleResizeStop = () => {
    // 处理拖拽完成或鼠标mouseup事件
    console.log('拖拽完成，最终尺寸:', columns);
    onToggleDrag(false);
    let columnWidthMap:any = {};
    _.each(columns, (i:any)=>{
      if(i.width&&i.id){
        columnWidthMap[i.id] = i.width;
      }
    })
    updateWidgetProperty("columnWidthMap", columnWidthMap);
  }
  const handleResizeStart = () => {
    onToggleDrag(true);
  }

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const columnsWithResize:any = columns.map((col:any, index) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index),
      onResizeStop: handleResizeStop,
      onResizeStart: handleResizeStart,
      onToggleDrag,
      columns
    }),
  }));
  useEffect(()=>{
    //console.log('parentColumns', parentColumns);
    const _columns:any = parentColumns.map((i:any)=>{
      return {
        ...i,
        width: i.width?parseInt(i.width):i.width
      }
    })
    const isEqualLabel = _columns.every((item:any, index:any) => {
      return _.isEqual(item.label, columns?.[index]?.label)
        &&_.isEqual(item.render, columns?.[index]?.render);
    });
    if (!isEqualLabel||_.isEmpty(columns)) {
      //console.log('0000', _columns);
      //console.log('1111', columns);
      setColumns(_columns);
    }
  }, [parentColumns]);

  return (
    <Table
      {...otherProps}
      bordered={bordered}
      components={components}
      columns={columnsWithResize}
      dataSource={dataSource}
      scroll={{...scroll, x: totalWidth }}
    />
  );
};

export default ResizeTable;
