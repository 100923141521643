import React, { useCallback, useEffect, useRef } from "react";
import * as echarts from 'echarts';
import _ from 'lodash';
import { EChartsType } from "echarts/types/dist/echarts";

function EchartsComponent(props: any) {
  const { currentData, maincanvasWidth, rightColumn, leftColumn,
    isDownload, fontColor, isDataView, backgroundColor, fontStyle, fontFamily,
    borderColor, borderWidth, boxShadow, borderRadius, isTitle, title, isLegend, radius,
    isProgress,//是否显示进度
    isAxisTick,//是否显示小刻度
    isAnchor,//是否显示圆心
    isValueAnimation,//是否开启数值动画
    progressWidth,//进度条宽度
    axisLineWidth,//圆周宽度
    splitLineLength,//大刻度长度
    splitLineWidth,//大刻度宽度
    splitLineColor,//大刻度颜色
    axisLabelDistance,//离圆周距离
    axisLabelColor,//刻度文字颜色 
    axisLabelFontSize,//刻度文字大小
    anchorSize,//圆心大小
    anchorBorderWidth,//圆心宽度
    detailFontSize,//数值文字大小 
    detailOffsetCenter,//数值垂直位置
    progressColor,
    pointerColor,
    anchorColor,
    anchorBorderColor
  } = props;
  const chartRef = useRef(null);
  const chartInstance = useRef<EChartsType>();
  const temp: any = [{
    // "title": {
    //   "text": title,
    //   "show": isTitle,
    //   "left": 'center'
    // },
    "tooltip": {
      "trigger": 'item'
    },
    "toolbox": {
      "feature": {
        "saveAsImage": { "title": "下载", "show": isDownload },
        "dataView": { "title": "数据视图", "show": isDataView, "lang": ['数据视图', '关闭', '刷新'] }
      }
    },


    "backgroundColor": backgroundColor || "transparent",
    // "textStyle": {
    //   "color": fontColor,
    //   "fontStyle": fontStyle?.indexOf("italic") > -1 ? "italic" : "",
    //   "fontWeight": fontStyle?.indexOf("bold") > -1 ? "bold" : "normal",
    //   "fontFamily": fontFamily,
    // },
    series: [
      {
        type: 'gauge',
        progress: {
          show: isProgress,//是否显示进度  isProgress:true
          width: progressWidth,//进度条宽度    progressWidth:18
          "itemStyle": {
            "color": progressColor  //进度条颜色  progressColor
          }
        },
        "pointer": {
          "itemStyle": {
            "color": pointerColor,   //指针颜色  pointerColor

          }
        },
        axisLine: {
          lineStyle: {
            width: axisLineWidth//圆周宽度     axisLineWidth:18
          }
        },
        axisTick: {
          show: isAxisTick//是否显示刻度    isAxisTick:false
        },
        splitLine: {
          length: splitLineLength,//大刻度长度      splitLineLength:15
          lineStyle: {
            width: splitLineWidth,//大刻度宽度     splitLineWidth:2
            color: splitLineColor//大刻度颜色   splitLineColor:"#fff"
          }
        },
        axisLabel: {
          distance: axisLabelDistance,//离圆周距离    axisLabelDistance:25
          color: axisLabelColor,//刻度文字颜色   axisLabelColor:"red"
          fontSize: axisLabelFontSize//刻度文字大小    axisLabelFontSize:20
        },
        anchor: {
          show: isAnchor,//是否显示圆心    isAnchor:true
          showAbove: true,
          size: anchorSize,//圆心大小       anchorSize:25
          itemStyle: {
            borderWidth: anchorBorderWidth,//圆心宽度   anchorBorderWidth:10
            "color": anchorColor,   //圆心空白颜色  anchorColor
            "borderColor": anchorBorderColor //圆心圆周颜色 anchorBorderColor
          }
        },
        title: {
          show: false
        },
        detail: {
          valueAnimation: isValueAnimation,//是否开启数值动画  isValueAnimation:true
          fontSize: detailFontSize,//数值文字大小       detailFontSize:80
          color: fontColor,
          offsetCenter: [0, detailOffsetCenter + '%']//数值垂直位置   detailOffsetCenter:70
        },
        data: [

        ]
      }
    ]
  }];

  const d1: any = [
    { value: 1048, name: 'Search Engine' },
    { value: 735, name: 'Direct' },
    { value: 580, name: 'Email' },
    { value: 484, name: 'Union Ads' },
    { value: 300, name: 'Video Ads' }
  ]
  const formatData: any = () => {

  }

  const renderChart = () => {
    //console.log("xxxxxxxxxxxxxxx", props);
    if (!_.isArray(currentData)) {
      alert("error");
      return false;
    }
    let _currentData: any = _.cloneDeep(temp);
    _currentData[0].series[0].data = currentData||[];
    chartInstance.current = echarts.init(chartRef.current);
    //console.log("2222222", _currentData);
    const option = {
      ..._currentData[0]
    };
    chartInstance.current.setOption(option);
  };
  useEffect(() => {
    if (chartInstance.current) {
      setTimeout(() => {
        chartInstance.current?.resize();
      }, 200);
    }
  }, [maincanvasWidth, rightColumn, leftColumn]);

  useEffect(() => {
    try{
      renderChart();
    }
    catch(e:any){

    }
    // if (!_.isEmpty(currentData)) {
    //   renderChart();
    // }
    //}, [currentData, sType, isDarkMode, color, isSmooth, isAreaStyle, isDownload, fontColor]);
  }, [props]);

  const DataDom = useCallback(() => {
    if (_.isEmpty(currentData)) {
      return <>暂无数据</>;
    } else {
      return <div ref={chartRef} style={{
        width: '100%', height: '100%', overflow: 'hidden', borderColor: borderColor || "#ccc",
        borderWidth: borderWidth, borderRadius: `${borderRadius}`, boxShadow: `${boxShadow}`
      }} />;
    }
  }, [props]);
  // }, [currentData]);

  return <DataDom />;
}

export default EchartsComponent;
