import React from "react";
import type {ControlProps} from "./BaseControl";
import BaseControl from "./BaseControl";
import styled from "styled-components";
import {useUploadImageCode} from "../../utils/hooks/useUploadImageCode";
import { DeleteOutlined } from "@ant-design/icons";
import {PlusOutlined} from "@ant-design/icons/lib";
const UploadImageCodeWrapper = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f4f4f4;
  .x-input-file{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
  }
  .x-image-del{
    position: absolute;
    right: 4px;
    top: 0;
    cursor: pointer;
    display: none;
  }
  &:hover .x-image-del{
    display: block;
  }
`;
const UploadImage = (props: any) => {
  let {valueChange, propertyValue} = props;

  const { previewImagePath, uploadImageCode } = useUploadImageCode();
  const uploadChange = (ev: any) => {
    let files = ev.target.files;
    if (files[0]) {
      uploadImageCode(files[0], (reImgCode:string)=>{
        console.log(previewImagePath(reImgCode));
        valueChange&&valueChange(reImgCode);
      })
    }
  };
  const onDelete = ()=>{
    valueChange&&valueChange('');
  }
  return (
    <UploadImageCodeWrapper>
      {
        !propertyValue&&
          <>
            <div className="x-input-add"><PlusOutlined /></div>
            <input className="x-input-file" accept='.jpg,.png,.jpeg' type="file" onChange={uploadChange} />
          </>
      }
      {
        propertyValue&&(
          <>
            <img src={previewImagePath(propertyValue)} alt="" />
            <div className="x-image-del" onClick={onDelete}>
              <DeleteOutlined />
            </div>
          </>
        )
      }
    </UploadImageCodeWrapper>
  );
};

class ImageCodeControl extends BaseControl<ImageCodeControlProps> {
  render() {
    const {propertyValue} = this.props;
    return (
      <UploadImage propertyValue={propertyValue} valueChange={this.valueChange} />
    );
  }

  valueChange = (code: string) => {
    this.updateProperty(this.props.propertyName, code, true);
  }


  static getControlType() {
    return "UPLOAD_IMAGE_CODE";
  }
}

export interface ImageCodeControlProps extends ControlProps {
  value: string
}

export default ImageCodeControl;
