import React, {useEffect, useState} from "react";
import {TreeSelect} from "antd";
import {useSelector} from "react-redux";
import {findNodeById} from "../../utils/XUtils";

function XTreeSelectPage(props:any){
  const [value, setValue] = useState(props.value);
  const pages = useSelector((state:any)=>{
    return state.entities.pageList.pages.filter((i:any)=>{
      return i.slug != 'frameDesignPage';
    });
  });
  const onChange = (value:any)=>{
    let selectPage = findNodeById(pages, value);
    props.onChange&&props.onChange(value, selectPage);
  };
  useEffect(()=>{
    setValue(props.value);
  },[props.value])
  return (
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      value={value}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Please select"
      allowClear
      treeDefaultExpandAll
      treeData={pages}
      fieldNames={{
        label: 'title',
        value: 'key',
        children: 'children'
      }}
      onChange={onChange}
    />
  )
}
export default XTreeSelectPage;
