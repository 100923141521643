import React, {createContext, useMemo} from "react";
import {SqlQueryEditorModel} from "@byk/pages/modeling/lib/SqlQueryEditorModel";

interface QueryEditorContextContextProps {
  gotoModeling: () => void;
  sqlQueryEditorMode: SqlQueryEditorModel;
  codeMirrorSqlRef: any;
  addNewSqlInfo: () => any;
  refreshCmp: () => void;
  setState: (param: any) => void;
  getState: () => any;
  sqlInfoAction: (type: any, params:any) => void;
}

type QueryEditorContextProviderProps =
  React.PropsWithChildren<QueryEditorContextContextProps>;

export const QueryEditorContext = createContext<QueryEditorContextContextProps>(
  {} as QueryEditorContextContextProps,
);

export function QueryEditorContextProvider(
  {
    gotoModeling,
    sqlQueryEditorMode,
    codeMirrorSqlRef,
    addNewSqlInfo,
    refreshCmp,
    setState,
    getState,
    children,
    sqlInfoAction,
  }: QueryEditorContextProviderProps) {
  const value: QueryEditorContextContextProps = useMemo(
    () => ({
      gotoModeling,
      sqlQueryEditorMode,
      codeMirrorSqlRef,
      addNewSqlInfo,
      refreshCmp,
      setState,
      getState,
      sqlInfoAction
    }),
    [getState],
  );

  return (
    <QueryEditorContext.Provider value={value}>
      {children}
    </QueryEditorContext.Provider>
  );
}
