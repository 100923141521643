import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "AtText",
  iconSVG: IconSVG,
  needsMeta: true,
  defaults: {
    rows: 3,
    columns: 64,
    widgetName: "AtText",
    version: 1,
    animateLoading: true,
    isVisible: true,
    isCircle: false,
    fontSize: 14,
    fontStyle: '',
    color: '#000',
    bgColor: 'transparent',
    textAlign: 'left',
    label: '文本'
  },
  properties: {
    derived: Widget.getDerivedPropertiesMap(),
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    config: Widget.getPropertyPaneConfig(),
    eventConfig: Widget.getPropertyPaneEventsConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    autocompleteDefinitions: Widget.getAutocompleteDefinitions(),
  }
};

export default Widget;
