import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TProgressWidget from "../component";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";

class TProgress extends TaroWidget<TProgressWidgetProps, WidgetState> {
  constructor(props: TProgressWidgetProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      },
      {
        sectionName: "设置",
        children: [
          {
            propertyName: "percent",
            label: "进度",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 12,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "isHidePercent",
            label: "隐藏文字",
            helpText: "Loading input to this widget",
            controlType: "SWITCH",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "按钮样式",
        children: [
          {
            propertyName: "color",
            label: "颜色",
            helpText: "Controls the color of the text displayed",
            controlType: "COLOR_PICKER",
            isJSConvertible: false,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          }
        ],
      },
    ];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.label }}`
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      color: "{{appsmith.theme.colors.primaryColor}}",
      boxShadow: "none",
    };
  }
  onClick(){
    super.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: this.props.onClick,
      event: {
        type: EventType.ON_CLICK
      },
    });
  }

  getPageView() {
    return (
      <TProgressWidget {...this.props} onClick={this.onClick} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_PROGRESS_WIDGET";
  }
}

export interface TProgressWidgetProps extends WidgetProps {
  value: any[];
  label: string;
  onClick?: any;
  borderRadius: string;
  boxShadow?: string;
}

export default TProgress;
