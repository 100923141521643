import React, {useCallback, useEffect} from "react";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Slider,
  Switch,
  TimePicker,
  TreeSelect,
  Upload,
} from "antd";
import {UploadOutlined} from "@ant-design/icons/lib";
import {FontStyleTypes} from "../../../constants/WidgetConstants";
import _ from "lodash";
import styled from "styled-components";
import {
  AntdBtnContainerDiv,
  AntdFormItemWrapper,
  getAntdCascaderPopupStyeld,
  getAntdDatePickerPopupStyled,
  getAntdSelectPopupStyled, getAntdTreeSelectPopupStyled
} from "widgets/widgetStyled";

const {TextArea} = Input;
const {RangePicker} = DatePicker;
const PLACEHOLDER = '请输入';

type LayoutType = Parameters<typeof Form>[0]['layout'];
const inputNumberType: any = ['inputNumber', 'integer'];
const textAreaType: any = ['textarea', 'textArea'];
export const dateType: any = ['date'];
const timePickerType: any = ['timePicker'];
const dateRangePickerType: any = ['dateRangePicker'];
const cascaderType: any = ['cascader'];
const uploadType: any = ['upload', 'image'];
const radioType: any = ['radio', 'bool'];
const selectType: any = ['select', 'dict'];
const treeSelectType: any = ['treeSelect'];
const checkboxType: any = ['checkbox'];
const sliderType: any = ['slider'];
const switchType: any = ['switch'];

const FormContainerDiv = styled.div<any>`
  width: 100%;
  height: 100%;
  overflowX: auto;
 .ant-picker-range .ant-picker-active-bar {
     background: ${(props) => props.accentColor} !important;
  }
`
function DynamicForms(props: any) {
  const {
    currentData,
    submitText,
    resetText,
    onFinish: eventSubmit,
    labelPosition,
    colSpans,
    isVisible,
    isDisabled,
    isReadonly,
    labelTextColor,
    labelTextSize,
    labelStyle,
    primaryBtnColor,
    secondBtnColor,
    backgroundColor,
    borderColor,
    borderRadius,
    borderWidth,
    boxShadow,
  } = props;
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    eventSubmit && eventSubmit(values);
  };
  const onFinishFailed = () => {
  };
  const onReset = () => {
    form.resetFields();
  };

  const FormItems = useCallback(() => {
    const inputType: any = [].concat(
      inputNumberType,
      textAreaType,
      dateType,
      timePickerType,
      dateRangePickerType,
      selectType,
      cascaderType,
      checkboxType,
      radioType,
      sliderType,
      switchType,
      treeSelectType,
      uploadType
    );

    const GetItem = (itemProps: any) => {
      const {primaryBtnColor} = props
      const {children, data} = itemProps
      const label = data.fieldName
      const name = data.field
      const rules = [{required: data.required, message: data.message}]
      const span = data.span || colSpans

      return (
        <Col span={span} style={{paddingLeft: `${labelPosition == 'Top' ? '24px' : ''}`}}>
          {!isReadonly &&
          <AntdFormItemWrapper
            {...formItemLayout}
            label={label}
            name={name}
            rules={rules}
            accentColor={primaryBtnColor}
          >
            {children}
          </AntdFormItemWrapper>}
        </Col>
      )
    }

    // 动态生成CSS样式
    const dynamicStyles = `
      .dynamicFormDatePickerWidget__popover {
        ${getAntdDatePickerPopupStyled(primaryBtnColor)}
      }

      .dynamicFormCascaderPopup {
        ${getAntdCascaderPopupStyeld(primaryBtnColor)}
      }

      .dynamicFormSelectPopup {
        ${getAntdSelectPopupStyled(primaryBtnColor)}
      }

      .dynamicFormTreeSelectPopup {
        ${getAntdTreeSelectPopupStyled(primaryBtnColor)}
      }
    `;

    return (currentData || []).map((i: any) => {
      return (
        <>
          <style>{dynamicStyles}</style>
          {
            uploadType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <Upload><Button icon={<UploadOutlined/>}>上传</Button></Upload>
            </GetItem>
          }
          {
            treeSelectType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <TreeSelect popupClassName={'dynamicFormTreeSelectPopup'} disabled={i.readonly} style={{width: '100%'}} placeholder={PLACEHOLDER} allowClear treeData={i.options}/>
            </GetItem>
          }
          {
            switchType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <Switch disabled={i.readonly} />
            </GetItem>
          }
          {
            sliderType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <Slider disabled={i.readonly} />
            </GetItem>
          }
          {
            radioType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <Radio.Group disabled={i.readonly} options={i.options}/>
            </GetItem>
          }
          {
            checkboxType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <Checkbox.Group disabled={i.readonly} options={i.options}/>
            </GetItem>
          }
          {
            cascaderType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <Cascader popupClassName={'dynamicFormCascaderPopup'} disabled={i.readonly} options={i.options} placeholder={PLACEHOLDER} style={{width: '100%'}}/>
            </GetItem>
          }
          {
            selectType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <Select popupClassName={'dynamicFormSelectPopup'} disabled={i.readonly} placeholder={PLACEHOLDER} options={i.options} style={{width: '100%'}}/>
            </GetItem>
          }
          {
            dateRangePickerType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <RangePicker popupClassName={'dynamicFormDatePickerWidget__popover'} disabled={i.readonly} style={{width: '100%'}}/>
            </GetItem>
          }
          {
            timePickerType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <TimePicker popupClassName={'dynamicFormDatePickerWidget__popover'} disabled={i.readonly} />
            </GetItem>
          }
          {
            dateType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <DatePicker popupClassName={'dynamicFormDatePickerWidget__popover'} disabled={i.readonly} placeholder={PLACEHOLDER} style={{width: '100%'}}/>
            </GetItem>
          }
          {
            textAreaType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <TextArea disabled={i.readonly} rows={4}/>
            </GetItem>
          }
          {
            inputNumberType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <InputNumber disabled={i.readonly} min={0} max={100} defaultValue={0} style={{width: '100%'}}/>
            </GetItem>
          }
          {
            !inputType.includes(i.fieldType)
            &&
            <GetItem data={i}>
              <Input disabled={i.readonly} placeholder={PLACEHOLDER}/>
            </GetItem>
          }

        </>

      )
    })
  }, [props]);

  let formLayout: LayoutType = labelPosition === 'Left' ? 'horizontal' : 'vertical';
  let fontStyle = labelStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : "normal";
  let fontWeight = labelStyle?.includes(FontStyleTypes.BOLD) ? "bold" : "normal";
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
        labelCol: {
          span: 8,
          style: {
            color: labelTextColor,
            fontSize: labelTextSize,
            fontStyle,
            fontWeight,
          }
        },
        wrapperCol: {span: 16},
      }
      : null;
  //初始化表单值
  useEffect(()=>{
    let formValues:Record<string, unknown> = {};
    _.each(currentData, (item:any)=>{
      formValues[item.field] = item.value;
    })
    form.setFieldsValue(formValues)
  }, []);
  return (
    <FormContainerDiv accentColor={primaryBtnColor}>
      <Form
        className='Widget_dynamicForm_'
        form={form}
        layout={formLayout}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        disabled={isDisabled}
        autoComplete="off"
        style={{
          backgroundColor: backgroundColor || '#fff',
          borderColor: borderColor || 'none',
          borderRadius: borderRadius,
          borderWidth: borderWidth,
          boxShadow: boxShadow,
          padding: '10px',
        }}
      >
        <Row>
          <FormItems />
        </Row>
        <Form.Item style={{textAlign: 'center'}}>
          <AntdBtnContainerDiv
            primaryBtnColor={primaryBtnColor}
            secondBtnColor={secondBtnColor}
          >
            <Button
              type={'primary'}
              style={{
                marginRight: "8px",
              }}
              htmlType="submit"
            >
              {submitText}
            </Button>
            <Button
              type={'default'}
              style={{
                marginRight: "8px",
              }}
              htmlType="button"
              onClick={onReset}
            >
              {resetText}
            </Button>
          </AntdBtnContainerDiv>
        </Form.Item>
      </Form>
    </FormContainerDiv>
  )
}

export default DynamicForms;
