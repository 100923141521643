import React, {useEffect, useRef, useState} from "react";
import {Checkbox, Table} from "antd";
import _ from "lodash";
import {formWidgetType} from "../../../../pages/Editor/WidgetSidebar";

export const setEditDict = formWidgetType || [
  "CHECKBOX_WIDGET",
  "CHECKBOX_GROUP_WIDGET",
  "DATE_PICKER_WIDGET2",
  "FILE_PICKER_WIDGET_V2",
  "INPUT_WIDGET_V2",
  "MULTI_SELECT_TREE_WIDGET",
  "MULTI_SELECT_WIDGET_V2",
  "RADIO_GROUP_WIDGET",
  "RICH_TEXT_EDITOR_WIDGET",
  "SELECT_WIDGET",
  "SWITCH_WIDGET",
  "SWITCH_GROUP_WIDGET",
  "SINGLE_SELECT_TREE_WIDGET",
  "CASCADER_WIDGET",
];
//export const setDict = ['TABLE_WIDGET_V2', 'FORM_WIDGET', 'MODAL_WIDGET', 'TABS_WIDGET'];
export const setDict = ['TABLE_WIDGET_V2','TABLE_WIDGET_ANTD'];
function PageAuthority(props: any) {
  const {permMapRef, drawerKey} = props;
  const [tableData, setTableData] = useState<any>([]);
  const [able, setAble] = useState(false);
  const [edit, setEdit] = useState(false);
  const [ableIndeterminate, setAbleIndeterminate] = useState(false);
  const [editIndeterminate, setEditIndeterminate] = useState(false);

  let saveParams: any = useRef([]);
  const toggleType: any = {
    "isVisable": {
      indeterminate: "ableIndeterminate",
      setHalf: setAbleIndeterminate,
      setAll: setAble
    },
    "isEditor": {
      indeterminate: "editIndeterminate",
      setHalf: setEditIndeterminate,
      setAll: setEdit
    }
  }
  const columns = [
    {
      title: '功能菜单',
      dataIndex: 'title',
      key: 'title',
      render: (text: any, record: any) => {
        return <span>{record.title}:{record.label}</span>
      }
    },
    {
      title: () => {
        return (
          <>
            <Checkbox checked={able} indeterminate={ableIndeterminate} onChange={(ev: any) => {
              toggleSelected(ev, 'isVisable');
            }}/> 是否可见
          </>
        )
      },
      key: 'able',
      render: (text: any, record: any) => {
        return <Checkbox indeterminate={record.ableIndeterminate} checked={record.isVisable} onChange={(ev: any) => {
          singleToggleAble(ev.target.checked, record, 'isVisable');
        }}/>
      },
      width: 150,
    },
    {
      title: () => {
        return (
          <>
            <Checkbox checked={edit} indeterminate={editIndeterminate} onChange={(ev: any) => {
              toggleSelected(ev, 'isEditor');
            }}/> 是否可编辑
          </>
        )
      },
      key: 'edit',
      render: (text: any, record: any) => {
        return (
          <>
            {
              setEditDict.includes(record.widgetType) ?
                <Checkbox indeterminate={record.editIndeterminate} checked={record.isEditor} onChange={(ev: any) => {
                  singleToggleAble(ev.target.checked, record, 'isEditor');
                }}/> : null
            }
          </>
        )
      },
      width: 150,
    },
    {
      title: '',
      width: 100,
      key: 'set',
      render: (text: any, record: any) => {
        return (
          <>
            {setDict.includes(record.widgetType) && record.isVisable ?
              <span style={{cursor: 'pointer', color: '#008dff'}} onClick={() => {
                props.drawerType(record.widgetType, record.key);
              }}>设置</span> : null}
          </>
        )
      }
    }
  ];
  const toggleSelected = (ev: any, key: string) => {
    const value = ev.target.checked;
    let newTableData = _.cloneDeep(tableData);

    const fn = (data: any) => {
      _.each(data, (i: any) => {
        i[key] = value;
        if (!_.isEmpty(i.children)) {
          fn(i.children);
        }
      })
    }
    fn(newTableData);
    toggleType[key]?.setAll(value);
    toggleType[key]?.setHalf(false);
    setTableData(newTableData);
    propsSetParams(newTableData);
  };
  const loopUpTableData = ({checked, record, key}: {
    checked: boolean,
    record: any,
    key: string
  }) => {
    let cloneTableData = _.cloneDeep(tableData);
    const fn = (data: any, recordKey: any) => {
      _.each(data, (i: any) => {
        if (i.key == recordKey) {
          i[key] = checked;
          i[toggleType[key]?.indeterminate] = false;
          /*向下*/
          const fn2 = (data2: any) => {
            _.each(data2, (j: any) => {
              j[key] = checked;
              if (j.children) {
                fn2(j.children);
              }
            })
          };
          if (i.children) {
            fn2(i.children);
          }
          /*向上*/
          const fn3 = (data3: any, parent: any) => {
            _.each(data3, (j: any) => {
              if (j.key == parent) {
                let _checked = true;
                let _half = false;
                _.each(j.children, (k: any) => {
                  if (!k[key]) {
                    _checked = false;
                  }
                  if (k[key]) {
                    _half = true;
                  }
                })
                j[key] = (!_checked && _half) || _checked;
                j[toggleType[key]?.indeterminate] = !_checked && _half;
                if (j.parentKey && j.parentKey != '0') {
                  fn3(cloneTableData, j.parentKey);
                }
              } else if (j.children) {
                fn3(j.children, parent);
              }
            })
          }
          if (i.parentKey && i.parentKey != '-1') {
            fn3(cloneTableData, i.parentKey);
          }
          return false;
        } else if (!_.isEmpty(i.children)) {
          fn(i.children, recordKey);
        }
      })
    }
    fn(cloneTableData, record.key);
    return cloneTableData;
  };
  const singleToggleAble = (checked: boolean, record: any, key: string) => {
    // console.log('singleToggleAble----------11',checked, record, key);
    const newTableData = loopUpTableData({
      record: record,
      checked,
      key
    });
    let b1 = false;
    let b2 = false;
    const fn = (data: any) => {
      _.each(data, (i: any) => {
        if (i[key]) {
          b1 = true;
        } else {
          b2 = true;
        }
        if (i.children) {
          fn(i.children);
        }
      })
    };
    fn(newTableData);
    // console.log('singleToggleAble----------22',b1, b2, newTableData);
    toggleType[key]?.setHalf(b1 && b2);
    toggleType[key]?.setAll(b1 && !b2);
    setTableData((newTableData));
    propsSetParams(newTableData);
  };
  //初始数据清理
  const clearChildren = (data: any) => {
    let newData = _.cloneDeep(data);
    let b1 = false;
    let b2 = false;
    let b3 = false;
    let b4 = false;
    const fn = (data2: any) => {
      _.each(data2, (i: any) => {
        i.isVisable = permMapRef.current[i.id]?.isVisable == 1;
        i.isEditor = permMapRef.current[i.id]?.isEditor == 1;
        if (_.isEmpty(i.children) || setDict.includes(i.widgetType)) {
          delete i.children;
        } else {
          let able = [], edit = [];
          _.each(i.children, (j: any) => {
            if (j.isVisable) {
              able.push(j.isViable);
            }
            if (j.isEditor) {
              edit.push(j.isEditor);
            }
            i.ableIndeterminate = (able.length > 0 && able.length < i.children.length);
            i.editIndeterminate = (edit.length > 0 && edit.length < i.children.length);
          })
          fn(i.children);
        }
        if (i.isVisable) {
          b1 = true;
        } else {
          b2 = true;
        }
        if (i.isEditor) {
          b3 = true;
        } else {
          b4 = true;
        }
      })
    }
    data && fn(newData);
    setAbleIndeterminate(b1 && b2);
    setAble(b1 && !b2);
    setEditIndeterminate(b3 && b4);
    setEdit(b3 && !b4);
    return newData || [];
  }

  useEffect(() => {
    // console.log('props.tableData', props.tableData);
    if (!_.isEmpty(props.tableData)) {
      const result = clearChildren(props.tableData);
      // console.log('___', result);
      setTableData(result);
    }
  }, [props.tableData]);

  const fn = (data: any) => {
    _.each(data, (i: any) => {
      saveParams.current.push(i);
      if (i.children) {
        fn(i.children);
      }
    })
  };

  const propsSetParams = (data: any) => {
    saveParams.current = [];
    fn(data);
    props.setParams(saveParams.current);
  }

  return <Table
    columns={columns}
    dataSource={tableData}
    size="small"
    pagination={false}
  />
}

export default PageAuthority;
