import IconSVG from "./icon.svg";
import Widget from "./widget";

export const CONFIG = {
  type: Widget.getWidgetType(),
  name: "Breadcrumb",
  iconSVG: IconSVG,
  defaults: {
    rows: 3,
    columns: 16,
    widgetName: "Breadcrumb",
    version: 1,
    animateLoading: true,
    currentData: []
  },
  properties: {
    default: Widget.getDefaultPropertiesMap(),
    meta: Widget.getMetaPropertiesMap(),
    derived: Widget.getDerivedPropertiesMap(),
    eventConfig: Widget.getPropertyPaneEventConfig(),
    contentConfig: Widget.getPropertyPaneContentConfig(),
    stylesheetConfig: Widget.getStylesheetConfig(),
    styleConfig: Widget.getPropertyPaneStyleConfig(),
  },
  autoLayout: {
    widgetSize: [
      {
        viewportMinWidth: 0,
        configuration: () => {
          return {
            minWidth: "320px",
            minHeight: "80px",
          };
        },
      },
    ],
  },
};

export default Widget;
