import {apiPath} from "@byk/routes";
import {getTenant} from "../localStorage";
import Api from "@byk/api/Api";
import {message} from "antd";
export const previewImagePathFun = (code:string)=>{
  if(!code||code=='[]'){
    return '';
  }else if(code.indexOf&&code.indexOf('/')<0){
    return `${apiPath}/${getTenant()}/file/${code}`;
  }else if(code.startsWith&&code.startsWith('[{')){
    return JSON.parse(code)[0]['url'];
  }else{
    return code;
  }
};
export function useUploadImageCode(){
  const previewImagePath = (code:string)=>{
    return previewImagePathFun(code);
  };
  const uploadImageCode = (file:any, callback:any)=> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      let base64 = e.target['result'];
      base64 = base64.split('base64,')[1];
      let params = {
        data: base64,
        ext: file.name.substring(file.name.lastIndexOf('.')),
        filename: file.name,
        type: file.type,
      };
      let url: any = `${apiPath}/${getTenant()}/rest/SysFile`;
      Api.post(url, params).then((res: any) => {
        if (res.success) {
          let r: any = res.result[0];
          callback && callback(r.code);
        } else {
          message.error(res.message);
        }
      })
    }
  }
  return {
    previewImagePath,
    uploadImageCode
  }
}
