import React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import type {WidgetType} from "constants/WidgetConstants";
import type {InputComponentProps} from "../component";
import InputComponent from "../component";
import {EventType} from "constants/AppsmithActionConstants/ActionConstants";
import type {ValidationResponse} from "constants/WidgetValidation";
import {ValidationTypes} from "constants/WidgetValidation";
import {
  createMessage,
  FIELD_REQUIRED_ERROR,
  INPUT_DEFAULT_TEXT_MAX_CHAR_ERROR,
  INPUT_DEFAULT_TEXT_MAX_NUM_ERROR,
  INPUT_DEFAULT_TEXT_MIN_NUM_ERROR,
  INPUT_TEXT_MAX_CHAR_ERROR,
} from "@appsmith/constants/messages";
import type {DerivedPropertiesMap} from "utils/WidgetFactory";
import type {AutocompletionDefinitions} from "widgets/constants";
import {GRID_DENSITY_MIGRATION_V1} from "widgets/constants";
import {AutocompleteDataType} from "utils/autocomplete/AutocompleteDataType";
import BaseInputWidget from "widgets/BaseInputWidget";
import {isNil, isNumber, merge, toString} from "lodash";
import derivedProperties from "./parsedDerivedProperties";
import type {BaseInputWidgetProps} from "widgets/BaseInputWidget/widget";
import {mergeWidgetConfig} from "utils/helpers";
import {InputTypes, NumberInputStepButtonPosition,} from "widgets/BaseInputWidget/constants";
import {getParsedText, isInputTypeEmailOrPassword} from "./Utilities";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions, isAutoHeightEnabledForWidget,} from "widgets/WidgetUtils";
import {checkInputTypeTextByProps} from "widgets/BaseInputWidget/utils";
import {DynamicHeight} from "utils/WidgetFeatures";

export function defaultValueValidation(
  value: any,
  props: InputWidgetProps,
  _?: any,
): ValidationResponse {
  const STRING_ERROR_MESSAGE = {
    name: "TypeError",
    message: "This value must be string",
  };
  const NUMBER_ERROR_MESSAGE = {
    name: "TypeError",
    message: "This value must be number",
  };
  const EMPTY_ERROR_MESSAGE = {name: "", message: ""};
  if (_.isObject(value)) {
    return {
      isValid: false,
      parsed: JSON.stringify(value, null, 2),
      messages: [STRING_ERROR_MESSAGE],
    };
  }

  const {inputType} = props;
  let parsed;
  switch (inputType) {
    case "NUMBER":
      if (_.isNil(value)) {
        parsed = null;
      } else {
        parsed = Number(value);
      }
      let isValid, messages;

      if (_.isString(value) && value.trim() === "") {
        /*
         *  When value is emtpy string
         */
        isValid = true;
        messages = [EMPTY_ERROR_MESSAGE];
        parsed = null;
      } else if (!Number.isFinite(parsed)) {
        /*
         *  When parsed value is not a finite numer
         */
        isValid = false;
        messages = [NUMBER_ERROR_MESSAGE];
        parsed = null;
      } else {
        /*
         *  When parsed value is a Number
         */
        isValid = true;
        messages = [EMPTY_ERROR_MESSAGE];
      }

      return {
        isValid,
        parsed,
        messages,
      };
    case "TEXT":
    case "MULTI_LINE_TEXT":
    case "IDCARD":
      parsed = value;
      if (!_.isString(parsed)) {
        try {
          parsed = _.toString(parsed);
        } catch (e) {
          return {
            isValid: false,
            parsed: "",
            messages: [STRING_ERROR_MESSAGE],
          };
        }
      }
      return {
        isValid: _.isString(parsed),
        parsed: parsed,
        messages: [EMPTY_ERROR_MESSAGE],
      };
    case "PASSWORD":
    case "EMAIL":
      parsed = value;
      if (!_.isString(parsed)) {
        try {
          parsed = _.toString(parsed);
        } catch (e) {
          return {
            isValid: false,
            parsed: "",
            messages: [STRING_ERROR_MESSAGE],
          };
        }
      }
      return {
        isValid: _.isString(parsed),
        parsed: parsed,
        messages: [EMPTY_ERROR_MESSAGE],
      };
    default:
      return {
        isValid: false,
        parsed: "",
        messages: [STRING_ERROR_MESSAGE],
      };
  }
}

export function minValueValidation(min: any, props: InputWidgetProps, _?: any) {
  const max = props.maxNum;
  const value = min;
  min = Number(min);

  if (_?.isNil(value) || value === "") {
    return {
      isValid: true,
      parsed: undefined,
      messages: [
        {
          name: "",
          message: "",
        },
      ],
    };
  } else if (!Number.isFinite(min)) {
    return {
      isValid: false,
      parsed: undefined,
      messages: [
        {
          name: "TypeError",
          message: "This value must be number",
        },
      ],
    };
  } else if (max !== undefined && min >= max) {
    return {
      isValid: false,
      parsed: undefined,
      messages: [
        {
          name: "RangeError",
          message: "This value must be lesser than max value",
        },
      ],
    };
  } else {
    return {
      isValid: true,
      parsed: Number(min),
      messages: [
        {
          name: "",
          message: "",
        },
      ],
    };
  }
}

export function maxValueValidation(max: any, props: InputWidgetProps, _?: any) {
  const min = props.minNum;
  const value = max;
  max = Number(max);

  if (_?.isNil(value) || value === "") {
    return {
      isValid: true,
      parsed: undefined,
      messages: [
        {
          name: "",
          message: "",
        },
      ],
    };
  } else if (!Number.isFinite(max)) {
    return {
      isValid: false,
      parsed: undefined,
      messages: [
        {
          name: "TypeError",
          message: "This value must be number",
        },
      ],
    };
  } else if (min !== undefined && max <= min) {
    return {
      isValid: false,
      parsed: undefined,
      messages: [
        {
          name: "RangeError",
          message: "This value must be greater than min value",
        },
      ],
    };
  } else {
    return {
      isValid: true,
      parsed: Number(max),
      messages: [
        {
          name: "",
          message: "",
        },
      ],
    };
  }
}

function InputTypeUpdateHook(
  props: WidgetProps,
  propertyName: string,
  propertyValue: any,
) {
  const updates = [
    {
      propertyPath: propertyName,
      propertyValue: propertyValue,
    },
  ];

  if (propertyValue === InputTypes.MULTI_LINE_TEXT) {
    if (props.dynamicHeight === DynamicHeight.FIXED) {
      updates.push({
        propertyPath: "dynamicHeight",
        propertyValue: DynamicHeight.AUTO_HEIGHT,
      });
    }
  }
  //if input type is email or password default the autofill state to be true
  // the user needs to explicity set autofill to fault disable autofill
  updates.push({
    propertyPath: "shouldAllowAutofill",
    propertyValue: isInputTypeEmailOrPassword(propertyValue),
  });

  return updates;
}

class InputWidget extends BaseInputWidget<InputWidgetProps, WidgetState> {
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc":
        "An input text field is used to capture a users textual input such as their names, numbers, emails etc. Inputs are used in forms and can have custom validations.",
      "!url": "https://docs.appsmith.com/widget-reference/input",
      text: {
        "!type": "string",
        "!doc": "The text value of the input",
        "!url": "https://docs.appsmith.com/widget-reference/input",
      },
      inputText: {
        "!type": "string",
        "!doc": "The unformatted text value of the input",
        "!url": "https://docs.appsmith.com/widget-reference/input",
      },
      isValid: "bool",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      isDisabled: "bool",
    };
  }

  static getPropertyPaneContentConfig() {
    return mergeWidgetConfig(
      [
        {
          sectionName: "标签",
          children: [
            {
              helpText: "Sets the label text of the widget",
              propertyName: "label",
              label: "文本",
              controlType: "INPUT_TEXT",
              placeholderText: "Name:",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: { type: ValidationTypes.TEXT },
            },
          ],
        },
        {
          sectionName: "数据",
          children: [
            {
              helpText: "Changes the type of data captured in the input",
              propertyName: "inputType",
              label: "字段类型",
              controlType: "DROP_DOWN",
              options: [
                {
                  label: "单行文本",
                  value: "TEXT",
                },
                {
                  label: "多行文本",
                  value: "MULTI_LINE_TEXT",
                },
                {
                  label: "数字",
                  value: "NUMBER",
                },
                {
                  label: "密码",
                  value: "PASSWORD",
                },
                {
                  label: "邮箱",
                  value: "EMAIL",
                },
                {
                  label: "身份证号码",
                  value: "IDCARD",
                },
              ],
              isBindProperty: false,
              isTriggerProperty: false,
              updateHook: InputTypeUpdateHook,
              dependencies: ["dynamicHeight"],
            },
            {
              helpText:
                "Sets the default text of the widget. The text is updated if the default text changes",
              propertyName: "defaultText",
              label: "默认值",
              controlType: "INPUT_TEXT",
              placeholderText: "",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {
                type: ValidationTypes.FUNCTION,
                params: {
                  fn: defaultValueValidation,
                  expected: {
                    type: "string or number",
                    example: `John | 123`,
                    autocompleteDataType: AutocompleteDataType.STRING,
                  },
                },
              },
              dependencies: ["inputType"],
            },
            {
              propertyName: "leftContent",
              label: "前置内容",
              helpText: "Makes input to the widget mandatory",
              controlType: "INPUT_TEXT",
              isJSConvertible: true,
              isBindProperty: true,
              isTriggerProperty: false,
              hidden: (props: BaseInputWidgetProps) =>
                  props.inputType !== "TEXT",
              dependencies: ["inputType"],
            },
            {
              propertyName: "rightContent",
              label: "后置内容",
              helpText: "Makes input to the widget mandatory",
              controlType: "INPUT_TEXT",
              isJSConvertible: true,
              isBindProperty: true,
              isTriggerProperty: false,
              hidden: (props: BaseInputWidgetProps) =>
                  props.inputType !== "TEXT",
              dependencies: ["inputType"],
            },
            {
              propertyName: "isCount",
              label: "是否开启计数提示",
              helpText: "Makes input to the widget mandatory",
              controlType: "SWITCH",
              isJSConvertible: true,
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {type: ValidationTypes.BOOLEAN},
            },
          ]
        },
        {
          sectionName: "验证",
          children: [
            {
              propertyName: "isRequired",
              label: "是否必填",
              helpText: "Makes input to the widget mandatory",
              controlType: "SWITCH",
              isJSConvertible: true,
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {type: ValidationTypes.BOOLEAN},
            },
            {
              helpText: "Sets maximum allowed text length",
              propertyName: "maxChars",
              label: "最大字符数",
              controlType: "INPUT_TEXT",
              placeholderText: "255",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {
                type: ValidationTypes.NUMBER,
                params: {min: 1, natural: true, passThroughOnZero: false},
              },
              hidden: (props: InputWidgetProps) => {
                return !checkInputTypeTextByProps(props);
              },
              dependencies: ["inputType"],
            },
            {
              helpText: "Sets the minimum allowed value",
              propertyName: "minNum",
              label: "Min",
              controlType: "INPUT_TEXT",
              placeholderText: "1",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {
                type: ValidationTypes.FUNCTION,
                params: {
                  fn: minValueValidation,
                  expected: {
                    type: "number",
                    example: `1`,
                    autocompleteDataType: AutocompleteDataType.NUMBER,
                  },
                },
              },
              hidden: (props: InputWidgetProps) => {
                return props.inputType !== InputTypes.NUMBER;
              },
              dependencies: ["inputType"],
            },
            {
              helpText: "Sets the maximum allowed value",
              propertyName: "maxNum",
              label: "Max",
              controlType: "INPUT_TEXT",
              placeholderText: "100",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {
                type: ValidationTypes.FUNCTION,
                params: {
                  fn: maxValueValidation,
                  expected: {
                    type: "number",
                    example: `100`,
                    autocompleteDataType: AutocompleteDataType.NUMBER,
                  },
                },
              },
              hidden: (props: InputWidgetProps) => {
                return props.inputType !== InputTypes.NUMBER;
              },
              dependencies: ["inputType"],
            },
          ],
        },
        {
          sectionName: "一般",
          children: [
            {
              helpText: "Sets a placeholder text for the input",
              propertyName: "placeholderText",
              label: "引导文字",
              controlType: "INPUT_TEXT",
              placeholderText: "请输入",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {type: ValidationTypes.TEXT},
            },

          ],
        },
      ],
      super.getPropertyPaneContentConfig(),
    );
  }

  static getPropertyPaneStyleConfig() {
    return mergeWidgetConfig(
      [
        {
          sectionName: "图标",
          children: [
          ],
        },
      ],
      super.getPropertyPaneStyleConfig(),
    );
  }

  static getPropertyPaneEventConfig() {
    return super.getWidgetEvents('InputWidget');
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return merge(super.getDerivedPropertiesMap(), {
      isValid: `{{(() => {${derivedProperties.isValid}})()}}`,
      getValue: `{{ this.text }}`,
    });
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return merge(super.getMetaPropertiesMap(), {
      inputText: "",
      text: "",
    });
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      inputText: "defaultText",
      text: "defaultText",
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
      it_color: "{{appsmith.theme.form.inputText.fontColor}}",
      it_size: "{{appsmith.theme.form.inputText.fontSize}}",
      it_style: "{{appsmith.theme.form.inputText.fontStyle}}",
      labelTextColor: "{{appsmith.theme.form.label.fontColor}}",
      labelTextSize: "{{appsmith.theme.form.label.fontSize}}",
      labelStyle: "{{appsmith.theme.form.label.fontStyle}}",
      ib_bgColor: "{{appsmith.theme.form.inputBorder.bgColor}}",
      ib_borderColor: "{{appsmith.theme.form.inputBorder.bgBorderColor}}",
    };
  }

  handleFocusChange = (focusState: boolean) => {
    if (focusState) {
      this.props.updateWidgetMetaProperty("isFocused", focusState, );
      if(this.props.onFocus){
        super.executeAction({
          triggerPropertyName: "onFocus",
          dynamicString: this.props.onFocus,
          event: {
            type: EventType.ON_FOCUS,
          },
        })
      }
    }
    if (!focusState) {
      this.props.updateWidgetMetaProperty("isFocused", focusState);
      if(this.props.onBlur){
        super.executeAction({
          triggerPropertyName: "onBlur",
          dynamicString: this.props.onBlur,
          event: {
            type: EventType.ON_BLUR,
          },
        })
      }
    }
    super.handleFocusChange(focusState);
  };

  handleKeyDown = (
    e:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    super.handleKeyDown(e);
  };

  componentDidUpdate = (prevProps: InputWidgetProps) => {
    if (
      prevProps.inputText !== this.props.inputText &&
      this.props.inputText !== toString(this.props.text)
    ) {
      this.props.updateWidgetMetaProperty(
        "text",
        getParsedText(this.props.inputText, this.props.inputType),
      );
    }

    if (prevProps.inputType !== this.props.inputType) {
      this.props.updateWidgetMetaProperty(
        "text",
        getParsedText(this.props.inputText, this.props.inputType),
      );
    }
    // If defaultText property has changed, reset isDirty to false
    if (
      this.props.defaultText !== prevProps.defaultText &&
      this.props.isDirty
    ) {
      this.props.updateWidgetMetaProperty("isDirty", false);
    }
  };

  onValueChange = (value: string) => {
    /*
     * Ideally text property should be derived property. But widgets
     * with derived properties won't work as expected inside a List
     * widget.
     * TODO(Balaji): Once we refactor the List widget, need to conver
     * text to a derived property.
     */
    this.props.updateWidgetMetaProperty(
      "text",
      getParsedText(value, this.props.inputType),
    );
    this.props.updateWidgetMetaProperty("inputText", value);
    if(this.props.onTextChanged){
      super.executeAction({
        triggerPropertyName: "onTextChanged",
        dynamicString: this.props.onTextChanged,
        event: {
          type: EventType.ON_TEXT_CHANGE,
        },
      })
    }

    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
  };

  resetWidgetText = () => {
    this.props.updateWidgetMetaProperty("inputText", "");
    this.props.updateWidgetMetaProperty(
      "text",
      getParsedText("", this.props.inputType),
    );
  };

  getPageView() {
    const value = this.props.inputText ?? "";
    let isInvalid = false;
    if (this.props.isDirty) {
      isInvalid = "isValid" in this.props && !this.props.isValid;
    } else {
      isInvalid = false;
    }

    const conditionalProps: Partial<InputComponentProps> = {};
    conditionalProps.errorMessage = this.props.errorMessage;
    if (this.props.isRequired && value.length === 0) {
      conditionalProps.errorMessage = createMessage(FIELD_REQUIRED_ERROR);
    }

    if (!isNil(this.props.maxNum)) {
      conditionalProps.maxNum = this.props.maxNum;
    }

    if (!isNil(this.props.minNum)) {
      conditionalProps.minNum = this.props.minNum;
    }

    if (checkInputTypeTextByProps(this.props) && this.props.maxChars) {
      // pass maxChars only for Text type inputs, undefined for other types
      conditionalProps.maxChars = this.props.maxChars;
      if (
        this.props.defaultText &&
        this.props.defaultText.toString().length > this.props.maxChars
      ) {
        isInvalid = true;
        conditionalProps.errorMessage = createMessage(
          INPUT_DEFAULT_TEXT_MAX_CHAR_ERROR,
          this.props.maxChars,
        );
      } else if (value && value.length > this.props.maxChars) {
        isInvalid = true;
        conditionalProps.errorMessage = createMessage(
          INPUT_TEXT_MAX_CHAR_ERROR,
          this.props.maxChars,
        );
      }
    }

    if (
      this.props.inputType === InputTypes.NUMBER &&
      isNumber(this.props.defaultText)
    ) {
      // check the default text is neither greater than max nor less than min value.
      if (
        !isNil(this.props.minNum) &&
        this.props.minNum > Number(this.props.defaultText)
      ) {
        isInvalid = true;
        conditionalProps.errorMessage = createMessage(
          INPUT_DEFAULT_TEXT_MIN_NUM_ERROR,
        );
      } else if (
        !isNil(this.props.maxNum) &&
        this.props.maxNum < Number(this.props.defaultText)
      ) {
        isInvalid = true;
        conditionalProps.errorMessage = createMessage(
          INPUT_DEFAULT_TEXT_MAX_NUM_ERROR,
        );
      }
    }

    if (
      this.props.inputType === InputTypes.NUMBER &&
      this.props.showStepArrows
    ) {
      conditionalProps.buttonPosition = NumberInputStepButtonPosition.RIGHT;
    } else {
      conditionalProps.buttonPosition = NumberInputStepButtonPosition.NONE;
    }
    const autoFillProps =
      !this.props.shouldAllowAutofill &&
      isInputTypeEmailOrPassword(this.props.inputType)
        ? {autoComplete: "off"}
        : {};
    return (
      <InputComponent
        accentColor={this.props.accentColor}
        {...autoFillProps}
        // show label and Input side by side if true
        autoFocus={this.props.autoFocus}
        borderRadius={this.props.borderRadius}
        boxShadow={this.props.boxShadow}
        compactMode={
          !(
            (this.props.bottomRow - this.props.topRow) /
            GRID_DENSITY_MIGRATION_V1 >
            1
          )
        }
        leftContent={this.props.leftContent}
        rightContent={this.props.rightContent}
        defaultValue={this.props.defaultText}
        disableNewLineOnPressEnterKey={!!this.props.onSubmit}
        disabled={this.props.isDisabled}
        readonly={this.props.readonly}
        iconAlign={this.props.iconAlign}
        iconName={this.props.iconName}
        inputType={this.props.inputType}
        isDynamicHeightEnabled={isAutoHeightEnabledForWidget(this.props)}
        isInvalid={isInvalid}
        isLoading={this.props.isLoading}
        label={this.props.label}
        labelAlignment={this.props.labelAlignment}
        labelPosition={this.props.labelPosition}
        labelStyle={this.props.labelStyle}
        labelTextColor={this.props.labelTextColor}
        labelTextSize={this.props.labelTextSize}
        labelWidth={this.getLabelWidth()}
        multiline={this.props.inputType === InputTypes.MULTI_LINE_TEXT}
        onFocusChange={this.handleFocusChange}
        onKeyDown={this.handleKeyDown}
        onValueChange={this.onValueChange}
        placeholder={this.props.placeholderText}
        showError={!!this.props.isFocused}
        spellCheck={!!this.props.isSpellCheck}
        stepSize={1}
        tooltip={this.props.tooltip}
        value={value}
        isRequired={this.props.isRequired}
        widgetId={this.props.widgetId}
        isCount={this.props.isCount}
        it_color={this.props.it_color}
        it_size={this.props.it_size}
        it_style={this.props.it_style}
        ib_bgColor={this.props.ib_bgColor}
        ib_borderColor={this.props.ib_borderColor}
        {...conditionalProps}
      />
    );
  }

  static getWidgetType(): WidgetType {
    return "INPUT_WIDGET_V2";
  }
}

export interface InputWidgetProps extends BaseInputWidgetProps {
  defaultText?: string | number;
  maxChars?: number;
  isSpellCheck?: boolean;
  maxNum?: number;
  minNum?: number;
  inputText: string;
  readonly: boolean;
}

export default InputWidget;
