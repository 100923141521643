import * as React from "react";
import {useEffect, useState} from "react";
import type {ComponentProps} from "widgets/BaseComponent";
import {Cascader, Col, Divider, Row} from "antd";
import {LabelPosition} from "../../../components/constants";
import _ from "lodash";
import "./index.css";
import styled from "styled-components";
import LabelWithTooltip, {LABEL_CONTAINER_CLASS, labelLayoutStyles} from "widgets/components/LabelWithTooltip";
import {FontStyleTypes} from "@design-system/widgets-old";
import {lightenColor} from "../../../widgets/WidgetUtils";
import { getAntdCascaderPopupStyeld } from "widgets/widgetStyled";

const DivContainer = styled.div<any>`
  ${labelLayoutStyles}
  & .${LABEL_CONTAINER_CLASS} {
    align-self: center;
    ${({ labelPosition }) =>
    labelPosition === LabelPosition.Left && "min-height: 30px"};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
   width: 100%;
   height: 100%!important;
   border-radius: ${({ borderRadius }) => borderRadius ||' 0.375rem'} !important;
   box-shadow: ${({ boxShadow }) => boxShadow ||'none'} !important;
   background:  ${({ib_bgColor}) => ib_bgColor ? ib_bgColor : 'var(--wds-color-bg)'} !important;
   border-color: ${({ ib_borderColor}) => ib_borderColor};
   display: flex !important;
   align-items: center !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${({accentColor}) => accentColor } !important;
      box-shadow: 0px 0px 0px 2px ${({accentColor}) => lightenColor(accentColor)} !important;
  }

  .ant-select-selection-item {
    color: ${({it_color}) => {
      return (it_color ? `${it_color}` : "var(--wds-color-text)") + ' !important';
    }};
    font-size: ${({it_size}) => {
      return (it_size ? `${it_size}` : "14px") + ' !important';
    }};
    font-style: ${({it_style}) => {
      return (it_style?.includes(FontStyleTypes.ITALIC) ? "italic" : "") + ' !important'
    }};
    font-weight: ${({it_style}) => {
      return (it_style?.includes(FontStyleTypes.BOLD) ? "bold" : "") + ' !important'
    }};
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: ${({it_size}) => {
        return (it_size ? `${it_size}` : "14px") + ' !important'
    }};
  }

  .ant-select-clear {
    background: none !important;
  }

  .anticon-down, .anticon-close-circle {
    svg {
      fill:  ${(props) => props.ib_iconColor ? props.ib_iconColor : "var(--wds-color-icon)"} !important;
      path {
        fill: ${({ $isDisabled, ib_iconColor}) =>
          $isDisabled
            ? "var(--wds-color-icon-disabled)"
            : ib_iconColor ? ib_iconColor : "var(--wds-color-icon)"} !important;
                stroke: ${({ $isDisabled, ib_iconColor }) =>
          $isDisabled
            ? "var(--wds-color-icon-disabled)"
            :  ib_iconColor ? ib_iconColor : "var(--wds-color-icon)"} !important;
      }
    }
  }
`;

const CascaderWidget = (props: any) => {
  const {
    isLast,
    isMultiple,
    isSearch,
    currentData,
    labelText,
    labelPosition,
    value,
    defaultValue,
    isDirty,
    updateWidgetProperty,
    onClose,
    onOpen,
    isReadonly,
    isDisabled,
    isRequired,
    labelWidth,
    selectedCount,
    labelTextColor,
    labelTextSize,
    labelStyle,
    boxShadow,
    borderRadius,
    compactMode,
    labelAlignment,
    it_color,
    it_size,
    it_style,
    ib_bgColor,
    ib_borderColor,
    ib_iconColor,
  } = props;
  const {SHOW_CHILD} = Cascader;
  const [status, setStatus] = useState<any>("");

  useEffect(() => {
    checkStatus(value);
  }, [selectedCount])

  useEffect(() => {
    if (value && value.length > 1) {
      updateWidgetProperty([], []);
    }
    setStatus("");
  }, [isMultiple])

  const checkStatus = (values: any = []) => {
    if (isMultiple && selectedCount && values.length >= selectedCount + 1) {
      setStatus("error");
    } else {
      setStatus("");
    }
  }

  const onChange = (values: any, selectedOptions: any) => {
    checkStatus(values);

    if (_.isEmpty(values)) {
      updateWidgetProperty([], []);
    } else {
      if (isMultiple) {
        let labels: any = [];
        _.each(selectedOptions, (item: any, idx: number) => {
          labels[idx] = [];
          _.each(item, (i: any) => {
            labels[idx].push(i.label);
          })
        })
        updateWidgetProperty(values, labels);
      } else {
        let _label = selectedOptions.map((i: any) => {
          return i.label;
        });
        updateWidgetProperty(values, _label);
      }
    }
  };

  const onDropdownVisibleChange = (value: boolean) => {
    if (value) {
      onOpen && onOpen();
    } else {
      onClose && onClose();
    }
  }
  const valueFormat = (v: any) => {
    if(isDirty&&_.isEmpty(v)){
      v = defaultValue;
    }
    if (!_.isEmpty(v) && _.isArray(v)) {
      let propertyValues: Array<any> = [];
      if (isMultiple) {
        propertyValues = v.map((i: any) => {
          if (i.includes("-")) {
            return i.split("-");
          } else {
            return _.isArray(i) ? i : [i];
          }
        });
      } else {
        propertyValues = v;
      }
      return propertyValues;
    }
    return [];
  }

  const dropdownRender = (menus: React.ReactNode) => (
    <div>
      {menus}
      {isMultiple && selectedCount > 0 &&
      <div>
        <Divider style={{margin: 0}}/>
        <div style={{padding: 8, color: status ? "red" : "black"}}>{`最多可选择${selectedCount}项`}</div>
      </div>
      }
    </div>
  );

  const dynamicStyles = `
    .dynamicFormCascaderPopup {
      ${getAntdCascaderPopupStyeld(props.accentColor)}
    }
  `;

  return (
    <>
      <style>{dynamicStyles}</style>
      <DivContainer compactMode={compactMode}
        data-testid="radiogroup-container"
        labelPosition={labelPosition}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        it_color={it_color}
        it_size={it_size}
        it_style={it_style}
        ib_bgColor={ib_bgColor}
        ib_borderColor={ib_borderColor}
        ib_iconColor={ib_iconColor}
        accentColor={props.accentColor}
      >
        {labelText && (
          <LabelWithTooltip
            className={`datepicker-label`}
            text={labelText}
            cyHelpTextClassName="datepicker-tooltip"
            alignment={labelAlignment}
            color={labelTextColor}
            compact={compactMode}
            disabled={isDisabled}
            fontSize={labelTextSize}
            fontStyle={labelStyle}
            helpText={""}
            loading={false}
            position={labelPosition}
            width={labelWidth}
            isRequired={isRequired}
          />

        )}
        <Cascader
          popupClassName={'dynamicFormCascaderPopup'}
          value={valueFormat(value)}
          style={{width: "100%"}}
          options={currentData}
          onChange={onChange}
          changeOnSelect={isLast}
          multiple={isMultiple}
          showSearch={isSearch}
          onDropdownVisibleChange={onDropdownVisibleChange}
          bordered={!isReadonly}
          disabled={isDisabled || isReadonly}
          showCheckedStrategy={SHOW_CHILD}
          status={status}
          dropdownRender={dropdownRender}
        />

      </DivContainer>
    </>
  )
};

class CascaderComponent extends React.Component<CascaderComponentProps> {
  constructor(props: CascaderComponentProps) {
    super(props);
  }

  render() {
    return <CascaderWidget {...this.props} />;
  }
}

export interface CascaderComponentProps extends ComponentProps {
  isLast: boolean,
  isSearch: boolean,
  isMultiple: boolean,
  updateWidgetProperty: any,
  onOpen?: () => void,
  onClose?: () => void,
  compactMode?:any
}

export default CascaderComponent;
