import type {WidgetType} from "constants/WidgetConstants";
import * as React from "react";
import type {WidgetProps, WidgetState} from "widgets/BaseWidget";
import TaroWidget from "widgetsTaro";
import {ValidationTypes} from "constants/WidgetValidation";
import type {Stylesheet} from "entities/AppTheming";
import {DefaultAutocompleteDefinitions} from "widgets/WidgetUtils";
import type {AutocompletionDefinitions} from "widgets/constants";
import TCheckboxWidget from "../component";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import {DerivedPropertiesMap} from "../../../utils/WidgetFactory";

class TCheckbox extends TaroWidget<TCheckboxWidgetProps, WidgetState> {
  constructor(props: TCheckboxWidgetProps) {
    super(props);
  }
  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      value: "string",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "一般",
        children: [
          {
            propertyName: "label",
            label: "组件标签",
            helpText: "设置该组件的标签",
            controlType: "INPUT_TEXT",
            placeholderText: "",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {type: ValidationTypes.TEXT},
          }
        ],
      }
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }
  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      getValue: `{{ this.value }}`
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }
  updateWidgetProperty = (propertyValues: string) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty("value", propertyValues);
  }

  getPageView() {
    return (
      <TCheckboxWidget {...this.props} updateWidgetProperty={this.updateWidgetProperty} />
    );
  }


  static getWidgetType(): WidgetType {
    return "AT_CHECKBOX_WIDGET";
  }
}

export interface TCheckboxWidgetProps extends WidgetProps {
  value: any[];
  label: string;
  onClick?: any;
  borderRadius: string;
  boxShadow?: string;
}

export default TCheckbox;
