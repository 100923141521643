import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import AMapLoader from '@amap/amap-jsapi-loader';
import securityKey from '@byk/utils/securityKey';
import { Input, Modal, message } from 'antd';
import _ from 'lodash';
import { uuid2 } from '@byk/utils/Utils';
import { SearchOutlined } from '@ant-design/icons';

export interface IProps {
  ref?: any,
  defaultLngLat?: any,//默认经纬度  eg:
  callback?: (v?: any) => any;
  mode: string,//模式 view、modal
  isSearch: boolean,
  dragEnable: boolean,//是否支持拖拽
  zoomEnable: boolean,//是否支持缩放
  isShowCard: boolean,//点击标记点打开记录详情卡片
  zoomLevel: number,//缩放级别
  currentData?: any,
  securityJsKey?: string,
  securityJsCode?: string,//密钥
  borderColor?: string,
  borderWidth?: string,
  borderRadius?: string,
  boxShadow?: string,
}

const MapComponent: React.FC<IProps> = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showModal
  }))
  const { defaultLngLat, callback, mode, isSearch, dragEnable, zoomEnable, isShowCard, zoomLevel, currentData, securityJsKey
    , securityJsCode,borderColor,borderWidth,borderRadius,boxShadow } = props;
  const defaultTitle = "请选择地点";
  const defaultZoom = 17;// 初始缩放级别
  const [zoom, setZoom] = useState(zoomLevel);
  const [key, SetKey] = useState(uuid2());
  const mapRef = useRef(null);
  const [map, setMap] = useState<any>(null);//地图
  const [input, setInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [center, setCenter] = useState(null);//地图中心点
  const [title, setTitle] = useState<any>(defaultTitle);
  const [lnglat, setLnglat] = useState<any>(null);


  const showModal = (defaultLngLat?: any) => {
    setIsModalOpen(true);
    if (defaultLngLat) {
      setCenter(defaultLngLat.lnglat);
      setTitle(defaultLngLat.address);
    }
    else {
      setTitle(defaultTitle);
    }
    setInput("");
    SetKey(uuid2());
  };

  const handleOk = () => {
    if (_.isEmpty(lnglat)) {
      message.warn("请选择地点！")
      return false;
    }
    callback && callback(lnglat);
    handleCancel();
  };

  const handleCancel = () => {
    let elements:any = document.getElementsByClassName("amap-sug-result");  
    // 遍历这个集合  
    for (let i = 0; i < elements.length; i++) {  
        // 对每个元素设置visibility属性  
        elements[i].style.visibility = 'hidden';  
    } 
    setIsModalOpen(false);
  };
  useEffect(() => {
    //console.log("currentData", currentData);
    let _securityJsCode = securityJsCode || securityKey.securityJsCode;
    let _securityJsKey = securityJsKey || securityKey.key;

    window._AMapSecurityConfig = {
      securityJsCode: _securityJsCode
    };
    let initialMapJson: any = {};
    initialMapJson.zoom = zoomLevel;// 初始缩放级别
    initialMapJson.dragEnable = dragEnable; // 禁用拖动
    initialMapJson.zoomEnable = zoomEnable; // 禁用缩放
    //initialMapJson.mapStyle = 'amap://styles/dark'; // 默认浅色模式
    if (!_.isEmpty(center)) {
      initialMapJson.center = center;// 地图中心点
    }
    //console.log("initialMapJson", initialMapJson);
    AMapLoader.load({
      key: _securityJsKey,
      version: '2.0',
      plugins: ['AMap.Geocoder', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Driving', 'AMap.ToolBar', 'AMap.ControlBar', 'AMap.HawkEye', 'AMap.Scale'], // 需要预加载的插件
    }).then((AMap) => {
      const initialMap = new AMap.Map(mapRef.current, initialMapJson);
      if (zoomEnable) {
        initialMap.addControl(new AMap.ToolBar());//添加缩放工具栏
      }

      //initialMap.addControl(new AMap.ControlBar({position: {top: '10px',right: '10px'}}));//工具条方向盘
      //initialMap.addControl(new AMap.HawkEye({opened: false}));//显示鹰眼
      initialMap.addControl(new AMap.Scale());//比例尺
      let marker: any = null;
      //点击地图获取经纬度坐标及地址---------------------start
      if (mode == "modal") {
        let geocoder = new AMap.Geocoder({
          //city: "010", //城市设为北京，默认：“全国”
        });
        marker = new AMap.Marker();
        //默认选择中心点,图标标记 ---------------------start
        initialMap.add(marker);
        if (!_.isEmpty(center)) {
          marker.setPosition(center);// 地图中心点
        }

        //默认选择中心点 -----------------------end
        initialMap.on('click', function (e: any) {
          let lnglat = [e.lnglat.lng, e.lnglat.lat];
          regeoCode(lnglat);
        });

        const regeoCode = (lnglat: any) => {
          geocoder.getAddress(lnglat, function (status: any, result: any) {
            if (status === 'complete' && result.regeocode) {
              let address = result.regeocode.formattedAddress;
              let obj = { lnglat: lnglat, address: address };
              setMark(obj)
              console.log(obj);
            } else {
              console.log('根据经纬度查询地址失败');
            }
          });
        }
      }
      //点击地图获取经纬度坐标及地址---------------------end

      //设置标记
      const clearMarker = () => {
        if (marker) {
          marker.setMap(null);
          marker = null;
        }
      }
      const setMark = (obj: any, type: any = "modal") => {
        clearMarker();
        marker = new AMap.Marker();
        initialMap.add(marker);
        marker.setPosition(obj.lnglat);
        setLnglat(obj);
        setTitle(obj.address);
      }


      //输入框关键字搜索------------------------------------------------------------------------------------------start
      if (isSearch) {
        // 实例化自动完成插件
        const autoComplete = new AMap.Autocomplete({
          input: "z-amap-autocomplete-input",  // 绑定输入框的DOM ID
        });
        const placeSearch2 = new AMap.PlaceSearch({
          map: initialMap
        });

        // 监听选中地址事件
        autoComplete.on('select', function (e: any) {
          setInput(e.poi.name);
          if (e.poi && e.poi.location) {
            debugger;
            let obj = { lnglat: [e.poi.location.lng, e.poi.location.lat], address: e.poi.district + e.poi.name };
            initialMap.setZoom(18); // 调整地图缩放级别
            initialMap.setCenter(e.poi.location); // 地图中心移动到选中地址
            setMark(obj);
            //placeSearch2.search(e.poi.name); // 同时执行地点搜索
          }
        });
      }

      //输入框关键字搜索------------------------------------------------------------------------------------------end


      //覆盖物标记------------------------------------------------------------------------------------------start
      if (mode == "view" && Array.isArray(currentData) && currentData.length > 0) {
        let infoWindow: any = null;
        //构建自定义信息窗体
        function createInfoWindow(title: any, content: any) {
          let info = document.createElement("div");
          info.className = "z-custom-info z-input-card z-content-window-card";

          //可以通过下面的方式修改自定义窗体的宽高
          info.style.width = "400px";
          // 定义顶部标题
          let top = document.createElement("div");
          let titleD = document.createElement("div");
          let closeX = document.createElement("img");
          top.className = "info-top";
          titleD.innerHTML = title;
          closeX.src = "https://webapi.amap.com/images/close2.gif";
          closeX.onclick = closeInfoWindow;

          top.appendChild(titleD);
          top.appendChild(closeX);
          info.appendChild(top);

          // 定义中部内容
          let middle = document.createElement("div");
          middle.className = "info-middle";
          middle.style.backgroundColor = 'white';
          middle.innerHTML = content;
          info.appendChild(middle);

          // 定义底部内容
          let bottom = document.createElement("div");
          bottom.className = "info-bottom";
          bottom.style.position = 'relative';
          bottom.style.top = '0px';
          bottom.style.margin = '0 auto';
          let sharp = document.createElement("img");
          sharp.src = "https://webapi.amap.com/images/sharp.png";
          bottom.appendChild(sharp);
          info.appendChild(bottom);
          return info;
        }

        //关闭信息窗体
        function closeInfoWindow() {
          initialMap.clearInfoWindow();
        }

        let markers = [];
        for (let i = 0; i < currentData.length; i++) {
          let info: any = currentData[i];
          let lnglat = info.lnglat;
          // 创建点实例
          let marker = new AMap.Marker({
            position: new AMap.LngLat(lnglat[0], lnglat[1]),
            icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b' + (i + 1) + '.png',
            extData: {
              id: i + 1
            }
          });
          marker.on('click', function () {
            if(!isShowCard){
              return false;
            }
            //点击打开信息窗口
            infoWindow = new AMap.InfoWindow({
              isCustom: true,  //使用自定义窗体
              content: createInfoWindow(info.address, _.isEmpty(info.content) ? [] : info.content.join("<br/>")),
              offset: new AMap.Pixel(0, -15)
            });

            infoWindow.open(initialMap, marker.getPosition());
            initialMap.setCenter(lnglat);
          });
          markers.push(marker);
        }
        if (currentData.length > 0) {
          //将最后一个标记点设置为中心点
          initialMap.setCenter(currentData[currentData.length - 1].lnglat);
        }

        // 创建覆盖物群组，并将 marker 传给 OverlayGroup
        let overlayGroups = new AMap.OverlayGroup(markers);
        initialMap.add(overlayGroups);
      }
      //覆盖物标记------------------------------------------------------------------------------------------end
      setMap(initialMap);
    }).catch(e => {
      console.log(e);
    });

    return () => {
      if (map) {
        map.destroy();
      }
    };
  }, [key, isSearch, dragEnable, zoomEnable, zoomLevel, currentData,isShowCard,borderColor,
    borderWidth,
    borderRadius,
    boxShadow]);

  return (
    <>
      {mode == "view" &&
        <>
          {isSearch && <>
            <Input size="large" id="z-amap-autocomplete-input" value={input} allowClear
              className='z-amap-autocomplete-input'
              style={{top:"13px"}}
              onChange={e => setInput(e.target.value)}
              placeholder="搜索地点" prefix={<SearchOutlined />} />
          </>
          }
          <div ref={mapRef}  style={{ width: '100%', height: 'inherit', minHeight: "100px",
            borderRadius:"inherit"
        }}></div>
        </>}
      {mode == "modal" &&
        <>
          <Modal keyboard className='z-MapWidget-Modal' forceRender={true} maskClosable={false} destroyOnClose={true} title={title} width={"90%"} cancelText="取消" okText="确定" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input size="large" style={{ top:"65px", left: "10px" }} id="z-amap-autocomplete-input" value={input} allowClear
              className='z-amap-autocomplete-input'
              onChange={e => setInput(e.target.value)}
              placeholder="搜索地点" prefix={<SearchOutlined />} />
            <div ref={mapRef} style={{ width: '100%', height: '600px' }}>

            </div>
          </Modal>
        </>}

    </>
  );
});

export default MapComponent;
