import {Button, Col, Form, Input, Modal, Row, Spin} from 'antd';
import React, {forwardRef, useImperativeHandle, useState} from 'react';

import ModelingApi from "@byk/pages/modeling/lib/ModelingApi";
import styled from "@emotion/styled";

const ModalWrapper = styled(Modal)<any>`
  .ant-modal-title {
    color: #101010;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-body {
    padding: 0;
  }
`

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 24},
  width: "400px",
};

const ConfigDataSource: React.FC<any> = forwardRef((
      {setDataSourceCfg, loadModels}, ref) => {

      useImperativeHandle(ref, () => ({
        showModal,
      }));

      const [form] = Form.useForm();
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [loading, setLoading] = useState(false);
      const [spinning, setSpinning] = useState(false);

      const showModal = () => {
        form.resetFields();
        setIsModalOpen(true);
      }

      const handleCancel = () => {
        setIsModalOpen(false);
      }

      const loadModelsByDs = () => {
        setSpinning(true);
        form.validateFields().then(async (values) => {
          await doImport({
            ...values,
            tenant: ModelingApi.getTenant(),
          });
          setSpinning(false);
        }).catch((errorInfo: any) => {
          setSpinning(false);
        });
      }

      const doImport = async (values: any) => {
        setLoading(true);
        setDataSourceCfg(values);
        let result: any = await ModelingApi.doApiLoadModelsFromDataSource(values);
        if (result.success) {
          loadModels(result.result);
          setIsModalOpen(false);
        }
        setLoading(false);
      };

      return (
        <>
          <ModalWrapper
            onCancel={handleCancel}
            open={isModalOpen}
            title="配置数据源"
            cancelText="取消" okText="确认"
            maskClosable={false}
            width={450}
            footer={null}
          >
            <Spin tip="Loading..." spinning={spinning}>
              <Form
                {...layout}
                form={form}
                wrapperCol={{span: 12}}
                autoComplete="off"
                style={{
                  padding: "10px 0px",
                }}
              >
                <Form.Item
                  label="主机名/IP" name="ip"
                  rules={[
                    {required: true, message: `主机名/IP必填.`,}
                  ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item
                  label="端口" name="port"
                  rules={[
                    {required: true, message: `端口必填.`,}
                  ]}
                >
                  <Input type={"number"} autoComplete={"off"}/>
                </Form.Item>
                <Form.Item
                  label="用户" name="username"
                  rules={[
                    {required: true, message: `用户必填.`,}
                  ]}
                >
                  <Input autoComplete={"new-password"}/>
                </Form.Item>
                <Form.Item
                  label="密码" name="password"
                  rules={[
                    {required: true, message: `密码必填.`,}
                  ]}
                >
                  <Input.Password autoComplete={"new-password"}/>
                </Form.Item>
                <Form.Item
                  label="数据库" name="db"
                  rules={[
                    {required: true, message: `数据库必填.`,}
                  ]}
                >
                  <Input/>
                </Form.Item>

                <Row>
                  <Col span={18} style={{textAlign: 'right'}}>
                    <Button htmlType="submit" onClick={handleCancel} style={{marginRight: "10px"}}>
                      取消
                    </Button>
                    <Button loading={loading} type="primary" onClick={loadModelsByDs} htmlType="submit">
                      加载模型
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </ModalWrapper>
        </>
      );
    }
  )
;

export default ConfigDataSource;
